import React, { useState, useEffect } from "react"
import { Box, IconButton, Button, Typography, TextField, List, ListItem } from "@mui/material";
import googleIcon from '../../assets/icons/googleIcon.svg';
import applyIcon from '../../assets/icons/applyIcon.svg';
import fbIcon from '../../assets/icons/fbIcon.svg';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import RegisterAccountMod from '../popUpSection/RegisterAccountMod';
import PasswordCustom from '../inputFieldCustom/PasswordCustom';
import { signInWithPopup, signInWithEmailAndPassword, getAuth, OAuthProvider, signInWithRedirect } from '@firebase/auth';
import { AppleProvider, auth, facebookProvider, provider } from '../../utils/config/firebaseAuth';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from "react-i18next";
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";
import useMediaQuery from '@mui/material/useMediaQuery';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CurrenciesModal from "../popUpSection/CurrenciesModal";
import { NeedHelp } from "../popUpSection/NeedHelp";
import {
    getUserDetailsApi,
    getUserEmailExistApi,
    setAuthenticationType,
    setEmailAuthenticationUserData,
    setEnterPassword,
    setSocialMediaAuthenticationUserData,
    setIsRegisterPopUp,
    setLoginUserEmailAddress,
    setMainPopupForRegisterUser,
    setSignInCallApi,
    setUserLoginPassword,
    setRegisterUserEmailAddress,
    setUserBirthDate,
    setRegisterUserCurrencyId,
    setLastNameUser,
    setFirstNameUser,
    setUserPhoneNumber,
    setCountryCallingIDData,
    fetchHotelsToExploreOffersApi,
    setErrorMessageForDialogueBox,
    setAlertBoxOnError,
    setUserFaviouratesListReset,
    setRemoveFromFaviouratesReset,
    setUserWishlistDataReset,
    setAddTofavouriteListReset,
    setUserActionLoader
} from "../../appStore/hotelSlice";
import ForgetPasswordModel from "../popUpSection/ForgetPasswordModel";
import { useDispatch } from "react-redux";
import AuthMessageHandler from "../../utils/utilities/AuthMessageHandler";
import { EventNamesForEventLogs, authenticationTypeForApp } from "../../utils/constants/constant";
import { accountOptionSectionType } from '../../utils/constants/constant';
import { fetchFaviourateFolderList, fetchUserTripsApi, setHotelRoomDetails, setHotelDetails, setGetHotelsToExploreOffersReset } from '../../appStore/hotelSlice';
import { useNavigate } from 'react-router';
import NextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faSolid from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export const LoginAccount = () => {
    // t object for language translation for keys
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [emailInput, setEmailInput]: any = useState('');
    const [passwordInputLogin, setPasswordInputLogin] = useState('')
    const [errorMessageInputs, setErrorMessageInputs] = useState('');
    const [emailValidator, setEmailValidator] = useState(false);
    const [mobileShow, setMobileShow] = useState(true);
    const userEmailAddress = useAppSelector(state => state.hotel.userLoginEmailAddress);
    const userLoginPassword: any = useAppSelector(state => state.hotel.userLoginPassword);
    const userRegisteredPasword: any = useAppSelector(state => state.hotel.userRegisterPassword);
    const { verificationPopup, gmailVerificationPopup, gateWayAndHolidayMakerPopup, userRegisterWelcomePopup,
        registerUserStatus, mainPopupForRegisterUser, userActionLoader } = useAppSelector(state => state.hotel);
    const actionLoader = userActionLoader;
    const emailAuthUserEmail = useAppSelector(state => state.hotel.emailAuthenticationUserData);
    const gmailAuthUserEmail: any = useAppSelector(state => state.hotel.socialMediaAuthenticationUserData);
    const authType = useAppSelector(state => state.hotel.authenticationType);
    const getUserEmailExist = useAppSelector(state => state.hotel.getUserEmailExist.data?.status)
    const userExistApiLoading = useAppSelector(state => state.hotel.getUserEmailExist.loading)
    const newUserCreatedMEssage: any = useAppSelector(state => state.hotel.setRegisteredUserDetails.message)
    const newUserCreatedDetails: any = useAppSelector(state => state.hotel.setRegisteredUserDetails.userDetail)
    const enterPassword = useAppSelector(state => state.hotel.enterPassword);
    const signInCallApi = useAppSelector(state => state.hotel.signInCall);
    const { getUserDetails, loggedInUserCurrency } = useAppSelector(state => state.hotel)
    const accessToken = sessionStorage.getItem('accessToken');
    const lastRefresh = sessionStorage.getItem('lastRefresh');
    //const email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const fromLogin = true;
    const matches = useMediaQuery('(max-width:1024px)');
    const emailAuthentication = authenticationTypeForApp.emailAuth;
    const gmailAuthentication = authenticationTypeForApp.gmailAuth;
    const appleAuthentication = authenticationTypeForApp.appleAuth;
    const facebookAuthentication = authenticationTypeForApp.facebook;
    const accountSectionType = accountOptionSectionType;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const iconName: any = 'fa-solid fa-spinner'; // This is a valid IconProp

    // register popup data reset when click on sign in options
    const resetRegistrationData = () => {
        dispatch(setFirstNameUser(''))
        dispatch(setLastNameUser(''))
        // dispatch(setUserBirthDate(''))
        dispatch(setCountryCallingIDData({
            countryCallingId: '91',
            countryId: 101,
            countryCallCodeLabel: ''
        }))
        dispatch(setUserPhoneNumber(''))
        dispatch(setRegisterUserEmailAddress(''));
        dispatch(setUserBirthDate(''));
        dispatch(setRegisterUserCurrencyId({ currencyId: 0, currencyCode: '' }));
    };

    useEffect(() => {
        // verify user datails at the time of signIn
        if (userEmailAddress && userLoginPassword && signInCallApi == true && getUserEmailExist == true) {
            signInHandler(auth, userEmailAddress, userLoginPassword)
        }
        // registerd user firebase login call email & pass  
        if (newUserCreatedDetails?.email && accessToken == null && userRegisteredPasword !== '' && newUserCreatedMEssage == 'User Created Successfully.' && mainPopupForRegisterUser == false) {
            signInHandler(auth, newUserCreatedDetails?.email, userRegisteredPasword)
        }
        // get details of email user loggedin
        if (accessToken && emailAuthUserEmail && authType == emailAuthentication && getUserEmailExist == true && signInCallApi == true) {
            switch (emailAuthUserEmail) {
                case emailAuthUserEmail:
                    dispatch(getUserDetailsApi(emailAuthUserEmail))
                    dispatch(setSignInCallApi(false))
                    break;
            }
        }

        // Registered user geting details and auto login
        if (accessToken && newUserCreatedMEssage == 'User Created Successfully.' && newUserCreatedDetails?.email && signInCallApi == true) {
            switch (newUserCreatedDetails?.email) {
                case newUserCreatedDetails?.email:
                    dispatch(getUserDetailsApi(newUserCreatedDetails.email))
                    dispatch(setSignInCallApi(false))
                    break;
            }
        }
        if (getUserDetails.data?.userDetail) {
            dispatch(setSignInCallApi(false))
        }
    }, [getUserEmailExist, lastRefresh, authType, newUserCreatedMEssage, newUserCreatedDetails, emailAuthUserEmail, signInCallApi, getUserDetails.data, mainPopupForRegisterUser])

    // user enterrd data validation functionality
    const emailInputHandler = (e: any) => {
        e.preventDefault();
        const { value } = e.target
        setEmailInput(value)
        setEmailValidator(false)
        if (value.match(email)) {
            dispatch(setLoginUserEmailAddress(value.trim()))
            setEmailValidator(false)
        }
    }

    // get details of gmail user loggedin
    useEffect(() => {
        if (authType !== emailAuthentication && accessToken && gmailAuthUserEmail?.userGmail && getUserEmailExist == true && gmailAuthUserEmail?.userGmail !== '' && signInCallApi) {
            switch (gmailAuthUserEmail?.userGmail) {
                case gmailAuthUserEmail?.userGmail:
                    dispatch(getUserDetailsApi(gmailAuthUserEmail?.userGmail))
                    dispatch(setSignInCallApi(false))
                    break;
            }
        }
        else if (accessToken && gmailAuthUserEmail?.userGmail && newUserCreatedMEssage == '' && authType !== emailAuthentication && getUserEmailExist == false && userExistApiLoading == false && gmailAuthUserEmail?.userGmail !== '' && registerUserStatus == false && signInCallApi) {
            // this dispatch need to remove after send otp enable
            if (verificationPopup == false && gmailVerificationPopup == false && gateWayAndHolidayMakerPopup == false && userRegisterWelcomePopup == false) {
                dispatch(setUserActionLoader({ action: '', loading: false }))
                dispatch(setMainPopupForRegisterUser(true))
                dispatch(setIsRegisterPopUp(true))
            }
        }
    }, [signInCallApi, authType, accessToken, gmailAuthUserEmail?.userGmail, newUserCreatedMEssage, getUserEmailExist, userExistApiLoading, registerUserStatus, verificationPopup, gmailVerificationPopup, gateWayAndHolidayMakerPopup, userRegisterWelcomePopup])

    useEffect(() => {
        userEmailAddress != '' ? dispatch(getUserEmailExistApi(userEmailAddress)) : '';
    }, [userEmailAddress])

    // useEffect for passwordinput handler
    useEffect(() => {
        if (passwordInputLogin?.length > 0) {
            dispatch(setUserLoginPassword(passwordInputLogin))
        }
        if (passwordInputLogin?.length == 0) {
            dispatch(setUserLoginPassword(passwordInputLogin))
        }
    }, [passwordInputLogin])

    // sign in with email functionality flow 
    function signInWithEmailHandler() {
        dispatch(setUserActionLoader({ action: 'signIn', loading: true }))
        userEmailAddress != '' ? dispatch(getUserEmailExistApi(userEmailAddress)) : '';
        dispatch(setSignInCallApi(true))
        if (passwordInputLogin.length <= 0) {
            dispatch(setUserActionLoader({ action: '', loading: false }))
            dispatch(setEnterPassword(true))
            dispatch(setSignInCallApi(false))
        }
        if (emailInput.length <= 0) {
            dispatch(setUserActionLoader({ action: '', loading: false }))
            setEmailValidator(true)
            setErrorMessageInputs(t('Please Enter your email'))
            dispatch(setSignInCallApi(false))
        }
        else if (!emailInput.match(email)) {
            dispatch(setUserActionLoader({ action: '', loading: false }))
            setEmailValidator(true)
            setErrorMessageInputs(t('Invalid email address'))
        }
        else if (getUserEmailExist === false && passwordInputLogin.length > 0) {
            dispatch(setUserActionLoader({ action: '', loading: false }))
            resetRegistrationData()
            dispatch(setErrorMessageForDialogueBox('1'));
            dispatch(setAlertBoxOnError(true));
            dispatch(setSignInCallApi(false))
        }
    }

    const signInHandler = (auth: any, userEmailAddress: any, userPassword: any) => {
        signInWithEmailAndPassword(auth, userEmailAddress, userPassword)
            .then((userCredential: any) => {
                // Signed in 
                sessionStorage.setItem("accessToken", userCredential.user.accessToken)
                sessionStorage.setItem("idToken", userCredential._tokenResponse.idToken)
                userCredential?.user?.email !== '' ? dispatch(getUserEmailExistApi(userCredential?.user?.email)) : ''
                dispatch(setEmailAuthenticationUserData(userCredential.user?.email))
                dispatch(setAuthenticationType(emailAuthentication))
                resetRegistrationData()
                AppLogHandler("userLoginDAta", userCredential.user?.email)
            })
            .catch((error) => {
                dispatch(setUserActionLoader({ action: '', loading: false }))
                const errorCode = error.code;
                const errorMessage = error.errorCode;
                AppLogHandler("errorCode,errorMessage", error)
                resetRegistrationData()
                // dispatch(setEmailAuthenticationUserData(errorCode))
                dispatch(setSignInCallApi(false))
                if (errorCode) {
                    const errmessage = AuthMessageHandler(errorCode);
                    if (errmessage !== '') {
                        dispatch(setErrorMessageForDialogueBox(errmessage));
                        dispatch(setAlertBoxOnError(true));
                    }
                }
            })
    }

    // sign with google flow 
    const signInWithGoogle = () => {
        dispatch(setUserActionLoader({ action: 'signIn', loading: true }))
        resetRegistrationData()
        setEmailInput('')
        setPasswordInputLogin('')
        signInWithPopup(auth, provider).then(
            (data: any) => {
                if (data?.user?.email !== '') {
                    dispatch(getUserEmailExistApi(data.user.email))
                }
                sessionStorage.setItem("accessToken", data.user.accessToken)
                sessionStorage.setItem("idToken", data._tokenResponse.idToken)
                sessionStorage.setItem("lastRefresh", data.user.reloadUserInfo.lastRefreshAt)
                dispatch(setSocialMediaAuthenticationUserData({ userGmail: data.user.email, userFirebaseId: data.user.uid }))
                dispatch(setAuthenticationType(gmailAuthentication))
                dispatch(setSignInCallApi(true))
                AppLogHandler("signinData", data)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                dispatch(setUserActionLoader({ action: '', loading: false }))
                if (errorCode) {
                    const errmessage = AuthMessageHandler(errorCode);
                    if (errmessage !== '') {
                        dispatch(setErrorMessageForDialogueBox(errmessage));
                        dispatch(setAlertBoxOnError(true));
                    }
                }
                AppLogHandler("errorsssss", error)
            })
    }
    const registerEnableHandler = () => {
        setEmailInput('')
        setPasswordInputLogin('')
        dispatch(setLoginUserEmailAddress(''))
        dispatch(setUserLoginPassword(''))
        setEmailValidator(false)
        dispatch(setEnterPassword(false))
    }

    // Apple Sign In Handler
    async function registerUserWithAppleHandler() {
        dispatch(setUserActionLoader({ action: 'signIn', loading: true }))
        resetRegistrationData()
        setEmailInput('')
        setPasswordInputLogin('')
        try {
            const login = await signInWithPopup(auth, AppleProvider)
                .then((result) => {
                    const userDetails: any = result.user;
                    const credential = OAuthProvider.credentialFromResult(result);
                    if (userDetails?.email) {
                        dispatch(getUserEmailExistApi(userDetails.email))
                    }
                    sessionStorage.setItem("accessToken", userDetails.accessToken)
                    sessionStorage.setItem("idToken", userDetails.idToken)
                    sessionStorage.setItem("lastRefresh", userDetails.reloadUserInfo.lastRefreshAt)
                    dispatch(setSocialMediaAuthenticationUserData({ userGmail: userDetails.email, userFirebaseId: userDetails.uid }))
                    dispatch(setAuthenticationType(appleAuthentication))
                    dispatch(setSignInCallApi(true))
                    AppLogHandler("Apple User Details", { userDetails, credential })
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    const email = error.email;
                    const credential = OAuthProvider.credentialFromError(errorCode);
                    dispatch(setUserActionLoader({ action: '', loading: false }))
                    dispatch(setErrorMessageForDialogueBox(errorMessage));
                    dispatch(setAlertBoxOnError(true));
                })
        }
        catch (error) {
            AppLogHandler("appleAuthError", error)
        }
    }

    //facebook sign in handler 
    async function signInWithFacebookHandler() {
        dispatch(setUserActionLoader({ action: 'signIn', loading: true }))
        resetRegistrationData()
        try {
            const result = await signInWithPopup(auth, facebookProvider);
            const userDetails: any = result.user;
            if (userDetails?.email) {
                dispatch(getUserEmailExistApi(userDetails.email))
            }
            sessionStorage.setItem("accessToken", userDetails.accessToken)
            sessionStorage.setItem("idToken", userDetails.idToken)
            sessionStorage.setItem("lastRefresh", userDetails.reloadUserInfo.lastRefreshAt)
            dispatch(setSocialMediaAuthenticationUserData({ userGmail: userDetails.email, userFirebaseId: userDetails.uid }))
            dispatch(setAuthenticationType(facebookAuthentication))
            dispatch(setSignInCallApi(true))
            AppLogHandler("Apple User Details", { userDetails })
        } catch (error: any) {
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.email;
            dispatch(setUserActionLoader({ action: '', loading: false }))
            dispatch(setErrorMessageForDialogueBox(errorMessage))
            dispatch(setAlertBoxOnError(true))
        }
    }

    // password input handler
    const passwordInputHandlerForLogin = (e: any) => {
        e.preventDefault();
        dispatch(setEnterPassword(false))
        const { value } = e.target
        if (value?.length > 0) {
            setPasswordInputLogin(value.trim())
        }
        else if (value?.length === 0) {
            setPasswordInputLogin(value.trim())
        }
    }

    // navigation handler
    const navigationHandler = (sectionType: string) => {
        switch (sectionType) {
            case accountSectionType.accountDetails:
                navigate('/account-details');
                break;
            case accountSectionType.myTrip:
                if (loggedInUserId != 0) {
                    dispatch(fetchUserTripsApi(loggedInUserId))
                }
                navigate('/my-trips')
                break;
            case accountSectionType.myFavourite:
                //need to remove this after 2-3 days
                dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
                dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
                dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
                dispatch(setAddTofavouriteListReset({ status: false, message: '' }))

                dispatch(fetchFaviourateFolderList(loggedInUserId))
                navigate('/favourites')
                break;
            case accountSectionType.exploreDestinations:
                dispatch(setHotelDetails({ data: [], loading: false, error: null }))
                dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
                dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
                navigate('/explore')
                break;
            case accountSectionType.latestOffers:
                dispatch(setHotelDetails({ data: [], loading: false, error: null }))
                dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
                dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
                dispatch(fetchHotelsToExploreOffersApi(''))
                navigate('/latest-offers')
                break;
            default: return 'No Section Matches'
        }
    }

    return (
        <>
            <Box className='iGoRoom_login_pop' sx={{ maxWidth: '260px' }}>
                {matches && mobileShow ?
                    (<>
                        <Box className='iGOr_Desk-D-none'>
                            <List>
                                <ListItem onClick={() => navigationHandler(accountSectionType.latestOffers)}>
                                    <Typography className="iGOr_MB-listLogin" variant="subtitle1">Latest Offer</Typography>
                                </ListItem>
                                <ListItem onClick={() => navigationHandler(accountSectionType.exploreDestinations)}>
                                    <Typography className="iGOr_MB-listLogin" variant="subtitle1">Explore</Typography>
                                </ListItem>
                                <ListItem onClick={() => navigationHandler(accountSectionType.myFavourite)}>
                                    <Typography className="iGOr_MB-listLogin" variant="subtitle1" >Favourites</Typography>
                                </ListItem>
                                <ListItem onClick={() => navigationHandler(accountSectionType.myTrip)}>
                                    <Typography className="iGOr_MB-listLogin" variant="subtitle1" >My Trip</Typography>
                                </ListItem>
                                <ListItem onClick={() => { setMobileShow(!mobileShow) }} sx={{ alignItems: 'flex-start' }}>
                                    <Typography className="iGOr_MB-listLogin" variant="subtitle1" >Account </Typography>
                                    <NextIcon />
                                </ListItem>
                            </List>
                            <RegisterAccountMod setBTn='iGoR_Btn' />
                            <IconButton onClick={() => { setMobileShow(!mobileShow) }} className="iGor_MB_loginBtn-Email iGor_MB_loginBtn" sx={{ width: '100%', color: '#000000 !important', marginTop: '10px', fontWeight: '600', padding: '15px' }}>
                                Sign in
                            </IconButton>
                        </Box>
                    </>
                    )
                    :
                    (<>
                        <Box className='iGor-Text-center iGOr_Desk-D-none'>
                            <Typography fontSize={'24px'} fontWeight={600} mt={'74px'} >{t('Sign in')}</Typography>
                            <Typography fontSize={'12px'} color={'#757575'} mb={'19px'}> Sign in first to plan your next trip </Typography>
                        </Box>

                        <Typography sx={{ padding: '6px 0 11px 0', fontSize: '14px', fontWeight: '600' }} variant="h5" className="iGOr_mb-D-none ">{t('Login to your account')}</Typography>
                        <Box className={!emailValidator ? ' InputField mb iGor_setRelative' : 'InputField iGor_setRelative'}  >
                            <MailOutlineIcon className='inputIcons iGor_mailIcon' />
                            <TextField
                                placeholder={t('Email Address')}
                                value={emailInput}
                                type='email'
                                onChange={(e) => emailInputHandler(e)}
                                sx={{ paddingRight: '15px' }}
                            />
                            {emailValidator && <label className="iGor-ErrorMsg">{errorMessageInputs}</label>}
                        </Box>
                        <Box className='iGor_setRelative iGor_loginPass'>
                            <PasswordCustom
                                fromLogin={fromLogin}
                                passwordInputLogin={passwordInputLogin}
                                setPasswordInputLogin={setPasswordInputLogin}
                                passwordInputHandlerForLogin={passwordInputHandlerForLogin}
                                placeholder='Password'
                                loginIcon='loginIcon'
                            />
                            {enterPassword && userLoginPassword === '' && <label className="iGor-ErrorMsg">{t('Please enter your password.')}</label>}
                        </Box>
                        <Box className='iGor_setFlex iGor_justifyCenter' onClick={(e) => (setEmailInput(''), setPasswordInputLogin(''))}>
                            <ForgetPasswordModel />
                        </Box>
                        <Box className='iGor_setFlex iGor_justifyCenter' sx={{ marginTop: '10px' }}>
                            <Button
                                className='igoRoomBtn iGOr_MB-primaryBtn'
                                sx={{ px: 3 }}
                                onClick={signInWithEmailHandler}
                                disabled={actionLoader?.loading == true ? true : false}
                            >
                                {actionLoader?.action == 'signIn' && actionLoader?.loading == true ? <Box className='iGor-action_loader'>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </Box> :
                                    t('Sign in')}
                            </Button>
                        </Box>
                    </>)}
                <Box className='iGor_setFlex iGor_justifyCenter'>
                    <Typography sx={{ padding: '18px 0', fontSize: '14px', fontWeight: matches ? '500' : '600' }} variant="h6"> {matches ? t('or') : t('Or sign in with')}</Typography>
                </Box>
                <Box className='iGor_setFlex iGor_justifyCenter iGor__loginBtnSocial' sx={{ flexDirection: `${matches ? 'column' : 'row'}` }} >
                    {matches && mobileShow ? <></> :
                        <RegisterAccountMod registerEnableHandler={registerEnableHandler} />}
                    <IconButton onClick={signInWithGoogle} className="iGor_MB_loginBtn-Google iGor_MB_loginBtn">
                        <img src={googleIcon} alt="logingUser" /> {matches ? mobileShow ? `Sign-up with Google` : `Sign-in with Google` : ''}
                    </IconButton>
                    <IconButton onClick={signInWithFacebookHandler} className="iGor_MB_loginBtn-Facebook iGor_MB_loginBtn">
                        <img src={fbIcon} alt="logingUser" />    {matches ? mobileShow ? `Sign-up with Facebook` : `Sign-in with Facebook` : ''}
                    </IconButton>
                    <IconButton onClick={registerUserWithAppleHandler} className="iGor_MB_loginBtn-Apple iGor_MB_loginBtn">
                        <img src={applyIcon} alt="logingUser" /> {matches ? mobileShow ? `Sign-up with Apple` : `Sign-in with Apple` : ''}
                    </IconButton>
                </Box>
                {matches &&
                    <>
                        <Box mt={'22px'}>
                            <Box className='iGor_setFlex iGor_justifyCenter'>
                                <CurrenciesModal setClass='iGor_currColor iGor_curr-MB ' />
                                <NeedHelp />
                            </Box>
                            <Box className="iGor__linkBox">
                                <a className="iGor__link" href='/privacy-policy' target='_blank'>Privacy </a>
                                <span>•</span> <a className="iGor__link" href='/terms-conditions' target='_blank'> Terms </a>
                                <span>•</span> <a className="iGor__link" href='https://support.igoroom.com/support/home' target='_blank' >
                                    Support</a>
                            </Box>
                            <Box className='iGor-Text-center'>
                                <a href="https://www.facebook.com/" target="_blank">
                                    <FacebookOutlinedIcon className="iGor_MB-social" />
                                </a>
                                <a href="https://www.instagram.com/" target="_blank">
                                    <InstagramIcon className="iGor_MB-social" />
                                </a>
                                <a href="https://www.youtube.com/" target="_blank">
                                    <YouTubeIcon className="iGor_MB-social" />
                                </a>
                            </Box>
                        </Box>
                    </>
                }
            </Box>
        </>
    )
}