import { useState } from "react";
import { useAppSelector } from "../../appStore/hooks";
import DestinationAddRoomBtn from "./DestinationAddRoomBtn"
import DestinationRoomAddition from "./DestinationRoomAddition"
import { Box, Button, Grid, Popover } from "@mui/material";
import { useTranslation } from 'react-i18next';
const DestinationRoomAdditionPopOver = (props: any) => {
    const { setRoomAnchorEl, RoomanchorEl, roomId, open, roomArrayForGuest, setRoomArrayForGuest } = props
    const { t } = useTranslation();
    const RoomClose = () => {
        setRoomAnchorEl(null)
    }
    return (
        <Popover
            id={roomId}
            open={open}
            anchorEl={RoomanchorEl}
            onClose={RoomClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div className="iGor__ProRoomBox">
                <Box className='iGor_d_roomBtnSectiom' >
                    <DestinationRoomAddition
                        setRoomArrayForGuest={setRoomArrayForGuest}
                        roomArrayForGuest={roomArrayForGuest}
                    />
                </Box>
                <Grid container spacing={1} className='iGor_mb_BtnAddSection'>
                    <Grid item sm={12} xs={12}>
                        <DestinationAddRoomBtn
                            setRoomArrayForGuest={setRoomArrayForGuest}
                            roomArrayForGuest={roomArrayForGuest}
                        />
                    </Grid>
                </Grid>
                <Button variant='contained' className='iGor_mb_Btn' onClick={() => { setRoomAnchorEl(null) }}>{t("Continue")}</Button>
            </div>
        </Popover>
    )
}
export default DestinationRoomAdditionPopOver