import { Button, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react'
import userOutline from '../../assets/icons/Icon_UserAccount.svg';
import { Box } from '@mui/system';
import CameraIcon from '@mui/icons-material/CameraAltOutlined';

export const FileUpload = () => {
    const [imageSrc, setImageSrc] = useState('')
    
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrc(imageUrl);
        }
    };

    return (
        <Box className='iGor_UploadSection iGor_setFlex iGor_justifyBetween'>
            <input
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                id="file-upload"
            />

            <img src={imageSrc ? imageSrc : userOutline} alt="Uploaded" className='iGor_UploadImg' />
            {/* <label htmlFor="file-upload">
                <Button component="span" className='iGor_UploadBtn' startIcon={<CameraIcon />}>
                   <span className='iGor_UploadTxt'>  Change profile picture </span>
                </Button>
            </label> */}
        </Box>
    )
}


