import { AppLogHandler } from "../handlers/appLogsHandler"

function AuthMessageHandler(errorCode: string) {
    let errorMessage = ''
    AppLogHandler('Auth error codes', errorCode)
    if (errorCode.includes('auth/wrong-password')) {
        return errorMessage = 'Please enter valid credentials to sign in !'
    }
    else if (errorCode.includes('auth/user-not-found')) {
        return errorMessage = 'This email address is not exist. Please try with sign up !'
    }
    else if (errorCode.includes('auth/too-many-requests')) {
        return errorMessage = 'We have blocked all request from this device due to unusual activity. Please try again later ...!'
    }
    else if (errorCode.includes('auth/network-request-failed')) {
        return errorMessage = 'There might be problem due to network. Please try after sometime ...!'
    }
    // else if (errorCode.includes('auth/popup-closed-by-user')) {
    //     return errorMessage = 'You have closed the popup...!'
    // }
    // else if (errorCode.includes('auth/cancelled-popup-request')) {
    //     return errorMessage = 'You have closed the popup...!'
    // }
    else {
        return errorMessage = '';
    }
}

export default AuthMessageHandler;