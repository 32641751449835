
import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material';
import circleCheckIcon from '../../assets/icons/circle-check-Icon.svg';
import { useDispatch } from 'react-redux';
import { setAlertBoxOnError, setErrorMessageForDialogueBox, setGateWaysAndHolidayMakerPopup, setUserRegisterWelcomePopup } from '../../appStore/hotelSlice';
import { userGateWays, userHolidaymakers } from '../../utils/constants/constant';
import { useTranslation } from 'react-i18next';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { useUpdateUserProfileMutation } from '../../services/api/hotelApi';
import { useAppSelector } from '../../appStore/hooks';
import { updateUserProfilePayload } from '../../services/updateUserProfileCall';

function GateWaysAndHolidayMaker() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedGateWay, setSelectedGateWay]: any = useState([]);
    const [selectedHoliday, setSelectedHoliday]: any = useState([]);
    const [gateWaysSelector, setGatwaysSelector] = useState(true)
    const [holidayMakerSelector, setHolidayMakerSelector] = useState(false)
    const { setRegisteredUserDetails } = useAppSelector(state => state.hotel)
    const registeredUserEmail = setRegisteredUserDetails?.userDetail?.email ? setRegisteredUserDetails?.userDetail?.email : '';
    const registeredUserDOB = setRegisteredUserDetails?.userDetail?.birthDate ? setRegisteredUserDetails?.userDetail?.birthDate : '';

    // gateWays and holida arrays
    const gateWays = userGateWays;
    const holidaymaker = userHolidaymakers;

    // user prefrence update mutation assigning
    const [updatePrefrances] = useUpdateUserProfileMutation();

    // Function to handle selected  gateways
    const gateWaysSelectionHandler = (gateWays: any) => {
        const gateWaySelected = gateWays.type

        // adding selected gateways on base of index to array
        const currentGatewayIndex = selectedGateWay.indexOf(gateWaySelected)
        const gateWaysArrayDispatch: any = [...selectedGateWay]
        if (currentGatewayIndex === -1) {
            gateWaysArrayDispatch.push(gateWaySelected);
        } else {
            gateWaysArrayDispatch.splice(currentGatewayIndex, 1);
        }
        setSelectedGateWay(gateWaysArrayDispatch)
        AppLogHandler('selectedGateWay', gateWaysArrayDispatch)
    };

    // gateways submit handler api call
    const submitGateWaysHandler = (gatewaycall: string) => {
        const gatewaySelectedArray = selectedGateWay.join()
        switch (gatewaycall) {
            case 'Proceed':
                if (selectedGateWay.length > 0) {
                    const payload = updateUserProfilePayload(registeredUserDOB, gatewaySelectedArray, registeredUserEmail, '', '', '')
                    updatePrefrances({ payload })
                    setHolidayMakerSelector(true)
                    setGatwaysSelector(false)
                }
                else {
                    dispatch(setErrorMessageForDialogueBox('10'))
                    dispatch(setAlertBoxOnError(true))
                }
                break;
            case 'Skip':
                setHolidayMakerSelector(true)
                setGatwaysSelector(false)
                setSelectedGateWay([])
                break;
        }
        AppLogHandler('gatewaySelectedArray', gatewaySelectedArray)
    }

    // Function to handle selected holidays
    const holidayMakerSelectionHandler = (holidays: any) => {
        const holidaysSelected = holidays?.type

        // adding selected holidays on base of index to array
        const currentholidayIndex = selectedHoliday.indexOf(holidaysSelected)
        const holidaysArrayDispatch: any = [...selectedHoliday]
        if (currentholidayIndex === -1) {
            holidaysArrayDispatch.push(holidaysSelected);
        } else {
            holidaysArrayDispatch.splice(currentholidayIndex, 1);
        }
        setSelectedHoliday(holidaysArrayDispatch);
        AppLogHandler('holidaysArrayDispatch', holidaysArrayDispatch)
    };

    // gateways submit handler api call
    const submitHolidaysHandler = (holidaycall: string) => {
        const holidaysSelectedArray = selectedHoliday.join()
        if (holidaycall == 'Proceed' && selectedHoliday.length > 0) {
            const payload = updateUserProfilePayload(registeredUserDOB, holidaysSelectedArray, registeredUserEmail, '', '', '')
            updatePrefrances({ payload })
            setHolidayMakerSelector(false)
            setGatwaysSelector(true)
            dispatch(setUserRegisterWelcomePopup(true))
            dispatch(setGateWaysAndHolidayMakerPopup(false))
        }
        else {
            dispatch(setErrorMessageForDialogueBox('11'))
            dispatch(setAlertBoxOnError(true))
        }
    }

    return (<>
        {gateWaysSelector && <Box>
            <Box sx={{ borderBottom: '1px solid #E5E5E5' }}>
                <Typography align='center' variant="subtitle1" sx={{ padding: '15px 20px  15px' }}>
                    What is your favourite getaway?
                </Typography>
            </Box>
            <Box sx={{ padding: '15px 20px  5px' }}>
                <Box className="iGor_setFlex iGor_verifiImg" >
                    {gateWays.map((gateWays: any, index: any) => {
                        return (<>
                            <Box className={`iGor_verifCardImg ${selectedGateWay.indexOf(gateWays?.type) !== -1 ? 'iGor_SelImg' : ''}`} onClick={() => gateWaysSelectionHandler(gateWays)}>
                                <img src={gateWays.img} alt='verifiImg1' />
                                <Box className='iGor_verifiTextOver' >
                                    <Typography variant='body1' className='iGor_verifiText'>{gateWays.type}</Typography>
                                </Box>
                                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
                            </Box>
                        </>
                        )
                    })}
                </Box>
                {/* <Box className="iGor_setFlex iGor_verifiImg">
                    <Box className={`iGor_verifCardImg ${selectedDiv === 4 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(4)}>
                        <img src={verifiImg} alt='verifiImg1' />
                        <Box className='iGor_verifiTextOver' >
                            <Typography variant='body1' >Adventure</Typography>
                        </Box>
                        <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
                    </Box>

                    <Box className={`iGor_verifCardImg ${selectedDiv === 5 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(5)}>
                        <img src={verifiImg2} alt='verifiImg1' />
                        <Box className='iGor_verifiTextOver' >
                            <Typography variant='body1' >Adventure</Typography>
                        </Box>
                        <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
                    </Box>

                    <Box className={`iGor_verifCardImg ${selectedDiv === 6 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(6)}>
                        <img src={verifiImg3} alt='verifiImg1' />
                        <Box className='iGor_verifiTextOver' >
                            <Typography variant='body1' >Adventure</Typography>
                        </Box>
                        <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
                    </Box>

                </Box> */}
            </Box>
            <Box sx={{ padding: '0 20px' }}>
                <Button variant="contained" sx={{ marginTop: '10px', marginBottom:'10px' }} onClick={() => { submitGateWaysHandler('Proceed') }}>
                    Continue
                </Button>
                {selectedGateWay < 1 ? (<Button fullWidth sx={{ marginBottom: '10px', color: '#757575', textTransform: 'capitalize' }} onClick={() => { submitGateWaysHandler('Skip') }}>
                    Skip
                </Button>) : ('')}
            </Box>
        </Box>}
        {holidayMakerSelector && <Box>
            <Box sx={{ borderBottom: '1px solid #E5E5E5' }}>
                <Typography align='center' variant="subtitle1" sx={{ padding: '15px 20px  15px' }}>
                    For every holidaymaker
                </Typography>
            </Box>
            <Box className="iGor_setFlex iGor_verifiImg">
                {holidaymaker.map((holidays, index) => {
                    return (<>
                        <Box className={`iGor_verifCardImg iGor_verifCardImgTwo ${selectedHoliday.indexOf(holidays?.type) !== -1 ? 'iGor_SelImg' : ''}`} onClick={() => holidayMakerSelectionHandler(holidays)}>
                            <img src={holidays.img} alt='verifiImg1' />
                            <Box className='iGor_verifiTextOver' >
                                <Typography variant='body1' >{holidays.type}</Typography>
                            </Box>
                            <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
                        </Box>
                    </>)
                })}
            </Box>
            <Box sx={{ padding: '15px 20px' }}>
                <Button type="submit" variant="contained"  onClick={() => { submitHolidaysHandler('Proceed') }}>
                    Continue
                </Button>
            </Box>
        </Box>}
    </>);
}

export default GateWaysAndHolidayMaker;