import { logEvent } from "firebase/analytics";
import { analytics } from "../config/firebaseAuth";
import { AppLogHandler } from "./appLogsHandler";

export const EventLogsHandler = (eventName: string, eventObject: any) => {
    logEvent(analytics, eventName, eventObject);
    AppLogHandler('Applogs for Events Capture', { eventName, eventObject })
}

export const EventLogHanlderMoengage = (eventNameMoe: string, eventObjectMoe: any) => {
    (window as any).Moengage.track_event(eventNameMoe, eventObjectMoe);
    AppLogHandler('Applogs for Events Capture Moe', { eventNameMoe, eventObjectMoe })
}

