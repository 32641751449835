import { Box, Typography } from "@mui/material";
import { useAppSelector } from "../../appStore/hooks";
import { formatDateDateMonthYear } from "../../utils/utilities/DateConverter";

function InboxNewsRoomsCard() {
    const { mediaNewsRoomsArticleData } = useAppSelector(state => state.hotel)
    const futureOfTravelData: [any] = mediaNewsRoomsArticleData?.futureOfTravel ? mediaNewsRoomsArticleData?.futureOfTravel : [];
    const latestNewsData: [any] = mediaNewsRoomsArticleData?.latestNews ? mediaNewsRoomsArticleData?.latestNews : [];
    const newsArticles = mediaNewsRoomsArticleData?.newsArticles ? mediaNewsRoomsArticleData?.newsArticles[0] : [];
    const host = window.location.host;

    return (<>
        <Box className="iGor-inbox_newsrooms">
            <a href={`/explore/231-united-arab-emirates/expo-2020-dubai`} target="_blank">
                <Box className="iGorNews_ImgCard">
                    <img src={newsArticles.image} className="iGorNews_Img" alt="iGorNews_Img" />
                    <Box className="iGorNews_content">
                        <Typography className="iGorNews_Tilte" variant="h1">
                            {newsArticles.title}
                        </Typography>
                        <Typography className="iGorNews_Auther" variant="body1">
                            by. {newsArticles.createdBy}
                        </Typography>
                    </Box>
                </Box>
            </a>
            {latestNewsData.length > 0 ? (<><Typography className="iGorNews_Topic" variant="body2">Latest News</Typography>
                <Box className="iGorNews_letest">
                    <Box className="iGorNews_letestCards">
                        {latestNewsData.length > 0 ? latestNewsData.map((latestNews: any) => {
                            return (
                                <a key={latestNews.title} href={latestNews.link} target="_blank">
                                    <Box className="iGorNews_letCard">
                                        <Box className="iGorNews_letImgs">
                                            <img
                                                src={latestNews.image}
                                                className="iGorNews_letImg"
                                                alt="iGorNews_Img" />
                                        </Box>
                                        <Box className="iGorNews_letContent">
                                            <Box>
                                                <Typography className="iGorNews_letTitle">{latestNews.title}</Typography>
                                                <Typography className="iGorNews_letAuther">by. {latestNews.createdBy}</Typography>
                                            </Box>
                                            <Typography className="iGorNews_letDate">{formatDateDateMonthYear(latestNews.publishDate)}</Typography>
                                        </Box>
                                    </Box>
                                </a>)
                        })
                            : (<></>)}
                    </Box>
                </Box></>) : (<></>)}
            {futureOfTravelData.length > 0 ?
                (<><Typography className="iGorNews_Topic" variant="body2">The Future of Travel</Typography>
                    {futureOfTravelData.length > 0 ? futureOfTravelData.map((news: any) => {
                        return (<a key={news.title} href={news.link} target="_blank">
                            <Box className="iGorNews_Travelcard">
                                <Box className="iGorNews_ImgTravelCard">
                                    <img src={news.image} className="iGorNews_ImgTravel" alt="iGorNews_Img" />
                                    <Box className="iGorNews_TravelCon">
                                        <Box className="iGorNews_Traveltext">
                                            <Typography className="iGorNews_Traveltitle" variant="h1">
                                                {news.title}
                                            </Typography>
                                            <Typography className="iGorNews_TravelDate" variant="body1">
                                                WRITTEN BY {news.createdBy} • {formatDateDateMonthYear(news.publishDate)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </a>)
                    })
                        : (<></>)
                    }
                </>) : (<></>)}
        </Box>
    </>);
}

export default InboxNewsRoomsCard;