import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../appStore/hooks";
import { useDispatch } from "react-redux";
import { fetchFaviourateFolderList, setFolderSelectedDetails, setShowFaviourateList } from "../../appStore/hotelSlice";
import 'react-toastify/dist/ReactToastify.css';
import { AccountSideMenu } from '../../components/AccountDetails/AccountSideMenu';
import { Container, display } from '@mui/system';
import Header from '../../layouts/Header';
import MakeWishlist from './MakeWishlist';
import FavTripCard from './FavTripCard';
import MyFavouritesList from './MyFaviouritesList';

function MyFavourites() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:1024px)');
    const { userFavouritesFolderList, setUserLoggedIn, getUserDetails, showFaviourateList, folderSelectedDetails } = useAppSelector(state => state.hotel)
    const favouriteDestinationFolderList = userFavouritesFolderList?.data ? userFavouritesFolderList?.data : [];
    const folderId = folderSelectedDetails?.folderId
    const folderNameSelected = folderSelectedDetails?.folderName
    const showFaviouratesListUser = showFaviourateList;
    const loggedInUserData = getUserDetails.data?.userDetail;

    useEffect(() => {
        if (setUserLoggedIn == true) {
            dispatch(fetchFaviourateFolderList(loggedInUserData.id))
        }
    }, [setUserLoggedIn])

    const moveToFolderScreen = () => {
        dispatch(setShowFaviourateList(false))
        dispatch(setFolderSelectedDetails({ folderName: '', folderId: 0 }))
    }

    return (<>
        <Box sx={{ background: { xs: "", sm: "#ffffff" } }} >
            {matches == true ? showFaviouratesListUser == false ? (<Header
                name='iGoRoomHeader iGor__SecondMenuMB'
                currClass='iGor_currColor'
            />) : '' : (<Header
                name='iGoRoomHeader iGor__SecondMenuMB'
                currClass='iGor_currColor'
            />)}
        </Box>
        {setUserLoggedIn ? (<>
            <Container>
                <Grid container spacing={2} marginY={2}>
                    <AccountSideMenu />
                    <Grid item lg={9} sm={12} xs={12}>
                        {showFaviouratesListUser == true && matches == true ? (<Box
                            className='iGor-backToFaviourate_list'
                            onClick={moveToFolderScreen}>
                            <ArrowBackIcon />
                        </Box>) : ''}
                        <Box my={1} className='iGOr_Desk-D-none'>
                            <Typography variant='subtitle1' fontSize={'24px'} textAlign={'center'}>{showFaviouratesListUser == false ? 'Favourites' : folderNameSelected}</Typography>
                        </Box>
                        {showFaviouratesListUser == false ? (<Box sx={{ background: "#fff", padding: '30px', borderRadius: '15px', marginBottom: '10px' }} className='iGOr_mb-D-none'>
                            <Typography variant='subtitle1'>
                                My Favourite
                            </Typography>
                            <Typography variant='body1'>
                                See your favourite trip, destination or article
                            </Typography>
                        </Box>) : (
                            <Box sx={{ background: "#fff", padding: '30px', borderRadius: '15px', marginBottom: '10px', display: 'flex' }} className='iGOr_mb-D-none'>
                                <Box className='iGor-backToFaviourate_list' onClick={moveToFolderScreen}>
                                    <ArrowBackIcon /></Box>
                                <Box>
                                    <Typography variant='subtitle1'>
                                        {folderNameSelected}
                                    </Typography>
                                    <Typography variant='body1'>
                                        Favourites list
                                    </Typography>
                                </Box>
                            </Box>)}

                        <Grid container spacing={1} className='iGOr__MyFavCardlist'>
                            {showFaviouratesListUser == false ?
                                <FavTripCard
                                    folderListData={favouriteDestinationFolderList}
                                />
                                : (<MyFavouritesList />)}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </>) :
            (<MakeWishlist />)}
    </>);
}

export default MyFavourites;