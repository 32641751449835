import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useAppSelector } from "../../appStore/hooks";
import userProfileIcon from '../../assets/icons/user-ProfileIcon.svg';
import { setIsRegisterPopUp, setMainPopupForRegisterUser, setUserRegisterWelcomePopup } from "../../appStore/hotelSlice";
import { useDispatch } from "react-redux";

function UserRegisterWelcome() {
    const dispatch = useDispatch()
    const firstNameRegisteredUser = useAppSelector(state => state.hotel.setRegisteredUserDetails.userDetail?.firstName);
    const lastNameRegisteredUser = useAppSelector(state => state.hotel.setRegisteredUserDetails.userDetail?.LastName);
    const closePopup = () => {
        dispatch(setUserRegisterWelcomePopup(false))
        dispatch(setMainPopupForRegisterUser(false))
    }
    return (<>
        {firstNameRegisteredUser !== '' && lastNameRegisteredUser !== '' ? (<>
            <Box className="iGor-Text-center iGor_userProfileBox" sx={{ padding: '24px' }}>
                <Box className='iGor_userProfile'>
                    <img src={userProfileIcon} alt='userProfileIcon' />
                </Box>
                <Typography className="iGor__userName">{firstNameRegisteredUser} {lastNameRegisteredUser}</Typography>
                <Typography variant='h1' className="iGor__userInfo">Welcome to Igoroom </Typography>
                <Typography variant='h1' className="iGor__userInfo"> {firstNameRegisteredUser} {lastNameRegisteredUser}!</Typography>
                <Typography variant='caption' className="iGor__userMsg">Explore your favourite trip in a different way. Discover <br />thousand accomodations, destinations and so much more <br /> with one platform.</Typography>
                <Button type="submit" variant="contained" className="iGor__closeBtn" onClick={closePopup}>
                    Explore Now
                </Button>
            </Box>

        </>) : ('')
        }
    </>)
}

export default UserRegisterWelcome;