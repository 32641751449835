import { environmentVariables } from "../../utils/config/environmentVariables";
import { env } from "../../utils/config/appConfig";
import { api } from "../hotelBookingApi";

const accountId: any = environmentVariables.accountIdApp;
const channelId: any = environmentVariables.channelIdApp;

export const HotelApi = api.injectEndpoints({
  endpoints: build => ({
    // coutryCallingCodes: build.query<any, any>({
    //   providesTags: ['hotel'],
    //   query: () => 'api/UserAccount/getCountryData'
    // }),
    // getCurrencies: build.query<any, any>({
    //   query: () => 'api/UserAccount/getCurrencyData'
    // }),
    // getPopularDestinationsForHotels: build.query<any, any>({
    //   query: () => 'api/hotels/getPopularHotel'
    // }),
    sendOTPPhone: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: { ...payload },
        url: 'api/UserAccount/sendOTP',
        method: 'POST'
      })
    }),
    verifyOTPForPhone: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: { ...payload },
        url: 'api/UserAccount/verifyOTP',
        method: 'POST'
      })
    }),
    registerUser: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.userRegisterDetails
        },
        url: 'api/UserAccount/signupandregister',
        method: 'POST'
      })
    }),
    forgotPasswordUser: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.emailData
        },
        url: 'api/UserAccount/Resetpassword',
        method: 'POST'
      })
    }),
    // destinationSearch: build.query<any, any>({
    //   providesTags: ['hotel'],
    //   query: () => 'api/UserAccount/getDestinationData'
    // }),
    initApi: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.initDatassss
        },
        headers: {
          AccountId: accountId
        },
        url: 'api/hotels/search/init',
        method: 'POST'
      }),
    }),
    applyFiltersHotelsResult: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.appliedFilters
        },
        headers: {
          AccountId: accountId
        },
        url: `/api/hotels/applyFilter/${payload.searchId}?limit=200&offset=1`,
        method: 'POST'
      }),
    }),
    bookHotel: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.hotelBookingDetails
        },
        headers: {
          AccountId: accountId
        },
        url: `api/Booking/${payload.priceId}/book`,
        method: 'POST'
      }),
    }),
    aboutUs: build.query<any, any>({
      providesTags: ['hotel'],
      query: () => 'api/hotels/aboutUs'
    }),
    privacyPolicies: build.query<any, any>({
      providesTags: ['hotel'],
      query: () => 'api/hotels/getPrivacyPolicy'
    }),
    termsConditions: build.query<any, any>({
      providesTags: ['hotel'],
      query: () => 'api/hotels/getTermsAndConditions'
    }),
    updateUserProfile: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.userDetails
        },
        url: `/api/UserAccount/updateuserprofile/${payload.userRegisterMail}?IdentifierType=Email`,
        method: 'POST'
      }),
    }),
    updateUserCurrency: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.userSelectedCurrency
        },
        url: `/api/UserAccount/updateuserpreference/${payload.userEmail}?IdentifierType=Email`,
        method: 'POST'
      }),
    }),
    cretateWishlistFolder: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.userDetails
        },
        url: `api/hotels/createfavouritefolder`,
        method: 'POST'
      }),
    }),
    updateWishlistHotels: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.userWishlistHotel
        },
        url: `api/hotels/addtofavourite`,
        method: 'POST'
      }),
    }),
    cancleUserBooking: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.resonDetails
        },
        headers: {
          AccountId: accountId,
          ChannelId: channelId
        },
        url: `api/Booking/booking/${payload.bookingId}/cancel/${payload.mail}`,
        method: 'POST'
      }),
    }),
    getUserNotificatiobs: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.userDetails
        },
        url: `api/notification/sendNotification/get`,
        method: 'POST'
      }),
    }),
    updateUserNotifications: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload
        },
        url: `api/notification/updateNotification/${payload.notificationId}`,
        method: 'POST'
      }),
    }),
    bookOfferHotel: build.mutation<any, any>({
      invalidatesTags: ['hotel'],
      query: ({ payload }) => ({
        body: {
          ...payload.offerBookingDetails
        },
        url: `api/Booking/${payload.searchId}/bookoffer`,
        method: 'POST'
      })
    })
  }),

  overrideExisting: false,
})

export const { useRegisterUserMutation, useForgotPasswordUserMutation, useSendOTPPhoneMutation, useVerifyOTPForPhoneMutation,
  useInitApiMutation, useApplyFiltersHotelsResultMutation, useBookHotelMutation, useAboutUsQuery, usePrivacyPoliciesQuery,
  useTermsConditionsQuery, useUpdateUserProfileMutation, useUpdateUserCurrencyMutation, useUpdateWishlistHotelsMutation,
  useCancleUserBookingMutation, useGetUserNotificatiobsMutation, useUpdateUserNotificationsMutation,
  useCretateWishlistFolderMutation, useBookOfferHotelMutation } = HotelApi;