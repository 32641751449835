import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { EnglishMessages } from "../messages/en-us-Messages";
import { FrenchMessages } from "../messages/fr-da-Messages";
import { inputFieldErrorMessages } from "../messages/inputFieldErrorMessages";
import { toastMessages } from "../messages/toastMessages";

i18n.use(initReactI18next).init({
    lng: "en",
    resources: {
        en: {
            translation: {
                ...EnglishMessages,
                ...inputFieldErrorMessages,
                ...toastMessages
            }
        },
        fr: {
            translation: {
                ...FrenchMessages
            }
        }
    },
    keySeparator: false,
    interpolation: { escapeValue: false }
});

export default i18n;