import React, { useState } from 'react';
import CountryFlag from 'react-country-flag';
import SyncProblem from '@mui/icons-material/SyncProblem';

const CountryCurrencyFlags = ({ currencyCode }: any) => {
    const countryCode = currencyCode.substring(0, 2).toUpperCase();
    const [flagError, setFlagError] = useState(false);

    const handleFlagError = () => {
        setFlagError(true);
    };

    return (
        <div
            style={{
                borderRadius: '100%',
                overflow: 'hidden',
                width: '15px',
                height: '15px',
                marginRight: '5px',
                display:'flex'
            }}
        >
            {flagError ? (
                <SyncProblem />
            ) : (
                <CountryFlag
                    countryCode={countryCode}
                    svg
                    className="currency-flag"
                    onError={handleFlagError}
                   // Adjust the size of the CountryFlag

                />
            )}
        </div>
    );
};

export default CountryCurrencyFlags;