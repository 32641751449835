import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { HotelApi } from '../services/api/hotelApi';
import { asyncThunkApiEndPoints, asyncThunkApiEndPointsWithParams, orderSummarySectionType } from '../utils/constants/constant';
import axios from 'axios';
import { environmentVariables } from '../utils/config/environmentVariables';
import { getThreeMonthsLaterDate } from '../utils/utilities/DateConverter';
const baseUrl: any = environmentVariables.appApiBaseUrl;
const AccountId: any = environmentVariables.accountIdApp;
const ChannelId: any = environmentVariables.channelIdApp;
const endPointPopularHotels = asyncThunkApiEndPoints.getPopularHotels;
const endPointDestinationSearch = asyncThunkApiEndPoints.getDestinationsForHotels;
const endPointCountryCodes = asyncThunkApiEndPoints.getCountryCallingCodes;
const endPointContryCurrency = asyncThunkApiEndPoints.getCurrencies;
const endPointGetResonsCancleBooking = asyncThunkApiEndPoints.getResonsForBookingCancle;
const endPointGetMediaNewsArticles = asyncThunkApiEndPoints.getMediaNewsArticles;
const endPointExploreDestinations = asyncThunkApiEndPointsWithParams.exploreDestinations;
const endPointExploreHotelsToStay = asyncThunkApiEndPointsWithParams.exploreHotelsToStay;
const endPointExploreThingToDo = asyncThunkApiEndPointsWithParams.exploreThingsTodo;
const endPointExploreRegion = asyncThunkApiEndPointsWithParams.exploreRegions;
const endpointGetUserWishlist = asyncThunkApiEndPointsWithParams.getUserWishlit;
const endpointGetMyTrips = asyncThunkApiEndPointsWithParams.getUserTrips;
const endpointGetMyTripDetails = asyncThunkApiEndPointsWithParams.getUserTripDetails;
const endpointGetHotelsToExploreOffers = asyncThunkApiEndPointsWithParams.getHotelsToExploreOffers;
const endpointGetCancellationPolicies = asyncThunkApiEndPointsWithParams.getCancellationPolicies;
const endpointGetPaypalCurrencies = asyncThunkApiEndPointsWithParams.getPaypalCurrencies;
const endpointGetHotelDetailsSlug = asyncThunkApiEndPointsWithParams.getHotelDetailsUsingSlug;
const endPointGetFaviouratesFolderList = asyncThunkApiEndPointsWithParams.getFaviouratesFloderList;
const endPointGetFaviouratesFolderDetails = asyncThunkApiEndPointsWithParams.getFaviourateFolderDetails;
const endPointRemoveFaviourates = asyncThunkApiEndPointsWithParams.removeFromFaviourates;
const endpointDownloadBookingPDF = asyncThunkApiEndPointsWithParams.donwloadBookingPDF;
const endpointOfferHotelsResult = asyncThunkApiEndPointsWithParams.offerHotelsResults;
const endpointGetBookedOffersList = asyncThunkApiEndPointsWithParams.getBookedOfferList;
const endpointGetBookedOfferDetails = asyncThunkApiEndPointsWithParams.getBookedOfferDetails;

const orderSummarySectionDefault = orderSummarySectionType.orderSummary;
const environment = environmentVariables.enviornment;
const headers = {
    AccountId: AccountId
}

const todaysDate = new Date().toISOString();
const tommorowsDate = new Date((new Date()).setDate((new Date()).getDate() + 1)).toISOString();
const threeMonthsLaterTodaysDate = getThreeMonthsLaterDate(todaysDate);
const threeMonthsLaterTommorowsDate = getThreeMonthsLaterDate(tommorowsDate);
interface InitialState {
    appLanguage: string;
    showDate: boolean,
    roomArray: any;
    setUserLoggedIn: boolean;
    mainPopupForRegisterUser: boolean;
    setRegisterPopUp: boolean;
    verificationPopup: boolean,
    gmailVerificationPopup: boolean,
    gateWayAndHolidayMakerPopup: boolean,
    userRegisterWelcomePopup: boolean,
    sentOTPForPhoneData: any,
    verifyOTPForPhoneData: any,
    socialMediaAuthenticationUserData: any;
    emailAuthenticationUserData: any,
    authenticationType: string,
    countryCallingCodes: any,
    countryCurrencies: any,
    countryCurrenciesPaypal: {
        data: any,
        loading: boolean,
        error: any
    },
    destinationForSearch: any,
    destinationsData: any,
    getUIFiltersData: any,
    setPropertyTypeSelectedFilters: any,
    setRoomTypeSelectedFilters: any,
    setPriceRangeSelectedFilters: any,
    setMobilePropertyTypeSelectedFilters: any,
    setMobileRoomTypeSelectedFilters: any,
    selectedHotelsTypeTab: string,
    initApi: any,
    searchHotelsResult: boolean;
    hotels: any;
    apiKey: string;
    user_session_key: string;
    searchTracingKey: string;
    searchId: string;
    getUserEmailExist: any;
    getUserDetails: any;
    hotelDetails: any;
    userLoginEmailAddress: string;
    userLoginPassword: string;
    signInCall: boolean,
    registerUserStatus: boolean,
    firstNameUser: string;
    lastNameUser: string;
    userRegisterEmailAddress: string;
    userRegisterPassword: string;
    userPhoneNumber: string;
    userBirthDate: string;
    registerUserCountryCall: any;
    countryId: number,
    registerUserCurrencyData: any;
    setRegisteredUserDetails: any
    forgotPasswordUserDetails: any,
    searchDestinationText: string,
    searchDestinationFromDate: any
    searchDestinationToDate: any;
    adultsCount: number;
    childrenCount: number;
    locationId: any;
    hotelResults: any,
    offerHotelResults: any
    maxDistanceHotel: any,
    minDistanceHotel: any,
    HotelNameAutoSuggest: any,
    lowestPrice: any,
    highestPrice: any,
    facilityId: any,
    noOfNights: number,
    enterPassword: boolean,
    inputValidatorRegisterUser: boolean,
    hotelRoomDetails: any,
    selectedHotelVrImageAndData: any,
    hotelIdSelected: number,
    roomRecommendationSelected: any,
    roomRecommendationIndexSelected: number,
    roomTypeSelectedForCheckout: any,
    roomIndexSelectedForCheckout: number,
    guestDetailsAtCheckIn: any,
    passengerDetailsForCheckoutHotel: any
    hotelRoomPriceApiData: any,
    screenTypeSeleted: string,
    hotelVrImageCheckedHotel: string,
    getPopularDestinationsForHotels: any,
    clientSecreatId: any,
    bookingApiData: any,
    aboutUSData: any,
    privacyPoliciesData: any,
    TermsAndConditions: any,
    userDOBForUpdate: string,
    loggedInUserCurrency: any,
    exploreDestinationsApiData: any,
    exploreHotelsToStay: {
        data: any,
        loading: boolean,
        error: any
    },
    exploreThingToDoSelected: {
        data: any,
        loading: boolean,
        error: any
    },
    exploreRegionSelected: {
        data: any,
        loading: boolean,
        error: any
    },
    createUserFaviourateFolder: any,
    addTofavouriteList: any,
    userFavouritesFolderList: any,
    userBookingList: any,
    userFaviouratesFolderDetails: any,
    userRemoveFromFaviourates: any,
    userBookingDetails: any,
    webFilterEnable: boolean,
    mobileFilterEnable: boolean,
    orderSummerySection: string,
    getHotelsToExploreOffers: {
        data: any,
        loading: boolean,
        error: any
    },
    allTypeOfLatestOffersAndHotels: any
    getResonsForBookingCancle: any,
    mediaNewsRoomsArticleData: any,
    getCancellationPoliciesData: {
        data: any,
        loading: boolean,
        error: any
    },
    cancleBookingData: any,
    userNotificationsAndMessg: any,
    selectedCurrencySymboll: string,
    previousPathName: string,
    errorMessageForDialogue: string,
    openAlertBoxOnError: boolean,
    NoOfHitsResultApi: number,
    sessionExpired: boolean,
    loadHotelResultSkeleton: boolean,
    path: any,
    exploreDestinationPopup: any,
    sentOTPTimer: any,
    allowMoeNotifications: any,
    userActionLoader: any,
    donwloadBookingPDFData: any,
    showFaviourateList: any,
    folderSelectedDetails: any,
    bookOfferHotelData: any,
    bookedOffersList: any,
    bookedOfferDetails: any
}

const initialState: InitialState = {
    appLanguage: 'en',
    setUserLoggedIn: false,
    showDate: false,
    roomArray: [{
        adults: 2,
        children: 0,
        childAges: [],
    }],
    mainPopupForRegisterUser: false,
    setRegisterPopUp: false,
    verificationPopup: false,
    gmailVerificationPopup: false,
    gateWayAndHolidayMakerPopup: false,
    userRegisterWelcomePopup: false,
    sentOTPForPhoneData: { message: '', otpResponse: {}, statusCode: '' },
    verifyOTPForPhoneData: { message: '', otpResponse: {}, statusCode: '' },
    socialMediaAuthenticationUserData: {},
    emailAuthenticationUserData: [''],
    authenticationType: '',
    countryCallingCodes: { status: '', message: '', countries: [] },
    countryCurrencies: { status: '', message: '', currencies: [] },
    countryCurrenciesPaypal: { data: [], loading: false, error: null },
    destinationForSearch: { status: '', message: '', destinations: [] },
    destinationsData: { destinationName: '', destinationCode: '' },
    getUIFiltersData: { data: [], loading: false, error: null },
    setPropertyTypeSelectedFilters: [],
    setRoomTypeSelectedFilters: [],
    setPriceRangeSelectedFilters: { min_price: 0, max_price: 0 },
    setMobilePropertyTypeSelectedFilters: [],
    setMobileRoomTypeSelectedFilters: [],
    selectedHotelsTypeTab: 'ALL',
    initApi: { status: '', searchId: '', searchTracingKey: '' },
    searchHotelsResult: false,
    hotels: [],
    apiKey: '',
    user_session_key: '',
    searchTracingKey: '',
    searchId: '',
    userLoginEmailAddress: '',
    userLoginPassword: '',
    getUserEmailExist: {
        data: [''],
        loading: false,
        error: null,
    },
    hotelDetails: {
        data: [],
        loading: false,
        error: null,
    },
    hotelRoomDetails: {
        data: [],
        loading: false,
        error: null,
    },
    getUserDetails: {
        data: [''],
        loading: false,
        error: null,
    },
    hotelResults: {
        data: [''],
        loading: false,
        error: null,
    },
    offerHotelResults: {
        data: [],
        loading: false,
        error: null,
    },
    signInCall: false,
    registerUserStatus: false,
    firstNameUser: '',
    lastNameUser: '',
    userRegisterEmailAddress: '',
    userRegisterPassword: '',
    userPhoneNumber: '',
    userBirthDate: '',
    registerUserCountryCall: {
        countryCallingId: '',
        countryId: 0,
        countryCallCodeLabel: ''
    },
    countryId: 101,
    registerUserCurrencyData: { currencyId: 0, currencyCode: '' },
    setRegisteredUserDetails: { status: '', message: '', userDetail: [] },
    forgotPasswordUserDetails: { status: '', message: '' },
    searchDestinationText: '',
    searchDestinationFromDate: environment == 'PROD' ? todaysDate : threeMonthsLaterTodaysDate,
    searchDestinationToDate: environment == 'PROD' ? tommorowsDate : threeMonthsLaterTommorowsDate,
    adultsCount: 0,
    childrenCount: 0,
    locationId: '',
    minDistanceHotel: 0,
    maxDistanceHotel: 20,
    HotelNameAutoSuggest: [],
    lowestPrice: 0,
    highestPrice: 0,
    facilityId: [],
    noOfNights: 1,
    enterPassword: false,
    inputValidatorRegisterUser: false,
    selectedHotelVrImageAndData: {},
    hotelIdSelected: 0,
    roomRecommendationSelected: {},
    roomRecommendationIndexSelected: 0,
    roomTypeSelectedForCheckout: {},
    roomIndexSelectedForCheckout: 0,
    guestDetailsAtCheckIn: [],
    passengerDetailsForCheckoutHotel: [],
    hotelRoomPriceApiData: {
        data: [],
        loading: false,
        error: null
    },
    screenTypeSeleted: '',
    hotelVrImageCheckedHotel: '',
    getPopularDestinationsForHotels: { success: false, popular: [] },
    clientSecreatId: '',
    bookingApiData: {},
    aboutUSData: '',
    privacyPoliciesData: '',
    TermsAndConditions: '',
    userDOBForUpdate: '',
    loggedInUserCurrency: {},
    exploreDestinationsApiData: {
        data: [],
        loading: false,
        error: null
    },
    exploreHotelsToStay: {
        data: [],
        loading: false,
        error: null
    },
    exploreThingToDoSelected: {
        data: [],
        loading: false,
        error: null
    },
    exploreRegionSelected: {
        data: [],
        loading: false,
        error: null
    },
    createUserFaviourateFolder: {
        status: false,
        message: "",
        data: []
    },
    addTofavouriteList: {
        status: false,
        message: ''
    },
    userFavouritesFolderList: {
        data: [],
        loading: false,
        error: null
    },
    userFaviouratesFolderDetails: {
        data: [],
        loading: false,
        error: null
    },
    userRemoveFromFaviourates: {
        data: [],
        loading: false,
        error: null
    },
    userBookingList: {
        data: [],
        loading: false,
        error: null
    },
    userBookingDetails: {
        data: [],
        loading: false,
        error: null
    },
    webFilterEnable: false,
    mobileFilterEnable: false,
    orderSummerySection: orderSummarySectionDefault,
    getHotelsToExploreOffers: {
        data: [],
        loading: false,
        error: null
    },
    allTypeOfLatestOffersAndHotels: [],
    getResonsForBookingCancle: { status: '', response: [] },
    mediaNewsRoomsArticleData: {},
    getCancellationPoliciesData: {
        data: [],
        loading: false,
        error: null
    },
    cancleBookingData: { status: '', response: {}, message: '' },
    userNotificationsAndMessg: {},
    selectedCurrencySymboll: '',
    previousPathName: '',
    errorMessageForDialogue: '',
    openAlertBoxOnError: false,
    NoOfHitsResultApi: 0,
    sessionExpired: false,
    loadHotelResultSkeleton: false,
    path: null,
    exploreDestinationPopup: true,
    sentOTPTimer: {
        initialMinutes: 0,
        initialSeconds: 0
    },
    allowMoeNotifications: null,
    userActionLoader: {
        action: '',
        loading: false
    },
    donwloadBookingPDFData: {
        status: false,
        message: '',
        data: ''
    },
    showFaviourateList: false,
    folderSelectedDetails: {
        folderName: '',
        folderId: 0
    },
    bookOfferHotelData: {
        status: '',
        message: ''
    },
    bookedOffersList: {
        data: [],
        loading: false,
        error: null
    },
    bookedOfferDetails: {
        data: [],
        loading: false,
        error: null
    }
};

// Async thunk to fetch data from the user email exist API with a single parameter in the query
export const getUserEmailExistApi: any = createAsyncThunk(
    'data/getUserExist',
    async (emailIdUser: any, { rejectWithValue }) => {
        try {
            const apiUrl = baseUrl + `api/UserAccount/status/`;
            const urlWithParams: any = `${apiUrl}${encodeURIComponent(emailIdUser)}?IdentifierType=Email`;

            const response = await fetch(urlWithParams);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        }
        catch (error) {
            return rejectWithValue(error);
        }
    })

// Async thunk to fetch data from the user Details API with a single parameter in the query
export const getUserDetailsApi: any = createAsyncThunk(
    'data/getUserDetails',
    async (emailId: any, { rejectWithValue }) => {
        try {
            const apiUrl = baseUrl + 'api/UserAccount/getUserDetails/';
            const urlWithParams: any = `${apiUrl}${encodeURIComponent(emailId)}?IdentifierType=Email`;

            const response = await fetch(urlWithParams);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        }
        catch (error) {
            return rejectWithValue(error);
        }
    }
);

// Async thunk to fetch data from the hotels result API with a single parameter in the query
export let fetchHotesResultsDataFromApi: any = () => { };
if (environment == 'development') {
    fetchHotesResultsDataFromApi = createAsyncThunk(
        'data/fetchDataHotelsResults',
        async ({ searchId, numberOfHitsResultApi }: any, { rejectWithValue }) => {
            try {
                const apiUrl = baseUrl + 'api/hotels/search/availabilityResult/V2/';
                // Complete URL with the single parameter in the query
                const urlWithParams: any = `${apiUrl}${encodeURIComponent(searchId)}?limit=200&offset=1&NoOfHits=${numberOfHitsResultApi}`;
                const headers: any = {
                    AccountId: AccountId
                }
                // Fetch configuration object
                const fetchConfig = {
                    method: 'GET', // Specify the HTTP method (GET, POST, etc.)
                    headers: headers, // Pass the headers object
                };
                const response = await fetch(urlWithParams, fetchConfig);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                return data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    );

}
else {
    fetchHotesResultsDataFromApi = createAsyncThunk(
        'data/fetchDataHotelsResults',
        async ({ searchId, numberOfHitsResultApi }: any, { rejectWithValue }) => {
            try {
                const apiUrl = baseUrl + 'api/hotels/search/availabilityResult/';
                // Complete URL with the single parameter in the query
                const urlWithParams: any = `${apiUrl}${encodeURIComponent(searchId)}?limit=200&offset=1&NoOfHits=${numberOfHitsResultApi}`;
                const headers: any = {
                    AccountId: AccountId
                }
                // Fetch configuration object
                const fetchConfig = {
                    method: 'GET', // Specify the HTTP method (GET, POST, etc.)
                    headers: headers, // Pass the headers object
                };
                const response = await fetch(urlWithParams, fetchConfig);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                return data;
            } catch (error) {
                return rejectWithValue(error);
            }
        }
    );
}

export const fetchHotelsRoomsDataFromApi: any = createAsyncThunk(
    'data/fetchHotesRoomsDataFromApi',
    async ({ searchId, hotelId }: any, { rejectWithValue }) => {
        try {
            const apiUrl = baseUrl + 'api/hotels/search/result/';
            // Complete URL with the single parameter in the query
            const urlWithParams: any = `${apiUrl}${encodeURIComponent(searchId)}/${encodeURIComponent(hotelId)}/rooms`;
            const headers: any = {
                AccountId: AccountId
            }
            // Fetch configuration object
            const fetchConfig = {
                method: 'GET', // Specify the HTTP method (GET, POST, etc.)
                headers: headers, // Pass the headers object
            };
            const response = await fetch(urlWithParams, fetchConfig);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchHotelDetailsFromApi: any = createAsyncThunk(
    'data/fetchHotelDetails',
    async ({ searchId, hotelId }: any, { rejectWithValue }) => {
        try {
            const apiUrl = baseUrl + 'api/hotels/';
            // Complete URL with the single parameter in the query
            const urlWithParams: any = `${apiUrl}${encodeURIComponent(searchId)}/${encodeURIComponent(hotelId)}/detail`;
            const headers: any = {
                AccountId: AccountId
            }
            // Fetch configuration object
            const fetchConfig = {
                method: 'GET', // Specify the HTTP method (GET, POST, etc.)
                headers: headers, // Pass the headers object
            };
            const response: any = await fetch(urlWithParams, fetchConfig);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


export const fetchHotelsRoomPriceDataFromApi: any = createAsyncThunk(
    'data/fetchHotesRoomPrciceDataFromApi',
    async ({ searchId, providerName, priceId, hotelId }: any, { rejectWithValue }) => {
        try {
            const apiUrl = baseUrl + 'api/hotels/search/';
            // Complete URL with the single parameter in the query
            const urlWithParams: any = `${apiUrl}${encodeURIComponent(searchId)}/${encodeURIComponent(hotelId)}/price/${encodeURIComponent(providerName)}/${encodeURIComponent(priceId)}`;
            const headers: any = {
                AccountId: AccountId,
                ChannelId: ChannelId
            }
            // Fetch configuration object
            const fetchConfig = {
                method: 'GET', // Specify the HTTP method (GET, POST, etc.)
                headers: headers, // Pass the headers object
            };
            const response = await fetch(urlWithParams, fetchConfig);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);


// Async thunk to fetch data from the hotels result API with a single parameter in the query
export const fetchGetFiltersDataFromApi: any = createAsyncThunk(
    'data/fetchGetFilters',
    async (searchId: any, { rejectWithValue }) => {
        try {
            const apiUrl = baseUrl + 'api/hotels/getfilter/';
            // Complete URL with the single parameter in the query
            const urlWithParams: any = `${apiUrl}${encodeURIComponent(searchId)}`;
            const headers: any = {
                AccountId: AccountId
            }
            // Fetch configuration object
            const fetchConfig = {
                method: 'GET', // Specify the HTTP method (GET, POST, etc.)
                headers: headers, // Pass the headers object
            };
            const response = await fetch(urlWithParams, fetchConfig);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

// function for create common async thunk for get parametrize api on name configuration     
const createAsyncThunkForGetApiWithParams: any = (name: any, apiUrl: any, requestParams: any) => {
    return createAsyncThunk(name, async (payload: any, { rejectWithValue }) => {
        try {
            const urlWithParams = apiUrl + requestParams(payload);
            const headers: any = {
                AccountId: AccountId,
                ChannelId: ChannelId
            };
            const fetchConfig = {
                method: 'GET',
                headers,
            };
            const response = await fetch(urlWithParams, fetchConfig);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error);
        }
    });
};

// explore api aync thunk
export const fetchExploreDestinationsApi: any = createAsyncThunkForGetApiWithParams(
    'data/getExploreDestinationDetails',
    baseUrl + endPointExploreDestinations,
    (exploreDestination: any) => `/${encodeURIComponent(exploreDestination.destinationId)}/${encodeURIComponent(exploreDestination.userId)}`
);

// explore destination places to stay
export const fetchExploreHotelsToStay: any = createAsyncThunkForGetApiWithParams(
    'data/getExploreHotelsToStay',
    baseUrl + endPointExploreHotelsToStay,
    (destinationId: any) => `/${encodeURIComponent(destinationId)}`
);

export const fetchExploreThingTodo: any = createAsyncThunkForGetApiWithParams(
    'data/getExploreThingToDo',
    baseUrl + endPointExploreThingToDo,
    (slug: any) => `/${encodeURIComponent(slug.userId)}/${encodeURIComponent(slug.slugId)}`
);

export const fetchExploreRegion: any = createAsyncThunkForGetApiWithParams(
    'data/getExploreRegion',
    baseUrl + endPointExploreRegion,
    (slug: any) => `/${encodeURIComponent(slug)}`
);


// get wishkist api aync thunk
// export const fetchGetWishlistApi: any = createAsyncThunkForGetApiWithParams(
//     'data/getUserWishList',
//     baseUrl + endpointGetUserWishlist,
//     (userId: any) => `?userId=${encodeURIComponent(userId)}`
// );

// get wishlist folder list api aync thunk
export const fetchFaviourateFolderList: any = createAsyncThunkForGetApiWithParams(
    'data/getFaviourateFolderList',
    baseUrl + endPointGetFaviouratesFolderList,
    (userId: any) => `/${encodeURIComponent(userId)}`
);

// get wishlist folder  detail api aync thunk
export const fetchFaviourateFolderDetails: any = createAsyncThunkForGetApiWithParams(
    'data/getFaviourateFolderDetails',
    baseUrl + endPointGetFaviouratesFolderDetails,
    (data: any) => `/${encodeURIComponent(data.userId)}/${encodeURIComponent(data.folderId)}`
);

// remove wishlist api aync thunk
export const fetchRemoveFromFaviourates: any = createAsyncThunkForGetApiWithParams(
    'data/removeFromFaviourates',
    baseUrl + endPointRemoveFaviourates,
    (data: any) => `/${encodeURIComponent(data.userId)}/${encodeURIComponent(data.faviourateId)}/${encodeURIComponent(data.type)}`
);

// get my trips api aync thunk
export const fetchUserTripsApi: any = createAsyncThunkForGetApiWithParams(
    'data/getMytrips',
    baseUrl + endpointGetMyTrips,
    (userId: any) => `/${encodeURIComponent(userId)}`
);

// get my trips detils api aync thunk
export const fetchUserBookingDetailsApi: any = createAsyncThunkForGetApiWithParams(
    'data/getMytripDetails',
    baseUrl + endpointGetMyTripDetails,
    (bookingId: any) => `/${encodeURIComponent(bookingId)}`
);

export const fetchHotelsToExploreOffersApi: any = createAsyncThunkForGetApiWithParams(
    'data/getHotelToExploreOffers',
    baseUrl + endpointGetHotelsToExploreOffers,
    (bookingId: any) => ``
);

export const fetchCancellationPolicies: any = createAsyncThunkForGetApiWithParams(
    'data/getCancellationPolicies',
    baseUrl + endpointGetCancellationPolicies,
    (bookingId: any) => `/${encodeURIComponent(bookingId)}/cancellationpolicy`
);

export const fetchPaypalCurrencies: any = createAsyncThunkForGetApiWithParams(
    'data/getPaypalCurrencies',
    baseUrl + endpointGetPaypalCurrencies,
    (paymentMethod: any) => `?paymentType=${encodeURIComponent(paymentMethod)}`
);

export const fetchHotelDetailsUsingSlug: any = createAsyncThunkForGetApiWithParams(
    'data/getHotelDetailsUsingSlug',
    baseUrl + endpointGetHotelDetailsSlug,
    (slug: any) => `/${encodeURIComponent(slug)}/detail`,
);

export const fetchDownloadBookingDetails: any = createAsyncThunkForGetApiWithParams(
    'data/downloadBookingDetails',
    baseUrl + endpointDownloadBookingPDF,
    (bookingId: any) => `/${encodeURIComponent(bookingId)}`,
);

export const fetchOfferHotelsResult: any = createAsyncThunkForGetApiWithParams(
    'data/offerHotelsResult',
    baseUrl + endpointOfferHotelsResult,
    (searchId: any) => `/${encodeURIComponent(searchId)}`,
);

export const getBookedOffersList: any = createAsyncThunkForGetApiWithParams(
    'data/getBookedOffersList',
    baseUrl + endpointGetBookedOffersList,
    (userId: any) => `/${encodeURIComponent(userId)}`,
);

export const getBookedOfferDetails: any = createAsyncThunkForGetApiWithParams(
    'data/getBookedOfferDetails',
    baseUrl + endpointGetBookedOfferDetails,
    (bookingId: any) => `/${encodeURIComponent(bookingId)}`,
);

// async thunk for api without parameters get apis
export const fetchDataAsyncStaticApi: any = createAsyncThunk(
    'hotels/fetchDataStaticApis',
    async (endpoint: any) => {
        const response = await fetch(`${baseUrl}${endpoint}`);
        return response.json();
    }
);

export const hotelSlice = createSlice({
    name: 'hotels',
    initialState: initialState,
    reducers: {
        resetState: () => initialState,
        navigateTo: (state, action) => {
            state.path = action.payload;
        },
        setAppLanguage: (state, action: PayloadAction<string>) => {
            state.appLanguage = action.payload;
        },
        setShowDate: (state, action: PayloadAction<boolean>) => {
            state.showDate = action.payload

        },
        setIsLogedInUser: (state, action: PayloadAction<any>) => {
            state.setUserLoggedIn = action.payload;
        },
        setRoomArray: (state, action: PayloadAction<any>) => {
            state.roomArray = action.payload;
        },
        setResetRoomArray: (state) => {
            state.roomArray = [{
                adults: 2,
                children: 0,
                childAges: []
            }]
        },
        setRemoveRoomArray: (state, action: PayloadAction<any>) => {
            let arr = [...state.roomArray]
            arr.splice(action.payload, 1)
            state.roomArray = arr
        },
        setMainPopupForRegisterUser: (state, action: PayloadAction<boolean>) => {
            state.mainPopupForRegisterUser = action.payload;
        },
        setIsRegisterPopUp: (state, action: PayloadAction<any>) => {
            state.setRegisterPopUp = action.payload;
        },
        setIsVerificationPopUp: (state, action: PayloadAction<any>) => {
            state.verificationPopup = action.payload;
        },
        setGmailVerificationPopUp: (state, action: PayloadAction<boolean>) => {
            state.gmailVerificationPopup = action.payload;
        },
        setGateWaysAndHolidayMakerPopup: (state, action: PayloadAction<boolean>) => {
            state.gateWayAndHolidayMakerPopup = action.payload;
        },
        setUserRegisterWelcomePopup: (state, action: PayloadAction<boolean>) => {
            state.userRegisterWelcomePopup = action.payload;
        },
        setAuthenticationType: (state, action: PayloadAction<any>) => {
            state.authenticationType = action.payload
        },
        setEmailAuthenticationUserData: (state, action: PayloadAction<any>) => {
            state.emailAuthenticationUserData = action.payload
        },
        setSocialMediaAuthenticationUserData: (state, action: PayloadAction<any>) => {
            state.socialMediaAuthenticationUserData = action.payload
        },
        setLoginUserEmailAddress: (state, action: PayloadAction<string>) => {
            state.userLoginEmailAddress = action.payload;
        },
        setUserLoginPassword: (state, action: PayloadAction<string>) => {
            state.userLoginPassword = action.payload;
        },
        setSignInCallApi: (state, action: PayloadAction<boolean>) => {
            state.signInCall = action.payload;
        },
        setRegisterUserStatus: (state, action: PayloadAction<boolean>) => {
            state.registerUserStatus = action.payload;
        },
        setFirstNameUser: (state, action: PayloadAction<string>) => {
            state.firstNameUser = action.payload;
        },
        setLastNameUser: (state, action: PayloadAction<string>) => {
            state.lastNameUser = action.payload;
        },
        setRegisterUserEmailAddress: (state, action: PayloadAction<string>) => {
            state.userRegisterEmailAddress = action.payload;
        },
        setRegisterUserPassword: (state, action: PayloadAction<string>) => {
            state.userRegisterPassword = action.payload;
        },
        setCountryCallingIDData: (state, action: PayloadAction<any>) => {
            state.registerUserCountryCall = action.payload;
        },
        setCountryNumCodeForApi: (state, action: PayloadAction<number>) => {
            state.countryId = action.payload;
        },
        setUserPhoneNumber: (state, action: PayloadAction<string>) => {
            state.userPhoneNumber = action.payload;
        },
        setUserBirthDate: (state, action: PayloadAction<string>) => {
            state.userBirthDate = action.payload;
        },
        setRegisterUserCurrencyId: (state, action: PayloadAction<any>) => {
            state.registerUserCurrencyData = action.payload;
        },
        setRegisteredUserDetailsReset: (state, action: PayloadAction<any>) => {
            state.setRegisteredUserDetails = action.payload;
        },
        setGetUserDetailsReset: (state, action: PayloadAction<any>) => {
            state.getUserDetails.data = action.payload;
        },
        setHotelSearchRequestReset: (state, action: PayloadAction<any>) => {
            state.initApi = action.payload;
        },
        setHotelsResultsDataReset: (state, action: PayloadAction<any>) => {
            state.hotelResults = action.payload;
        },
        setOfferHotelsDataReset: (state, action: PayloadAction<any>) => {
            state.offerHotelResults = action.payload;
        },
        setForgotPasswordDataReset: (state, action: PayloadAction<any>) => {
            state.forgotPasswordUserDetails = action.payload
        },
        setSentOTPPhoneDataReset: (state, action: PayloadAction<any>) => {
            state.sentOTPForPhoneData = action.payload
        },
        setVerifyOTPPhoneDataReset: (state, action: PayloadAction<any>) => {
            state.verifyOTPForPhoneData = action.payload
        },
        setExistingUserEmailStatusReset: (state, action: PayloadAction<any>) => {
            state.getUserEmailExist = action.payload;
        },
        setSearchDestinationData: (state, action: PayloadAction<any>) => {
            state.destinationsData = action.payload;
        },
        setSearchDestinationFromDate: (state, action: PayloadAction<any>) => {
            state.searchDestinationFromDate = action.payload;
        },
        setSearchDestinationToDate: (state, action: PayloadAction<any>) => {
            state.searchDestinationToDate = action.payload;
        },
        setLocationId: (state, action: PayloadAction<any>) => {
            state.locationId = action.payload;
        },
        setIncrementNights: (state) => {
            state.noOfNights = state.noOfNights + 1
        },
        setDecrementNights: (state) => {
            if (state.noOfNights > 0)
                state.noOfNights = state.noOfNights - 1
        },
        setNoOfNights: (state, action: PayloadAction<number>) => {
            state.noOfNights = action.payload
        },
        setIncrementAdultCount: (state) => {
            state.adultsCount = state.adultsCount + 1
        },
        setDecrementAdultCount: (state) => {
            if (state.adultsCount > 0)
                state.adultsCount = state.adultsCount - 1
        },
        setIncrementChildrenCount: (state) => {
            state.childrenCount = state.childrenCount + 1
        },
        setDecrementChildrenCount: (state) => {
            if (state.childrenCount > 0)
                state.childrenCount = state.childrenCount - 1
        },
        setSearchDestinationName: (state, action: PayloadAction<string>) => {
            state.searchDestinationText = action.payload
        },
        setEnterPassword: (state, action: PayloadAction<boolean>) => {
            state.enterPassword = action.payload
        },
        setInputValidatorRegisterUser: (state, action: PayloadAction<boolean>) => {
            state.inputValidatorRegisterUser = action.payload
        },
        setHotelDetails: (state, action: PayloadAction<any>) => {
            state.hotelDetails = action.payload
        },
        setHotelRoomDetails: (state, action: PayloadAction<any>) => {
            state.hotelRoomDetails = action.payload
        },
        setAppliedPropertyTypeFiltersForHotels: (state, action: PayloadAction<any>) => {
            state.setPropertyTypeSelectedFilters = action.payload
        },
        setAppliedRoomTypeFiltersForHotels: (state, action: PayloadAction<any>) => {
            state.setRoomTypeSelectedFilters = action.payload
        },
        setAppliedPriceRangeFiltersForHotels: (state, action: PayloadAction<any>) => {
            state.setPriceRangeSelectedFilters = action.payload
        },
        setMobileAppliedPropertyTypeFilterForHotels: (state, action: PayloadAction<any>) => {
            state.setMobilePropertyTypeSelectedFilters = action.payload
        },
        setMobileAppliedRoomTypeFilterForHotels: (state, action: PayloadAction<any>) => {
            state.setMobileRoomTypeSelectedFilters = action.payload
        },
        setHotelsTypeTabSelection: (state, action: PayloadAction<string>) => {
            state.selectedHotelsTypeTab = action.payload
        },
        setHotelSelectedVrImgAndData: (state, action: PayloadAction<any>) => {
            state.selectedHotelVrImageAndData = action.payload
        },
        setHotelIdSelected: (state, action: PayloadAction<number>) => {
            state.hotelIdSelected = action.payload
        },
        setRoomTypeSelectedForCheckout: (state, action: PayloadAction<any>) => {
            state.roomTypeSelectedForCheckout = action.payload
        },
        setRoomIndexSelectedForCheckout: (state, action: PayloadAction<number>) => {
            state.roomIndexSelectedForCheckout = action.payload
        },
        setRoomRecommendationSelected: (state, action: PayloadAction<any>) => {
            state.roomRecommendationSelected = action.payload
        },
        setRoomRecommendationIndexSelected: (state, action: PayloadAction<number>) => {
            state.roomRecommendationIndexSelected = action.payload
        },
        setHotelRoomPriceSelectedReset: (state, action: PayloadAction<any>) => {
            state.hotelRoomPriceApiData = action.payload
        },
        setGuestDetailsAtCheckIn: (state, action: PayloadAction<any>) => {
            state.guestDetailsAtCheckIn = action.payload
        },
        setPassengerDetailsForCheckout: (state, action: PayloadAction<any>) => {
            state.passengerDetailsForCheckoutHotel = action.payload
        },
        setScreenTypeSelected: (state, action: PayloadAction<any>) => {
            state.screenTypeSeleted = action.payload
        },
        setHotelSelectedVrImgForCheckout: (state, action: PayloadAction<string>) => {
            state.hotelVrImageCheckedHotel = action.payload
        },
        setSearchHotelsResultCall: (state, action: PayloadAction<boolean>) => {
            state.searchHotelsResult = action.payload
        },
        setCliendSecreatId: (state, action: PayloadAction<any>) => {
            state.clientSecreatId = action.payload
        },
        setbBookingApiDataReset: (state, action: PayloadAction<any>) => {
            state.bookingApiData = action.payload
        },
        setUserDOBForUpdate: (state, action: PayloadAction<string>) => {
            state.userDOBForUpdate = action.payload
        },
        setLoggedInUserData: (state, action: PayloadAction<any>) => {
            state.loggedInUserCurrency = action.payload
        },
        setExploreApiDataReset: (state, action: PayloadAction<any>) => {
            state.exploreDestinationsApiData = action.payload
        },
        setCreateFolderListDataReset: (state, action: PayloadAction<any>) => {
            state.createUserFaviourateFolder = action.payload
        },
        setUserWishlistDataReset: (state, action: PayloadAction<any>) => {
            state.userFaviouratesFolderDetails = action.payload
        },
        setUserFaviouratesListReset: (state, action: PayloadAction<any>) => {
            state.userFavouritesFolderList = action.payload
        },
        setRemoveFromFaviouratesReset: (state, action: PayloadAction<any>) => {
            state.userRemoveFromFaviourates = action.payload
        },
        setUserMytripsDataReset: (state, action: PayloadAction<any>) => {
            state.userBookingList = action.payload
        },
        setUserMytripDetailsDataReset: (state, action: PayloadAction<any>) => {
            state.userBookingDetails = action.payload
        },
        setWebFiltersEnable: (state, action: PayloadAction<boolean>) => {
            state.webFilterEnable = action.payload
        },
        setMobileFiltersEnable: (state, action: PayloadAction<boolean>) => {
            state.mobileFilterEnable = action.payload
        },
        setOrderSummarySectionType: (state, action: PayloadAction<string>) => {
            state.orderSummerySection = action.payload
        },
        setGetHotelsToExploreOffersReset: (state, action: PayloadAction<any>) => {
            state.getHotelsToExploreOffers = action.payload
        },
        setAllTypeOfOffersAndHotels: (state, action: PayloadAction<any>) => {
            state.allTypeOfLatestOffersAndHotels = action.payload;
        },
        setResonsToBookingCancle: (state, action: PayloadAction<any>) => {
            state.getResonsForBookingCancle = action.payload
        },
        setCancellationPoliciesReset: (state, action: PayloadAction<any>) => {
            state.getCancellationPoliciesData = action.payload
        },
        setExploreHotelsToStayReset: (state, action: PayloadAction<any>) => {
            state.exploreHotelsToStay = action.payload;
        },
        setExploreThingToDoSelectedReset: (state, action: PayloadAction<any>) => {
            state.exploreThingToDoSelected = action.payload;
        },
        setExploreRegionSelectedReset: (state, action: PayloadAction<any>) => {
            state.exploreRegionSelected = action.payload;
        },
        setCancleBookingReset: (state, action: PayloadAction<any>) => {
            state.cancleBookingData = action.payload
        },
        setUserNotificationsAndMessgReset: (state, action: PayloadAction<any>) => {
            state.userNotificationsAndMessg = action.payload
        },
        setPaypalCurrenciesReset: (state, action: PayloadAction<any>) => {
            state.countryCurrenciesPaypal = action.payload
        },
        setSelectedCurrencySymbol: (state, action: PayloadAction<string>) => {
            state.selectedCurrencySymboll = action.payload
        },
        setPreviousPathName: (state, action: PayloadAction<string>) => {
            state.previousPathName = action.payload
        },
        setErrorMessageForDialogueBox: (state, action: PayloadAction<string>) => {
            state.errorMessageForDialogue = action.payload
        },
        setAlertBoxOnError: (state, action: PayloadAction<boolean>) => {
            state.openAlertBoxOnError = action.payload
        },
        setNoOfHitsResultApi: (state, action: PayloadAction<number>) => {
            state.NoOfHitsResultApi = action.payload;
        },
        setDestinationsReset: (state, action: PayloadAction<any>) => {
            state.destinationForSearch = action.payload
        },
        setPopularHotelsReset: (state, action: PayloadAction<any>) => {
            state.getPopularDestinationsForHotels = action.payload
        },
        setSessionExpired: (state, action: PayloadAction<boolean>) => {
            state.sessionExpired = action.payload
        },
        setLoadHotelResultSkeleton: (state, action: PayloadAction<boolean>) => {
            state.loadHotelResultSkeleton = action.payload
        },
        setExploreDestinationPopup: (state, action: PayloadAction<boolean>) => {
            state.exploreDestinationPopup = action.payload
        },
        setAddTofavouriteListReset: (state, action: PayloadAction<any>) => {
            state.addTofavouriteList = action.payload
        },
        setOTPSentTimer: (state, action: PayloadAction<any>) => {
            state.sentOTPTimer = action.payload
        },
        setAllowMoeNotification: (state, action: PayloadAction<any>) => {
            state.allowMoeNotifications = action.payload
        },
        setUserActionLoader: (state, action: PayloadAction<any>) => {
            state.userActionLoader = action.payload
        },
        setDownloadPDFDataReset: (state, action: PayloadAction<any>) => {
            state.donwloadBookingPDFData = action.payload
        },
        setShowFaviourateList: (state, action: PayloadAction<boolean>) => {
            state.showFaviourateList = action.payload
        },
        setFolderSelectedDetails: (state, action: PayloadAction<any>) => {
            state.folderSelectedDetails = action.payload
        },
        setMediaNewsArticlesDataReset: (state, action: PayloadAction<any>) => {
            state.mediaNewsRoomsArticleData = action.payload
        },
        setOfferBookedDataReset: (state, action: PayloadAction<any>) => {
            state.bookOfferHotelData = action.payload
        },
        setBookedOfferListDataReset: (state, action: PayloadAction<any>) => {
            state.bookedOffersList = action.payload
        },
        setBookedOfferDetailsReset: (state, action: PayloadAction<any>) => {
            state.bookedOfferDetails = action.payload
        }
    },

    // extra reducers 
    extraReducers: (builder) => {
        // reducer function for all static api calls 
        builder.addCase(fetchDataAsyncStaticApi.fulfilled, (state, action) => {
            // Store the response in the corresponding extra reducer
            switch (action.meta.arg) {
                case endPointPopularHotels:
                    state.getPopularDestinationsForHotels.success = action.payload.success;
                    state.getPopularDestinationsForHotels.popular = action.payload.popular;
                    break;
                case endPointDestinationSearch:
                    state.destinationForSearch.status = action.payload.status;
                    state.destinationForSearch.message = action.payload.message;
                    state.destinationForSearch.destinations = action.payload.destinations;
                    break;
                case endPointCountryCodes:
                    state.countryCallingCodes.status = action.payload.status;
                    state.countryCallingCodes.message = action.payload.message;
                    state.countryCallingCodes.countries = action.payload.countries;
                    break;
                case endPointContryCurrency:
                    state.countryCurrencies.status = action.payload.status;
                    state.countryCurrencies.message = action.payload.message;
                    state.countryCurrencies.currencies = action.payload.currencies;
                    break;
                case endPointGetResonsCancleBooking:
                    state.getResonsForBookingCancle.status = action.payload.status;
                    state.getResonsForBookingCancle.response = action.payload.response;
                    break;
                case endPointGetMediaNewsArticles:
                    state.mediaNewsRoomsArticleData = action.payload;
                    break;
                default:
                    break;
            }
        });

        // reducer function for all get api calls with params
        // get user wishlist folders
        builder
            .addCase(fetchFaviourateFolderList.pending, (state) => {
                state.userFavouritesFolderList.loading = true;
                state.userFavouritesFolderList.error = null;
            })
            .addCase(fetchFaviourateFolderList.fulfilled, (state, action) => {
                state.userFavouritesFolderList.loading = false;
                state.userFavouritesFolderList.data = action.payload;
            })
            .addCase(fetchFaviourateFolderList.rejected, (state, action) => {
                state.userFavouritesFolderList.loading = false;
                state.userFavouritesFolderList.error = action.payload;
            });

        // get user folder details     
        builder
            .addCase(fetchFaviourateFolderDetails.pending, (state) => {
                state.userFaviouratesFolderDetails.loading = true;
                state.userFaviouratesFolderDetails.error = null;
            })
            .addCase(fetchFaviourateFolderDetails.fulfilled, (state, action) => {
                state.userFaviouratesFolderDetails.loading = false;
                state.userFaviouratesFolderDetails.data = action.payload;
            })
            .addCase(fetchFaviourateFolderDetails.rejected, (state, action) => {
                state.userFaviouratesFolderDetails.loading = false;
                state.userFaviouratesFolderDetails.error = action.payload;
            });

        // remove user faviourates      
        builder
            .addCase(fetchRemoveFromFaviourates.pending, (state) => {
                state.userRemoveFromFaviourates.loading = true;
                state.userRemoveFromFaviourates.error = null;
            })
            .addCase(fetchRemoveFromFaviourates.fulfilled, (state, action) => {
                state.userRemoveFromFaviourates.loading = false;
                state.userRemoveFromFaviourates.data = action.payload;
            })
            .addCase(fetchRemoveFromFaviourates.rejected, (state, action) => {
                state.userRemoveFromFaviourates.loading = false;
                state.userRemoveFromFaviourates.error = action.payload;
            });



        // get user trips 
        builder
            .addCase(fetchUserTripsApi.pending, (state) => {
                state.userBookingList.loading = true;
                state.userBookingList.error = null;
            })
            .addCase(fetchUserTripsApi.fulfilled, (state, action) => {
                state.userBookingList.loading = false;
                state.userBookingList.data = action.payload;
            })
            .addCase(fetchUserTripsApi.rejected, (state, action) => {
                state.userBookingList.loading = false;
                state.userBookingList.error = action.payload;
            });

        // get user trip details
        builder
            .addCase(fetchUserBookingDetailsApi.pending, (state) => {
                state.userBookingDetails.loading = true;
                state.userBookingDetails.error = null;
            })
            .addCase(fetchUserBookingDetailsApi.fulfilled, (state, action) => {
                state.userBookingDetails.loading = false;
                state.userBookingDetails.data = action.payload;
            })
            .addCase(fetchUserBookingDetailsApi.rejected, (state, action) => {
                state.userBookingDetails.loading = false;
                state.userBookingDetails.error = action.payload;
            });

        // explore destinations 
        builder
            .addCase(fetchExploreDestinationsApi.pending, (state) => {
                state.exploreDestinationsApiData.loading = true;
                state.exploreDestinationsApiData.error = null;
            })
            .addCase(fetchExploreDestinationsApi.fulfilled, (state, action) => {
                state.exploreDestinationsApiData.loading = false;
                state.exploreDestinationsApiData.data = action.payload;
            })
            .addCase(fetchExploreDestinationsApi.rejected, (state, action) => {
                state.exploreDestinationsApiData.loading = false;
                state.exploreDestinationsApiData.error = action.payload;
            });

        //explore hotels to stay    
        builder
            .addCase(fetchExploreHotelsToStay.pending, (state) => {
                if (state.exploreHotelsToStay) {
                    state.exploreHotelsToStay.loading = true;
                    state.exploreHotelsToStay.error = null;
                }
            })
            .addCase(fetchExploreHotelsToStay.fulfilled, (state, action: any) => {
                state.exploreHotelsToStay.loading = false;
                state.exploreHotelsToStay.data = action.payload;
            })
            .addCase(fetchExploreHotelsToStay.rejected, (state, action) => {
                state.exploreHotelsToStay.loading = false;
                state.exploreHotelsToStay.error = action.payload;
            });

        //explore thing to do selected    
        builder
            .addCase(fetchExploreThingTodo.pending, (state) => {
                if (state.exploreThingToDoSelected) {
                    state.exploreThingToDoSelected.loading = true;
                    state.exploreThingToDoSelected.error = null;
                }
            })
            .addCase(fetchExploreThingTodo.fulfilled, (state, action: any) => {
                state.exploreThingToDoSelected.loading = false;
                state.exploreThingToDoSelected.data = action.payload;
            })
            .addCase(fetchExploreThingTodo.rejected, (state, action) => {
                state.exploreThingToDoSelected.loading = false;
                state.exploreThingToDoSelected.error = action.payload;
            });

        //explore region selected    
        builder
            .addCase(fetchExploreRegion.pending, (state) => {
                if (state.exploreRegionSelected) {
                    state.exploreRegionSelected.loading = true;
                    state.exploreRegionSelected.error = null;
                }
            })
            .addCase(fetchExploreRegion.fulfilled, (state, action: any) => {
                state.exploreRegionSelected.loading = false;
                state.exploreRegionSelected.data = action.payload;
            })
            .addCase(fetchExploreRegion.rejected, (state, action) => {
                state.exploreRegionSelected.loading = false;
                state.exploreRegionSelected.error = action.payload;
            });
        // get user email exist
        builder
            .addCase(getUserEmailExistApi.pending, (state) => {
                state.getUserEmailExist.loading = true;
                state.getUserEmailExist.error = null;
            })
            .addCase(getUserEmailExistApi.fulfilled, (state, action) => {
                state.getUserEmailExist.loading = false;
                state.getUserEmailExist.data = action.payload
            })
            .addCase(getUserEmailExistApi.rejected, (state, action) => {
                state.getUserEmailExist.loading = false;
                state.getUserEmailExist.error = action.payload;
            });

        // get User Details Reducer
        builder
            .addCase(getUserDetailsApi.pending, (state) => {
                state.getUserDetails.loading = true;
                state.getUserDetails.error = null;
            })
            .addCase(getUserDetailsApi.fulfilled, (state, action) => {
                state.getUserDetails.loading = false;
                state.getUserDetails.data = action.payload;
            })
            .addCase(getUserDetailsApi.rejected, (state, action) => {
                state.getUserDetails.loading = false;
                state.getUserDetails.error = action.payload;
            });

        // get hotel Details Reducer
        builder
            .addCase(fetchHotelDetailsFromApi.pending, (state) => {
                state.hotelDetails.loading = true;
                state.hotelDetails.error = null;
            })
            .addCase(fetchHotelDetailsFromApi.fulfilled, (state, action) => {
                state.hotelDetails.loading = false;
                state.hotelDetails.data = action.payload;
            })
            .addCase(fetchHotelDetailsFromApi.rejected, (state, action) => {
                state.hotelDetails.loading = false;
                state.hotelDetails.error = action.payload;
            });

        // get hotel Details using slug Reducer
        builder
            .addCase(fetchHotelDetailsUsingSlug.pending, (state) => {
                state.hotelDetails.loading = true;
                state.hotelDetails.error = null;
            })
            .addCase(fetchHotelDetailsUsingSlug.fulfilled, (state, action) => {
                state.hotelDetails.loading = false;
                state.hotelDetails.data = action.payload;
            })
            .addCase(fetchHotelDetailsUsingSlug.rejected, (state, action) => {
                state.hotelDetails.loading = false;
                state.hotelDetails.error = action.payload;
            });

        // hotel room details
        builder
            .addCase(fetchHotelsRoomsDataFromApi.pending, (state) => {
                state.hotelRoomDetails.loading = true;
                state.hotelRoomDetails.error = null;
            })
            .addCase(fetchHotelsRoomsDataFromApi.fulfilled, (state, action) => {
                state.hotelRoomDetails.loading = false;
                state.hotelRoomDetails.data = action.payload;
            })
            .addCase(fetchHotelsRoomsDataFromApi.rejected, (state, action) => {
                state.hotelRoomDetails.loading = false;
                state.hotelRoomDetails.error = action.payload;
            });

        // hotels Result Reducer
        builder
            .addCase(fetchHotesResultsDataFromApi.pending, (state) => {
                state.hotelResults.loading = true;
                state.hotelResults.error = null;
            })
            .addCase(fetchHotesResultsDataFromApi.fulfilled, (state, action) => {
                state.hotelResults.loading = false;
                state.hotelResults.data = action.payload;
            })
            .addCase(fetchHotesResultsDataFromApi.rejected, (state, action) => {
                state.hotelResults.loading = false;
                state.hotelResults.error = action.payload;
            });

        //offer hotels Result Reducer
        builder
            .addCase(fetchOfferHotelsResult.pending, (state) => {
                state.offerHotelResults.loading = true;
                state.offerHotelResults.error = null;
            })
            .addCase(fetchOfferHotelsResult.fulfilled, (state, action) => {
                state.offerHotelResults.loading = false;
                state.offerHotelResults.data = action.payload;
            })
            .addCase(fetchOfferHotelsResult.rejected, (state, action) => {
                state.offerHotelResults.loading = false;
                state.offerHotelResults.error = action.payload;
            });

        // hotels Room price Reducer
        builder
            .addCase(fetchGetFiltersDataFromApi.pending, (state) => {
                state.getUIFiltersData.loading = true;
                state.getUIFiltersData.error = null;
            })
            .addCase(fetchGetFiltersDataFromApi.fulfilled, (state, action) => {
                state.getUIFiltersData.loading = false;
                state.getUIFiltersData.data = action.payload;
            })
            .addCase(fetchGetFiltersDataFromApi.rejected, (state, action) => {
                state.getUIFiltersData.loading = false;
                state.getUIFiltersData.error = action.payload;
            });


        // hotels get filters Reducer
        builder
            .addCase(fetchHotelsRoomPriceDataFromApi.pending, (state) => {
                state.hotelRoomPriceApiData.loading = true;
                state.hotelRoomPriceApiData.error = null;
            })
            .addCase(fetchHotelsRoomPriceDataFromApi.fulfilled, (state, action) => {
                state.hotelRoomPriceApiData.loading = false;
                state.hotelRoomPriceApiData.data = action.payload;
            })
            .addCase(fetchHotelsRoomPriceDataFromApi.rejected, (state, action) => {
                state.hotelRoomPriceApiData.loading = false;
                state.hotelRoomPriceApiData.error = action.payload;
            });

        // explore hotels to get latest offers    
        builder
            .addCase(fetchHotelsToExploreOffersApi.pending, (state) => {
                state.getHotelsToExploreOffers.loading = true;
                state.getHotelsToExploreOffers.error = null;
            })
            .addCase(fetchHotelsToExploreOffersApi.fulfilled, (state, action) => {
                state.getHotelsToExploreOffers.loading = false;
                state.getHotelsToExploreOffers.data = action.payload;
            })
            .addCase(fetchHotelsToExploreOffersApi.rejected, (state, action) => {
                state.getHotelsToExploreOffers.loading = false;
                state.getHotelsToExploreOffers.error = action.payload;
            });

        //get cancellation policies 
        builder
            .addCase(fetchCancellationPolicies.pending, (state) => {
                state.getCancellationPoliciesData.loading = true;
                state.getCancellationPoliciesData.error = null
            })
            .addCase(fetchCancellationPolicies.fulfilled, (state, action) => {
                state.getCancellationPoliciesData.loading = false;
                state.getCancellationPoliciesData.data = action.payload;
            })
            .addCase(fetchCancellationPolicies.rejected, (state, action) => {
                state.getCancellationPoliciesData.loading = false;
                state.getCancellationPoliciesData.error = action.payload
            });

        builder
            .addCase(fetchPaypalCurrencies.pending, (state) => {
                state.countryCurrenciesPaypal.loading = true;
                state.countryCurrenciesPaypal.error = null
            })
            .addCase(fetchPaypalCurrencies.fulfilled, (state, action) => {
                state.countryCurrenciesPaypal.loading = false;
                state.countryCurrenciesPaypal.data = action.payload;
            })
            .addCase(fetchPaypalCurrencies.rejected, (state, action) => {
                state.countryCurrenciesPaypal.loading = false;
                state.countryCurrenciesPaypal.error = action.payload
            })

        builder
            .addCase(fetchDownloadBookingDetails.fulfilled, (state, action) => {
                state.donwloadBookingPDFData = action.payload
            })

        builder
            .addCase(getBookedOffersList.pending, (state) => {
                state.bookedOffersList.loading = true;
                state.bookedOffersList.error = null
            })
            .addCase(getBookedOffersList.fulfilled, (state, action) => {
                state.bookedOffersList.loading = false;
                state.bookedOffersList.data = action.payload;
            })
            .addCase(getBookedOffersList.rejected, (state, action) => {
                state.bookedOffersList.loading = false;
                state.bookedOffersList.error = action.payload
            })

        builder
            .addCase(getBookedOfferDetails.pending, (state) => {
                state.bookedOfferDetails.loading = true;
                state.bookedOfferDetails.error = null
            })
            .addCase(getBookedOfferDetails.fulfilled, (state, action) => {
                state.bookedOfferDetails.loading = false;
                state.bookedOfferDetails.data = action.payload;
            })
            .addCase(getBookedOfferDetails.rejected, (state, action) => {
                state.bookedOfferDetails.loading = false;
                state.bookedOfferDetails.error = action.payload
            })

        // hotels details reducer 
        builder.addMatcher(
            HotelApi.endpoints.sendOTPPhone.matchFulfilled,
            (state, { payload }: any) => {
                state.sentOTPForPhoneData.message = payload.message;
                state.sentOTPForPhoneData.otpResponse = payload.otpResponse;
                state.sentOTPForPhoneData.statusCode = payload.statusCode;
            }
        ),
            builder.addMatcher(
                HotelApi.endpoints.verifyOTPForPhone.matchFulfilled,
                (state, { payload }: any) => {
                    state.verifyOTPForPhoneData.message = payload.message;
                    state.verifyOTPForPhoneData.oTPVerifResp = payload.oTPVerifResp;
                    state.verifyOTPForPhoneData.statusCode = payload.statusCode;
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.registerUser.matchFulfilled, (state, { payload }: any) => {
                    state.setRegisteredUserDetails.status = payload.status;
                    state.setRegisteredUserDetails.message = payload.message;
                    state.setRegisteredUserDetails.userDetail = payload.userDetail;
                }
            ),

            builder.addMatcher(
                HotelApi.endpoints.initApi.matchFulfilled, (state, { payload }: any) => {
                    state.initApi.status = payload.status;
                    state.initApi.searchId = payload.searchId;
                    state.initApi.searchTracingKey = payload.searchTracingKey
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.applyFiltersHotelsResult.matchFulfilled, (state, { payload }: any) => {
                    state.hotelResults.data = payload
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.forgotPasswordUser.matchFulfilled, (state, { payload }: any) => {
                    state.forgotPasswordUserDetails.status = payload.status;
                    state.forgotPasswordUserDetails.message = payload.message;
                },
            ),
            builder.addMatcher(
                HotelApi.endpoints.bookHotel.matchFulfilled, (state, { payload }: any) => {
                    state.bookingApiData = payload;
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.aboutUs.matchFulfilled, (state, { payload }: any) => {
                    state.aboutUSData = payload;
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.privacyPolicies.matchFulfilled, (state, { payload }: any) => {
                    state.privacyPoliciesData = payload;
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.termsConditions.matchFulfilled, (state, { payload }: any) => {
                    state.TermsAndConditions = payload;
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.updateUserCurrency.matchFulfilled, (state, { payload }: any) => {
                    state.getUserDetails.data.userDetail = payload.userDetail;
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.updateUserProfile.matchFulfilled, (state, { payload }: any) => {
                    state.getUserDetails.data.userDetail = payload.userDetail;
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.cancleUserBooking.matchFulfilled, (state, { payload }: any) => {
                    state.cancleBookingData.status = payload.status;
                    state.cancleBookingData.response = payload.response;
                    state.cancleBookingData.message = payload.message;
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.getUserNotificatiobs.matchFulfilled, (state, { payload }: any) => {
                    state.userNotificationsAndMessg = payload
                }
            ),
            builder.addMatcher(
                HotelApi.endpoints.cretateWishlistFolder.matchFulfilled, (state, { payload }: any) => {
                    state.createUserFaviourateFolder = payload
                }
            )
        builder.addMatcher(
            HotelApi.endpoints.updateWishlistHotels.matchFulfilled, (state, { payload }: any) => {
                state.addTofavouriteList = payload
            },
        )
        builder.addMatcher(
            HotelApi.endpoints.bookOfferHotel.matchFulfilled, (state, { payload }: any) => {
                state.bookOfferHotelData = payload
            }
        )
    },
})



export const { setAppLanguage, setIsLogedInUser, setMainPopupForRegisterUser, setIsRegisterPopUp, setIsVerificationPopUp, setGmailVerificationPopUp, setGateWaysAndHolidayMakerPopup, setUserRegisterWelcomePopup, setEmailAuthenticationUserData,
    setSocialMediaAuthenticationUserData, setAuthenticationType, setRegisterUserStatus, setFirstNameUser, setLastNameUser, setLoginUserEmailAddress, setUserLoginPassword, setSignInCallApi, setCountryCallingIDData, setCountryNumCodeForApi,
    setUserPhoneNumber, setRegisterUserCurrencyId, setRegisterUserEmailAddress, setRegisterUserPassword, setUserBirthDate, setRegisteredUserDetailsReset, setGetUserDetailsReset, setHotelSearchRequestReset, setHotelsResultsDataReset, setExistingUserEmailStatusReset,
    setSearchDestinationFromDate, setSearchDestinationToDate, setSearchDestinationData, setLocationId, setDecrementNights, setIncrementNights, setDecrementAdultCount, setIncrementAdultCount, setDecrementChildrenCount, setIncrementChildrenCount,
    setNoOfNights, setRoomArray, setResetRoomArray, setSearchDestinationName, setRemoveRoomArray, setEnterPassword, setShowDate, setInputValidatorRegisterUser, setHotelDetails, setHotelRoomDetails, setAppliedPriceRangeFiltersForHotels,
    setMobileAppliedPropertyTypeFilterForHotels, setMobileAppliedRoomTypeFilterForHotels, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels, setHotelsTypeTabSelection, setHotelSelectedVrImgAndData, setHotelIdSelected,
    setRoomRecommendationSelected, setHotelRoomPriceSelectedReset, setGuestDetailsAtCheckIn, setScreenTypeSelected, setHotelSelectedVrImgForCheckout, setForgotPasswordDataReset, setSearchHotelsResultCall, setCliendSecreatId, setbBookingApiDataReset,
    setSentOTPPhoneDataReset, setVerifyOTPPhoneDataReset, setUserDOBForUpdate, setLoggedInUserData, setExploreApiDataReset, setUserWishlistDataReset, setUserMytripsDataReset, setUserMytripDetailsDataReset, setWebFiltersEnable,
    setMobileFiltersEnable, setOrderSummarySectionType, setGetHotelsToExploreOffersReset, setAllTypeOfOffersAndHotels, setResonsToBookingCancle, setCancellationPoliciesReset, setExploreHotelsToStayReset, setRoomTypeSelectedForCheckout, setCancleBookingReset,
    setUserNotificationsAndMessgReset, setPaypalCurrenciesReset, setSelectedCurrencySymbol, setPreviousPathName, setPassengerDetailsForCheckout, setRoomRecommendationIndexSelected, setRoomIndexSelectedForCheckout,
    setExploreThingToDoSelectedReset, setExploreRegionSelectedReset, setErrorMessageForDialogueBox, setAlertBoxOnError, setNoOfHitsResultApi, setDestinationsReset, setSessionExpired, setPopularHotelsReset, setLoadHotelResultSkeleton,
    resetState, navigateTo, setExploreDestinationPopup, setUserFaviouratesListReset, setCreateFolderListDataReset, setRemoveFromFaviouratesReset, setAddTofavouriteListReset, setOTPSentTimer, setAllowMoeNotification, setUserActionLoader,
    setDownloadPDFDataReset, setShowFaviourateList, setFolderSelectedDetails, setMediaNewsArticlesDataReset, setOfferHotelsDataReset, setOfferBookedDataReset, setBookedOfferListDataReset, setBookedOfferDetailsReset } = hotelSlice.actions

export default hotelSlice.reducer;