export const EnglishMessages = {
    "Latest Offers": "Latest Offers",
    "Explore": "Explore",
    "Favourites": "Favourites",
    "About Us": "About Us",
    "Blog": "Blog",
    "Privacy": "Privacy",
    "Terms": "Terms",
    "Support": "Support",
    "Login to your account": "Login to your account",
    "Or sign in with": "Or sign in with",
    "sign in": "sign in",
    "Forgot password": "Forgot Password?",
    "Email Address": "Email Address",
    "code": "code",
    "Password": "Password",
    "Register your account": "Register your account",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Make sure it matches": "Make sure it matches the name on your ID card or Passport",
    "Search code country": "Search code country",
    "Phone Number": "Phone Number",
    "We will call or send you": "We will send you a confirmation to your phone number.",
    "Currency based on your location": "Currency based on your location",
    "By clicking": "By clicking",
    "By continuing": "By continuing, you agree to our ",
    "and": " and ",
    "to subscribe": " to subscribe to marketing communications for offers, alerts and services.",
    "you will agree with our": "you will agree with our ",
    "terms & conditions": "Terms & Conditions",
    "privacy policy": " Privacy Policy",
    "Continue": "Continue",
    "Need Help": "Need Help?",
    "Igobot": "Igobot",
    "Quick answer to your queries": "Quick answer to your queries",
    "Support Center": "Support Center",
    "Information about": "Information about all things about Igoroom",
    "Customer Services": "Customer Services",
    "Schedule a callback from of agents": "Schedule a callback from of agents",
    "Where do you want to go?": "Where do you want to go?",
    "How many guest & room": "How many guest & room",
    "Popular Destinations": "Popular Destinations",
    "Destination": "Destination",
    "Maldives": "Maldives",
    "Dates": "Dates",
    "Choose date": "Choose date",
    "Room": "Room",
    "1 Room": "1 Room",
    "It will be": "It will be",
    "Nights": "Nights",
    "I’m alone": "I’m alone",
    "Child": "Child",
    "Adults": "Adults",
    "Children": "Children",
    "You never really travel alone": "You never really travel alone. The world is full of friends waiting to get to know you!",
    "Check Availability": "Check Availability",
    "Add room": "Add room",
    "View details": "View details",
    "Book now": "Book now",
    "night": "night",
    "start from": "start from",
    "Todays Rate": "Todays Rate",
    "Offer ends in": "Offer ends in",
    "Travel until": "Travel until ",
    "Excellent": "Excellent",
    "reviews": "reviews",
    "Guests": "Guests",
    "When and how long is your trip": "When and how long is your trip",
    "Location": "Location",
    "Search": "Search",
    "Price range": "Price range",
    "Property type": "Property type",
    "Room Type": "Room Type",
    "View By": "View By",
    "Type": "Type",
    "Our recommendations": "Our recommendations",
    "Sort by": "Sort by",
    "Reset Filters":"Reset Filters",
    "Check in":"Check in",
    "Check Out":"Check out",
    "© Copyright":"© Copyright 2024 Igoroom Pte. Ltd."
}