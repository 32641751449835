import { Button, ButtonGroup } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useAppSelector } from "../../appStore/hooks";
import { useDispatch } from "react-redux";
import { setSearchDestinationToDate } from "../../appStore/hotelSlice";
import { incrementDecrementConverter } from "../../utils/utilities/DateConverter";
import { useState } from "react";

export const CountInput = ({ increment, decrement, count, paxType, countType }: any) => {

    return (
        <>
            <ButtonGroup variant="text" aria-label="group" className='iGoRoom_CountInput'>
                <Button variant="text"
                    disabled={paxType == 'adult' ? count <= 1 ? true : false : count == 0 ? true : false}
                    onClick={(e) => decrement()}><RemoveIcon sx={{ width: '13px', height: '13px' }} /></Button>
                <Button variant="text"> {count} </Button>
                <Button variant="text"
                    disabled={paxType == 'adult' ? count >= 23 ? true : false : paxType == 'child' && count >= 13 ? true : false}
                    onClick={(e) => increment()} ><AddIcon sx={{ width: '13px', height: '13px' }} /></Button>
            </ButtonGroup>
        </>
    )
}
