import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

let primaryFontFamily = 'Poppins, sans-serif';

let primaryColor = '#FF6B6B';
let btnPrimaryColor = 'linear-gradient(180deg, #FF6B6B 0%, #EE5253 100%)';
let secondaryColor = '#C4C4C4';
let bgGrayColor = '#F1F5F9';
let whiteColor = '#ffffff';
let blackColor = '#000000';
let fontSize20 = '1.25rem';
let fontSize18 = '1.125rem';
let fontSize14 = '0.875rem';
let fontSize12 = '0.75rem';
let fontSize10 = '0.625rem';
let fwRegular = '400';
let fwMedium = '500';
let fwSemibold = '600';





const theme = extendTheme({
  typography: {
    fontFamily: primaryFontFamily,
    h1: {
      fontSize: fontSize20,
      fontWeight: fwSemibold,
    },
    h3: {
      fontSize: fontSize18,
    },
    h4: {
      fontSize: fontSize18,
      fontWeight: fwSemibold,
    },
    subtitle1: {
      fontSize: fontSize14,
      fontWeight: fwSemibold,
    },
    subtitle2: {
      fontSize: fontSize14,
      fontWeight: fwRegular,
    },
    caption: {
      fontSize: fontSize12,
    },
    body1: {
      fontSize: fontSize10,
    },
    body2: {
      fontSize: fontSize12,
      fontWeight:fwSemibold
    },
    
  },
  components: {

    MuiButton: {
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            fontSize: fontSize14,
            color: whiteColor,
            background: btnPrimaryColor,
            borderRadius: '50px',
            width: '100%',
            height: '45px',
            boxShadow: 'none',
            textTransform: 'inherit',
            '&:hover': {
              color: whiteColor,
              boxShadow: 'none',
              background: btnPrimaryColor,
            },
            '&:active': {
              color: whiteColor,
            },
          },
        },
        // {
        //   props: { variant: 'dashed', color: 'secondary' },
        //   style: {
        //     border: `4px dashed ${red[500]}`,
        //   },
        // },
      ],
    },

  },

});

export default theme;
