import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CountInput } from '../inputFieldCustom/CountInput';
import { DestinationChildAge } from './DestinationChildAge';
import Remove from '@mui/icons-material/DoDisturbOnOutlined';
import { Button } from '@mui/base';
import { useAppSelector } from '../../appStore/hooks';
import { Box } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
const DestinationRoom = (props: any) => {
    const matches = useMediaQuery('(max-width:1024px)');
    // const roomArr = useAppSelector(state => state.hotel.roomArray)
    const { roomNo, deleteHandler, adultUpdate, childUpdate, roomDetails, ChildAgeUpdate, roomArray } = props
    const { adults, children, childAges } = roomDetails
    const { t } = useTranslation();
    const adultIncrement = () => {
        adultUpdate(true, roomNo)
    }
    const adultDecrement = () => {
        adultUpdate(false, roomNo)
    }
    const childrenIncrement = () => {
        childUpdate(true, roomNo)
    }
    const childrenDecrement = () => {
        childUpdate(false, roomNo)
    }
    const childAgeIncrement = (arrIndex: any) => {
        let arr = [...childAges]
        if (arr[arrIndex] < 12)
            arr[arrIndex] = arr[arrIndex] + 1
        ChildAgeUpdate(arr, roomNo)
    }
    const childAgeDecrement = (arrIndex: any) => {
        let arr = [...childAges]
        if (arr[arrIndex] > 0)
            arr[arrIndex] = arr[arrIndex] - 1
        ChildAgeUpdate(arr, roomNo)
    }

    const adultsCountLable = `${adults > 1 ? t("Adults") : t("Adult")}`;
    const childCountLable = `${children > 1 ? t("Children") : t("Child")}`;
    return (<>
        <Grid item xs={12} lg={3} sx={{ textAlign: "left", paddingLeft: '0 !important' }} className={matches ? 'iGor_setFlex iGor_justifyBetween' : 'iGor_setFlex  iGor_alignCenter'} >
            <Typography variant='subtitle1' className='iGor__RoomTitle'>{t(`Room ${roomNo + 1}`)}</Typography>
            {matches && roomArray.length > 1 &&
                <Box>
                    <Remove className='iGor_d_roomBtnDel' onClick={() => deleteHandler(roomNo)} />
                </Box>
            }
        </Grid>
        <Grid item xs={12} lg={4}  className={matches ? 'iGor_setFlex iGor_justifyBetween iGor_MB_addRoomBox' : ''} >
            {matches && <Typography variant='body1' sx={{ textAlign: 'left' }}>{t("We are")}</Typography>}
            {!matches && <Typography variant='body1' sx={{ marginBottom: '5px', fontWeight: '500' }}>{adultsCountLable}</Typography>}
            <CountInput paxType={'adult'} increment={adultIncrement} decrement={adultDecrement} count={adults} />
            {matches && <Typography variant='body1' sx={{ marginBottom: '5px', fontWeight: '500', textAlign: 'right' }} className='iGor__RoomsubTitle'>{adultsCountLable}</Typography>}
        </Grid>
        <Grid item xs={12} lg={4} className={matches ? 'iGor_setFlex iGor_justifyBetween iGor_MB_addRoomBox' : ''} >
            {matches && <Typography variant='body1' sx={{ textAlign: 'left' }}>{t("with")}</Typography>}
            {!matches && <Typography variant='body1' sx={{ marginBottom: '5px', fontWeight: '500' }}>{childCountLable}</Typography>}
            <CountInput paxType={'child'} increment={childrenIncrement} decrement={childrenDecrement} count={children} />
            {matches && <Typography variant='body1' sx={{ marginBottom: '5px', fontWeight: '500', textAlign: 'right' }} className='iGor__RoomsubTitle'>{childCountLable}</Typography>}
        </Grid>
        {!matches && roomArray.length > 1 &&
            <Grid item sm={1} >
                <Box>
                    <Remove className='iGor_d_roomBtnDel' onClick={() => deleteHandler(roomNo)} />
                </Box>
            </Grid>}
        <Grid container sx={{ width: '100%' }}>
            {Array.from(Array(children), (e, index) => {
                return <>
                    {!matches &&
                        <Grid item sm={3} xs={12} marginY={1} className='iGor_setFlex  iGor_alignCenter'>
                            <Typography variant='body1' sx={{ marginTop: '10px', textAlign: 'left', fontSize: '14px', fontWeight: '600' }} >{t(`Child ${index + 1}`)}</Typography>
                        </Grid>}
                    <Grid item sm={9} xs={12} marginY={1} className={matches ? 'iGor_childAgeDe iGor_setFlex iGor_justifyBetween iGor_MB_addChildBox' : ''}>
                        <Box className="iGor__ChildBox">
                            {matches && <Typography variant='body1' sx={{ textAlign: 'left' }} >{t(`Child ${index + 1}`)}</Typography>}
                            <DestinationChildAge index={index} count={childAges[index]} increment={childAgeIncrement} decrement={childAgeDecrement} />
                            {matches && <Typography variant='body1' sx={{ marginBottom: '5px', textAlign: 'right' }} >{t(`Years old`)}</Typography>}
                            {!matches && <Box className="iGor__YearsOld">
                                <Typography variant='body1' sx={{ fontSize: '12px', color: '#C4C4C4' }} >{t(`Years old`)}</Typography>
                            </Box>}
                        </Box>
                    </Grid>
                </>
            })}
        </Grid>
    </>)
}
export default DestinationRoom 