import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import SearchIcon from '../../assets/icons/search_Icon.svg';
import ExploreDestinationModel from './ExploreDestinationModel';

export const ExploreDestination = () => {
    return (
        <>
            <Box className='InputField iGor_Ex-Destination'>
                <img src={SearchIcon} alt='SearchIcon' />
              <ExploreDestinationModel/>
            </Box>
        </>
    )
}
