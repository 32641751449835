import { updateUserWishlist } from "../utils/constants/types";

export const updateUserHotelWishlistPayload = (faviourateType: string, faviourateId: number, folderId: number, loggedInUserId: number) => {
    // payload body for update user hotel wishlist 
    const payload: updateUserWishlist = {
        userWishlistHotel: {
            type: faviourateType,
            id: faviourateId,
            folderId: folderId,
            UserId: loggedInUserId
        }
    }
    return payload
}