import { AppLogHandler } from "../../utils/handlers/appLogsHandler";

const cachedImages: any = {};

export const cacheImages = (imageUrl: any) => {
    if (!cachedImages[imageUrl]) {
        const img = new Image();
        img.src = imageUrl;
        cachedImages[imageUrl] = img;
        AppLogHandler('######block if ',cachedImages)
    }
    else {
        AppLogHandler('########block else',cachedImages)
    }
    return cachedImages[imageUrl];
};