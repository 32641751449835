import React, { useEffect, useState } from 'react';
import { IconButton, Popover } from "@mui/material";
import logingUser from '../../assets/icons/loginIcon.svg';
import { LoginAccount } from '../userDetailsFrom/LoginAccount';
import { fetchDataAsyncStaticApi, fetchPaypalCurrencies, setEnterPassword, setUserActionLoader } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../appStore/hooks';
import { asyncThunkApiEndPoints } from '../../utils/constants/constant';

export const LoginAccountPopover = () => {
    const dispatch = useDispatch();
    const endPointCountryCodes = asyncThunkApiEndPoints.getCountryCallingCodes;
    const { countryCallingCodes } = useAppSelector(state => state.hotel);  
    const countryCurrenciesPaypal = useAppSelector(state => state.hotel.countryCurrenciesPaypal?.data); 


    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    let countryCodes: [any] = countryCallingCodes?.countries ? countryCallingCodes?.countries : []
    const paypalCurrenciesArray = countryCurrenciesPaypal?.currencies ? countryCurrenciesPaypal?.currencies : [];
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        dispatch(setEnterPassword(false))
        if (countryCodes.length <= 0) {
            dispatch(fetchDataAsyncStaticApi(endPointCountryCodes))
        }
        if (paypalCurrenciesArray.length <= 0) {
            dispatch(fetchPaypalCurrencies('paypal'))
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        dispatch(setUserActionLoader({
            action: '',
            loading: false
        }))
    };

    const { mainPopupForRegisterUser }: any = useAppSelector(state => state.hotel);

    const open = Boolean(anchorEl);
    const id = open ? 'LoginAccount-popover' : undefined;

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick} className='iGor__logingUserProfile'>
                <img src={logingUser} alt="logingUser" />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className={mainPopupForRegisterUser ? "iGor_LoginAccount" : ''}
            >
                {<LoginAccount />}
            </Popover>
        </>
    );
}
