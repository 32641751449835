// NavigationHandler.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { navigateTo } from './hotelSlice';
import { useAppSelector } from './hooks';
function NavigationHandlerForReset() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const path = useAppSelector((state) => state.hotel.path);

    useEffect(() => {
        if (path) {
            navigate(path);
            dispatch(navigateTo(null));
        }
    }, [path, navigate, dispatch]);

    return null;
}

export default NavigationHandlerForReset;

// Adjust this path to where your navigationSlice is located

