import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { env } from "../../utils/config/appConfig";
import NoHotelsImg from '../../assets/images/No Hotels Img.png'
import Icon_360 from '../../assets/icons/Icon_360.svg';
import icon_set from '../../assets/icons/icon_set.svg';
import { useAppSelector } from "../../appStore/hooks";
import URLValidator from "../../utils/utilities/URLValidator";
import { cacheImages } from "../../utils/utilities/ImageCaching";
import { environmentVariables } from "../../utils/config/environmentVariables";

function PropertyRoomVrSectionViewer({ hotelrooms, selectedRoomId, defaultRecommendation, setIframeIndexId, setIframeShow, roomIndex, selectedDivIndex }: any) {
    const imagePath = environmentVariables.imagePathApp;
    const pathname = window.location;
    const hrefDetails = pathname.pathname + pathname.search;
    const { roomRecommendationSelected } = useAppSelector(state => state.hotel)
    const imageURL = hotelrooms?.image ? `${imagePath}${hotelrooms.image}` : NoHotelsImg
    const validUrl = URLValidator(hotelrooms?.vrroom);

    // show vr handler
    const showRoomVirtually = (index: any) => {
        if (setIframeShow) setIframeShow(true)
        if (setIframeIndexId) setIframeIndexId(index)
    }

    const imageCached = cacheImages(imageURL)
    return (
        <>
            <Box className="img">
                <img
                    src={imageCached ? imageCached.src : NoHotelsImg}
                    alt="Villa with Ocean View and Infinity Pool"
                    onError={(e: any) => {
                        // Handle the image load error by setting the source to the default image
                        e.target.src = NoHotelsImg;
                    }}
                />
            </Box>
            {validUrl == true ? (
                <><Box className="icon-wrapper" onClick={(e) => showRoomVirtually(roomIndex)} >
                    <img src={Icon_360} alt="sphere" />
                </Box >
                     <Box className='iGor_iconZoom'>
                        <Link
                            to={'/property-details-view'}
                            state={{
                                roomSelected: hotelrooms,
                                href: hrefDetails,
                                selectedRoomId: selectedRoomId,
                                roomRecommendationSelected: roomRecommendationSelected,
                                defaultRecommendation: defaultRecommendation,
                                roomIndexSelected: roomIndex,
                                selectedDivIndex: selectedDivIndex
                            }}
                        >
                            <img src={icon_set} alt="icon_set" />
                        </Link>
                    </Box></>) : ('')}
        </>
    )
}

export default PropertyRoomVrSectionViewer