import * as React from 'react';
import { Grid, Container, Box, IconButton } from "@mui/material";
import IgoRoomLogo from '../assets/icons/iGoRoom_icons.svg';
import { useNavigate } from "react-router";
import { LoginAccountPopover } from '../components/popUpSection/LoginAccountPopover';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../appStore/hooks";
import {
    setAppLanguage, setHotelSearchRequestReset, setIsLogedInUser, setResetRoomArray, setNoOfNights, setEnterPassword, setEmailAuthenticationUserData,
    setUserLoginPassword, setSearchDestinationData, setShowDate, setRegisterUserCurrencyId, setSearchDestinationFromDate, setSearchDestinationToDate, setHotelsResultsDataReset,
    setAppliedPriceRangeFiltersForHotels, setHotelRoomDetails, setHotelDetails, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels,
    setHotelsTypeTabSelection, setHotelSelectedVrImgAndData, setRoomRecommendationSelected, setHotelIdSelected, setHotelRoomPriceSelectedReset,
    setMobileAppliedPropertyTypeFilterForHotels, setMobileAppliedRoomTypeFilterForHotels, setLoggedInUserData, setExploreApiDataReset,
    fetchFaviourateFolderList, setWebFiltersEnable, setMobileFiltersEnable, setOrderSummarySectionType, fetchHotelsToExploreOffersApi, setGetHotelsToExploreOffersReset,
    setResonsToBookingCancle, setCancellationPoliciesReset, setExploreHotelsToStayReset, setRoomTypeSelectedForCheckout, setCancleBookingReset, setPreviousPathName, setPassengerDetailsForCheckout, setRoomIndexSelectedForCheckout, setExploreThingToDoSelectedReset, setExploreRegionSelectedReset,
    setNoOfHitsResultApi, setDestinationsReset,
    setPopularHotelsReset,
    setUserFaviouratesListReset,
    setRemoveFromFaviouratesReset,
    setUserWishlistDataReset,
    setAddTofavouriteListReset,
    setShowFaviourateList,
    setFolderSelectedDetails,
    setOfferHotelsDataReset,
    setBookedOfferListDataReset,
    setBookedOfferDetailsReset
} from "../appStore/hotelSlice";
import i18n from "../utils/config/langConfig";
import { useEffect } from "react";
import { AppLogHandler } from "../utils/handlers/appLogsHandler";
import ExploreIcon from '@mui/icons-material/Explore';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { UserLoginPopover } from '../components/popUpSection/UserLoginPopover';
import MobileMenu from '../components/mobileComponents/MobileMenu';
import CurrenciesModal from '../components/popUpSection/CurrenciesModal';
import { useDispatch } from 'react-redux';
import { EventNamesForEventLogs, accountOptionSectionType, eventNameForMoeEvents, orderSummarySectionType } from '../utils/constants/constant';
import { ToastContainer } from 'react-toastify';
import { calculateDateFourtyFiveDaysLater, getThreeMonthsLaterDate } from '../utils/utilities/DateConverter';
import { EventLogHanlderMoengage, EventLogsHandler } from '../utils/handlers/EventLogsHandler';

const Header = (props: any) => {
    // t object for language translation for keys
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const language = useAppSelector(state => state.hotel.appLanguage);
    const { getUserEmailExist, setUserLoggedIn, getUserDetails, getUIFiltersData, authenticationType, mainPopupForRegisterUser, loggedInUserCurrency, countryCallingCodes } = useAppSelector(state => state.hotel);
    const countriesCallingCodeData: any = countryCallingCodes?.countries ? countryCallingCodes?.countries : [];

    const userEmailExist = getUserEmailExist.data?.status;
    const resetPriceRange = getUIFiltersData?.data?.priceRange
    const loggedInUserData = getUserDetails.data?.userDetail;
    const getUserDetailsApiCall = getUserDetails?.loading;
    const userEmail = loggedInUserData?.email
    const userPhoneNumber = loggedInUserData?.phone;
    const userBirthdate = loggedInUserData?.birthDate;
    const firstName = loggedInUserData?.firstName;
    const lastName = loggedInUserData?.LastName;
    const pathname = window.location.pathname;
    const accountSectionType = accountOptionSectionType;
    const orderSummarySction = orderSummarySectionType.orderSummary;
    const todaysDate = new Date();
    const tommorowsDate = new Date((new Date()).setDate((new Date()).getDate() + 1));
    const destinationSearchStartDate = getThreeMonthsLaterDate(todaysDate);
    const destinationSearchEndDate = getThreeMonthsLaterDate(tommorowsDate);
    const eventNameLogin = EventNamesForEventLogs.loginOfUser;
    const eventNameLoginMoe = eventNameForMoeEvents.Login;
    const authenthicationType = authenticationType.toLocaleLowerCase();
    const loggedInUserCountryId = loggedInUserData?.country_id;

    const countryNationalCode = countriesCallingCodeData.find((country: any) => country.id == loggedInUserCountryId)?.iso2 || "MY";
    
    // assign logged in user details 
    const loggedinUserDetails = {
        userId: loggedInUserData?.id ? loggedInUserData?.id : 0,
        currencyId: loggedInUserData?.currency_id ? loggedInUserData?.currency_id : 21,
        currencyName: loggedInUserData?.currency_name ? loggedInUserData?.currency_name : 'USD',
        currencySymbol: loggedInUserData?.currency_symbol ? loggedInUserData?.currency_symbol : '$',
        countryOfResidenceCode:countryNationalCode,
        nationality:countryNationalCode
    }


    // navigation Handler
    const navigationHandler = (sectionType: string) => {
        const fromDateDispatch = destinationSearchStartDate.toISOString();
        const toDateDispatch = destinationSearchEndDate.toISOString();
        switch (sectionType) {
            case accountSectionType.latestOffers:
                // if (loggedinUserDetails.userId !== 0) {
                dispatch(setHotelDetails({ data: [], loading: false, error: null }))
                dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
                dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
                dispatch(fetchHotelsToExploreOffersApi(''))
                navigate('/latest-offers')
                dispatch(setSearchDestinationFromDate(fromDateDispatch))
                dispatch(setSearchDestinationToDate(toDateDispatch))
                break;
            case accountSectionType.exploreDestinations:
                dispatch(setHotelDetails({ data: [], loading: false, error: null }))
                dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
                dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
                navigate('/explore')
                dispatch(setSearchDestinationFromDate(fromDateDispatch))
                dispatch(setSearchDestinationToDate(toDateDispatch))
                break;
            case accountSectionType.myFavourite:
                dispatch(setHotelDetails({ data: [], loading: false, error: null }))
                dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
                dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
                if (loggedinUserDetails.userId !== 0) {
                    dispatch(fetchFaviourateFolderList(loggedinUserDetails.userId))
                    dispatch(setSearchDestinationFromDate(fromDateDispatch))
                    dispatch(setSearchDestinationToDate(toDateDispatch))
                }
                //need to remove this after 2-3 days
                dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
                dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
                dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
                dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
                navigate('/favourites')
                break;
            default: return 'No Section Matches'
        }
    }

    // on navigate to home screen function
    const home = () => {
        const fromDates = new Date().toISOString()
        const toDates = new Date((new Date()).setDate((new Date()).getDate() + 1)).toISOString()
        navigate('/')
        dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
        dispatch(setResetRoomArray())
        dispatch(setNoOfNights(1))
        dispatch(setEnterPassword(false))
        dispatch(setEmailAuthenticationUserData(['']))
        dispatch(setUserLoginPassword(''))
        dispatch(setSearchDestinationData({ destinationName: '', destinationCode: '' }))
        dispatch(setShowDate(false))
        dispatch(setRegisterUserCurrencyId({ currencyId: 0, currencyCode: '' }))
        dispatch(setSearchDestinationFromDate(fromDates))
        dispatch(setSearchDestinationToDate(toDates))
        dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
        dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
        dispatch(setHotelDetails({ data: [], loading: false, error: null }))
        dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
        dispatch(setAppliedPropertyTypeFiltersForHotels([]))
        dispatch(setAppliedRoomTypeFiltersForHotels([]))
        dispatch(setMobileAppliedPropertyTypeFilterForHotels([]))
        dispatch(setMobileAppliedRoomTypeFilterForHotels([]))
        dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: resetPriceRange?.min_price, max_price: resetPriceRange?.max_price }))
        dispatch(setHotelsTypeTabSelection(''))
        dispatch(setHotelSelectedVrImgAndData({ hotelName: '', hotelVrImg: '', hotelId: '', slugId: '' }))
        dispatch(setHotelIdSelected(0))
        dispatch(setHotelRoomPriceSelectedReset({ data: [], loading: false, error: null }))
        dispatch(setRoomRecommendationSelected({}))
        dispatch(setExploreApiDataReset({ data: [], loading: false, error: null }))
        dispatch(setExploreThingToDoSelectedReset({ data: [], loading: false, error: null }))
        dispatch(setExploreRegionSelectedReset({ data: [], loading: false, error: null }))
        dispatch(setWebFiltersEnable(false))
        dispatch(setMobileFiltersEnable(false))
        dispatch(setOrderSummarySectionType(orderSummarySction))
        dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
        dispatch(setResonsToBookingCancle({ status: '', response: [] }))
        dispatch(setCancellationPoliciesReset({ data: [], loading: false, error: null }))
        dispatch(setExploreHotelsToStayReset({ data: [], loading: false, error: null }));
        dispatch(setCancleBookingReset({ status: '', responce: {}, message: '' }))
        dispatch(setRoomTypeSelectedForCheckout({}))
        dispatch(setRoomIndexSelectedForCheckout(0))
        dispatch(setPreviousPathName(''))
        dispatch(setPassengerDetailsForCheckout([]))
        dispatch(setNoOfHitsResultApi(0))
        dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
        dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
        dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
        dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
        dispatch(setShowFaviourateList(false))
        dispatch(setFolderSelectedDetails({ folderName: '', folderId: 0 }))
        dispatch(setBookedOfferListDataReset({ data: [], loading: false, error: null }))
        dispatch(setBookedOfferDetailsReset({ data: [], loading: false, error: null }))
    };

    useEffect(() => {
        if (getUserDetails.data?.userDetail?.email && mainPopupForRegisterUser == false && setUserLoggedIn == false && getUserDetailsApiCall == false) {
            const loggedinCurrency = {
                userId: loggedInUserData?.id ? loggedInUserData?.id : 0,
                currencyId: loggedInUserData?.currency_id ? loggedInUserData?.currency_id : 21,
                currencyName: loggedInUserData?.currency_name ? loggedInUserData?.currency_name : 'USD',
                currencySymbol: loggedInUserData?.currency_symbol ? loggedInUserData?.currency_symbol : '$',
                countryOfResidenceCode: countryNationalCode,
                nationality: countryNationalCode
            }
            dispatch(setIsLogedInUser(true))
            const moeAttributes = {
                "Unique ID": userEmail,
                "First Name": firstName,
                "Last Name": lastName,
                "email": userEmail,
                "mobile_number": parseInt(userPhoneNumber),
                "gender": "male",
                "birthday": userBirthdate,
            }
            EventLogHanlderMoengage(eventNameLoginMoe, moeAttributes)
            // event log to analytics
            EventLogsHandler(eventNameLogin, { ig_registration_method: authenthicationType })
            dispatch(setLoggedInUserData(loggedinCurrency))
        }
        if (loggedInUserCurrency && Object?.keys(loggedInUserCurrency)?.length === 0 && loggedinUserDetails.currencyId && loggedinUserDetails.userId && loggedinUserDetails.currencyName) {
            dispatch(setLoggedInUserData(loggedinUserDetails))
        }
        // i18n.changeLanguage(language)
    }, [getUserDetails.data?.userDetail, getUserDetailsApiCall, mainPopupForRegisterUser, setUserLoggedIn, loggedinUserDetails, loggedInUserCurrency])

    //  Internationalizaion setup for language change feature
    const languageHandler = (e: any) => {
        const { value } = e.target;
        dispatch(setAppLanguage(value));
        i18n.changeLanguage(value);
        AppLogHandler("valuesssss", value)
    }

    AppLogHandler("Header Component", {
        "loggedInUserCurrency": loggedInUserCurrency,
        "loggedIn User details": getUserDetails
    })

    return (<>
        <Container sx={{ padding: '1.875rem 0', position: "relative" }} className={props.name}>
            <Grid container spacing={2} className='IgoRoomIcon'>
                <Grid item lg={3} xs={12} className='IgoRoomLogo'>
                    <img src={IgoRoomLogo} alt="IgoRoomLogo" onClick={home} />
                    <Box className="iGo_m-Menu" >
                        {pathname === '/property-details' ? '' : (<MobileMenu loggedinUserDetails={loggedinUserDetails} />)}
                    </Box>
                </Grid>
                <Grid item lg={6} sx={{
                    display: {
                        xs: 'none',
                        lg: 'block',
                    },
                }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        <IconButton className='iGoRoomNav'
                            onClick={() => navigationHandler(accountSectionType.latestOffers)}>
                            {t("Latest Offers")}
                        </IconButton>
                        <IconButton className='iGoRoomNav'
                            onClick={() => navigationHandler(accountSectionType.exploreDestinations)}>
                            <ExploreIcon className='iGoRoomIcon' />
                            {t("Explore")}
                        </IconButton>
                        <IconButton className='iGoRoomNav'
                            onClick={() => navigationHandler(accountSectionType.myFavourite)}>
                            <FavoriteBorderIcon className='iGoRoomIcon' />
                            {t("Favourites")}
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    {/* language change selector 
                    <select onChange={languageHandler}>
                        <option value='en'>En</option>
                        <option value='fr'>Fr</option>
                    </select> */}
                    <Box className='iGOr_mb-D-none '>
                        <CurrenciesModal setClass={props.currClass} />
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'right', }} className='iGOr_mb-D-none'>
                        {setUserLoggedIn == true ?
                            (<UserLoginPopover />) :
                            (<LoginAccountPopover />)}
                    </Box>
                </Grid>
            </Grid>
        </Container>
        <ToastContainer />
    </>);
}
export default Header;