import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../appStore/hooks';
import Header from '../../layouts/Header'
import { Box, Container, borderRadius, color, width } from '@mui/system';
import { Avatar, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, Popover, Skeleton, useMediaQuery, Modal, Fade, TextField } from '@mui/material';
import icon_Mask from '../../assets/icons/icon_Mask.svg';
import icon_saveWater from '../../assets/icons/icon_saveWater.svg';
import timerIcon from '../../assets/icons/timerIcon.svg';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import locationpin from '../../assets/icons/location-pin.svg';
import userLoginIcon from '../../assets/icons/Icon_user-outlined.svg';
import CheckCircleIcon from '@mui/icons-material/Check';
import { PropertyDetailsCards } from '../../components/PropertyDetailsCards/PropertyDetailsCards';
import icons_calendar from '../../assets/icons/icons_calendar_gray.svg';
import icon_wifi from '../../assets/icons/icon_wifi.svg';
import icon_pool from '../../assets/icons/icon_pool.svg';
import icon_Shower from '../../assets/icons/icon_Shower.svg';
import icon_GymBar from '../../assets/icons/icon_GymBar.svg';
import icon_Playground from '../../assets/icons/icon_Playground.svg';
import Icon_clinic from '../../assets/icons/Icon_clinic.svg';
import PropertyDAccordion from '../../components/PropertyDAccordion/PropertyDAccordion';
import { PropertyDetailsReview } from '../../components/PropertyDetailsReview/PropertyDetailsReview';
import DestinationRoomAdditionPopOver from '../../components/DestinationsHotelsResults/DestinationRoomAdditionPopOver';
import PropertyFacilitiesModal from '../../components/popUpSection/PropertyFacilitiesModal';
import PropertyShareModel from '../../components/popUpSection/PropertyShareModel';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import DestinationNightCount from '../../components/DestinationsHotelsResults/DestinationNightCount';
import { DatePickerComp } from '../../components/inputFieldCustom/DatePicker';
import { useTranslation } from 'react-i18next';
import { useInitApiMutation, useUpdateWishlistHotelsMutation } from '../../services/api/hotelApi'
import initApiCall from '../../services/initApiCall';
import NoHotelsImg from '../../assets/images/No Hotels Img.png'
import { useDispatch } from 'react-redux';
import { AdultCount, ChildCount } from '../../utils/handlers/arrayLoop';
import { fetchFaviourateFolderList, fetchHotelDetailsFromApi, fetchHotelDetailsUsingSlug, fetchHotelsRoomsDataFromApi, fetchRemoveFromFaviourates, setAddTofavouriteListReset, setAlertBoxOnError, setErrorMessageForDialogueBox, setHotelDetails, setHotelRoomDetails, setHotelSearchRequestReset, setHotelsResultsDataReset, setNoOfHitsResultApi, setOfferHotelsDataReset, setRemoveFromFaviouratesReset, setSearchDestinationData, setSearchDestinationFromDate, setSearchDestinationToDate, setSearchHotelsResultCall, setSelectedCurrencySymbol, setSessionExpired, setUserFaviouratesListReset, setUserWishlistDataReset } from '../../appStore/hotelSlice';
import { Footer } from '../../layouts/Footer';
import { PropDertailsCardsSkeleton } from '../../components/Skeleton/PropDertailsCardsSkeleton';
import { EventNamesForEventLogs, addHotelFavioratesType, countryResidenceAndCulture, eventNameForMoeEvents } from '../../utils/constants/constant';
import MapContainer from '../../utils/utilities/GoogleMap';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { dateConvertForExpiredCheckOut, dateFormatedForUrls, formatDateDateMonthYear, formatDateOnSearchResultPage } from '../../utils/utilities/DateConverter';
import ErrorShowMod from '../../components/popUpSection/ErrorShowMod';
import { Helmet } from 'react-helmet-async';
import { env } from '../../utils/config/appConfig';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { updateUserHotelWishlistPayload } from '../../services/updateWishlist';
import BackArrow from '@mui/icons-material/KeyboardArrowLeft';
import DateExpirationHandler from '../../utils/utilities/DateExpiration';
import { EventLogHanlderMoengage, EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import VTUrlParser from '../../utils/utilities/VTUrlParser';
import SEOPropertyDetails from '../../utils/utilities/SEOPropertyDetails';
import { searchValuesDispatch } from '../../components/DestinationsHotelsResults/SearchValuesDispatch';
import { environmentVariables } from '../../utils/config/environmentVariables';
import AddToFaviourates from './AddToFaviourates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import PropertyDetailsOffersCards from '../../components/PropertyDetailsCards/PropertyDetailsOffersCards';


export const PropertyDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const paramsFromUrl = useParams();
  const [reSearchRooms, setResearchRooms] = useState(false);
  const [shareData, setShareData] = useState({ title: '', description: '', imageUrl: '' });
  const [RoomanchorEl, setRoomAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { t } = useTranslation();
  const [checkInanchorEl, setCheckInAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { noOfNights, searchDestinationFromDate, searchDestinationToDate, hotelRoomDetails, loggedInUserCurrency, hotelDetails,
    initApi, roomArray, previousPathName, NoOfHitsResultApi, sessionExpired } = useAppSelector(state => state.hotel)
  const [roomArrayForGuest, setRoomArrayForGuest] = useState(roomArray);
  const [startDateForDestination, setStartDateForDestinationSearch] = useState(searchDestinationFromDate);
  const [endDateForDestination, setEndDateForDestinationSearch] = useState(searchDestinationToDate);
  const [numberOfNights, setNumberOfNights] = useState(noOfNights);
  const destinationSelectedData = useAppSelector(state => state.hotel.destinationsData)
  const hotel = useAppSelector(state => state.hotel.hotelDetails?.data?.hotel)
  const initApiStastus = initApi.status
  const roomsDataFromApi = hotelRoomDetails?.data?.rooms ? hotelRoomDetails?.data?.rooms : [];
  const roomsLoading = hotelRoomDetails?.loading;
  const RoomOpen = Boolean(RoomanchorEl)
  const roomId = RoomOpen ? 'simple-popover' : undefined;
  const checkInOpen = Boolean(checkInanchorEl)
  const checkInID = checkInOpen ? 'simple-popover' : undefined;
  const searchId = useAppSelector(state => state.hotel.initApi.searchId)
  const roomArrayForResearchRooms = roomArrayForGuest;
  const fromDate = new Date(startDateForDestination);
  const toDate = new Date(endDateForDestination);
  const formatedFromDate = formatDateOnSearchResultPage(startDateForDestination, 'detail')
  const formatedToDate = formatDateOnSearchResultPage(endDateForDestination, 'detail')
  const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
  const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
  const countryResidenceAndCultureInit = countryResidenceAndCulture
  const hotelsDetailsLoading = useAppSelector(state => state.hotel.hotelDetails.loading)
  const hotelsDetailsApiData = hotelDetails.data ? hotelDetails.data : [];
  const [searchParams] = useSearchParams();
  const serializedGuestArray: any = searchParams.get('guestArray')
  const decodedGuestArray: any = decodeURIComponent(serializedGuestArray);
  const guestArray = JSON.parse(decodedGuestArray);
  const imagePath = environmentVariables.imagePathApp;
  const environment = environmentVariables.enviornment;
  const adultsCount = AdultCount(roomArrayForResearchRooms);
  const childCount = ChildCount(roomArrayForResearchRooms);
  const matches = useMediaQuery('(max-width:1024px)');
  const slugIdForDetails = paramsFromUrl?.slugId ? paramsFromUrl?.slugId : '';
  let paramsCheckInCheckoutDates: any = {}
  let currencyFromParams = searchParams.get('currency')
  const eventNameViewProperty = EventNamesForEventLogs.viewPropertyDetails;
  const eventNameHotelViewMoe = eventNameForMoeEvents.HotelView;
  const eventNameSearchMoe = eventNameForMoeEvents.Search;
  const destinationName = hotel?.city_country;
  const hotelIdForEvent = hotel?.id;
  const hotelNameForEvent = hotel?.name
  const hotelPriceDefaultForEvent = roomsDataFromApi[0]?.listprice?.roomRecommendation[0]?.perNightTotal;
  const [AddtoWhishlishOpen, setAddtoWhishlishOpen] = React.useState(false);
  const faviourateType = addHotelFavioratesType.property;

  let idParamsFromHome: any = {
    searchIdForHotels: searchParams.get('searchId'),
    hotelIdForRooms: searchParams.get('hotelId'),
    destinationName: searchParams.get('destinationName'),
    destinationId: searchParams.get('destinationId'),
    checkInDate: searchParams.get('checkIn'),
    checkOutDate: searchParams.get('checkout'),
    guestRoomSelectedArray: guestArray,
  }

  // event logs for google analytics
  if (roomsDataFromApi?.length > 0) {
    const eventIdsForLogEvents = {
      "ig_content_id": hotelIdForEvent,
      "ig_price": hotelPriceDefaultForEvent,
      "ig_destination_a": destinationName,
      "ig_num_adults": adultsCount,
      "ig_num_children": childCount,
    }
    EventLogsHandler(eventNameViewProperty, eventIdsForLogEvents)

    // event logs for Moengage
    const eventIdsForLogEventsMoe = {
      "Hotel Name": hotelNameForEvent,
      "Hotel ID": hotelIdForEvent,
      "Price": hotelPriceDefaultForEvent
    }
    EventLogHanlderMoengage(eventNameHotelViewMoe, eventIdsForLogEventsMoe)
  }


  // initialization init api
  const [iniApiCall, initApiCallLoading] = useInitApiMutation();


  const handleRoomClick = (event: any) => {
    setRoomAnchorEl(event.currentTarget)
  }
  const checkInhandleClick = (event: any) => {
    setCheckInAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setCheckInAnchorEl(null)
  }

  // get hotel details handler
  const getHotelsDetailsHandler = () => {
    dispatch(fetchHotelDetailsFromApi({ searchId: searchId, hotelId: idParamsFromHome.hotelIdForRooms }))
  }

  // get hotel details using slug handler
  const getHotelsDetailsUsingSlugHandler = () => {
    dispatch(fetchHotelDetailsUsingSlug(slugIdForDetails))
  }

  // fetch rooms on date and guest change init api call
  const fetchRoomsHandler = (hotelId: string) => {
    dispatch(fetchHotelsRoomsDataFromApi({ searchId: searchId, hotelId: hotelId }))
  }

  let locationId = destinationSelectedData?.destinationCode

  // event logs for Moengage
  const moengageEventLogHanlder = () => {
    const eventIdsForLogEventsMoe = {
      "Search term": destinationSelectedData.destinationName,
      "Booking date start": new Date(fromDate),
      "Booking date finish": new Date(toDate),
      "Number of guest": adultsCount + childCount
    }
    EventLogHanlderMoengage(eventNameSearchMoe, eventIdsForLogEventsMoe)
  }

  // research room handler
  const searchHandler = async () => {
    const searchDataDispatched = await dispatch(searchValuesDispatch(
      numberOfNights,
      roomArrayForGuest,
      startDateForDestination,
      endDateForDestination,
    ))
    dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
    const payload = initApiCall(loggendInUserCurrency, fromDate, toDate, roomArrayForResearchRooms,
      countryResidenceAndCultureInit.countryOfResidence, countryResidenceAndCultureInit.nationality,
      countryResidenceAndCultureInit.culture, locationId, loggedInUserId)
    iniApiCall({ payload })
    setResearchRooms(true)
    moengageEventLogHanlder()
  }

  // init call when search id expired
  const generateSearchIdHandler = () => {
    let checkInDateFourtyFiveDaysLater = new Date(paramsCheckInCheckoutDates.checkInDateForparams);
    checkInDateFourtyFiveDaysLater.toISOString()
    let checkOutDateFourtyFiveDaysLater = new Date(paramsCheckInCheckoutDates.checkOutDateForparams)
    checkOutDateFourtyFiveDaysLater.toISOString()
    const payload = initApiCall(currencyFromParams, checkInDateFourtyFiveDaysLater, checkOutDateFourtyFiveDaysLater,
      roomArrayForResearchRooms, countryResidenceAndCultureInit.countryOfResidence, countryResidenceAndCultureInit.nationality,
      countryResidenceAndCultureInit.culture, idParamsFromHome.destinationId, loggedInUserId)
    iniApiCall({ payload })
    dispatch(setSearchDestinationFromDate(checkInDateFourtyFiveDaysLater))
    dispatch(setSearchDestinationToDate(checkOutDateFourtyFiveDaysLater))
  }


  // session expiration handler
  const sessionExpiredHandler = () => {
    dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
    dispatch(setHotelsResultsDataReset({ data: [''], loading: false, error: null }))
    dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
    dispatch(setSearchDestinationData({ destinationName: idParamsFromHome.destinationName, destinationCode: idParamsFromHome.destinationId }))
    dispatch(setSearchHotelsResultCall(true))
    dispatch(setSessionExpired(false))
    navigate('/')
  }


  const openAddToFaviouratesSection = (userId: number) => {
    if (userId !== 0) {
      setAddtoWhishlishOpen(true);
      dispatch(fetchFaviourateFolderList(userId))
    }
    else {
      dispatch(setErrorMessageForDialogueBox('15'))
      dispatch(setAlertBoxOnError(true))
    }
  }



  const removeFromWishlist = (userId: number, faviourateId: number) => {
    dispatch(fetchRemoveFromFaviourates({ userId: userId, faviourateId: faviourateId, type: faviourateType }))
  }

  // naigation handler when press back button on mobile
  const navigateToSearchScreen = () => {
    if (previousPathName == "/latest-offers") {
      navigate("/latest-offers")
    }
    else if (previousPathName == "/explore-more") {
      navigate(`${previousPathName}`)
    }
    else if (previousPathName == "/favourites" && loggedInUserId !== 0) {
      //need to remove this after 2-3 days
      dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
      dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
      
      navigate(`${previousPathName}`)
      dispatch(fetchFaviourateFolderList(loggedInUserId))
    }
    else if (previousPathName == '/') {
      navigate(`${previousPathName}`)
    }
    else {
      navigate(`/search-results?destinationName=${idParamsFromHome.destinationName}&destinationId=${idParamsFromHome.destinationId}&checkIn=${paramsCheckInCheckoutDates.checkInDateForparams}&checkout=${paramsCheckInCheckoutDates.checkOutDateForparams}&currency=${currencyFromParams}`)
    }
  }

  // logic while search id was expired
  useEffect(() => {
    // date checkoutHandler
    paramsCheckInCheckoutDates = DateExpirationHandler(idParamsFromHome)

    if (hotel?.listPrice?.currency_symbol) {
      dispatch(setSelectedCurrencySymbol(hotel.listPrice.currency_symbol))
    }

    if (currencyFromParams == undefined || currencyFromParams == null || currencyFromParams == '') {
      currencyFromParams = loggendInUserCurrency;
    }

    else if (searchId != '' && hotelsDetailsApiData?.statusCode?.includes('BadRequest') && hotelsDetailsApiData?.message?.includes('Object reference not set to an instance of an object.')) {
      dispatch(setHotelDetails({ data: [], loading: false, error: null }))
      dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
      paramsCheckInCheckoutDates = DateExpirationHandler(idParamsFromHome)
      dispatch(setErrorMessageForDialogueBox('25'))
      dispatch(setAlertBoxOnError(true))
    }
    else if (sessionExpired == true) {
      sessionExpiredHandler()
    }
  }, [currencyFromParams, sessionExpired, hotelsDetailsApiData.hotel, hotelsDetailsApiData?.statusCode,
    hotelsDetailsApiData?.message, searchId, loggendInUserCurrency, reSearchRooms])

  // get details and rooms from query params
  const hotelIdSelected = idParamsFromHome.hotelIdForRooms
  useEffect(() => {
    if (searchId && hotelIdSelected && reSearchRooms == false) {
      getHotelsDetailsHandler()
      setResearchRooms(false)
    }
    else if (searchId && hotelIdSelected && reSearchRooms == true) {
      fetchRoomsHandler(hotelIdSelected)
    }

    if (searchId && hotelIdSelected && reSearchRooms == false) {
      fetchRoomsHandler(hotelIdSelected)
    }


  }, [hotelIdSelected, searchId, reSearchRooms])

  // logic for hotels details and room api hit related to slug from query params
  useEffect(() => {
    if (searchId == '' && reSearchRooms == false && hotelsDetailsApiData?.hotel?.id && NoOfHitsResultApi == 0) {
      generateSearchIdHandler()
    }
    else if (slugIdForDetails != '' && searchId == '' && !idParamsFromHome.hotelIdForRooms && reSearchRooms == false) {
      getHotelsDetailsUsingSlugHandler();
    }
    else if (reSearchRooms == false && hotelsDetailsApiData?.hotel?.id && searchId !== '' && !idParamsFromHome.hotelIdForRooms) {
      fetchRoomsHandler(hotelsDetailsApiData.hotel?.id)
    }
    else if (reSearchRooms == true && hotelsDetailsApiData?.hotel?.id && searchId !== '' && !idParamsFromHome.hotelIdForRooms) {
      fetchRoomsHandler(hotelsDetailsApiData?.hotel?.id)
    }
    else if (slugIdForDetails == '') {
      dispatch(setErrorMessageForDialogueBox('26'))
      dispatch(setAlertBoxOnError(true))
    }
  }, [searchId, hotelsDetailsApiData?.hotel?.id, idParamsFromHome.hotelIdForRooms, reSearchRooms, currencyFromParams,
    paramsCheckInCheckoutDates.checkInDateForparams, paramsCheckInCheckoutDates.checkOutDateForparams, slugIdForDetails
    , NoOfHitsResultApi])


  return (<>
    <Container className="iGor_headerPro">
      <Header name='iGor_ProDetHeader' />
      <Button className='iGor__ProBackArrow' onClick={navigateToSearchScreen}><BackArrow /></Button>
    </Container>
    <Box sx={{ background: '#fff',position:'relative' }}>
      <ErrorShowMod />
      <Box className='iGor_PropertyDetails'>
        <Box className="iGor_iFrameimg">
          {hotelsDetailsLoading == true ? (<>
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} sx={{ bgcolor: 'grey.900' }} />
          </>) :
            // VTUrlParser
            hotel?.image?.vr_img ? environment !== 'development' ? (<iframe src={VTUrlParser(hotel?.image?.vr_img)} className="iGor_ProDetIframe"></iframe>) : ((<iframe src={hotel?.image?.vr_img} className="iGor_ProDetIframe"></iframe>))
              : (<img src={NoHotelsImg} className="iGor_ProDetImage" />)
          }
        </Box>
        <Container >
          <Grid container spacing={2} className='iGor__prodetTop'>
            <Grid item sm={12} className="iGor_ProDetDescription">
              <Box className="box-review">
                <Box className="value"><span>{hotel?.googleReviewRating ? parseFloat(hotel?.googleReviewRating).toFixed(1) : 0}                </span></Box>
                <Box className="text-value"><span>{hotel?.userTotalReview ? hotel?.userTotalReview : 0}</span><span> reviews</span></Box>
              </Box>
              <Box className="title-wrapper">
                <Box className="subtitle">
                  <img src={timerIcon} alt="time" />
                  <span>{t('Travel until')} {formatDateDateMonthYear(toDate)}</span>
                </Box>
                <Box className="title">
                  <h2>{hotel?.name}</h2>
                  <Box className='iGor_topLocTitel'>
                    <img src={locationpin} alt="time" />
                    <span>{hotel?.city_country || 'Loading..'}</span>
                  </Box>
                </Box>
                <Box className="btn-wrapper">
                  <Button className="btn-outline" variant="outlined"> {hotel?.wish == true ? (
                    <Box
                      className='iGor_wishListDetailsScreen'
                      onClick={(e) => removeFromWishlist(loggedInUserId, hotel?.id)}
                    >
                      <FavoriteIcon sx={{ color: '#FF6B6B' }}
                        className='iGor_btnIcon' />
                      <span>Remove From Wishlist</span>
                    </Box>)
                    :
                    (<Box
                      className='iGor_wishListDetailsScreen'
                      onClick={(e) => openAddToFaviouratesSection(loggedInUserId)}>
                      <FavoriteBorderIcon
                        className='iGor_btnIcon' />
                      <span>Add To Wishlist</span>
                    </Box>)}
                  </Button>
                  <PropertyShareModel hotelDetails={hotel} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        {/* <Helmet>
          <meta name="description" content={`Blog post about ${hotel?.description}`} />
          <meta property="og:title" content={encodeURIComponent(`${hotel?.name}`)} />
          <meta property="og:description" content={encodeURIComponent(`${hotel?.description}`)} />
          <meta property="og:image" content={encodeURIComponent(`${imagePath + hotel?.image?.pathIMG}`)} />
        </Helmet> */}
        <SEOPropertyDetails
          // <meta name="description" content={`Blog post about ${hotel?.description}`} />
          // <meta property="og:title" content={encodeURIComponent(`${hotel?.name}`)} />
          // <meta property="og:description" content={encodeURIComponent(`${hotel?.description}`)} />
          // <meta property="og:image" content={encodeURIComponent(`${imagePath + hotel?.image?.pathIMG}`)} />
          title='igoroom'
          description='Beginner friendly page for learning React Helmet.'
          name='Company name.'
          type='article' />
      </Box >
      <Container>
        <Grid container spacing={2} className="iGor_ProDet-hotel-inf">
          <Grid item className="iGor_ProDet-hotel-info-des">
            <Typography variant='subtitle1'>Hotel information</Typography>
            <Typography className='iGor_ProDet-hotel-discription' marginY={1}>{hotel?.description || 'Not available right now'}</Typography>
          </Grid>
          {/* <Grid item sm={6} className="iGor_ProDet-hotel-info-list">
            <Typography variant='subtitle1' className='iGor__accoText'>This Accomodation is Clean & Safe</Typography>
            <Box className="iGor__ListSection">
              <List className='iGor_ProDList'>
                <ListItem>
                  <ListItemAvatar>
                    <Box className='boxIcon'>
                      <img src={icon_Mask}
                        alt="masker" />
                      <CheckCircleIcon className='iGor_ProDetCheck' />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText primary="Personal Protective Equipment" secondary="All Conrad Luxury Resort & Spa staff wearing personal protective equipment everytime
                  on every hotel are." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Box className='boxIcon'>
                      <img src={icon_saveWater} alt="hand" />
                      <CheckCircleIcon className='iGor_ProDetCheck' />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText primary="Cleaning & Disinfection" secondary="Every area and facilities has been disinfected with our team." />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Box className='boxIcon'>
                      <img src={icon_saveWater} alt="hand" />
                      <CheckCircleIcon className='iGor_ProDetCheck' />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText primary="Body Temperature Check" secondary="All staff and customers have gone through the process of checking body temperature" />
                </ListItem>
              </List>
            </Box>
          </Grid> */}
        </Grid>
      </Container>
      <Box className='iGor_searchbar-innerpage'>
        <Box>
          <img src={icons_calendar} className='iGoe_ser_calIcon' />
        </Box>
        <Box className={matches ? 'iGor_bar_mb ' : `iGor_bar`}>
          <Typography className='iGor_Ser_Title'> When & How long is your trip?</Typography>
          <div onClick={checkInhandleClick}>
            <Typography className='iGor_ser_SubTitle'> {formatedFromDate} - {formatedToDate}</Typography>
          </div>
        </Box>
        <Popover
          id={checkInID}
          open={checkInOpen}
          anchorEl={checkInanchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box className='iGor_proDetWrapper'  >
            <DestinationNightCount
              numberOfNights={numberOfNights}
              setNumberOfNights={setNumberOfNights}
              endDateForDestination={endDateForDestination}
              setEndDateForDestinationSearch={setEndDateForDestinationSearch}
            />
            <DatePickerComp
              setNumberOfNights={setNumberOfNights}
              startDateForDestination={startDateForDestination}
              endDateForDestination={endDateForDestination}
              setEndDateForDestinationSearch={setEndDateForDestinationSearch}
              setStartDateForDestinationSearch={setStartDateForDestinationSearch}
              reSearchRooms={reSearchRooms}
            />
            <Button variant='contained' onClick={() => { setCheckInAnchorEl(null), matches ? searchHandler() : '' }}>{t("Continue")}</Button>
          </Box>
        </Popover>
        <Box className={`iGor_proDetlisGuests iGor_setFlex ${!matches ? 'iGOr_mb-D-none' : ''}`}>
          {!matches ? (<Box className='iGOr_mb-D-none'>
            <img src={userLoginIcon} className='iGoe_ser_UserIcon' />
          </Box>) : ''}
          <Box className=''>
            {!matches ? (<Typography className='iGor_Ser_Title'>Guests</Typography>) : ''}
            <div onClick={(event) => { handleRoomClick(event) }} aria-describedby={roomId}>
              <Typography className='iGor_ser_SubTitle'>
                {`${adultsCount > 1 ? adultsCount + t(' Adults') : childCount == 0 ? t("I’m alone") : adultsCount + t(' Adult')}`} {childCount > 0 ? ", " + childCount + ` ${childCount > 1 ? t('Children') : t('Child')}` : ''}
              </Typography>

            </div>
            <DestinationRoomAdditionPopOver
              setRoomArrayForGuest={setRoomArrayForGuest}
              roomArrayForGuest={roomArrayForGuest}
              setRoomAnchorEl={setRoomAnchorEl}
              RoomanchorEl={RoomanchorEl}
              roomId={roomId}
              open={RoomOpen}
            />
          </Box>
          <Button variant='contained'
            className='iGor_SerBtn iGOr_mb-D-none'
            onClick={searchHandler}
            disabled={initApiCallLoading?.isLoading == true || roomsLoading == true ? true : false}
          >
            {initApiCallLoading?.isLoading == true || roomsLoading == true ? <Box className='iGor-action_loader'>
              <FontAwesomeIcon icon={faSpinner} spin />
            </Box> :
              'Search'}
          </Button>
        </Box>
      </Box>
    </Box >
    <Container className="iGor__ProDetailsCardS">
      <Box paddingY={2} className='iGor_procardAvil'>
        <Typography variant='subtitle1'> Available room options</Typography>
      </Box>
      <PropertyDetailsCards
        initApiLoading={initApiCallLoading?.isLoading}
      />
    </Container>
    <Container className="iGor__ProDetailsCardS" style={{ marginTop: '0px' }}>
      <PropertyDetailsOffersCards
        initApiLoading={initApiCallLoading?.isLoading}
      />
    </Container>
    <Box className='iGOr__ProHotelFacAme' >
      <Container >
        <Grid container>
          <Grid item sm={6} xs={12} className='iGor__Pro_Facility'>
            <Typography variant='subtitle1' className='iGor__HotelFacHead'>Hotel Facilities & Amenities</Typography>
            <Box className='iGor_FacilAmen'>
              <Box className='iGor_FacilAmen_Icon'>
                <img src={icon_wifi} alt='icon_wifi' />
                <Typography>Wifi </Typography>
              </Box>
              <Box className='iGor_FacilAmen_Icon'>
                <img src={icon_pool} alt='icon_pool' />
                <Typography>Pool</Typography>
              </Box>
              <Box className='iGor_FacilAmen_Icon'>
                <img src={icon_Shower} alt='icon_Shower' />
                <Typography>Shower </Typography>
              </Box>
              <Box className='iGor_FacilAmen_Icon'>
                <img src={icon_Playground} alt='icon_Playground' />
                <Typography>Park </Typography>
              </Box>
              <Box className='iGor_FacilAmen_Icon'>
                <img src={Icon_clinic} alt='Icon_clinic' />
                <Typography>Clinic </Typography>
              </Box>
              <Box className='iGor_FacilAmen_Icon'>
                <img src={icon_GymBar} alt='icon_wifi' />
                <Typography>Gym </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <PropertyFacilitiesModal btn='one' listing_details={hotel} />
            </Box>
          </Grid>
          <Grid item sm={6} xs={12} className='iGor__Pro_ThtoKnow'>
            <Typography variant='subtitle1' pl={"2rem"}>Things to Know</Typography>
            <Box padding={"1rem 2rem"}>
              <PropertyDAccordion hotelDetail={hotel} />
            </Box>
          </Grid>
          <PropertyDetailsReview hotelDetail={hotel} />
        </Grid>
      </Container>
    </Box>
    <Container>
      <Box paddingY={2}>
        <Typography variant='subtitle1'>Hotel Location</Typography>
      </Box>
      <Box className='iGOr__ProDeMap'>
        <MapContainer latitude={hotel?.latitude} longitude={hotel?.longtitude} />
      </Box>
    </Container>
    <Footer />

    <AddToFaviourates
      faviourateType={faviourateType}
      AddtoWhishlishOpen={AddtoWhishlishOpen}
      setAddtoWhishlishOpen={setAddtoWhishlishOpen}
      hotelIdSelected={hotel?.id}
      formatedFromDate={formatedFromDate}
      formatedToDate={formatedToDate}
    />
  </>
  )
}