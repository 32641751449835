import { useDispatch } from 'react-redux'
import { fetchHotelDetailsFromApi, fetchHotelsRoomsDataFromApi, setHotelDetails, setHotelRoomDetails, setHotelSearchRequestReset } from '../../appStore/hotelSlice'
import { useNavigate } from 'react-router';
import { useInitApiMutation } from '../../services/api/hotelApi';
import { countryResidenceAndCulture } from '../../utils/constants/constant';
import initApiCall from '../../services/initApiCall';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from 'react-i18next';
import NoHotelsImg from '../../assets/images/noHotelIgo.jpg'
import StarIcon from '@mui/icons-material/Star';
import { Box } from '@mui/system';
import { dateFormatedForUrls } from '../../utils/utilities/DateConverter';

function HotelCardMap({ hotelInfo }: any) {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchOfferHotel, setSearchOfferHotel] = useState(false);
    const [destinationSelectedObj, setDestinationSelectedObj]:any = useState({});
    const { initApi, loggedInUserCurrency, hotelDetails, searchDestinationFromDate, searchDestinationToDate, roomArray } = useAppSelector(state => state.hotel)
    const searchId = initApi?.searchId;
    const destinationSearchStartDate = searchDestinationFromDate;
    const destinationSearchEndDate = searchDestinationToDate;
    const hotelRoomArray = roomArray
    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const hotelDetailsFromApi = hotelDetails.data;
    const countryResidenceAndCultureInit = countryResidenceAndCulture;
    // init api mutation intialization
    const [initApiForHotel] = useInitApiMutation();

    const startRating = Array.from({ length: hotelInfo.hotelRating }, (_, index) => index);
    const startDateFormated = dateFormatedForUrls(destinationSearchStartDate);
    const endDateFormated = dateFormatedForUrls(destinationSearchEndDate);

    // checkout offers Selection Handler
    const offersCheckoutHandler = async (offerSelected: any) => {
        const locationId = offerSelected.country;
        const hotelId = offerSelected.id;
        const destinationName = offerSelected?.destinationName?.replaceAll(" ", "-");
        const hotelNameSlug = offerSelected?.hotelName?.toLowerCase();
        const slug = offerSelected?.slug;
        setDestinationSelectedObj({
            destinationName: destinationName,
            locationId: locationId,
            hotelId: hotelId,
            slugId: slug ? slug : hotelNameSlug.replaceAll(" ", "-")
        })
        dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
        dispatch(setHotelRoomDetails({ data: [] }))
        dispatch(setHotelDetails({ data: [] }))
        const payload = await initApiCall(loggendInUserCurrency, destinationSearchStartDate, destinationSearchEndDate,
            hotelRoomArray, countryResidenceAndCultureInit.countryOfResidence, countryResidenceAndCultureInit.nationality,
            countryResidenceAndCultureInit.culture, locationId, loggedInUserId)
        initApiForHotel({ payload })
        setSearchOfferHotel(true)
    }

    // use Effect for navigate to hotel detail after click popular destination details screen
    useEffect(() => {
        if (hotelDetailsFromApi.success == true && searchOfferHotel == true) {
            setSearchOfferHotel(false)
            // dispatch(fetchHotelsRoomsDataFromApi({ searchId: searchId, hotelId: hotelId }))
            // dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
            // navigate('/property-details')
        }
        else if (hotelDetailsFromApi.success == false) {
            dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
            dispatch(setHotelDetails({ data: [], loading: false, error: null, }))
            setSearchOfferHotel(false)
        }
        else if (searchId && destinationSelectedObj.hotelId != 0 && searchOfferHotel == true) {
            const slugId = destinationSelectedObj.slugId;
            const destinationName = destinationSelectedObj.destinationName;
            const locationId = destinationSelectedObj.locationId;
            const hotelId = destinationSelectedObj.hotelId;
            navigate(`/property-details/${slugId}?hotelId=${hotelId}&destinationName=${destinationName}&destinationId=${locationId}&checkIn=${startDateFormated}&checkout=${startDateFormated}&currency=${loggendInUserCurrency}`)
        }
    }, [searchId, destinationSelectedObj, hotelDetailsFromApi, searchOfferHotel])

    return (<>
        <div
            id='hotel Card'
            className='iGor-MarkerHotelCard'
            onClick={() => offersCheckoutHandler(hotelInfo)}
        >
            <img
                src={hotelInfo.bgImgHotel ? hotelInfo.bgImgHotel : NoHotelsImg}
                onError={(e: any) => {
                    e.target.src = NoHotelsImg;
                }}
                alt='no img available'>
            </img>
            <Box className='iGor-rating'>
                {startRating.map((ratingIndex: any) => (
                    <StarIcon className='iGor_starIcon' />))}
            </Box>
            <h3>{hotelInfo.hotelName}</h3>
        </div>
    </>);
}

export default HotelCardMap;
