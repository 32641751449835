import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Icon_360 from '../../assets/icons/Icon_360.svg';
import Iconclockhistory from '../../assets/icons/Icon_clock-history.svg';
import icon_room from '../../assets/icons/icon_room.svg';
import Icon_bed from '../../assets/icons/Icon_bed.svg';
import Icon_shaer from '../../assets/icons/Icon_shaerWithBg.svg';
import icon_set from '../../assets/icons/icon_set.svg';
import PropertyFacilitiesModal from '../popUpSection/PropertyFacilitiesModal';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import NoHotelsImg from '../../assets/images/No Hotels Img.png'
import PropertyRoomsRecomendations from './PropertyRoomsRecommendations';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { fetchHotelsRoomPriceDataFromApi, setGuestDetailsAtCheckIn, setHotelRoomPriceSelectedReset, setPreviousPathName, setRoomIndexSelectedForCheckout, setRoomRecommendationIndexSelected, setRoomRecommendationSelected, setRoomTypeSelectedForCheckout } from '../../appStore/hotelSlice';
import { useAppSelector } from '../../appStore/hooks';
import { env } from '../../utils/config/appConfig';
import PassengersCount from '../../utils/utilities/PassengerCounts';
import { useTranslation } from 'react-i18next';
import { PropDertailsCardsSkeleton } from '../Skeleton/PropDertailsCardsSkeleton';
import VTUrlParser from '../../utils/utilities/VTUrlParser';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import roomsAndGuestCountHandler from '../../utils/utilities/RoomAndGuestCount';
import PropertyRoomVrSectionViewer from './PropertyRoomVrSection';
import { PropertyRoomSelectionFunction } from './PropertyRoomSelection';
import { environmentVariables } from '../../utils/config/environmentVariables';
import PropertyFacilitiesIconMapper from './PropertyFacilitiesIconMapper';
import { ArrowDropUpOutlined } from '@mui/icons-material';

export const PropertyDetailsCards = ({ initApiLoading }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:1024px)');
    const [cardShow, setAmenitiesCardShow] = useState(false);
    const [moreOptions, setMoreOptions] = useState(false);
    const [moreOptionsIndex, setMoreOptionsIndex]: any = useState(null);
    const [iframeShow, setIframeShow] = useState(false);
    const [defaultRecommendation, setDefaultRecommendation] = useState(true)
    const [selectedRoomId, setSelectedRoomId] = useState(0);
    const [selectedDivIndex, setSelectedDivIndex] = useState(0);
    const [iframeIndexId, setIframeIndexId] = useState(null);
    const [seeAmenitiesIndex, setSeeAmenitiesIndex]: any = useState(null);
    const { roomRecommendationSelected, hotelIdSelected, hotelRoomDetails, roomIndexSelectedForCheckout, roomTypeSelectedForCheckout } = useAppSelector(state => state.hotel)
    const searchId = useAppSelector(state => state.hotel.initApi?.searchId);
    const roomArrayForGuest = useAppSelector(state => state.hotel.roomArray);
    const hotelsDetailsLoading = useAppSelector(state => state.hotel.hotelDetails.loading)
    const roomsReceivedFromApis = hotelRoomDetails?.data?.rooms ? hotelRoomDetails?.data?.rooms : [];
    const roomsLoading = hotelRoomDetails?.loading;
    const imagePath = environmentVariables.imagePathApp;
    const pathnameFromUrl = window.location.pathname;
    const pathForPrevious = window.location.pathname + window.location.search;
    const upgradeRoomIndex = roomIndexSelectedForCheckout;
    const lastUpgradeLastIndex = roomsReceivedFromApis.length - 1;
    const pathname = window.location;
    const hrefDetails = pathname.pathname + pathname.search;
    // const hrefDetails = pathnameFromUrl.pathname + pathnameFromUrl.search;
    const showRoomVirtually = (roomId: any) => {
        setIframeShow(true)
        setSelectedRoomId(roomId)
    }
    // room checkout handler on recommendation selected 
    const roomCheckoutHandler = async (roomRecommendations: any, roomSelectedForCheckout: any, roomIndexSelected: number) => {
        dispatch(setRoomIndexSelectedForCheckout(roomIndexSelected))
        if (pathForPrevious.includes('/property-details')) {
            dispatch(setPreviousPathName(pathForPrevious))
        }
        if (pathnameFromUrl == '/order-summary') {
            dispatch(setRoomRecommendationIndexSelected(selectedDivIndex))
        }
        else if (pathnameFromUrl.includes('/property-details')) {
            dispatch(setRoomRecommendationIndexSelected(selectedDivIndex))
        }
        let res = await dispatch(PropertyRoomSelectionFunction(
            roomRecommendations,
            roomSelectedForCheckout,
            searchId,
            defaultRecommendation,
            roomRecommendationSelected,
            hotelIdSelected,
            roomArrayForGuest,
            roomIndexSelected,
            navigate
        ))

        // dispatch(setHotelRoomPriceSelectedReset({ data: [], loading: false, error: null }))
        // const passengerCountsForDetails: any = roomsAndGuestCountHandler(roomArrayForGuest)
        // let selectedRoomRecommForCheckout: any

        // if (defaultRecommendation == false) {
        //     [selectedRoomRecommForCheckout] = roomRecommendations.filter((rooms: any) => rooms.id == roomRecommendationSelected.id)
        // }
        // else if (!selectedRoomRecommForCheckout && defaultRecommendation == true) {
        //     selectedRoomRecommForCheckout = roomRecommendations[0]
        //     dispatch(setRoomRecommendationIndexSelected(0))
        // }
        // if (selectedRoomRecommForCheckout == undefined) {
        //     selectedRoomRecommForCheckout = roomRecommendations[0]
        //     dispatch(setRoomRecommendationIndexSelected(0))
        // }

        // const roomPriceId = selectedRoomRecommForCheckout?.id ? selectedRoomRecommForCheckout?.id : ''
        // const [roomGroup] = selectedRoomRecommForCheckout?.roomGroup ? selectedRoomRecommForCheckout?.roomGroup : []
        // AppLogHandler('roomSelectedForCheckout', { roomSelectedForCheckout, selectedRoomRecommForCheckout, roomGroup })

        // if (searchId != '' && selectedRoomRecommForCheckout && roomSelectedForCheckout && roomGroup.providerName && roomPriceId && roomGroup.refundability == 'Refundable') {
        //     dispatch(setRoomTypeSelectedForCheckout(roomSelectedForCheckout))
        //     dispatch(fetchHotelsRoomPriceDataFromApi({ searchId: searchId, providerName: roomGroup.providerName, priceId: roomPriceId, hotelId: hotelIdSelected }))
        //     navigate('/order-summary')
        //     dispatch(setGuestDetailsAtCheckIn(passengerCountsForDetails))
        // }
        // else if (roomGroup.refundability == 'NonRefundable') {
        // }
    }
    // made seprate component for this
    // const showVrSectionHandler = (hotelrooms: any) => {
    //     return (
    //         <>
    //             <Box className="img">
    //                 <img
    //                     src={hotelrooms?.image ? `${imagePath}${hotelrooms.image}` : NoHotelsImg}
    //                     alt="Villa with Ocean View and Infinity Pool"
    //                     onError={(e: any) => {
    //                         // Handle the image load error by setting the source to the default image
    //                         e.target.src = NoHotelsImg;
    //                     }}
    //                 />
    //             </Box>
    //             {hotelrooms?.vrroom ? (
    //                 <><Box className="icon-wrapper" onClick={() => showRoomVirtually(hotelrooms.id)} >
    //                     <img src={Icon_360} alt="sphere" />
    //                 </Box >
    //                     <Box className='iGor_iconZoom'>
    //                         <Link
    //                             to={'/property-details-view'}
    //                             state={{
    //                                 roomSelected: hotelrooms,
    //                                 href: hrefDetails,
    //                                 selectedRoomId: selectedRoomId,
    //                                 roomRecommendationSelected: roomRecommendationSelected,
    //                                 defaultRecommendation: defaultRecommendation
    //                             }}
    //                         >
    //                             <img src={icon_set} alt="icon_set" />
    //                         </Link>
    //                     </Box></>) : ('')}
    //         </>
    //     )
    // }

    const RoomDetailComponent = ({ hotelroomsForDiscription }: any) => {
        const [showFullText, setShowFullText] = useState(false);

        const toggleText = () => {
            setShowFullText(!showFullText);
        };

        return (
            <Box className="text-wrapper">
                <Typography>
                    {showFullText
                        ? hotelroomsForDiscription?.packagedescription || 'No information available'
                        : hotelroomsForDiscription?.packagedescription ? `${hotelroomsForDiscription?.packagedescription?.slice(0, 100)}...` : 'No information available'}
                </Typography>
                {hotelroomsForDiscription?.packagedescription ?
                    (<Box sx={{ textAlign: 'right' }}>
                        <Button onClick={toggleText} className='iGor__ReadMoreBtn'>
                            {showFullText ? 'Read Less' : 'Read More'}
                        </Button>
                    </Box>
                    ) : (<Button className='iGor__ReadMoreBtn' />)
                }
            </Box>);
    };

    const handleClickAccordion = (index: any) => {
        setSeeAmenitiesIndex(index)
        // setSelectedRoomId((prevId) => (prevId === roomId ? null : roomId));
        setAmenitiesCardShow(true)
    };

    const recommendationsViewHandler = (roomIndex: any, showReco: boolean) => {
        setMoreOptionsIndex(roomIndex)
        setMoreOptions(showReco)
    }

    // if no room upgrade not available
    const noUpgradeRoom = roomIndexSelectedForCheckout == lastUpgradeLastIndex && pathnameFromUrl == '/order-summary' && (
        <Box className='iGor_bgWhite iGOr_BorderRadius15' sx={{ padding: '16px 20px 16px 23px' }}>
            <Box key="no-services-message" className="iGor-noService-Available">
                {t('5014')}
            </Box></Box>
    );

    return (
        <>{initApiLoading == true || hotelsDetailsLoading == true || roomsLoading == true ? <PropDertailsCardsSkeleton /> : (
            roomsReceivedFromApis.length > 0 ? roomsReceivedFromApis.map((hotelrooms: any, roomIndex: number) => {
                if (pathnameFromUrl.includes('/property-details') && hotelrooms.isOffer == false) {
                    return (<Box className="iGOr_card-listing">
                        <Box className="top">
                            <a className="image">
                                {!iframeShow && (<PropertyRoomVrSectionViewer
                                    hotelrooms={hotelrooms}
                                    selectedRoomId={selectedRoomId}
                                    defaultRecommendation={defaultRecommendation}
                                    setSelectedRoomId={setSelectedRoomId}
                                    setIframeShow={setIframeShow}
                                    roomIndex={roomIndex}
                                    selectedDivIndex={selectedDivIndex}
                                    setIframeIndexId={setIframeIndexId}
                                />)}
                                {iframeShow ? (roomIndex == iframeIndexId ? (<><Box className="iframe">
                                    <iframe src={VTUrlParser(hotelrooms?.vrroom)} ></iframe>
                                </Box >
                                    <Box className='iGor_iconZoom'>
                                        <Link
                                            to={'/property-details-view'}
                                            state={{
                                                roomSelected: hotelrooms,
                                                href: hrefDetails,
                                                selectedRoomId: selectedRoomId,
                                                roomRecommendationSelected: roomRecommendationSelected,
                                                defaultRecommendation: defaultRecommendation,
                                                roomIndexSelected: roomIndex,
                                                selectedDivIndex: selectedDivIndex
                                            }}
                                        >
                                            <img src={icon_set} alt="icon_set" />
                                        </Link>
                                    </Box></>
                                )

                                    : (<PropertyRoomVrSectionViewer
                                        hotelrooms={hotelrooms}
                                        selectedRoomId={selectedRoomId}
                                        defaultRecommendation={defaultRecommendation}
                                        setSelectedRoomId={setSelectedRoomId}
                                        setIframeShow={setIframeShow}
                                        roomIndex={roomIndex}
                                        selectedDivIndex={selectedDivIndex}
                                        setIframeIndexId={setIframeIndexId}
                                    />))
                                    : ('')
                                }
                            </a>
                            <Box className="description">
                                <Box className="head">
                                    <Box className="title">
                                        {/* <p> <img src={Iconclockhistory} alt="bi_clock-history" /><span>only 5 rooms left</span></p> */}
                                        <h4>{hotelrooms.roomname}</h4>
                                    </Box >
                                    <Box className="price">
                                        <p className="price-wrapper"><span className="currency">{hotelrooms?.isSoldOut == false ? hotelrooms?.listprice?.currency_symbol : ''}</span>
                                            {hotelrooms?.listprice?.roomRecommendation.length > 0 ? hotelrooms?.listprice?.roomRecommendation.map((recommendation: any, indexReco: any) => {
                                                if (hotelrooms.roomid == selectedRoomId && roomRecommendationSelected.id == recommendation.id && roomRecommendationSelected.groupId == recommendation.groupId) {
                                                    return (<><span className="price-value">{`${hotelrooms?.isSoldOut == true ? 'SOLD OUT' : numeral(recommendation.roundOffPerNightTotal).format('0,0')}`}</span>
                                                        <span className="room">/night</span>
                                                    </>)
                                                }
                                                if (hotelrooms.roomid !== selectedRoomId && indexReco == 0) {
                                                    return (<><span className="price-value">{`${hotelrooms?.isSoldOut == true ? 'SOLD OUT' : numeral(recommendation.roundOffPerNightTotal).format('0,0')}`}</span>
                                                        <span className="room">/night</span>
                                                    </>)
                                                }
                                            }) : ""
                                                // <span className="iGor_Sold">SOLD OUT</span>
                                            }
                                        </p>
                                        {hotelrooms?.isSoldOut == true ? '' : <p className="price-info">Taxes and all fees exclude</p>}
                                    </Box >
                                </Box >
                                <Box className="body">
                                    {/* <Box className="text-wrapper">
                                    <Typography>
                                        {hotelrooms?.packagedescription || 'No information available'}
                                    </Typography>
                                </Box > */}
                                    <RoomDetailComponent hotelroomsForDiscription={hotelrooms} />
                                    <Box className="icon-wrapper">
                                        <Box className="icon-room"> <img src={icon_room} alt="room" /><span className="title">Room size: </span><span className="value">{hotelrooms?.roomsize}</span><span> sqm</span></Box >
                                        <Box className="icon-room"> <img src={Icon_bed} alt="fa-solid_bed" /><span className="title">Capacity: </span><span className="value">{hotelrooms?.roomcapacity}</span><span> people</span></Box >
                                    </Box>
                                    <Box className={roomIndex === moreOptionsIndex && moreOptions == true ? '' : 'iGor__moreOption'}>
                                        <PropertyRoomsRecomendations
                                            roomRecommendation={hotelrooms?.listprice?.roomRecommendation}
                                            roomId={hotelrooms.roomid}
                                            currencySymboll={hotelrooms?.listprice?.currency_symbol}
                                            roomIdSelectedForCheckout={hotelrooms.roomid}
                                            setDefaultRecommendation={setDefaultRecommendation}
                                            setSelectedRoomId={setSelectedRoomId}
                                            selectedRoomId={selectedRoomId}
                                            selectedDivIndex={selectedDivIndex}
                                            setSelectedDivIndex={setSelectedDivIndex}
                                            roomDetails={hotelrooms}
                                        />
                                    </Box>
                                    {hotelrooms?.listprice?.roomRecommendation.length > 1 ?
                                        roomIndex !== moreOptionsIndex || moreOptions == false ? (<Typography
                                            sx={{ cursor: 'pointer', fontWeight: '600' }}
                                            textAlign={'center'}
                                            onClick={(e) => recommendationsViewHandler(roomIndex, true)}
                                        >
                                            Show more options
                                        </Typography>) : (<Typography
                                            sx={{ cursor: 'pointer', fontWeight: '600' }}
                                            textAlign={'center'}
                                            onClick={(e) => recommendationsViewHandler(null, false)}
                                        >
                                            Hide options
                                        </Typography>) : ('')}
                                </Box>
                                <Box className="btn-wrapper">
                                    <Button variant='contained' className={hotelrooms?.isSoldOut == true ? `iGor_button_disable_room` : ''} disabled={hotelrooms?.isSoldOut} onClick={() => roomCheckoutHandler(hotelrooms.listprice.roomRecommendation, hotelrooms, roomIndex)}>Choose this room type</Button>
                                    {cardShow == false || roomIndex !== seeAmenitiesIndex ? (<Box className="accordion-head" onClick={() => handleClickAccordion(roomIndex)}  ><span>See room details and amenities</span>
                                        <ArrowDownIcon className="fa-solid fa-angle-down" />
                                    </Box>) : (<Box className="accordion-head" onClick={() => setSeeAmenitiesIndex(null)}  ><span>Hide room details and amenities</span>
                                        <ArrowDownIcon className="fa-solid fa-angle-down" />
                                    </Box>)}
                                    {/* <Box className="accordion-head" onClick={() => handleClickAccordion(index)}  ><span>See room details and amenities</span>
                                    <ArrowDownIcon className="fa-solid fa-angle-down" />
                                </Box> */}
                                </Box >
                            </Box >
                            {/* <Box className="iGor_cardshear">
                                <img src={Icon_shaer} alt='Icon_shaer' />
                            </Box> */}
                        </Box >


                        <Box className={"bottom iGor_accordion-body   " + (roomIndex === seeAmenitiesIndex && cardShow ? "iGor_accordion_slide-left   " : "iGor_accordion_slide-right iGor_accordion-hide")}>
                            <Box className="inclusion inclusion-left">
                                <Typography variant='subtitle1'>Inclusion details</Typography>
                                <ul className=''>
                                    {hotelrooms?.packageinclusion.length > 0 ?
                                        hotelrooms?.packageinclusion.map((item: any) => (<li>{item}</li>))
                                        : t('5007')}
                                </ul>
                            </Box >
                            <Box className="details">
                                <Typography variant='subtitle1' >Size details</Typography>

                                <List className='iGor_Faci_list'>
                                    <ListItem>
                                        <ListItemText primary={`1 ${hotelrooms.bedType} bed`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <img src={icon_room} alt="room" />
                                        </ListItemIcon>
                                        <ListItemText primary={`Room size: ${hotelrooms?.roomsize} sqm`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <img src={Icon_bed} alt="fa-solid_bed" />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontWeight: 600 }} primary={`Capacity: ${hotelrooms?.roomcapacity} people`} />
                                    </ListItem>
                                </List>
                            </Box >
                            <Box className="facilities">
                                <Typography variant='subtitle1'>Main Facilities</Typography>
                                <Box>
                                    <List className='iGor_Faci_list iGor_Faci_listMain '>
                                        {hotelrooms?.facilities.length > 0 ? hotelrooms?.facilities?.map((item: any) => (
                                            <ListItem>
                                                {/* <ListItemIcon>
                                           <img src={icon_room} alt="room" />
                                       </ListItemIcon> */}
                                                <PropertyFacilitiesIconMapper iconName={item.icon[0]} />
                                                {/* <CheckCircleRoundedIcon fontSize='small' /> */}
                                                <ListItemText primary={item.title} />
                                            </ListItem>
                                        )) : t('5008')}
                                    </List>
                                </Box>

                                <PropertyFacilitiesModal listing_details={hotelrooms} showFacilityText={"property-details"} />
                            </Box >
                        </Box >

                    </Box >)
                }
                else if (pathnameFromUrl == '/order-summary' && roomIndex > upgradeRoomIndex && hotelrooms?.isSoldOut == false && hotelrooms.isOffer == false) {
                    return (<Box className="iGOr_card-listing">
                        <Box className="top">
                            <a className="image">
                                {!iframeShow && (<PropertyRoomVrSectionViewer
                                    hotelrooms={hotelrooms}
                                    selectedRoomId={selectedRoomId}
                                    defaultRecommendation={defaultRecommendation}
                                    setSelectedRoomId={setSelectedRoomId}
                                    setIframeShow={setIframeShow}
                                />)}
                                {iframeShow ? (hotelrooms.roomid == selectedRoomId ? (<Box className="iframe">
                                    <iframe src={VTUrlParser(hotelrooms?.vrroom)} ></iframe>
                                </Box >) : (<PropertyRoomVrSectionViewer
                                    hotelrooms={hotelrooms}
                                    selectedRoomId={selectedRoomId}
                                    defaultRecommendation={defaultRecommendation}
                                    setSelectedRoomId={setSelectedRoomId}
                                    setIframeShow={setIframeShow}
                                />))
                                    : ('')
                                }
                            </a>
                            <Box className="description">
                                <Box className="head">
                                    <Box className="title">
                                        {/* <p> <img src={Iconclockhistory} alt="bi_clock-history" /><span>only 5 rooms left</span></p> */}
                                        <h4>{hotelrooms.roomname}</h4>
                                    </Box >
                                    <Box className="price">
                                        <p className="price-wrapper"><span className="currency">{hotelrooms?.isSoldOut == false ? hotelrooms?.listprice?.currency_symbol : ''}</span>
                                            {hotelrooms?.listprice?.roomRecommendation.length > 0 ? hotelrooms?.listprice?.roomRecommendation.map((recommendation: any, indexReco: any) => {
                                                if (hotelrooms.roomid == selectedRoomId && roomRecommendationSelected.id == recommendation.id && roomRecommendationSelected.groupId == recommendation.groupId) {
                                                    return (<><span className="price-value">{`${hotelrooms?.isSoldOut == true ? 'SOLD OUT' : numeral(recommendation.roundOffPerNightTotal).format('0,0')}`}</span>
                                                        <span className="room">/night</span>
                                                    </>)
                                                }
                                                if (hotelrooms.roomid !== selectedRoomId && indexReco == 0) {
                                                    return (<><span className="price-value">{`${hotelrooms?.isSoldOut == true ? 'SOLD OUT' : numeral(recommendation.roundOffPerNightTotal).format('0,0')}`}</span>
                                                        <span className="room">/night</span>
                                                    </>)
                                                }
                                            }) : ""
                                                // <span className="iGor_Sold">SOLD OUT</span>
                                            }
                                        </p>
                                        {hotelrooms?.isSoldOut == true ? '' : <p className="price-info">Taxes and all fees exclude</p>}
                                    </Box >
                                </Box >
                                <Box className="body">
                                    {/* <Box className="text-wrapper">
                                    <Typography>
                                        {hotelrooms?.packagedescription || 'No information available'}
                                    </Typography>
                                </Box > */}
                                    <RoomDetailComponent hotelroomsForDiscription={hotelrooms} />
                                    <Box className="icon-wrapper">
                                        <Box className="icon-room"> <img src={icon_room} alt="room" /><span className="title">Room size: </span><span className="value">{hotelrooms?.roomsize}</span><span> sqm</span></Box >
                                        <Box className="icon-room"> <img src={Icon_bed} alt="fa-solid_bed" /><span className="title">Capacity: </span><span className="value">{hotelrooms?.roomcapacity}</span><span> people</span></Box >
                                    </Box>
                                    <Box className={roomIndex === moreOptionsIndex && moreOptions == true ? '' : 'iGor__moreOption'}>
                                        <PropertyRoomsRecomendations
                                            roomRecommendation={hotelrooms?.listprice?.roomRecommendation}
                                            roomId={hotelrooms.roomid}
                                            currencySymboll={hotelrooms?.listprice?.currency_symbol}
                                            roomIdSelectedForCheckout={hotelrooms.roomid}
                                            setDefaultRecommendation={setDefaultRecommendation}
                                            setSelectedRoomId={setSelectedRoomId}
                                            selectedRoomId={selectedRoomId}
                                            selectedDivIndex={selectedDivIndex}
                                            setSelectedDivIndex={setSelectedDivIndex}
                                        />
                                    </Box>
                                    {hotelrooms?.listprice?.roomRecommendation.length > 1 ?
                                        roomIndex !== moreOptionsIndex || moreOptions == false ? (<Typography
                                            sx={{ cursor: 'pointer', fontWeight: '600' }}
                                            textAlign={'center'}
                                            onClick={(e) => recommendationsViewHandler(roomIndex, true)}
                                        >
                                            Show more options
                                        </Typography>) : (<Typography
                                            sx={{ cursor: 'pointer', fontWeight: '600' }}
                                            textAlign={'center'}
                                            onClick={(e) => recommendationsViewHandler(null, false)}
                                        >
                                            Hide options
                                        </Typography>) : ('')}
                                </Box>
                                <Box className="btn-wrapper">
                                    <Button variant='contained' style={{ fontSize: '12px', width: '299px' }} className={hotelrooms?.isSoldOut == true ? `iGor_button_disable_room` : ''} disabled={hotelrooms?.isSoldOut} onClick={() => roomCheckoutHandler(hotelrooms.listprice.roomRecommendation, hotelrooms, roomIndex)}>Change to this room type</Button>
                                    {cardShow == false || roomIndex !== seeAmenitiesIndex ? (<Box className="accordion-head" onClick={() => handleClickAccordion(roomIndex)}  ><span>See room details and amenities</span>
                                        <ArrowDownIcon className="fa-solid fa-angle-down" />
                                    </Box>) : (<Box className="accordion-head" onClick={() => setSeeAmenitiesIndex(null)}  ><span>Hide room details and amenities</span>
                                        <ArrowDownIcon className="fa-solid fa-angle-down" />
                                    </Box>)}
                                    {/* <Box className="accordion-head" onClick={() => handleClickAccordion(index)}  ><span>See room details and amenities</span>
                                    <ArrowDownIcon className="fa-solid fa-angle-down" />
                                </Box> */}
                                </Box >
                            </Box >
                            {/* <Box className="iGor_cardshear">
                                <img src={Icon_shaer} alt='Icon_shaer' />
                            </Box> */}
                        </Box >
                        <Box className={"bottom iGor_accordion-body   " + (roomIndex === seeAmenitiesIndex && cardShow ? "iGor_accordion_slide-left   " : "iGor_accordion_slide-right iGor_accordion-hide")}>
                            <Box className="inclusion inclusion-left">
                                <Typography variant='subtitle1'>Inclusion Details</Typography>
                                <ul className=''>
                                    {hotelrooms?.packageinclusion.length > 0 ?
                                        hotelrooms?.packageinclusion.map((item: any) => (<li>{item}</li>))
                                        : t('5007')}
                                </ul>
                            </Box >
                            <Box className="details" style={{ marginLeft: !matches ? '10px' : 0, maxWidth: '31.33333%' }}>
                                <Typography variant='subtitle1' mb={'8px'}>Size Details</Typography>

                                <List className='iGor_Faci_list'>
                                    <ListItem>
                                        <ListItemText primary="1 Large Double bed" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <img src={icon_room} alt="room" />
                                        </ListItemIcon>
                                        <ListItemText primary={`Room size: ${hotelrooms?.roomsize} sqm`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <img src={Icon_bed} alt="fa-solid_bed" />
                                        </ListItemIcon>
                                        <ListItemText style={{ fontWeight: 600 }} primary={`Capacity: ${hotelrooms?.roomcapacity} people`} />
                                    </ListItem>
                                </List>
                            </Box >
                            <Box className="facilities">
                                <Typography variant='subtitle1'>Main Facilities</Typography>
                                <Box>
                                    <List className='iGor_Faci_list iGor_Faci_listMain '>
                                        {hotelrooms?.facilities.length > 0 ? hotelrooms?.facilities?.map((item: any, index: number) => (
                                            <ListItem>
                                                {/* <ListItemIcon>
                                           <img src={icon_room} alt="room" />
                                       </ListItemIcon> */}
                                                <PropertyFacilitiesIconMapper iconName={item.icon[0]} />
                                                {/* <CheckCircleRoundedIcon fontSize='small' /> */}
                                                <ListItemText primary={item.title} />
                                            </ListItem>
                                        )) : t('5008')}
                                    </List>
                                </Box>

                                <PropertyFacilitiesModal listing_details={hotelrooms} showFacilityText={"property-details"} />
                            </Box >
                        </Box >
                    </Box >)
                }

            }) :
                (<Box className='iGor_noRooms-Available'>No rooms available for this hotel</Box>)
        )}
            {noUpgradeRoom}
        </>
    )
}