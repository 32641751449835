import { useDispatch } from "react-redux"
import { useAppSelector } from "../../appStore/hooks"
import { setRemoveRoomArray, setRoomArray } from "../../appStore/hotelSlice"
import DestinationRoom from "./DestinationRoom"
import {Grid} from "@mui/material";

function fixJsonString(str: string) {
    const fixedStr = str.replace(/'/g, '"').replace(/(\w+):/g, '"$1":');
    return JSON.parse(fixedStr);
  }

const DestinationRoomAddition = ({ setRoomArrayForGuest, roomArrayForGuest }: any) => {
    const roomArray = roomArrayForGuest;
    const dispatch=useDispatch()
    const roomyArrayConverted = JSON.stringify(roomArrayForGuest) 
    const onUpdateAdultCount = (isIncrement: boolean, key: number) => {
        const arr = fixJsonString(roomyArrayConverted);
        if (isIncrement) {
            arr[key].adults = arr[key].adults + 1
        }
        else {
            if (arr[key].adults > 1)
                arr[key].adults = arr[key].adults - 1
        }
        setRoomArrayForGuest(arr)
        // dispatch(setRoomArray(arr))
    }

    const onUpdateChildCount = (isIncrement: boolean, key: number) => {
        const arr = fixJsonString(roomyArrayConverted);
        if (isIncrement) {
            arr[key].children = arr[key].children + 1
            arr[key].childAges.push(1)
        }
        else {
            if (arr[key].children > 0) {
                arr[key].children = arr[key].children - 1
                arr[key].childAges.pop()
            }
        }
        setRoomArrayForGuest(arr)

        // dispatch(setRoomArray(arr))
    }

    const onChildAgeUpdate = (childArr: any, key: number) => {
        const arr = fixJsonString(roomyArrayConverted);
        arr[key].childAges = childArr
        setRoomArrayForGuest(arr)

        // dispatch(setRoomArray(arr))
    }

    const ondeleteHandler = (index: number) => {
        let arr = [...roomArrayForGuest]
            arr.splice(index,1)
            setRoomArrayForGuest(arr)
        // dispatch(setRemoveRoomArray(index))
    }
    
    return(
        <>
            {roomArrayForGuest?.map((item: any, index: number) => (
                <Grid container spacing={1} >
                    <DestinationRoom
                        roomNo={index}
                        key={index}
                        roomDetails={item}
                        adultUpdate={onUpdateAdultCount}
                        childUpdate={onUpdateChildCount}
                        deleteHandler={ondeleteHandler}
                        ChildAgeUpdate={onChildAgeUpdate}
                        roomArray={roomArray}
                    />
                </Grid>
        ))}
        </>
        
    )

}

export default DestinationRoomAddition