import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Skeleton, Typography, useMediaQuery } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NoHotelsImg from '../../assets/images/No Hotels Img.png'
import timeIcon from '../../assets/icons/timerIcon.svg';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../appStore/hooks";
import { useDispatch } from "react-redux";
import { fetchFaviourateFolderDetails, fetchFaviourateFolderList, fetchHotelDetailsFromApi, fetchHotelsRoomsDataFromApi, fetchRemoveFromFaviourates, setAlertBoxOnError, setErrorMessageForDialogueBox, setPreviousPathName, setRemoveFromFaviouratesReset } from "../../appStore/hotelSlice";
import { useNavigate } from 'react-router';
import { useInitApiMutation, useUpdateWishlistHotelsMutation } from '../../services/api/hotelApi';
import { EventNamesForEventLogs, addHotelFavioratesType, countryResidenceAndCulture, wishlistApiMessages } from '../../utils/constants/constant';
import HotelresultSkeleton from '../../components/Skeleton/HotelresultSkeleton';
import initApiCall from '../../services/initApiCall';
import 'react-toastify/dist/ReactToastify.css';
import convertDateFormat, { dateFormatedForUrls } from '../../utils/utilities/DateConverter';
import Fav_FirstWishlist from '../../assets/icons/Fav_FirstWishlist.svg'
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import { cacheImages } from '../../utils/utilities/ImageCaching';
import { environmentVariables } from '../../utils/config/environmentVariables';
import ArticalCard from './ArticalCard';
import mapIcon from '../../assets/icons/mapIcon.svg'


function MyFavouritesList() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [imageLoaded, setImageLoaded] = useState(false);
    const [activeButton, setActiveButton] = useState('All'); // Default active button
    const { userFavouritesFolderList, userFaviouratesFolderDetails, loggedInUserCurrency, roomArray, searchDestinationFromDate, setUserLoggedIn,
        searchDestinationToDate, initApi, userRemoveFromFaviourates, folderSelectedDetails } = useAppSelector(state => state.hotel)
    const folderIdSelected = folderSelectedDetails?.folderId
    const favouriteDestinationFolderList = userFavouritesFolderList?.data ? userFavouritesFolderList?.data : [];
    const imagePath = environmentVariables.imagePathApp;
    const userfaviouritesApiLoading = userFavouritesFolderList?.loading ? userFavouritesFolderList?.loading : false;
    const favouriteHotelsResponseStatus = userFavouritesFolderList?.data?.success ? userFavouritesFolderList?.data?.success : false;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const countryResidenceAndCultureInit = countryResidenceAndCulture;
    const hotelRoomArray = roomArray;
    const searchId = initApi.searchId;
    const searchStatus = initApi.status;
    const pathname = window.location.pathname;
    const matches = useMediaQuery('(max-width:1024px)');
    const eventNameViewFavourite = EventNamesForEventLogs.favouriteContentView;
    const [removeHotelIndex, setRemoveHotelIndex]: any = useState(null);
    const faviourateType = addHotelFavioratesType.property;
    const [destinationSelectedObj, setDestinationSelectedObj]: any = useState({});
    const startFormattedDate = dateFormatedForUrls(searchDestinationFromDate)
    const endFormattedDate = dateFormatedForUrls(searchDestinationToDate)
    const formatedEndDate = convertDateFormat(searchDestinationToDate);
    const userFaviouratesFolderDetailsLoading = userFaviouratesFolderDetails?.loading ? userFaviouratesFolderDetails?.loading : false;
    const propertiesArray = userFaviouratesFolderDetails?.data ?
        userFaviouratesFolderDetails.data.filter((item: any) => item.properties)
            .map((item: any) => item.properties).flat() : [];
    const articlesArray = userFaviouratesFolderDetails?.data ?
        userFaviouratesFolderDetails.data.filter((item: any) => item.articles)
            .map((item: any) => item.articles).flat() : [];

    // init api mutation intialization
    const [initApiForHotel] = useInitApiMutation();

    // update  wishlist initialization
    const [updateWishlist, updateWishListResponse] = useUpdateWishlistHotelsMutation();
    const hotelRemovedMessage = updateWishListResponse?.data?.message ? updateWishListResponse?.data?.message : '';

    // remove from faviourites handler
    const removeFromFaviouratesHandler = async (hotelId: any, wish: boolean, index: number) => {
        setRemoveHotelIndex(index)
        dispatch(fetchRemoveFromFaviourates({ userId: loggedInUserId, faviourateId: hotelId, type: faviourateType }))
        // api payload assigning and api call add hotel to favorite
        // const payload = updateUserHotelWishlistPayload(hotelId, loggedInUserId, wish)
        // await updateWishlist({ payload })
        dispatch(fetchFaviourateFolderList(loggedInUserId))
    }

    // faviourite checkout handler 
    const checkoutFaviouriteHandler = (faviouriteHotelSelected: any) => {
        if (matches) {
            dispatch(setPreviousPathName(pathname))
        }
        const locationId = faviouriteHotelSelected.locationId
        const hotelId = faviouriteHotelSelected.id
        const destinationName = faviouriteHotelSelected.location
        const hotelNameSlug = faviouriteHotelSelected.name.replaceAll(" ", "-")
        const slug = faviouriteHotelSelected.slug

        setDestinationSelectedObj({
            destinationName: destinationName.replaceAll(" ", "-"),
            locationId: locationId,
            hotelId: hotelId,
            slugId: slug ? slug : hotelNameSlug.replaceAll(",", "")
        })

        const payload = initApiCall(loggendInUserCurrency, searchDestinationFromDate, searchDestinationToDate,
            hotelRoomArray, countryResidenceAndCultureInit.countryOfResidence, countryResidenceAndCultureInit.nationality,
            countryResidenceAndCultureInit.culture, locationId, loggedInUserId)
        initApiForHotel({ payload })
    }
    const handleImageLoad = () => {
        setTimeout(() => { setImageLoaded(true) }, 2000)
    };

    // event logs for analytics
    const eventLogHanlder = (hotelId: number) => {
        const eventIdsForLogEvents = {
            "ig_content_id": hotelId.toString()
        }
        EventLogsHandler(eventNameViewFavourite, eventIdsForLogEvents)
    }

    //    fetching updated wishlistData 
    useEffect(() => {
        if (userRemoveFromFaviourates?.data?.message?.includes('Property removed successfully')) {
            dispatch(fetchFaviourateFolderDetails({ userId: loggedInUserId, folderId: folderIdSelected }))
            dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
            // dispatch(fetchFaviourateFolderList(loggedInUserId))
            setRemoveHotelIndex(null)
        }
        else if (hotelRemovedMessage == wishlistApiMessages.CredentialNotValid) {
            dispatch(setErrorMessageForDialogueBox(hotelRemovedMessage))
            dispatch(setAlertBoxOnError(true))
        }
        else if (pathname == '/favourites' && favouriteDestinationFolderList.length == 0 && loggedInUserId !== 0 && favouriteHotelsResponseStatus == false) {
            // dispatch(fetchFaviourateFolderList(loggedInUserId))
        }

        if (searchStatus == 'success' && searchId && destinationSelectedObj.hotelId) {
            const slugId = destinationSelectedObj.slugId.toLowerCase();
            const destinationName = destinationSelectedObj.destinationName;
            const locationId = destinationSelectedObj.locationId;
            const hotelId = destinationSelectedObj.hotelId;
            eventLogHanlder(hotelId)
            navigate(`/property-details/${slugId}?hotelId=${hotelId}&destinationName=${destinationName}&destinationId=${locationId}&checkIn=${startFormattedDate}&checkout=${endFormattedDate}&currency=${loggendInUserCurrency}`)
        }
    }, [hotelRemovedMessage, searchStatus, searchId, destinationSelectedObj, favouriteDestinationFolderList,
        pathname, loggedInUserId, userRemoveFromFaviourates?.data?.message])


    const handleButtonClick = (buttonName: any) => {
        setActiveButton(buttonName);
    };

    return (<>
        <Grid sm={12} xs={12} m={2}>
            <Button
                className={'iGor_WhishListTab ' + (activeButton === 'All' ? 'iGor_tabActive' : '')}
                onClick={() => handleButtonClick('All')}
            >
                All
            </Button>
            <Button
                className={'iGor_WhishListTab ' + (activeButton === 'Property' ? 'iGor_tabActive' : '')}
                onClick={() => handleButtonClick('Property')}
            >
                Property
            </Button>
            <Button
                className={'iGor_WhishListTab ' + (activeButton === 'Article' ? 'iGor_tabActive' : '')}
                onClick={() => handleButtonClick('Article')}
            >
                Article
            </Button>
        </Grid>
        {propertiesArray.length > 0 && activeButton == 'All' || activeButton == 'Property' ? (<Grid sm={12} style={{ paddingLeft: 8 }}>
            <Typography
                fontSize={'14px'}
                fontWeight={600}
                mt={1}
                ml={1} >
                Wishlist
            </Typography>
        </Grid>) : (<></>)}
        {propertiesArray.length > 0 && activeButton == 'All' || activeButton == 'Property' ? (<Grid container spacing={1} className="iGor_favCardWrapper"> {propertiesArray.map((favourite: any, index: number) => {
            const imageForBookings = favourite?.heroimageurl ? `${imagePath}${favourite?.heroimageurl}` : NoHotelsImg
            const imageCached = cacheImages(imageForBookings);
            const hotelRating = favourite?.avg_Rating?.rating ? favourite?.avg_Rating?.rating : 0
            const startRating = Array.from({ length: hotelRating }, (_, index) => index);
            const starRatingNot = Array.from({ length: 5 - hotelRating }, (_, index) => index);
            return (<Grid key={favourite.name} item sm={6} xs={12} className="iGor_favouriteCard">

                <Box className="iGoR_roomCard"
                //  key={offersHotels?.id} 
                //  onClick={() => matches && onRoomDetailHandler(offersHotels?.id, offersHotels?.image)}
                >
                    <Box className="iGoR_roomCardInner" onClick={matches ? () => checkoutFaviouriteHandler(favourite) : () => { }}>
                        <Box className='iGor-CardLeft'>
                            {!imageLoaded && (<Skeleton variant="rectangular" height={matches ? 134 : 150} animation="wave" sx={{ borderRadius: '0.625rem' }} />)}
                            <img src={imageCached.src}
                                className='iGor_cardImg' height='151px'
                                onError={(e: any) => {
                                    // Handle the image load error by setting the source to the default image
                                    e.target.src = NoHotelsImg;
                                }}
                                style={{
                                    display: imageLoaded ? 'block' : 'none'
                                }}
                                onLoad={handleImageLoad}
                            />
                            <Box className='iGor_cardBtnSection iGor_setFlex iGor_justifyBetween'>

                                <IconButton className=' iGor_bgWhite iGor_cardLeftBtn' disabled={index == removeHotelIndex ? true : false}>
                                    <FavoriteIcon sx={{ color: '#FF6B6B' }}
                                        onClick={(e) => removeFromFaviouratesHandler(favourite.id, false, index)}
                                        className='iGor_heartColour' />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box className='iGor-CardRight'>
                            <Typography className='iGor_mainTitleFev' >{favourite.name}</Typography>
                            <Box className='iGor-ratingSection'>
                                <Typography variant='caption'>  {favourite?.hoteltype}</Typography>
                                <Box className='iGor-rating'>
                                    {startRating.map((rating: any) => (
                                        <StarIcon className='iGor_starIcon' />))}
                                    {starRatingNot.map((rating: any) => (
                                        <StarIcon className='iGor_starIcon_not_rating' />))}
                                </Box>
                                <Box className="iGor_ratingNum">
                                    {hotelRating}
                                </Box>
                                {!matches && <Typography variant='caption' className={`iGor_subTitle ${matches ? 'iGor_mb-View' : ''}`}>
                                    {`${hotelRating <= 2 ? t('Average') : hotelRating <= 4 && hotelRating >= 2 ? t('Good') : t('Excellent')}`}
                                    ({favourite?.googleReviewsCount ? favourite?.googleReviewsCount : 0} {t('reviews')})
                                </Typography>}
                            </Box>
                            <Box className={`iGor_Travel_fv ${matches ? 'iGor_travel-until-mb' : ''}`}>
                                <img src={timeIcon} alt='timeIcon' />
                                <Typography variant='caption' className='iGor_subTitle'>  {t('Travel until')} {formatedEndDate}</Typography>
                            </Box>
                            <Box className='iGor-location_box' sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>
                                <img src={mapIcon} />
                                <Typography variant='caption' className='iGor_Location' lineHeight={'1'} component={'p'} sx={{
                                    whiteSpace: 'nowrap', overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>{favourite?.location}</Typography>
                            </Box>
                            {
                                <Box className="iGor_CardBtnSection">
                                    <Box>
                                        {/* <Typography variant='body1' className='iGor_subTitle'>{t('Starting from')}</Typography> */}
                                        <Box className='iGor_cardPrice'>
                                            <Typography variant='h4'>
                                                {/* offersHotels?.listPrice?.currencySymbol offersHotels?.listPrice?.minPrice ? offersHotels?.listPrice?.minPrice?.toFixed() : '' */}
                                            </Typography>
                                            {/* <Typography variant='body1' className='iGor_subTitle'>/ {t('night')}</Typography> */}
                                        </Box>
                                    </Box>
                                    <Button variant='contained' className='iGor_cardBtn'
                                        onClick={() => checkoutFaviouriteHandler(favourite)}
                                    >
                                        Check Availability
                                    </Button>
                                </Box>
                            }

                        </Box>
                    </Box>
                </Box>
            </Grid>
            )
        })} </Grid>) :
            (userFaviouratesFolderDetailsLoading == true ?
                (<HotelresultSkeleton />) :
                articlesArray.length < 0 || propertiesArray.length < 0 ? (

                    <>
                        <Box className={matches ? '' : 'iGor__loginCard'} width={'100%'}>
                            <Grid container spacing={2} className='iGor__CardForCenter'>
                                <Grid item lg={4} sm={4} className={matches ? 'iGor__loginCard' : ''}>
                                    <img src={Fav_FirstWishlist} alt='Fav_FirstWishlist' />
                                </Grid>
                                <Grid item lg={8} sm={4}>
                                    <Box sx={{
                                        display: 'flex', height: '100%',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column'
                                    }}>

                                        <Typography variant='h4'>Make Your First Wishlist </Typography>
                                        <Typography variant='body1' color={'#C4C4C4'} textAlign={'center'}>You can click ‘heart’ icon to add your favourite <br />
                                            resorts, packages or article</Typography>
                                        <Button variant='contained' sx={{ width: '167px', marginTop: "20px" }} onClick={() => navigate('/')}>Start to Search</Button>
                                    </Box>

                                </Grid>

                            </Grid>
                        </Box>
                    </>) : (<></>))}

        {activeButton == 'All' || activeButton == 'Article' ? (<Grid item sm={12} >
            <ArticalCard />
        </Grid>) : (<></>)}
    </>);
}

export default MyFavouritesList;