import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button, Skeleton } from '@mui/material';
import timerIcon from '../../assets/icons/timerIcon.svg';
import icons_heart from '../../assets/icons/icons_heart.svg';
import roomCard from '../../assets/images/roomCard_2.jpg';
import StarIcon from '@mui/icons-material/Star';
import { env } from '../../utils/config/appConfig';
import { useAppSelector } from '../../appStore/hooks';
import convertDateFormat, { calculateDateFourtyFiveDaysLater, dateFormatedForUrls } from '../../utils/utilities/DateConverter';
import { useDispatch } from 'react-redux';
import { fetchHotelDetailsFromApi, fetchHotelsRoomsDataFromApi, setAlertBoxOnError, setErrorMessageForDialogueBox, setHotelDetails, setHotelRoomDetails, setHotelSearchRequestReset, setHotelsTypeTabSelection, setPreviousPathName, setSearchDestinationFromDate, setSearchDestinationToDate } from '../../appStore/hotelSlice';
import initApiCall from '../../services/initApiCall';
import { useInitApiMutation } from '../../services/api/hotelApi';
import { EventNamesForEventLogs, countryResidenceAndCulture } from '../../utils/constants/constant';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { sliderSetting } from '../../utils/constants/SliderSettings';
import MarkIcon from '@mui/icons-material/Room';
import NoHotelsImg from '../../assets/images/noHotelIgo.jpg'
import { cacheImages } from '../../utils/utilities/ImageCaching';
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import mapIcon from '../../assets/icons/map.svg'
import LazyLoadImagesHotelsComp from '../ExploreDestination/LazyLoadImagesHotells'
import { environmentVariables } from '../../utils/config/environmentVariables';

const ExploreCardOffers = ({ exploreHotelsToStayData }: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchOfferHotel, setSearchOfferHotel] = useState(false);
    const { initApi, loggedInUserCurrency, hotelDetails, searchDestinationFromDate, selectedHotelsTypeTab, searchDestinationToDate, roomArray, exploreHotelsToStay } = useAppSelector(state => state.hotel)
    const explorePlacesToStay = exploreHotelsToStayData?.length > 0 ? exploreHotelsToStayData : [];
    const formatedEndDate = convertDateFormat(searchDestinationToDate)
    const searchId = initApi?.searchId;
    const [destinationSelectedObj, setDestinationSelectedObj]: any = useState({});
    const destinationSearchStartDate = searchDestinationFromDate;
    const destinationSearchEndDate = searchDestinationToDate;
    const hotelRoomArray = roomArray
    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const hotelDetailsFromApi = hotelDetails.data;
    const countryResidenceAndCultureInit = countryResidenceAndCulture;
    const imagePath = environmentVariables.imagePathApp;
    const pathname = window.location.pathname;
    const exploreHotelsLoading = exploreHotelsToStay.loading;
    const eventNameExplorePlaces = EventNamesForEventLogs.explorePlacesToStay;

    // init api mutation
    const [initApiForHotel] = useInitApiMutation();
    const startDateFormated = dateFormatedForUrls(destinationSearchStartDate);
    const endDateFormated = dateFormatedForUrls(destinationSearchEndDate);
    const [imageLoaded, setImageLoaded] = useState(false);
    // event log to analytics
    const eventLogHanlderForEventLogs = (hotelId: number, hotelName: string) => {
        const eventIdsForLogEvents = {
            "ig_content_id": hotelId.toString(),
            "ig_content_name": hotelName,
        }
        EventLogsHandler(eventNameExplorePlaces, eventIdsForLogEvents)
    }

    const placesCheckoutHandler = async (hotelSelected: any) => {
        const locationId = hotelSelected.locationId;
        const hotelName = hotelSelected.name;
        const hotelNameForSlug = hotelName.replaceAll(" ", "-")
        const hotelId = hotelSelected.id;
        const slug = hotelSelected.slug;
        eventLogHanlderForEventLogs(hotelId, hotelName)
        dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
        dispatch(setHotelRoomDetails({ data: [] }))
        dispatch(setHotelDetails({ data: [] }))
        setDestinationSelectedObj({
            destinationId: locationId,
            destinationName: hotelSelected.location,
            hotelId: hotelId,
            slug: slug ? slug : hotelNameForSlug.toLowerCase()
        })
        const payload = await initApiCall(loggendInUserCurrency, destinationSearchStartDate, destinationSearchEndDate,
            hotelRoomArray, countryResidenceAndCultureInit.countryOfResidence, countryResidenceAndCultureInit.nationality,
            countryResidenceAndCultureInit.culture, locationId, loggedInUserId)
        initApiForHotel({ payload })
        setSearchOfferHotel(true)
    }

    // use Effect for navigate to hotel detail after click popular destination details screen
    useEffect(() => {
        if (hotelDetailsFromApi.success == true && searchOfferHotel == true) {
            setSearchOfferHotel(false)
            // dispatch(fetchHotelsRoomsDataFromApi({ searchId: searchId, hotelId: hotelId }))
            // dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
            // navigate('/property-details')

        }
        else if (hotelDetailsFromApi.success == false) {
            setSearchOfferHotel(false)
            dispatch(setErrorMessageForDialogueBox('19'))
            dispatch(setAlertBoxOnError(true))
            dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
            dispatch(setHotelDetails({ data: [], loading: false, error: null, }))
        }
        else if (searchId && destinationSelectedObj.destinationId && destinationSelectedObj.destinationName && destinationSelectedObj.hotelId && searchOfferHotel == true) {
            const locationId = destinationSelectedObj.destinationId;
            const slugId = destinationSelectedObj.slug;
            const hotelId = destinationSelectedObj.hotelId;
            const destinationName = destinationSelectedObj.destinationName.replaceAll(" ", "-");
            dispatch(setPreviousPathName(pathname))
            navigate(`/property-details/${slugId}?hotelId=${hotelId}&destinationName=${destinationName}&destinationId=${locationId}&checkIn=${startDateFormated}&checkout=${endDateFormated}&currency=${loggendInUserCurrency}`)
            setDestinationSelectedObj({})
        }
    }, [searchId, destinationSelectedObj.destinationId, destinationSelectedObj.destinationName, destinationSelectedObj.hotelId,
        hotelDetailsFromApi, searchOfferHotel])

    // commnted because when did refresh only skeleton showing after images loaded
    // useEffect(() => {
    //     // Reset imageLoaded state on component unmount
    //     if (imageLoaded == false) {
    //         setTimeout(() => { setImageLoaded(true) }, 3000)
    //     }
    // }, [imageLoaded]);

    const handleImageLoad = () => {
        // Set imageLoaded to true when the image is loaded
        setTimeout(() => { setImageLoaded(true) }, 2000)
    };
    return (
        <Grid container spacing={2} marginY={1} className='iGor__ExploreCardOff'>
            {
                // exploreHotelsLoading == true ? ( Array.from(Array(3).keys()).map((index) => 
                // (<Grid item sm={4} xs={12} key={index}>
                //             <Box className="iGor__card-offers iGor__portrait hotel">
                //                 <Box className="iGor__image">
                //                     <Skeleton variant="rectangular" width="100%" height={200} />
                //                 </Box>
                //                 <Box className="iGor__description">
                //                     <Box className="iGor__head">
                //                         <p> <img src={timerIcon} alt="timer" /><span>Travel until {formatedEndDate}</span></p>
                //                         <h2><Skeleton variant="text" /></h2>
                //                         <p className="iGor__icon-location"><i className="fa fa-map-marker"> </i><span><Skeleton variant="text" /></span></p>
                //                     </Box>
                //                     <Box className="iGor__body">
                //                         <Box className="iGor__review">
                //                             <Box className="iGor__stars">
                //                                 <StarIcon />
                //                                 <StarIcon />
                //                                 <StarIcon />
                //                                 <StarIcon />
                //                                 <StarIcon />
                //                             </Box>
                //                             <span className="iGor__ratings-value"> <span> <Skeleton variant="text" /></span></span>
                //                         </Box>
                //                     </Box>
                //                 </Box>
                //             </Box>
                //         </Grid>
                //     ))
                // ) : 
                exploreHotelsLoading == true ?
                    ([...Array(3)].map(() => {
                        return (<Grid item sm={4} xs={12} >
                            <Box className="iGor__card-offers iGor__portrait hotel">
                                <Box className="iGor__image">
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%" height={150}
                                        sx={{ borderRadius: '0.625rem' }} />
                                </Box>
                            </Box>
                        </Grid>)
                    })) :
                    (explorePlacesToStay.length > 0 ?
                        explorePlacesToStay.map((hotels: any, index: number) => {
                            const maxStars = 5;
                            const filledStars = hotels && hotels?.avg_Rating?.rating ? Math.floor(hotels.avg_Rating.rating) : 0;
                            const emptyStars = maxStars - filledStars;
                            const imageUrl: any = cacheImages(`${imagePath}${hotels.heroimageurl}`)
                            return (
                                <Grid item sm={4} xs={12} key={hotels.name}>
                                    <Box className="iGor__card-offers iGor__portrait hotel" onClick={() => placesCheckoutHandler(hotels)}>
                                        <Box className="iGor__image">
                                            {imageLoaded == false && 
                                                <Skeleton
                                                    variant="rectangular"
                                                    width="100%"
                                                    height={150}
                                                    sx={{ borderRadius: '0.625rem' }}
                                                />}
                                                 {/* <LazyLoadImagesHotelsComp
                                                    key={index}
                                                    className={'iGor__img'}
                                                    style={{ display: imageLoaded ? 'block' : 'none' }}
                                                    handleImageLoad={handleImageLoad}
                                                    images={imageUrl.src}
                                                    alt={hotels.name}
                                                /> */}
                                            <img className='iGor__img'
                                                src={imageUrl.src}
                                                alt={hotels.name}
                                                onError={(e: any) => {
                                                    // Handle the image load error by setting the source to the default image
                                                    e.target.src = NoHotelsImg;
                                                }}
                                                style={{
                                                    display: imageLoaded ? 'block' : 'none'
                                                }}
                                                onLoad={handleImageLoad}
                                            />
                                            {/* <img className="iGor__icon-wishlist icon-circle" src={icons_heart} /> */}
                                        </Box>
                                        <Box className="iGor__description"  >
                                            <Box className="iGor__head">
                                                <p> <img src={timerIcon} alt="timer" /><span>Travel until {formatedEndDate}</span></p>
                                                <h2>{hotels.name}</h2>
                                                <p className="iGor__icon-location"><MarkIcon /> <span>{hotels.location}</span></p>
                                            </Box>
                                            <Box className="iGor__body">
                                                {/* <Box className="iGor__price">
                                <p>Starting from </p>
                                <p className="iGor__price-wrapper"><span className="iGor__currency">$ </span><span className="iGor__price-value">332,00</span><span className="iGor__room"> /room</span></p>
                            </Box> */}
                                                <Box className="iGor__review">
                                                    <Box className="iGor__stars">
                                                        {[...Array(filledStars)].map((_, index) => (
                                                            <StarIcon key={index} className='iGor_starIcon' />
                                                        ))}
                                                        {[...Array(emptyStars)].map((_, index) => (
                                                            <StarIcon key={filledStars + index} className='iGor_starIcon_not_rating' />
                                                        ))}
                                                    </Box>
                                                    <span className="iGor__ratings-value"> <span>{filledStars}</span></span>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Grid>

                            )
                        }
                        ) : (<Box className='iGor-NoDataOffersSection'>
                            {t('5005')}
                        </Box>)
                    )
            }
        </Grid>
    )
}

export default ExploreCardOffers;