import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './scss/main.css';
import App from './App';
import reportWebVitals from './utils/config/reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux';
import { persistor, store } from './appStore/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AppLogHandler } from '../src/utils/handlers/appLogsHandler';
import NavigationHandlerForReset from './appStore/NavigationHandlerReset';

const env: any = process.env;
AppLogHandler('env',env)
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Router>
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <NavigationHandlerForReset/>
        <App />
        </PersistGate>
      </Provider>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
