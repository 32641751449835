import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { OrderSumRegisterModel } from "../../components/popUpSection/OrderSumRegisterModel";
import MyTrip_firsttrip from '../../assets/icons/MyTrip_firsttrip.svg';
import { useNavigate } from "react-router";
import Fav_FirstWishlist from '../../assets/icons/Fav_FirstWishlist.svg'

function MakeTripCard({setUserLoggedIn}:any) {
    const navigate = useNavigate();
    const matches = useMediaQuery('(max-width:1024px)');
    return (<>
{        setUserLoggedIn ?(<Box className={matches ? '' : 'iGor__loginCard'}>
            <Grid container spacing={2} className='iGor__CardForCenter'>
                <Grid item  lg={4} sm={4} className={matches ? 'iGor__loginCard' : ''}>
                    <img src={MyTrip_firsttrip} alt='MyTrip_firsttrip' />
                </Grid>
                <Grid item lg={8}  sm={4}  >
                    <Box sx={{
                        display: 'flex', height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>

                        <Typography variant='h4'>Make your first trip </Typography>
                        <Typography variant='body1' color={'#C4C4C4'} textAlign={'center'}>Start planning your first trip on Igoroom</Typography>
                        <Button variant='contained' sx={{ width: '167px', marginTop: "20px" }} onClick={() => navigate('/')}>Start to Search</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>):(
            <Box className={matches ? '' : 'iGor__loginCard'}>
            <Grid container spacing={2} className='iGor__CardForCenter'>
                <Grid item sm={3} className={matches ? 'iGor__loginCard' : ''}>
                    <img src={Fav_FirstWishlist} alt='MyTrip_firsttrip' />
                </Grid>
                <Grid item sm={9}>
                    <Box sx={{
                        display: 'flex', height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>

                        <Typography variant='h4' marginBottom={2}> Log In or register to enjoy Make Your First Trip </Typography>
                        <OrderSumRegisterModel />
                    </Box>
                </Grid>
            </Grid>
        </Box>
        )}
    </>);
}

export default MakeTripCard;