import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../appStore/hooks';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setOTPSentTimer } from '../../appStore/hotelSlice';

const OTPTimer = ({resendOTP}:any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { sentOTPTimer } = useAppSelector(state => state.hotel);
    const initialMinutes = sentOTPTimer?.initialMinutes ;
    const initialSeconds = sentOTPTimer?.initialSeconds;
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            } else if (seconds === 0) {
                if (minutes === 0) {
                    {
                        clearInterval(countdown);
                        dispatch(setOTPSentTimer({ initialMinutes: 0, initialSeconds: 0 }))
                    }
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    return (<Box className='iGor-OTP_timer'>
        <Typography align='center' sx={{ fontSize: '8px', color: '#000000' }} marginBottom={1}>
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </Typography>
    </Box>);
};

export default OTPTimer;
