import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Button, Grid, List, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material'
import Icon_points from '../../assets/icons/Icon_points.svg';
import Icon_iGift from '../../assets/icons/Icon_iGift.svg';
import userOutline from '../../assets/icons/userOutline.svg';
import ticketIcon from '../../assets/icons/ticketIcon.svg';
import heartIcon from '../../assets/icons/heartIcon.svg'
import Icon_bell from '../../assets/icons/Icon_bell-notification.svg';
import Icon_verify from '../../assets/icons/Icon_verify.svg';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../appStore/hooks';
import UserLogoutProfile from '../../pages/AccountDetails/UserLogoutProfile';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchFaviourateFolderList, fetchUserTripsApi, getBookedOffersList, setAddTofavouriteListReset, setBookedOfferListDataReset, setRemoveFromFaviouratesReset, setSearchDestinationFromDate, setSearchDestinationToDate, setShowFaviourateList, setUserFaviouratesListReset, setUserWishlistDataReset } from '../../appStore/hotelSlice';
import { accountOptionSectionType } from '../../utils/constants/constant';
import { calculateDateFourtyFiveDaysLater } from '../../utils/utilities/DateConverter';


export const AccountSideMenu = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getUserDetails, loggedInUserCurrency } = useAppSelector(state => state.hotel)
    const userFirstName = getUserDetails?.data?.userDetail?.firstName ? getUserDetails?.data?.userDetail?.firstName : '';
    const userLastName = getUserDetails?.data?.userDetail?.LastName ? getUserDetails?.data?.userDetail?.LastName : '';
    let userCurrency = getUserDetails?.data?.userDetail?.currency_name ? getUserDetails?.data?.userDetail?.currency_name : 'Use recommended currency based on location';
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const accountSectionType = accountOptionSectionType;
    const todaysDate = new Date();
    const tommorowsDate = new Date((new Date()).setDate((new Date()).getDate() + 1));
    const destinationSearchStartDate = calculateDateFourtyFiveDaysLater(todaysDate);
    const destinationSearchEndDate = calculateDateFourtyFiveDaysLater(tommorowsDate);

    useEffect(() => {
        userCurrency = getUserDetails?.data?.userDetail?.currency_name
    }, [getUserDetails?.data?.userDetail])

    // navigation handler
    const navigationHandler = (sectionType: string) => {
        const fromDateDispatch = destinationSearchStartDate.toISOString();
        const toDateDispatch = destinationSearchEndDate.toISOString();
        const todaysDateDispatch = todaysDate.toISOString();
        const tommmorowsDateDispatch = tommorowsDate.toISOString();
        switch (sectionType) {
            case accountSectionType.accountDetails:
                navigate('/account-details');
                dispatch(setSearchDestinationFromDate(todaysDateDispatch))
                dispatch(setSearchDestinationToDate(tommmorowsDateDispatch))
                break;
            case accountSectionType.myTrip:
                dispatch(setBookedOfferListDataReset({ data: [], loading: false, error: null }))
                dispatch(getBookedOffersList(loggedInUserId))
                dispatch(fetchUserTripsApi(loggedInUserId))
                navigate('/my-trips')
                dispatch(setSearchDestinationFromDate(todaysDateDispatch))
                dispatch(setSearchDestinationToDate(tommmorowsDateDispatch))
                break;
            case accountSectionType.myFavourite:
                dispatch(setSearchDestinationFromDate(fromDateDispatch))
                dispatch(setSearchDestinationToDate(toDateDispatch))
                dispatch(fetchFaviourateFolderList(loggedInUserId))
                dispatch(setShowFaviourateList(false))
                //need to remove this after 2-3 days
                dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
                dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
                dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
                dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
                navigate('/favourites')
                break;
            case accountSectionType.notificationPermission:
                navigate('/account-details')
                dispatch(setSearchDestinationFromDate(todaysDateDispatch))
                dispatch(setSearchDestinationToDate(tommmorowsDateDispatch))
                break;
            default: return 'No Section Matches'
        }
    }



    return (
        <>
            <Grid item sm={3} className='iGOr_mb-D-none'>
                <Box className="iGor_bgWhite iGOr_BorderRadius15">
                    <Box className=" iGoR_SideCard" borderBottom={'1px solid #C4C4C4'}>
                        <Typography variant='h4'>{`${userFirstName} ${userLastName}`} </Typography>
                        <Typography variant='body1' color={'#FF6B6B'}> Verified <img src={Icon_verify} alt='Icon_verify' /></Typography>
                        {/* <Box marginY={2}>
                            <img src={Icon_points} alt='Icon_points' />
                        </Box>
                        <Box className='iGor_setFlex iGor_justifyBetween'>
                            <Box>
                                <Typography variant='body2'>  Balance: 2,300 Points</Typography>
                                <Typography variant='body1' className='iGor_checkpoint'> Check your points details</Typography>
                            </Box>
                            <img src={Icon_RightArrow} alt='Icon_RightArrow' />
                        </Box>
                        <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                            <Box>
                                <img src={Icon_iGift} alt='Icon_points' />
                            </Box>
                            <Box>
                                <Typography variant='body1' fontWeight={'600'}>   Gift holiday voucher <br /> to your friends</Typography>
                            </Box>
                            <img src={Icon_RightArrow} alt='Icon_RightArrow' />
                        </Box> */}
                    </Box>

                    <Box className=" iGoR_SideCard">
                        <Typography variant='subtitle1'>  Personal Settings</Typography>
                        <Box>
                            <List className='iGor_Accountlist'>
                                <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.accountDetails)}>
                                    <ListItemIcon className='iGor_UserIcon'>
                                        <img src={userOutline} className='iGor_userOutline' />
                                    </ListItemIcon>
                                    <ListItemText primary={t('Account Details')} className='iGor_UserTitle' />
                                </ListItemButton>
                                <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.myTrip)}>
                                    <ListItemIcon className='iGor_UserIcon'>
                                        <img src={ticketIcon} className='ticketIcon' />
                                    </ListItemIcon>
                                    <ListItemText primary={t('My Trip')} className='iGor_UserTitle' />
                                </ListItemButton>
                                <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.myFavourite)}>
                                    <ListItemIcon className='iGor_UserIcon'>
                                        <img src={heartIcon} className='faviourateIcon' />
                                    </ListItemIcon>
                                    <ListItemText primary={t('My Favourite')} className='iGor_UserTitle' />
                                </ListItemButton>
                                {/* <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.myOffers)}>
                                    <ListItemIcon className='iGor_UserIcon'>
                                        <img src={ticketIcon} className='ticketIcon' />
                                    </ListItemIcon>
                                    <ListItemText primary={t('My Booked Offers')} className='iGor_UserTitle' />
                                </ListItemButton> */}
                            </List>
                            <Box />
                            <Typography variant='subtitle1'>Other Information</Typography>
                            <Box className='iGor_otherInfo'>
                                <a href="about-us" target='_blank'>
                                    <Typography>About Us</Typography>
                                </a>
                                <a href="terms-conditions" target='_blank'>
                                    <Typography>Terms and Conditions</Typography>
                                </a>
                                <a href="privacy-policy" target='_blank'>
                                    <Typography>Privacy Policy</Typography>
                                </a>
                                <a href="fbdatadeletion-instruction" target='_blank'>
                                    <Typography>Facebook Data Policy</Typography>
                                </a>
                            </Box>
                            <UserLogoutProfile />
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </>
    )
}
