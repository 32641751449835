import React, { useEffect, useState } from 'react';
import { Grid, Link, Box, IconButton, Button, Popover, Typography, TextField, List, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import chatIcon from '../../assets/icons/chatIcon.svg';
import Igobot from '../../assets/icons/chat_icon.svg';
import customerServiceIcon from '../../assets/icons/customerServiceIcon.svg';
import faq_Icon from '../../assets/icons/Icon_faq_Icon.svg';
import { useTranslation } from 'react-i18next';
import IgoChatBotComponent from './IgoChatBot';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';

declare global {
    interface Window {
      toggleFbotsBox: (show: boolean) => void;
    }
  }
export const NeedHelp = () => {
    // t object for language translation for keys
    const { t } = useTranslation();
    const [openChatBot, setOpenChatBot] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'Need-Help' : undefined;

    const handleFbotsBox = () => {
        // Call the function defined in the global scope
        window.toggleFbotsBox(true); // Pass true to show, false to hide ddd
        setAnchorEl(null);
      };

    return (
        <>
            <IconButton aria-describedby={id} onClick={handleClick} className='igoRoomBtn iGoR_NeedHelpBtn' sx={{ width: '150px',background:(anchorEl && '#C4C4C4') }} >
                <img src={chatIcon} alt="chatIcon" style={{marginRight:'9px'}}/>{t('Need Help')} 
            </IconButton>
        
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  sx={{
                    '& .MuiPaper-root': {
                      marginTop: '-10px', 
                      marginLeft:'-0px'
                    },
                  }}
                className='iGor__NeedHelpPop'
            >
                {openChatBot == true ? <IgoChatBotComponent setChatBot={setOpenChatBot} isOpen={openChatBot} />  : null}
                <List className='iGoRoom_NeedHelp'>
                    <ListItemButton onClick={handleFbotsBox}>
                        <ListItemIcon sx={{ minWidth: '35px' }}>
                            <img src={Igobot} />
                        </ListItemIcon>
                        <ListItemText primary={t('Igochat')} secondary={t('Quick answer to your queries')} className='iGoroom_NhTitle' />
                    </ListItemButton>
                    <a href='https://support.igoroom.com/support/home' target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
                        <ListItemButton sx={{ borderTop: '1px solid #DADADA', borderBottom: '1px solid #DADADA' }}>
                            <ListItemIcon sx={{ minWidth: '35px' }}>
                                <img src={faq_Icon} />
                            </ListItemIcon>
                            <ListItemText primary={t('Support Center')} secondary={t('Information about')} className='iGoroom_NhTitle' />
                        </ListItemButton>
                    </a>
                    {/* <ListItemButton>
                        <ListItemIcon sx={{ minWidth: '35px' }}>
                            <img src={Igobot} />
                        </ListItemIcon>
                        <ListItemText primary={t('Customer Services')} secondary={t('Schedule a callback from of agents')} className='iGoroom_NhTitle' />
                    </ListItemButton> */}
                </List>
            </Popover>
             
        </>
    );
}
