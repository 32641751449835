import React, { useState } from 'react'
import { useAppSelector } from '../../appStore/hooks';
import PayPalGateWay from './PayPalGateWay';
import StripeGateWays from './StripeGateWays';
import { Button, Typography } from '@mui/material';
import { eventNameForMoeEvents, paymentGateWaysType } from '../../utils/constants/constant';
import { fontSize } from '@mui/system';
import { EventLogHanlderMoengage } from '../../utils/handlers/EventLogsHandler';

function PaymentGateWays() {
  const payPalGateWay = paymentGateWaysType.paypal;
  const stripeGateWay = paymentGateWaysType.stripe;
  const [gateWayType, setGateWayType] = useState(payPalGateWay);
  const hotelPriceData: any = useAppSelector(state => state.hotel.hotelRoomPriceApiData.data)
  const [roomGroup] = hotelPriceData?.roomGroup ? hotelPriceData?.roomGroup : []
  let roomPriceSelected = roomGroup?.roundOffTotalRate;
  const currencyForcheckout = roomGroup?.currency;
  const eventNameViewScreenMoe = eventNameForMoeEvents.ViewScreen;
  const refundability =roomGroup?.refundability ?roomGroup?.refundability :'';

  // event logs for Moengage
  const eventIdsForLogEventsMoe = {
    "Payment Screen": "Payment Screen"
  }
  EventLogHanlderMoengage(eventNameViewScreenMoe, eventIdsForLogEventsMoe)

  return (
    <>
      {/* <StripeGateWays
          currencyForcheckout={currencyForcheckout}
          roomPriceSelected={roomPriceSelected?.toFixed()}
        />
      <Typography style={{margin :'30px 0 10px 10px',fontSize:'14px' ,fontWeight:'bold' }}>Or pay with PayPal</Typography>  */}
      <PayPalGateWay
        currencyForcheckout={currencyForcheckout}
        roomPriceSelected={roomPriceSelected}
        refundability={refundability}
      />
    
    </>
  );
}

export default PaymentGateWays;