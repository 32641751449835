import { Box, Button } from '@mui/material';
import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { setHotelsTypeTabSelection } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../appStore/hooks';

export const CustomTabs = ({ setTabType }: any) => {
    const dispatch = useDispatch();
    const pathname = window.location.pathname
    const [activeTab, setActiveTab] = useState(1); // Default active tab is 1
    const matches = useMediaQuery('(max-width:1024px)');
    const { loggedInUserCurrency, selectedHotelsTypeTab } = useAppSelector(state => state.hotel);
    const hotelsTypeSelected = selectedHotelsTypeTab;
    const loggedInUserId: any = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const buttonStyles = { fontWeight: '400' }
    const handleTabClick = (tabNumber: any, tabName: any) => {
        setActiveTab(tabNumber);
        // if (loggedInUserId == 0 && pathname == '/latest-offers' && tabName == 'OFFERS') {
        //     dispatch(setAlertBoxOnError(true))
        // }
        // else if (tabName == 'ALL') {
        //     dispatch(setAlertBoxOnError(false))
        // }
        dispatch(setHotelsTypeTabSelection(tabName))
        if(setTabType)setTabType(tabName)
    };

    useEffect(() => {
        switch (hotelsTypeSelected) {
            case 'ALL':
                setActiveTab(1);
                break;
            case 'HOTELSRESORT':
                setActiveTab(2);
                break;
            case 'VIP':
                setActiveTab(3);
                break;
            case 'OFFERS':
                break;
            default: setActiveTab(1);
        }
    }, [hotelsTypeSelected])

    return (
        <>
            {!matches &&
                <Box className={pathname == '/latest-offers' ? 'iGor_CustomTabs_Offers' : "iGor_CustomTabs"}>
                    <Button style={buttonStyles} onClick={() => handleTabClick(1, 'ALL')} className={activeTab === 1 ? 'iGor_activeTab iGor_btnTab' : 'iGor_btnTab'}>
                        All
                    </Button>
                    <Button style={buttonStyles} onClick={(e) => handleTabClick(4, 'OFFERS')} className={activeTab === 4 ? 'iGor_activeTab iGor_btnTab btncenter' : 'iGor_btnTab btncenter'}>
                        Offers
                    </Button>
                    <Button style={buttonStyles} onClick={(e) => handleTabClick(2, 'HOTELSRESORT')} className={activeTab === 2 ? 'iGor_activeTab iGor_btnTab btncenter' : 'iGor_btnTab btncenter'}>
                        Hotels & Resorts
                    </Button>
                    <Button style={buttonStyles} onClick={(e) => handleTabClick(3, 'VIP')} className={activeTab === 3 ? 'iGor_activeTab iGor_btnTab' : 'iGor_btnTab'}>
                        VIP
                    </Button>
                </Box>
            }
            {matches &&
                <Box className={"iGor_CustomTabs"} >
                    <Button onClick={(e) => handleTabClick(1, 'ALL')} className={activeTab === 1 ? 'iGor_activeTab iGor_btnTab' : 'iGor_btnTab'}>
                        Show All
                    </Button>
                    <Button onClick={(e) => handleTabClick(4, 'OFFERS')} className={activeTab === 4 ? 'iGor_activeTab iGor_btnTab' : 'iGor_btnTab'}>
                        Offers
                    </Button>
                    <Button onClick={(e) => handleTabClick(2, 'HOTELSRESORT')} className={activeTab === 2 ? 'iGor_activeTab iGor_btnTab btncenter' : 'iGor_btnTab btncenter'}>
                        Hotels & Resorts
                    </Button>
                    <Button onClick={(e) => handleTabClick(3, 'VIP')} className={activeTab === 3 ? 'iGor_activeTab iGor_btnTab' : 'iGor_btnTab'}>
                        VIP
                    </Button>
                </Box>
            }
        </>
    );
};
