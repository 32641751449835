import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { env } from '../../utils/config/appConfig';
import Slider from "react-slick";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NoHotelsImg from '../../assets/images/noHotelIgo.jpg'
import { useAppSelector } from '../../appStore/hooks';
import { cacheImages } from '../../utils/utilities/ImageCaching';
import { useDispatch } from 'react-redux';
import { setExploreThingToDoSelectedReset, setPreviousPathName } from '../../appStore/hotelSlice';
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import { EventNamesForEventLogs } from '../../utils/constants/constant';
import LazyLoadImagesComp from './LazyLoadImages';
import { environmentVariables } from '../../utils/config/environmentVariables';
import placeHolderImage from '../../assets/images/placeholder.svg';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import ImageComponent from '../../utils/utilities/ImageComp';


const ExploreThingsToDoCards = ({ setclassName, thingsToDoArray, paramsIdToNextPath }: any) => {
    const dispatch = useDispatch();
    const { exploreDestinationsApiData } = useAppSelector(state => state.hotel)
    const exploreLoading = exploreDestinationsApiData.loading;
    const imagePathUrl = environmentVariables.imagePathApp;
    const eventNameExploreCulture = EventNamesForEventLogs.exploreCulture;
    const eventNameExploreEvents = EventNamesForEventLogs.exploreUpComingEvents;
    const [imageLoaded, setImageLoaded] = useState(false);
    const previousPath = window.location.pathname;
    const matches = useMediaQuery('(max-width:1024px)');

    function NextArrow(props: any) {
        const { className, style, onClick } = props;
        const arrowClickHanlder = (e: any) => {
            const { clientX, clientY } = e;
            AppLogHandler('arrowClickHanlder', { clientX, clientY })
            if (onClick) onClick(e)
        }
        return (
            <ArrowForwardIcon
                className={className}
                style={{
                    ...style, display: "block", color: "#ffffff", border: '1.5px solid',
                    borderRadius: '50px'
                }}
                onClick={arrowClickHanlder}
            // disabled={currentBatchIndex * batchSize >= thingsToDoArray.length}
            />
        );
    }

    function PrevArrow(props: any) {
        const { className, style, onClick } = props;
        return (
            <ArrowBackIcon
                className={className}
                style={{
                    ...style, display: "block", color: "#ffffff", border: '1.5px solid',
                    borderRadius: '50px'
                }}
                onClick={onClick}
            />
        );
    }

    const findThigSlider = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                slidesToShow: 4,
              },
              
            },
            {
                breakpoint: 767,
                settings: {
                  slidesToShow: 2,
                  
                },
                
              },
        ]
    };


    const exploreThingsToDoHandler = (id: number, title: string, category: string) => {
        // event log to analytics
        if (category == 'Culture') {
            const eventIdsForLogEvents = {
                "ig_culture_id": id.toString(),
                "ig_culture_title": title
            }
            EventLogsHandler(eventNameExploreCulture, eventIdsForLogEvents)
        }
        else if (category == 'Events') {
            const eventIdsForLogEvents = {
                "ig_upcoming_event_id": id.toString(),
                "ig_upcoming_event_title": title
            }
            EventLogsHandler(eventNameExploreEvents, eventIdsForLogEvents)
        }
        dispatch(setExploreThingToDoSelectedReset({ data: [], loading: false, error: null }))
    }

    // commnted because when did refresh only skeleton showing after images loaded
    // useEffect(() => {
    //     // Reset imageLoaded state on component unmount
    //     if (imageLoaded == false) {
    //         setTimeout(() => { setImageLoaded(true) }, 3000)
    //     }
    // }, [imageLoaded]);

    const handleImageLoad = () => {
        // Set imageLoaded to true when the image is loaded
        setTimeout(() => { setImageLoaded(true) }, 1000)
    };


    return (
        <>
            <Slider {...findThigSlider} className='iGor__findThigSlider'>
                {exploreLoading == true ?
                    ([...Array(2)].map(() => {
                        return (<Box className={`iGor_ExMore_cards ${setclassName}`}>
                            <Box className="iGor_ExMore_images">
                                <Skeleton
                                    variant="rectangular"
                                    width={"100%"}
                                    height={200}
                                    animation="wave"
                                    sx={{ borderRadius: '0.625rem' }} />
                            </Box>
                        </Box>)
                    })) : (thingsToDoArray?.length > 0 ? thingsToDoArray.map((thingsToData: any, index: any) => {
                        const imageUrl: any = cacheImages(`${imagePathUrl}${thingsToData.bg_image}`)
                        return (<>
                            <Box className={`iGor_ExMore_cards ${setclassName}`} onClick={(e) => exploreThingsToDoHandler(thingsToData.id, thingsToData.title, thingsToData.category)}>
                                <Box className="iGor_ExMore_images">
                                    {imageLoaded == false &&
                                        <Skeleton
                                            variant="rectangular"
                                            width={"100%"}
                                            height={200}
                                            animation="wave"
                                            sx={{ borderRadius: '0.625rem' }}
                                        />}
                                    <Link
                                        className="iGor_ExMore_img"
                                        to={`/explore/${paramsIdToNextPath}/${thingsToData.slug}`}
                                        state={{
                                            slug: thingsToData.slug,
                                            prevPathid: paramsIdToNextPath
                                        }}
                                        style={{
                                            display: imageLoaded ? 'block' : 'none'
                                        }}
                                    >
                                        {/* <LazyLoadImagesComp
                                                key={index}
                                                handleImageLoad={handleImageLoad}
                                                images={imageUrl.src}
                                                alt={thingsToData.category}
                                                scrollPosition={slidePosition}
                                            /> */}
                                        {/* <img
                                            onLoad={handleImageLoad}
                                            src={imageUrl.src}
                                            onError={(e: any) => {
                                                // Handle the image load error by setting the source to the default image
                                                e.target.src = NoHotelsImg;
                                            }}
                                            alt={thingsToData.category} /> */}


                                        <ImageComponent imageUrl={imageUrl.src} handleImageLoad={handleImageLoad} />
                                    </Link>
                                </Box>
                                <Box className="iGor_ExMore_description">
                                    {!matches && <Button className="iGor_ExMore_btn" >
                                        <span>{thingsToData.category}</span>
                                    </Button>}
                                    <Link
                                        className="iGor_ExMore_title"
                                        to={`/explore/${paramsIdToNextPath}/${thingsToData.slug}`}
                                        state={{
                                            slug: thingsToData.slug,
                                            prevPathid: paramsIdToNextPath
                                        }}
                                    >{thingsToData.title}</Link><span className="subtitle"></span>
                                    <Typography color={"#757575"} fontSize={'8px'} fontStyle={'italic'}>Make culture discoveries at Indonesia</Typography>
                                </Box>
                            </Box>
                        </>)
                    }) : <>No options available</>
                    )}
            </Slider>
        </>
    )
}

export default ExploreThingsToDoCards;