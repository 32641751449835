import React, { useState, useEffect } from 'react'
import Header from '../../layouts/Header'
import { Box } from "@mui/material";
import { DestinationSearch } from '../DestinationSearch/DestinationSearch';
import layoutBg from '../../assets/images/homeLanding.jpg'
import noImageFound from '../../assets/images/No Hotels Img.png'
import { useAppSelector } from '../../appStore/hooks';
import { env } from '../../utils/config/appConfig';
import { useNavigate } from 'react-router';
import VTUrlParser from '../../utils/utilities/VTUrlParser';
import { environmentVariables } from '../../utils/config/environmentVariables';

export const Explore = () => {
  const navigate= useNavigate();
  const [num, setNum] = useState(0);
  const { destinationForSearch } = useAppSelector(state => state.hotel);
  let destinationsSearch: [any] = destinationForSearch?.destinations ? destinationForSearch?.destinations : []
  const defaultImage = layoutBg;
  const imagePathUrl = environmentVariables.imagePathApp;
  let vrContentForExplore :string  = ''

  // vr image map function
  const iframeVal = destinationsSearch ? destinationsSearch?.map((destinations: any) => {
    return destinations ? destinations : ['']
  }) : ['']

  const arrayLength = iframeVal.length;
  const handleIncrement = () => {
    setNum((prevNum) => (prevNum + 1) % arrayLength);
  };
  const handleDecrement = () => {
    setNum((prevNum) => (prevNum - 1 + arrayLength) % arrayLength);
  };

  // useEffect for updating states of the data received from api
  useEffect
    (() => {
      destinationsSearch = destinationForSearch?.destinations ? destinationForSearch?.destinations : []
      if (destinationForSearch.status == '' && destinationForSearch.message == '') {
        navigate('/')
      }
    }, [destinationForSearch])

    return (
    <>
        {iframeVal.length > 0 ? (
          <><iframe
            src={VTUrlParser(iframeVal[num]?.vt)}
            onError={(e: any) => {
              e.target.src = noImageFound;
            }}
            id="landingPageIframe">
          </iframe>
          </>
      ) : (
        <Box className="iGoRoom_NotIfrome" sx={{ backgroundImage: `url(${imagePathUrl} ${defaultImage})` }}>
        </Box> // You can replace this with your custom loading message or spinner
      )}
      <div className="iGoRoom_LayoutMain" >
        <Header />
        <DestinationSearch destinationName={iframeVal[num]?.titleBg ? iframeVal[num]?.titleBg : 'Not Available'} destinationLocation={iframeVal[num]?.name ? iframeVal[num]?.name : 'Not Available'} incrFun={handleIncrement} decFun={handleDecrement} setPageShow="Explore" />
      </div>
    </>
  )
}