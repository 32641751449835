import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdjustIcon from '@mui/icons-material/Adjust';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { iconMapping, IconName } from './IconMapping';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface IconMapperProps {
  iconName: IconName;
}

const PropertyFacilitiesIconMapper: React.FC<IconMapperProps> = ({ iconName }) => {
  const [prefix, icon] = iconName.split(' ');

  AppLogHandler('IconComponent', iconName);

  if (!prefix || !icon || !iconMapping[prefix]) {
    return (
      <div className='iGor-facilities_default'>
        <FontAwesomeIcon icon={['fas', 'check'] as IconProp} />
      </div>
    );
  }

  return <FontAwesomeIcon icon={[prefix.replace('fa-', ''), icon.replace('fa-', '')] as IconProp} />;
};

export default PropertyFacilitiesIconMapper;
