import { useState, useEffect } from "react";
import { Slider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AppLogHandler } from "../handlers/appLogsHandler";
import { useDispatch } from "react-redux";
import { setAppliedPriceRangeFiltersForHotels, setWebFiltersEnable } from "../../appStore/hotelSlice";
import { useAppSelector } from "../../appStore/hooks";
import { useTranslation } from "react-i18next";
import priceRange from '../../assets/images/PriceRangeGraph3.svg';
import applyFiltersPayload from "../../services/applyFiltersApiPayload";
import { useApplyFiltersHotelsResultMutation } from "../../services/api/hotelApi";
import numeral from "numeral";

function PriceRangeFilters({ currencySymbolPrice }: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setPriceRangeSelectedFilters, loggedInUserCurrency, setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters,
        setMobilePropertyTypeSelectedFilters, setMobileRoomTypeSelectedFilters, initApi, screenTypeSeleted,
        selectedCurrencySymboll } = useAppSelector(state => state.hotel);
    const filtersForUI = useAppSelector(state => state.hotel.getUIFiltersData);
    const searchId = initApi.searchId;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 270;
    let currencySelected = selectedCurrencySymboll;
    const currencySym = currencySymbolPrice;
    // apply filters api call assign to variable for dynamic use
    const [applyFilters] = useApplyFiltersHotelsResultMutation();

    // assign the price for updated price and default prices
    let defaultPriceRanges = filtersForUI?.data?.priceRange;

    const defaultMinPrice = defaultPriceRanges?.min_price;
    const defaultMaxPrice = defaultPriceRanges?.max_price;
    const setPriceRangesSelected = setPriceRangeSelectedFilters;
    const updatedMinPrice = setPriceRangesSelected?.min_price;
    const updatedMaxPrice = setPriceRangesSelected?.max_price;

    // setting the price state
    const [value, setValue] = useState<number[]>([
        defaultMinPrice, defaultMaxPrice
    ]);

    // price change handler
    const priceRangeChangeHandler = (event: Event, newValue: number | number[]) => {
        AppLogHandler("priceRangeChangeHandler", newValue)
        setValue(newValue as number[]);
    }

    // price commited on drag end hanlder
    const handlePriceChangeCommitHandler = (event: any, newValue: any) => {
        let payload: any = {}
        dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: newValue[0], max_price: newValue[1] }))
        dispatch(setWebFiltersEnable(true))
        if (screenTypeSeleted == 'Web') {
            payload = applyFiltersPayload(loggedInUserId, setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters, newValue[0], newValue[1], searchId)
            if (payload) {
                applyFilters({ payload })
            }
        }
        else if (screenTypeSeleted == 'Mobile') {
            payload = applyFiltersPayload(loggedInUserId, setMobilePropertyTypeSelectedFilters, setMobileRoomTypeSelectedFilters, newValue[0], newValue[1], searchId)
            if (payload) {
                applyFilters({ payload })
            }
        }
    }

    useEffect(() => {
        // if (value[0] != defaultMinPrice || value[1] != defaultMaxPrice) {
        //     dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: value[0], max_price: value[1] }))
        // }
        if (value[0] == undefined || value[1] == undefined) {
            setValue([defaultMinPrice, defaultMaxPrice])
        }
        else if (updatedMinPrice == 0 && updatedMaxPrice == 0) {
            setValue([defaultMinPrice, defaultMaxPrice])
        }
        // else if (updatedMinPrice == defaultMinPrice && updatedMaxPrice == defaultMaxPrice) {
        //     setValue([updatedMinPrice, updatedMaxPrice])
        // }
    }, [value[0], value[1], updatedMinPrice, updatedMaxPrice, defaultMinPrice, defaultMaxPrice])

    // const formattedPrice = numeral(offersHotels?.listPrice?.minPrice).format('0,0');


    return (<>
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2" className='subtitle2'>{t("Price range")}</Typography>
                <Typography variant="subtitle2" style={{ fontWeight: '500' }}>{currencySym}{value[0] || value[0] == 0 ? numeral(value[0]).format('0,0') : 0} - {currencySym}{value[1] ? numeral(value[1]).format('0,0') : 0}</Typography>
            </Box>
            <Box className='iGor_priceRange'>

                <img src={priceRange} className='priceRange' />
                <Slider className='iGor_silder'
                    getAriaLabel={() => 'Temperature range'}
                    max={defaultMaxPrice}
                    value={value}
                    onChange={priceRangeChangeHandler}
                    onChangeCommitted={handlePriceChangeCommitHandler}
                    sx={{ top: '-20px' }}
                />
            </Box>
        </Box>
    </>);
}

export default PriceRangeFilters;