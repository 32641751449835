import React, { useEffect, useState } from 'react';
import ExploreThingsToDoCards from '../../components/ExploreMore/ExploreThingsToDo';
import { useAppSelector } from '../../appStore/hooks';
import { useParams } from 'react-router';

const ThingsToDo = ({ thingsToDoTitle }: any) => {
    const params: any = useParams();
    const paramsIdToNextPath = params.id;
    const [thingsToDoTypesData, setThingsToDoTypesData] = useState<any[]>([]);
    const { exploreDestinationsApiData } = useAppSelector(state => state.hotel);
    const exploreDestinationData = exploreDestinationsApiData.data;
    const thingsToDoArray = exploreDestinationData?.thingsToDo || [];

    useEffect(() => {
        let newData: any[] = [];
        switch (thingsToDoTitle) {
            case 'All':
                newData = thingsToDoArray?.map((category: any) => category.data).reduce((a: any, b: any) => a.concat(b), []);
                break;
            default:
                newData = thingsToDoArray
                    ?.filter((thingsToDo: any) => thingsToDo.title === thingsToDoTitle)
                    .map((thingsToDo: any) => thingsToDo.data)
                    .reduce((a: any, b: any) => a.concat(b), []);
                break;
        }
        setThingsToDoTypesData(newData);
    }, [thingsToDoTitle, thingsToDoArray]);

    return (
        <>
            <ExploreThingsToDoCards
                thingsToDoArray={thingsToDoTypesData}
                paramsIdToNextPath={paramsIdToNextPath}
            />
        </>
    );
};

export default ThingsToDo;
