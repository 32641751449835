export function AdultCount(arr:[any]){
    let no_adult=0
    for(let i=0;i<arr.length;i++){
        no_adult=no_adult+arr[i].adults
    }
    return no_adult
}
export function ChildCount(arr:[any]){
    let no_Child=0
    for(let i=0;i<arr.length;i++){
        no_Child=no_Child+arr[i].children
    }
    return no_Child
}