import {createApi,fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import { environmentVariables } from '../utils/config/environmentVariables';

const baseUrl = environmentVariables.appApiBaseUrl;
export const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl:  baseUrl,
        prepareHeaders: async (headers) => {
            headers.set('Accept', 'application/json')
            headers.set('Content-Type', 'application/json; charset=UTF-8')
            return headers
        },
        credentials: 'include',
    }), 
    endpoints: () => ({}),
    reducerPath: 'api',
    tagTypes: ['hotel'],
})
