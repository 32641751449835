import React, { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import userIcon from '../../assets/icons/Icon_userColorFill.svg';
import userIconOutline from '../../assets/icons/Icon_user-Black.svg';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Icon_delete from '../../assets/icons/Icon_delete.svg';
import { useAppSelector } from '../../appStore/hooks';
import { useDispatch } from 'react-redux';
import { setGuestDetailsAtCheckIn } from '../../appStore/hotelSlice';
import { ToastContainer } from 'react-toastify';
import Select from '@mui/material/Select';
import PassengersCount from '../../utils/utilities/PassengerCounts';
import roomsAndGuestCountHandler from '../../utils/utilities/RoomAndGuestCount';

export const OrderGuestDetails = ({ errors, setErrors, validateGuestDetails, continuePayment, setContinuePayment }: any) => {
  const dispatch = useDispatch();
  const [cardOpen, setCardOpen] = useState(false);
  const { getUserDetails, setUserLoggedIn } = useAppSelector(state => state.hotel)
  const [anotherPerCard, setAnotherPerCard] = useState(!setUserLoggedIn);
  const roomArrayForGuest = useAppSelector(state => state.hotel.roomArray)
  const passengerDetails = useAppSelector(state => state.hotel.guestDetailsAtCheckIn)
  const passengerDataForLoggedInUser = passengerDetails[0]?.adultGuests[0]
  const firstNameLoggedIn = getUserDetails.data?.userDetail?.firstName ? getUserDetails.data?.userDetail?.firstName : ''
  const lastNameLoggedIn = getUserDetails.data?.userDetail?.LastName ? getUserDetails.data?.userDetail?.LastName : ''
  // const passengerCountsForDetails: any = PassengersCount(roomArrayForGuest)
  const userLoggedIn = useAppSelector(state => state.hotel.setUserLoggedIn)

  const [guestsDetails, setGuestDetails] = useState(passengerDetails);
  const roomsCounter = roomsAndGuestCountHandler(roomArrayForGuest);

  // handle for another person and myself booking selection
  const handleRadioChange = (e: any) => {
    if (setContinuePayment) setContinuePayment(false)
    e.target.value === "person" ? setAnotherPerCard(true) : userLoggedIn == true ?  setAnotherPerCard(false) : ''
    if (anotherPerCard == false) {
      setGuestDetails(roomsCounter)
      dispatch(setGuestDetailsAtCheckIn(roomsCounter))
    }
  }

  // guest info input handler
  const handleGuestInputChange = (e: any, roomIndex: number, guestIndex: number, guestType: string) => {
    const { name, value } = e.target;
    setGuestDetails((prevData: any) =>
      prevData?.map((room: any, i: number) =>
        i === roomIndex ? {
          ...room,
          adultGuests: guestType === 'adult' ? room?.adultGuests?.map((adult: any, j: number) =>
            j === guestIndex ? { ...adult, [name]: value, id: j + 1 } : adult
          ) : room?.adultGuests,
          childGuests: guestType === 'child' ? room?.childGuests?.map((child: any, j: number) =>
            j === guestIndex ? { ...child, [name]: value, id: j + 1 } : child
          ) : room?.childGuests
        } : room
      )
    );
    errorHandlerFunction(name, roomIndex, guestIndex, guestType)
  };

  // to update states of erros when value is entered
  const errorHandlerFunction = (name: string, roomIndex: number, guestIndex: number, guestType: string) => {
    setErrors((prevErrors: any) => {
      const newErrors = prevErrors.map((roomError: any, roomErrorIndex: number) => {
        if (roomErrorIndex === roomIndex) {
          return {
            ...roomError,
            adultGuests: guestType === 'adult' ? roomError?.adultGuests.map((adultError: any, guestErrorIndex: number) =>
              guestErrorIndex === guestIndex ? { ...adultError, [name]: '' } : adultError
            ) : roomError?.adultGuests,
            childGuests: guestType === 'child' ? roomError?.childGuests.map((childError: any, guestErrorIndex: number) =>
              guestErrorIndex === guestIndex ? { ...childError, [name]: '' } : childError
            ) : roomError?.childGuests,
          };
        } else {
          return roomError;
        }
      });
      return newErrors;
    });
  }


  // set logged in user details for first adult handler
  const loggedInUserDetailsHandler = () => {
    setGuestDetails((prevData: any) => {
      const updatedGuestDetails = prevData?.map((room: any, roomIndex: number) => {
        if (roomIndex === 0 && room?.adultGuests?.length > 0) {
          return {
            ...room,
            adultGuests: room?.adultGuests?.map((adultGuest: any, adultGuestIndex: number) => {
              if (adultGuestIndex === 0) {
                return {
                  ...adultGuest,
                  firstName: firstNameLoggedIn,
                  lastName: lastNameLoggedIn,
                  id: 1
                };
              }
              else if(adultGuestIndex !== 0) {
                return {
                  ...adultGuest,
                  firstName: '',
                  lastName: '',
                  id: adultGuestIndex + 1
                };
              }
              return adultGuest;
            })

          };
        }
        return room;
      });
      return updatedGuestDetails;
    });
  }

  // Set logged in user etails for object at index 0
  useEffect(() => {
    if (!anotherPerCard) {
      loggedInUserDetailsHandler()
    }
    else if (continuePayment == true && anotherPerCard) {
      validateGuestDetails()
      setCardOpen(true)
    }

    if (continuePayment == true && passengerDataForLoggedInUser.firstName !== '') {
      validateGuestDetails()
      setCardOpen(true)
    }
  }, [anotherPerCard, continuePayment]);

  // useEffect to dispatch updated guest details
  useEffect(() => {
    if (guestsDetails) {
      dispatch(setGuestDetailsAtCheckIn(guestsDetails))
    }
  })

  return (
    <>
      <Box className="iGor_bgWhite iGOr_BorderRadius15 iGor_Guestdetails" padding={2}>
        <Box className='iGor_setFlex iGor_justifyBetween' onClick={() => { setCardOpen(!cardOpen) }}>
          <Box className='iGor_setFlex iGor_alignCenter' width={'100%'} >
            <img src={userIcon} className='iGor_userIcon' />
            <Typography variant='body2'>Fill in guest details</Typography>
          </Box>
          <ArrowDownIcon />
        </Box>
        {cardOpen &&
          <Box className='iGor_GuestRadioSection'>
            <RadioGroup
              aria-labelledby="radios"
              name="quiz"
              // value={value}
              onChange={handleRadioChange}
            >

              <FormControlLabel value="myself" control={<Radio className='iGor_radioBtn' checked={!anotherPerCard} />} label="I’m booking for myself" />
              <FormControlLabel value="person" control={<Radio className='iGor_radioBtn' checked={anotherPerCard} />} label="I’m booking for another person" />
            </RadioGroup>



            {
              <Box className='iGor_orderAnotherper'>
                {!anotherPerCard && roomsCounter?.map((roomsCount: any, roomIndex: number) => {
                  return (<>
                    <Box className='iGor_orderGuestTitles'>
                      <Typography variant='body2' className='iGor_titles iGor_mainTitle'> Room {roomsCount.room + 1}</Typography>
                      {/* <Typography className='iGor_titles'>{`${roomsCount.adultCount} ${roomsCount.adultCount > 1 ? ' Adults' : ' Adult'}`} {roomsCount.childCount > 0 ? ", " + roomsCount.childCount + `${roomsCount.childCount > 1 ? ' Children' : ' Child'}` : ''}</Typography> */}
                    </Box>
                    <Box>{roomsCount?.adultGuests?.map((guestDetails: any, adultIndex: number) => {

                      return (<>
                        <Typography variant='body2' marginY={1}> Adult{` ${adultIndex + 1}`} </Typography>
                        <Grid container spacing={1} className='iGor__orderField'>
                          <Grid item sm={2} xs={3} >
                            <Box className='iGor_setRelative'>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  className='iGor_selectOrder'
                                  name='title'
                                  value={guestsDetails[roomIndex]?.adultGuests[adultIndex].title ? guestsDetails[roomIndex].adultGuests[adultIndex].title : "Mr"}
                                  onChange={(e) => handleGuestInputChange(e, roomIndex, adultIndex, 'adult')}
                                >
                                  <MenuItem value={"Mr"}> Mr</MenuItem>
                                  <MenuItem value={"Ms"}> Ms</MenuItem>
                                  <MenuItem value={"Mrs"}> Mrs</MenuItem>
                                  <MenuItem value={"Miss"}> Miss</MenuItem>
                                  <MenuItem value={"Dr"}> Dr</MenuItem>
                                </Select>

                              </FormControl>
                              {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px' }}>{errors[roomIndex]?.adultGuests[adultIndex].title}</div>}
                              {/* <Box className='iGor_OrderSumDelBtn'>
                               <img src={Icon_delete} alt='Icon_delete' />
                              </Box> */}
                            </Box>
                          </Grid>
                          <Grid item sm={5} xs={9} >
                            <Box className='iGor_setRelative'>
                              <TextField
                                name='firstName'
                                value={guestsDetails[roomIndex]?.adultGuests[adultIndex].firstName}
                                // value={guestDetails.firstName}
                                placeholder=' First Name '
                                onChange={(e) => handleGuestInputChange(e, roomIndex, adultIndex, 'adult')}
                                // disabled={setUserLoggedIn && index == 0? true : false}
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       <img src={userIconOutline} alt='userIconOutline' />
                                //     </InputAdornment>
                                //   ),
                                // }}
                                className='iGor_TextFieldGrey'
                                fullWidth
                              />
                              {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.adultGuests[adultIndex].firstName}</div>}
                              {/* <Box className='iGor_OrderSumDelBtn'>
                        <img src={Icon_delete} alt='Icon_delete' />
                      </Box> */}
                            </Box>
                          </Grid>
                          <Grid item sm={5} xs={9} >
                            <TextField
                              name='lastName'
                              value={guestsDetails[roomIndex]?.adultGuests[adultIndex].lastName}
                              placeholder='Last Name '
                              // disabled={setUserLoggedIn && index == 0? true : false}
                              onChange={(e) => handleGuestInputChange(e, roomIndex, adultIndex, 'adult')}

                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       <img src={userIconOutline} alt='userIconOutline' />
                              //     </InputAdornment>
                              //   ),
                              // }}
                              className='iGor_TextFieldGrey'
                              fullWidth
                            />
                            {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.adultGuests[adultIndex].lastName}</div>}
                          </Grid>
                        </Grid>                      </>)
                    })}</Box>
                    <Box>{roomsCount?.childGuests?.map((childDetails: any, childIndex: number) => {
                      return (<>
                        <Typography variant='body2' marginY={1}> {childDetails.type == "Adult" ? "Adult" : 'Child'}{` ${childIndex + 1}`} </Typography>
                        <Grid container spacing={1} className='iGor__orderField'>
                          <Grid item sm={2} xs={3} >
                            <Box className='iGor_setRelative'>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  className='iGor_selectOrder'
                                  name='title'
                                  value={guestsDetails[roomIndex].childGuests[childIndex].title ? guestsDetails[roomIndex].childGuests[childIndex].title : "Mr"}

                                  // value={guestDetails.title}
                                  onChange={(e) => handleGuestInputChange(e, roomIndex, childIndex, 'child')}
                                >
                                  <MenuItem value={"Mr"}> Mr</MenuItem>
                                  <MenuItem value={"Ms"}> Ms</MenuItem>
                                  <MenuItem value={"Child"}> Child</MenuItem>
                                </Select>

                              </FormControl>
                              {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.childGuests[childIndex].title}</div>}
                              {/* <Box className='iGor_OrderSumDelBtn'>
                              <img src={Icon_delete} alt='Icon_delete' />
                            </Box> */}
                            </Box>
                          </Grid>
                          <Grid item sm={5} xs={9} >
                            <Box className='iGor_setRelative'>
                              <TextField
                                name='firstName'
                                value={guestsDetails[roomIndex].childGuests[childIndex].firstName}
                                placeholder=' First Name '
                                onChange={(e) => handleGuestInputChange(e, roomIndex, childIndex, 'child')}
                                // disabled={setUserLoggedIn && index == 0? true : false}
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       <img src={userIconOutline} alt='userIconOutline' />
                                //     </InputAdornment>
                                //   ),
                                // }}
                                className='iGor_TextFieldGrey'
                                fullWidth
                              />
                              {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.childGuests[childIndex].firstName}</div>}
                              {/* <Box className='iGor_OrderSumDelBtn'>
                              <img src={Icon_delete} alt='Icon_delete' />
                              </Box> */}
                            </Box>
                          </Grid>
                          <Grid item sm={5} xs={9} >
                            <TextField
                              name='lastName'
                              value={guestsDetails[roomIndex].childGuests[childIndex].lastName}
                              // value={guestDetails.lastName}
                              placeholder='Last Name '
                              // disabled={setUserLoggedIn && index == 0? true : false}
                              onChange={(e) => handleGuestInputChange(e, roomIndex, childIndex, 'child')}

                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       <img src={userIconOutline} alt='userIconOutline' />
                              //     </InputAdornment>
                              //   ),
                              // }}
                              className='iGor_TextFieldGrey'
                              fullWidth
                            />
                            {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.childGuests[childIndex].lastName}</div>}
                          </Grid>
                        </Grid>
                      </>)
                    })}</Box>
                  </>)
                })}
                {/* {!anotherPerCard && guestsDetails.map((guest: any, index: number) => {
                  return (<>
                    <Typography variant='body2' marginY={1}> {guest.type == "Adult" ? "Adult" : 'Child'}{` ${index + 1}`} </Typography>
                    <Grid container spacing={1} className='iGor__orderField'>
                      <Grid item sm={2} xs={3} >
                        <Box className='iGor_setRelative'>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              className='iGor_selectOrder'
                              name='title'
                              value={guest.title}
                              onChange={(e) => handleGuestInputChange(e, index, e.target.name)}
                            >
                              <MenuItem value={"Mr"}> Mr</MenuItem>
                              <MenuItem value={"Mrs"}> Mrs</MenuItem>
                            </Select>

                          </FormControl>
                          {errors[index] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px' }}>{errors[index]?.title}</div>}
                        </Box>
                      </Grid>
                      <Grid item sm={5} xs={9} >
                        <Box className='iGor_setRelative'>
                          <TextField
                            name='firstName'
                            value={guest.firstName}
                            placeholder=' First Name '
                            onChange={(e) => handleGuestInputChange(e, index, e.target.name)}
                            className='iGor_TextFieldGrey'
                            fullWidth
                          />
                          {errors[index] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px' }}>{errors[index]?.firstName}</div>}
                        </Box>
                      </Grid>
                      <Grid item sm={5} xs={9} >
                        <TextField
                          name='lastName'
                          value={guest.lastName}
                          placeholder='Last Name '
                          onChange={(e) => handleGuestInputChange(e, index, e.target.name)}
                          className='iGor_TextFieldGrey'
                          fullWidth
                        />
                        {errors[index] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px' }}>{errors[index]?.lastName}</div>}
                      </Grid>
                    </Grid>
                  </>)
                })} */}

                {/* <TextField
                  placeholder=' Guest name (Make sure your name match with your Passport, ID or Driver License)'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={userIconOutline} alt='userIconOutline' />
                      </InputAdornment>
                    ),
                  }}
                  className='iGor_TextFieldGrey'
                  fullWidth
                />
                <Typography fontSize={'6px'} margin={'1rem '}> This information will appear on the hotel and our system</Typography> */}
                {/* <Box className='iGor_setRelative'> */}
                {/* {anotherPerCard && guestsDetails.map((guest: any, index: any) => {
                  return (<>
                    <Typography variant='body2' marginY={1}>{guest.type == "Adult" ? "Adult" : 'Child'}{` ${index + 1}`}</Typography>
                    <Grid container spacing={1} className='iGor__orderField'>
                      <Grid item sm={2} xs={3}>
                        <Box className='iGor_setRelative'>
                          <FormControl fullWidth>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              className='iGor_selectOrder'
                              name='title'
                              value={guest.title}                              // value={"Mr"}
                              // label="Age"
                              onChange={(e) => handleGuestInputChange(e, index, e.target.name)}
                            >
                              <MenuItem value={"Mr"}> Mr</MenuItem>
                              <MenuItem value={"Mrs"}> Mrs</MenuItem>
                            </Select>
                          </FormControl>
                          {errors[index] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px' }}>{errors[index]?.title}</div>}
                        </Box>
                      </Grid>
                      <Grid item sm={5} xs={9}>
                        <Box className='iGor_setRelative'>
                          <TextField
                            name='firstName'
                            value={guest.firstName}
                            placeholder=' First Name '
                            onChange={(e) => handleGuestInputChange(e, index, e.target.name)}
                            className='iGor_TextFieldGrey'
                            fullWidth
                          />
                          {errors[index] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px' }}>{errors[index]?.lastName}</div>}
                
                        </Box>
                      </Grid>
                      <Grid item sm={5} xs={9}>
                        <Box className='iGor_setRelative'>
                          <TextField
                            name='lastName'
                            value={guest.lastName}
                            placeholder='Last Name '
                            onChange={(e) => handleGuestInputChange(e, index, e.target.name)}
                            
                            className='iGor_TextFieldGrey'
                            fullWidth
                          />
                          {errors[index] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px' }}>{errors[index]?.lastName}</div>}
                          
                        </Box>
                      </Grid>
                    </Grid>
                  </>)
                })} */}
                {anotherPerCard && roomsCounter?.map((roomsCount: any, roomIndex: number) => {
                  return (<>
                    <Box className='iGor_orderGuestTitles'>
                      <Typography variant='body2' className='iGor_titles iGor_mainTitle'> Room {roomsCount.room + 1}</Typography>
                      {/* <Typography>{roomsCount?.adultCount} Adults , {roomsCount.childCount}Child </Typography> */}
                    </Box>
                    <Box>{roomsCount?.adultGuests?.map((adultDetails: any, adultIndex: number) => {

                      return (<>
                        <Typography variant='body2' marginY={1}> {adultDetails.type}{` ${adultIndex + 1}`} </Typography>
                        <Grid container spacing={1} className='iGor__orderField'>
                          <Grid item sm={2} xs={3} >
                            <Box className='iGor_setRelative'>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  className='iGor_selectOrder'
                                  name='title'
                                  value={guestsDetails[roomIndex]?.adultGuests[adultIndex].title ? guestsDetails[roomIndex].adultGuests[adultIndex].title : "Mr" }
                                  // value={guestDetails.title}
                                  onChange={(e) => handleGuestInputChange(e, roomIndex, adultIndex, 'adult')}
                                >
                                  <MenuItem value={"Mr"}> Mr</MenuItem>
                                  <MenuItem value={"Ms"}> Ms</MenuItem>
                                  <MenuItem value={"Mrs"}> Mrs</MenuItem>
                                  <MenuItem value={"Miss"}> Miss</MenuItem>
                                  <MenuItem value={"Dr"}> Dr</MenuItem>
                                </Select>

                              </FormControl>
                              {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.adultGuests[adultIndex].title}</div>}
                              {/* <Box className='iGor_OrderSumDelBtn'>
                               <img src={Icon_delete} alt='Icon_delete' />
                              </Box> */}
                            </Box>
                          </Grid>
                          <Grid item sm={5} xs={9} >
                            <Box className='iGor_setRelative'>
                              <TextField
                                name='firstName'
                                value={guestsDetails[roomIndex]?.adultGuests[adultIndex].firstName}
                                placeholder=' First Name '
                                onChange={(e) => handleGuestInputChange(e, roomIndex, adultIndex, 'adult')}
                                // disabled={setUserLoggedIn && index == 0? true : false}
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       <img src={userIconOutline} alt='userIconOutline' />
                                //     </InputAdornment>
                                //   ),
                                // }}
                                className='iGor_TextFieldGrey'
                                fullWidth
                              />
                              {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.adultGuests[adultIndex].firstName}</div>}
                              {/* <Box className='iGor_OrderSumDelBtn'>
                        <img src={Icon_delete} alt='Icon_delete' />
                      </Box> */}
                            </Box>
                          </Grid>
                          <Grid item sm={5} xs={9} >
                            <TextField
                              name='lastName'
                              value={guestsDetails[roomIndex]?.adultGuests[adultIndex].lastName}
                              placeholder='Last Name '
                              // disabled={setUserLoggedIn && index == 0? true : false}
                              onChange={(e) => handleGuestInputChange(e, roomIndex, adultIndex, 'adult')}

                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       <img src={userIconOutline} alt='userIconOutline' />
                              //     </InputAdornment>
                              //   ),
                              // }}
                              className='iGor_TextFieldGrey'
                              fullWidth
                            />
                            {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.adultGuests[adultIndex].lastName}</div>}

                          </Grid>
                        </Grid>                      </>)
                    })}</Box>
                    <Box>{roomsCount?.childGuests?.map((childDetails: any, childIndex: number) => {
                      return (<>
                        <Typography variant='body2' marginY={1}> {childDetails.type == "Adult" ? "Adult" : 'Child'}{` ${childIndex + 1}`} </Typography>
                        <Grid container spacing={1} className='iGor__orderField'>
                          <Grid item sm={2} xs={3} >
                            <Box className='iGor_setRelative'>
                              <FormControl fullWidth>
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  className='iGor_selectOrder'
                                  name='title'
                                  value={guestsDetails[roomIndex].childGuests[childIndex].title ? guestsDetails[roomIndex].childGuests[childIndex].title :"Mr" }
                                  onChange={(e) => handleGuestInputChange(e, roomIndex, childIndex, 'child')}
                                >
                                  <MenuItem value={"Mr"}> Mr</MenuItem>
                                  <MenuItem value={"Ms"}> Ms</MenuItem>
                                  <MenuItem value={"Child"}> Child</MenuItem>
                                </Select>

                              </FormControl>
                              {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.childGuests[childIndex].title}</div>}

                              {/* <Box className='iGor_OrderSumDelBtn'>
                              <img src={Icon_delete} alt='Icon_delete' />
                              </Box> */}
                            </Box>
                          </Grid>
                          <Grid item sm={5} xs={9} >
                            <Box className='iGor_setRelative'>
                              <TextField
                                name='firstName'
                                value={guestsDetails[roomIndex].childGuests[childIndex].firstName}
                                placeholder=' First Name '
                                onChange={(e) => handleGuestInputChange(e, roomIndex, childIndex, 'child')}
                                // disabled={setUserLoggedIn && index == 0? true : false}
                                // InputProps={{
                                //   startAdornment: (
                                //     <InputAdornment position="start">
                                //       <img src={userIconOutline} alt='userIconOutline' />
                                //     </InputAdornment>
                                //   ),
                                // }}
                                className='iGor_TextFieldGrey'
                                fullWidth
                              />
                              {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.childGuests[childIndex].firstName}</div>}

                              {/* <Box className='iGor_OrderSumDelBtn'>
                              <img src={Icon_delete} alt='Icon_delete' />
                              </Box> */}
                            </Box>
                          </Grid>
                          <Grid item sm={5} xs={9} >
                            <TextField
                              name='lastName'
                              value={guestsDetails[roomIndex].childGuests[childIndex].lastName}
                              placeholder='Last Name '
                              // disabled={setUserLoggedIn && index == 0? true : false}
                              onChange={(e) => handleGuestInputChange(e, roomIndex, childIndex, 'child')}

                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       <img src={userIconOutline} alt='userIconOutline' />
                              //     </InputAdornment>
                              //   ),
                              // }}
                              className='iGor_TextFieldGrey'
                              fullWidth
                            />
                            {errors[roomIndex] && <div style={{ color: 'red', fontSize: '10px', marginLeft: '15px', marginTop: '3px' }}>{errors[roomIndex]?.childGuests[childIndex].lastName}</div>}
                          </Grid>
                        </Grid>
                      </>)
                    })}</Box>
                  </>)
                })}

                {/* <Box className='iGor_OrderSumDelBtn'>
                    <img src={Icon_delete} alt='Icon_delete' />
                  </Box>
                </Box> */}
                {/* <Grid container spacing={1}>
                  <Grid item sm={6}>

                    <Typography variant='body2' marginY={1}> Children</Typography>
                    <TextField
                      placeholder=' Guest name '
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={userIconOutline} alt='userIconOutline' />
                          </InputAdornment>
                        ),
                      }}
                      className='iGor_TextFieldGrey'
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} paddingTop={4} className='iGor_setRelative'>

                    <TextField
                      placeholder='Age'
                      className='iGor_TextFieldGrey iGor_OrderAge'
                      fullWidth
                    />
                    <Box className='iGor_OrderSumDelBtn'>
                      <img src={Icon_delete} alt='Icon_delete' />
                    </Box>
                  </Grid>

                  <Grid item sm={6}>

                    <Typography variant='body2' marginY={1}> Infant</Typography>
                    <TextField
                      placeholder=' Guest name '
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={userIconOutline} alt='userIconOutline' />
                          </InputAdornment>
                        ),
                      }}
                      className='iGor_TextFieldGrey'
                      fullWidth
                    />
                  </Grid>
                  <Grid item sm={6} paddingTop={4} className='iGor_setRelative'>

                    <TextField
                      placeholder='Age'
                      className='iGor_TextFieldGrey iGor_OrderAge'
                      fullWidth
                    />
                    <Box className='iGor_OrderSumDelBtn'>
                      <img src={Icon_delete} alt='Icon_delete' />
                    </Box>
                  </Grid>


                </Grid> */}
              </Box>
            }
          </Box>}
      </Box>
      <ToastContainer />
    </>
  )
}
