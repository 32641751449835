import React, { useState, useEffect } from 'react';
import { Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useNavigate } from "react-router";
import IgoRoomLogo from '../../assets/icons/iGoRoom_icons.svg';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { LoginAccount } from '../userDetailsFrom/LoginAccount';
import { useDispatch } from 'react-redux';
import { setHotelSearchRequestReset, setResetRoomArray, setAuthenticationType, setEmailAuthenticationUserData, setEnterPassword, setGetUserDetailsReset, setSocialMediaAuthenticationUserData, setIsLogedInUser, setIsRegisterPopUp, setIsVerificationPopUp, setLoginUserEmailAddress, setRegisterUserEmailAddress, setRegisterUserPassword, setRegisteredUserDetailsReset, setUserLoginPassword, setMainPopupForRegisterUser, setExistingUserEmailStatusReset, fetchFaviourateFolderList, fetchUserTripsApi, setHotelDetails, setHotelRoomDetails, setGetHotelsToExploreOffersReset, fetchHotelsToExploreOffersApi, setUserNotificationsAndMessgReset, setLoggedInUserData, setPreviousPathName, setDestinationsReset, setRemoveFromFaviouratesReset, setUserWishlistDataReset, setAddTofavouriteListReset, setUserFaviouratesListReset, setAllowMoeNotification, setShowFaviourateList, setFolderSelectedDetails, setSearchDestinationFromDate, setSearchDestinationToDate, setMediaNewsArticlesDataReset, setBookedOfferListDataReset, setBookedOfferDetailsReset, getBookedOffersList } from '../../appStore/hotelSlice';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useAppSelector } from "../../appStore/hooks";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { signOut } from 'firebase/auth';
import { auth } from '../../utils/config/firebaseAuth';
import userOutline from '../../assets/icons/userOutline.svg';
import ipont_name from '../../assets/icons/ipont_name.svg';
import Igift_name from '../../assets/icons/Igift_name.svg';
import ticketIcon from '../../assets/icons/ticketIcon.svg';
import notification from '../../assets/icons/notification_icon.svg';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useTranslation } from "react-i18next";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { accountOptionSectionType, countryResidenceAndCulture } from '../../utils/constants/constant';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { getNotificationsPayload } from '../../utils/constants/types';
import { useGetUserNotificatiobsMutation } from '../../services/api/hotelApi';
import { getThreeMonthsLaterDate } from '../../utils/utilities/DateConverter';

const MobileMenu = ({ loggedinUserDetails }: any) => {
  const { t } = useTranslation();
  const language = useAppSelector(state => state.hotel.appLanguage);
  const { setUserLoggedIn, getUserDetails, verificationPopup, loggedInUserCurrency } = useAppSelector(state => state.hotel);
  const firstNameUser = useAppSelector(state => state.hotel.getUserDetails.data?.userDetail?.firstName);
  const lastNameUser = useAppSelector(state => state.hotel.getUserDetails.data?.userDetail?.LastName);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userProfile, setUserProfile] = useState(false);
  const accountSectionType = accountOptionSectionType
  const loggedInUserId = loggedinUserDetails?.userId ? loggedinUserDetails?.userId : 0;
  const culture = countryResidenceAndCulture.culture;
  const pathname = window.location.pathname;
  // get user notigification and messages api initialization
  const [getUserNotification] = useGetUserNotificatiobsMutation();
  const todaysDate = new Date();
  const tommorowsDate = new Date((new Date()).setDate((new Date()).getDate() + 1));
  const destinationSearchStartDate = getThreeMonthsLaterDate(todaysDate);
  const destinationSearchEndDate = getThreeMonthsLaterDate(tommorowsDate);

  useEffect(() => {
    if (setUserLoggedIn == true && firstNameUser != undefined && lastNameUser != undefined)
      setUserProfile(true)
  }, [setUserLoggedIn, getUserDetails.data, firstNameUser, lastNameUser])

  const home = () => {
    setDrawerOpen(false)
    navigate('/')
    dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
    dispatch(setResetRoomArray())
  };

  const inboxMobileClickHandler = () => {
    const payload: getNotificationsPayload = {
      userDetails: {
        userId: `${loggedInUserId}`,
        date: "",
        culture: culture
      }
    }
    getUserNotification({ payload })
  }

  // navigation handler
  const navigationHandler = (sectionType: string) => {
    const fromDateDispatch = destinationSearchStartDate.toISOString();
    const toDateDispatch = destinationSearchEndDate.toISOString();

    switch (sectionType) {
      case accountSectionType.accountDetails:
        navigate('/account-details');
        break;
      case accountSectionType.myTrip:
        dispatch(setBookedOfferListDataReset({ data: [], loading: false, error: null }))
        dispatch(fetchUserTripsApi(loggedInUserId))
        dispatch(getBookedOffersList(loggedInUserId))
        dispatch(setSearchDestinationFromDate(fromDateDispatch))
        dispatch(setSearchDestinationToDate(toDateDispatch))
        navigate('/my-trips')
        break;
      case accountSectionType.myFavourite:
        dispatch(setShowFaviourateList(false))
        //need to remove this after 2-3 days
        dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
        dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
        dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
        dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
        dispatch(fetchFaviourateFolderList(loggedInUserId))
        dispatch(setSearchDestinationFromDate(fromDateDispatch))
        dispatch(setSearchDestinationToDate(toDateDispatch))
        navigate('/favourites')
        break;
      case accountSectionType.notificationPermission:
        navigate('/account-details')
        break;
      case accountSectionType.inbox:
        inboxMobileClickHandler()
        navigate('/inbox')
        break;
      case accountSectionType.latestOffers:
        dispatch(setHotelDetails({ data: [], loading: false, error: null }))
        dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
        dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
        dispatch(fetchHotelsToExploreOffersApi(''))
        dispatch(setSearchDestinationFromDate(fromDateDispatch))
        dispatch(setSearchDestinationToDate(toDateDispatch))
        navigate('/latest-offers')
        break;
      case accountSectionType.exploreDestinations:
        dispatch(setSearchDestinationFromDate(fromDateDispatch))
        dispatch(setSearchDestinationToDate(toDateDispatch))
        navigate('/explore')
        break;
      default: return 'No Section Matches'
    }
  }
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: any) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(setPreviousPathName(''))
    setDrawerOpen(open);
  };


  //   const navigationHandler = (sectionType: string) => {
  //     switch (sectionType) {
  //       case accountSectionType.latestOffers:
  //         navigate('/LatestOffers')
  //         break;
  //       case accountSectionType.exploreDestinations:
  //         navigate('/explore')
  //         break;
  //       case accountSectionType.termsAndConditions:
  //         navigate('/TermsAndConditions')
  //         break;
  //       // case accountSectionType.accountDetails:
  //       //   navigate('/AccountDetails');
  //       //   break;
  //       // case accountSectionType.myTrip:
  //       //   dispatch(fetchUserTripsApi(loggedInUserId))
  //       //   navigate('/MyTrip')
  //       //   break;
  //       // case accountSectionType.myFavourite:
  //       //   if (loggedinUserDetails.userId !== 0) { dispatch(fetchFaviourateFolderList(loggedInUserId)) }
  //       //   navigate('/favourites')
  //       //   break;
  //       default: return 'No Section Matches'
  //     }
  // } 

  const userLogoutHandler = () => {

    // Sign out call for gmail user looged in
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
    });

    dispatch(setEnterPassword(false))
    dispatch(setIsLogedInUser(false))
    dispatch(setEmailAuthenticationUserData(['']))
    dispatch(setSocialMediaAuthenticationUserData(['']))
    dispatch(setLoginUserEmailAddress(''))
    dispatch(setUserLoginPassword(''))
    dispatch(setAuthenticationType(''))
    dispatch(setRegisteredUserDetailsReset({ status: '', message: '', userDetail: [''] }))
    dispatch(setGetUserDetailsReset([]))
    dispatch(setRegisterUserEmailAddress(''))
    dispatch(setRegisterUserPassword(''))
    dispatch(setIsLogedInUser(false))
    dispatch(setExistingUserEmailStatusReset({ data: [''], loading: false, error: null, }))
    dispatch(setMainPopupForRegisterUser(false))
    dispatch(setIsVerificationPopUp(false))
    dispatch(setUserNotificationsAndMessgReset([]))
    dispatch(setLoggedInUserData({}))
    dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
    dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
    dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
    dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
    dispatch(setAllowMoeNotification(null))
    setUserProfile(false)
    dispatch(setShowFaviourateList(false))
    dispatch(setFolderSelectedDetails({ folderName: '', folderId: 0 }))
    dispatch(setMediaNewsArticlesDataReset({}))
    dispatch(setBookedOfferListDataReset({ data: [], loading: false, error: null }))
    dispatch(setBookedOfferDetailsReset({ data: [], loading: false, error: null }))
    sessionStorage.clear()
    if (pathname == '/favourites' || pathname == '/account-details' || pathname == '/my-trips' || pathname == '/inbox') {
      navigate('/')
    }
  }

  // navigation function for terms and policy page
  const navigateToTermsAndPrivancy = (naivagateScreen: string) => {
    if (naivagateScreen == 'Terms') {
      navigate('/terms-conditions')
    }
    else if (naivagateScreen == 'Privacy') {
      navigate('/privacy-policy')
    } else if (naivagateScreen == 'LatestOffers') {
      navigate('/latest-offers')
    }
    else if (naivagateScreen == 'AboutUS') {
      navigate('/about-us')
    }
    else if (naivagateScreen == 'Facebook') {
      navigate('/fbdatadeletion-instruction')
    }
  }

  return (
    <Box>
      <IconButton edge="end" className="iGor_m-menuBtn" onClick={toggleDrawer(true)}>
        <MenuIcon className="iGo_m-menuIcon" />
      </IconButton>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)} className='iGo_mb-menuBox'>
        <Box className={setUserLoggedIn ? "iGo_mb-menuTop iGo_mb-UserLoggedIn" : "iGo_mb-menuTop"} >
          <img src={IgoRoomLogo} alt="IgoRoomLogo" onClick={home} />
          <ClearIcon className="iGo_m-menuClear" onClick={toggleDrawer(false)} />
        </Box>
        {setUserLoggedIn ?
          (<Box className='iGo_mb-UserMenu'>
            <Box className='iGor_mb_UserDetails'>
              <Typography variant='h4' fontSize={'18px'}> {firstNameUser} {lastNameUser}</Typography>
              <Typography color={'#FF6B6B'} fontSize={'12px'}>Verified   <CheckCircleOutlineIcon className='iGor_Verified' /></Typography>

              {/* <Box marginY={2}>
              <img src={ipont_name} alt='ipont_name' />
            </Box>
            <Box className="iGor_setFlex iGor_justifyBetween">
              <Box>
                <Typography fontSize={'12px'} fontWeight={500}> Balance: 2,300 Points</Typography>
                <Typography color={'#FF6B6B'} fontSize={'10px'}> Check your points details</Typography>
              </Box>
              <Box className="iGor_setFlex iGor_justifyBetween">
                <Box>
                  <Typography fontSize={'12px'} fontWeight={500}> Balance: 2,300 Points</Typography>
                  <Typography color={'#FF6B6B'} fontSize={'10px'}> Check your points details</Typography>
                </Box>
                <ArrowRightAltIcon className='iGor_menuArrow' />
              </Box>
              <ArrowRightAltIcon className='iGor_menuArrow' />
            </Box> */}
              <Typography variant='subtitle1' fontSize={'14px'} my={'20px'} onClick={() => navigationHandler(accountSectionType.latestOffers)}> Latest Offers</Typography>
              <Typography variant='subtitle1' fontSize={'14px'} marginBottom={'20px'} onClick={() => navigationHandler(accountSectionType.exploreDestinations)}>  Explore</Typography>
              <Typography variant='subtitle1' fontSize={'14px'}  > Personal Settings</Typography>
              <List className='igor_mobileList'>
                <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.accountDetails)}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <img src={userOutline} className='iGor_userOutline' />
                  </ListItemIcon>
                  <ListItemText primary={t('Account Details')}
                    className='iGor_UserTitle'
                    onClick={(e) => navigationHandler(accountSectionType.accountDetails)}
                  />
                </ListItemButton>
                <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.myTrip)}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <img src={ticketIcon} className='ticketIcon' />
                  </ListItemIcon>
                  <ListItemText primary={t('My Trip')} className='iGor_UserTitle'
                    onClick={(e) => navigationHandler(accountSectionType.myTrip)}
                  />
                </ListItemButton>
                <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.myFavourite)}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <FavoriteBorderIcon />
                  </ListItemIcon>
                  <ListItemText primary={t('My Favourite')} className='iGor_UserTitle'
                    onClick={(e) => navigationHandler(accountSectionType.myFavourite)}
                  />
                </ListItemButton>
                <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.inbox)}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <img src={notification} alt='notification' />
                  </ListItemIcon>
                  <ListItemText primary={t('Inbox')} className='iGor_UserTitle' />
                </ListItemButton>
                {/* <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSectionType.myOffers)}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <img src={ticketIcon} className='ticketIcon' />
                  </ListItemIcon>
                  <ListItemText primary={t('My Booked Offers')} className='iGor_UserTitle' />
                </ListItemButton> */}
              </List>
              <Typography variant='subtitle1' fontSize={'14px'}>Other Information</Typography>
              <List className='igor_mobileList'>
                <ListItemButton sx={{ paddingLeft: '0' }} onClick={(e) => navigateToTermsAndPrivancy('AboutUS')}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <MoreHorizIcon className='iGor_userOutline' />
                  </ListItemIcon>
                  <ListItemText primary={t('About US')}
                    className='iGor_UserTitle'
                  />
                </ListItemButton>
                <ListItemButton sx={{ paddingLeft: '0' }} onClick={(e) => navigateToTermsAndPrivancy('Terms')}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <MoreHorizIcon className='iGor_userOutline' />
                  </ListItemIcon>
                  <ListItemText primary={t('Terms and Conditions')}
                    className='iGor_UserTitle'
                  />
                </ListItemButton>
                <ListItemButton sx={{ paddingLeft: '0' }} onClick={(e) => navigateToTermsAndPrivancy('Privacy')}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <MoreHorizIcon className='iGor_userOutline' />
                  </ListItemIcon>
                  <ListItemText primary={t(' Privacy Policy')}
                    className='iGor_UserTitle'
                  />
                </ListItemButton>
                <ListItemButton sx={{ paddingLeft: '0' }} onClick={(e) => navigateToTermsAndPrivancy('Facebook')}>
                  <ListItemIcon className='iGor_UserIcon'>
                    <MoreHorizIcon className='iGor_userOutline' />
                  </ListItemIcon>
                  <ListItemText primary={t('Facebook Data Policy')}
                    className='iGor_UserTitle'
                  />
                </ListItemButton>
              </List>
              <Box className='iGor__UserLogoutBtn'>
                <Button variant="contained" onClick={userLogoutHandler}>Logout</Button>
              </Box>
            </Box>


          </Box>) :
          (<>

            <Box
              sx={{ width: '100vw', height: '100vh' }}
            >
              <LoginAccount />
            </Box></>)

        }

      </Drawer>
    </Box>
  );
};

export default MobileMenu;


