import React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { grey } from '@mui/material/colors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Icon_location from "../../assets/icons/Icon_location-live.svg";
import icon_user from "../../assets/icons/icon_user-check.svg";
import Icon_VR from "../../assets/icons/Icon_VR.svg";
import Icon_dealHand from "../../assets/icons/Icon_dealHand.svg";
import { Grid } from '@mui/material';


const drawerBleeding = 56;

interface Props {
    window?: () => Window;
}

const Root = styled('div')(({ theme }) => ({
    height: '100%',
    backgroundColor:
        theme.palette.mode === 'light' ? grey[100] : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
}));


const InfoFormobile = React.memo((props: any) => {
    const { window } = props;
    const [open, setOpen] = React.useState(true);

    const toggleDrawer = (newOpen: any) => () => {
        setOpen(newOpen);
        props.handlerRemove();
    };

    // This is used only for the example
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Root>
            <CssBaseline />
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: `340px`,
                        overflow: 'visible',
                    },
                }}
            />
            {/* <Box sx={{ display: 'none' }}>
                <Button onClick={toggleDrawer(true)}>Open</Button>
            </Box> */}
            <SwipeableDrawer
                container={container}
                anchor="bottom"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{ display: open ? 'block' : 'none' }}
            >
                <StyledBox
                    sx={{
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        visibility: 'visible',
                        right: 0,
                        left: 0,
                        padding: '20px',
                    }}
                >

                    <Typography fontSize={'12px'} fontWeight={600} textAlign={'center'}>
                        Travel Beyond Imagination and Experience
                    </Typography>
                    <Typography fontSize={'12px'} fontWeight={600} textAlign={'center'}>
                        a World of VR with Igoroom to select your favourite
                    </Typography>
                    <Typography fontSize={'12px'} fontWeight={600} textAlign={'center'}>
                        hotels and destinations
                    </Typography>
                    <Grid container >
                        <Grid item xs={6} padding={2} textAlign={'center'}>
                            <Box >
                                <img src={Icon_location} alt='Icon_location' />
                            </Box>
                            <Typography fontSize={'8px'} color={'#757575'} fontWeight={600}>
                                Find the nearest
                            </Typography>
                            <Typography fontSize={'8px'} color={'#757575'}>
                                hotels & resorts from<br/>
                                your location
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'} padding={2}>
                            <Box textAlign={'center'}>
                                <img src={Icon_dealHand} alt='Icon_location' width={'39px'} height={'39px'} />
                            </Box>
                            <Typography fontSize={'8px'} color={'#757575'} fontWeight={600}>
                                Be the first to know the best
                            </Typography>
                            <Typography fontSize={'8px'} color={'#757575'}>
                                deals on offer at your<br/>
                                favourite resort
                            </Typography>
                        </Grid>
                        <Grid item xs={6} padding={'28px 16px'} paddingTop={0} textAlign={'center'}>
                            <Box >
                                <img src={icon_user} alt='icon_user' width={'39px'} height={'39px'} />
                            </Box>
                            <Typography fontSize={'8px'} color={'#757575'}>
                                <Typography component='span' fontWeight={600} paddingRight={'2px'}>
                                    User friendly
                                </Typography>
                                an amplified user <br/>
                                experience for online <br/>
                                booking
                            </Typography>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'} padding={"28px 16px"} paddingTop={0}>
                            <Box textAlign={'center'}>
                                <img src={Icon_VR} alt='Icon_VR' width={'39px'} height={'39px'} />
                            </Box>
                            <Typography fontSize={'8px'} color={'#757575'}>
                                <Typography component='span' fontWeight={600} paddingRight={'2px'}>
                                    Virtual Reality
                                </Typography>
                                impressive 360 VR<br/>
                                and AR for a seamless booking<br/>
                                experience
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box>
                        <Button variant='contained' sx={{fontSize:'14px'}}>Open in application</Button>
                        <Button variant="outlined" fullWidth sx={{
                            borderRadius: '50px',
                            background: '#E5E5E5',
                            textTransform: 'capitalize',
                            color: '#000000',
                            borderColor: '#E5E5E5',
                            margin: '5px 0',
                            height: '45px',
                            fontSize:'14px',
                        }} onClick={toggleDrawer(false)}>Stay in browser</Button>

                    </Box>
                </StyledBox>
            </SwipeableDrawer>
        </Root>
    );
})

export default InfoFormobile;