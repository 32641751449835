import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from 'react-i18next';
import UpdateUserCurrency from './UpdateCurrency';
import { updateUserCurrency, userProfileUpdate } from '../../utils/constants/types';
import { useUpdateUserCurrencyMutation, useUpdateUserProfileMutation } from '../../services/api/hotelApi';
import DateOfBirthUpdateHandler from './DOBUpdate';
import { updateUserProfilePayload } from '../../services/updateUserProfileCall';
import { setLoggedInUserData } from '../../appStore/hotelSlice';

export const AcountEditModel = ({ fieldType, fieldVal, editEnableHandler, updateCurrencyHandler,
    updateUserProfileHandler, currencyEditEnable, nameEditEnable, dobEditEnable }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectUpdateCurrency, setSelectUpdateCurrency] = useState(0)
    const { getUserDetails, userDOBForUpdate } = useAppSelector(state => state.hotel)
    let currency = selectUpdateCurrency
    const userEmail = getUserDetails.data?.userDetail?.email
    const firtsNameUser = fieldVal?.userFirstName;
    const lastNameUser = fieldVal?.userLastName;
    const gatewaySelectedArray = '';
    let userBirthdateForUpdate = userDOBForUpdate;
    const [firstName, setFirstName]: any = useState(firtsNameUser)
    const [lastName, setLastName]: any = useState(lastNameUser)


    // update currency api initialization
    const [updateUserCurrency, updateCurrencyApiCallData] = useUpdateUserCurrencyMutation();
    const userCurrencyUpdatedMessage = updateCurrencyApiCallData?.data ? updateCurrencyApiCallData.data?.message : 'Not Updated'
    // update user profile api initialization
    const [updateUserProfile] = useUpdateUserProfileMutation();
   
    if (nameEditEnable == true) 
        userBirthdateForUpdate = '';

    // currency update api call handling
    const updateCurrency = () => {
        if (selectUpdateCurrency !== 0 && selectUpdateCurrency) {
            const payload: updateUserCurrency = {
                userSelectedCurrency:
                    { CurrencyId: currency },
                userEmail: userEmail
            }
            updateUserCurrency({ payload })
            setSelectUpdateCurrency(0)
        }
        updateCurrencyHandler()
    }

    // user profile update api call handling
    const updateUserProfileHandlerFunction = () => {
        const payload = updateUserProfilePayload(userBirthdateForUpdate, gatewaySelectedArray, userEmail, firstName, lastName, '')
        updateUserProfile({ payload })
        updateUserProfileHandler()
    }

    useEffect(() => {
        userBirthdateForUpdate = userDOBForUpdate
        if (userCurrencyUpdatedMessage == 'User currency updated.') {
            dispatch(setLoggedInUserData({}))
        }
    }, [nameEditEnable, currencyEditEnable, userDOBForUpdate, userCurrencyUpdatedMessage])

    return (
        <Box sx={{ display: 'flex', textAlign: 'right', justifyContent: 'flex-end' }}>

            <>
                {fieldType == 'Full Name' && nameEditEnable &&
                    <Box>
                        <TextField className='iGo__AccountTextField' id="outlined-required"
                            label="First Name" value={firstName} onChange={(e) => setFirstName(e.target.value)}></TextField>
                        <TextField id="outlined-required" className='iGo__AccountTextField'
                            label="Last Name" value={lastName} onChange={(e) => setLastName(e.target.value)}></TextField>
                    </Box>}
                {fieldType == 'Currency' && currencyEditEnable &&
                    <UpdateUserCurrency setSelectUpdateCurrency={setSelectUpdateCurrency} />
                }
                {fieldType == 'Birthday' && dobEditEnable &&
                    <><DateOfBirthUpdateHandler /></>
                }
                {/* {fieldType == 'Emergency contact' && phoneEditEnable && <Box  >
                    <TextField placeholder='Emergency contact' onChange={(e) => setFirstName(e.target.value)}></TextField>
                </Box>} */}
            </>
            {nameEditEnable == false || currencyEditEnable == false || dobEditEnable == false ?
                (<Button onClick={(e) => editEnableHandler(fieldType)} className='iGor_AccountEditBtn'>  Edit </Button>) :
                fieldType == 'Currency' ? (<Button onClick={updateCurrency} className='iGor_AccountEditBtn'>  Update </Button>) :
                    (<Button onClick={updateUserProfileHandlerFunction} className='iGor_AccountEditBtn'>  Update</Button>)
            }
        </Box>
    );
}