import React, { useState, useEffect } from 'react'
import { Box, Button, Checkbox, IconButton, LinearProgress, ListItem, ListItemButton, ListItemIcon, ListItemText, Popover, Skeleton, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NoHotelsImg from '../../assets/images/No Hotels Img.png'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import timeIcon from '../../assets/icons/timerIcon.svg';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../appStore/hooks";
import { useDispatch } from "react-redux";
import { fetchHotelDetailsFromApi, fetchHotelsRoomsDataFromApi, setAlertBoxOnError, setErrorMessageForDialogueBox, setHotelDetails, setHotelIdSelected, setHotelRoomDetails, setHotelSelectedVrImgAndData, setHotelsResultsDataReset } from "../../appStore/hotelSlice";
import { useNavigate } from 'react-router';
import 'react-toastify/dist/ReactToastify.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import mapIcon from '../../assets/icons/map.svg'
import vrViewIcon from '../../assets/icons/vrView.svg'
import { env } from '../../utils/config/appConfig';
import HotelresultSkeleton from '../Skeleton/HotelresultSkeleton';
import { useUpdateWishlistHotelsMutation } from '../../services/api/hotelApi';
import { updateUserWishlist } from '../../utils/constants/types';
import HotelWishList from './HotelWishList';
import { updateUserHotelWishlistPayload } from '../../services/updateWishlist';
import { toast } from 'react-toastify';
import { hotelTypes } from '../../utils/constants/constant';
import { useSearchParams } from 'react-router-dom';
import { dateFormatedForUrls, formatDateDateMonthYear } from '../../utils/utilities/DateConverter';
import numeral from 'numeral';
import { cacheImages } from '../../utils/utilities/ImageCaching';
import { environmentVariables } from '../../utils/config/environmentVariables';
import ImageComponent from '../../utils/utilities/ImageComp';
import NoHotelsFound from '../../assets/icons/NoHotelIcon.svg'
import OfferHotelsResult from './OfferHotelsResultsComp';
import { HotelVRDataDispatch } from './HotelVRDataDispatch';

function HotelsResultsList({ initApiLoading, tabType }: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [display, setDisplay] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [ourRecommendFilter, setourRecommenFilter] = useState('Our recommendations')
    const [sortedArrayAllHotels, setSortedArrayAllHotels]: any = useState([]);
    const { searchDestinationToDate, selectedHotelsTypeTab, loggedInUserCurrency, hotelResults, roomArray, hotelIdSelected,
        searchDestinationFromDate, destinationsData, searchHotelsResult, loadHotelResultSkeleton, offerHotelResults } = useAppSelector(state => state.hotel);
    const destinationSelectedData = destinationsData
    const searchId = useAppSelector(state => state.hotel.initApi?.searchId);
    const hotelsResultData = useAppSelector(state => state.hotel.hotelResults.data);
    const hotelDetail = useAppSelector(state => state.hotel.hotelDetails.data);
    const hotelsDataLoading = useAppSelector(state => state.hotel.hotelResults?.loading)
    let hotelsAndResorts: [any] = hotelsResultData.hotelresort ? hotelsResultData.hotelresort : [];
    let vipHotels: [any] = hotelsResultData.vip ? hotelsResultData.vip : [];
    let offerHotels: [any] = offerHotelResults?.data ? offerHotelResults.data : [];
    let hotelsAndResortForAll: [any] = hotelsResultData.hotelresort ? hotelsResultData.hotelresort : [];
    let vipHotelsForAll: [any] = hotelsResultData.vip ? hotelsResultData.vip : [];
    let offerHotelsForAll: [any] = offerHotelResults?.data ? offerHotelResults.data : [];
    const toDate = new Date(searchDestinationToDate);
    let hotelsTypeSelected = selectedHotelsTypeTab;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const matches = useMediaQuery('(max-width:1024px)');
    const [searchParams] = useSearchParams();
    const serializedGuestArray: any = searchParams.get('guestArray')
    const decodedGuestArray: any = decodeURIComponent(serializedGuestArray);
    const guestArray = JSON.parse(decodedGuestArray);
    const imagePath = environmentVariables.imagePathApp;
    const typeHotelsAndResorts = hotelTypes.hotelsAndResort
    const typeOfferHotels = hotelTypes.OfferHotels
    const typeVipHotels = hotelTypes.VipHotels
    const removeHotelFlag = false
    const [imageLoaded, setImageLoaded] = useState(false);
    const environment: any = environmentVariables.enviornment;

    // function to sort all type hotels
    function sortBySoldOut(a: any, b: any): number {
        if (a.isSoldOut === b.isSoldOut) {
            return 0;
        } else if (a.isSoldOut === false) {
            return -1;
        } else {
            return 1;
        }
    }

    // update wishlist api call initialization
    const [updateWishlist, updateWishListResponse] = useUpdateWishlistHotelsMutation();
    const updatedWishData = updateWishListResponse?.data ? updateWishListResponse.data : 'Data not found'
    const updatedWishListMessage = updatedWishData?.message ? updatedWishData?.message : 'Message not found'
    // hotels details and room details api call
    let idsForParams: any = {
        destinationName: searchParams.get('destinationName'),
        destinationId: searchParams.get('destinationId'),
        checkInDate: searchParams.get('checkIn'),
        checkOutDate: searchParams.get('checkout'),
        guestRoomSelectedArray: guestArray,
        currency: searchParams.get('currency'),
        hotelIdForparams: hotelIdSelected
    }

    const hotelDetailsCheckoutHandler = (hotelIdSelected: any) => {
        const hotelId = hotelIdSelected.id;
        const hotelSoldOut = hotelIdSelected.isSoldOut;
        const slugOffers = hotelIdSelected.name.toLowerCase().replaceAll(" ", "-");
        const slugId = hotelIdSelected.slug ? hotelIdSelected.slug : slugOffers;
        const destinationName = destinationSelectedData.destinationName.replaceAll(" ", "-")
        // const hotelVrImageForCheckout = hotelVrImage?.logoImg ? hotelVrImage?.logoImg : hotelVrImage?.pathImg;
        // dispatch(fetchHotelDetailsFromApi({ searchId: searchId, hotelId: hotelId }))
        // dispatch(fetchHotelsRoomsDataFromApi({ searchId: searchId, hotelId: hotelId }))
        // navigate(`/property-details?searchId=${idsForParams.searchIdForParams}&&hotelId=${idsForParams.hotelIdForparams}`)
        if (environment !== 'PROD' && hotelSoldOut == true) {
            dispatch(setHotelDetails({ data: [], loading: false, error: null }))
            dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
            let locationId = destinationSelectedData?.destinationCode
            const serializedGuestArray = encodeURIComponent(JSON.stringify(roomArray));
            const fromDate = dateFormatedForUrls(searchDestinationFromDate)
            const toDate = dateFormatedForUrls(searchDestinationToDate)
            dispatch(setHotelIdSelected(hotelId))
            toast('Loading..', { position: "top-center" })
            setDisplay(true)
            navigate(`/property-details/${slugId}?hotelId=${hotelId}&destinationName=${destinationName}&destinationId=${locationId}&checkIn=${fromDate}&checkout=${toDate}&currency=${loggendInUserCurrency}`)
        }
        else if (hotelSoldOut == false) {
            dispatch(setHotelDetails({ data: [], loading: false, error: null }))
            dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
            let locationId = destinationSelectedData?.destinationCode
            const serializedGuestArray = encodeURIComponent(JSON.stringify(roomArray));
            const fromDate = dateFormatedForUrls(searchDestinationFromDate)
            const toDate = dateFormatedForUrls(searchDestinationToDate)
            dispatch(setHotelIdSelected(hotelId))
            toast('Loading..', { position: "top-center" })
            setDisplay(true)
            navigate(`/property-details/${slugId}?hotelId=${hotelId}&destinationName=${destinationName}&destinationId=${locationId}&checkIn=${fromDate}&checkout=${toDate}&currency=${loggendInUserCurrency}`)
        }
    }

    // hotel image selection handler
    const hotelImageSelectionHandler = async (hotelImgSelected: object) => {
        const hotelVRDataDispatche = await dispatch(HotelVRDataDispatch(hotelImgSelected))
    }

    // add hotels to  wishlist handler
    const addToWishListHandler = async (hotelSelected: any, hotelType: string, index: number, wishlist: boolean) => {
        const hotelId = hotelSelected?.id;
        const hotelDataForAddFavourite = {
            ...hotelsResultData,
            readonly: false
        }

        // logic for adding and removing hotels from wishlist
        if (loggedInUserId == 0) {
            dispatch(setErrorMessageForDialogueBox('15'))
            dispatch(setAlertBoxOnError(true))
        }
        else if (hotelType) {
            switch (hotelType) {
                case typeHotelsAndResorts:
                    const hotelResortWishlistedArray = HotelWishList(hotelsAndResorts, index, wishlist)
                    hotelDataForAddFavourite.hotelresort = hotelResortWishlistedArray
                    dispatch(setHotelsResultsDataReset({ data: hotelDataForAddFavourite, loading: false, error: null }))
                    break;
                case typeVipHotels:
                    const vipWishlistedArray = HotelWishList(vipHotels, index, wishlist)
                    hotelDataForAddFavourite.vip = vipWishlistedArray
                    dispatch(setHotelsResultsDataReset({ data: hotelDataForAddFavourite, loading: false, error: null }))
                    break;
                case typeOfferHotels:
                    const offersWishlistedArray = HotelWishList(offerHotels, index, wishlist)
                    hotelDataForAddFavourite.offer = offersWishlistedArray
                    dispatch(setHotelsResultsDataReset({ data: hotelDataForAddFavourite, loading: false, error: null }))
                    break;
            }
            // api payload assigning and api call add hotel to favorite
            // const payload = updateUserHotelWishlistPayload(hotelId, loggedInUserId, wishlist)
            // await updateWishlist({ payload })

            // managing wishlisted hotels locally if api fails to add hotel
            if (updatedWishListMessage == 'Credentials are not valid') {
                switch (hotelType) {
                    case typeHotelsAndResorts:
                        const hotelResortWishlistedArray = HotelWishList(hotelsAndResorts, index, removeHotelFlag)
                        hotelDataForAddFavourite.hotelresort = hotelResortWishlistedArray
                        dispatch(setHotelsResultsDataReset({ data: hotelDataForAddFavourite, loading: false, error: null }))
                        break;
                    case typeVipHotels:
                        const vipWishlistedArray = HotelWishList(vipHotels, index, removeHotelFlag)
                        hotelDataForAddFavourite.vip = vipWishlistedArray
                        dispatch(setHotelsResultsDataReset({ data: hotelDataForAddFavourite, loading: false, error: null }))
                        break;
                    case typeOfferHotels:
                        const offersWishlistedArray = HotelWishList(offerHotels, index, removeHotelFlag)
                        hotelDataForAddFavourite.offer = offersWishlistedArray
                        dispatch(setHotelsResultsDataReset({ data: hotelDataForAddFavourite, loading: false, error: null }))
                        break;
                }
            }
        }
    }

    // useEffect to manage sorted arra
    useEffect(() => {
        let hotelsResultsListArray: any = [];
        if (selectedHotelsTypeTab === 'ALL') {
            hotelsResultsListArray = hotelsAndResortForAll.concat(offerHotelsForAll, vipHotelsForAll);
            let sortedArray = hotelsResultsListArray.sort(sortBySoldOut);
            setSortedArrayAllHotels(sortedArray);
        }
        else if (selectedHotelsTypeTab === 'OFFERS' && offerHotelsForAll.length > 0) {
            hotelsResultsListArray = [...offerHotelsForAll].sort(sortBySoldOut);
            setSortedArrayAllHotels(hotelsResultsListArray);
        }
        else if (selectedHotelsTypeTab === 'HOTELSRESORT' && hotelsAndResortForAll.length > 0) {
            hotelsResultsListArray = [...hotelsAndResortForAll].sort(sortBySoldOut);
            setSortedArrayAllHotels(hotelsResultsListArray);

        }
        else if (selectedHotelsTypeTab === 'VIP' && vipHotelsForAll.length > 0) {
            hotelsResultsListArray = [...vipHotelsForAll].sort(sortBySoldOut);
            setSortedArrayAllHotels(hotelsResultsListArray);
        }
        else {
            setSortedArrayAllHotels([]);
        }
    }, [selectedHotelsTypeTab, vipHotelsForAll, hotelsAndResortForAll, offerHotelsForAll]);

    const handleImageLoad = () => {
        setTimeout(() => { setImageLoaded(true) }, 1000)
    };


    // const handleClick = (event: any) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    // hotels result jsx element 
    const HotelsResultList: any = ({ hotels }: any) => {
        const hotelRating = hotels?.rating ? hotels?.rating : 0
        const imageUrl: any = cacheImages(`${imagePath}${hotels?.image?.pathImg}`)
        return (<Box className={`iGoR_roomCard ${hotels.isSoldOut == true ? 'iGor-hotel_disable' : ''}`} key={hotels?.id} onClick={() => { matches && hotelDetailsCheckoutHandler(hotels) }}>
            <Box className={`iGoR_roomCardInner`}>
                <Box className='iGor-CardLeft'>
                    {!imageLoaded && (<Skeleton variant="rectangular" height={matches ? 110 : 151} animation="wave" sx={{ borderRadius: '0.625rem' }} />)}
                    <ImageComponent
                        className={'iGor_cardImg'}
                        imageUrl={hotels?.image?.pathImg ? imageUrl.src : NoHotelsImg}
                        style={{
                            display: imageLoaded ? 'block' : 'none'
                        }}
                        handleImageLoad={handleImageLoad}
                    />
                    {/* <img src={hotels?.image?.pathImg ? imageUrl.src : NoHotelsImg} alt='No Image Available' className='iGor_cardImg'
                        onError={(e: any) => {
                            // Handle the image load error by setting the source to the default image
                            e.target.src = NoHotelsImg;
                        }}
                        style={{
                            display: imageLoaded ? 'block' : 'none'
                        }}
                        onLoad={handleImageLoad} 
                    /> */}
                    <Box className='iGor_cardBtnSection iGor_setFlex iGor_justifyBetween'>
                        <IconButton>
                            {/* <IconButton className={hotels?.wish ? ' iGor_bgWhite iGor_cardLeftBtn' : 'iGor_cardLeftBtn'} >
                                        {hotels?.wish ?
                                            (<FavoriteIcon sx={{ color: '#FF6B6B' }} onClick={(e) => addToWishListHandler(hotels, typeHotelsAndResorts, index, hotels?.wish == true ? false : true)}
                                                className='iGor_heartColour' />) :
                                            (<FavoriteBorderIcon
                                                onClick={(e) => addToWishListHandler(hotels, typeHotelsAndResorts, index, hotels?.wish == true ? false : true)}
                                                className='iGor_heartColour'
                                            />)
                                        } */}
                        </IconButton>
                        {/* {!matches && <img
                            className='iGor_vrViewIcon'
                            src={vrViewIcon}
                            onClick={(e) => hotelImageSelectionHandler(hotels)}
                        />} */}
                        {!matches && <IconButton className='iGor_cardLeftBtn iGOr_mb-D-none ' onClick={(e) => hotelImageSelectionHandler(hotels)}>
                            <RemoveRedEyeOutlinedIcon />
                        </IconButton>}
                    </Box>
                </Box>
                <Box className='iGor-CardRight' >
                    <Box className=" iGOr_Desk-D-none">
                        <Box sx={{ display: 'flex', marginBottom: '2px', marginTop: '4px' }}>
                            <img src={timeIcon} alt='timeIcon' />
                            <Typography variant='caption' className='iGor_subTitle'>  {t('Travel until')} {formatDateDateMonthYear(toDate)}</Typography>
                        </Box>
                    </Box>
                    <Typography variant="h4" className='iGor__HotelName'>{hotels?.name}</Typography>
                    <Box className='iGor-ratingSection iGOr_mb-D-none '>
                        <Typography variant='caption'>{hotels?.propertyType}</Typography>
                        <Box className='iGor-rating'>
                            {Array.from({ length: hotelRating || 0 }, (_, index) => (
                                <StarIcon key={index} className='iGor_starIcon' />
                            ))}
                            {Array.from({ length: 5 - hotelRating || 0 }, (_, index) => (
                                <StarIcon key={index} className='iGor_starIcon_not_rating' />
                            ))}
                        </Box>
                        <Box className="iGor_ratingNum">{hotelRating}</Box>
                        <Typography variant='caption' className='iGor_subTitle'>
                            {`${hotelRating <= 2 ? t('Average') : hotelRating <= 4 && hotelRating >= 2 ? t('Good') : t('Excellent')}`}
                            {/* ({hotels?.userReview ? hotels?.userReview?.count : 0} {t('reviews')}) */}
                        </Typography>
                    </Box>
                    <Box className="iGor_Travel iGOr_mb-D-none ">
                        <img src={timeIcon} alt='timeIcon' />
                        <Typography variant='caption' className='iGor_subTitle'>  {t('Travel until')} {formatDateDateMonthYear(toDate)}</Typography>
                    </Box>
                    {matches ? (<Box sx={{ display: 'flex', alignItems: 'flex-start', marginTop: '5px' }}>
                        <img src={mapIcon} />
                        <Typography variant='caption' className='iGor_Location' lineHeight={'1'} component={'p'} sx={{
                            whiteSpace: 'nowrap', overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}>{hotels?.cityCountry ? hotels?.cityCountry : hotels?.address}</Typography>
                    </Box>) : ('')}
                    {<Box className="iGor_CardBtnSection ">
                        <Box >
                            {hotels.isSoldOut == false ? (<>
                                <Typography variant='body1' fontWeight={600} className='iGor_subTitle'>{t('Starting from')}</Typography>
                                <Box className='iGor_cardPrice'>
                                    {hotelsResultData?.searchStatus == 'Completed' ?
                                        hotels?.listPrice?.roundOffPerNightCharges ? (<>
                                            <Typography variant='h4' className='iGor_price'>{`${hotels?.listPrice?.currencySymbol}${hotels?.listPrice?.roundOffPerNightCharges ? numeral(hotels?.listPrice?.roundOffPerNightCharges).format('0,0') : ''}`} </Typography>
                                            <Typography variant='body1' className='iGor_subTitle'> /{t('night')}</Typography>
                                        </>) : (<>
                                            <Typography variant='h4' className='iGor_price'>{`${hotels?.listPrice?.currencySymbol}${hotels?.listPrice?.perNightCharges !== undefined ? numeral(hotels?.listPrice?.perNightCharges).format('0,0') : ''}`} </Typography>
                                            <Typography variant='body1' className='iGor_subTitle'> /{t('night')}</Typography>
                                        </>) :
                                        (<LinearProgress style={{ width: '45px', height: '7px' }} color="inherit" />)}
                                </Box>
                            </>) : (<>
                                <Box className='iGor_cardPrice'>
                                    <Typography variant='h4' className='iGor_price'>{t('SOLD OUT')}</Typography>
                                </Box>
                            </>)}
                        </Box>
                        <Button variant='contained' disabled={environment !== 'PROD' ? false : hotels.isSoldOut} className={`iGor_cardBtn iGOr_mb-D-none ${environment == 'PROD' && hotels.isSoldOut == true ? 'iGOrBtn__disable' : ''} `} onClick={() => hotelDetailsCheckoutHandler(hotels)}>
                            Check Availability
                        </Button>
                    </Box>
                    }
                    {/* {props.show && <>
                                    <Box className="iGor_setFlex iGor_villasCard iGor_justifyBetween iGor_alignCenter">
                                        <Box width={'65%'}>
                                            <Typography variant='body1' className='iGor_villasTitle'>
                                                3D2N The Private Reserve
                                                All-Inclusive
                                            </Typography>
                                        </Box>
                                        <Box className="iGor_setFlex">
                                            <Typography variant='body1' className='iGor_villasTitle'>INR 35,382 <span>/{hotels?.propertyType}</span></Typography>
                                            <EastIcon className='iGor_ArrowRight' />
                                        </Box>
                                    </Box>
                                    <Box className="iGor_setFlex iGor_villasCard iGor_justifyBetween iGor_alignCenter">
                                        <Box width={'65%'}>
                                            <Typography variant='body1' className='iGor_villasTitle'>
                                                4D3N Beach Villas Include
                                                Romantic Dinner
                                            </Typography>
                                        </Box>
                                        <Box className="iGor_setFlex">
                                            <Typography variant='body1' className='iGor_villasTitle'> INR 44,822 <span>/{hotels?.propertyType}</span></Typography>
                                            <EastIcon className='iGor_ArrowRight' />
                                        </Box>
                                    </Box>
                                </>} */}
                </Box>
            </Box>
            {/* {props.show &&
                            <Box className="iGor_setFlex iGor_justifyBetween iGor_BookNowSection">
                                <Box>
                                    <Typography variant='caption' >{t('Todays Rate')}</Typography>
                                    <Typography variant='body1' color={'#C4C4C4'}>   {t('Offer ends in')}:</Typography>
                                    <Typography variant='body1' color={'#FF6B6B'}> 00 DAYS : 21 HOURS : 21 MINUTES</Typography>
                                </Box>
                                <Box>
                                    <Typography variant='caption' color={'#c4c4c4'}> {t('start from')}</Typography>
                                    <Typography variant='subtitle1' className='iGor_BookPrice'>INR {hotels?.listPrice?.minPrice ? hotels?.listPrice?.minPrice?.toFixed() : ''}<span>/ {t('night')}</span></Typography>
                                </Box>
                                <Box>
                                    <Button variant='contained' className='iGor_BookNowBtn' onClick={() => hotelDetailsCheckoutHandler(hotels?.id)}>Book now</Button>
                                </Box>
                            </Box>} */}
        </Box >
        )
    }

    // our recommendation filters component
    const OurRecommendationComponent = () => {
        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const recommendationsFilterArray = ['Relevance', 'Lowest to Highest', 'Highest to Lowest']
        const handleClick = (event: any) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        return (<>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0 10px 0', alignItems: 'center' }} className='iGOr_mb-D-none'>
                <Box> <Typography fontSize={'14px'} fontWeight={400} color={'#757575'}>{t('Sort by')}</Typography></Box>
                <Box >
                    <IconButton className='iGor_OurRecBtn' onClick={handleClick} >
                        {t(ourRecommendFilter)}   <ExpandMoreIcon />
                    </IconButton>
                    <Popover
                        //  id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <ListItem component="nav" className='iGor_listPop'  >
                            {
                                recommendationsFilterArray.map((recommendation: any) => {
                                    return (
                                        <>
                                            <ListItemButton>
                                                {/* <ListItemIcon
                                                    key={recommendation}
                                                // secondaryAction={

                                                // }
                                                // disablePadding
                                                >
                                                    <Checkbox
                                                        key={recommendation}
                                                        edge="start"
                                                        className='iGor_FiltercheckBox'
                                                        // onChange={(e) => propertyTypeSelectionHandler(propertyType?.category, propertyType?.id)}
                                                        // checked={setPropertyTypeSelectedFilters?.indexOf(propertyType?.id) !== -1}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </ListItemIcon>

                                                <ListItemText className='iGor_FiltercheckText' id={labelId} primary={recommendation} />
                                                 */}
                                                <Typography variant='subtitle2'
                                                    onClick={(e) => sortHotelsByRecommendations(recommendation)}
                                                >
                                                    {recommendation}
                                                </Typography>
                                            </ListItemButton>

                                        </>)
                                })
                            }
                        </ListItem>
                    </Popover>
                </Box>
            </Box>
        </>)
    }

    // hotel sorting on basis of our recommendation selection
    const sortHotelsByRecommendations = (type: string) => {
        handleClose()
        let sortedArrayForHotels: any = [];
        setourRecommenFilter(type)
        if (type == 'Lowest to Highest') {
            sortedArrayForHotels = sortedArrayAllHotels.sort((a: any, b: any) => {
                if (a.isSoldOut && !b.isSoldOut) return 1;
                if (!a.isSoldOut && b.isSoldOut) return -1;
                return a.listPrice?.perNightCharges - b.listPrice?.perNightCharges;
            });
            setSortedArrayAllHotels(sortedArrayForHotels);
        } else if (type == 'Highest to Lowest') {
            sortedArrayForHotels = sortedArrayAllHotels.sort((a: any, b: any) => {
                if (a.isSoldOut && !b.isSoldOut) return 1;
                if (!a.isSoldOut && b.isSoldOut) return -1;
                return b.listPrice?.perNightCharges - a.listPrice?.perNightCharges;
            });
            setSortedArrayAllHotels(sortedArrayForHotels);
        } else if (type == 'Relevance') {
            sortedArrayForHotels = hotelsAndResortForAll.concat(offerHotelsForAll, vipHotelsForAll);
            let sortedArray = sortedArrayForHotels.sort(sortBySoldOut);
            setSortedArrayAllHotels(sortedArray);
        }
    }

    // hotels selected vr image show logic
    useEffect(() => {
        let arrayForHotelsToSort: any = [];
        if (selectedHotelsTypeTab) {
            hotelsTypeSelected = selectedHotelsTypeTab;
        }
        if (hotelDetail.success == true && display == true) {
            setDisplay(false)
        }
        if (hotelDetail.success == false && display == true) {
            dispatch(setErrorMessageForDialogueBox('35'))
            dispatch(setAlertBoxOnError(true))
        }

        if (hotelsAndResorts.length <= 0 || offerHotels.length <= 0 || vipHotels.length <= 0) {
            dispatch(setHotelSelectedVrImgAndData({
                hotelName: '', hotelVrImg: '', hotelId: '', slugId: '', isSoldOut: '', hotelLocation: ''
            }))
        }
        // logic for default vr image set
        if (hotelsAndResorts?.length > 0 || offerHotels.length > 0 || vipHotels.length > 0 || sortedArrayAllHotels.length > 0) {
            if (sortedArrayAllHotels && sortedArrayAllHotels.length > 0 && selectedHotelsTypeTab == 'ALL') {
                dispatch(setHotelSelectedVrImgAndData({
                    hotelName: sortedArrayAllHotels[0]?.name,
                    hotelVrImg: sortedArrayAllHotels[0]?.image?.vrImg ? sortedArrayAllHotels[0]?.image?.vrImg : NoHotelsImg,
                    hotelId: sortedArrayAllHotels[0]?.id,
                    slugId: sortedArrayAllHotels[0]?.slug,
                    isSoldOut: sortedArrayAllHotels[0]?.isSoldOut,
                    hotelLocation: sortedArrayAllHotels[0]?.cityCountry
                }))
            }
            else if (hotelsAndResorts.length > 0 && selectedHotelsTypeTab == 'HOTELSRESORT') {
                dispatch(setHotelSelectedVrImgAndData({
                    hotelName: hotelsAndResorts[0]?.name,
                    hotelVrImg: hotelsAndResorts[0]?.image?.vrImg,
                    hotelId: hotelsAndResorts[0]?.id,
                    slugId: hotelsAndResorts[0]?.slug,
                    isSoldOut: hotelsAndResorts[0]?.isSoldOut,
                    hotelLocation: hotelsAndResorts[0]?.cityCountry
                }))
            }
            else if (vipHotels.length > 0 && selectedHotelsTypeTab == 'VIP') {
                dispatch(setHotelSelectedVrImgAndData({
                    hotelName: vipHotels[0]?.name,
                    hotelVrImg: vipHotels[0]?.image?.vrImg,
                    hotelId: vipHotels[0]?.id,
                    slugId: vipHotels[0]?.slug,
                    isSoldOut: vipHotels[0]?.isSoldOut,
                    hotelLocation: vipHotels[0]?.cityCountry
                }))
            }
            else if (offerHotels.length > 0 && selectedHotelsTypeTab == 'OFFERS') {
                dispatch(setHotelSelectedVrImgAndData({
                    hotelName: offerHotels[0]?.name,
                    hotelVrImg: offerHotels[0]?.image?.vrImg,
                    hotelId: offerHotels[0]?.id,
                    slugId: offerHotels[0]?.slug,
                    isSoldOut: offerHotels[0]?.isSoldOut,
                    hotelLocation: offerHotels[0]?.address
                }))
            }
        }
    }, [hotelDetail, display, selectedHotelsTypeTab, hotelsAndResorts.length, offerHotels.length, vipHotels.length
        , sortedArrayAllHotels.length, sortedArrayAllHotels])

    return (<>
        {loadHotelResultSkeleton == true ? (<Box className='iGor-resultSection'>
            <HotelresultSkeleton />
            <HotelresultSkeleton />
            <HotelresultSkeleton />
            <HotelresultSkeleton />
        </Box>) :
            hotelsAndResorts.length > 0 || vipHotels.length > 0 || offerHotels.length > 0 ?
                hotelsTypeSelected == 'ALL' ?
                    (<>
                        <OurRecommendationComponent />
                        <Box className='iGor-resultSection'>
                            {/* array map for all type hotels */}
                            {sortedArrayAllHotels.map((hotels: any) =>
                                <>
                                    <HotelsResultList hotels={hotels} />
                                </>
                            )}
                        </Box>
                    </>) :

                    // hotels results for other types

                    hotelsTypeSelected == 'HOTELSRESORT' && hotelsAndResorts.length <= 0 ?
                        <Box className='iGor-No_Result_messages'>
                            <Box className='iGor-No_Result_image'>
                                <img src={NoHotelsFound}></img>
                                <Typography>{t('5001')}</Typography>
                            </Box>
                        </Box>
                        : hotelsTypeSelected == 'OFFERS' && offerHotels.length <= 0 ?
                            <Box className='iGor-No_Result_messages'>
                                <Box className='iGor-No_Result_image'>
                                    <img src={NoHotelsFound}></img>
                                    <Typography>{t('5002')}</Typography>
                                </Box>
                            </Box>
                            : hotelsTypeSelected == 'VIP' && vipHotels.length <= 0 ?
                                <Box className='iGor-No_Result_messages'>
                                    <Box className='iGor-No_Result_image'>
                                        <img src={NoHotelsFound}></img>
                                        <Typography>{t('5003')}</Typography>
                                    </Box>
                                </Box>
                                :
                                (<>
                                    <OurRecommendationComponent />
                                    <Box className='iGor-resultSection'>
                                        {hotelsTypeSelected == 'HOTELSRESORT' && hotelsAndResorts.length > 0 ?

                                            // hotels results sorting as per hotels and reosrts  
                                            sortedArrayAllHotels.map((hotels: any) =>
                                                <HotelsResultList hotels={hotels} />
                                            ) :
                                            hotelsTypeSelected == 'OFFERS' && offerHotels.length > 0 ?

                                                // hotels results sorting as per offers hotels
                                                sortedArrayAllHotels.map((hotels: any) =>
                                                    <HotelsResultList hotels={hotels} />
                                                ) :
                                                hotelsTypeSelected == 'VIP' && vipHotels.length > 0 &&

                                                // hotels results sorting as per vip hotels
                                                sortedArrayAllHotels.map((hotels: any) =>
                                                    <HotelsResultList hotels={hotels} />
                                                )
                                        }
                                    </Box>
                                </>)
                : (<Box className='iGor-No_Result_messages'>
                    <Box className='iGor-No_Result_image'>
                        <img src={NoHotelsFound}></img>
                        <Typography>{t('5017')}</Typography>
                    </Box>
                </Box>)
        }</>);
}

export default HotelsResultsList;
