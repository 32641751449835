import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react'
import Header from '../../layouts/Header';
import { useNavigate } from 'react-router';
import { Box, Button, Container, Grid, IconButton, Popover, Typography, MenuList, Skeleton, SwipeableDrawer, List, ListItem, ListItemText } from "@mui/material";
import locationIcon from '../../assets/icons/location-pin.svg';
import icons_calendar from '../../assets/icons/icons_calendar.svg';
import { CustomTabs } from '../../components/inputFieldCustom/CustomTabs';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import GuestsIcon from '../../assets/icons/GuestsIcon.svg';
import { useInitApiMutation } from '../../services/api/hotelApi';
import AdvancedFilterMod from '../../components/popUpSection/AdvancedFilterMod';
import { useAppSelector } from '../../appStore/hooks';
import { fetchGetFiltersDataFromApi, fetchHotesResultsDataFromApi, fetchOfferHotelsResult, setAppliedPriceRangeFiltersForHotels, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels, setDestinationsReset, setHotelSearchRequestReset, setHotelsResultsDataReset, setHotelsTypeTabSelection, setLoadHotelResultSkeleton, setMobileAppliedPropertyTypeFilterForHotels, setMobileAppliedRoomTypeFilterForHotels, setMobileFiltersEnable, setNoOfHitsResultApi, setOfferHotelsDataReset, setPreviousPathName, setResetRoomArray, setRoomArray, setScreenTypeSelected, setSearchDestinationData, setSearchDestinationFromDate, setSearchDestinationToDate, setSearchHotelsResultCall, setSelectedCurrencySymbol, setWebFiltersEnable } from '../../appStore/hotelSlice';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { useDispatch } from 'react-redux';
import { AdultCount, ChildCount } from '../../utils/handlers/arrayLoop';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SearchIcon from '@mui/icons-material/Search';
import { useApplyFiltersHotelsResultMutation } from '../../services/api/hotelApi';
import { DatePickerComp } from '../../components/inputFieldCustom/DatePicker';
import initApiCall from '../../services/initApiCall';
import DestinationRoomAdditionPopOver from '../../components/DestinationsHotelsResults/DestinationRoomAdditionPopOver';
import DestinationMenuName from '../../components/DestinationsHotelsResults/DestinationMenuName';
import DestinationNightCount from '../../components/DestinationsHotelsResults/DestinationNightCount';
import HotelResultVRImgSection from '../../components/DestinationsHotelsResults/HotelResultVRImgSection';
import HotelsResultsList from '../../components/DestinationsHotelsResults/HotelsResultsList';
import Filters from '../../utils/utilities/Filters';
import ResetFilters from '../../components/DestinationsHotelsResults/ResetFilters';
import { set } from 'date-fns';
import { countryResidenceAndCulture, eventNameForMoeEvents } from '../../utils/constants/constant';
import VRandMapSectionSkeleton from '../../components/Skeleton/VRAndMapSkeleton';
import { useSearchParams } from 'react-router-dom';
import LocPin from '@mui/icons-material/Room';
import TodayIcon from '@mui/icons-material/Today';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { formatDateOnSearchResultPage, formatDateOnSearchResultPageMobile } from '../../utils/utilities/DateConverter';
import SearchModifyMobile from './SearchModifyMob';
import Icon__magnify from '../../assets/icons/Icon__magnify.svg';
import TuneIcon from '../../assets/icons/Icon_FilterMb.svg';
import FilterIcon from '../../assets/icons/FilterIcon.svg';
import { EventLogHanlderMoengage } from '../../utils/handlers/EventLogsHandler';
import { searchValuesDispatch } from '../../components/DestinationsHotelsResults/SearchValuesDispatch';
import SearchModifyBox from './SearchModifyBox';
import HotelresultSkeleton from '../../components/Skeleton/HotelresultSkeleton';

export const SearchResults = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch: any = useDispatch();
  const [show, setShow] = useState(false);
  const [stepFirst, setstepFirst] = useState(false);
  const [stepSecond, setstepSecond] = useState(true);
  const [stepNumber, setStepNumber] = useState(0);
  const [tabType, setTabType] = useState('ALL');
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [destinationShow, setDestinationShow] = useState(false);
  const [serchField, setSerchField] = useState(false)
  const [researchHotelResults, setResearchHotelResults] = useState(false);
  const { webFilterEnable, mobileFilterEnable, loggedInUserCurrency, searchDestinationFromDate, searchDestinationToDate,
    destinationsData, setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters, setMobilePropertyTypeSelectedFilters,
    setMobileRoomTypeSelectedFilters, setPriceRangeSelectedFilters, screenTypeSeleted, searchHotelsResult, NoOfHitsResultApi,
    noOfNights, loadHotelResultSkeleton } = useAppSelector(state => state.hotel)
  const searchId = useAppSelector(state => state.hotel.initApi?.searchId);
  const RoomArr = useAppSelector(state => state.hotel.roomArray)
  const [destinationObj, setDestinationObj] = useState(destinationsData);
  const [roomArrayForGuest, setRoomArrayForGuest] = useState(RoomArr);
  const [startDateForDestination, setStartDateForDestinationSearch] = useState(new Date(searchDestinationFromDate));
  const [endDateForDestination, setEndDateForDestinationSearch] = useState(new Date(searchDestinationToDate));
  const [numberOfNights, setNumberOfNights] = useState(noOfNights);
  const hotelsResultData = useAppSelector(state => state.hotel.hotelResults.data);
  const hotelsDataLoading = useAppSelector(state => state.hotel.hotelResults?.loading)
  const startDate = useAppSelector(state => state.hotel.searchDestinationFromDate)
  const endDate = useAppSelector(state => state.hotel.searchDestinationToDate)
  const destinationSelectedData = useAppSelector(state => state.hotel.destinationsData)
  const filtersForUI = useAppSelector(state => state.hotel.getUIFiltersData?.data);
  let defaultPropertyType = filtersForUI?.propertyTypes;
  let defaultRoomTypes = filtersForUI?.roomTypes;
  let defaultPriceRanges = filtersForUI?.priceRange;
  const matches = useMediaQuery('(max-width:1024px)');
  const fromDate = formatDateOnSearchResultPageMobile(startDateForDestination);
  const toDate = formatDateOnSearchResultPageMobile(endDateForDestination);
  const formatedFromDate = formatDateOnSearchResultPage(startDateForDestination, 'search')
  const formatedToDate = formatDateOnSearchResultPage(endDateForDestination, 'search')
  const countryResidenceAndCultureInit = countryResidenceAndCulture;
  const serializedGuestArray: any = searchParams.get('guestArray')
  const decodedGuestArray: any = decodeURIComponent(serializedGuestArray);
  const guestArray = JSON.parse(decodedGuestArray);
  const roomCount = roomArrayForGuest.length;
  const adultCount = AdultCount(roomArrayForGuest);
  const childCount = ChildCount(roomArrayForGuest);
  const pathname = window.location.pathname;
  const previousPathName = pathname + window.location.search;
  const eventNameSearchMoe = eventNameForMoeEvents.Search;
  const eventNameSearchResultMoe = eventNameForMoeEvents.SearchResult;
  const hotelsCount = hotelsResultData?.total;
  const numberOfHitsResultApi: number = NoOfHitsResultApi;
  let idParamsFromHome: any = {
    destinationName: searchParams.get('destinationName'),
    destinationId: searchParams.get('destinationId'),
    checkInDate: searchParams.get('checkIn'),
    checkOutDate: searchParams.get('checkout'),
    roomArray: guestArray,
    currency: searchParams.get('currency')
  }
  let hotelsAndResorts: [any] = hotelsResultData?.hotelresort ? hotelsResultData?.hotelresort : [];
  let offersHotels: [any] = hotelsResultData?.offer ? hotelsResultData?.offer : [];
  let vipHotels: [any] = hotelsResultData?.vip ? hotelsResultData?.vip : [];

  // assign the price for updated price and default prices
  let defaultMinPrice = defaultPriceRanges?.min_price;
  let defaultMaxPrice = defaultPriceRanges?.max_price;
  let setPriceRangesSelected = setPriceRangeSelectedFilters;
  let updatedMinPrice: number = setPriceRangesSelected?.min_price;
  let updatedMaxPrice: number = setPriceRangesSelected?.max_price;

  const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
  const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
  const countryOfResidence = loggedInUserCurrency?.countryOfResidenceCode ? loggedInUserCurrency?.countryOfResidenceCode : 'MY';
  const nationality = loggedInUserCurrency?.nationality ? loggedInUserCurrency?.nationality : 'MY';

  const handleClick = () => {
    setShow(!show);
  };

  const handleStepNumUpdate = (index: any, operation: any) => {
    if (operation === 'increment') {
      setStepNumber((stepNumber) => stepNumber + 1);
    } else if (operation === 'decrement') {
      if (stepNumber === 0) {
        setDestinationShow(!destinationShow);
        setSerchField(!serchField);
        setStepNumber(0);
      } else if (stepNumber > 0) {
        setStepNumber((stepNumber) => stepNumber - 1);
      } else {
        // Handle special case when stepNumber is -1
        // For example, setDestinationShow or do something else
      }
    }
  };

  const handleAnimationInput = () => {
    setOpenDrawer(false)
    setstepFirst(false)
    setSerchField(!serchField);
    setstepSecond(false)
    setStepNumber(0)
    setTimeout(() => setDestinationShow(!destinationShow), 900);
  }

  const [iniApiCall, initApiCallData] = useInitApiMutation();
  // apply filters api call and assign data to reqest object   
  // const appliedFilterSelectionHandler = () => {
  //   AppLogHandler("appliedFilterSelectionHandler", { setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters, screenTypeSeleted })
  //   // main filters applied api call
  //   if (setPropertyTypeSelectedFilters?.length > 0 || setRoomTypeSelectedFilters?.length > 0 || updatedMinPrice != defaultMinPrice || updatedMaxPrice != defaultMaxPrice && screenTypeSeleted == 'Web') {
  //     const selectedPropetyTypeFilters: any = setPropertyTypeSelectedFilters?.length > 0 ? setPropertyTypeSelectedFilters : [0]
  //     const selectedRoomTypeFilters: any = setRoomTypeSelectedFilters?.length > 0 ? setRoomTypeSelectedFilters : [0]
  //     const selectedPriceRange: any = setPriceRangesSelected;
  //     const payload = {
  //       appliedFilters:
  //       {
  //         "userid": 270,
  //         "propertytype": selectedPropetyTypeFilters,
  //         "roomtype": selectedRoomTypeFilters,
  //         "minprice": selectedPriceRange?.min_price,
  //         "maxprice": selectedPriceRange?.max_price
  //       },
  //       searchId: searchId
  //     }
  //     applyFilters({ payload })

  //   }

  //   // Mobile filters applied api call
  //   if (setMobilePropertyTypeSelectedFilters?.length > 0 || setMobileRoomTypeSelectedFilters?.length > 0 || updatedMinPrice != defaultMinPrice || updatedMaxPrice != defaultMaxPrice && screenTypeSeleted == 'Mobile') {
  //     const selectedMobilePropertypeMultipleFilters = setMobilePropertyTypeSelectedFilters?.length > 0 ? setMobilePropertyTypeSelectedFilters : [0];
  //     const selectedMobileRoomTypeMultipleFilters = setMobileRoomTypeSelectedFilters?.length > 0 ? setMobileRoomTypeSelectedFilters : [0]
  //     const selectedPriceRange: any = setPriceRangesSelected;

  //     const payload = {
  //       appliedFilters:
  //       {
  //         "userid": 270,
  //         "propertytype": selectedMobilePropertypeMultipleFilters,
  //         "roomtype": selectedMobileRoomTypeMultipleFilters,
  //         "minprice": selectedPriceRange?.min_price,
  //         "maxprice": selectedPriceRange?.max_price
  //       },
  //       searchId: searchId
  //     }
  //     applyFilters({ payload })
  //   }
  // }



  //  re-search hotels click hanlder
  const searchClickHandler = async () => {
    const searchDataDispatched = await dispatch(searchValuesDispatch(
      numberOfNights,
      roomArrayForGuest,
      startDateForDestination,
      endDateForDestination,
    ))
    dispatch(setSearchDestinationData(destinationObj));
    dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
    dispatch(setAppliedPropertyTypeFiltersForHotels([]))
    dispatch(setAppliedRoomTypeFiltersForHotels([]))
    dispatch(setMobileAppliedPropertyTypeFilterForHotels([]))
    dispatch(setMobileFiltersEnable(false))
    dispatch(setWebFiltersEnable(false))
    dispatch(setMobileAppliedRoomTypeFilterForHotels([]))
    dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: defaultMinPrice, max_price: defaultMaxPrice }))
    dispatch(setLoadHotelResultSkeleton(true))
    let locationId = destinationObj?.destinationCode
    const payload = initApiCall(loggendInUserCurrency, startDateForDestination, endDateForDestination, roomArrayForGuest,
      countryOfResidence, nationality, countryResidenceAndCultureInit.culture, locationId, loggedInUserId)
    iniApiCall({ payload })
    dispatch(setNoOfHitsResultApi(1))
    navigate(`/search-results?destinationName=${destinationObj.destinationName}&destinationId=${locationId}&checkIn=${idParamsFromHome.checkInDate}&checkout=${idParamsFromHome.checkOutDate}&currency=${loggendInUserCurrency}`)
    setResearchHotelResults(true)
    setOpenDrawer(false)
    // dispatch(setSearchHotelsResultCall(true))

    // event logs for Moengage
    const eventIdsForLogEventsMoe = {
      "Search term": destinationObj.destinationName,
      "Booking date start": new Date(formatedFromDate),
      "Booking date finish": new Date(formatedToDate),
      "Number of guest": adultCount + childCount
    }
    EventLogHanlderMoengage(eventNameSearchMoe, eventIdsForLogEventsMoe)
    dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
    dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
  }

  const fromDates = new Date().toISOString()
  const toDates = new Date((new Date()).setDate((new Date()).getDate() + 1)).toISOString()
  const navigateToHomePageMobileView = () => {
    dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }));
    dispatch(setSearchDestinationData({ destinationName: '', destinationCode: '' }))
    dispatch(setSearchDestinationFromDate(fromDates))
    dispatch(setSearchDestinationToDate(toDates))
    dispatch(setResetRoomArray())
    dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
    dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
    dispatch(setMobileAppliedPropertyTypeFilterForHotels([]));
    dispatch(setMobileAppliedRoomTypeFilterForHotels([]));
    dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: defaultMinPrice, max_price: defaultMaxPrice }));
    dispatch(setHotelsTypeTabSelection('ALL'));
    dispatch(setMobileFiltersEnable(false))
    dispatch(setDestinationsReset({ status: '', message: '', destinations: [] }))
    navigate('/')
  }

  // moengage log hanlder
  const moengageEventLogHanlder = () => {
    // event logs for Moengage
    const eventIdsForLogEventsMoe = {
      "Number of room": roomCount,
      "Number of property": hotelsCount
    }
    EventLogHanlderMoengage(eventNameSearchResultMoe, eventIdsForLogEventsMoe)
  }

  AppLogHandler("searchid hotels result", { searchId, hotelsResultData, destinationObj })
  // Reset filters handler
  const resetFiltersHandler = () => {
    if (setPropertyTypeSelectedFilters[0] != 0 || setRoomTypeSelectedFilters[0] != 0 || updatedMinPrice != defaultMinPrice || updatedMaxPrice != defaultMaxPrice) {
      dispatch(setAppliedPropertyTypeFiltersForHotels([]))
      dispatch(setAppliedRoomTypeFiltersForHotels([]))
      dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: 0, max_price: 0 }))
      dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
      dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
      dispatch(fetchHotesResultsDataFromApi({ searchId, numberOfHitsResultApi }));
      dispatch(fetchOfferHotelsResult(searchId))
      dispatch(setWebFiltersEnable(false))
    }
    dispatch(setHotelsTypeTabSelection('ALL'))
  }

  AppLogHandler("Search Result Page ", {
    "Price Selector": { "defaultPriceRanges": defaultPriceRanges, "defaultMinPrice": defaultMinPrice, "defaultMaxPrice": defaultMaxPrice, "updatedMinPrice": updatedMinPrice, "updatedMaxPrice": updatedMaxPrice }
  })

  // useEffect for sorting hotels and geting hotels list call
  useEffect(() => {
    // re-assigning data if change in filters for ui
    defaultPropertyType = filtersForUI?.propertyTypes;
    defaultRoomTypes = filtersForUI?.roomTypes;
    defaultPriceRanges = filtersForUI?.priceRange;

    // re - assign the price for updated price and default prices
    defaultMinPrice = defaultPriceRanges?.min_price;
    defaultMaxPrice = defaultPriceRanges?.max_price;
    setPriceRangesSelected = setPriceRangeSelectedFilters;
    updatedMinPrice = setPriceRangesSelected?.min_price;
    updatedMaxPrice = setPriceRangesSelected?.max_price;

    // get filters for ui after found hotels result
    if (searchId && hotelsResultData?.searchStatus == "Completed" && hotelsDataLoading == false && searchHotelsResult == true) {
      dispatch(setSearchHotelsResultCall(false))
      dispatch(setSelectedCurrencySymbol(hotelsResultData.currencySymbol))
      // dispatch(fetchGetFiltersDataFromApi(searchId))
    }
    // get hotels result call after search from result page 
    // if (searchId && !hotelsResultData?.searchStatus && researchHotelResults == true) {
    //   setResearchHotelResults(false)
    //   dispatch(fetchHotesResultsDataFromApi(searchId))
    // }

    // price range set when clear cache
    if (updatedMinPrice == undefined && updatedMaxPrice == undefined) {
      dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: defaultMinPrice, max_price: defaultMaxPrice }))
    }

    // responsive screen management
    if (matches) {
      dispatch(setScreenTypeSelected('Mobile'))
    }

    else if (!matches) {
      dispatch(setScreenTypeSelected('Web'))
    }

    if (screenTypeSeleted == "Web" && setMobilePropertyTypeSelectedFilters?.length > 0 && setMobileRoomTypeSelectedFilters?.length > 0) {
      dispatch(setMobileAppliedPropertyTypeFilterForHotels([]));
      dispatch(setMobileAppliedRoomTypeFilterForHotels([]))
      dispatch(setMobileFiltersEnable(false))
    }

    if (screenTypeSeleted == "Mobile" && setPropertyTypeSelectedFilters?.length > 0 && setRoomTypeSelectedFilters?.length > 0) {
      dispatch(setAppliedPropertyTypeFiltersForHotels([]));
      dispatch(setAppliedRoomTypeFiltersForHotels([]))
    }

    // main filters applied api function call
    if ((setPropertyTypeSelectedFilters[0] != 0 || setRoomTypeSelectedFilters[0] != 0 || (updatedMinPrice != defaultMinPrice && updatedMinPrice != undefined) || (updatedMaxPrice != defaultMaxPrice && updatedMaxPrice != undefined)) && screenTypeSeleted == 'Web' && webFilterEnable == true) {
      // (hotelsAndResorts.length > 0 || offersHotels.length > 0 || vipHotels.length > 0)
      // appliedFilterSelectionHandler()
    }

    // mobile filters applied api function call
    if ((setMobilePropertyTypeSelectedFilters?.length > 0 || setMobileRoomTypeSelectedFilters?.length > 0 || (updatedMinPrice != defaultMinPrice && updatedMinPrice != undefined) || (updatedMaxPrice != defaultMaxPrice && updatedMaxPrice != undefined)) && screenTypeSeleted == 'Mobile' && mobileFilterEnable == true) {
      // appliedFilterSelectionHandler()
    }

    // navigating to home page when cache reseted
    if (destinationObj.destinationName == '' && destinationObj.destinationCode == '' && pathname == '/search-results') {
      // navigate('/')
    }
  }, [hotelsResultData.length, searchId, filtersForUI, setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters,
    setMobilePropertyTypeSelectedFilters, setMobileRoomTypeSelectedFilters, updatedMinPrice, updatedMaxPrice,
    defaultMinPrice, defaultMaxPrice, screenTypeSeleted, matches, searchHotelsResult,
    webFilterEnable, mobileFilterEnable, researchHotelResults]);

  // init api call function
  const initApiCallForSearchHotel = () => {
    const payload = initApiCall(idParamsFromHome.currency, idParamsFromHome.checkInDate, idParamsFromHome.checkOutDate,
      RoomArr, countryOfResidence, nationality,
      countryResidenceAndCultureInit.culture, idParamsFromHome.destinationId, loggedInUserId)
    iniApiCall({ payload })
    // dispatch(setSearchHotelsResultCall(true))
    dispatch(setHotelsTypeTabSelection('ALL'))
  }

  // init api call and get hotels results api
  useEffect(() => {
    if (searchId == '' && idParamsFromHome.currency && idParamsFromHome.checkInDate && idParamsFromHome.checkOutDate &&
      RoomArr && searchHotelsResult == true) {
      dispatch(setNoOfHitsResultApi(1))
      initApiCallForSearchHotel()
      dispatch(setSearchHotelsResultCall(false))
    }

    if (searchId && hotelsResultData?.searchStatus !== "Completed" && numberOfHitsResultApi == 1) {
      dispatch(fetchHotesResultsDataFromApi({ searchId, numberOfHitsResultApi }));
      dispatch(fetchOfferHotelsResult(searchId))
      dispatch(setNoOfHitsResultApi(2))
    }
    else if (searchId && hotelsResultData?.searchStatus == "InProgress" && numberOfHitsResultApi == 2) {
      dispatch(fetchHotesResultsDataFromApi({ searchId, numberOfHitsResultApi }));
      dispatch(setLoadHotelResultSkeleton(false))
    }
    else if (searchId && hotelsResultData?.searchStatus == "Completed" && hotelsDataLoading == false && numberOfHitsResultApi == 2) {
      dispatch(fetchGetFiltersDataFromApi(searchId))
      dispatch(setSearchHotelsResultCall(false))
      moengageEventLogHanlder()
    }
  }, [searchId, hotelsResultData.searchStatus, numberOfHitsResultApi])

  AppLogHandler("hotelsResultData", setPriceRangesSelected)

  // function for navigate advance filters mobile responsive
  const navigateToAdvancefilters = () => {
    dispatch(setPreviousPathName(previousPathName))
    navigate('/filters')
  }

  // modify seach for reseach hotel UI
  const SwipeableDrawerExample = ({ destinationName, fromDate, toDate }: any) => {

    const toggleDrawer = (isOpen: any) => () => {
      setOpenDrawer(isOpen);
    };

    // open date picker handler    
    const openDatePickerHandler = () => {
      setstepSecond(true)
      setstepFirst(true)
      setStepNumber(1)
      setDestinationShow(true)
      setOpenDrawer(false)
    }

    // open guest picker handler    
    const openGuestPickerHandler = () => {
      setstepFirst(true)
      setstepSecond(false)
      setStepNumber(2)
      setDestinationShow(true)
      setOpenDrawer(false)
    }


    return (
      <>

        <Box width={'75%'} onClick={toggleDrawer(true)}>
          <Typography fontSize={'10px'} fontWeight={'600'}>{destinationName}</Typography>
          <Typography color={'#C4C4C4'} fontSize={'8px'}>{fromDate} - {toDate} •
            {` ${adultCount > 1 ? adultCount + t(' Adults') : childCount == 0 ? t("I’m alone") : adultCount + t(' Adult')}`} {childCount > 0 ? ", " + childCount + ` ${childCount > 1 ? t('Children') : t('Child')}` : ''}</Typography>
        </Box>
        <SwipeableDrawer
          anchor="bottom"
          open={openDrawer}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={true}
          className='iGor__SearchSwipe'>
          <Grid container >
            <Grid item xs={12} padding={2} >
              <Box onClick={toggleDrawer(false)}>
                <Box sx={{ background: '#C4C4C4', width: '45px', height: '5px', margin: '0 auto', borderRadius: '50px' }}></Box>
                <Typography fontSize={'12px'} color={'#FF6B6B'} textAlign={'center'} mb={'20px'} mt={'4px'}>Change Search</Typography>
              </Box>
              <Box className='iGor__inputflied' onClick={handleAnimationInput}>
                <LocPin className='iGor__icon' />
                <Typography className='iGor__input'>{destinationName}</Typography>
              </Box>
              <Box className='iGor__inputflied' onClick={openDatePickerHandler}>
                <TodayIcon className='iGor__icon' />
                <Typography className='iGor__input'>{fromDate} - {toDate}</Typography>
              </Box>
              <Box className='iGor__inputflied' onClick={openGuestPickerHandler}>
                <PersonOutlineIcon className='iGor__icon' />
                <Typography className='iGor__input'>{roomCount} Room • {` ${adultCount > 1 ? adultCount + t(' Adults') : childCount == 0 ? t("I’m alone") : adultCount + t(' Adult')}`} {childCount > 0 ? ", " + childCount + ` ${childCount > 1 ? t('Children') : t('Child')}` : ''}</Typography>
              </Box>
              <Button variant='contained' sx={{ marginTop: '21px' }} onClick={searchClickHandler}>Apply to search</Button>
            </Grid>
          </Grid>

        </SwipeableDrawer>
      </>
    );
  };
  return (
    <>
      <Box sx={{ background: "#ffffff" }} className='iGOr_mb-D-none'>
        <Header name='iGoRoomHeader' currClass='iGor_currColor' />
      </Box>

      <Container className='iGoR_SearchResult'>
        {!matches &&
          <Box className='iGoR-SearchRFilter'>
            <SearchModifyBox
              startDateForDestination={startDateForDestination}
              endDateForDestination={endDateForDestination}
              searchClickHandler={searchClickHandler}
              setDestinationObj={setDestinationObj}
              setRoomArrayForGuest={setRoomArrayForGuest}
              setStartDateForDestinationSearch={setStartDateForDestinationSearch}
              setEndDateForDestinationSearch={setEndDateForDestinationSearch}
              destinationObj={destinationObj}
              roomArrayForGuest={roomArrayForGuest}
              initApiLoading={initApiCallData?.isLoading}
            />
            {show && <Filters />}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <IconButton onClick={handleClick}>
                {
                  show ? <ExpandLessIcon /> :
                    <ExpandMoreIcon />}
              </IconButton>
            </Box>
          </Box>
        }
        {!matches && <ResetFilters resetFiltersHandler={resetFiltersHandler} />}
        <Grid container>
          {matches && <Grid width={'100%'} item lg={12} >
            <Box className='iGor_setFlex iGor_alignCenter iGor_fliterMbMain' margin={' 33px 0 20px 0'}>
              <ArrowBackIosNewIcon className='iGor_serR_arrow ' onClick={navigateToHomePageMobileView} />
              <Box className='iGor_fliterSectionMb'>
                <img src={Icon__magnify} className="iGo__SearchIcon" />
                <SwipeableDrawerExample fromDate={fromDate} toDate={toDate} destinationName={destinationObj.destinationName} />
                <IconButton className='iGor_PT-0'>
                  {true ? <img onClick={navigateToAdvancefilters} src={TuneIcon} className='IGor_FilterIcon' /> : <img src={FilterIcon} alt='FilterIcon' />}
                </IconButton>
                {/* <AdvancedFilterMod setIcon={true} /> */}
              </Box>
            </Box>
            <Box className='iGor-Text-center' marginBottom={1}>
              <Typography variant='body2' fontSize={'12px'}>Search results in ‘{destinationsData.destinationName}’</Typography>
              <Typography color={'#C4C4C4'} fontSize={'8px'}>Founded {hotelsCount} properties in {destinationsData.destinationName}</Typography>
            </Box>
            <CustomTabs setTabType={setTabType} />
          </Grid>}
          <Grid item lg={6} xs={12}>
            {/* hotesl results for all type */}
            <HotelsResultsList
              tabType={tabType}
              show={show}
              matches={matches}
              initApiLoading={initApiCallData?.isLoading}
              numberOfHitsResultApi={numberOfHitsResultApi}
            />
          </Grid>
          {/* hotel vr image component */}
          {loadHotelResultSkeleton == true ?
            (<VRandMapSectionSkeleton />)
            : (<HotelResultVRImgSection matches={matches} />)}
        </Grid>
      </Container>


      {destinationShow && matches ?
        (<>
          <SearchModifyMobile
            numberOfNights={numberOfNights}
            setRoomArrayForGuest={setRoomArrayForGuest}
            roomArrayForGuest={roomArrayForGuest}
            setOpenModifySearchBox={setOpenDrawer}
            stepFirst={stepFirst}
            stepNumber={stepNumber}
            setstepFirst={setstepFirst}
            stepSecond={stepSecond}
            setstepSecond={setstepSecond}
            stepNumUpdate={handleStepNumUpdate}
            setDestinationShow={setDestinationShow}
            setResearchHotelResults={setResearchHotelResults}
            setNumberOfNights={setNumberOfNights}
            startDateForDestination={startDateForDestination}
            endDateForDestination={endDateForDestination}
            setEndDateForDestinationSearch={setEndDateForDestinationSearch}
            setStartDateForDestinationSearch={setStartDateForDestinationSearch}
            destinationObj={destinationObj}
            setDestinationObj={setDestinationObj}
          /> </>) : ('')}
      <ToastContainer />
    </>
  )
}
