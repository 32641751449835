import React, { useEffect, useState } from 'react'
import { Typography, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useBookHotelMutation } from "../../services/api/hotelApi";
import { useAppSelector } from "../../appStore/hooks";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setAlertBoxOnError, setCliendSecreatId, setErrorMessageForDialogueBox, setOrderSummarySectionType, setPassengerDetailsForCheckout, setbBookingApiDataReset } from '../../appStore/hotelSlice';
import { authorizePayment } from './PayPalApis';
import { EventNamesForEventLogs, defaultCardPaymentObj, orderSummarySectionType, paymentGateWaysType } from '../../utils/constants/constant';
import { useTranslation } from 'react-i18next';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import confirmPaymentPayloadData from '../../services/confirmPaymentPayload';
import notPagefoundImg from '../../assets/images/NotPageFound.webp';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import { isDateWithinThreeMonthsFromNow } from '../../utils/utilities/DateConverter';

function ConfirmPayments() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { hotelRoomPriceApiData, getUserDetails, bookingApiData, searchDestinationFromDate } = useAppSelector(state => state.hotel)
    const passengerDetails = useAppSelector(state => state.hotel.passengerDetailsForCheckoutHotel);
    const hotelPriceData: any = hotelRoomPriceApiData?.data ? hotelRoomPriceApiData?.data : [];
    const loggedInUserDetails = getUserDetails.data.userDetail ? getUserDetails.data.userDetail : ''
    const [roomGroup] = hotelPriceData?.roomGroup ? hotelPriceData?.roomGroup : [];
    const [occupancies] = roomGroup?.occupancies;
    const hotelPrice = roomGroup?.roundOffTotalRate;
    const roomPriceId = hotelPriceData.priceId;
    const roomRateId = roomGroup.rateId;
    const hotelId = hotelPriceData.hotelId;
    const currency = roomGroup?.currency
    const urlParam = window.location.search;
    const bookingStatus = bookingApiData?.bookStatus;
    const bookApiStatus = bookingApiData?.status;
    const queryParams = new URLSearchParams(urlParam)
    const paymentIntentId = queryParams.get('payment_intent')
    const paymentStastus = queryParams.get('redirect_status')
    const paypalUrlParams = window.location.search;
    const paramsForPaypal = new URLSearchParams(paypalUrlParams);
    const payerId: any = paramsForPaypal.get('PayerID')
    const paypalToken: any = paramsForPaypal.get('token')
    const paymentGateWayType = paymentGateWaysType.paypal;
    const paymentSection = orderSummarySectionType.payment;
    const userMailId = loggedInUserDetails.email;
    const orderId = paymentIntentId ? paymentIntentId : '';
    const priceIdRoom = roomPriceId
    const gstDetails = null;
    const eventNamePaymentSuccess = EventNamesForEventLogs.paymentSuccess;

    const contactDetails = {
        phone: loggedInUserDetails.phone,
        email: loggedInUserDetails.email
    }
    const roomMappingArray = [{
        paxIds: passengerDetails.map((passId: any) => passId.id),
        roomIndex: occupancies?.roomIndex,
        roomRateId: roomRateId
    }]
    // const [isModalOpen, setIsModalOpen] = useState(true);

    // book hotel mutation assign to variable
    const [bookHotel, apiCallData] = useBookHotelMutation()

    // book hotel api call function
    const bookingApiLoading = apiCallData.isLoading;
    const bookHotelAndProceedPayment = (paymentIntentId: any, gateWayType: string) => {
        const isThreeeMonthLaterDate = isDateWithinThreeMonthsFromNow(searchDestinationFromDate)
        if (isThreeeMonthLaterDate == true) {
            AppLogHandler('Three month later date', isThreeeMonthLaterDate)
            const payload = confirmPaymentPayloadData(passengerDetails, contactDetails, gstDetails, roomMappingArray,
                defaultCardPaymentObj, paymentIntentId, gateWayType, orderId, userMailId, priceIdRoom, hotelPrice)
            if (payload)
                bookHotel({ payload })
        }
        else if (isThreeeMonthLaterDate == false) {
            AppLogHandler('Three month later date', isThreeeMonthLaterDate)
            dispatch(setErrorMessageForDialogueBox('36'))
            dispatch(setAlertBoxOnError(true))
        }
    }

    // function for authorize payment and book api call hit paypal
    const authorizepayment = async () => {
        const paypalGateWay = paymentGateWaysType.paypal;
        const accesToken: any = sessionStorage.getItem('acceess-Token')
        const authoRizePayment = await authorizePayment(accesToken, paypalToken, payerId)
        AppLogHandler('authoRizePayment', authoRizePayment)
        const paymentId = authoRizePayment?.purchase_units ? authoRizePayment?.purchase_units[0]?.payments?.authorizations[0]?.id : authoRizePayment

        if (!paymentId?.includes('Request failed')) {
            const purchaseID = paymentId
            bookHotelAndProceedPayment(purchaseID, paypalGateWay)
        }

        else if (paymentId?.includes('Request failed')) {
            dispatch(setErrorMessageForDialogueBox('17'))
            dispatch(setAlertBoxOnError(true))
            dispatch(setOrderSummarySectionType(paymentSection))
            setTimeout((() => { navigate('/order-summary') }), 3000)
        }
    }

    const eventLogPaymentSuccessHanlder = () => {
        const eventIdsForLogEvents = {
            "ig_content_id": hotelId,
            "ig_content_type": 'Hotel',
            "ig_price": hotelPrice,
            "ig_quantity": '1',
            "ig_currency": currency ? currency : '',
        }
        EventLogsHandler(eventNamePaymentSuccess, eventIdsForLogEvents)
    }

    // useEffect for book hotel api after payment sucess
    useEffect(() => {
        dispatch(setCliendSecreatId(''))
        if (passengerDetails?.length > 0 && paymentIntentId && paymentStastus == 'succeeded') {
            const stripeGateWay = paymentGateWaysType.stripe;
            bookHotelAndProceedPayment(paymentIntentId, stripeGateWay)
            eventLogPaymentSuccessHanlder()
        }
        else if (paymentGateWayType == 'PayPal' && payerId && paypalToken) {
            authorizepayment()
        }
        else {
            dispatch(setErrorMessageForDialogueBox('14'))
            dispatch(setAlertBoxOnError(true))
        }

    }, [passengerDetails, paymentIntentId, paymentStastus])

    // useeffect for booking confirmation handle 
    useEffect(() => {
        if (bookingStatus == 'Confirmed' && apiCallData.status == "fulfilled" && bookingApiLoading == false) {
            dispatch(setbBookingApiDataReset({}))
            setTimeout((() => { navigate('/booking-confirmed') }), 1500)
        }
        else if (bookingApiLoading == false && apiCallData.status == "rejected") {
            dispatch(setbBookingApiDataReset({}))
            dispatch(setErrorMessageForDialogueBox('13'))
            dispatch(setAlertBoxOnError(true))
            dispatch(setOrderSummarySectionType(paymentSection))
            dispatch(setPassengerDetailsForCheckout([]))
            setTimeout((() => { navigate('/order-summary') }), 3000)
        }
        else if (bookingStatus == 'Planned') {
            dispatch(setbBookingApiDataReset({}))
            dispatch(setErrorMessageForDialogueBox('13'))
            dispatch(setAlertBoxOnError(true))
            dispatch(setOrderSummarySectionType(paymentSection))
            setTimeout((() => { navigate('/order-summary') }), 3000)
        }
    }, [bookingStatus, bookApiStatus, bookingApiLoading, apiCallData])

    AppLogHandler('ConfirmPayment Page', {
        'token': { payerId, paypalToken },
        'hotelPriceData': hotelPriceData
    })
    return (<>

        <Grid container sx={{ overflow: 'hidden' }}>
            <Grid item sm={6}>
                <Box className='iGor__pageNotRight'>
                    <AutoModeIcon className='iGor__loadingIcon iGor__loadingAnimation' />

                    <Typography fontSize={'1rem'} mb={1} textAlign={'center'}>Please be patient while your transaction is being processed </Typography>
                    <Typography fontSize={'1.5rem'} mb={1} textAlign={'center'}> Please wait. Don't press Back or REFRESH button of your browser.</Typography>
                </Box>
            </Grid>
            <Grid item sm={6} className="iGor__bg-error iGor__side-error">
                <img src={notPagefoundImg} className="iGor__side-error" />
            </Grid>
        </Grid>
    </>);
}

export default ConfirmPayments;