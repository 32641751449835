
import * as React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';

const HotelresultSkeleton = () => {
    return (
        <Box className="iGoR_roomCard iGoR_roomCardSkeleton" >
            <Box className="iGoR_roomCardInner">
                <Box className='iGor-CardLeft'>  <Skeleton variant="rectangular" className='iGoR_ImgSkeleton' /> </Box>
                <Box className='iGor-CardRight'>
                    <Typography variant="h4" ><Skeleton animation="wave" /></Typography>
                    <Box className='iGor-ratingSection'>
                        <Typography variant='caption'>  <Skeleton animation="wave" /></Typography>
                        <Box className='iGor-rating'>
                            <Skeleton animation={false} />
                        </Box>
                        <Box className="iGor_ratingNum"><Skeleton animation="wave" /></Box>
                        <Typography variant='caption' className='iGor_subTitle'>  <Skeleton animation="wave" /></Typography>
                    </Box>
                    <Box className="iGor_Travel">
                        <Typography variant='caption' className='iGor_subTitle'>  <Skeleton animation="wave" /></Typography>
                        <Typography variant='caption' className='iGor_subTitle'>  <Skeleton animation="wave" /></Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default HotelresultSkeleton;