import { env } from "../utils/config/appConfig";

const ChannelId = env.ChannelIdIgo;

const initApiCall = (currency: any, startDate: any, endDate: any, roomArr: any, country: any, nationality: any, culture: any, locId: any, loggendInUserId: number) => {
  const formatDate = (date: Date) => {
    return date.toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  };

  const payload = {
    initDatassss:
    {
      currency: currency,
      checkIn: formatDate(new Date(startDate)),
      checkOut: formatDate(new Date(endDate)),
      rooms: roomArr,
      countryOfResidence: country,
      nationality,
      culture,
      locationId: `${locId}`,
      channelId: ChannelId,
      userId: loggendInUserId
    }
  }
  return payload

}

export default initApiCall