import { AppLogHandler } from "../../utils/handlers/appLogsHandler"
import { setAlertBoxOnError, setErrorMessageForDialogueBox } from "../../appStore/hotelSlice"

export const CheckAllMatchFulfilledForBooking: any = (openAlert: boolean, messageNo: string) => {

    return async (dispatch: any) => {
        AppLogHandler('message for alert', messageNo)
        dispatch(setErrorMessageForDialogueBox(messageNo))
        dispatch(setAlertBoxOnError(openAlert))
    }
}