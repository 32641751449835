import React, { useState } from 'react'
import { Autocomplete, InputLabelProps, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import { AppLogHandler } from '../../utils/handlers/appLogsHandler'
import { setCountryCallingIDData, setCountryNumCodeForApi } from '../../appStore/hotelSlice';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from 'react-i18next';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';



export const CountryCode = ({ setCallCodeError }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const countriesCallingCodeData: any = useAppSelector(state => state.hotel?.countryCallingCodes?.countries);
    const countrySelectedForRegister: any = useAppSelector(state => state.hotel.registerUserCountryCall)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [newCode, setNewCode] = useState(countrySelectedForRegister?.countryCallCodeLabel);

    // country calling code handler
    const setCallingCode = (e: any, data: any) => {
        AppLogHandler("e.target.value", data)
        if (data?.countryCallCode !== '') {
            setNewCode(data.label);
            setCallCodeError('')
            dispatch(setCountryCallingIDData({
                countryCallingId: data.countryCallCode,
                countryId: data.countryId,
                countryCallCodeLabel: data.label
            }))
        }
        if (data?.countryId !== '') { dispatch(setCountryNumCodeForApi(data?.countryId)) }
        if (data?.label == '') {
            setCallCodeError('Please select calling code')
        }
        setAnchorEl(null);
    }

    // country calling array map function for display country call code list
    let countryCallingCodes: any = [];
    countriesCallingCodeData ? countryCallingCodes = countriesCallingCodeData?.map((country: any) => {
        return (
            {
                countryName: country.name,
                countryCallCode: country.phone_code,
                countryId: country.id,
                label: `${country.name} (${country.phone_code})`
            }
        )
    }) : ([''])

    return (
        <>


            <Typography aria-describedby={id} onClick={handleClick} className='iGor_codeField'>
                <Typography className='iGorTitle'>Country</Typography>
                <Typography className={newCode ? 'iGorCodeNameSelected' : 'iGorCodeName'} variant='subtitle2'>{newCode ? newCode : "Choose code country"}</Typography>
            </Typography>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ overflowY: 'hidden' }}

            >
                {countryCallingCodes.length > 0 ?
                    (<Box className="iGoR_autoComSection">

                        <Autocomplete
                            open
                            className='iGoR_autoCom'
                            freeSolo
                            disablePortal
                            options={countryCallingCodes != null ? countryCallingCodes : [' ']}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={t('Search code country')}
                                    InputLabelProps={{
                                        ...(params.InputLabelProps as Partial<InputLabelProps>),
                                    }}
                                />)}
                            onChange={(value, data) => setCallingCode(value, data)}
                        />
                    </Box>) :
                    (<Box className="iGoR_autoComSection_noCuntry">
                        <Typography className='iGor_Text_noCuntry'>{t('5011')}</Typography>
                    </Box>)}
            </Popover>
        </>
    )
}