import { Container, Typography, Grid, Box, Button } from "@mui/material";
import notPagefoundImg from '../../assets/icons/IGorIcon__404.svg';
import Icon__IGorlogoBlack from '../../assets/icons/Icon__IGorlogoBlack.svg';
import { useNavigate } from "react-router";

const PagesNotFound = () => {
    const navigate = useNavigate();
    const home = () => {
        navigate('/')
    }
    return (
        <>
            <Container>
                <Grid container sx={{ overflow: 'hidden' }}>
                    <Grid item sm={12} marginTop={'50px'}>
                        <img src={Icon__IGorlogoBlack} onClick={home} />
                    </Grid>
                    <Grid item sm={6} sx={{ height: '80vh' }}>
                        <Box className='iGor__pageNotRight' sx={{alignItems:'flex-start'}}>

                            <Typography fontSize={'72px'} fontWeight={600} mb={1} lineHeight={1}>Page <br /> not found!</Typography>
                            <Typography fontSize={'14px'} mb={1} >Oops! The page you were trying to access is currently  <br />
                                not available. Now, don't be sad!  </Typography>
                            <Typography fontSize={'14px'} mb={1} >  There are many exciting things to explore on Igoroom!</Typography>
                            <Button onClick={home} variant="contained" sx={{ width: '200px', marginTop: '21px' }}>Back to homepage</Button>
                        </Box>
                    </Grid>
                    <Grid item sm={6} >
                        <img src={notPagefoundImg} className="iGor__NotFoundimg" />
                    </Grid>

                </Grid>
            </Container>

        </>
    )
}
export default PagesNotFound;