import React, { useEffect, useState } from 'react';
import { Popover, IconButton, ListItem, ListItemButton, Typography, ListItemIcon, Checkbox, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../appStore/hooks';
import { fetchHotesResultsDataFromApi, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels, setHotelsResultsDataReset, setMobileAppliedPropertyTypeFilterForHotels, setMobileAppliedRoomTypeFilterForHotels, setSearchHotelsResultCall } from '../../appStore/hotelSlice';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { viewByOptionsRoomList } from '../../utils/constants/constant';

export const PropertyTypePopOver = ({ propertyTypeSelectionHandler, roomTypeSelectionHandler, propertyType, roomTypes, setlist }: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const viewByOptionsList = viewByOptionsRoomList;
    const { setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters ,initApi } = useAppSelector(state => state.hotel);
    const [viewBy, setViewBy] = useState('Random');

    const filtersForUI = useAppSelector(state => state.hotel.getUIFiltersData);
    let defaultPropertyType = filtersForUI?.data?.propertyTypes;
    let defaultRoomTypes = filtersForUI?.data?.roomTypes;
    let searchId = initApi.searchId;

    React.useEffect(() => {
        // re-assign values 
        defaultPropertyType = filtersForUI?.data?.propertyTypes;
        defaultRoomTypes = filtersForUI?.data?.roomTypes;

        // reset view type
        if (  setPropertyTypeSelectedFilters?.length == 0 && setRoomTypeSelectedFilters?.length == 0) {
            setViewBy('Random')
            // dispatch(fetchHotesResultsDataFromApi(searchId))       
        }
    }, [propertyType, roomTypes, setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters, filtersForUI])

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const viewBySelectedHandler = (e: any, viewBy: any) => {
        setViewBy(viewBy)
        handleClose()
    }

    const open = Boolean(anchorEl);
    const id = open ? 'PropertyType-popover' : undefined;

    AppLogHandler(' setApplyFiltersMainData', {
        "defaultPropertyType": defaultPropertyType,
        "defaultRoomTypes": defaultRoomTypes,
        "props.setlist": setlist,
        "setSinglePropertyTypeSelectedFilter": setPropertyTypeSelectedFilters,
        "setSingleRoomTypeSelectedFilter": setRoomTypeSelectedFilters
    })


    // dispatching applied filters
    // useEffect(() => {
    //     AppLogHandler("propertyTypeSelectedArray", { roomTypeSelectedArray, propertyTypeSelectedArray })
    //     if(propertyTypeSelectedArray){            
    //         dispatch(setAppliedPropertyTypeFiltersForHotels(propertyTypeSelectedArray))
    //     }
    //     if(roomTypeSelectedArray.length){            
    //         dispatch(setAppliedRoomTypeFiltersForHotels(roomTypeSelectedArray))
    //     }
    //     if (setPropertyTypeSelectedFilters.length >= 0 && resetFilterCall == true) {
    //         setPropetyTypeSelectedArray([])
    //         dispatch(setAppliedPropertyTypeFiltersForHotels([]))
    //         dispatch(setResetFilterCall(false))
    //     }
    //     else if (setRoomTypeSelectedFilters.length >= 0 && resetFilterCall == true) {
    //                     dispatch(setAppliedRoomTypeFiltersForHotels([]))
    //         setRoomTypeSeletedArray([])
    //         dispatch(setResetFilterCall(false))
    //     }
    // }, [propertyTypeSelectedArray, roomTypeSelectedArray,setPropertyTypeSelectedFilters,setRoomTypeSelectedFilters,resetFilterCall])

    return (
        <>
            <IconButton className='iGor_typeBtn' onClick={handleClick}>
                {setlist === 'Propertytype' && t('Random')}
                {setlist === 'Roomtype' && t('Random')}
                {setlist === 'Viewtype' && viewBy}
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                
                {setlist === 'Propertytype' && defaultPropertyType &&
                    <ListItem component="nav" className='iGor_listPop' disablePadding >
                        {defaultPropertyType.length ?
                            defaultPropertyType.map((propertyType: any) => {
                                const labelId = `checkbox-list-secondary-label-${propertyType?.category ? propertyType?.category : 'No Options Available'}`;
                                return (
                                    <>
                                     <ListItemButton>
                                        <ListItemIcon
                                            key={propertyType.category}
                                            // secondaryAction={
                                              
                                            // }
                                            // disablePadding
                                        >
                                              <Checkbox
                                                    key={propertyType.category}
                                                    edge="start"
                                                    className='iGor_FiltercheckBox'
                                                    onChange={(e) => propertyTypeSelectionHandler(propertyType?.category, propertyType?.id)}
                                                    checked={setPropertyTypeSelectedFilters?.indexOf(propertyType?.id) !== -1}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                                </ListItemIcon>
                                           
                                                <ListItemText className='iGor_FiltercheckText' id={labelId} primary={`${propertyType?.category ? propertyType?.category : 'No Options Available'}`} />
                                                {/* 
                                    <Typography variant='subtitle2' onClick={(e) => propertyTypeSelectionHandler(propertyType?.category, propertyType?.id)}>
                                        {propertyType.category}
                                    </Typography> */}
                                            </ListItemButton>
                                        
                                    </>)
                            }) :
                            (<>
                                <ListItemButton>
                                    <Typography variant='subtitle2'>
                                        {t('No Match Found')}
                                    </Typography>
                                </ListItemButton>
                            </>)
                        }
                    </ListItem>
                }

                {setlist === 'Roomtype' && defaultRoomTypes &&
                    <ListItem component="nav" className='iGor_listPop' disablePadding >
                        {defaultRoomTypes.length > 0 ?
                            defaultRoomTypes.map((roomType: any) => {
                                const labelId = `checkbox-list-secondary-label-${roomType?.category ? roomType?.category : 'No Options Available'}`;

                                return (
                                    <>
                                      <ListItemButton>
                                        <ListItemIcon
                                            key={roomType.category}
                                            // secondaryAction={
                                                
                                            // }
                                            // disablePadding
                                        >
                                            <Checkbox
                                                    key={roomType.category}
                                                    edge="start"
                                                    onChange={(e) => roomTypeSelectionHandler(roomType?.category, roomType?.id)}
                                                    checked={setRoomTypeSelectedFilters?.indexOf(roomType?.id) !== -1}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    className='iGor_FiltercheckBox'
                                                />
                                        </ListItemIcon>
                                          
                                                <ListItemText className='iGor_FiltercheckText' id={labelId} primary={`${roomType?.category ? roomType?.category : 'No Options Available'}`} />
                                            </ListItemButton>
                                    </>
                                )
                            }) :
                            (<>
                                <ListItemButton>
                                    <Typography variant='subtitle2'>
                                        {t('No Match Found')}
                                    </Typography>
                                </ListItemButton>
                            </>)
                        }
                    </ListItem>
                }

                {setlist === 'Viewtype' &&
                    <ListItem component="nav" className='iGor_listPop' disablePadding >
                        {viewByOptionsList.map((viewByType: any) => {
                            return (
                                <ListItemButton onClick={(e) => viewBySelectedHandler(e, viewByType)}>
                                    <Typography variant='subtitle2'>
                                        {viewByType}
                                    </Typography>
                                </ListItemButton>)
                        })}
                    </ListItem>
                }
            </Popover>
        </>
    )
}
