import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid, IconButton, Box, useMediaQuery } from '@mui/material'
import { Container } from '@mui/system';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router';
import { orderSummarySectionType } from '../../utils/constants/constant';
import { useAppSelector } from '../../appStore/hooks';
import { useDispatch } from 'react-redux';
import { setHotelRoomPriceSelectedReset, setOrderSummarySectionType, setPassengerDetailsForCheckout, setRoomRecommendationSelected } from '../../appStore/hotelSlice';
import { dateFormatedForUrls } from '../../utils/utilities/DateConverter';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

export function OrderSummaryHeader({ nextStep, onBackStep, setMobileStep, setContinuePayment }: any) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { searchDestinationFromDate, searchDestinationToDate, loggedInUserCurrency, destinationsData, hotelIdSelected ,previousPathName } = useAppSelector(state => state.hotel)
    const orderSummarySectionSelected = useAppSelector(state => state.hotel.orderSummerySection)
    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const destinationSelectedData = destinationsData
    const orderSummarySction = orderSummarySectionType.orderSummary;
    const paymentSection = orderSummarySectionType.payment;
    const finishSection = orderSummarySectionType.finish;
    const matches = useMediaQuery('(max-width:1024px)');
    const previousPath = previousPathName;
    const startFormattedDate = dateFormatedForUrls(searchDestinationFromDate)
    const endFormattedDate = dateFormatedForUrls(searchDestinationToDate)
    const handleSetBack = () => {
        if (setContinuePayment) (setContinuePayment(false))
        dispatch(setOrderSummarySectionType(orderSummarySction))
        dispatch(setPassengerDetailsForCheckout([]))
    };
    const handleHomepage = () => {
        let locationId = destinationSelectedData?.destinationCode

        navigate(previousPathName)
        dispatch(setRoomRecommendationSelected({}))
        dispatch(setHotelRoomPriceSelectedReset({ data: [], loading: false, error: null }))
    };

    AppLogHandler('previousPath :',previousPath)
    return (
        <>

            <Box className='iGor_orderSumHeader'>
                <Container>
                    <Grid container spacing={2} className='iGor_alignCenter'>
                        <Grid item lg={3} sm={1} xs={3}>
                            <IconButton className='iGor_orderBack' onClick={orderSummarySectionSelected == paymentSection ? handleSetBack : handleHomepage}> <KeyboardArrowLeftIcon className='iGor_orderBackIcon' />     {!matches && 'Back'} </IconButton>
                        </Grid>
                        <Grid item sm={6} xs={9} className='iGOr_mb-D-none iGor_justifyCenter iGor_setFlex'>
                            <IconButton className={orderSummarySectionSelected === orderSummarySction ? 'iGor_OrderBtn iGor_OrderBtnActive' : 'iGor_OrderBtn'}> {orderSummarySectionSelected === orderSummarySction && <span className='iGor_OrderBtnIcon'>1</span>} Order summary </IconButton>
                            <IconButton className={orderSummarySectionSelected === paymentSection ? 'iGor_OrderBtn iGor_OrderBtnActive' : 'iGor_OrderBtn'}>
                                <ArrowRightAltIcon className='iGor_orderArrow' /> {orderSummarySectionSelected === paymentSection && <span className='iGor_OrderBtnIcon'>2</span>}  Payment</IconButton>
                            <IconButton className={orderSummarySectionSelected === finishSection ? 'iGor_OrderBtn iGor_OrderBtnActive' : 'iGor_OrderBtn'}>
                                <ArrowRightAltIcon className='iGor_orderArrow' /> {orderSummarySectionSelected === finishSection && <span className='iGor_OrderBtnIcon'>3</span>}Finish
                            </IconButton>

                        </Grid>
                        {matches &&
                            <Grid item pl={0} sm={11} xs={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography variant='body2' className='iGoi_OrderSumMainTitle'>{setMobileStep ? 'Booking Details ' : 'Order Summary'}   </Typography>
                            </Grid>
                        }

                    </Grid>

                </Container>
            </Box>


        </>
    );
}
