import React, { useState, useEffect, useMemo } from 'react'
import Header from '../../layouts/Header'
import { Box, Button, Container, Grid, IconButton, Skeleton, Typography, useMediaQuery } from "@mui/material";
import { CustomTabs } from '../../components/inputFieldCustom/CustomTabs'
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import NoHotelsImg from '../../assets/images/noHotelIgo.jpg'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import 'react-toastify/dist/ReactToastify.css';
import { useAppSelector } from '../../appStore/hooks';
import { env } from '../../utils/config/appConfig';
import { useTranslation } from 'react-i18next';
import GoogleMapForOfferHotels from '../../components/Maps/GoogleMapForOfferHotels';
import { fetchHotelDetailsFromApi, fetchHotelsRoomsDataFromApi, setAlertBoxOnError, setAllTypeOfOffersAndHotels, setErrorMessageForDialogueBox, setHotelDetails, setHotelRoomDetails, setHotelSearchRequestReset, setHotelsTypeTabSelection, setPreviousPathName } from '../../appStore/hotelSlice';
import initApiCall from '../../services/initApiCall';
import timeIcon from '../../assets/icons/timerIcon.svg';
import { useInitApiMutation } from '../../services/api/hotelApi';
import { useDispatch } from 'react-redux';
import { countryResidenceAndCulture } from '../../utils/constants/constant';
import { useNavigate } from 'react-router';
import HotelresultSkeleton from '../../components/Skeleton/HotelresultSkeleton';
import VRandMapSectionSkeleton from '../../components/Skeleton/VRAndMapSkeleton';
import Filter from '@mui/icons-material/Tune';
import { LoadScript } from '@react-google-maps/api';
import convertDateFormat, { dateFormatedForUrls } from '../../utils/utilities/DateConverter';
import needToLogin from '../../assets/images/need_login.png'
import BackArrow from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from 'react-router-dom';
import { cacheImages } from '../../utils/utilities/ImageCaching';
import { OrderSumRegisterModel } from '../../components/popUpSection/OrderSumRegisterModel';
import { environmentVariables } from '../../utils/config/environmentVariables';

export const LatestOffers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchOfferHotel, setSearchOfferHotel] = useState(false);
  let [arrayForClusters, setArrayforClusters] = useState([]);
  const [selectedMarker, setSelectedMarker]: any = useState(null);
  const [hotelCardOpen, setHotelCardOpen] = useState(false)
  const [defaultZoom, setDefaultZoom] = useState(2);
  const [maploaded, setMapLoaded] = useState(false);
  const [mapError, setMapError] = useState(false)
  const [destinationSelectedObj, setDestinationSelectedObj]: any = useState({});
  const { initApi, loggedInUserCurrency, hotelDetails, searchDestinationFromDate, searchDestinationToDate, roomArray, allTypeOfLatestOffersAndHotels, getHotelsToExploreOffers, selectedHotelsTypeTab } = useAppSelector(state => state.hotel)
  const latestOffersdata: any = useAppSelector(state => state.hotel.getHotelsToExploreOffers)
  const latestOffersToExplore = latestOffersdata?.data?.propertyOfferResponses ? latestOffersdata?.data?.propertyOfferResponses : [];
  const latestHotelsToExplore = latestOffersdata?.data?.propertyHotelResponses ? latestOffersdata?.data?.propertyHotelResponses : [];
  const latestVipHotelsToExplore = latestOffersdata?.data?.propertyVIPResponses ? latestOffersdata?.data?.propertyVIPResponses : [];
  const allTypeOfLatesOffersAndHotelsResorts: any = allTypeOfLatestOffersAndHotels;
  const getHotelsToExploreOffersApiLoading = getHotelsToExploreOffers.loading
  const searchId = initApi?.searchId;
  const destinationSearchStartDate = searchDestinationFromDate;
  const destinationSearchEndDate = searchDestinationToDate;
  const hotelRoomArray = roomArray
  const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
  const loggedInUserId: any = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
  const hotelDetailsFromApi = hotelDetails.data;
  const countryResidenceAndCultureInit = countryResidenceAndCulture;
  const imagePath = environmentVariables.imagePathApp;
  const toDate = new Date(searchDestinationToDate);
  const tabTypeSelected = selectedHotelsTypeTab
  const matches = useMediaQuery('(max-width:1024px)');
  const [swipeMb, setSwipeMb] = useState(true);
  const mapkey: any = environmentVariables.googleMapKey;
  const pathname = window.location.pathname;
  const formatedDate = convertDateFormat(toDate)
  const [imageLoaded, setImageLoaded] = useState(false);
  // init api mutation intialization
  const [initApiForHotel] = useInitApiMutation();

  // logic for seeting clusters to map
  let susolvkaCoords = {
    latitude: latestOffersToExplore[0]?.latitude ? parseFloat(latestOffersToExplore[0]?.latitude) : -25.3,
    longitude: latestOffersToExplore[0]?.longtitude ? parseFloat(latestOffersToExplore[0]?.longtitude) : 133.8
  }

  const startDateFormated = dateFormatedForUrls(searchDestinationFromDate);
  const endDateFormated = dateFormatedForUrls(searchDestinationToDate);

  useEffect(() => {
    if (getHotelsToExploreOffersApiLoading == true) {
      dispatch(setHotelsTypeTabSelection('ALL'))
      setSelectedMarker(null);
    }
    if (tabTypeSelected == 'ALL') {
      let arrayConcatedforAll: any = [];
      setSelectedMarker(null);
      if (loggedInUserId == 0) {
        arrayConcatedforAll = latestHotelsToExplore.concat(latestVipHotelsToExplore)
      }
      else if (loggedInUserId !== 0) {
        arrayConcatedforAll = latestHotelsToExplore.concat(latestOffersToExplore, latestVipHotelsToExplore)
      }
      setArrayforClusters(arrayConcatedforAll)
      // susolvkaCoords.latitude = latestOffersToExplore[0]?.latitude ? parseFloat(latestOffersToExplore[0]?.latitude) : -25.3,
      // susolvkaCoords.longitude = latestOffersToExplore[0]?.longtitude ? parseFloat(latestOffersToExplore[0]?.longtitude) : 133.8

    }
    else if (tabTypeSelected == 'HOTELSRESORT') {
      setSelectedMarker(null);
      setArrayforClusters(latestHotelsToExplore)
      // susolvkaCoords.latitude = latestHotelsToExplore[0]?.latitude ? parseFloat(latestHotelsToExplore[0]?.latitude) : -25.3,
      // susolvkaCoords.longitude = latestHotelsToExplore[0]?.longtitude ? parseFloat(latestHotelsToExplore[0]?.longtitude) : 133.8
    }
    else if (loggedInUserId !== 0 && tabTypeSelected == 'OFFERS') {
      setSelectedMarker(null);
      setArrayforClusters(latestOffersToExplore)
      // susolvkaCoords.latitude = latestOffersToExplore[0]?.latitude ? parseFloat(latestOffersToExplore[0]?.latitude) : -25.3,
      // susolvkaCoords.longitude = latestOffersToExplore[0]?.longtitude ? parseFloat(latestOffersToExplore[0]?.longtitude) : 133.8
    }
    else if (tabTypeSelected == 'VIP') {
      setMapLoaded(false)
      setSelectedMarker(null);
      setArrayforClusters(latestVipHotelsToExplore)
      // susolvkaCoords.latitude = latestVipHotelsToExplore[0]?.latitude ? parseFloat(latestVipHotelsToExplore[0]?.latitude) : -25.3,
      // susolvkaCoords.longitude = latestVipHotelsToExplore[0]?.longtitude ? parseFloat(latestVipHotelsToExplore[0]?.longtitude) : 133.8
    }
    else if (loggedInUserId == 0 && tabTypeSelected == 'OFFERS') {
      setSelectedMarker(null);
      setArrayforClusters([])
    }
  }, [tabTypeSelected, getHotelsToExploreOffersApiLoading, loggedInUserId])

  const mapClusters = arrayForClusters?.map((latestOffers: any) => {
    return {
      id: latestOffers.id,
      latitude: latestOffers?.latitude,
      longitude: latestOffers?.longtitude,
      bgImgHotel: `${imagePath}${latestOffers?.img.pathImg}`,
      hotelName: latestOffers?.name,
      hotelRating: latestOffers.avg_Rating.rating,
      country: latestOffers.country
    }
  })

  const handleImageLoad = () => {
    setTimeout(() => { setImageLoaded(true) },500)
  };
  // checkout offers Selection Handler
  const offersCheckoutHandler = async (offerSelected: any) => {
    const locationId = offerSelected.country
    const hotelIdSelected = offerSelected.id
    const destinationName = offerSelected.destinationName;
    const hotelNameSlug = offerSelected.name.replaceAll(" ", "-");
    const slug = offerSelected.slug;
    const locationNameSelected = offerSelected.city_Country
    setDestinationSelectedObj({
      destinationName: destinationName,
      locationId: locationId,
      hotelId: hotelIdSelected,
      slugId: slug ? slug : hotelNameSlug.toLowerCase()
    })
    dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
    dispatch(setHotelRoomDetails({ data: [] }))
    dispatch(setHotelDetails({ data: [] }))
    const payload = await initApiCall(loggendInUserCurrency, destinationSearchStartDate, destinationSearchEndDate,
      hotelRoomArray, countryResidenceAndCultureInit.countryOfResidence, countryResidenceAndCultureInit.nationality,
      countryResidenceAndCultureInit.culture, locationId, loggedInUserId)
    initApiForHotel({ payload })
    setSearchOfferHotel(true)
  }

  // to dispatch all type of hotels ,offers and vip hotels
  useEffect(() => {
    let allTypeOffersAndHotels: any = [];
    if (loggedInUserId != 0 && latestHotelsToExplore.length > 0) {
      allTypeOffersAndHotels = latestHotelsToExplore.concat(latestOffersToExplore, latestVipHotelsToExplore)
      dispatch(setAllTypeOfOffersAndHotels(allTypeOffersAndHotels))
    }
    else if (loggedInUserId == 0 && latestHotelsToExplore.length > 0) {
      allTypeOffersAndHotels = latestHotelsToExplore.concat(latestVipHotelsToExplore)
      dispatch(setAllTypeOfOffersAndHotels(allTypeOffersAndHotels))
    }
  }, [loggedInUserId, latestHotelsToExplore.length])


  const offerClickHandler = (offer: any) => {
    setSelectedMarker(
      {
        id: offer.id,
        latitude: offer?.latitude,
        longitude: offer?.longtitude,
        bgImgHotel: `${imagePath}${offer?.img.pathImg}`,
        hotelName: offer?.name,
        hotelRating: offer.avg_Rating.rating,
        country: offer.country,
        destinationName: offer?.destinationName,
        slug: offer?.slug
      }
    )
    setHotelCardOpen(true)
    setDefaultZoom(20)
  }

  // use Effect for navigate to hotel detail after click popular destination details screen
  useEffect(() => {
    if (hotelDetailsFromApi.success == true && searchOfferHotel == true && searchId) {
      setSearchOfferHotel(false)
      // dispatch(fetchHotelsRoomsDataFromApi({ searchId: searchId, hotelId: hotelId }))
      dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
      navigate('/property-details')
    }
    else if (hotelDetailsFromApi.success == false) {
      setSearchOfferHotel(false)
      dispatch(setErrorMessageForDialogueBox('19'))
      dispatch(setAlertBoxOnError(true))
      dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
      dispatch(setHotelDetails({ data: [], loading: false, error: null, }))
    }
    else if (hotelDetailsFromApi.statusCode == "BadRequest") {
      dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
      dispatch(setHotelDetails({ data: [], loading: false, error: null, }))
      dispatch(setErrorMessageForDialogueBox('35'))
      dispatch(setAlertBoxOnError(true))
    }
    else if (searchId && destinationSelectedObj.hotelId != 0 && searchOfferHotel == true) {
      const slugId = destinationSelectedObj.slugId;
      const destinationName = destinationSelectedObj.destinationName;
      const locationId = destinationSelectedObj.locationId;
      const hotelId = destinationSelectedObj.hotelId;
      navigate(`/property-details/${slugId}?hotelId=${hotelId}&destinationName=${destinationName}&destinationId=${locationId}&checkIn=${startDateFormated}&checkout=${startDateFormated}&currency=${loggendInUserCurrency}`)
      dispatch(setPreviousPathName(pathname))
    }
  }, [searchId, destinationSelectedObj, hotelDetailsFromApi, searchOfferHotel])

  const memoizedProps = useMemo(() => ({
    markersData: mapClusters,
    defaultCenter: susolvkaCoords,
    defaultZoom,
    setSelectedMarker,
    selectedMarker,
    hotelCardOpen,
    setHotelCardOpen,
    setDefaultZoom
  }), [mapClusters, susolvkaCoords, defaultZoom, setSelectedMarker, selectedMarker, hotelCardOpen, setHotelCardOpen, setDefaultZoom]);

  return (<>
    <Box sx={{ background: "#ffffff" }} className='iGOr_mb-D-none'><Header name='iGoRoomHeader' currClass='iGor_currColor' /></Box>
    <Container className='iGoR_SearchResult iGoR_LatestOffers '>
      <Button className='iGor__ProBackArrow'><Link to={'/'}><BackArrow /></Link></Button>

      <Grid container>
        <Grid item sm={6} xs={12}>
          {!matches && <CustomTabs />}
          <Box className={'iGor-latestOfferBox ' + (swipeMb ? 'iGor__mbHeight60' : 'iGor__mbHeight5')}  >
            {matches && <Box onTouchStart={() => setSwipeMb(!swipeMb)} className='iGor__LatestSwipe'> <Typography className='iGo__titltMb' >Latest Offers</Typography>
              {swipeMb && <Box className='iGor__LatestFilter'> <Filter className='iGo__filter' /></Box>}</Box>
            }
            {loggedInUserId == 0 && tabTypeSelected == "OFFERS" ?
              (<Box className='iGor__latestImgBox'><img src={needToLogin} className='iGor__latestLogoImg' />
                <Typography variant='body2' my={3}> You haven't logged in yet</Typography>

                <OrderSumRegisterModel />
              </Box>) :
              (<Box className='iGor-latestOfferSection'>
                {latestOffersToExplore.length > 1 || latestHotelsToExplore.length > 1 || latestVipHotelsToExplore.length > 1 ?
                  tabTypeSelected == 'ALL' ?
                    (<>
                      {allTypeOfLatesOffersAndHotelsResorts?.map((allTypeHotels: any) => {
                        const hotelRating = allTypeHotels?.avg_Rating?.rating ? allTypeHotels?.avg_Rating?.rating : 0;
                        const imageUrl: any = cacheImages(`${imagePath}${allTypeHotels?.img?.pathImg}`)
                        return (
                          <Box className="iGoR_roomCard" onClick={matches ? (e) => offersCheckoutHandler(allTypeHotels) : () => { }}>
                            <Box  key={allTypeHotels?.id}
                            // onClick={() => matches && onRoomDetailHandler(offersHotels?.id, offersHotels?.image)}
                            >
                              <Box className="iGoR_roomCardInner">
                                <Box className='iGor-CardLeft'>
                                  {/* {!imageLoaded && (<Skeleton variant="rectangular" height={matches ? 110 : 151} animation="wave" sx={{ borderRadius: '0.625rem' }} />)} */}

                                  <img src={allTypeHotels?.img.pathImg ? imageUrl.src : NoHotelsImg} className='iGor_cardImg' alt='img not '
                                    onError={(e: any) => {
                                      // Handle the image load error by setting the source to the default image
                                      e.target.src = NoHotelsImg;
                                    }}
                                    style={{
                                      display: imageLoaded ? 'block' : 'none'
                                    }}
                                    onLoad={handleImageLoad}
                                  />
                                  <Box className='iGor_cardBtnSection iGor_setFlex iGor_justifyBetween'>

                                    {/* <IconButton
                    className={hotels?.isWishlisted ? ' iGor_bgWhite iGor_cardLeftBtn' : 'iGor_cardLeftBtn'} >
                    {hotels?.isWishlisted ?
                      (<FavoriteIcon sx={{ color: '#FF6B6B' }}
                        onClick={(e) => addToWishListHandler(offersHotels, typeOfferHotels, index, offersHotels?.wish == true ? false : true)}
                        className='iGor_heartColour' />)
                      :
                      (<FavoriteBorderIcon
                        onClick={(e) => addToWishListHandler(offersHotels, typeOfferHotels, index, offersHotels?.wish == true ? false : true)}
                        className='iGor_heartColour'
                      />)
                    }
                  </IconButton> */}
                                    {/* <IconButton className='iGor_cardLeftBtn iGOr_mb-D-none' 
                            onClick={(e) => hotelImageSelectionHandler(offersHotels)}
                            >
                                <RemoveRedEyeOutlinedIcon />
                            </IconButton> */}
                                  </Box>
                                </Box>
                                <Box className='iGor-CardRight' onClick={() => offerClickHandler(allTypeHotels)}>
                                  <Typography variant="h4" className='iGor__HotelName'>{allTypeHotels?.name} </Typography>
                                  <Box className='iGor-ratingSection'>
                                    <Typography variant='caption'>{allTypeHotels?.propertyType ? allTypeHotels?.propertyType : 'Offers'}</Typography>
                                    <Box className='iGor-rating'>
                                      {Array.from({ length: allTypeHotels?.avg_Rating.rating || 0 }, (_, index) => (
                                        <StarIcon key={index} className='iGor_starIcon' />
                                      ))}
                                      {Array.from({ length: 5 - allTypeHotels?.avg_Rating.rating || 0 }, (_, index) => (
                                        <StarIcon key={index} className='iGor_starIcon_not_rating' />
                                      ))}
                                    </Box>
                                    <Box className="iGor_ratingNum">{allTypeHotels?.avg_Rating.rating}</Box>
                                    <Typography variant='caption' className='iGor_subTitle'>
                                      {`${hotelRating <= 2 ? t('Average') : hotelRating <= 4 && hotelRating >= 2 ? t('Good') : t('Excellent')}`}
                                      {/* ({hotels?.userReview ? `${hotels?.userReview?.count} ${t('reviews')}`  : 'No reviews'}) */}
                                    </Typography>
                                  </Box>
                                  <Box className="iGor_Travel">
                                    <img src={timeIcon} alt='timeIcon' />
                                    <Typography variant='caption' className='iGor_subTitle'>  {t('Travel until')}
                                      {formatedDate}
                                    </Typography>
                                  </Box>
                                  {/* {props.matches ? (<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            <img src={mapIcon} />
                            <Typography variant='caption' component={'p'} lineHeight={'1'} sx={{
                                whiteSpace: 'nowrap', overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>{offersHotels?.address}</Typography>
                        </Box>) : ('')} */}
                                  {
                                    <Box className="iGor_CardBtnSection">
                                      <Box>
                                        {/* <Typography variant='body1' className='iGor_subTitle'>{t('Starting from')}</Typography> */}
                                        <Box className='iGor_cardPrice'>
                                          {/* <Typography variant='h3'>{offersHotels?.listPrice?.currencySymbol} {offersHotels?.listPrice?.minPrice ? offersHotels?.listPrice?.minPrice?.toFixed() : ''}</Typography> */}
                                        </Box>
                                      </Box>
                                      <Button variant='contained' className='iGor_cardBtn iGOr_mb-D-none '
                                        onClick={(e) => offersCheckoutHandler(allTypeHotels)}
                                      >
                                        Check Availability
                                      </Button>
                                    </Box>
                                  }
                                </Box>
                              </Box>

                            </Box>
                          </Box>
                        )
                      })}
                    </>) :
                    tabTypeSelected == 'HOTELSRESORT' && latestHotelsToExplore.length <= 0 ?
                      (<Box className='iGor-NoDataOffersSection'>
                        {t('5001')}
                      </Box>) :
                      tabTypeSelected == 'OFFERS' && latestOffersToExplore.length <= 0 ?
                        (<Box className='iGor-NoDataOffersSection'>
                          {t('5002')}
                        </Box>) :
                        tabTypeSelected == 'VIP' && latestVipHotelsToExplore.length <= 0 ?
                          (<Box className='iGor-NoDataOffersSection'>
                            {t('5003')}
                          </Box>) :
                          tabTypeSelected == 'OFFERS' ? latestOffersToExplore.map((offers: any) => {
                            const hotelRating = offers?.avg_Rating?.rating ? offers?.avg_Rating?.rating : 0;
                            const imageUrl: any = cacheImages(`${imagePath}${offers?.img?.pathImg}`)
                            return (
                              <Box onClick={matches ? (e) => offersCheckoutHandler(offers) : () => { }}>
                                <Box className="iGoR_roomCard" key={offers?.id}
                                // onClick={() => matches && onRoomDetailHandler(offersHotels?.id, offersHotels?.image)}
                                >
                                  <Box className="iGoR_roomCardInner">
                                    <Box className='iGor-CardLeft'>
                                      {!imageLoaded && (<Skeleton variant="rectangular" height={matches ? 110 : 151} animation="wave" sx={{ borderRadius: '0.625rem' }} />)}

                                      <img src={offers?.img.pathImg ? imageUrl.src : NoHotelsImg} className='iGor_cardImg' alt='img not '
                                        onError={(e: any) => {
                                          // Handle the image load error by setting the source to the default image
                                          e.target.src = NoHotelsImg;
                                        }}
                                        style={{
                                          display: imageLoaded ? 'block' : 'none'
                                        }}
                                        onLoad={handleImageLoad}
                                      />
                                      <Box className='iGor_cardBtnSection iGor_setFlex iGor_justifyBetween'>

                                        {/* <IconButton
                    className={hotels?.isWishlisted ? ' iGor_bgWhite iGor_cardLeftBtn' : 'iGor_cardLeftBtn'} >
                    {hotels?.isWishlisted ?
                      (<FavoriteIcon sx={{ color: '#FF6B6B' }}
                        onClick={(e) => addToWishListHandler(offersHotels, typeOfferHotels, index, offersHotels?.wish == true ? false : true)}
                        className='iGor_heartColour' />)
                      :
                      (<FavoriteBorderIcon
                        onClick={(e) => addToWishListHandler(offersHotels, typeOfferHotels, index, offersHotels?.wish == true ? false : true)}
                        className='iGor_heartColour'
                      />)
                    }
                  </IconButton> */}
                                        {/* <IconButton className='iGor_cardLeftBtn iGOr_mb-D-none' 
                            onClick={(e) => hotelImageSelectionHandler(offersHotels)}
                            >
                                <RemoveRedEyeOutlinedIcon />
                            </IconButton> */}
                                      </Box>
                                    </Box>
                                    <Box className='iGor-CardRight' onClick={() => offerClickHandler(offers)}>
                                      <Typography variant="h4" className='iGor__HotelName'>{offers?.name} </Typography>
                                      <Box className='iGor-ratingSection'>
                                        <Typography variant='caption'>{offers?.propertyType ? offers?.propertyType : 'Offers'}</Typography>
                                        <Box className='iGor-rating'>
                                          {Array.from({ length: offers?.avg_Rating.rating || 0 }, (_, index) => (
                                            <StarIcon key={index} className='iGor_starIcon' />
                                          ))}
                                          {Array.from({ length: 5 - offers?.avg_Rating.rating || 0 }, (_, index) => (
                                            <StarIcon key={index} className='iGor_starIcon_not_rating' />
                                          ))}
                                        </Box>
                                        <Box className="iGor_ratingNum">{offers?.avg_Rating.rating}</Box>
                                        <Typography variant='caption' className='iGor_subTitle'>
                                          {`${hotelRating <= 2 ? t('Average') : hotelRating <= 4 && hotelRating >= 2 ? t('Good') : t('Excellent')}`}
                                          {/* ({hotels?.userReview ? `${hotels?.userReview?.count} ${t('reviews')}`  : 'No reviews'}) */}
                                        </Typography>
                                      </Box>
                                      <Box className="iGor_Travel">
                                        <img src={timeIcon} alt='timeIcon' />
                                        <Typography variant='caption' className='iGor_subTitle'>  {t('Travel until')}
                                          {formatedDate}
                                        </Typography>
                                      </Box>
                                      {/* {props.matches ? (<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                            <img src={mapIcon} />
                            <Typography variant='caption' component={'p'} lineHeight={'1'} sx={{
                                whiteSpace: 'nowrap', overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>{offersHotels?.address}</Typography>
                        </Box>) : ('')} */}
                                      {
                                        <Box className="iGor_CardBtnSection">
                                          <Box>
                                            {/* <Typography variant='body1' className='iGor_subTitle'>{t('Starting from')}</Typography> */}
                                            <Box className='iGor_cardPrice'>
                                              {/* <Typography variant='h3'>{offersHotels?.listPrice?.currencySymbol} {offersHotels?.listPrice?.minPrice ? offersHotels?.listPrice?.minPrice?.toFixed() : ''}</Typography> */}
                                            </Box>
                                          </Box>
                                          <Button variant='contained' className='iGor_cardBtn iGOr_mb-D-none '
                                            onClick={(e) => offersCheckoutHandler(offers)}
                                          >
                                            Check Availability
                                          </Button>
                                        </Box>
                                      }
                                    </Box>
                                  </Box>

                                </Box>
                              </Box>
                            )
                          })
                            : tabTypeSelected == 'HOTELSRESORT' ? latestHotelsToExplore.map((hotelsResort: any) => {
                              const hotelRating = hotelsResort?.avg_Rating?.rating ? hotelsResort?.avg_Rating?.rating : 0;
                              const imageUrl: any = cacheImages(`${imagePath}${hotelsResort?.img?.pathImg}`)
                              return (
                                <Box onClick={matches ? (e) => offersCheckoutHandler(hotelsResort) : () => { }}>
                                  <Box className="iGoR_roomCard" key={hotelsResort?.id}
                                  // onClick={() => matches && onRoomDetailHandler(offersHotels?.id, offersHotels?.image)}
                                  >
                                    <Box className="iGoR_roomCardInner">
                                      <Box className='iGor-CardLeft'>
                                        {!imageLoaded && (<Skeleton variant="rectangular" height={matches ? 110 : 151} animation="wave" sx={{ borderRadius: '0.625rem' }} />)}

                                        <img src={hotelsResort?.img.pathImg ? imageUrl.src : NoHotelsImg} className='iGor_cardImg' alt='img not '
                                          onError={(e: any) => {
                                            // Handle the image load error by setting the source to the default image
                                            e.target.src = NoHotelsImg;
                                          }}
                                          style={{
                                            display: imageLoaded ? 'block' : 'none'
                                          }}
                                          onLoad={handleImageLoad}
                                        />
                                        <Box className='iGor_cardBtnSection iGor_setFlex iGor_justifyBetween'>

                                          {/* <IconButton
                      className={hotels?.isWishlisted ? ' iGor_bgWhite iGor_cardLeftBtn' : 'iGor_cardLeftBtn'} >
                      {hotels?.isWishlisted ?
                        (<FavoriteIcon sx={{ color: '#FF6B6B' }}
                          onClick={(e) => addToWishListHandler(offersHotels, typeOfferHotels, index, offersHotels?.wish == true ? false : true)}
                          className='iGor_heartColour' />)
                        :
                        (<FavoriteBorderIcon
                          onClick={(e) => addToWishListHandler(offersHotels, typeOfferHotels, index, offersHotels?.wish == true ? false : true)}
                          className='iGor_heartColour'
                        />)
                      }
                    </IconButton> */}
                                          {/* <IconButton className='iGor_cardLeftBtn iGOr_mb-D-none' 
                              onClick={(e) => hotelImageSelectionHandler(offersHotels)}
                              >
                                  <RemoveRedEyeOutlinedIcon />
                              </IconButton> */}
                                        </Box>
                                      </Box>
                                      <Box className='iGor-CardRight' onClick={() => offerClickHandler(hotelsResort)}>
                                        <Typography variant="h4" className='iGor__HotelName'>{hotelsResort?.name} </Typography>
                                        <Box className='iGor-ratingSection'>
                                          <Typography variant='caption'>{hotelsResort?.propertyType ? hotelsResort?.propertyType : 'Offers'}</Typography>
                                          <Box className='iGor-rating'>
                                            {Array.from({ length: hotelsResort?.avg_Rating.rating || 0 }, (_, index) => (
                                              <StarIcon key={index} className='iGor_starIcon' />
                                            ))}
                                            {Array.from({ length: 5 - hotelsResort?.avg_Rating.rating || 0 }, (_, index) => (
                                              <StarIcon key={index} className='iGor_starIcon_not_rating' />
                                            ))}
                                          </Box>
                                          <Box className="iGor_ratingNum">{hotelsResort?.avg_Rating.rating}</Box>
                                          <Typography variant='caption' className='iGor_subTitle'>
                                            {`${hotelRating <= 2 ? t('Average') : hotelRating <= 4 && hotelRating >= 2 ? t('Good') : t('Excellent')}`}
                                            {/* ({hotels?.userReview ? `${hotels?.userReview?.count} ${t('reviews')}`  : 'No reviews'}) */}
                                          </Typography>
                                        </Box>
                                        <Box className="iGor_Travel">
                                          <img src={timeIcon} alt='timeIcon' />
                                          <Typography variant='caption' className='iGor_subTitle'>  {t('Travel until')}
                                            {formatedDate}
                                          </Typography>
                                        </Box>
                                        {/* {props.matches ? (<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                              <img src={mapIcon} />
                              <Typography variant='caption' component={'p'} lineHeight={'1'} sx={{
                                  whiteSpace: 'nowrap', overflow: 'hidden',
                                  textOverflow: 'ellipsis'
                              }}>{offersHotels?.address}</Typography>
                          </Box>) : ('')} */}
                                        {
                                          <Box className="iGor_CardBtnSection">
                                            <Box>
                                              {/* <Typography variant='body1' className='iGor_subTitle'>{t('Starting from')}</Typography> */}
                                              <Box className='iGor_cardPrice'>
                                                {/* <Typography variant='h3'>{offersHotels?.listPrice?.currencySymbol} {offersHotels?.listPrice?.minPrice ? offersHotels?.listPrice?.minPrice?.toFixed() : ''}</Typography> */}
                                              </Box>
                                            </Box>
                                            <Button variant='contained' className='iGor_cardBtn iGOr_mb-D-none '
                                              onClick={(e) => offersCheckoutHandler(hotelsResort)}
                                            >
                                              Check Availability
                                            </Button>
                                          </Box>
                                        }
                                      </Box>
                                    </Box>

                                  </Box>
                                </Box>
                              )
                            })
                              : tabTypeSelected == 'VIP' ? latestVipHotelsToExplore.map((vipHotels: any) => {
                                const hotelRating = vipHotels?.avg_Rating?.rating ? vipHotels?.avg_Rating?.rating : 0;
                                const imageUrl: any = cacheImages(`${imagePath}${vipHotels?.img?.pathImg}`)
                                return (
                                  <Box onClick={matches ? (e) => offersCheckoutHandler(vipHotels) : () => { }}>
                                    <Box className="iGoR_roomCard" key={vipHotels?.id}
                                    // onClick={() => matches && onRoomDetailHandler(offersHotels?.id, offersHotels?.image)}
                                    >
                                      <Box className="iGoR_roomCardInner">
                                        <Box className='iGor-CardLeft'>
                                          {!imageLoaded && (<Skeleton variant="rectangular" height={matches ? 110 : 151} animation="wave" sx={{ borderRadius: '0.625rem' }} />)}

                                          <img src={vipHotels?.img.pathImg ? imageUrl.src : NoHotelsImg} className='iGor_cardImg' alt='img not '
                                            onError={(e: any) => {
                                              // Handle the image load error by setting the source to the default image
                                              e.target.src = NoHotelsImg;
                                            }}
                                            style={{
                                              display: imageLoaded ? 'block' : 'none'
                                            }}
                                            onLoad={handleImageLoad}
                                          />
                                          <Box className='iGor_cardBtnSection iGor_setFlex iGor_justifyBetween'>

                                            {/* <IconButton
                        className={hotels?.isWishlisted ? ' iGor_bgWhite iGor_cardLeftBtn' : 'iGor_cardLeftBtn'} >
                        {hotels?.isWishlisted ?
                          (<FavoriteIcon sx={{ color: '#FF6B6B' }}
                            onClick={(e) => addToWishListHandler(offersHotels, typeOfferHotels, index, offersHotels?.wish == true ? false : true)}
                            className='iGor_heartColour' />)
                          :
                          (<FavoriteBorderIcon
                            onClick={(e) => addToWishListHandler(offersHotels, typeOfferHotels, index, offersHotels?.wish == true ? false : true)}
                            className='iGor_heartColour'
                          />)
                        }
                      </IconButton> */}
                                            {/* <IconButton className='iGor_cardLeftBtn iGOr_mb-D-none' 
                                onClick={(e) => hotelImageSelectionHandler(offersHotels)}
                                >
                                    <RemoveRedEyeOutlinedIcon />
                                </IconButton> */}
                                          </Box>
                                        </Box>
                                        <Box className='iGor-CardRight' onClick={() => offerClickHandler(vipHotels)}>
                                          <Typography variant="h4" className='iGor__HotelName'>{vipHotels?.name} </Typography>
                                          <Box className='iGor-ratingSection'>
                                            <Typography variant='caption'>{vipHotels?.propertyType ? vipHotels?.propertyType : 'Offers'}</Typography>
                                            <Box className='iGor-rating'>
                                              {Array.from({ length: vipHotels?.avg_Rating.rating || 0 }, (_, index) => (
                                                <StarIcon key={index} className='iGor_starIcon' />
                                              ))}
                                              {Array.from({ length: 5 - vipHotels?.avg_Rating.rating || 0 }, (_, index) => (
                                                <StarIcon key={index} className='iGor_starIcon_not_rating' />
                                              ))}
                                            </Box>
                                            <Box className="iGor_ratingNum">{vipHotels?.avg_Rating.rating}</Box>
                                            <Typography variant='caption' className='iGor_subTitle'>
                                              {`${hotelRating <= 2 ? t('Average') : hotelRating <= 4 && hotelRating >= 2 ? t('Good') : t('Excellent')}`}
                                              {/* ({hotels?.userReview ? `${hotels?.userReview?.count} ${t('reviews')}`  : 'No reviews'}) */}
                                            </Typography>
                                          </Box>
                                          <Box className="iGor_Travel">
                                            <img src={timeIcon} alt='timeIcon' />
                                            <Typography variant='caption' className='iGor_subTitle'>  {t('Travel until')}
                                              {formatedDate}
                                            </Typography>
                                          </Box>
                                          {/* {props.matches ? (<Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                                <img src={mapIcon} />
                                <Typography variant='caption' component={'p'} lineHeight={'1'} sx={{
                                    whiteSpace: 'nowrap', overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}>{offersHotels?.address}</Typography>
                            </Box>) : ('')} */}
                                          {
                                            <Box className="iGor_CardBtnSection">
                                              <Box>
                                                {/* <Typography variant='body1' className='iGor_subTitle'>{t('Starting from')}</Typography> */}
                                                <Box className='iGor_cardPrice'>
                                                  {/* <Typography variant='h3'>{offersHotels?.listPrice?.currencySymbol} {offersHotels?.listPrice?.minPrice ? offersHotels?.listPrice?.minPrice?.toFixed() : ''}</Typography> */}
                                                </Box>
                                              </Box>
                                              <Button variant='contained' className='iGor_cardBtn iGOr_mb-D-none '
                                                onClick={(e) => offersCheckoutHandler(vipHotels)}
                                              >
                                                Check Availability
                                              </Button>
                                            </Box>
                                          }
                                        </Box>
                                      </Box>

                                    </Box>
                                  </Box>
                                )
                              })
                                : ''
                  : (getHotelsToExploreOffersApiLoading == true ?
                    (<>
                      <HotelresultSkeleton />
                      <HotelresultSkeleton />
                      <HotelresultSkeleton />
                    </>) : <Box className='iGor-NoDataOffersSection'>
                      {t('5004')}
                    </Box>)}
              </Box>)}
          </Box>
        </Grid>
        {getHotelsToExploreOffersApiLoading == true ? (<VRandMapSectionSkeleton />) : (
          <Grid item lg={6} sm={12} xs={12}>
            <Box className={'Igor_MapSectionOffers ' + (!swipeMb ? ' iGor__mbHeight100' : 'iGor__mbHeight40')} >
              {mapError == false ? (<LoadScript googleMapsApiKey={mapkey}
                onLoad={() => setMapLoaded(true)}
                onError={() => setMapError(true)}
              >
                <GoogleMapForOfferHotels
                  {...memoizedProps}
                />
              </LoadScript>) : (<Box>{t('5015')}</Box>)}
            </Box>
          </Grid>)}
      </Grid>
    </Container>
  </>
  )
}
