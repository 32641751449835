import { applyFiltersPayloadInterface } from "../utils/constants/types";

const applyFiltersPayload = (userId: number, propertyTypeSelected: any, roomTypeSelected: any, minPriceSelected: number, maxPrice: number, searchId: any) => {
  const payload: applyFiltersPayloadInterface = {
    appliedFilters: {
      userid: userId,
      propertytype: propertyTypeSelected,
      roomtype: roomTypeSelected,
      minprice: minPriceSelected,
      maxprice: maxPrice
    },
    searchId: searchId
  }
  return payload
}

export default applyFiltersPayload