// IconMapping.js
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

// Add all icons to the library for easier use
library.add(fas, far, fab);

const iconMapping: any = {
  'fa-solid': fas,
  'fa-regular': far,
  'fa-brands': fab,
};

export type IconName = string;

export { iconMapping };
