import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import verifiImg from '../../assets/images/verificationImg (1).jpg';
import verifiImg2 from '../../assets/images/verificationImg (1).png';
import verifiImg3 from '../../assets/images/verificationImg (2).png';
import verifiImg4 from '../../assets/images/verificationImg2 (1).png';
import verifiImg5 from '../../assets/images/verificationImg2 (2).png';
import verifiImg6 from '../../assets/images/verificationImg2 (3).png';
import circleCheckIcon from '../../assets/icons/circle-check-Icon.svg';
import { useAppSelector } from '../../appStore/hooks';
import { setAlertBoxOnError, setErrorMessageForDialogueBox, setGateWaysAndHolidayMakerPopup, setGmailVerificationPopUp, setIsRegisterPopUp, setIsVerificationPopUp, setOTPSentTimer, setSentOTPPhoneDataReset, setUserActionLoader, setVerifyOTPPhoneDataReset } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import GateWaysAndHolidayMaker from './GateWaysAndHolidayMaker';
import UserRegisterWelcome from './UserRegisterWelcome';
import { getOTPForPhone, sendOTPForPhone } from '../../utils/constants/types';
import { useSendOTPPhoneMutation, useVerifyOTPForPhoneMutation } from '../../services/api/hotelApi';
import { ToastContainer } from 'react-toastify';
import EmailOTPVerification from './EmailOTPVerificationScreen';
import { environmentVariables } from '../../utils/config/environmentVariables';
import OTPTimer from './OTPTimer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const VerificationCodeInput = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [verificationCodeLength, setVerificationCodeInputLength] = useState(6);
  const [verificationCode, setVerificationCode] = useState(['']);
  const [inValidOTP, setInValidOTP] = useState(false)
  const [resendOTPLimmit, setResendOTPLimmit] = useState(0);
  const [resendOTP, setResendOTP] = useState(false);
  const [checkVerificationCode, setCheckVerificationCode] = useState(1)
  const [selectedDiv, setSelectedDiv] = useState(null);
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const firstNameRegisteredUser = useAppSelector(state => state.hotel.setRegisteredUserDetails.userDetail?.firstName);
  const lastNameRegisteredUser = useAppSelector(state => state.hotel.setRegisteredUserDetails.userDetail?.LastName);
  const verficationPopup = useAppSelector(state => state.hotel?.verificationPopup)
  const { userPhoneNumber, registerUserCountryCall, gateWayAndHolidayMakerPopup, userRegisterEmailAddress,
    userRegisterWelcomePopup, gmailVerificationPopup, sentOTPForPhoneData, verifyOTPForPhoneData,
    sentOTPTimer, userActionLoader } = useAppSelector(state => state.hotel);
  const sentOTPMinutes = sentOTPTimer?.initialMinutes;
  const sentOTPSeconds = sentOTPTimer?.initialSeconds;

  const sidForOTPVerifyPhone = sentOTPForPhoneData?.otpResponse?.sid ? sentOTPForPhoneData?.otpResponse?.sid : '';
  const phoneVerificationApiStatus = verifyOTPForPhoneData?.statusCode ? verifyOTPForPhoneData?.statusCode : '';
  const phoneVerifationStatus = verifyOTPForPhoneData?.oTPVerifResp?.status ? verifyOTPForPhoneData?.oTPVerifResp?.status : '';
  const phoneVerifationMessage = verifyOTPForPhoneData?.message ? verifyOTPForPhoneData?.message : '';

  const IdentifierTypeForVerifyOTP = 'Phone'
  const countryCallingId = registerUserCountryCall?.countryCallingId;
  const phoneNumberForVerification = `+${countryCallingId}${userPhoneNumber}`
  const verficationCodeForPhone = `${verificationCode.join('')}`;
  const emailForVerification = userRegisterEmailAddress ? userRegisterEmailAddress : '';
  const enviornment = environmentVariables.enviornment;
  const actionLoader = userActionLoader;

  // send OTP to phone api initialize
  const [sendOTP] = useSendOTPPhoneMutation();

  // verify otp mutation
  const [verifyOTPPhone] = useVerifyOTPForPhoneMutation();

  // phone otp verification handler
  const verifyOTPForPhoneHandler = () => {
    dispatch(setUserActionLoader({ action: 'verifyOTP', loading: true }))
    const payload: getOTPForPhone = {
      Identifier: phoneNumberForVerification,
      IdentifierType: IdentifierTypeForVerifyOTP,
      verificationCode: verficationCodeForPhone,
      sid: `${sidForOTPVerifyPhone}`
    }
    if (verficationCodeForPhone.trim().length == 6) {
      if (enviornment !== 'development') {
        verifyOTPPhone({ payload })
      }
      else {
        dispatch(setOTPSentTimer({ initialMinutes: 2, initialSeconds: 30 }))
        dispatch(setGmailVerificationPopUp(true))
        dispatch(setIsVerificationPopUp(false))
        dispatch(setUserActionLoader({ action: '', loading: false }))
        // sentOTPToEmailHandler()
      }
    }
    else if (verficationCodeForPhone.trim().length < 6) {
      dispatch(setUserActionLoader({ action: '', loading: false }))
      setInValidOTP(true)
    }
  }

  // send otp api call for email
  const sentOTPToEmailHandler = () => {
    if (emailForVerification != '') {
      const payload: sendOTPForPhone = {
        Identifier: [emailForVerification],
        IdentifierType: 'Email'
      }
      sendOTP({ payload })
    }
  }

  // send otp api call for mobile
  const resendOTPToUserPhoneNumber = () => {
    setResendOTPLimmit(resendOTPLimmit + 1)
    if (resendOTPLimmit < 3) {
      const payload: sendOTPForPhone = {
        Identifier: [phoneNumberForVerification],
        IdentifierType: 'Phone'
      }
      // sendOTP({ payload })
      setResendOTP(false)
      dispatch(setOTPSentTimer({ initialMinutes: 2, initialSeconds: 30 }))
    }
  }

  // navigating gmail verification
  useEffect(() => {
    if (phoneVerificationApiStatus == 'OK' && phoneVerifationMessage == 'User mobile verification done' && phoneVerifationStatus == 'approved') {
      dispatch(setSentOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
      dispatch(setVerifyOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
      sentOTPToEmailHandler()
      dispatch(setUserActionLoader({ action: '', loading: false }))
      dispatch(setGmailVerificationPopUp(true))
      dispatch(setIsVerificationPopUp(false))
    }
    else if (phoneVerificationApiStatus == 'OK' && !phoneVerifationMessage.includes('User mobile verification done') && !phoneVerifationMessage.includes('User email verification done')) {
      dispatch(setUserActionLoader({ action: '', loading: false }))
      dispatch(setErrorMessageForDialogueBox(phoneVerifationMessage))
      dispatch(setAlertBoxOnError(true))
      dispatch(setVerifyOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
    }
    else if (phoneVerificationApiStatus == 'BadRequest') {
      dispatch(setUserActionLoader({ action: '', loading: false }))
      dispatch(setErrorMessageForDialogueBox(phoneVerifationMessage))
      dispatch(setAlertBoxOnError(true))
      dispatch(setVerifyOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
    }
    if (sentOTPMinutes == 0 && sentOTPSeconds == 0) {
      setResendOTP(true)
    }
  }, [phoneVerificationApiStatus, phoneVerifationMessage, sentOTPMinutes, sentOTPSeconds])

  // Function to handle selected  
  const handleDivClick = (divNumber: any) => {
    setSelectedDiv(divNumber);
  };

  // Function to handle code changes
  const handleCodeChange = (index: number, event: any) => {
    setInValidOTP(false)
    const code = event.target.value; // Limit code length to 1 character
    setVerificationCode((prevCodes) => {
      const newCodes = [...prevCodes];
      newCodes[index] = code;
      return newCodes;
    });

    // Move focus to the next input field (if available)
    if (code && index < 5) {
      inputsRef.current[index + 1].focus();
    } else if (event.target.value === '' && index > 0) {
      // Check if Backspace was pressed (value is empty)
      event.preventDefault(); // Prevent the default Backspace behavior
      inputsRef.current[index - 1].focus(); // Move focus to the previous input field
    }

  };
  AppLogHandler('Verification Code input component', `divNumber:${gmailVerificationPopup},verificationCode:${gateWayAndHolidayMakerPopup}`)
  return (
    <>
      <Box>
        {verficationPopup &&
          <>
            <Box sx={{ borderBottom: '1px solid #E5E5E5' }}>
              <Typography align='center' variant="subtitle1" sx={{ padding: '22px  0px 22px 0px' }}>
                {checkVerificationCode == 1 && t('Input verification code')}
              </Typography>
            </Box>
            <Box sx={{ padding: '0.938rem 1.25rem  0.313rem' }}>
              {checkVerificationCode == 1 &&
                <>
                  <Typography align='center' sx={{ fontSize: '8px', color: '#757575', fontWeight: '500' }} >
                    {t('We’ve sent you verification 6 digit code by Phone. Please input code here')}
                  </Typography>
                  <Box className='iGor_setFlex iGor_justifyCenter '>
                    {[...Array(verificationCodeLength)].map((code, index) => (
                      <input
                        key={index}
                        type="text" // Use type="number" if you prefer a numeric keypad on mobile devices
                        name={`verificationCode${index}`}
                        value={code}
                        autoComplete="off"
                        onChange={(event) => handleCodeChange(index, event)}
                        maxLength={1}
                        ref={(input) => (inputsRef.current[index] = input!)}
                        className='iGor_verCodeinput'
                      />
                    ))}
                  </Box>
                  {inValidOTP ? (<Typography align='center' sx={{ fontSize: '12px', color: 'red' }}>Please Input Valid OTP</Typography>) : (<></>)}
                  <Button
                    variant="contained"
                    sx={{ margin: '10px 0' }}
                    onClick={verifyOTPForPhoneHandler}
                    disabled={actionLoader?.loading == true ? true : false}
                  >
                    {actionLoader?.action == 'verifyOTP' && actionLoader?.loading == true ? <Box className='iGor-action_loader'>
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </Box> :
                      'Continue'}
                  </Button>
                  <Box className={`iGor-resendOTP_box ${resendOTP == true ? 'increase_height' : ''}`}>
                    <Typography align='center' sx={{ marginRight: '2px' }}>
                      {t('Didn’t get any code?')}
                    </Typography>
                    {resendOTP == true ? (<Typography
                      style={{ cursor: resendOTP == true ? 'pointer' : 'unset', textDecoration: 'underline' }}
                      onClick={resendOTP ? resendOTPToUserPhoneNumber : () => { }}
                    >
                      Resend verification
                    </Typography>) : (<OTPTimer />)}
                  </Box>
                  {resendOTPLimmit >= 3 ? (<Typography align='center' sx={{ fontSize: '8px', color: 'red ' }} marginBottom={1}>
                    {t('OTP Resend limit exceed...!')}
                  </Typography>) : ''}
                </>
              }
            </Box>
          </>}

        {gmailVerificationPopup && <EmailOTPVerification />}
        {gateWayAndHolidayMakerPopup && <GateWaysAndHolidayMaker />}
        {/* {checkVerificationCode == 2 && <>
          <Box>
            <Box className="iGor_setFlex iGor_verifiImg">
              <Box className={`iGor_verifCardImg ${selectedDiv === 1 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(1)}>
                <img src={verifiImg} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>

              <Box className={`iGor_verifCardImg ${selectedDiv === 2 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(2)}>
                <img src={verifiImg2} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>

              <Box className={`iGor_verifCardImg ${selectedDiv === 3 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(3)}>
                <img src={verifiImg3} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>

            </Box>
            <Box className="iGor_setFlex iGor_verifiImg">
              <Box className={`iGor_verifCardImg ${selectedDiv === 4 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(4)}>
                <img src={verifiImg} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>

              <Box className={`iGor_verifCardImg ${selectedDiv === 5 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(5)}>
                <img src={verifiImg2} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>

              <Box className={`iGor_verifCardImg ${selectedDiv === 6 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(6)}>
                <img src={verifiImg3} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>

            </Box>
          </Box>

          <Button variant="contained" sx={{ margin: '10px 0' }} onClick={() => { handleVerificationCode(3) }}>
            Continue
          </Button>
          <Button fullWidth sx={{ margin: '10px 0' }} onClick={() => { handleVerificationCode(3) }}>
            Skip
          </Button>
        </>} */
        }




        {/* {checkVerificationCode == 3 && <>
          <Box>

            <Box className="iGor_setFlex iGor_verifiImg">
              <Box className={`iGor_verifCardImg ${selectedDiv === 4 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(4)}>
                <img src={verifiImg4} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>

              <Box className={`iGor_verifCardImg ${selectedDiv === 5 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(5)}>
                <img src={verifiImg5} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>

              <Box className={`iGor_verifCardImg ${selectedDiv === 6 ? 'iGor_SelImg' : ''}`} onClick={() => handleDivClick(6)}>
                <img src={verifiImg6} alt='verifiImg1' />
                <Box className='iGor_verifiTextOver' >
                  <Typography variant='body1' >Adventure</Typography>
                </Box>
                <img className='circleCheckIcon' src={circleCheckIcon} alt='circleCheckIcon' />
              </Box>
            </Box>
          </Box>
          <Button type="submit" variant="contained" sx={{ margin: '10px' }} onClick={() => { handleVerificationCode(4) }}>
            Continue
          </Button>
        </>} */}
        {/* {checkVerificationCode == 4 && firstNameRegisteredUser !== '' && lastNameRegisteredUser !== '' ? (<>
          <Box className="iGor-Text-center">
            <Box className='iGor_userProfile'>
              <img src={userProfileIcon} alt='userProfileIcon' />
            </Box>
            <Typography >{firstNameRegisteredUser} {lastNameRegisteredUser}</Typography>
            <Typography variant='h1' sx={{ margin: '10px' }}>Welcome to Igoroom <br />{firstNameRegisteredUser} {lastNameRegisteredUser}!</Typography>
            <Typography variant='caption'>Explore your favourite trip in a different way. Discover <br />thousand accomodations, destinations and so much more <br /> with one platform.</Typography>
            <Button type="submit" variant="contained" sx={{ margin: '10px' }} onClick={() => { handleVerificationCode(20) }}>
              Explore Now
            </Button>
          </Box>

        </>) : ('')
        } */}
        {userRegisterWelcomePopup && <UserRegisterWelcome />}
        <ToastContainer />
      </Box>
    </ >
  )
}
