import React from 'react';
import { Modal, Box, IconButton, Button, Typography } from "@mui/material";
import maiLIcon from '../../assets/icons/maiLIcon.svg';
import { RegisterAccount } from '../userDetailsFrom/RegisterAccount';
import { Close } from '@mui/icons-material';
import IgoRoomLogo from '../../assets/icons/iGoRoom_icons.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from "react-router";
import { useAppSelector } from '../../appStore/hooks';
import {
  setAuthenticationType,
  setCountryNumCodeForApi,
  setEmailAuthenticationUserData,
  setExistingUserEmailStatusReset,
  setGateWaysAndHolidayMakerPopup,
  setSocialMediaAuthenticationUserData,
  setGmailVerificationPopUp,
  setInputValidatorRegisterUser,
  setIsRegisterPopUp,
  setIsVerificationPopUp,
  setLoginUserEmailAddress,
  setMainPopupForRegisterUser,
  setRegisterUserEmailAddress,
  setRegisterUserStatus,
  setResetRoomArray,
  setSentOTPPhoneDataReset,
  setShowDate,
  setSignInCallApi,
  setUserBirthDate,
  setUserLoginPassword,
  setUserRegisterWelcomePopup,
  setVerifyOTPPhoneDataReset,
  setRegisterUserCurrencyId,
  setFirstNameUser,
  setLastNameUser,
  setUserPhoneNumber,
  setCountryCallingIDData,
  setAllowMoeNotification,
  setUserActionLoader
} from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { VerificationCodeInput } from '../userDetailsFrom/VerificationCodeInput';
import { authenticationTypeForApp } from '../../utils/constants/constant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';




const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  maxWidth: '597px',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#F1F5F9',
  borderRadius: '10px',

};

export default function RegisterAccountMod(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matches = useMediaQuery('(max-width:1024px)');
  const { setRegisterPopUp, mainPopupForRegisterUser, authenticationType, gmailVerificationPopup }: any = useAppSelector(state => state.hotel);
  const newUserCreatedMEssage: any = useAppSelector(state => state.hotel.setRegisteredUserDetails.message)
  const emailAuthentication = authenticationTypeForApp.emailAuth;

  const handleOpen = () => {
    dispatch(setMainPopupForRegisterUser(true))
    dispatch(setIsRegisterPopUp(true))
    dispatch(setIsVerificationPopUp(false))
    // dispatch(setShowDate(true))
    dispatch(setAuthenticationType(emailAuthentication))
  }
  const home = () => {
    navigate('/')
    dispatch(setResetRoomArray())
  };

  const handleClose = () => {
    dispatch(setUserActionLoader({ action: '', loading: false }))
    dispatch(setMainPopupForRegisterUser(false))
    sessionStorage.clear()
    if (authenticationType != emailAuthentication) {
      dispatch(setRegisterUserEmailAddress(''))
      dispatch(setUserBirthDate(''))
      dispatch(setRegisterUserCurrencyId({ currencyId: 0, currencyCode: '' }))
      dispatch(setFirstNameUser(''))
      dispatch(setLastNameUser(''))
      dispatch(setUserPhoneNumber(''))
      dispatch(setCountryCallingIDData({
        countryCallingId: '91',
        countryId: 101,
        countryCallCodeLabel: ''
      }))
    }
    if (gmailVerificationPopup == true) {
      dispatch(setVerifyOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
    }
    dispatch(setIsRegisterPopUp(false))
    dispatch(setEmailAuthenticationUserData(''))
    dispatch(setSocialMediaAuthenticationUserData(''))
    dispatch(setLoginUserEmailAddress(''))
    dispatch(setUserLoginPassword(''))
    dispatch(setAuthenticationType(''))
    // dispatch(setRegisterUserEmailAddress(''))
    // dispatch(setUserBirthDate(''))
    // dispatch(setRegisterUserCurrencyId({ currencyId: 0, currencyCode: '' }))
    dispatch(setCountryNumCodeForApi(101))
    dispatch(setSignInCallApi(false))
    dispatch(setInputValidatorRegisterUser(false))
    dispatch(setRegisterUserStatus(false))
    dispatch(setIsVerificationPopUp(false))
    dispatch(setGmailVerificationPopUp(false))
    dispatch(setGateWaysAndHolidayMakerPopup(false))
    dispatch(setUserRegisterWelcomePopup(false))
    dispatch(setMainPopupForRegisterUser(false))
    dispatch(setSentOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
    dispatch(setVerifyOTPPhoneDataReset({ message: '', oTPVerifResp: {}, statusCode: '' }))
    dispatch(setExistingUserEmailStatusReset({ data: [''], loading: false, error: null, }))
    dispatch(setAllowMoeNotification(null))

    if (newUserCreatedMEssage == 'User Created Successfully.') {
      dispatch(setSignInCallApi(true))
    }
  }

  // back to register popup handler
  const backToRegisterPopupHandler = () => {
    dispatch(setVerifyOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
    dispatch(setIsVerificationPopUp(false))
    dispatch(setGmailVerificationPopUp(false))
    dispatch(setIsRegisterPopUp(true))
  }

  return (
    <>
      {props.setBTn === 'iGoR_Btn' ?
        <Button variant="contained" onClick={handleOpen} className='iGor__CreateAccountBtn' >Create an Account</Button> :
        <IconButton onClick={(e) => { handleOpen(), props?.registerEnableHandler() }} className="iGor_MB_loginBtn-Email iGor_MB_loginBtn iGor_addmb">
          <img src={maiLIcon} alt="logingUser" /> {matches && `Register with Email`}
        </IconButton>}

      <Modal
        open={mainPopupForRegisterUser}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className={setRegisterPopUp ? "iGr__RegAccountPopup" : "iGr__VerifictionPopup"}
      >
        <>
          <Box sx={style} className={setRegisterPopUp ? "iGor_Modal iGor__addPadding iGr__RegAccount " : "iGor_Modal iGor__VerificationBg"}>

            {gmailVerificationPopup ? <ArrowBackIcon className='iGor__arrowBackBtn' onClick={backToRegisterPopupHandler} /> :
              <div className={gmailVerificationPopup ? 'iGor__arrowBackBtn' : 'modal-close iGor__regiterClose'}>
                <IconButton onClick={handleClose}  >
                  <Close />
                </IconButton>
              </div>
            }
            {matches &&
              <>
                <Box sx={{
                  textAlign: 'center',
                  height: '12%',
                  paddingTop: '8%'
                }}>
                  <img src={IgoRoomLogo} alt="IgoRoomLogo" onClick={home} />
                </Box>
                <Box className='iGor-Text-center'>
                  <Typography fontSize={'24px'} fontWeight={600} mt={3}>Let’s get start</Typography>
                  <Typography fontSize={'12px'} color={'#757575'} mb={2}> Sign up to explore all the valueable features</Typography>
                </Box>

              </>
            }
            {setRegisterPopUp ? (<RegisterAccount />) : (<VerificationCodeInput />)}




            {matches &&
              <Box className='iGor-Text-center'>
                <Typography fontSize={'12px'} mt={2} > By continuing you agree to our</Typography>
                <a href={'/terms-conditions'} target='_blank' className='iGor__TeramAndConLinkTow'><Typography fontSize={'12px'} fontWeight={600}  > {t('terms & conditions')}</Typography></a>
              </Box>
            }
          </Box>
        </>
      </Modal >
    </>
  );
}