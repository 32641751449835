import { confirmPaymentPayload } from "../utils/constants/types"

const confirmPaymentPayloadData = (passengers: any, contact: any, gstDetails: any, roomPaxMapping: any, cardPayment: any,
    PaymentId: any, GatewayType: any, OrderId: any, useraccountemail: any, priceId: any, paidAmountRounded: any) => {

    const payload: confirmPaymentPayload = {
        hotelBookingDetails: {
            passengers: passengers,
            contact: contact,
            gstDetails: gstDetails,
            roomPaxMapping: roomPaxMapping,
            cardPayment: cardPayment,
            PaymentId: PaymentId,
            GatewayType: GatewayType,
            OrderId: OrderId,
            useraccountemail: useraccountemail,
            specialRequest: [],
            paidAmount: paidAmountRounded
        },
        priceId: priceId
    }
    return payload

}

export default confirmPaymentPayloadData