import React from 'react'
import { Skeleton } from '@mui/material'
import { Button, Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

export const PropDertailsCardsSkeleton = () => {
    return (
        <>

            <Box className="iGOr_card-listing">
                <Box className="top">

                    <Box className="image" padding={2}>
                    <Skeleton variant="rounded" width={'97%'} height={'97%'} />


                    </Box>
                    

                    <Box className="description">
                        <Box className="head">
                            <Box className="title">
                                <Skeleton variant="text" width={'100%'} />
                                <Skeleton variant="text" width={210} /> 
                               
                            </Box >
                            <Box className="price">
                                <Skeleton variant="text" className="price-wrapper" width={50} />
                                <Skeleton variant="text" className="price-info" width={50}/>
                            </Box>
                        </Box >
                        <Box className="body">
                            <Box className="text-wrapper">
                            <Skeleton variant="rounded" width={'99%'} height={100} />
                                    <Skeleton variant="text" />
                                
                            </Box >
                            <Box className="icon-wrapper">
                                <Box className="icon-room"> <Skeleton animation="wave" width={15} />    <Skeleton variant="text" className="title" /></Box >
                                <Box className="icon-room"> <Skeleton animation="wave" width={15}/>    <Skeleton variant="text" className="title" /></Box >
                            </Box >

                        </Box >
                        <Box className="btn-wrapper">
                            <Skeleton width="95%" height={30}/>
                            <Box className="accordion-head"   >
                                <Skeleton variant="text" className="title" />
                            </Box>
                        </Box >
                    </Box >
                    
                </Box >


            </Box >
        </>
    )
}
