import React from 'react'
import Header from '../../layouts/Header';
import { Button, Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { Link } from 'react-router-dom';
import BackArrow from '@mui/icons-material/KeyboardArrowLeft';

function FacebookDataPolicies() {
    return (<>
        <Box bgcolor={'#ffffff'} marginBottom={4}>
            <Header name='iGoRoomHeader' currClass='iGor_currColor' />
            <Button className='iGor__ProBackArrow'><Link to={'/'}><BackArrow /></Link></Button>
        </Box>
        <Container>
            <Typography fontSize={'1.75rem'} fontWeight={600}>Facebook Data Deletion Instructions</Typography>
            <Grid className='igo_facbookContent'>

                <h3>How to remove your data</h3><br />
                <p>Igoroom uses a facebook login app to authenticate our users. Igoroom does not save your personal data in our server.</p><br />
                <div>According to Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL.</div><br />
                <ul>If you want to delete your activities for Igoroom'S facebook login app, you can remove your information by following these steps:<br />
                    <li>Go to your Facebook Account’s “Settings & Privacy”.</li>
                    <li>Click “Settings”</li>
                    <li>Then click “Security and Login”.</li>
                    <li>Look for “Apps and Websites” and you will see all of the apps and websites you have linked with your Facebook account.</li>
                    <li>Search and Click “Igoroom” in the search bar.</li>
                    <li>Scroll and click “Remove”.</li>
                </ul>
            </Grid>
        </Container>
    </>);
}

export default FacebookDataPolicies;