import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Button, Grid, List, Popover, ListItemIcon, ListItem, ListItemButton, ListItemText } from "@mui/material";
import MytripCancellationModel from './MytripCancellationModel';
import FacebookIcon from '@mui/icons-material/Facebook';
import Icon_maps_pin from '../../assets/icons/Icon_maps-pin.svg';
import Icon_facebook from '../../assets/icons/Icon_facebook.svg';
import { FacebookShareButton } from 'react-share';


export default function MytripPopover({ booking }: any) {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'LoginAccount-popover' : undefined;
    const pathToShare = window.location.href;
    const bookingId = booking?.booking_id ? booking?.booking_id : "";
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const bookingStatus = booking?.status ? booking?.status : booking?.bookingStatus ? booking?.bookingStatus : ''

    //direct to the loation
    const moveToLocationGoogleMap = (coOrdinates: any) => {
        const coOrdinatesMap = coOrdinates;
        if (coOrdinatesMap?.latitude && coOrdinatesMap?.longitude) {
            window.open(`https://www.google.com/maps/search/?api=1&query=${coOrdinatesMap?.latitude},${coOrdinatesMap?.longitude}`);
        }
    }

    return (
        <>

            <Button sx={{ minWidth: '25px', height: '35px' }} className='iGor__ModelBtn' aria-describedby={id} onClick={handleClick} >
                <MoreVertIcon className='iGor__ModelBtn' />
            </Button >


            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className='iGor__MytripPopover'
                sx={{ right: '9%' }}
            >
                <Box sx={{ width: '200px' }}>
                    <List className='iGor_mytripPopoList'>
                        <ListItem disablePadding onClick={(e) => moveToLocationGoogleMap(booking)}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <img src={Icon_maps_pin} className='iGor__maps_pin' />
                                </ListItemIcon>
                                <ListItemText primary="Direct me to location" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <FacebookShareButton url={pathToShare}>
                                <ListItemButton>
                                    <ListItemIcon>
                                        <img src={Icon_facebook} />
                                    </ListItemIcon>
                                    <ListItemText primary="Share this to Facebook" />
                                </ListItemButton>
                            </FacebookShareButton>
                        </ListItem>
                        {bookingStatus == 'Confirmed' && <ListItem disablePadding>
                            <ListItemButton>
                                <MytripCancellationModel
                                    bookingId={bookingId}
                                />
                            </ListItemButton>
                        </ListItem>}
                    </List>
                </Box>
            </Popover>
        </>
    );
}
