import React from "react";
import { Helmet } from "react-helmet-async";

function SEOPropertyDetails({ title, description, name, type }: any) {
    return (<Helmet>
        { /* Standard metadata tags */}
        <title>{title}</title>
        <meta name="author" content="Igoroom"/>
        <meta name='description' content={description} />
        { /* End standard metadata tags */}
        { /* Facebook tags */}
        <meta property="og:type" content={type} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://igoroom-dev.firebaseapp.com/property-details/atlantis-the-palm-1"></meta>
        <meta property="og:image" content="https://api-test.igoroom.com/api/media?path=image/property-image/12/1673427033.jpg"></meta>
        { /* End Facebook tags */}
        { /* Twitter tags */}
        <meta name="twitter:creator" content={name} />
        <meta name="twitter:card" content={type} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        { /* End Twitter tags */}
    </Helmet>);
}

export default SEOPropertyDetails;