import React from 'react'
import { useAppSelector } from "../../appStore/hooks";
import { useAboutUsQuery } from "../../services/api/hotelApi";
import ReactParser from 'html-react-parser';
import Header from '../../layouts/Header';
import { Button, Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import { Link } from 'react-router-dom';
import BackArrow from '@mui/icons-material/KeyboardArrowLeft';

function AboutUS() {
    useAboutUsQuery('')
    const { aboutUSData } = useAppSelector(state => state.hotel)
    const aboutUSStaticDataForUi = aboutUSData
    return (<>
        <Box bgcolor={'#ffffff'} marginBottom={4}>
            <Header name='iGoRoomHeader' currClass='iGor_currColor' />
         <Button className='iGor__ProBackArrow'><Link to={'/'}><BackArrow /></Link></Button>

        </Box>
        <Container>
        <Typography fontSize={'1.75rem'} fontWeight={600}>About Us</Typography>
            <Grid className='iGor__pageslink' container >
                {ReactParser(aboutUSStaticDataForUi as any)}
            </Grid>
        </Container>
    </>)
}
export default AboutUS;