import { Grid, Typography } from '@mui/material'
import { Box, Container } from '@mui/system'
import RegionsBG from '../../assets/images/RegionsBG.png';
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';
import { useLocation, useNavigate, useParams } from 'react-router';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import layoutBg from '../../assets/images/homeLanding.jpg'
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchExploreRegion, setAlertBoxOnError, setErrorMessageForDialogueBox, setExploreRegionSelectedReset } from '../../appStore/hotelSlice';
import { useAppSelector } from '../../appStore/hooks';

const Regions = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const { exploreRegionSelected } = useAppSelector(state => state.hotel)
    const [exploreRegionData] = exploreRegionSelected?.data ? exploreRegionSelected?.data?.map((data: any) => data) : []
    const exploreRegion = params.slug ? params.slug : '';
    const { state } = useLocation();
    const slugReceived = state?.slug;
    const previousPathId = state?.prevPathid

    const regionDetails = exploreRegionData;

    const navigateToHome = () => {
        dispatch(setExploreRegionSelectedReset({ data: [], loading: false, error: null }))
        navigate(`/`)
    }

    const navigateBack = () => {
        navigate(`/explore/${previousPathId}`)
        dispatch(setExploreRegionSelectedReset({ data: [], loading: false, error: null }))
    }

    useEffect(() => {
        if (exploreRegion == slugReceived) {
            dispatch(fetchExploreRegion(exploreRegion))
        }
        else if (exploreRegion !== slugReceived) {
            dispatch(setErrorMessageForDialogueBox('33'))
            dispatch(setAlertBoxOnError(true))
            setTimeout(navigateToHome, 4000)
        }
    }, [exploreRegion])

    AppLogHandler('regionDetailsPage', state)
    return (
        <>
            <Box>
                <iframe src={regionDetails?.vt != '0' ? regionDetails?.vt : layoutBg} className='iGor__RegionIframe'></iframe>
                <Container >
                    {regionDetails?.vt == '0' ? (<Box className='iGor_RegionsTop' >
                        <Grid container>
                            <Grid item sm={4} textAlign={'left'}>
                                <ArrowBack className='iGor_backBtn' onClick={navigateBack} />
                            </Grid>
                            <Grid item sm={4}>
                                <Box textAlign={'center'} display={'flex'} height={'400px'} alignItems={'center'} justifyContent={'center'}>
                                    <h2>No region content found to explore for this region please try for another one...! for go back hit the back arrow</h2>
                                </Box>
                            </Grid>
                            <Grid item sm={4}></Grid>
                        </Grid>
                    </Box>) : (
                        <Box className='iGor_RegionsTop' >
                            <Grid container>
                                <ArrowBack className='iGor_backBtn' onClick={navigateBack} />
                            </Grid>
                        </Box>
                    )}
                    {/* <Box className='iGor_RegionsBottom'>
                        <Grid container>
                            <Grid item sm={12}>
                                <Typography >  TABANAN, BALI</Typography>
                                <Typography variant='h4' fontSize={'28px'}>Yeh Hoo Waterfall</Typography>
                                Find another destination in Bali, Indonesia
                            </Grid>
                        </Grid>
                    </Box> */}
                </Container>
            </Box >

        </>
    )
}

export default Regions