
import { envDev, firebaseConfigDev } from './appConfig.dev';
import { envUAT, firebaseConfigUAT } from './appConfig.uat';

// AppConfig for DEV
// export const env = envDev;
// export const firebaseConfig = firebaseConfigDev;

// AppConfig for UAT
export const env = envUAT;
export const firebaseConfig = firebaseConfigUAT;

