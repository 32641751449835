import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Button } from '@mui/base';
import { useDispatch } from 'react-redux';
import { setErrorMessageForDialogueBox, setAlertBoxOnError, setSessionExpired } from '../../appStore/hotelSlice';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
};

export default function ErrorShowMod({ message, open, setAlertOpen, setOpenOffersAlertBox, setOpenAlert, setAlertOpenOrderSum, setAlertOpenForgot, setAlertOpenLogin }: any) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const [openAlert, setOpen] = React.useState(open);
  React.useEffect(() => {
    setOpen(open)
  }, [open]);

  const closeAlertHanlder = () => {
    if (pathname.includes('/property-details') && message == '25') {
      dispatch(setSessionExpired(true))
    }
    dispatch(setAlertBoxOnError(false))
    dispatch(setErrorMessageForDialogueBox(''))
    if (pathname.includes('/confirm-payments')) {
      navigate('/order-summary')
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAlert}
        onClose={closeAlertHanlder}
        closeAfterTransition

      >
        <Fade in={openAlert}>
          <Box sx={style}>
            <CancelOutlinedIcon className='iGor__loadingIcon' sx={{ marginBottom: 'rem' }} />
            <Typography className='iGor__errorText' id="transition-modal-description">{t(message)}</Typography>
            <Button className='iGorClose_alert_button' onClick={closeAlertHanlder}>Close</Button>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
