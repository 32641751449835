import { useDispatch } from "react-redux";
import { setDecrementNights, setIncrementNights, setSearchDestinationToDate } from "../../appStore/hotelSlice";
import { CountInput } from "../inputFieldCustom/CountInput"
import { Box } from "@mui/system";
import { useAppSelector } from "../../appStore/hooks";
import { useTranslation } from 'react-i18next';
import { incrementDecrementConverter } from "../../utils/utilities/DateConverter";
const DestinationNightCount = ({ endDateForDestination, setEndDateForDestinationSearch,
     numberOfNights, setNumberOfNights }: any) => {
    const { t } = useTranslation();
    const searchDestinationToDate = endDateForDestination;
    const noOfNight = numberOfNights;

    const incrementNight = () => {
        const numOfNights = noOfNight + 1;
        if (setNumberOfNights) setNumberOfNights(numOfNights)
        countInputHanlerForDates(1)
    }

    const decrementNight = () => {
        if (noOfNight > 1) {
            const numOfNights = noOfNight - 1;
            if (setNumberOfNights) setNumberOfNights(numOfNights)
            countInputHanlerForDates(-1)
        }
    }

    // increment and decrement date count function
    const countInputHanlerForDates = (nightCounts: any) => {
        const getIncreAndDecreDate = incrementDecrementConverter(searchDestinationToDate, nightCounts)
        const formatedDateReducer = getIncreAndDecreDate.toISOString()
        if (setEndDateForDestinationSearch) setEndDateForDestinationSearch(formatedDateReducer)
    }

    return (
        <Box className="iGor_NightCount">
            {t("It will be")}
            <Box minWidth={'45%'}>
                <CountInput increment={incrementNight} decrement={decrementNight} count={noOfNight}/>
            </Box>
            {t("Nights")}
        </Box>
    )

}
export default DestinationNightCount