import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import Icon_info from '../../assets/icons/Icon_info.svg';
import convertDateFormat from '../../utils/utilities/DateConverter';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../appStore/hooks';
import { countryResidenceAndCulture } from '../../utils/constants/constant';
import { useGetUserNotificatiobsMutation } from '../../services/api/hotelApi';
import { useUpdateUserNotificationsMutation } from '../../services/api/hotelApi';
import { getNotificationsPayload } from '../../utils/constants/types';
import { Circle } from '@mui/icons-material';

const InboxUpdateCards = ({ userNotifications }: any) => {
    const { t } = useTranslation();
    const { loggedInUserCurrency } = useAppSelector(state => state.hotel)
    const userNotificationsArray = userNotifications ? userNotifications : [];
    const culture = countryResidenceAndCulture.culture;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;

    // get user notigification, messages and  update notifications api initialization
    const [getUserNotification] = useGetUserNotificatiobsMutation();
    const [updateNotification, apiStatus] = useUpdateUserNotificationsMutation();

    // message update hanlder
    const readNotificationClickHandler = (notificationId: any) => {
        const payload = {
            notificationId: notificationId
        }
        updateNotification({ payload })
    }

    // get Notification hanlder 
    const getNotificationsHanlder = () => {
        const payload: getNotificationsPayload = {
            userDetails: {
                userId: `${loggedInUserId}`,
                date: "",
                culture: culture
            }
        }
        getUserNotification({ payload })
    }


    useEffect(() => {
        if (apiStatus?.data?.statusCode == "OK" && apiStatus?.data?.message?.includes("Updated Successfully")) {
            getNotificationsHanlder()
        }
    }, [apiStatus.data])
    return (<>{userNotificationsArray.length > 0 ? userNotificationsArray.map((notification: any) => {
        return (<Box className="iGor_updatedCard" sx={{ paddingBottom: '5px', borderBottom: '1px solid #C4C4C4', marginBottom: '15px', pointerEvents: notification.isRead ? 'none' : 'auto' }} onClick={() => { readNotificationClickHandler(notification.id) }}>
            <Box className='iGor-messageUnread'>
                <Typography variant='body1'><img src={Icon_info} />  Info • {convertDateFormat(notification.created_at)}</Typography>
                {notification.isRead == false ? (<Circle />) : ''}
            </Box>
            <Box className="iGor__updatedImgBox">
                <img src={notification.imageUrl} alt='no image available' className="iGor__updatedImg" />
            </Box>
            <Typography fontWeight={600}>{notification.title}</Typography>
            <Typography>{notification.message}. </Typography>
        </Box>)
    }) : (<Box className="iGor_updatedCard" sx={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '60px' }}>
        <Typography fontWeight={600}>{t('5009')}</Typography>
    </Box>)}
    </>)
}

export default InboxUpdateCards;