import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Header from '../../layouts/Header'
import { Button, Grid, List, ListItemButton, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { FileUpload } from '../../components/AccountDetails/FileUpload';
import GoogleIcon from '@mui/icons-material/Google';
import { Email, Apple, Facebook } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { AcountEditModel } from '../../components/AccountDetails/AcountEditModel';
import { useAppSelector } from '../../appStore/hooks';
import { useNavigate } from 'react-router';
import { authenticationTypeForApp } from '../../utils/constants/constant';
import { AccountSideMenu } from '../../components/AccountDetails/AccountSideMenu';
import convertDateFormat from '../../utils/utilities/DateConverter';
import useMediaQuery from '@mui/material/useMediaQuery';
import tickIcon from '../../assets/icons/rightTickIcon.svg'




export const AccountDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [nameEditEnable, setNameEditEnable] = useState(false);
  const [currencyEditEnable, setCurrencyEditEnable] = useState(false);
  const [phoneEditEnable, setPhoneEditEnable] = useState(false);
  const [dobEditEnable, setDOBEditEnable] = useState(false);



  const { getUserDetails, setUserLoggedIn } = useAppSelector(state => state.hotel);
  const countriesCallingCodeData: any = useAppSelector(state => state.hotel?.countryCallingCodes?.countries);
  const userFirstName = getUserDetails?.data?.userDetail?.firstName ? getUserDetails?.data?.userDetail?.firstName : '';
  const userLastName = getUserDetails?.data?.userDetail?.LastName ? getUserDetails?.data?.userDetail?.LastName : '';
  const userBirthDate = getUserDetails?.data?.userDetail?.birthDate ? getUserDetails?.data?.userDetail?.birthDate : '';
  const userEmailAddress = getUserDetails?.data?.userDetail?.email ? getUserDetails?.data?.userDetail?.email : '';
  let loggedInWith = getUserDetails?.data?.userDetail?.loggedInWith ? getUserDetails?.data?.userDetail?.loggedInWith : '';
  const phoneNumber = getUserDetails?.data?.userDetail?.phone ? getUserDetails?.data?.userDetail?.phone : 'Add your emergency contact';
  let userCurrency = getUserDetails?.data?.userDetail?.currency_name ? getUserDetails?.data?.userDetail?.currency_name : 'Use recommended currency based on location';
  const userCountryId = getUserDetails?.data?.userDetail?.country_id ? getUserDetails?.data?.userDetail?.country_id : 'Add your country of origin'
  const emailAuthentication = authenticationTypeForApp.emailAuth;
  const gmailAuthentication = authenticationTypeForApp.gmailAuth;
  const appleAuthentication = authenticationTypeForApp.appleAuth;
  const facebookAuthentication = authenticationTypeForApp.facebook;
  const matches = useMediaQuery('(max-width:1024px)');
  const [userCountryFilterd] = countriesCallingCodeData.filter((country: any) => country.id == userCountryId);
  const loogedInUserCountry = userCountryFilterd?.name ? userCountryFilterd?.name : 'Indonesia'; 
  // date of birth format conversion
  // const convertDateFormat = (originalDate: any) => {
  //   const inputDate = new Date(originalDate);
  //   const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  //   const formattedDate: any = inputDate.toLocaleDateString('en-US', options);
  //   return formattedDate;
  // }
  const formatedDateToShow = convertDateFormat(userBirthDate);

  function editEnableHandler(editField: any) {
    switch (editField) {
      case 'Full Name':
        setNameEditEnable(true)
        setPhoneEditEnable(false)
        setDOBEditEnable(false)
        setCurrencyEditEnable(false)
        break;
      case 'Birthday':
        setDOBEditEnable(true)
        setNameEditEnable(false)
        setPhoneEditEnable(false)
        setCurrencyEditEnable(false)
        break;
      case 'Currency':
        setCurrencyEditEnable(true)
        setDOBEditEnable(false)
        setNameEditEnable(false)
        setPhoneEditEnable(false)
        break;
      // case 'Emergency contact':
      //   setPhoneEditEnable(true)
      //   break;
    }
  }

  const updateCurrencyHandler = () => {
    setCurrencyEditEnable(false)
  }

  // update enable call 
  const updateUserProfileHandler = () => {
    setNameEditEnable(false)
    setPhoneEditEnable(false)
    setDOBEditEnable(false)
  }

  useEffect(() => {
    userCurrency = getUserDetails?.data?.userDetail?.currency_name
  }, [getUserDetails?.data?.userDetail])


  // navigation function for terms and policy page
  const navigateToTermsAndPrivancy = (naivagateScreen: string) => {
    if (naivagateScreen == 'Terms') {
      navigate('/terms-conditions')
    }
    else if (naivagateScreen == 'Privacy') {
      navigate('/privacy-policy')
    }
  }

  const loggedinWithIcon = () => {
    if (loggedInWith == emailAuthentication) {
      loggedInWith = 'E-mail'
      return <Email className='svg1' />
    }
    else if (loggedInWith == gmailAuthentication) {
      loggedInWith = 'Google'
      return <GoogleIcon className='svg1' />
    }
    else if (loggedInWith == appleAuthentication) {
      return <Apple className='svg1' />
    }
    else if (loggedInWith == facebookAuthentication) {
      return <Facebook className='svg1' />
    }
  }

  useEffect(() => {
    if (setUserLoggedIn == false) {
      navigate('/')
    }
  }, [setUserLoggedIn])
  
  return (
    <>
      <Box sx={{ background: { xs: "", sm: "#ffffff" } }} ><Header name='iGoRoomHeader iGor__SecondMenuMB' currClass='iGor_currColor' /></Box>
      <Container>

        <Grid container spacing={2} marginY={2}>
          {/* <Grid item sm={3}>
            <Box className="iGor_bgWhite iGOr_BorderRadius15">
              <Box className=" iGoR_SideCard" borderBottom={'1px solid #C4C4C4'}>

                <Typography variant='h4'>{`${userFirstName} ${userLastName}`} </Typography>
                <Typography variant='body1' color={'#FF6B6B'}> Verified <img src={Icon_verify} alt='Icon_verify' /></Typography>


                <Box marginY={2}>
                  <img src={Icon_points} alt='Icon_points' />
                </Box>


                <Box className='iGor_setFlex iGor_justifyBetween'>
                  <Box>
                    <Typography variant='body2'>  Balance: 2,300 Points</Typography>
                    <Typography variant='body1' className='iGor_checkpoint'> Check your points details</Typography>
                  </Box>
                  <img src={Icon_RightArrow} alt='Icon_RightArrow' />
                </Box>
                <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                  <Box>
                    <img src={Icon_iGift} alt='Icon_points' />
                  </Box>
                  <Box>
                    <Typography variant='body1' fontWeight={'600'}>   Gift holiday voucher <br /> to your friends</Typography>
                  </Box>
                  <img src={Icon_RightArrow} alt='Icon_RightArrow' />
                </Box>
              </Box>
              <Box className=" iGoR_SideCard">


                <Typography variant='subtitle1'>  Personal Settings</Typography>
                <Box>
                  <List className='iGor_Accountlist'>
                    <ListItemButton sx={{ paddingLeft: '0' }}>
                      <ListItemIcon className='iGor_UserIcon'>
                        <img src={userOutline} className='iGor_userOutline' />
                      </ListItemIcon>
                      <ListItemText primary={t('Account Details')} className='iGor_UserTitle' />
                    </ListItemButton>
                    <ListItemButton sx={{ paddingLeft: '0' }}>
                      <ListItemIcon className='iGor_UserIcon'>
                        <img src={ticketIcon} className='ticketIcon' />
                      </ListItemIcon>
                      <ListItemText primary={t('My Trip')} className='iGor_UserTitle' />
                    </ListItemButton>
                    <ListItemButton sx={{ paddingLeft: '0' }}>
                      <ListItemIcon className='iGor_UserIcon'>
                        <FavoriteBorderIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('My Favourite')} className='iGor_UserTitle' />
                    </ListItemButton>
                    <ListItemButton sx={{ paddingLeft: '0' }}>
                      <ListItemIcon className='iGor_UserIcon'>
                        <img src={Icon_bell} alt='Icon_bell-notification' />
                      </ListItemIcon>
                      <ListItemText primary={t('Notifications & Permissions')} className='iGor_UserTitle' />
                    </ListItemButton>
                  </List>

                  <Box />

                  <Typography variant='subtitle1' marginBottom={2}>  Other Information</Typography>
                  <Typography variant='subtitle1' marginBottom={2} onClick= {(e)=>navigateToTermsAndPrivancy('Terms')}>  Terms and Conditions</Typography>
                  <Typography variant='subtitle1' marginBottom={2} onClick= {(e)=>navigateToTermsAndPrivancy('Privacy')}>  Privacy Policy</Typography>
                  <UserLogoutProfile/>
                </Box>
              </Box>
            </Box>
          </Grid> */}
          <AccountSideMenu />

          <Grid item lg={6} xs={12}>
            
          { matches &&  
          <Box my={1}>
          <Typography variant='subtitle1' fontSize={'24px'} className='iGor_AccountTitleMB'> Personal Details</Typography>
          </Box>
          }
            <Box className="iGor_bgWhite iGOr_BorderRadius15 iGoR_AccountCard ">
              <Typography variant='subtitle1' className='iGOr_mb-D-none'>  Account Details</Typography>
              <Typography variant='body1' className='iGOr_mb-D-none'>  You can set up your account details here.</Typography>
              <Box className='iGor_setFlex iGor_justifyBetween iGor_alignCenter iGOr_mb-D-none' marginY={1}>
                <FileUpload />
                <Button className='iGoR_AccGoogleBtn'>
                  {loggedinWithIcon()}
                  Logged in from {loggedInWith} 
                  <img src={tickIcon} className='svg2'/>
                  {/* <CheckIcon  /> */}
                </Button>
              </Box>

              <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'> Full Name </Typography>
                  {nameEditEnable ? "-->" : <Typography variant='caption'> {`${userFirstName} ${userLastName}`}</Typography>}
                </Box>
                <Box>
                  <AcountEditModel
                    updateUserProfileHandler={updateUserProfileHandler}
                    nameEditEnable={nameEditEnable}
                    editEnableHandler={editEnableHandler}
                    fieldType='Full Name'
                    fieldVal={{ userFirstName: userFirstName, userLastName: userLastName }} />
                </Box>
              </Box>

              {/* <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}> */}
                {/* <Box >
                  <Typography variant='body2'>  Gender</Typography>
                  <Typography variant='caption'> Not Available</Typography>
                </Box> */}
                {/* <Box> */}
                  {/* <AcountEditModel fieldType='Gender' fieldVal='Not Available'/> */}
                {/* </Box> */}
              {/* </Box> */}

              <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'>  Birthday</Typography>
                  {dobEditEnable ? "-->" :
                    <Typography variant='caption'>{formatedDateToShow}</Typography>}
                </Box>
                <Box>
                  <AcountEditModel
                    fieldType='Birthday'
                    fieldVal={formatedDateToShow}
                    updateUserProfileHandler={updateUserProfileHandler}
                    dobEditEnable={dobEditEnable}
                    editEnableHandler={editEnableHandler}
                  />

                </Box>
              </Box>

              <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'>Currency</Typography>
                  {currencyEditEnable ? "-->" : <Typography variant='caption'>{userCurrency}</Typography>}
                </Box>
                <Box width={"80%"} textAlign={'right'} className='iGor_currencyEdit'>
                  <AcountEditModel
                    currencyEditEnable={currencyEditEnable}
                    updateCurrencyHandler={updateCurrencyHandler}
                    editEnableHandler={editEnableHandler}
                    fieldType='Currency'
                    fieldVal={userCurrency} />
                </Box>
              </Box>
              {currencyEditEnable && <Box>
              </Box>}
              <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'> Country</Typography>
                  <Typography variant='caption'>{loogedInUserCountry}</Typography>
                </Box>
                <Box>
                  {/* <AcountEditModel fieldType='Country' fieldVal={userCountry}/> */}

                </Box>
              </Box>


              <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'> Email Address</Typography>
                  <Typography variant='caption'> {userEmailAddress}</Typography>
                </Box>
                <Box>
                  {/* <AcountEditModel fieldType='Email Address' fieldVal={userEmailAddress}/> */}

                </Box>
              </Box>


              {/* <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'> Reset Password</Typography>
                  <Typography variant='caption'>Feature not available</Typography>
                </Box>
                <Box>
                  <AcountEditModel fieldType='Reset Password' fieldVal=''/>

                </Box>
              </Box>

              <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'>  Legal Identity (Passport, ID Card, Driver License, Insurance)</Typography>
                  <Typography variant='caption'>Feature not available</Typography>
                </Box>
                <Box>
                  <AcountEditModel fieldType='Legal Identity ' fieldVal=''/>

                </Box>
              </Box>

              <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'> Address</Typography>
                  <Typography variant='caption'>Feature not available</Typography>
                </Box>
                <Box>
                  <AcountEditModel fieldType='Address' fieldVal=''/>

                </Box>
              </Box> */}

              <Box className='iGor_setFlex iGor_justifyBetween' marginY={2}>
                <Box >
                  <Typography variant='body2'> Emergency contact</Typography>
                  <Typography variant='caption'> {phoneNumber}</Typography>
                </Box>
                <Box>
                  {/* <AcountEditModel
                    fieldType='Emergency contact'
                    fieldVal=''
                    phoneEditEnable={phoneEditEnable}
                    editEnableHandler={editEnableHandler}
                    updateUserProfileHandler={updateUserProfileHandler}
                  /> */}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={3} className='iGOr_mb-D-none'>
            <Box className='iGor-identityBox'>
              <Box className="iGor_Number">
                <Typography variant='subtitle1'>1</Typography>
              </Box>
              <Typography variant='subtitle1'> Your personal information
                is secure with us</Typography>
              <Typography variant='body1' width={'80%'} >We’re hiding some account details to protect your identity.</Typography>
              <Box className="iGor_Number">
                <Typography variant='subtitle1'>2</Typography>
              </Box>
              <Typography variant='subtitle1'>    What info is shared with others?</Typography>
              <Typography variant='body1' width={'87%'} >  Igoroom only releases contact information between property and guests after a reservation is confirmed.</Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}