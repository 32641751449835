import { Box, Container, borderRadius, color, width } from '@mui/system';
import { Avatar, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography, Popover, Skeleton, useMediaQuery, Modal, Fade, TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { EventNamesForEventLogs, addHotelFavioratesType } from '../../utils/constants/constant';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../appStore/hooks';
import { updateUserHotelWishlistPayload } from '../../services/updateWishlist';
import { useCretateWishlistFolderMutation, useUpdateWishlistHotelsMutation } from '../../services/api/hotelApi';
import { useDispatch } from 'react-redux';
import { setAddTofavouriteListReset, setAlertBoxOnError, setCreateFolderListDataReset, setErrorMessageForDialogueBox, setHotelDetails, setRemoveFromFaviouratesReset } from '../../appStore/hotelSlice';
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import { createFolder } from '../../utils/constants/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


function AddToFaviourates({ faviourateType, hotelIdSelected, formatedFromDate, formatedToDate, AddtoWhishlishOpen,
    setAddtoWhishlishOpen, addToWishlistHandlerDataUpdate }: any) {
    const [folderName, setFolderName] = useState('');
    const [folderNameError, setFolderNameError] = useState(false);
    const dispatch = useDispatch();
    const [wishlistshow, setWishlistShow] = useState(false);
    const { userFavouritesFolderList, loggedInUserCurrency, userRemoveFromFaviourates, createUserFaviourateFolder, addTofavouriteList } = useAppSelector(state => state.hotel)
    const hotel = useAppSelector(state => state.hotel.hotelDetails?.data?.hotel)
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const eventNameAddWishlist = EventNamesForEventLogs.addToWishlist;
    const folderListUser = userFavouritesFolderList?.data ? userFavouritesFolderList?.data : [];
    const folderListLoading = userFavouritesFolderList?.loading ? userFavouritesFolderList?.loading : false;
    const folderCreatedData = createUserFaviourateFolder?.data;
    const folderCreatedMessage = createUserFaviourateFolder?.message;

    // update wish list api initialization
    const [updateWishlist, updateWishListResponse] = useUpdateWishlistHotelsMutation();

    //create folder api initialization
    const [createFolderApi] = useCretateWishlistFolderMutation();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '10px',
    };

    // add hotel wishlist successful and event log when hotel is added
    const addHotelWishlistApiSuccessHandler = () => {
        modifyHotelDetails(true)

        // event log to analytics
        const eventIdsForLogEvents = {
            ig_content_id: hotelIdSelected,
            ig_date_a: formatedFromDate,
            ig_date_b: formatedToDate
        }
        EventLogsHandler(eventNameAddWishlist, eventIdsForLogEvents)
    }

    useEffect(() => {
        if (addTofavouriteList?.message?.includes('Added to favourite successfully') && faviourateType == 'article') {
            if (addToWishlistHandlerDataUpdate) addToWishlistHandlerDataUpdate()
            dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
        }
        if (addTofavouriteList?.message?.includes('Added to favourite successfully') && faviourateType == 'property') {
            addHotelWishlistApiSuccessHandler()
            dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
        }
        else if (userRemoveFromFaviourates?.data?.message?.includes('Property removed successfully')) {
            modifyHotelDetails(false)
            dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
        }
        else if (folderCreatedMessage?.includes('Folder created successfully')) {
            const { id, userid } = folderCreatedData;
            updateWishListHandler(userid, id, hotelIdSelected)
            dispatch(setCreateFolderListDataReset({ status: false, message: "", data: [] }))
            setAddtoWhishlishOpen(false)
        }
    }, [addTofavouriteList?.message, userRemoveFromFaviourates?.data?.message, folderCreatedMessage])

    // modify hotel details locally to update wishlist
    const modifyHotelDetails = (wish: boolean) => {
        let hotelDetails = { ...hotel };
        hotelDetails.readonly = false;
        hotelDetails.wish = wish;
        dispatch(setHotelDetails({ data: { hotel: hotelDetails, success: true }, loading: false, error: null }))
    }

    // update wishlist api call
    const updateWishListHandler = async (userId: number, folderId: number, hotelId: number) => {
        if (userId !== 0 && hotelId) {
            const payload = updateUserHotelWishlistPayload(faviourateType, hotelId, folderId, userId)
            await updateWishlist({ payload })
            if (setAddtoWhishlishOpen) setAddtoWhishlishOpen(false);
        }
        else {
            dispatch(setErrorMessageForDialogueBox('15'))
            dispatch(setAlertBoxOnError(true))
            if (setAddtoWhishlishOpen) setAddtoWhishlishOpen(false);
        }
    }

    //folder name input handler
    const folderNameEnteredHandler = (e: any) => {
        const { value } = e.target;
        setFolderNameError(false)
        setFolderName(value)
    }

    // create folder api handler
    const createNewFolderHanlder = (userId: number, folderNameParam: string) => {
        const regex = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
        if (regex.test(folderNameParam) && userId) {
            const payload: createFolder = {
                userDetails: {
                    userId: userId,
                    folderName: folderNameParam
                }
            }
            if (payload) createFolderApi({ payload })
        }
        else {
            setFolderNameError(true)
        }
    }

    return (<>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={AddtoWhishlishOpen}
            onClose={() => setAddtoWhishlishOpen(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >

            <Fade in={AddtoWhishlishOpen}>
                <Box sx={style} className="wishListMod">
                    {wishlistshow ?
                        <Box>
                            <Box className="wishList_header">
                                <Box sx={{ textAlign: 'center', width: '100%' }}>
                                    <Typography id="transition-modal-title" variant="body2" component="h2" >
                                        Create name for your favourite list
                                    </Typography>
                                </Box>
                                <CloseIcon onClick={() => { setAddtoWhishlishOpen(false); setWishlistShow(false) }} />
                            </Box>
                            <Box sx={{ p: ' 1rem ' }}>
                                <TextField
                                    placeholder='Create name for your list'
                                    className='iGoR_autoCom'
                                    sx={{ width: '100%', background: '#efefef', margin: '10px 0' }}
                                    onChange={(e) => folderNameEnteredHandler(e)}
                                />
                                {folderNameError == true ? (<label style={{ color: 'red', fontSize: '0.68rem', display: 'flex', justifyContent: 'center' }}>
                                    Please Enter valid folder name
                                </label>) : (<></>)}
                                <Typography sx={{ marginBottom: '10px', marginLeft: '25px' }}>Max 100 Character</Typography>
                                <Button variant='contained'
                                    onClick={(e) => createNewFolderHanlder(loggedInUserId, folderName)}
                                >Add to wishlist</Button>
                            </Box>
                        </Box>
                        :
                        <Box>
                            <Box className="wishList_header">
                                <CloseIcon onClick={() => setAddtoWhishlishOpen(false)} />
                                <Box sx={{ textAlign: 'center', width: '100%' }}>
                                    <Typography id="transition-modal-title" variant="h6" component="h2" >
                                        Add to Wishlist
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ p: '0 1rem ' }}>
                                <Box className="wishList_btnActive" onClick={() => { setWishlistShow(true) }}>
                                    <AddIcon sx={{ color: '#FF6B6B' }} /> <Typography className='' fontSize={'14px'} fontWeight={'600'} ml={'1rem'}> Make a new wishlist</Typography>
                                </Box>
                                {folderListLoading == true ?
                                    (<Box className='iGor-wishListLoader'>
                                        <FontAwesomeIcon icon={faSpinner} spin />
                                    </Box>) : folderListUser?.length > 0 ? folderListUser.map((folder: any) => {
                                        return (<Box className="wishList_btnTrip" onClick={(e) => updateWishListHandler(loggedInUserId, folder.folderId, hotelIdSelected)}>
                                            <Box sx={{ display: 'flex', alignItems: ' center' }}>
                                                <AddIcon />
                                                <Typography fontSize={'14px'} fontWeight={'600'} ml={'1rem'}>{folder.folderName}</Typography>
                                            </Box>
                                            <Typography fontSize={'10px'} color={'#c4c4c4'} ml={'2.5rem'}>Add to this list</Typography>
                                        </Box>)
                                    }) : (<></>)}
                            </Box>
                        </Box>
                    }
                </Box>
            </Fade>
        </Modal>

    </>);
}

export default AddToFaviourates;