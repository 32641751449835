import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextareaAutosize, IconButton, Select, MenuItem, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../appStore/hooks';
import { useDispatch } from 'react-redux';
import { fetchCancellationPolicies, fetchDataAsyncStaticApi, fetchUserTripsApi, setAlertBoxOnError, setCancellationPoliciesReset, setCancleBookingReset, setErrorMessageForDialogueBox, setUserMytripsDataReset } from '../../appStore/hotelSlice';
import { EventNamesForEventLogs, asyncThunkApiEndPoints } from '../../utils/constants/constant';
import { useCancleUserBookingMutation } from '../../services/api/hotelApi';
import { cancleBookingPayload } from '../../utils/constants/types';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTranslation } from 'react-i18next';
import { dateConverterCancellationPolicies, formatDateDayDateYear } from '../../utils/utilities/DateConverter';
import ToastSuccessMessageContainer from '../../utils/utilities/ToastSuccesMessageContainer';
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F1F5F9',
    borderRadius: '15px',
    boxShadow: 24,
    p: 2,
    minHeight: '300px'
};

export default function MytripCancellationModel({ bookingId }: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false)
        setCancleBookingPopup(false)
        dispatch(setCancellationPoliciesReset({ data: [], loading: false, error: null }))
    };
    const [resonSelected, setResonSelected]: any = useState('')
    const [resonDetails, setResonDetails]: any = useState('');
    const [cancelBookingPopup, setCancleBookingPopup] = useState(false);
    const { getUserDetails, getResonsForBookingCancle, loggedInUserCurrency, getCancellationPoliciesData, cancleBookingData } = useAppSelector(state => state.hotel)
    const cancellationPoliciesApiLoading = getCancellationPoliciesData.loading
    const cancellationPoliciesStatus = getCancellationPoliciesData.data?.status ? getCancellationPoliciesData.data?.status : ''
    const cancellationPoliciesResponse = getCancellationPoliciesData.data?.response ? getCancellationPoliciesData.data?.response : ''
    const userMail = getUserDetails.data?.userDetail?.email;
    const resonsForCancleBooking = getResonsForBookingCancle?.response ? getResonsForBookingCancle?.response : [];
    const endPointGetResonsCancleBooking = asyncThunkApiEndPoints.getResonsForBookingCancle;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const cancellationRules = cancellationPoliciesResponse?.cancellationPolicies ? cancellationPoliciesResponse?.cancellationPolicies[0]?.rules : [];
    const cancellationStartDate = formatDateDayDateYear(cancellationRules[0]?.start)
    const cancellationEndDate = formatDateDayDateYear(cancellationRules[0]?.end)
    const cancleBookingStatus = cancleBookingData?.status
    const cancleBookingResponse = cancleBookingData?.response
    const eventNameCancleBooking = EventNamesForEventLogs.cancleBooking;
    const matches = useMediaQuery('(max-width:600px)');

    // cancle booking mutation
    const [cancleBooking,cancleBookingApi] = useCancleUserBookingMutation();
    AppLogHandler('cancleBookingApi',cancleBookingApi)

    const payload: cancleBookingPayload = {
        resonDetails: {
            "reasonType": resonSelected,
            "reasonDetails": resonDetails
        },
        bookingId: bookingId,
        mail: userMail
    }
    AppLogHandler('getCancellationPoliciesData', { cancellationPoliciesStatus, cancellationPoliciesResponse, cancellationRules, cancellationStartDate })

    const requestForCancellationHandler = () => {
        dispatch(fetchDataAsyncStaticApi(endPointGetResonsCancleBooking))
        setOpen(true);
    }

    const cancellationHandler = () => {
        dispatch(fetchCancellationPolicies(bookingId))
    }

    // event logs for analytics
    const eventLogHanlder = (bookingIdEvent: number) => {
        const eventIdsForLogEvents = {
            "ig_booking_id": bookingIdEvent.toString(),
            "ig_cancelation_reason": resonSelected,
            "ig_reason_description": resonDetails
        }
        EventLogsHandler(eventNameCancleBooking, eventIdsForLogEvents)
    }

    useEffect(() => {
        if (cancellationPoliciesStatus == 'Success' && cancellationPoliciesApiLoading == false) {
            setCancleBookingPopup(true)
        }
        else if (cancellationPoliciesStatus == 'failure') {
            dispatch(setErrorMessageForDialogueBox('22'))
            dispatch(setAlertBoxOnError(true))
            dispatch(setCancellationPoliciesReset({ data: [], loading: false, error: null }))
        }

        if (cancleBookingStatus == 'Success' && cancleBookingResponse?.bookingStatus == 'Cancelled') {
            eventLogHanlder(bookingId)
            setCancleBookingPopup(false)
            setOpen(false);
            setResonSelected('')
            setResonDetails('')
            dispatch(setUserMytripsDataReset({ data: [], loading: false, error: null }))
            dispatch(setCancellationPoliciesReset({ data: [], loading: false, error: null }))
            dispatch(setCancleBookingReset({ status: '', responce: {}, message: '' }))
            dispatch(fetchUserTripsApi(loggedInUserId))
            ToastSuccessMessageContainer(t('1002'))
        }
        else if (cancleBookingStatus == 'failure') {
            dispatch(setErrorMessageForDialogueBox(cancleBookingData.message))
            dispatch(setAlertBoxOnError(true))
            dispatch(setCancleBookingReset({ status: '', responce: {}, message: '' }))
        }
    }, [cancellationPoliciesStatus, cancellationPoliciesApiLoading, cancleBookingData])

    const cancleBookingHandler = () => {
        if (resonSelected !== '') {
            cancleBooking({ payload })
        }
    }

    AppLogHandler('Booking Cancle Page', { 'resonsForCancleBooking': { resonSelected, resonDetails } })

    return (
        <>
            <Button onClick={requestForCancellationHandler} className='iGor__mytripCanBtn'> Request for Cancellation</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box >
                    {cancelBookingPopup == false ? (<Box sx={style} className='iGor-Trip_cancel_modal-mB'>
                        <div className="modal-close">
                            <IconButton onClick={handleClose} className="close-button">
                                <CloseIcon />
                            </IconButton>
                        </div>

                        <Typography id="modal-modal-title" variant="subtitle2" textAlign={'center'} component="h2" fontWeight={600}>
                            Request for Cancellation
                        </Typography>
                        <Typography variant='body2' mt={'20px'} mb={'15px'} ml={'15px'} >
                            Please select reason
                        </Typography>

                        <Select
                            displayEmpty
                            fullWidth
                            label="Age"
                            sx={{
                                background: '#fff',
                                borderRadius: '50px'

                            }}
                        >


                            {resonsForCancleBooking.length > 0 ?
                                resonsForCancleBooking.map((reasons: any) => {
                                    return <MenuItem
                                        onClick={(e: any) => setResonSelected(reasons.reason)}
                                        key={reasons.reason}
                                        value={reasons.reason}
                                    >
                                        {reasons.reason}
                                    </MenuItem>
                                })
                                : (<>{t('5006')}</>)}
                        </Select>
                        <Typography variant='body2' mt={'20px'} mb={'15px'} ml={'15px'}> Tell us why you need cancel this trip?</Typography>
                        <TextareaAutosize aria-label="minimum height" minRows={6} placeholder="Explain your reason" className='iGor_cancellationText' onChange={(e) => setResonDetails(e.target.value)} />
                        <Typography variant='body1' mt={'20px'} mb={'15px'} textAlign={'center'}>This request can’t be undone, by clicking the button below you will request for cancellation</Typography>
                        <Button
                            variant='contained'
                            onClick={cancellationHandler}
                            disabled={cancellationPoliciesApiLoading}
                        >
                            {cancellationPoliciesApiLoading == true ?
                                (<Box className='iGor-action_loader'>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </Box>)
                                : ('Request to cancel')}
                        </Button>
                    </Box>) :
                        (<Box sx={style} className="iGor_Modal iGor_cancelationPop">
                            <div className="modal-close">
                                <IconButton onClick={handleClose} className="close-button">
                                    <CloseIcon />
                                </IconButton>
                            </div>
                            <Box className='iGor-cancellation_box'>
                                <Typography className='iGor_policy_title' id="modal-modal-title" variant="subtitle2" component="h2">
                                    Cancellation Policies
                                </Typography>
                                <Box className='iGor-cancellation-policies'>
                                    <Typography style={{ fontSize: matches ? '11px' : '12px' }} mt={'20px'} ml={'15px'}>
                                        Cancelling from   {dateConverterCancellationPolicies(cancellationStartDate)} to {dateConverterCancellationPolicies(cancellationEndDate)}
                                    </Typography>
                                    <Typography style={{ fontSize: matches ? '13px' : '14px', fontWeight: 600 }}>
                                        will incur cancellation charges of: {cancellationPoliciesResponse?.currency} {cancellationPoliciesResponse?.totalFee}
                                    </Typography>
                                </Box>
                                <Typography style={{ fontSize: matches ? '11px' : '12px', textAlign: 'center', fontWeight: 400, margin: '20px 0 0 0', padding:matches ? '0px 25px': '0px 45px' }}>
                                    The remaining refund amount will be processed in your bank account 5 - 7 working days.
                                </Typography>
                            </Box>
                            <Box className='iGor-Cancelltrip_button'>
                                <Button
                                    onClick={cancleBookingHandler}
                                    variant='contained'
                                    disabled={cancleBookingApi?.isLoading}
                                >
                                    {cancleBookingApi?.isLoading == true ?
                                        (<Box className='iGor-action_loader'>
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        </Box>)
                                        : ('Submit to cancel')
                                    }
                                </Button>
                            </Box>
                        </Box>)}
                </Box>
            </Modal>
        </>
    );
}