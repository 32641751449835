// IgoChatBotComponent.jsx

import React, { useEffect } from 'react';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { useAppSelector } from '../../appStore/hooks';

const IgoChatBotComponent = ({ setChatBot, isOpen }: any) => {
  const { getUserDetails } = useAppSelector(state => state.hotel);
  const loggedInUserData = getUserDetails.data?.userDetail;
  const userPhoneNumber = loggedInUserData?.phone;
  const userFirstName = loggedInUserData?.firstName;
  const userEmail = loggedInUserData?.email;

  const setUserAttributesHanlder = () => {
    // window.fcWidget.setExternalId(userPhoneNumber ? userPhoneNumber : "");

    // // To set user name
    // window.fcWidget.user.setFirstName(userFirstName ? userFirstName : "");

    // // To set user email
    // window.fcWidget.user.setEmail(userEmail ? userEmail : "");
  }

  useEffect(() => {
    setUserAttributesHanlder()
    const initFreshdeskWidget = () => {
      AppLogHandler('Initializing Freshdesk widget...', window);
      // window.fcWidget.open();
      // window.fcWidget.show()

       // Replace with the parameter value needed for Freshbots.activate()
      // (window as any).Freshbots.activate();

  
      // (window as any).Freshbots.activate();
      // (window as any).Freshbots.showWidget();

      // (window as any).Freshbots.open();
      // (window as any).Freshbots.show()
    //   (window as any).Freshbots.on('widget:close', function() {
    //     console.log("The Freshbots widget has been closed.");
    //     // Add your code here to handle the event when the widget is closed
    // });
      // window.fcWidget.on('widget:closed', () => {
        AppLogHandler('Chat widget is closed.', '');
        setChatBot(false)
      //   window.fcWidget.hide();
      //   // Clear conversation
      //   window.fcWidget.user.clear();
      //   // Clear user details
      //   window.fcWidget.user.update({
      //     firstName: '',
      //     lastName: '',
      //     email: ''
      //     // You can clear any other user details here
      //   });
      // });
    };

    const initializeChat = async () => {
      try {
        initFreshdeskWidget();
      } catch (error) {
        AppLogHandler('Error loading or initializing Freshdesk script:', error);
      }
    };

    initializeChat();
  }, [setChatBot]);

  return <></>;
};

export default IgoChatBotComponent;
