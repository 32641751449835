// Month names long
const monthsLong = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

// Month names short
const monthsShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

function convertDateFormat(inputDate: any) {
  // Parse the input date
  const inputDateObj = new Date(inputDate);

  // Extract the day, month, and year
  const day = inputDateObj.getDate();
  const month = monthsLong[inputDateObj.getMonth()];
  const year = inputDateObj.getFullYear();

  // Format the date in the desired output format
  const outputDate = `${day} ${month} ${year}`;

  return outputDate;
}
export default convertDateFormat


// date conversion function for 45 days later date
export function calculateDateFourtyFiveDaysLater(baseDate: any) {
  const resultDate = new Date(baseDate);
  resultDate.setDate(resultDate.getDate() + 45);
  return resultDate;
}

// date converter function for date one year later
export function calculateOneYearLaterDate(todaysDate: any) {
  const yearLater = new Date(todaysDate);
  yearLater.setMonth(yearLater.getMonth() + 12);
  return yearLater;
}

export function formatDateDayDateYear(baseDate: any) {
  let date: any = new Date(baseDate)
  date = date.toDateString()
  return date;
}

export function convetDateForInitApiCall(date: Date) {
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
}

export function dateFormatedForUrls(date: any) {
  const dateToFormat = new Date(date);
  const year = dateToFormat.getFullYear();
  const month = String(dateToFormat.getMonth() + 1).padStart(2, '0');
  const day = String(dateToFormat.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}


export function dateConvertForExpiredCheckOut(date: any) {
  const tomorrowDate: any = date.setDate(date.getDate() + 1);
  const nextDate = new Date(tomorrowDate)
  return nextDate.toISOString().split('T')[0];
}

export function incrementDecrementConverter(dateSelected: any, dayNumber: any) {
  const currentDate = new Date(dateSelected);
  currentDate.setDate(currentDate.getDate() + dayNumber);
  return currentDate;
}

// function for formate date formate mobile responsive
export function formatDateOnSearchResultPageMobile(date: any) {
  const options: any = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = new Date(date).toLocaleDateString('en-US', options);

  const [weekday, month, day, year] = formattedDate.split(' ');
  const formatedDay = day.replace(',', '')

  // Concatenate the formatted date with the desired format
  return `${weekday} ${formatedDay} ${month} ${year}`;
}

// function for date formate for web
export function formatDateOnSearchResultPage(date: any, screen: string) {
  const options: any = {
    weekday: screen == 'detail' ? 'short' : 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  };

  const formattedDate = new Date(date).toLocaleDateString('en-US', options);

  const [weekday, month, day, year] = formattedDate.split(' ');
  const formatedDay = day?.replace(',', '')

  // Concatenate the formatted date with the desired format
  return `${weekday} ${formatedDay} ${month} ${year}`;
}

// funct for date format 31 december 2024
export function formatDateDateMonthYear(date: any): string {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  };

  const formattedDate = new Date(date).toLocaleDateString('en-US', options);

  // Destructure the formattedDate directly with the correct order
  const [month, formatedDay, year] = formattedDate.split(' ');

  // Remove the comma from the day
  const formattedDayWithoutComma = formatedDay.replace(',', '');

  // Concatenate the formatted date with the desired format
  return ` ${formattedDayWithoutComma} ${month} ${year}`;
}

// date function to check wheather date is 3 months later or not for UAT and develop env
export const isDateWithinThreeMonthsFromNow = (dateParams: any) => {
  const inputDate = new Date(dateParams);
  const currentDate = new Date();

  if (inputDate < currentDate) {
    return false;
  }

  const threeMonthsLaterDate = new Date();
  threeMonthsLaterDate.setMonth(currentDate.getMonth() + 3);

  return inputDate >= threeMonthsLaterDate;
}

export const getThreeMonthsLaterDate = (currentDate: any) => {
  const inputDate = new Date(currentDate);
  const threeMonthsLaterDate = new Date(inputDate);

  threeMonthsLaterDate.setMonth(inputDate.getMonth() + 3);

  // Adjust for cases where adding 3 months could cause an overflow
  if (threeMonthsLaterDate.getDate() > inputDate.getDate()) {
    threeMonthsLaterDate.setDate(0); // Set to the last day of the previous month
  }

  // Increment the date by one day
  threeMonthsLaterDate.setDate(threeMonthsLaterDate.getDate() + 1);

  return threeMonthsLaterDate;
}


export const dateConverterCancellationPolicies = (date: any) => {
  // Original date string

  // Parse the original date string to a Date object
  const dateObj = new Date(date);

  // Get the components of the date
  const day = dateObj.getUTCDate();
  const month = monthsShort[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();
  const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getUTCSeconds();

  // Format the hours for 12-hour clock and determine AM/PM
  const formattedHours = hours % 12 || 12;
  const period = hours >= 12 ? "PM" : "AM";

  // Pad minutes and seconds with leading zeros if needed
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  // Construct the formatted date string
  const formattedDateStr = `${day} ${month}, ${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}${period}`;
  return formattedDateStr
}

export const myTripDateConverter = (date: any) => {
  const newDate = new Date(date)
  const optionsWeekday: any = { weekday: 'short' };
  const optionsDay: any = { day: '2-digit' };
  const optionsMonth: any = { month: 'short' };
  const optionsYear: any = { year: 'numeric' };
  const weekday = newDate.toLocaleDateString('en-US', optionsWeekday);
  const day = newDate.toLocaleDateString('en-US', optionsDay);
  const month = newDate.toLocaleDateString('en-US', optionsMonth);
  const year = newDate.toLocaleDateString('en-US', optionsYear);
  const formattedDate = `${weekday}, ${day} ${month} ${year}`;
  return formattedDate;
}

export function dateFormatedFaviourateFolder(date: any, dateType: string) {
  const newDate = new Date(date)
  const day = newDate.getDate();
  const month = monthsShort[newDate.getMonth()];
  const year = newDate.getFullYear();
  if (dateType == 'created') {
    return `${day} ${month}`;
  }
  else if (dateType == 'updated') {
    return `${day} ${month} ${year}`;
  }
}
