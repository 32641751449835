import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import { env } from '../../utils/config/appConfig';
import Slider from "react-slick";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import NoHotelsImg from '../../assets/images/noHotelIgo.jpg';
import { useAppSelector } from '../../appStore/hooks';
import { cacheImages } from '../../utils/utilities/ImageCaching';
import { useDispatch } from 'react-redux';
import { setExploreRegionSelectedReset } from '../../appStore/hotelSlice';
import { EventNamesForEventLogs } from '../../utils/constants/constant';
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import LazyLoadImagesComp from './LazyLoadImages';
import { environmentVariables } from '../../utils/config/environmentVariables';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import Icon_InfoPro from '../../assets/icons/Icon_InfoPro2.svg'

function NextArrow(props: any) {
    const { className, style, onClick } = props;
    const arrowClickHanlder = (e: any) => {
        const { clientX, clientY } = e;
        AppLogHandler('arrowClickHanlder', { clientX, clientY })
        if(onClick)onClick(e)
    }
    return (
        <ArrowForwardIcon
            className={className}
            style={{
                ...style, display: "block", color: "#ffffff", border: '1.5px solid',
                borderRadius: '50px'
            }}
            onClick={arrowClickHanlder}
        />
    );
}

function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
        <ArrowBackIcon
            className={className}
            style={{
                ...style, display: "block", color: "#ffffff", border: '1.5px solid',
                borderRadius: '50px'
            }}
            onClick={onClick}
        />
    );
}

const ExploreRegions = ({ setclassName, regionsToExplore, paramsIdToNextPath }: any) => {
    const dispatch = useDispatch();
    const imagePathUrl = environmentVariables.imagePathApp;
    const { exploreDestinationsApiData } = useAppSelector(state => state.hotel)
    const exploreLoading = exploreDestinationsApiData.loading;
    const eventNameExploreRegion = EventNamesForEventLogs.exploreRegion;
    const [imageLoaded, setImageLoaded] = useState(false);

    const matches = useMediaQuery('(max-width:1024px)');
    const regionsSlider = {
        dots: false,
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 4,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    centerPadding: '20px',
                    variableWidth: true,
                    adaptiveHeight: true
                },
            },
        ],
    };

    // commnted because when did refresh only skeleton showing after images loaded
    // useEffect(() => {
    //     // Reset imageLoaded state on component unmount
    //     if (imageLoaded == false) {
    //         setTimeout(() => { setImageLoaded(true) }, 3000)
    //     }
    // }, [imageLoaded]);

    const handleImageLoad = () => {
        // Set imageLoaded to true when the image is loaded
        setTimeout(() => { setImageLoaded(true) }, 1500)
    };
    const exploreRegionsHandler = (title: string, location: string) => {
        dispatch(setExploreRegionSelectedReset({ data: [], loading: false, error: null }))
        // event log to analytics
        const eventIdsForLogEvents = {
            "ig_region_name": title,
            "ig_region_location": location,
        }
        EventLogsHandler(eventNameExploreRegion, eventIdsForLogEvents)
    }

    return (
        <>
            <Slider {...regionsSlider} className='iGor__regionsSlider'>
                {exploreLoading == true ?
                    ([...Array(3)].map(() => {
                        return (
                            <Box>
                                <Box className={`iGor_ExMore_cards ${setclassName}`}>
                                    <Box className="iGor_ExMore_images">
                                        <Skeleton
                                            variant="rectangular"
                                            height={260}
                                            animation="wave"
                                            sx={{ borderRadius: '0.625rem' }} />
                                    </Box>
                                </Box>
                            </Box>
                        )
                    })) : (regionsToExplore?.length > 0 ? regionsToExplore.map((exploreRegions: any, index: number) => {
                        const imageUrl: any = cacheImages(`${imagePathUrl}${exploreRegions.bg_image}`)
                        return (<>
                            <Box className={`iGor_ExMore_cards ${setclassName}`} onClick={(e) => exploreRegionsHandler(exploreRegions.title, exploreRegions.location)} >
                             <img src={Icon_InfoPro} className='iGor_Icon_InfoPro'/>
                                <Box className="iGor_ExMore_images">
                                    {imageLoaded == false &&
                                        <Skeleton
                                            variant="rectangular"
                                            height={matches ? 210 : 260}
                                            animation="wave"
                                            sx={{ borderRadius: '0.625rem' }} />}
                                        <Link
                                            className="iGor_ExMore_img"
                                            to={`/explore/${paramsIdToNextPath}/regions/${exploreRegions.slug}`}
                                            state={{
                                                slug: exploreRegions.slug,
                                                prevPathid: paramsIdToNextPath
                                            }}
                                            style={{
                                                display: imageLoaded ? 'block' : 'none'
                                            }}
                                        >
                                            {/* <LazyLoadImagesComp
                                                key={index}
                                                handleImageLoad={handleImageLoad}
                                                images={imageUrl.src}
                                                alt={exploreRegions.category}
                                                scrollPosition={slidePosition}
                                            /> */}
                                            <img
                                                onLoad={handleImageLoad}
                                                src={imageUrl.src}
                                                onError={(e: any) => {
                                                    // Handle the image load error by setting the source to the default image
                                                    e.target.src = NoHotelsImg;
                                                }}
                                                alt={exploreRegions.category} />
                                        </Link>                                   
                                </Box>
                                <Box className="iGor_ExMore_description">
                                    {!matches && <Button className="iGor_ExMore_btn" >
                                        <span>{exploreRegions.category}</span>
                                    </Button>}
                                    <Link
                                        className="iGor_ExMore_title"
                                        to={`/explore/${paramsIdToNextPath}/regions/${exploreRegions.slug}`}
                                        state={{
                                            slug: exploreRegions.slug,
                                            prevPathid: paramsIdToNextPath
                                        }}
                                    >{exploreRegions.title}</Link><span className="subtitle"></span>
                                    <Typography color={"#757575"} fontSize={'8px'} fontStyle={'italic'}>{exploreRegions.location}</Typography>
                                </Box>
                            </Box>
                        </>)
                    }) : <>No options available</>
                    )}
            </Slider>
        </>
    )
}

export default ExploreRegions;