export const FrenchMessages = {
    "Latest Offers": "Dernières offres",
    "Explore": "Explorer",
    "Favourites": "Favoris",
    "About Us": "À propos de nous",
    "Blog": "Blog",
    "Privacy": "Confidentialité",
    "Terms": "Conditions",
    "Support": "Soutien",
    "Login to your account": "Login to your account",
    "Or sign in with":"Or sign in with",
    "sign in": "sign in",
    "Forgot password": "Forgot password?",
    "Email Address": "Email Address",
    "code": "code",
    "Password": "Password",
    "Register your account": "Register your account",
    "First Name": "First Name",
    "Last Name": "Last Name",
    "Make sure it matches": "Make sure it matches the name on your ID card or Passport",
    "Phone Number": "Phone Number",
    "We will call or send you": "We will call or send you a confirmation to your phone number. Charges may apply.",
    "Currency based on your location": "Currency based on your location",
    "By clicking": "By clicking",
    "you will agree with our" : "you will agree with our",
    "terms & conditions": " terms & conditions ",
    "Continue": "Continue",
    "Need Help": "Besoin d'aide ?",
    "Igobot": "Igobot",
    "Quick answer to your queries": "Quick answer to your queries",
    "Support Center": "Support Center",
    "Information about": "Information about all things about Igoroom",
    "Customer Services": "Customer Services",
    "Schedule a callback from of agents": "Schedule a callback from of agents",
    "Where do you want to go?": "Où veux-tu aller?"
}