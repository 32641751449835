import React, { useEffect } from 'react'
import { useDispatch } from "react-redux"
import { setAppliedPriceRangeFiltersForHotels, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels, setHotelsTypeTabSelection, setScreenTypeSelected } from "../../appStore/hotelSlice"
import { Box } from "@mui/system"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../appStore/hooks";
import { IconButton } from "@mui/material";
import { Close } from '@mui/icons-material';

function ResetFilters({resetFiltersHandler} :any) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const filtersForUI = useAppSelector(state => state.hotel.getUIFiltersData)
    return (<>
        <Box sx={{ textAlign: 'right' }}>
            <IconButton onClick={resetFiltersHandler} className='iGor_filterResetBtn'>
                {t('Reset Filters')}
            </IconButton>
        </Box>
    </>);
}

export default ResetFilters;