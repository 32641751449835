import React, { useEffect, useState, useTransition } from 'react'
import { Grid, Typography, Box, Button, useMediaQuery, Skeleton } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../../layouts/Header';
import { Container } from '@mui/system';
import Explore_CardOffers from '../../components/ExploreDestination/ExploreCardOffers';
import { Footer } from '../../layouts/Footer';
import TuneIcon from '@mui/icons-material/Tune';
import { useAppSelector } from '../../appStore/hooks';
import noImageFound from '../../assets/images/no-hotel-image.png'
import ExploreRegions from '../../components/ExploreMore/ExploreRegions';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import ExploreThingsToDoCards from '../../components/ExploreMore/ExploreThingsToDo';
import { sliderSetting } from '../../utils/constants/SliderSettings';
import BackArrow from '@mui/icons-material/KeyboardArrowLeft';
import { Link, useParams } from 'react-router-dom';
import { fetchExploreDestinationsApi, fetchExploreHotelsToStay, setAlertBoxOnError, setErrorMessageForDialogueBox, setExploreRegionSelectedReset, setExploreThingToDoSelectedReset } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import { EventNamesForEventLogs } from '../../utils/constants/constant';
import ThingsToDo from '../../components/ExploreMore/ThingsToDo';

function ExploreMore() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const params: any = useParams();
    const paramsIdToNextPath = params.id;
    const [thingsToDoTitle, setThingsToDoTitle] = useState('All');
    const [activeButton, setActiveButton] = useState('All');
    const [tabSelected, setTabSelected]: any = useState('');
    const { exploreDestinationsApiData, exploreHotelsToStay, selectedHotelsTypeTab, loggedInUserCurrency } = useAppSelector(state => state.hotel);
    const exploreDestinationData = exploreDestinationsApiData.data;
    const exploreDestinationDetailsObj = exploreDestinationData?.exploreDetail ? exploreDestinationData.exploreDetail : {}
    const thingsToDoArray = exploreDestinationData?.thingsToDo ? exploreDestinationData?.thingsToDo : []
    const regionsToExplore = exploreDestinationData?.regions ? exploreDestinationData?.regions : []
    const exploreHotelsToStayData: any = exploreHotelsToStay?.data ? exploreHotelsToStay?.data : []
    const exploreHotelsAndResorts = exploreHotelsToStayData?.popularHotelResponse ? exploreHotelsToStayData.popularHotelResponse : [];
    const exploreOffers = exploreHotelsToStayData?.popularOfferResponse ? exploreHotelsToStayData.popularOfferResponse : [];
    const exploreVip = exploreHotelsToStayData?.popularVIPResponse ? exploreHotelsToStayData.popularVIPResponse : [];
    const matchResult = params.id.match(/\d+/);
    const matchResultDestination = params.id.match(/[a-zA-Z]+/);
    const destinationName = matchResultDestination ? matchResultDestination[0] : null;
    const destinationNameUI = exploreDestinationDetailsObj?.name?.toUpperCase()
    const destinationIdExplore: any = matchResult ? matchResult[0] : '';
    const eventNameExploreDestination = EventNamesForEventLogs.exploreDestination;
    const tabTypeSelected = selectedHotelsTypeTab
    const allTypeHotelsArray = exploreHotelsAndResorts.concat(exploreOffers, exploreVip)
    const [exploreHotelData, setExploreHotelsData] = useState()
    const matches = useMediaQuery('(max-width:1024px)');
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const exploreLoading = exploreDestinationsApiData.loading;
    const [imageLoaded, setImageLoaded] = useState(false);

    // to check explore destination data is empty
    function exploreDesDataEmpty(obj: any) {
        return Object.entries(obj).length === 0;
    }
    const exploreData = exploreDesDataEmpty(exploreDestinationData);

    // explore destination api call and event log handler
    const exploreDestinationApiCallHandler = () => {
        const eventIdsForLogEvents = {
            "ig_destination_id": destinationIdExplore,
            "ig_destination_name": destinationName
        }
        dispatch(setExploreThingToDoSelectedReset({ data: [], loading: false, error: null }))
        dispatch(setExploreRegionSelectedReset({ data: [], loading: false, error: null }))
        dispatch(fetchExploreDestinationsApi({ destinationId: destinationIdExplore, userId: loggedInUserId }))
        dispatch(fetchExploreHotelsToStay(destinationIdExplore))
        EventLogsHandler(eventNameExploreDestination, eventIdsForLogEvents)
    }

    const tabChangeHandler = (tabSelected: string) => {
        switch (tabSelected) {
            case 'ALL':
                setTabSelected(tabSelected)
                setExploreHotelsData(allTypeHotelsArray)
                break;
            case 'HOTELSRESORT':
                setTabSelected(tabSelected)
                setExploreHotelsData(exploreHotelsAndResorts)
                break;
            case 'OFFERS':
                setTabSelected(tabSelected)
                setExploreHotelsData(exploreOffers)
                break;
            case 'VIP':
                setTabSelected(tabSelected)
                setExploreHotelsData(exploreVip)
                break;
        }
    }

    useEffect(() => {
        if (destinationIdExplore && exploreData == true && loggedInUserId !== undefined) {
            exploreDestinationApiCallHandler()
        }
        else if (destinationIdExplore == '') {
            dispatch(setErrorMessageForDialogueBox('31'))
            dispatch(setAlertBoxOnError(true))
        }
    }, [destinationIdExplore, exploreData, loggedInUserId])

    // to updating the places to stay array
    useEffect(() => {
        if (tabSelected == '') {
            setTabSelected('ALL')
        }
        else if (allTypeHotelsArray.length > 0 && tabSelected == "ALL") {
            setExploreHotelsData(allTypeHotelsArray)
        }
    }, [tabSelected, allTypeHotelsArray.length])

    const handleImageLoad = () => {
        // Set imageLoaded to true when the image is loaded
        setTimeout(() => { setImageLoaded(true) }, 2000)
    };

    const handleButtonClick = (title: any) => {
        setThingsToDoTitle(title);
        setActiveButton(title);
    };
    // // function for all things to do and sorting as per tab selection
    // const thingsToDo = () => {
    //     let thingsToDoTypesData: [any] = [{}];
    //     switch (thingsToDoTitle) {
    //         case 'All':
    //             thingsToDoTypesData = thingsToDoArray?.map((category: any) => category.data).reduce((a: any, b: any) => a.concat(b), []);
    //             return (<>
    //                 <ExploreThingsToDoCards
    //                     thingsToDoArray={thingsToDoTypesData}
    //                     paramsIdToNextPath={paramsIdToNextPath} />
    //             </>)
    //         default:
    //             thingsToDoTypesData = thingsToDoArray?.map((thingsToDo: any) => {
    //                 if (thingsToDoTitle == thingsToDo.title)
    //                     return (<ExploreThingsToDoCards
    //                         thingsToDoArray={thingsToDo.data}
    //                         paramsIdToNextPath={paramsIdToNextPath} />)
    //             })
    //     }
    //     AppLogHandler('thingsToDoTypesData', thingsToDoTypesData)
    //     return thingsToDoTypesData
    // }


    return (
        <>
            <Box bgcolor={"#ffffff"}>
                <Box className="iGor__banner-explorer">
                    <Container>
                        <Header name='iGor__header-explorer' />
                    </Container>
                    <Box className="iGor__image">
                        {imageLoaded == false && <Skeleton variant="rectangular" width={"100%"} height={"100%"} sx={{ bgcolor: 'grey.900' }} />}
                        <img
                            onLoad={handleImageLoad}
                            src={exploreDestinationDetailsObj.exploreBg ? exploreDestinationDetailsObj.exploreImg : noImageFound}
                            alt="Indonesia"
                            className={exploreDestinationDetailsObj.exploreBg ? '' : 'noImageFound'}
                        />
                    </Box>
                    {exploreLoading == false && <Box className="iGor__description">
                        <h1 className="iGor__title">{exploreDestinationDetailsObj.titleBg}</h1>
                        <p className="iGor__subtitle">IN {destinationNameUI}</p>
                    </Box>}
                    <Button className='iGor__ProBackArrow'><Link to={'/explore'}><BackArrow /></Link></Button>

                </Box>

                <Container>
                    <Grid container spacing={2} className='iGor__ExpMoreSidelerBox'>
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography variant='h4' className='iGor__ExpMoreSidelerTitle'>Find things to do</Typography>
                            <Box className="iGOr_ExBtnFilterWrapper">
                                <Button className={`iGor__ExBtnFilter ${activeButton === 'All' ? 'iGor__ExBtnFilterActive' : ''}`} onClick={() => handleButtonClick('All')}>All</Button>
                                {thingsToDoArray.map((thingsTitle: any) => {
                                    return (<>
                                        <Button className={`iGor__ExBtnFilter ${activeButton === thingsTitle.title ? 'iGor__ExBtnFilterActive' : ''}`} onClick={() => handleButtonClick(thingsTitle.title)}>{thingsTitle.title}</Button>
                                    </>)
                                })}
                            </Box>
                            <Box >
                                <ThingsToDo thingsToDoTitle={thingsToDoTitle} />
                            </Box>
                        </Grid>
                        <Grid item lg={6} sm={12} xs={12}>
                            <Typography variant='h4' marginBottom={'15px'}> Regions</Typography>
                            {/* <Slider {...regionsSlider} className='iGor__regionsSlider'> */}
                            <ExploreRegions setclassName="iGor__portrait" regionsToExplore={regionsToExplore} paramsIdToNextPath={paramsIdToNextPath} />
                            {/* <ExMoreCards sliderImg={sliderImg2} setclassName="iGor__portrait" event='REGIONS' title='Bali - A Journey to Serenity' subTitle='Make culture discoveries at Indonesia' />
                                <ExMoreCards sliderImg={sliderImg2} setclassName="iGor__portrait" event='REGIONS' title='Yogyakarta - The Grand Temple' subTitle='Make culture discoveries at Indonesia' />
                                <ExMoreCards sliderImg={sliderImg2} setclassName="iGor__portrait" event='REGIONS' title='Beautiful Places for Your Summer in Indonesia' subTitle='Make culture discoveries at Indonesia' />
                                <ExMoreCards sliderImg={sliderImg2} setclassName="iGor__portrait" event='REGIONS' title='5 Beautiful Places for Your Summer in Indonesia' subTitle='Make culture discoveries at Indonesia' />
                                <ExMoreCards sliderImg={sliderImg2} setclassName="iGor__portrait" event='REGIONS' title='Bromo - The Great BromoCrater' subTitle='Make culture discoveries at Indonesia' /> */}
                            {/* </Slider> */}

                        </Grid>
                    </Grid>

                    <Grid container className='iGor__ExptabSection'>
                        <Grid item sm={4} xs={12}>
                            <Typography variant='h4'>Places to Stay</Typography>
                            <Typography fontStyle={'italic'} fontSize={'10px'} fontWeight={'300'}>  Get to know where to stay at ‘{exploreDestinationDetailsObj?.name}’</Typography>
                        </Grid>
                        <Grid item sm={4} xs={12} className={matches ? 'iGor-Explore-TabGrid' : ''}>
                            <Box className="iGor__ExploreTab">
                                <Button className={`iGor_ExploreTabs ${tabSelected == 'ALL' ? 'iGor_ExTabsActive' : ''}`} onClick={() => tabChangeHandler('ALL')}>Show All</Button>
                                <Button className={`iGor_ExploreTabs ${tabSelected == 'HOTELSRESORT' ? 'iGor_ExTabsActive' : ''}`} onClick={() => tabChangeHandler('HOTELSRESORT')}>Hotel</Button>
                                <Button className={`iGor_ExploreTabs ${tabSelected == 'OFFERS' ? 'iGor_ExTabsActive' : ''}`} onClick={() => tabChangeHandler('OFFERS')}>Offers</Button>
                                <Button className={`iGor_ExploreTabs ${tabSelected == 'VIP' ? 'iGor_ExTabsActive' : ''}`} onClick={() => tabChangeHandler('VIP')} >VIP</Button>
                            </Box>
                        </Grid>
                        {/* <Grid item sm={4} textAlign={'right'}>
                                <Button className='iGor__FilterBtnMain' startIcon={<TuneIcon />} > Filter</Button>
                            </Grid> */}
                    </Grid>

                    {/* <Slider {...sliderSetting} className='iGor__regionsSlider'> */}



                    <Explore_CardOffers exploreHotelsToStayData={exploreHotelData} />

                    {/* </Slider> */}
                </Container>

                <Footer />
            </Box>
        </>

    )
}

export default ExploreMore;