import React from 'react'
import { Box, Grid, Typography, Paper, Skeleton } from '@mui/material';
const FavTripCardSkelton = () => {
    return (
        < >
                    <Box className="favTripCard" width={250}>
                        <Box className="favTrip_imgSection">
                            <Skeleton variant="rectangular" className='favTrip_leftImg'  sx={{borderRadius:'15px'}} />
                        </Box>
                        <Box className='favTrip_skeletonContent'>
                            <Typography variant="h6" className='favTrip_title'>
                                <Skeleton width="60%" />
                            </Typography>
                            <Typography variant="body2" className='favTrip_date'>
                                <Skeleton width="40%" />
                            </Typography>
                        </Box>
                    </Box>

        </>
    )
}

export default FavTripCardSkelton