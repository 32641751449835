import React, { useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import { Button, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'react-date-range';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { setUserBirthDate } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { setShowDate } from '../../appStore/hotelSlice';
import { useAppSelector } from '../../appStore/hooks';
import Icon_calendarBlack from '../../assets/icons/Icon_calendarBlack.svg';
import BackspaceIcon from '@mui/icons-material/Backspace';

export const DatePickerInPopOver = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const registerUserBirthDate = useAppSelector(state => state.hotel.userBirthDate)
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    let [date, setDate]: any = useState();

    const defaultDate = new Date();
    defaultDate.setFullYear(defaultDate.getFullYear() - 17);
    const handleClick = (event: any) => {
        const formatedDateString = defaultDate.toISOString()
        // Calculate the default date (17 years ago from today)
        setAnchorEl(event.currentTarget);
        setDate(defaultDate)
        dispatch(setUserBirthDate(formatedDateString));
        dispatch(setShowDate(true))
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangeHandler = (newDate: any) => {
        setDate(newDate);
        dispatch(setShowDate(true));
        dispatch(setUserBirthDate(newDate));
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'DatePiker-popover' : undefined;

    const resetDateHanlder = () => {
        setDate('')
        dispatch(setUserBirthDate(''));
        dispatch(setShowDate(false))
    }

    const dateToshowFormated = date ? date?.toLocaleDateString('en-UK', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }) : '';


    return (
        <>
            {/* <DateRangeIcon className='inputIcons' /> */}
            <img src={Icon_calendarBlack} alt='Icon_calendar' className='inputIcons' />
            <TextField
                aria-describedby={id}
                className='iGor_inputFieldval'
                value={dateToshowFormated}
                onClick={handleClick}
                inputProps={{ readOnly: true }}
                placeholder='Date of birth'
            ></TextField>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Calendar
                    date={date}
                    maxDate={defaultDate}
                    onChange={(setDate: any) => onChangeHandler(setDate)}
                    className='iGor_calendar'
                />
            </Popover>
            <Button className='iGor-date_reset' onClick={resetDateHanlder}>
                <BackspaceIcon />
            </Button>
        </>
    );
};