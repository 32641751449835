import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from 'react-i18next';
import clipboardCopy from 'clipboard-copy';
import ToastSuccessMessageContainer from '../../utils/utilities/ToastSuccesMessageContainer';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  width: '500px',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  borderRadius: '10px',
};

export default function MyTripModel({ handleClose, open }: any) {
  const { t } = useTranslation();
  const { userBookingDetails, donwloadBookingPDFData } = useAppSelector(state => state.hotel)
  const userBookingDetailsData = userBookingDetails?.data?.bookingDetailResponse;
  const adultsCount = userBookingDetailsData.hotelbooking.passengers.map((adults: any) => adults.type == 'Adult' ? adults : null);
  const childCount = userBookingDetailsData.hotelbooking.passengers.map((childs: any) => childs.type == 'Child' ? childs : null);
  const bookedHotelInclusions: [any] = userBookingDetailsData.hotelbooking?.packageinclusion ? userBookingDetailsData.hotelbooking?.packageinclusion : [];
  const bookingId = userBookingDetailsData.hotelbooking?.bookingId;
  const downloadPDFUrl = donwloadBookingPDFData?.data ? donwloadBookingPDFData?.data : '';
  const baseRate = userBookingDetailsData?.hotelbooking?.hotel?.baseRate?.toFixed(2);
  const taxesAndFees = userBookingDetailsData?.hotelbooking?.hotel?.taxes?.toFixed(2);


  // handle copy booking id
  const handleCopyToClipboard = (bookingId: string) => {
    clipboardCopy(bookingId);
    const copyMessage = `${t('1004')} ${bookingId} `
    ToastSuccessMessageContainer(copyMessage)
  }

  // download PDF hanlder
  const downloadBookingPDFHandler = () => {
    const pdfUrl = downloadPDFUrl;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.target = '_blank'
    link.download = downloadPDFUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div>
      {/* <MoreVertIcon className='iGor__ModelBtn' onClick={openbox} /> */}

      <Modal

        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 50,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className='iGor_Modal iGor_bookingDetailsModal'>
            <Box className='iGor_setFlex iGor_justifyBetween iGor_bookingDetHeader' >
              <IconButton onClick={handleClose} className="close-button">
                <Close />
              </IconButton>
              <Typography variant="subtitle1" >
                Booking Details
              </Typography>
              <IconButton
                disabled={downloadPDFUrl ? false : true}
              >

                <DownloadIcon
                  onClick={downloadBookingPDFHandler}
                />
              </IconButton>
            </Box>
            <Box className="iGor_ContentBody">
              <Box className="iGor_bookingDetBox">
                <Box className='iGor_setFlex iGor_justifyBetween iGor_BookingID' >
                  <Box>
                    <Typography color={'#c4c4c4'}>
                      Booking ID
                    </Typography>
                    <Typography fontWeight={500} justifyContent={'center'}>
                      {userBookingDetailsData.hotelbooking.bookingId}
                      <ContentCopyIcon
                        onClick={(e) => handleCopyToClipboard(userBookingDetailsData.hotelbooking?.bookingId)}
                        className='iGor_BookingCopyId'
                      />
                    </Typography>
                  </Box>
                  <Box>
                    <Button className={userBookingDetailsData.hotelbooking.bookStatus == 'Confirmed' ? "iGor_ConfirmedBtn" : " iGor__CancelledBtn"} startIcon={userBookingDetailsData.hotelbooking.bookStatus == 'Confirmed' ? <CheckIcon /> : <CancelIcon />}>{userBookingDetailsData.hotelbooking.bookStatus}</Button>
                  </Box>
                </Box>
                <Box className="iGor_BookingDetails">
                  <Typography color={'#c4c4c4'}>
                    Booking Details
                  </Typography>
                  <Typography variant='body2'>
                    {userBookingDetailsData?.propertyName}
                  </Typography>
                </Box>
                <Box className='iGor_setFlex iGor_justifyBetween iGor_BookingRoomInfo' >
                  <Box width={'33.3%'}>
                    <Typography color={'#c4c4c4'}>
                      Room Type
                    </Typography>
                    <Typography variant='body2'>
                      {userBookingDetailsData?.roomType}
                    </Typography>
                  </Box>
                  <Box width={'33.3%'}>
                    <Typography color={'#c4c4c4'}>
                      Bed Type
                    </Typography>
                    <Typography variant='body2'>{userBookingDetailsData?.bedType?.length > 0 ? userBookingDetailsData?.bedType : 'Not Available'}</Typography>

                  </Box>
                  <Box>
                    <Typography color={'#c4c4c4'}>
                      Guests
                    </Typography>
                    <Typography variant='body2'>{`${adultsCount.length} ${adultsCount.length > 1 ? 'Adults' : 'Adult'} ${childCount[0] !== null ? ', ' + childCount.length + ' ' + (childCount.length > 1 ? 'Childs' : 'Child') : ''}`}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box className="iGor_bookingDetBox">
                <Box className='iGor_setFlex iGor_justifyBetween iGor_bookingDetPrice'  >
                  <Box>
                    <Typography className='iGor_bookingDetTitle'>Price Details</Typography>
                    <Typography variant='body2'>
                      {`${userBookingDetailsData?.totalNight ? userBookingDetailsData?.totalNight > 1 ? userBookingDetailsData?.totalNight + ' Nights, ' : userBookingDetailsData?.totalNight + ' Night, ' : ''}`}
                      {userBookingDetailsData?.roomType}
                    </Typography>
                  </Box>
                  <Box paddingTop={'8px'}>
                    <Typography variant='body2'>{userBookingDetailsData.hotelbooking.currency} {userBookingDetailsData?.hotelbooking?.totalAmount.toFixed(2)}</Typography>
                  </Box>
                </Box>
                {bookedHotelInclusions.length > 0 ? (<><Typography fontWeight={600} className='iGor_bookingDetTitle'>Inclusions details</Typography>
                  <Box className="iGor__MytripList">
                    <ul>
                      {bookedHotelInclusions.map((inclusion: any) => {
                        return (<li>{inclusion}</li>)
                      }
                      )}
                    </ul>
                  </Box></>) : ''}

                <Box className='iGor_setFlex iGor_justifyBetween' mb={'0.65rem'}>
                  <Box>
                    <Typography variant='body2'>Base Rate</Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2'>{userBookingDetailsData.hotelbooking.currency} {baseRate ? baseRate : 0}</Typography>
                  </Box>
                </Box>
                <Box className='iGor_setFlex iGor_justifyBetween iGor_bookingfooter'>
                  <Box>
                    <Typography variant='body2' >Taxes & Fees</Typography>
                  </Box>
                  <Box>
                    <Typography variant='body2'>{userBookingDetailsData.hotelbooking.currency} {taxesAndFees ? taxesAndFees : 0}</Typography>
                  </Box>
                </Box>

              </Box>

              <Box className='iGor_setFlex iGor_justifyBetween iGOr_bookingDetlsTotal'>
                <Box>
                  <Typography variant='body2'>Total</Typography>
                  {/* <Typography color={"#FF6B6B"}>This account will earn 2300 Points</Typography> */}
                </Box>
                <Box>
                  <Typography variant='body2'>{userBookingDetailsData.hotelbooking.currency} {userBookingDetailsData.hotelbooking.totalAmount}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
