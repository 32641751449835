import { Button, Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../appStore/hooks";
import Header from "../../layouts/Header";
import { usePrivacyPoliciesQuery } from "../../services/api/hotelApi";
import ReactParser from 'html-react-parser';
import { Box, Container } from "@mui/system";
import { Link } from "react-router-dom";
import BackArrow from '@mui/icons-material/KeyboardArrowLeft';

function PrivacyPolicies() {
    usePrivacyPoliciesQuery('')
    const { privacyPoliciesData } = useAppSelector(state => state.hotel)
    const privacyAndPoliciesUIData = privacyPoliciesData
    return (<>
        <Box bgcolor={'#ffffff'} marginBottom={4}>
            <Header name='iGoRoomHeader ' currClass='iGor_currColor' />
            <Button className='iGor__ProBackArrow'><Link to={'/'}><BackArrow /></Link></Button>
        </Box>
        <Container>
            <Typography fontSize={'1.75rem'} fontWeight={600}>Privacy Policy</Typography>
            <Grid container className='iGor__pageslink'>{ReactParser(privacyAndPoliciesUIData as any)}</Grid>
        </Container>
    </>
    );
}

export default PrivacyPolicies;