import React, { useEffect, useState } from 'react';
import { Grid, Modal, Box, IconButton, Button, Popover, Typography, TextField, InputAdornment, List, ListItem, Checkbox, ListItemButton, ListItemAvatar, ListItemText, Slider, Autocomplete } from "@mui/material";
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Close, Height } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { useAppSelector } from '../../appStore/hooks';
import { fetchHotesResultsDataFromApi, fetchOfferHotelsResult, fetchPaypalCurrencies, setHotelSearchRequestReset, setHotelsResultsDataReset, setLoadHotelResultSkeleton, setLoggedInUserData, setNoOfHitsResultApi } from '../../appStore/hotelSlice';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useDispatch } from 'react-redux';
import SyncProblem from '@mui/icons-material/SyncProblem';
import { countryResidenceAndCulture, popularCurrencies } from '../../utils/constants/constant';
import WorldFlag from 'react-world-flags';
import CountryCurrencyFlags from './CountryCurrencyFlags';
import { useInitApiMutation } from '../../services/api/hotelApi';
import initApiCall from '../../services/initApiCall';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  borderRadius: '10px',
};

export default function CurrenciesModal(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [currencyInput, setCurrencyInput] = useState('')
  const searchId = useAppSelector(state => state.hotel.initApi?.searchId);
  const hotelsResultData = useAppSelector(state => state.hotel.hotelResults.data);
  const { loggedInUserCurrency, NoOfHitsResultApi, getUserDetails, searchDestinationFromDate,
    searchDestinationToDate, roomArray, destinationsData, countryCurrenciesPaypal, countryCallingCodes } = useAppSelector(state => state.hotel)
  // const countriesCurrenciesData: any = useAppSelector(state => state.hotel?.countryCurrencies?.currencies);
  const countriesCurrenciesData: any = countryCurrenciesPaypal?.data?.currencies ? countryCurrenciesPaypal?.data?.currencies : [];
  const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD'
  const loggedInUserId = getUserDetails.data?.userDetail?.id ? getUserDetails.data?.userDetail?.id : 0;
  const loggedInUserCountryId = getUserDetails.data?.userDetail?.country_id;
  const paypalCurrenciesArray = countryCurrenciesPaypal?.data?.currencies ? countryCurrenciesPaypal?.data?.currencies : [];
  let locationId = destinationsData?.destinationCode
  const destinationSearchStartDate = searchDestinationFromDate;
  const destinationSearchEndDate = searchDestinationToDate;
  const hotelRoomArray = roomArray;
  const countryResidenceAndCultureInit = countryResidenceAndCulture;
  const pathName = window.location.pathname;
  const numberOfHitsResultApi = NoOfHitsResultApi;
  const countriesCallingCodeData: any = countryCallingCodes?.countries ? countryCallingCodes?.countries : [];
  const countryNationalCode = countriesCallingCodeData.find((country: any) => country.id == loggedInUserCountryId)?.iso2 || "MY";
  const countryOfResidence = countryNationalCode;
  const nationality = countryNationalCode;

  // init Api initialization
  const [initApiForHotel, initApiCallData] = useInitApiMutation();

  //country currencies data handler
  let countryCurrencies: any = [];
  countriesCurrenciesData ? countryCurrencies = countriesCurrenciesData?.map((currency: any) => {
    return {
      currencyName: currency.name,
      currencyCode: currency.code,
      currencyNumCode: currency.id,
      currencySymbol: currency.symbol,
      label: `${currency.name}(${currency.code})`
    }
  }) : ([]);

  const handleOpen = () => {
    setOpen(true)
    if (paypalCurrenciesArray.length <= 0) {
      dispatch(fetchPaypalCurrencies('paypal'))
    }
  };

  // popular currencies sortion on user input
  let filtereOtherCurrency = currencyInput
    ? countryCurrencies.filter((currency: any) => {
      return currency?.label?.toLowerCase().includes(currencyInput.toLowerCase())
    })
    : countryCurrencies;

  // close currency popup 
  const handleClose = () => {
    setOpen(false);
    setCurrencyInput('')
  }

  const initApiCallHandler = async (currencyCode: any) => {
    dispatch(setLoadHotelResultSkeleton(true))
    dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
    const payload = await initApiCall(currencyCode, destinationSearchStartDate, destinationSearchEndDate,
      hotelRoomArray, countryOfResidence, nationality, countryResidenceAndCultureInit.culture, locationId,
      loggedInUserId)
    initApiForHotel({ payload })
    // dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
  }


  // currency selection handler function
  const currencySelectionHandler = (e: any, selectedCurrencyOBJ: any) => {
    const loggedinUserDetails = {
      userId: loggedInUserId,
      currencyId: selectedCurrencyOBJ?.currencyNumCode,
      currencyName: selectedCurrencyOBJ?.currencyCode,
      currencySymbol: selectedCurrencyOBJ?.symbol ? selectedCurrencyOBJ?.symbol : '$',
      countryOfResidenceCode: countryNationalCode,
      nationality: countryNationalCode
    }
    AppLogHandler("e.target.value", loggedInUserCurrency)
    if (loggedinUserDetails?.currencyId != '') {
      dispatch(setLoggedInUserData(loggedinUserDetails))
    }
    if (pathName == '/search-results') {
      initApiCallHandler(selectedCurrencyOBJ.currencyCode)
      dispatch(setNoOfHitsResultApi(1))
    }
    else if (pathName.includes('/property-details')) {
      initApiCallHandler(selectedCurrencyOBJ.currencyCode)
      dispatch(setNoOfHitsResultApi(2))
    }
  }

  useEffect(() => {
    if (searchId && initApiCallData.status == "fulfilled" && hotelsResultData?.searchStatus == "Completed" && numberOfHitsResultApi == 1) {
      dispatch(fetchHotesResultsDataFromApi({ searchId, numberOfHitsResultApi }));
      dispatch(fetchOfferHotelsResult(searchId))
      dispatch(setNoOfHitsResultApi(2))
    }
  }, [searchId, initApiCallData.status, numberOfHitsResultApi, hotelsResultData?.searchStatus])

  return (
    <div>
      <IconButton onClick={handleOpen} className={props.setClass ? `iGor_CurrenciBtn ${props.setClass}` : 'iGor_CurrenciBtn'}>
        {/* <MonetizationOnRoundedIcon className='iGor_Curr_DollerIcon' />  */}
        <CountryCurrencyFlags currencyCode={loggendInUserCurrency} />
        {loggendInUserCurrency} <ArrowDropDownIcon className='iGor_Curr_Arrow' />
      </IconButton>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"

      >
        <>
          <Box sx={style} className='iGOr_DropDown iGoR_curr_Modal iGor_Modal' >
            <div className="modal-close">
              <IconButton onClick={handleClose} className="close-button">
                <Close />
              </IconButton>
            </div>


            <Box>
              <Typography variant="subtitle1" fontSize={'1.25rem'} marginBottom={'1.25rem'} >
                Select Currency</Typography>
            </Box>

            <Box className='iGOr_setFlex iGor_CurrSearch' >
              {/* <SearchIcon className=' iGor_CurrSearchIcon' /> */}
              <TextField sx={{ borderRadius: '10px' }} placeholder='Search' value={currencyInput} fullWidth onChange={(e: any) => setCurrencyInput(e.target.value)}></TextField>
              {/* <Autocomplete
                className='iGoR_autoCom'
                disablePortal
                options={countryCurrencies != null ? countryCurrencies : []}
                renderInput={(params) => <TextField {...params} placeholder={t('Search')} />}
                onChange={(value, data) => currencySelectionHandler(value, data)}
              /> */}
            </Box>

            <Box>

              {currencyInput == '' ? (<Grid container>
                <Grid item xs={12} marginY={1}>
                  <Typography variant='caption' className='iGor_Curr-subTitle'>
                    Popular currencies
                  </Typography>
                </Grid>
                {popularCurrencies.map((popularCurrency: any, index: any) => {
                  return (<>
                    <Grid key={popularCurrency.currencyCode} item sm={4} xs={6} onClick={(value: any) => currencySelectionHandler(value, popularCurrency)}>
                      <Box className={`iGor_CurrCard ${loggendInUserCurrency == popularCurrency.currencyCode ? 'iGor_CurrSelect' : ''}`} >
                        {/* <SyncProblem sx={{ marginRight: '15px' }} /> */}
                        <CountryCurrencyFlags currencyCode={popularCurrency.currencyCode} />
                        <Box width={'80%'}>
                          <Typography variant='caption' component="p">{popularCurrency.label}</Typography>
                          <Typography variant='caption' className='iGor_Curr_DollerVal'>{popularCurrency.currencyCode} - {popularCurrency.symbol}</Typography>
                        </Box>
                        {loggendInUserCurrency == popularCurrency.currencyCode ? (<Box>
                          <CheckCircleRoundedIcon className='iGor_Check' />
                        </Box>) : (null)}
                      </Box>
                    </Grid>
                  </>)
                })}
              </Grid>) : (null)}

            </Box>


            <Box>
              <Grid container>
                <Grid item xs={12} marginY={1}>
                  {currencyInput == '' ? (<Typography variant='caption' className='iGor_Curr-subTitle'>
                    Other Currencies
                  </Typography>) : (<Typography variant='caption' className='iGor_Curr-subTitle'>
                    Result
                  </Typography>)}
                </Grid>
              </Grid>

              <Grid container className='iGor_Curr-Scroll'>
                {filtereOtherCurrency.map((currencies: any, index: any) => {
                  return (
                    <Grid key={currencies.currencyCode} item sm={4} xs={6} onClick={(value: any) => currencySelectionHandler(value, currencies)}>
                      <Box className={`iGor_CurrCard ${loggendInUserCurrency == currencies?.currencyCode ? 'iGor_CurrSelect' : ''}`}>
                        <CountryCurrencyFlags currencyCode={currencies.currencyCode} />
                        <Box width={'80%'}>
                          <Typography variant='caption'>{currencies?.currencyName}</Typography>
                          <Typography variant='caption' className='iGor_Curr_DollerVal'>{currencies?.currencyCode} - {currencies?.currencySymbol}</Typography>
                        </Box>
                        {loggendInUserCurrency == currencies?.currencyCode ? (<Box>
                          <CheckCircleRoundedIcon className='iGor_Check' />
                        </Box>) : (null)}
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </Box>
          </Box>

        </>
      </Modal>
    </div >
  );
}