import { useDispatch } from "react-redux";
import { useAppSelector } from "../../appStore/hooks";
import { Box, Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { fetchHotelsRoomPriceDataFromApi, setAlertBoxOnError, setErrorMessageForDialogueBox } from "../../appStore/hotelSlice";
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useState } from "react";
import numeral from "numeral";
import { useTranslation } from "react-i18next";

function OrderSummaryAdditionalService() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue]: any = useState(null);
    const { roomRecommendationIndexSelected, roomIndexSelectedForCheckout, hotelRoomDetails, roomTypeSelectedForCheckout, initApi, selectedCurrencySymboll } = useAppSelector(state => state.hotel)
    const roomRecommendationIndexSelectedCheckout = roomRecommendationIndexSelected;
    const roomsReceivedFromApis = hotelRoomDetails?.data?.rooms ? hotelRoomDetails?.data?.rooms : [];
    const searchId = initApi.searchId;
    const currencySelected = roomTypeSelectedForCheckout?.listprice?.currency_symbol ? roomTypeSelectedForCheckout.listprice.currency_symbol : selectedCurrencySymboll;

    // additional services filter for current room selected 
    const [recommendationForCurrentRoom] = roomsReceivedFromApis ? roomsReceivedFromApis.filter((recco: any, index: any) =>
        index == roomIndexSelectedForCheckout
    ) : {}
    const additionalServicesArray = recommendationForCurrentRoom?.listprice?.roomRecommendation ? recommendationForCurrentRoom.listprice.roomRecommendation : [];
    const additionalServicesLastIndex = additionalServicesArray.length - 1;

    // add additional services handler
    const handleChange = (serviceSelected: any, index: number) => {
        const rateIdRoom = serviceSelected.id;
        const hotelId = roomTypeSelectedForCheckout?.propertyid ? roomTypeSelectedForCheckout?.propertyid : '';
        const supplierName = serviceSelected?.roomGroup[0]?.providerName ? serviceSelected.roomGroup[0]?.providerName : '';
        const refundable = serviceSelected?.roomGroup[0]?.refundability;
        if (searchId && hotelId && rateIdRoom && supplierName) {
            setSelectedValue(index);
            dispatch(fetchHotelsRoomPriceDataFromApi({ searchId: searchId, providerName: supplierName, priceId: rateIdRoom, hotelId: hotelId }))
        }
        // else if (refundable === 'NonRefundable') {
        //     dispatch(setErrorMessageForDialogueBox('21'))
        //     dispatch(setAlertBoxOnError(true))
        // }
        else {
            dispatch(setErrorMessageForDialogueBox('30'))
            dispatch(setAlertBoxOnError(true))
        }
    };

    // additional services component
    const additionalServices = additionalServicesArray.map((services: any, index: number) => {
        if (roomRecommendationIndexSelectedCheckout < index) {
            return (
                <Box key={services.id}>
                    <Box className='iGor_setFlex iGor_justifyBetween iGor_alignCenter'>

                        <Box className='iGor_setFlex iGor_justifyBetween iGor_alignCenter'>

                            <Radio checked={selectedValue === index}
                                onChange={(e) => handleChange(services, index)}
                                value="a"
                                name="radio-buttons"
                                className='iGor_radioBtn'
                                inputProps={{ 'aria-label': 'A' }} />
                            <Box marginLeft={'15px'}>
                                <Typography variant='body2'>{services.roomGroup[0]?.boardBasis?.description ? services.roomGroup[0]?.boardBasis?.description : 'Other'}</Typography>
                                <Typography variant='body1'>{services.roomGroup[0]?.refundability == "Refundable" ? "(Refundable)" : "(Non Refundable)"} { }</Typography>
                            </Box>
                            <Box>
                                {/* <Typography variant='body2' className='iGor_radioBtn_Tags'>{services.roomGroup[0]?.refundability == "Refundable" ? "( Refundable )" : "( Non Refundable )"}</Typography> */}
                                {/* <Typography variant='body1'>{services.roomGroup[0]?.boardBasis?.description ? services.roomGroup[0]?.boardBasis?.description : 'Other'}</Typography> */}
                            </Box>
                        </Box>

                        <Box className='iGor_setFlex'>
                            <Typography fontSize={'8px'} color={'#C4C4C4'}> <span className='iGor_amountBold'> {currencySelected}{numeral(services?.perNightTotal).format('0,0')}</span> / night</Typography>
                            <ArrowRightIcon width={'18px'} height={'18px'} />
                        </Box>
                    </Box>
                    {index != additionalServicesLastIndex ? (<Divider sx={{ margin: '0.625rem 0' }} />) : ''}
                </Box>
            );
        } else {
            return null;
        }
    });

    // if no additoanl service not available
    const noAdditionalServices = roomRecommendationIndexSelectedCheckout == additionalServicesLastIndex && (
        <Box key="no-services-message" className="iGor-noService-Available">
            {t('5013')}
        </Box>
    );
    return (<>
        {additionalServices}
        {noAdditionalServices}
    </>);
}

export default OrderSummaryAdditionalService;