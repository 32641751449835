import { initializeApp } from "firebase/app";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, OAuthProvider } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { appConfigFirebase } from "./environmentVariables";

// Initialize Firebase
const app = initializeApp(appConfigFirebase);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const AppleProvider = new OAuthProvider('apple.com');
const facebookProvider = new FacebookAuthProvider();
const analytics :any = getAnalytics(app);


export { auth, provider, AppleProvider, facebookProvider, analytics }