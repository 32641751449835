import Header from "./Header";
import layoutBg from '../assets/images/homeLanding.jpg'
import { Container, Box, Grid, useMediaQuery } from "@mui/material";
import { DestinationSearch } from "../pages/DestinationSearch/DestinationSearch";
import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { fetchDataAsyncStaticApi, fetchPaypalCurrencies, setAppliedPriceRangeFiltersForHotels, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels, setHotelSearchRequestReset, setHotelSelectedVrImgAndData, setHotelsResultsDataReset, setHotelsTypeTabSelection, setMobileAppliedPropertyTypeFilterForHotels, setMobileAppliedRoomTypeFilterForHotels, setMobileFiltersEnable, setNoOfNights, setOfferHotelsDataReset, setResetRoomArray, setSearchDestinationData, setSearchDestinationFromDate, setSearchDestinationName, setSearchDestinationToDate, setWebFiltersEnable } from "../appStore/hotelSlice";
import { useAppSelector } from "../appStore/hooks";
import noImageFound from '../assets/images/No Hotels Img.png'
import { env } from "../utils/config/appConfig";
import InfoFormobile from "../pages/InfoForMobile/InfoFormobile";
import { asyncThunkApiEndPoints, eventNameForMoeEvents } from "../utils/constants/constant";
import VTUrlParser from "../utils/utilities/VTUrlParser";
import { EventLogHanlderMoengage } from "../utils/handlers/EventLogsHandler";
import { environmentVariables } from "../utils/config/environmentVariables";

const Layout = ({ children }: any) => {
    const dispatch = useDispatch();
    const { getUIFiltersData, getPopularDestinationsForHotels, destinationForSearch, countryCallingCodes, countryCurrencies } = useAppSelector(state => state.hotel);
    const resetPriceRange = getUIFiltersData?.data?.priceRange;
    let popular: [any] = getPopularDestinationsForHotels?.popular ? getPopularDestinationsForHotels?.popular : []
    let destinationsSearch: [any] = destinationForSearch?.destinations ? destinationForSearch?.destinations : []
    let countryCodes: [any] = countryCallingCodes?.countries ? countryCallingCodes?.countries : []
    let countryCurrency: [any] = countryCurrencies?.currencies ? countryCurrencies?.currencies : []
    const countryCurrenciesPaypal = useAppSelector(state=>state.hotel.countryCurrenciesPaypal?.data); 
    let paypalCurrenciesArray: any = countryCurrenciesPaypal?.currencies ? countryCurrenciesPaypal?.currencies : [];

    // enpoints hotel
    const endPointPopularHotels = asyncThunkApiEndPoints.getPopularHotels;
    const endPointDestinationSearch = asyncThunkApiEndPoints.getDestinationsForHotels;
    const endPointCountryCodes = asyncThunkApiEndPoints.getCountryCallingCodes;
    const endPointContryCurrency = asyncThunkApiEndPoints.getCurrencies;
    const eventNameViewScreenMoe = eventNameForMoeEvents.ViewScreen;

    const [showLoading, setShowLoading] = useState(true)
    const iframeVal = popular ? popular?.map((popularDestinations: any) => {
        return popularDestinations ? popularDestinations : ['']
    }) : ['']

    const defaultImage = popular[0]?.heroimageurl ? popular[0]?.heroimageurl : layoutBg;
    const imagePathUrl = environmentVariables.imagePathApp;

    const [num, setNum] = useState(0);
    const arrayLength = iframeVal.length;
    const handleIncrement = () => {
        setNum((prevNum) => (prevNum + 1) % arrayLength);
    };

    const handleDecrement = () => {
        setNum((prevNum) => (prevNum - 1 + arrayLength) % arrayLength);
    };


    const timeoutId = setTimeout(() => {
        setShowLoading(false); // Hide loading message after 2 seconds
    }, 2000);

    // resetting all state on back arrow click
    const pathname = window.location.pathname
    const matches = useMediaQuery('(max-width:1024px)');
    const userVisitedBeforeApp = localStorage.getItem('visitedBefore')
    const [inFoHide, setInFoHide] = useState(false);
    const handlerRemove = useCallback(() => {
        setInFoHide(true)
        localStorage.setItem('visitedBefore', 'YES')
    },[inFoHide])

    // event logs for Moengage
    const moengageEventsLogHandler = () => {
        const eventIdsForLogEventsMoe = {
            "Home Screen": "Home Screen",
        }
        EventLogHanlderMoengage(eventNameViewScreenMoe, eventIdsForLogEventsMoe)
    }

// useEffect for reseting state on navigate home screen
    useEffect(() => {
        const fromDates = new Date().toISOString()
        moengageEventsLogHandler()
        const toDates = new Date((new Date()).setDate((new Date()).getDate() + 1)).toISOString()
        if (pathname == "/") {
            dispatch(setSearchDestinationData({ destinationName: '', destinationCode: '' }))
            dispatch(setSearchDestinationFromDate(fromDates))
            dispatch(setSearchDestinationToDate(toDates))
            dispatch(setResetRoomArray())
            dispatch(setNoOfNights(1))
            dispatch(setHotelSearchRequestReset({ status: '', searchId: '', searchTracingKey: '' }))
            dispatch(setHotelsTypeTabSelection(''))
            dispatch(setAppliedPropertyTypeFiltersForHotels([]))
            dispatch(setAppliedRoomTypeFiltersForHotels([]))
            dispatch(setMobileAppliedPropertyTypeFilterForHotels([]))
            dispatch(setMobileAppliedRoomTypeFilterForHotels([]))
            dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: resetPriceRange?.min_price, max_price: resetPriceRange?.max_price }))
            dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
            dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
            dispatch(setHotelSelectedVrImgAndData({ hotelName: '', hotelVrImg: '', hotelId: '', slugId: '' }))
            dispatch(setWebFiltersEnable(false))
            dispatch(setMobileFiltersEnable(false))
        }
    }, [pathname])

    const handleClearCookies = () => {
        const currentDate = new Date();
        currentDate.setSeconds(currentDate.getSeconds() - 1);
    
        // Set the cookie with an expiration date in the past
        document.cookie = `devts.igoroom.com=; expires=${currentDate.toUTCString()}; path=/;`;
        document.cookie = `igoroom.com=; expires=${currentDate.toUTCString()}; path=/;`
      };
    // dispatching api call on action
    useEffect(() => {
        handleClearCookies()
        dispatch(fetchDataAsyncStaticApi(endPointPopularHotels))
        dispatch(fetchDataAsyncStaticApi(endPointDestinationSearch))
        dispatch(fetchDataAsyncStaticApi(endPointCountryCodes))
        dispatch(fetchPaypalCurrencies('paypal'))
    }, [])

    // useEffect for updating states of the data received from api
    useEffect
        (() => {
            popular = getPopularDestinationsForHotels?.popular ? getPopularDestinationsForHotels?.popular : []
            destinationsSearch = destinationForSearch?.destinations ? destinationForSearch?.destinations : []
            countryCodes = countryCallingCodes?.countries ? countryCallingCodes?.countries : []
            countryCurrency = countryCurrencies?.currencies ? countryCurrencies?.currencies : []
            paypalCurrenciesArray = countryCurrenciesPaypal?.currencies ? countryCurrenciesPaypal?.currencies : [];


        }, [getPopularDestinationsForHotels, destinationForSearch, countryCallingCodes, countryCurrencies, countryCurrenciesPaypal])

    return (<>
        {iframeVal.length > 0 ? (
            <>
                <iframe
                    src={VTUrlParser(iframeVal[num]?.krviewurl)}
                    onError={(e: any) => {
                        e.target.src = noImageFound;
                    }}
                    id="landingPageIframe">
                </iframe>
                {showLoading && <Box className="iGoRoom_NotIfrome" sx={{ backgroundImage: `url(${imagePathUrl} ${defaultImage})` }}>
                </Box>}
            </>
        ) : (
            <Box className="iGoRoom_NotIfrome" sx={{ backgroundImage: `url(${imagePathUrl} ${defaultImage})` }}>
            </Box> // You can replace this with your custom loading message or spinner
        )}
        {matches && !inFoHide && userVisitedBeforeApp !== 'YES' ? <InfoFormobile handlerRemove={handlerRemove} /> : null}

        <div className="iGoRoom_LayoutMain" >
            <Header />
            <DestinationSearch destinationName={iframeVal[num]?.location} hotelName={iframeVal[num]?.name} destinationLocation={iframeVal[num]?.location} destinationOBJ={iframeVal[num]} incrFun={handleIncrement} decFun={handleDecrement} />

        </div>
    </>);
}
export default Layout;
