import { useEffect, useRef, useState } from "react";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setAlertBoxOnError, setErrorMessageForDialogueBox, setGateWaysAndHolidayMakerPopup, setGmailVerificationPopUp, setOTPSentTimer, setRegisterUserEmailAddress, setRegisterUserPassword, setRegisterUserStatus, setRegisteredUserDetailsReset, setSentOTPPhoneDataReset, setSignInCallApi, setUserActionLoader, setVerifyOTPPhoneDataReset } from "../../appStore/hotelSlice";
import { useAppSelector } from "../../appStore/hooks";
import { useRegisterUserMutation, useSendOTPPhoneMutation, useVerifyOTPForPhoneMutation } from "../../services/api/hotelApi";
import { getOTPForPhone, registerUserPayload, sendOTPForPhone } from "../../utils/constants/types";
import { EventNamesForEventLogs, authenticationTypeForApp, eventNameForMoeEvents } from "../../utils/constants/constant";
import { EventLogHanlderMoengage, EventLogsHandler } from "../../utils/handlers/EventLogsHandler";
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";
import { environmentVariables } from "../../utils/config/environmentVariables";
import OTPTimer from "./OTPTimer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

function EmailOTPVerification() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [verificationCodeLength, setVerificationCodeInputLength] = useState(6);
    const [verificationCode, setVerificationCode] = useState(['']);
    const [resendOTP, setResendOTP] = useState(false);
    const inputsRef = useRef<HTMLInputElement[]>([]);
    const { userRegisterEmailAddress, userRegisterPassword, registerUserCountryCall, sentOTPForPhoneData,
        userPhoneNumber, firstNameUser, lastNameUser, countryId, registerUserCurrencyData, userBirthDate,
        authenticationType, verifyOTPForPhoneData, socialMediaAuthenticationUserData, getUserEmailExist,
        setRegisteredUserDetails, sentOTPTimer, userActionLoader } = useAppSelector(state => state.hotel)
    const sentOTPMinutes = sentOTPTimer?.initialMinutes;
    const sentOTPSeconds = sentOTPTimer?.initialSeconds;
    const gmailUserFirebaseId = socialMediaAuthenticationUserData?.userFirebaseId != '' && socialMediaAuthenticationUserData?.userFirebaseId ? socialMediaAuthenticationUserData?.userFirebaseId : '';
    const emailAuthentication = authenticationTypeForApp.emailAuth;
    const userPassword = authenticationType == emailAuthentication ? userRegisterPassword : '';
    const phoneVerifyedStatus = verifyOTPForPhoneData?.oTPVerifResp?.status
    const registeredUserDetails = setRegisteredUserDetails?.userDetail;
    const registeredUserStatus = setRegisteredUserDetails?.status;
    const registeredMessage = setRegisteredUserDetails?.message;
    const [phoneVerifyed, setPhoneVerifyed] = useState(false);
    const [gmailVerifyed, setGmailVerifyed] = useState(false);
    const [inValidOTP, setInValidOTP] = useState(false);
    const authenticationTypeForEvent = authenticationType.toLowerCase()
    const eventNameRegister = EventNamesForEventLogs.completeRegistration;
    const userEmailExist = getUserEmailExist?.data?.status
    let gmailVerificationCode = verificationCode.join('')
    const EmailVerificationApiStatus = verifyOTPForPhoneData?.statusCode ? verifyOTPForPhoneData?.statusCode : '';
    const EmailVerifationStatus = verifyOTPForPhoneData?.oTPVerifResp?.status ? verifyOTPForPhoneData?.oTPVerifResp?.status : '';
    const EmailVerifationMessage = verifyOTPForPhoneData?.message ? verifyOTPForPhoneData?.message : '';
    const sidForOTPVerifyPhone = sentOTPForPhoneData?.otpResponse?.sid ? sentOTPForPhoneData?.otpResponse?.sid : '';
    const eventNameSignUpMoe = eventNameForMoeEvents.Signup;
    const countryCallingId = registerUserCountryCall?.countryCallingId;
    const IdentifierTypeForVerifyOTP = 'Email'
    const verficationCodeForPhone = `${gmailVerificationCode}`;
    const emailForVerification = userRegisterEmailAddress ? userRegisterEmailAddress : '';
    const enviornment = environmentVariables.enviornment;
    const actionLoader = userActionLoader;

    // function for format user birthdate for api payload
    const formatDateToYYYYMMDD = (dateString: any) => {
        const dateObj = new Date(dateString);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const formatteUserdBirthDate = formatDateToYYYYMMDD(userBirthDate);

    // register user api initialize
    const [registerUser] = useRegisterUserMutation();

    // send OTP to phone api initialize
    const [sendOTP] = useSendOTPPhoneMutation();
    const [verifyOTPPhone] = useVerifyOTPForPhoneMutation();

    // verify email api call
    const EmailVerificationApiHanlder = () => {
        const payload: getOTPForPhone = {
            Identifier: emailForVerification,
            IdentifierType: IdentifierTypeForVerifyOTP,
            verificationCode: verficationCodeForPhone,
            sid: `${sidForOTPVerifyPhone}`
        }
        verifyOTPPhone({ payload })
    }

    // email verification handler function
    const gmailVerificationHandler = () => {
        dispatch(setUserActionLoader({ action: 'verifyOTP', loading: true }))
        if (gmailVerificationCode.trim().length == 6) {
            if (enviornment !== 'development') {
                EmailVerificationApiHanlder()
            }
            else {
                setGmailVerifyed(true)
                setPhoneVerifyed(true)
            }
        }
        else if (gmailVerificationCode.trim().length < 6) {
            setInValidOTP(true)
            setGmailVerifyed(false)
            setPhoneVerifyed(false)
            dispatch(setUserActionLoader({ action: '', loading: false }))
        }
    }

    // Function to handle code changes
    const handleCodeChange = (index: number, event: any) => {
        setInValidOTP(false)
        const code = event.target.value; // Limit code length to 1 character
        setVerificationCode((prevCodes) => {
            const newCodes = [...prevCodes];
            newCodes[index] = code;
            return newCodes;
        });

        // Move focus to the next input field (if available)
        if (code && index < 5) {
            inputsRef.current[index + 1].focus();
        } else if (event.target.value === '' && index > 0) {
            // Check if Backspace was pressed (value is empty)
            event.preventDefault(); // Prevent the default Backspace behavior
            inputsRef.current[index - 1].focus(); // Move focus to the previous input field
        }
    };

    const registerUserHandler = (firebaseId: any, password: any, authetype: any) => {
        const payload: registerUserPayload = {
            userRegisterDetails: {
                firebaseId: firebaseId,
                email: userRegisterEmailAddress,
                password: password,
                phone: `+${countryCallingId}${userPhoneNumber}`,
                firstName: firstNameUser,
                LastName: lastNameUser,
                birthDate: formatteUserdBirthDate,
                country_id: countryId,
                currency_id: registerUserCurrencyData.currencyId,
                role: "user",
                role_id: 3,
                referal_code: '',
                loggedInWith: authetype,
                emailVerified: gmailVerifyed,
                phoneVerified: phoneVerifyed
            }

        }
        registerUser({ payload })
        dispatch(setRegisterUserPassword(userRegisterPassword))
        dispatch(setRegisterUserStatus(false))
        // setVerificationCode([''])
        // setEmailAddress('')
    }

    const addMobileNoMoengageHanlder = () => {
        (window as any).Moengage.add_mobile(`+${countryCallingId} ${userPhoneNumber}`).then((e: any) => {
            AppLogHandler('success', e)
            return e
        }).catch((error: any) => {
            AppLogHandler('error', error)
        });
    }

    // resend verification code to email
    const resendVerificationCodeEmail = () => {
        if (emailForVerification != '') {
            const payload: sendOTPForPhone = {
                Identifier: [emailForVerification],
                IdentifierType: 'Email'
            }
            sendOTP({ payload })
            setResendOTP(false)
            dispatch(setOTPSentTimer({ initialMinutes: 2, initialSeconds: 30 }))
        }
    }

    useEffect(() => {
        if (EmailVerificationApiStatus == 'OK' && EmailVerifationMessage.includes('User email verification done') && EmailVerifationStatus == 'approved') {
            dispatch(setSentOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
            setPhoneVerifyed(true)
            setGmailVerifyed(true)
        }
        else if (EmailVerificationApiStatus == 'OK' && !EmailVerifationMessage.includes('User email verification done') && !EmailVerifationMessage.includes('User mobile verification done')) {
            dispatch(setUserActionLoader({ action: '', loading: false }))
            setGmailVerifyed(false)
            setPhoneVerifyed(true)
            dispatch(setErrorMessageForDialogueBox(EmailVerifationMessage))
            dispatch(setAlertBoxOnError(true))
            dispatch(setSentOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
        }
        else if (EmailVerificationApiStatus == 'BadRequest' && EmailVerifationStatus !== 'approved') {
            dispatch(setUserActionLoader({ action: '', loading: false }))
            dispatch(setErrorMessageForDialogueBox(EmailVerifationMessage))
            dispatch(setAlertBoxOnError(true))
            setGmailVerifyed(false)
            setPhoneVerifyed(true)
            dispatch(setSentOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
        }
        if (sentOTPMinutes == 0 && sentOTPSeconds == 0) {
            setResendOTP(true)
        }
    }, [EmailVerificationApiStatus, EmailVerifationMessage, sentOTPMinutes, sentOTPSeconds])

    useEffect(() => {
        if (gmailVerifyed == true && phoneVerifyed == true && registeredMessage == '') {
            registerUserHandler(gmailUserFirebaseId, userPassword, authenticationType)
        }
        if (registeredUserStatus == 'OK' && registeredMessage == 'User Created Successfully.' && registeredUserDetails?.email && gmailVerifyed == true && phoneVerifyed == true) {
            dispatch(setUserActionLoader({ action: 'signIn', loading: true }))
            dispatch(setVerifyOTPPhoneDataReset({ message: '', oTPVerifResp: {}, statusCode: '' }))
            setGmailVerifyed(false)
            setPhoneVerifyed(false)
            dispatch(setSignInCallApi(true))
            dispatch(setGateWaysAndHolidayMakerPopup(true))
            dispatch(setGmailVerificationPopUp(false))
            addMobileNoMoengageHanlder()
            // event log to analytics
            EventLogsHandler(eventNameRegister, { ig_registration_method: authenticationTypeForEvent })

            // event logs for Moengage
            const eventIdsForLogEventsMoe = {
                "Signup": "Signup"
            }
            EventLogHanlderMoengage(eventNameSignUpMoe, eventIdsForLogEventsMoe)
        }
        else if (registeredUserStatus == 'OK' && registeredMessage !== 'User Created Successfully.' && registeredUserDetails?.email && gmailVerifyed == true && phoneVerifyed == true) {
            dispatch(setUserActionLoader({ action: '', loading: false }))
            setGmailVerifyed(false)
            setPhoneVerifyed(false)
            // dispatch(setSignInCallApi(true))
            dispatch(setErrorMessageForDialogueBox(registeredMessage))
            dispatch(setAlertBoxOnError(true))
            dispatch(setRegisteredUserDetailsReset({ status: '', message: '', userDetail: [''] }))
            // dispatch(setGateWaysAndHolidayMakerPopup(true))
            // dispatch(setGmailVerificationPopUp(false))
        }
        else if (registeredUserStatus == 'BadRequest') {
            dispatch(setUserActionLoader({ action: '', loading: false }))
            dispatch(setErrorMessageForDialogueBox(registeredMessage))
            dispatch(setAlertBoxOnError(true))
            setGmailVerifyed(false)
            setPhoneVerifyed(false)
            dispatch(setRegisteredUserDetailsReset({ status: '', message: '', userDetail: [''] }))
        }
    }, [EmailVerifationStatus, gmailVerifyed, phoneVerifyed, registeredUserStatus])

    return (<>
        <Box sx={{ borderBottom: '1px solid #E5E5E5' }}>
            <Typography align='center' variant="subtitle1" sx={{ padding: '22px  0px 22px 0px' }}>
                {t('Input verification code')}
            </Typography>
        </Box>
        <Box sx={{ padding: '0.938rem 1.25rem  0.313rem' }}>
            <>
                <Typography align='center' sx={{ fontSize: '8px', color: '#757575' }} >
                    {t('We’ve sent you verification 6 digit code by Email. Please input code here')}
                </Typography>
                <Box className='iGor_setFlex iGor_justifyCenter'>
                    {[...Array(verificationCodeLength)].map((code: any, index: any) => {
                        return (<>
                            <input
                                key={index}
                                type="text" // Use type="number" if you prefer a numeric keypad on mobile devices
                                value={code}
                                name={`verificationCodeEmail${index}`}
                                onChange={(event) => handleCodeChange(index, event)}
                                maxLength={1}
                                ref={(input) => (inputsRef.current[index] = input!)}
                                className='iGor_verCodeinputEmail'
                            />
                        </>)
                    })}
                </Box>
                {inValidOTP ? (<Typography align='center' sx={{ fontSize: '12px', color: 'red' }}>Please Input Valid OTP</Typography>) : (<></>)}
                <Button
                    variant="contained"
                    sx={{ margin: '10px 0' }}
                    onClick={gmailVerificationHandler}
                    disabled={actionLoader?.loading == true ? true : false}
                >
                    {actionLoader?.action == 'verifyOTP' && actionLoader?.loading == true ? <Box className='iGor-action_loader'>
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </Box> :
                        'Continue'}
                </Button>

                <Box className={`iGor-resendOTP_box ${resendOTP == true ? 'increase_height' : ''}`}>
                    <Typography align='center' sx={{ marginRight: '2px' }}>
                        {t('Didn’t get any code?')}
                    </Typography>
                    {resendOTP == true ? (<Typography
                        style={{ cursor: resendOTP == true ? 'pointer' : 'unset', textDecoration: 'underline' }}
                        onClick={resendOTP ? resendVerificationCodeEmail : () => { }}
                    >
                        Resend verification
                    </Typography>) : (<OTPTimer />)}
                </Box>
            </>
        </Box>
    </>);
}

export default EmailOTPVerification;