import * as React from 'react';
import { Button, ButtonGroup } from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
export const DestinationChildAge = (props:any) => {
    const {count,index,increment,decrement}=props
    return (
        <>
            <ButtonGroup variant="text" aria-label="group" className='iGoRoom_CountInput'>
                <Button variant="text" className='iGor__childAge' disabled={count <= 1} onClick={() => decrement(index)}><RemoveIcon sx={{ width: '13px', height: '13px' }} /></Button>
                <Button variant="text"> {count} </Button>
                <Button variant="text" className='iGor__childAge' disabled={count >= 12} onClick={() => increment(index)} ><AddIcon sx={{ width: '13px', height: '13px' }} /></Button>
            </ButtonGroup>
        </>
    )
}