import React, { useEffect, useState } from 'react';
import { Grid, Box, Container, Typography, IconButton, TextField } from "@mui/material";
import locationIcon from '../../assets/icons/location-pin.svg';
import { NeedHelp } from '../../components/popUpSection/NeedHelp';
import { DestinationAutocomplete } from '../../components/DestinationsHotelsResults/DestinationAutocomplete';
import { useTranslation } from 'react-i18next';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { ExploreDestination } from '../../components/ExploreDestination/ExploreDestination';
import { useAppSelector } from '../../appStore/hooks';
import { Link, useNavigate } from 'react-router-dom';
import initApiCall from '../../services/initApiCall';
import { useDispatch } from 'react-redux';
import { fetchHotelDetailsFromApi, fetchHotelsRoomsDataFromApi, setHotelDetails, setHotelRoomDetails, setPreviousPathName, setSearchDestinationFromDate, setSearchDestinationToDate } from '../../appStore/hotelSlice';
import { countryResidenceAndCulture } from '../../utils/constants/constant';
import { useInitApiMutation } from '../../services/api/hotelApi';
import Icon_searchRed from '../../assets/icons/Icon_searchRed.svg';
import { calculateDateFourtyFiveDaysLater, dateFormatedForUrls, getThreeMonthsLaterDate } from '../../utils/utilities/DateConverter';
import { environmentVariables } from '../../utils/config/environmentVariables';


export const DestinationSearch = (props: any) => {
    // t object for language translation for keys
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [addClass, setAddClass] = useState(false);
    const [searchPopularHotel, setSearchPopularHotel] = useState(false);
    const [stepSecond, setstepSecond] = useState(true);
    const [destinationSelectedObj, setDestinationSelectedObj]: any = useState({});
    const matches = useMediaQuery('(max-width:1024px)');
    const { initApi, loggedInUserCurrency, hotelDetails, searchDestinationFromDate, searchDestinationToDate, roomArray } = useAppSelector(state => state.hotel)
    const hotelId = props?.destinationOBJ?.id
    const searchId = initApi?.searchId;
    const todaysDate = new Date();
    const tommorowsDate = new Date((new Date()).setDate((new Date()).getDate() + 1));
    const destinationSearchStartDate = getThreeMonthsLaterDate(todaysDate);
    const destinationSearchEndDate = getThreeMonthsLaterDate(tommorowsDate);
    const startDateFormated = dateFormatedForUrls(destinationSearchStartDate);
    const endDateFormated = dateFormatedForUrls(destinationSearchEndDate);
    const hotelRoomArray = roomArray
    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const destinationID = props?.destinationOBJ?.locationId;
    const destinationName = props?.destinationOBJ?.location;
    const hotelNameForSlug = props?.destinationOBJ?.name;
    const slug = props?.destinationOBJ?.slug;
    const hotelDetailsFromApi = hotelDetails.data;
    const countryResidenceAndCultureInit = countryResidenceAndCulture;
    const [serchField, setSerchField] = useState(false);
    const [destinationShow, setDestinationShow] = useState(false);
    const [stepNumber, setStepNumber] = useState(0);
    const environment = environmentVariables.enviornment;
    const locationTitle= props?.destinationName;
    const pathName = window.location.pathname;

    // init api mutation intialization
    const [initApiForHotel] = useInitApiMutation();

    const handleToggleClass = (open: any) => {
        setAddClass(open)
    }
    const handleAnimationInput = () => {
        setSerchField(!serchField);
        setTimeout(() => setDestinationShow(!destinationShow), 900);

    }
    // popular Destinations Selection Handler
    const popularDestinationSelectionHandler = () => {
        if(matches){dispatch(setPreviousPathName('/'))}
        dispatch(setSearchDestinationFromDate(destinationSearchStartDate))
        dispatch(setSearchDestinationToDate(destinationSearchEndDate))
        dispatch(setHotelRoomDetails({ data: [] }))
        dispatch(setHotelDetails({ data: [] }))
        const payload = initApiCall(loggendInUserCurrency, destinationSearchStartDate, destinationSearchEndDate,
            hotelRoomArray, countryResidenceAndCultureInit.countryOfResidence, countryResidenceAndCultureInit.nationality,
            countryResidenceAndCultureInit.culture, destinationID, loggedInUserId)
        initApiForHotel({ payload })
        setSearchPopularHotel(true)
        setDestinationSelectedObj({
            destinationName: destinationName.replaceAll(" ", "-"),
            locationId: destinationID,
            hotelId: hotelId,
            slugId: slug ? slug : hotelNameForSlug.replaceAll(" ", "-")
        })
    }


    const handleStepNumUpdate = (index: any, operation: any) => {
        if (operation === 'increment') {
            setStepNumber((stepNumber) => stepNumber + 1);
        } else if (operation === 'decrement') {
            if (stepNumber === 0) {
                setDestinationShow(!destinationShow);
                setSerchField(!serchField);
                setStepNumber(0);
            } else if (stepNumber > 0) {
                setStepNumber((stepNumber) => stepNumber - 1);
                setstepSecond(true)
            }
        }
    };

    // use Effect for navigate to hotel detail after click popular destination details screen
    useEffect(() => {
        // if (hotelDetailsFromApi.success == true && searchPopularHotel == true) {
        // }
        if (searchId && hotelId && searchPopularHotel == true) {
            const slugId = destinationSelectedObj.slugId.toLowerCase();
            const destinationName = destinationSelectedObj.destinationName;
            const locationId = destinationSelectedObj.locationId;
            const hotelId = destinationSelectedObj.hotelId;
            // dispatch(fetchHotelDetailsFromApi({ searchId: searchId, hotelId: hotelId }))
            // dispatch(fetchHotelsRoomsDataFromApi({ searchId: searchId, hotelId: hotelId }))
            setSearchPopularHotel(false)
            navigate(`/property-details/${slugId}?hotelId=${hotelId}&destinationName=${destinationName}&destinationId=${locationId}&checkIn=${startDateFormated}&checkout=${endDateFormated}&currency=${loggendInUserCurrency}`)
        }
    }, [searchId, hotelId, hotelDetailsFromApi, searchPopularHotel, destinationSelectedObj])

    return (
        <>
            <Box className='iGor__DesSearchBar'>
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={3} sx={{ textAlign: `${matches ? 'center' : ''}` }}>
                            <Typography className='iGor_IframeCity' variant='subtitle1'>
                                <img src={locationIcon} alt="locationIcon" />
                                {props?.destinationLocation}
                            </Typography>
                            {pathName.includes('explore') ? <Typography className='iGor_IframeTitle iGOr_mb-D-none ' variant="h1">
                                {locationTitle}
                            </Typography> :
                                <Typography className='iGor_IframeTitle iGOr_mb-D-none ' variant="h1" onClick={popularDestinationSelectionHandler}>
                                    {hotelNameForSlug}
                                </Typography>}
                            <Box className='IGor_Mb-Destination'>
                                <IconButton onClick={props.decFun} >
                                    <ArrowBackIosIcon className='iGor_IframeBtn' />
                                </IconButton>
                                {matches ?
                                    pathName.includes('explore') ?
                                        (<Typography className='iGor_IframeTitle' variant="h1">
                                            {locationTitle}
                                        </Typography>) :
                                        (<Typography className='iGor_IframeTitle' variant="h1" onClick={popularDestinationSelectionHandler}>
                                            {hotelNameForSlug}
                                        </Typography>) : ''
                                }
                                <IconButton onClick={props.incrFun}>
                                    <ArrowForwardIosIcon className='iGor_IframeBtn' />
                                </IconButton>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12} lg={7} >

                            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: "column" }} >
                                {props.setPageShow != 'Explore' && matches ?
                                    <Box className={'InputField iGor_DestAutoTitBtn   iGor__SearchCopy ' + (serchField && ' iGor__SearchCopytop')} onClick={handleAnimationInput}>
                                        <img src={Icon_searchRed} alt='SearchIcon' />
                                        <Typography padding={' 0.9375rem '} fontSize={'12px'} color={'#C4C4C4'}>
                                            Where do you want to go?
                                        </Typography>
                                    </Box> : null}
                                <Box className={'iGor__destinMBshow ' + (destinationShow && matches ? ' iGOr_DestiMB ' : props.setPageShow === 'Explore' && 'iGo__ExploreSearch')}>
                                    <Box className="iGor_destiMBSection">
                                        {props.setPageShow === 'Explore' ?
                                            <ExploreDestination />
                                            :
                                            <>
                                                {destinationShow && matches &&
                                                    <Box onClick={() => { handleStepNumUpdate(stepNumber, 'decrement'), setAddClass(false) }} className='iGor_DestiMb_arrow'>
                                                        <ArrowBackIosNewIcon />
                                                    </Box>}
                                                <DestinationAutocomplete
                                                    setclass={handleToggleClass}
                                                    showOpen={serchField}
                                                    stepNumUpdate={handleStepNumUpdate}
                                                    stepNumber={stepNumber}
                                                    stepSecond={stepSecond}
                                                    setstepSecond={setstepSecond}
                                                />
                                            </>
                                        }
                                    </Box>
                                </Box>
                                {!addClass &&
                                    <Box className="iGor__footerBox">
                                        <Typography className='iGor_footer-link' variant="h5">
                                           {t('© Copyright')}</Typography>
                                        <a className='iGOr_mb-D-none' href='/about-us' target='_blank'>{t('About Us')}</a>
                                        {/* <Link className='iGOr_mb-D-none' to='/'>{t("Blog")}</Link> */}
                                        <a className={matches ? 'iGOr_mb-footer-link' : ''} href='/privacy-policy' target='_blank'>{t('Privacy')}</a>
                                        <a className={matches ? 'iGOr_mb-footer-link' : ''} href='/terms-conditions' target='_blank'>{t('Terms')}</a>
                                        <a className={matches ? 'iGOr_mb-footer-link' : ''}
                                            href='https://support.igoroom.com/support/home'
                                            target='_blank' style={{ cursor: 'pointer', color: 'black' }}>
                                            {t("Support")}
                                        </a>
                                    </Box>
                                }
                                {environment !== 'PROD' ? (<Typography className='iGor_verSion' variant="h5">
                                    Version : 1.0.63</Typography>) : ''}
                            </Box>

                        </Grid>
                        <Grid item xs={12} md={12} lg={2} className='iGOr_mb-D-none'>
                            <Box sx={{ display: 'flex', justifyContent: 'right', }}>
                                <NeedHelp />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}
