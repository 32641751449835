import MainRoutes from './routes/routes';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import theme from './utils/handlers/themeProvider';
import { useEffect } from 'react';
import { EventLogsHandler } from './utils/handlers/EventLogsHandler';
import ErrorShowMod from './components/popUpSection/ErrorShowMod';
import { useAppSelector } from './appStore/hooks';
import { HelmetProvider } from 'react-helmet-async';
import SEOPropertyDetails from './utils/utilities/SEOPropertyDetails';

function App() {
  const { errorMessageForDialogue, openAlertBoxOnError } = useAppSelector(state => state.hotel)
  let errorMessage = errorMessageForDialogue;
  let openAlertBox = openAlertBoxOnError
  const helmetContext = {};
  useEffect(() => {
    errorMessage = errorMessageForDialogue;
    openAlertBox = openAlertBoxOnError
  }, [errorMessageForDialogue, openAlertBoxOnError])
  
  return (<>
    <HelmetProvider context={helmetContext}>
    <SEOPropertyDetails
          title='igoroom'
          description='Beginner friendly page for learning React Helmet from home screen.'
          name='Techspian'
          type='article' />
      <CssVarsProvider theme={theme}>
        <MainRoutes />
        <ErrorShowMod open={openAlertBox} message={errorMessageForDialogue} />
      </CssVarsProvider>
    </HelmetProvider>
  </>
  );
}

export default App;