import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material';
import Icon_info from '../../assets/icons/Icon_info.svg';
import { Circle } from '@mui/icons-material';
import convertDateFormat from '../../utils/utilities/DateConverter';
import { useTranslation } from 'react-i18next';
import { useGetUserNotificatiobsMutation, useUpdateUserNotificationsMutation } from '../../services/api/hotelApi';
import { getNotificationsPayload } from '../../utils/constants/types';
import { countryResidenceAndCulture } from '../../utils/constants/constant';
import { useAppSelector } from '../../appStore/hooks';


const InboxMassgaeCards = ({ userMessages}: any) => {
    const { t } = useTranslation();
    const { loggedInUserCurrency } = useAppSelector(state => state.hotel)
    const userMessagesArray = userMessages ? userMessages : [];
    const culture = countryResidenceAndCulture.culture;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;

    // get user notigification, messages and  update notifications api initialization
    const [getUserNotification] = useGetUserNotificatiobsMutation();
    const [updateNotification, apiStatus] = useUpdateUserNotificationsMutation();

    // message update hanlder
    const readMessageClickHandler = (notificationId: any) => {
        const payload = {
            notificationId: notificationId
        }
        updateNotification({ payload })
    }

    // get Notification hanlder 
    const getNotificationsHanlder = () => {
        const payload: getNotificationsPayload = {
            userDetails: {
                userId: `${loggedInUserId}`,
                date: "",
                culture: culture
            }
        }
        getUserNotification({ payload })
    }


    useEffect(() => {
        if (apiStatus?.data?.statusCode == "OK" && apiStatus?.data?.message?.includes("Updated Successfully")) {
            getNotificationsHanlder()
        }
    }, [apiStatus.data])

    return (<>{userMessagesArray.length > 0 ? userMessagesArray?.map((message: any) => {
        return (<Box key={message.id} sx={{ paddingBottom: '5px', borderBottom: '1px solid #C4C4C4', marginBottom: '15px', pointerEvents: message.isRead ? 'none' : 'auto' }} onClick={() => { readMessageClickHandler(message.id) }}>
            <Box className='iGor-messageUnread'>
                <Typography variant='body1'><img src={Icon_info} />  Info • {convertDateFormat(message.created_at)}</Typography>
                {message.isRead == false ? (<Circle />) : ''}
            </Box>
            <Typography variant='body1' fontWeight={600} my={'5px'}>{message.title}.</Typography>
            <Typography variant='body1' >{message.message}.</Typography>
        </Box>)
    }) : (<Box sx={{ fontSize: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '60px' }}>
        <Typography variant='body1' fontWeight={600} my={'5px'}>{t('5010')}</Typography>
    </Box>)}
    </>
    )
}

export default InboxMassgaeCards