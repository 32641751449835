import React, { useEffect, useState } from "react";
import { Link, Modal, Box, IconButton, Button, Popover, Typography, TextField } from "@mui/material";
import { Close } from '@mui/icons-material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useTranslation } from "react-i18next";
import PasswordCustom from "../inputFieldCustom/PasswordCustom";
import { useAppSelector } from "../../appStore/hooks";
import { useForgotPasswordUserMutation } from "../../services/api/hotelApi";
import { emailValidations } from "../../utils/constants/constant";
import { ToastContainer, toast } from "react-toastify";
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";
import { useDispatch } from "react-redux";
import { setAlertBoxOnError, setErrorMessageForDialogueBox, setForgotPasswordDataReset } from "../../appStore/hotelSlice";
import ToastSuccessMessageContainer from "../../utils/utilities/ToastSuccesMessageContainer";
import { Link as LinkRoute } from 'react-router-dom';
import ErrorShowMod from "./ErrorShowMod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#F1F5F9',
    borderRadius: '15px',
    maxWidth: '515px',
};

export default function ForgetPasswordModel() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setInputUserEmail('')
        setEmailValidator(false)
        setOpen(false)
    };
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [ForgetPasswordDiv, setForgetPasswordDiv] = useState(1)
    const [inputUserEmail, setInputUserEmail] = useState('')
    const [emailValidator, setEmailValidator] = useState(false)
    const [forgotPasswordCall, setUserForgotPasswordCall] = useState(false)
    const { forgotPasswordUserDetails } = useAppSelector(state => state.hotel)
    let passwordForgotData: any = forgotPasswordUserDetails ? forgotPasswordUserDetails : { status: '', message: '' }
    AppLogHandler("passwordForgotMessage", passwordForgotData)
    // forgot password api mutation.
    const [userForgotPasswordApi, userFogotPassApiRes] = useForgotPasswordUserMutation();

    const setUserEmailInputHandler = (e: any) => {
        const { value } = e.target;
        setInputUserEmail(value)
        setEmailValidator(false)
        setUserForgotPasswordCall(false)
    }

    // payload body for forgot password api
    const payload = {
        emailData: {
            email: inputUserEmail
        }
    }

    const forgotPasswordStatusHandler = (data: any) => {
        if (data == 'Successful') {
            setOpen(false);
            setInputUserEmail('')
            setUserForgotPasswordCall(false)
            dispatch(setForgotPasswordDataReset({ status: '', message: '' }))
        }
        if (data == 'This email is not associated with email/password') {
            dispatch(setForgotPasswordDataReset({ status: '', message: '' }))
            setUserForgotPasswordCall(false)
        }
        if (data == 'Invalid EmailId or User not found.') {
            dispatch(setForgotPasswordDataReset({ status: '', message: '' }))
            setUserForgotPasswordCall(false)
        }
        if (data.status && data.status !== 'OK') {
            dispatch(setErrorMessageForDialogueBox(passwordForgotData.message))
            dispatch(setAlertBoxOnError(true))
            dispatch(setForgotPasswordDataReset({ status: '', message: '' }))
        }
    }

    // forgot password call
    useEffect(() => {
        passwordForgotData = forgotPasswordUserDetails;
        switch (passwordForgotData.message) {
            case 'Successful':
                ToastSuccessMessageContainer(t('1001') + inputUserEmail)
                forgotPasswordStatusHandler(passwordForgotData.message)
                break;

            case 'This email is not associated with email/password':
                dispatch(setErrorMessageForDialogueBox('8'))
                dispatch(setAlertBoxOnError(true))
                forgotPasswordStatusHandler(passwordForgotData.message)
                break;

            case 'Invalid EmailId or User not found.':
                dispatch(setErrorMessageForDialogueBox('9'))
                dispatch(setAlertBoxOnError(true))
                forgotPasswordStatusHandler(passwordForgotData.message)
                break;
            default:
                forgotPasswordStatusHandler(passwordForgotData)
        }
        if (inputUserEmail && forgotPasswordCall == true) {
            userForgotPasswordApi({ payload })
            setUserForgotPasswordCall(false)
        }
    }, [forgotPasswordCall, inputUserEmail, payload.emailData.email, passwordForgotData.message, forgotPasswordUserDetails])

    const setUserForgotPasswordHandler = () => {
        if (inputUserEmail.length > 0 && inputUserEmail.match(emailValidations)) {
            setUserForgotPasswordCall(true)
            setEmailValidator(false)
        }
        if (inputUserEmail == '' || !inputUserEmail.match(emailValidations)) {
            setUserForgotPasswordCall(false)
            setEmailValidator(true)
        }
    }



    return (
        <div>
            <Link href="#" underline="hover" sx={{ fontSize: '10px', fontWeight: '500', color: { xs: "#000000", sm: "#FF6B6B" }, textDecoration: { xs: 'underline', sm: 'none' } }} onClick={handleOpen}>
                {t('Forgot password')}
            </Link>

            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <>
                    <Box sx={style} className='iGor_Modal iGor_forgetPass'>
                        <div className="modal-close">
                            <IconButton onClick={handleClose} className="close-button">
                                <Close />
                            </IconButton>
                        </div>
                        <Box className='iGor-Text-center' padding={2}>

                            <Box sx={{ marginBottom: '0.625rem' }}>
                                <Typography align='center' variant="subtitle1"  >
                                    {ForgetPasswordDiv == 1 && t('Forgot your password')}
                                    {ForgetPasswordDiv == 2 && t('Reset my Password')}

                                </Typography>
                            </Box>


                            {ForgetPasswordDiv == 1 && <>
                                <Box sx={{ margin: '1.25rem 0' }}>
                                    <Typography align='center' variant="caption" sx={{ color: '#757575' }} >
                                        {t(' Please input your email address that you registered for Igoroom')}
                                    </Typography>
                                </Box>
                                <Box className='InputField iGor_setRelative'>
                                    <MailOutlineIcon className='inputIcons ' />
                                    <TextField
                                        placeholder={t('Email Address')}
                                        value={inputUserEmail}
                                        type='email'
                                        onChange={setUserEmailInputHandler}
                                        helperText={emailValidator ? t('9') : ''}
                                        error={emailValidator}
                                    />
                                </Box>

                                <Typography align='center' variant="caption" style={{ fontSize: '10px' }} >
                                    {t('By clicking')} {`continue`} {t('you will agree with our')}
                                    <a href='/terms-conditions' target='_blank' className='iGor__TeramAndConLink'>
                                        {t('terms & conditions')}
                                    </a>
                                </Typography>
                                <Button variant="contained"
                                    sx={{ margin: '10px 0' }}
                                    onClick={setUserForgotPasswordHandler}
                                >
                                    {userFogotPassApiRes?.isLoading == true ?
                                        (<Box className='iGor-action_loader'>
                                            <FontAwesomeIcon icon={faSpinner} spin />
                                        </Box>) :
                                        ('Reset my Password')}
                                </Button>
                            </>}
                            {ForgetPasswordDiv == 2 && <>
                                <Box sx={{ margin: '1.25rem 0' }}>
                                    <Typography align='center' variant="caption" sx={{ color: '#757575' }}>
                                        {t('Your new password must be different from previous used password.')}
                                    </Typography>
                                </Box>

                                <PasswordCustom placeholder='Password' />
                                <PasswordCustom placeholder='Confirm your new password' />


                                <Button variant="contained" sx={{ margin: '10px 0' }}  >
                                    Confirm to Reset
                                </Button>

                            </>}
                        </Box>
                    </Box>
                    <ToastContainer />
                </>
            </Modal>
        </div>
    );
}