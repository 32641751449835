import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


export const OrderSumInfoMassage = () => {
    return (
        <>  <Box className="iGor_OrderInfo" >
            <Box className='iGor_setFlex iGor_Section'  >
                <ReportProblemIcon />
                <Typography variant='subtitle1'>  Must-Read Before Check-in</Typography>
            </Box>
            <Typography variant='body1'>  MUST READ BEFORE CHECK-IN: This package or deals doesn’t include transfer from MALE Airport to this property, make sure you <br /> already prepared the transfer or transport</Typography>
        </Box></>
    )
}
