import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuList, Box, Typography, TextField, Grid } from "@mui/material";
import SearchIcon from '../../assets/icons/search_Icon.svg';
import { DatePickerComp } from '../../components/inputFieldCustom/DatePicker';
import ArrowRightAltIcon from '../../assets/icons/Icon_arrow-right.svg';
import { useTranslation } from 'react-i18next';
import aloneIcon from '../../assets/icons/arrow_alone.svg';
import { useAppSelector } from '../../appStore/hooks';
import { useInitApiMutation } from '../../services/api/hotelApi'
//import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import DestinationRoomAddition from '../../components/DestinationsHotelsResults/DestinationRoomAddition';
import DestinationAddRoomBtn from '../../components/DestinationsHotelsResults/DestinationAddRoomBtn';
import DestinationMenuName from '../../components/DestinationsHotelsResults/DestinationMenuName';
import DestinationNightCount from '../../components/DestinationsHotelsResults/DestinationNightCount';
import { countryResidenceAndCulture } from '../../utils/constants/constant';
import { PinkSwitch } from '../../utils/constants/SliderColourTheme';
import initApiCall from '../../services/initApiCall';
import { setResetRoomArray, setRoomArray } from '../../appStore/hotelSlice';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

function SearchModifyMobile({ stepFirst, setstepFirst, stepSecond, setstepSecond, stepNumUpdate, stepNumber,
    setDestinationShow, setOpenModifySearchBox, roomArrayForGuest, setRoomArrayForGuest, startDateForDestination,
    setStartDateForDestinationSearch, endDateForDestination, setEndDateForDestinationSearch, numberOfNights,
    destinationsData, setNumberOfNights, destinationObj, setDestinationObj, setOpenDrawer }: any) {
    const { t } = useTranslation()
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [animation, setAnimation] = useState('');
    const [switchBtn, setswitchBtn] = useState(false);
    const [openDestinationPick, setOpendDestinationPick] = useState(false);
    const matches = useMediaQuery('(max-width:1024px)');
    const [open, setOpen] = useState(false);
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };
    const [destinVal, setDestinVal] = useState('');
    const [searchMSg, setSearchMsg]: any = useState(t("Where do you want to go?"))

    const onAloneHandler = (e: any) => {
        setswitchBtn(e.target.checked)
    }

    useEffect(() => {
        if (switchBtn == true) {
            let arr = [{
                adults: 1,
                children: 0,
                childAges: []
            }]
            setRoomArrayForGuest(arr)
        }
        else {
            dispatch(setResetRoomArray())
        }
    }, [switchBtn])

    const setTextFieldValue = (e: any) => {
        setSearchMsg("When and how long is your trip?")
        setDestinVal(e)
    }

    const closeCalenderHander = () => {
        stepNumUpdate(2, 'increment');
        setstepSecond(false);
        setAnimation('iGor_animation_3');
        setDestinationShow(false);
        setstepFirst(false);
        if (setOpenModifySearchBox) setOpenModifySearchBox(true);
    }

    const closeGuestPickerHander = () => {
        setDestinationShow(false);
        setstepSecond(true);
        setstepFirst(false);
        if (setOpenModifySearchBox) setOpenModifySearchBox(true);
    }

    return (
        <>
            <ClickAwayListener onClickAway={() => `${setOpen(() => matches ? true : false)}`} >
                <>

                    <Box className='iGor__destinMBshow  iGOr_DestiMB'>
                        {!stepFirst && stepNumber == 0 &&
                            <Box className='iGor_destiMBSection'>
                                <Box className='iGor_DestiMb_arrow'>
                                    <ArrowBackIosNewIcon onClick={closeGuestPickerHander} />
                                </Box>
                                <Box className={"InputField iGor_DestAutoTitBtn " + (stepNumber != 0 && ' iGor__MbSerInput')}>
                                    <img src={SearchIcon} alt='SearchIcon' />
                                    <TextField value={destinVal} placeholder={searchMSg} autoComplete='off' onChange={(e) => setTextFieldValue(e.target.value)}> </TextField>
                                </Box>
                            </Box>}
                        <Box className="iGor_DestAutoMain">
                            <div className={"iGor_animation MuiPaper-root " + (!stepSecond ? roomArrayForGuest.length > 1 || roomArrayForGuest[0].children >= 1 ? " " : animation : animation)}  >
                                {!stepFirst && stepNumber == 0 &&
                                    (<Box sx={{ position: "relative", height: '100%', overflow: 'hidden' }}>
                                        <Typography variant='subtitle1' className='iGor_DestiTitle'  > {t("Popular Destinations")}</Typography>
                                        <Box className='iGor_DestiListSection'>
                                            <MenuList
                                                autoFocusItem={open}
                                                className='iGor_DestiList'
                                            >
                                            </MenuList>
                                            <DestinationMenuName
                                                initialValue={destinVal}
                                                onTextChange={setTextFieldValue}
                                                setSearchMsg={setSearchMsg}
                                                setDestinVal={setDestinVal}
                                                setstepFirst={setstepFirst}
                                                setAnimation={setAnimation}
                                                stepSecond={!stepSecond}
                                                stepNumUpdate={stepNumUpdate}
                                                setDestinationShow={setDestinationShow}
                                                destinationObj={destinationObj}
                                                setDestinationObj={setDestinationObj}
                                                closeGuestPickerHander={closeGuestPickerHander}
                                            />
                                        </Box>
                                    </Box>)}
                                {stepFirst == true &&
                                    <Box sx={{ textAlign: 'center' }} className='iGor__roomAddSeciton'>
                                        <Box className="iGor_destiMBSection">
                                            <Box className='iGor_DestiMb_arrow' sx={{ marginX: "0 !important" }}>
                                                <ArrowBackIosNewIcon onClick={closeGuestPickerHander} />
                                            </Box>
                                            <Box className={"InputField iGor_DestAutoTitBtn " + (stepNumber != 0 && ' iGor__MbSerInput')}>
                                                <Typography color={'#FF6B6B'} fontSize={'12px'} fontWeight={600} width={100}>
                                                    How long is your trip?'
                                                </Typography>
                                            </Box>
                                        </Box>
                                        {stepSecond || stepNumber === 1 ?
                                            <DestinationNightCount
                                                numberOfNights={numberOfNights}
                                                setNumberOfNights={setNumberOfNights}
                                                endDateForDestination={endDateForDestination}
                                                setEndDateForDestinationSearch={setEndDateForDestinationSearch}
                                            />
                                            : <></>
                                        }
                                        <Box className='iGor__ImAloneBox'>
                                            {stepSecond == true && stepNumber === 1 ?
                                                (<DatePickerComp
                                                    setNumberOfNights={setNumberOfNights}
                                                    startDateForDestination={startDateForDestination}
                                                    endDateForDestination={endDateForDestination}
                                                    setEndDateForDestinationSearch={setEndDateForDestinationSearch}
                                                    setStartDateForDestinationSearch={setStartDateForDestinationSearch}
                                                />) :
                                                stepNumber == 2 ? (<>
                                                    <Grid container >
                                                        <Grid item xs={6} sm={6} sx={{ textAlign: 'left', margin: ' 0.625rem 0' }} >
                                                            <Typography variant='subtitle1' className='iGo__titleImalone'>{t("I’m alone")}  </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6} sx={{ textAlign: 'right' }}>
                                                            <PinkSwitch {...label} className='iGor__ImAloneBtn' onChange={(e) => onAloneHandler(e)} />
                                                        </Grid>
                                                    </Grid>
                                                    {!switchBtn && <>
                                                        <Box className='iGor_d_roomBtnSectiom'>
                                                            <DestinationRoomAddition
                                                                setRoomArrayForGuest={setRoomArrayForGuest}
                                                                roomArrayForGuest={roomArrayForGuest}
                                                            />
                                                        </Box>
                                                        <Grid container spacing={1} className='iGor_mb_BtnAddSection'>
                                                            <Grid item sm={12}>
                                                                <DestinationAddRoomBtn
                                                                    setRoomArrayForGuest={setRoomArrayForGuest}
                                                                    roomArrayForGuest={roomArrayForGuest}
                                                                />
                                                            </Grid>
                                                        </Grid> </>}
                                                    {switchBtn &&
                                                        <Box className='iGor__ImConentBox' >
                                                            <img src={aloneIcon} alt='arrow alone' className='aloneIcon' />
                                                            {matches ? <Typography variant='body1' className='iGor__ImAloneContent'>
                                                                You never really travel alone.<br />
                                                                The world is full of friends waiting to get to know you!
                                                            </Typography>
                                                                :
                                                                <Typography variant='body1' className='iGor__ImAloneContent'>
                                                                    {t("You never really travel alone")}
                                                                </Typography>}
                                                        </Box>
                                                    }
                                                </>) : ('')
                                            }
                                            {stepSecond &&
                                                <Button variant='contained' className='iGor_mb_Btn iGor__conBtn' onClick={closeCalenderHander}>{matches ? t("Submit") : t("Continue")}</Button>}
                                            {!stepSecond && <>
                                                <Button variant='contained' className='iGor_mb_Btn' onClick={closeGuestPickerHander}>{matches ? t("Submit") : t("Check Availability")}</Button>
                                            </>
                                            }
                                        </Box>
                                    </Box>}
                            </div>
                        </Box>
                    </Box>
                </>
            </ClickAwayListener>
        </>

    );
}

export default SearchModifyMobile;