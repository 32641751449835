import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, Skeleton } from '@mui/material';
import cardImg from '../../assets/images/mytripCards.jpg';
import iconMoon from '../../assets/icons/Icon_crescent-moon.svg';
import MyTripModel from './MyTripModel';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import Icon_clock from '../../assets/icons/Icon_alarm-clock.svg';
import { env } from '../../utils/config/appConfig';
import { useDispatch } from 'react-redux';
import { fetchDownloadBookingDetails, fetchUserBookingDetailsApi, setAlertBoxOnError, setDownloadPDFDataReset, setErrorMessageForDialogueBox, setUserMytripDetailsDataReset } from '../../appStore/hotelSlice';
import MytripPopover from './MytripPopover';
import convertDateFormat, { formatDateDayDateYear, myTripDateConverter } from '../../utils/utilities/DateConverter';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from 'react-i18next';
import Icon_maps_pin from '../../assets/icons/Icon_maps-pin.svg';
import Icon_facebook from '../../assets/icons/Icon-white-facebook.svg';
import { cacheImages } from '../../utils/utilities/ImageCaching';
import { environmentVariables } from '../../utils/config/environmentVariables';
import MytripCancellationModel from './MytripCancellationModel';
import { FacebookShareButton } from 'react-share';


const MytripCards = ({ userBookingsList }: any) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { userBookingDetails } = useAppSelector(state => state.hotel)
    const userBookingResponseStatus = userBookingDetails?.data?.statusCode;
    const matches = useMediaQuery('(max-width:1024px)');
    const [imageLoaded, setImageLoaded] = useState(false);
    const imagePath = environmentVariables.imagePathApp;
    const matchesMobile = useMediaQuery('(max-width:600px)');
    const pathToShare = window.location.href;

    const handleClose = () => {
        dispatch(setUserMytripDetailsDataReset({ data: [], loading: false, error: null }))
        setOpen(false)
        dispatch(setDownloadPDFDataReset({}))
    };

    const getBookingDetailsHandler = (bookingId: any) => {
        dispatch(setUserMytripDetailsDataReset({ data: [], loading: false, error: null }))
        dispatch(fetchUserBookingDetailsApi(bookingId))
        dispatch(fetchDownloadBookingDetails(bookingId))
    }
    const handleImageLoad = () => {
        setTimeout(() => { setImageLoaded(true) }, 2000)
    };

    //direct to location
    const moveToLocationGoogleMap = (coOrdinates: any) => {
        const coOrdinatesMap = coOrdinates;
        if (coOrdinatesMap?.latitude && coOrdinatesMap?.longitude) {
            window.open(`https://www.google.com/maps/search/?api=1&query=${coOrdinatesMap?.latitude},${coOrdinatesMap?.longitude}`);
        }
    }

    useEffect(() => {
        if (userBookingResponseStatus == 'OK') {
            setOpen(true);
        }
        if (userBookingResponseStatus == 'NotFound') {
            dispatch(setErrorMessageForDialogueBox('20'))
            dispatch(setAlertBoxOnError(true))

            dispatch(setUserMytripDetailsDataReset({ data: [], loading: false, error: null }))
        }
    }, [userBookingResponseStatus])

    return (
        <>{userBookingsList.map((bookingList: any) => {
            const imageForBookings = bookingList.image ? `${imagePath}${bookingList.image}` : cardImg
            const imageCached = cacheImages(imageForBookings);
            return (<Box className="iGor__MytripCards" key={bookingList.booking_id}>
                <MytripPopover booking={bookingList} />
                <Grid container onClick={!matchesMobile ? () => getBookingDetailsHandler(bookingList.booking_id) : () => { }}>
                    <Grid item sm={7} xs={12} >
                        <Grid container  >
                            {matches &&
                                <Grid item xs={12} height={'14px'}>
                                    <Box height={'100%'} className="iGor__BtnBox">
                                        <Button className={bookingList.status == 'Confirmed' ? 'iGor__Paid' : 'iGor__Cancelled'}>
                                            {bookingList.status == 'Confirmed' ?
                                                (<><CheckIcon />
                                                    <Typography> Paid</Typography></>) :
                                                (<><CancelIcon />
                                                    <Typography>Cancelled</Typography>
                                                </>)}
                                        </Button>
                                    </Box>
                                </Grid>}
                            <Grid item sm={6} xs={5}>
                                <Box className='iGor__MytripImgBox' onClick={matchesMobile ? () => getBookingDetailsHandler(bookingList.booking_id) : () => { }}>
                                    {!imageLoaded && (<Skeleton variant="rectangular" height={132} animation="wave" sx={{ borderRadius: '0.625rem' }} />)}

                                    <img src={imageCached.src} className='iGor__MytripImg'
                                        style={{
                                            display: imageLoaded ? 'block' : 'none'
                                        }}
                                        onLoad={handleImageLoad} />
                                </Box>
                            </Grid>
                            <Grid item sm={6} className='iGor__MytripCardContent' xs={7}>
                                <Typography variant='body1' className='iGor__orderId'>  Order ID: {bookingList.booking_id}    </Typography>
                                <Typography variant='subtitle1' className='iGor__Title'>      {bookingList.name}</Typography>
                                <Box className="iGor_setFlex iGor_justifyBetween iGor_alignCenter" sx={{ margin: '1rem 0' }}>
                                    <Box>
                                        <Typography variant='body1' className='iGor__check'>   Check-in</Typography>
                                        <Typography variant='body1' className='iGor__Date'> {convertDateFormat(bookingList.checkin)}</Typography>
                                    </Box>
                                    <Box>
                                        <img style={{ display: 'flex' }} src={iconMoon} />
                                    </Box>
                                    <Box>
                                        <Typography variant='body1' className='iGor__check'>   Check-out </Typography>
                                        <Typography variant='body1' className='iGor__Date'>  {convertDateFormat(bookingList.checkout)}</Typography>

                                    </Box>
                                </Box>
                                <Typography variant='body1' className='iGor__subTilte'>  {bookingList.roomName} </Typography>
                                <Typography variant='body1' className='iGor__subTilte'>   {`${bookingList.numberOfPassengers ? bookingList.numberOfPassengers > 1 ? bookingList.numberOfPassengers + ' Guests ' : bookingList.numberOfPassengers + ' Guest ' : ''}`}
                                    {/* • 1 Rooms    */}
                                    |    {myTripDateConverter(bookingList.bookingDate) + " "}
                                    • {`${bookingList.numberOfNights ? bookingList.numberOfNights > 1 ? bookingList.numberOfNights + ' Nights' : bookingList.numberOfNights + ' Night' : ''}`}</Typography>
                                {matches &&
                                    <Box className='IGor_mytripBtnWrapper'>
                                        <List className='iGor_mytripPopoList'>
                                            <ListItem disablePadding>
                                                <ListItemButton className='iGor_mytripBtnMap' onClick={(e) => { moveToLocationGoogleMap(bookingList) }}>
                                                    <ListItemIcon>
                                                        <img src={Icon_maps_pin} className='iGor__maps_pin' />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Direct me" />
                                                </ListItemButton>
                                            </ListItem>
                                            <ListItem disablePadding className='iGor_mytripBtnFbList'>
                                                <FacebookShareButton url={pathToShare}>
                                                    <ListItemButton className='iGor_mytripBtnFb'>
                                                        <ListItemIcon>
                                                            <img src={Icon_facebook} />
                                                        </ListItemIcon>
                                                        <ListItemText primary="Share this" />
                                                    </ListItemButton>
                                                </FacebookShareButton>
                                            </ListItem>
                                        </List>
                                        {bookingList.status === 'Confirmed' ? (<Box>
                                            <MytripCancellationModel
                                                bookingId={bookingList.booking_id}
                                            />
                                        </Box>) : (<></>)}
                                    </Box>}
                            </Grid>
                        </Grid>

                    </Grid>
                    {!matches &&
                        <Grid item sm={5}>
                            <Box height={'100%'} className="iGor__BtnBox">
                                <Button className={bookingList.status == 'Confirmed' ? 'iGor__Paid' : 'iGor__Cancelled'}>
                                    {bookingList.status == 'Confirmed' ?
                                        (<><CheckIcon />
                                            <Typography> Paid</Typography></>) :
                                        (<><CancelIcon />
                                            <Typography>Cancelled</Typography>
                                        </>)}
                                </Button>
                            </Box>
                        </Grid>}
                </Grid>
            </Box>)
        })}
            {userBookingResponseStatus === 'OK' && userBookingsList.length > 0 && (
                <MyTripModel
                    bookingId={userBookingsList[0].booking_id} // Adjust this logic as needed
                    handleClose={handleClose}
                    open={open}
                />
            )}
        </>
    )
}

export default MytripCards