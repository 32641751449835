import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../appStore/hooks";
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";
import { Autocomplete, InputLabelProps, TextField } from '@mui/material';
import { useDispatch } from "react-redux";
import { useState } from "react";

function UpdateUserCurrency({ setSelectUpdateCurrency }: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { countryCurrenciesPaypal } = useAppSelector(state => state.hotel)
    // const countriesCurrenciesData: any = useAppSelector(state => state.hotel?.countryCurrencies?.currencies);
    const countriesCurrenciesData: any = countryCurrenciesPaypal?.data?.currencies ? countryCurrenciesPaypal?.data?.currencies : [];

    //country currencies data handler
    let countryCurrencies: any = [];
    countriesCurrenciesData ? countryCurrencies = countriesCurrenciesData?.map((currency: any) => {
        return {
            currencyName: currency.name,
            currencyCode: currency.code,
            currencyNumCode: currency.id,
            label: `${currency.name}(${currency.code})`
        }
    }) : ('');
    const currencyHandler = (e: any, data: any) => {
        AppLogHandler("e.target.value", data)
        if (data?.currencyNumCode != 0) {
            setSelectUpdateCurrency(data?.currencyNumCode)
        }
    }


    return (<>
        {/* <Grid item xs={12} className='iGor_setRelative'> */}
        <Autocomplete
            className='iGoR_autoCom'
            disablePortal
            options={countryCurrencies != null ? countryCurrencies : []}
            renderInput={(params) => (
                <TextField
                    className='iGor_inputFieldval'
                    {...params}
                    placeholder={t('Currency based on your location')}
                    InputLabelProps={{
                        ...(params.InputLabelProps as Partial<InputLabelProps>),
                    }}
                />)}
            onChange={(value, data) => currencyHandler(value, data)}
        />
        {/* {ButtonClicked && currencyId === 0 && <label className="iGor-ErrorMsg iGorCurrError">Select Your Preferred Currency</label>} */}
        {/* </Grid> */}
    </>);
}

export default UpdateUserCurrency;