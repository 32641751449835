import { setHotelSelectedVrImgAndData } from '../../appStore/hotelSlice';
import NoHotelsImg from '../../assets/images/No Hotels Img.png'

export const HotelVRDataDispatch: any = (hotelImgSelected: any) => {
    const { name, image, propertyType, id, slug, isSoldOut, cityCountry, address }: any = hotelImgSelected;
    const vrImageSelectedType = image?.vrImg ? image?.vrImg : NoHotelsImg;
    return (dispatch: any) => {
        dispatch(setHotelSelectedVrImgAndData({
            hotelName: name,
            hotelVrImg: vrImageSelectedType,
            hotelId: id,
            slugId: slug,
            isSoldOut: isSoldOut,
            hotelLocation: cityCountry !== undefined ? cityCountry : address
        }))
    }
}