import React, { useEffect, useState } from "react";
import { Link, Modal, Box, IconButton, Button, Popover, Typography, TextField, List, ListItem, ListItemAvatar, ListItemText, ListItemIcon, useMediaQuery, capitalize } from "@mui/material";
import { Close } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import AdjustIcon from '@mui/icons-material/Adjust';
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";
import PropertyFacilitiesIconMapper from "../../components/PropertyDetailsCards/PropertyFacilitiesIconMapper";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FfFfFf',
    borderRadius: '15px',
    width: '31.25rem',
    padding: '0 1rem',
};
function PropertyFacilitiesModal({ btn, listing_details, showFacilityText, showFacilities, setShowFacilities }: any) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const matches = useMediaQuery('(max-width:1024px)');

    const handleClose = () => {
        setOpen(false)
        if (setShowFacilities) setShowFacilities(false)
    };

    useEffect(() => {
        if (showFacilities == true) {
            setOpen(true)
        }
    }, [showFacilities])

    return (
        <div>
            {btn === 'one' ?
                <Button variant='contained' className="iGor__SeeAllBtnPro" onClick={handleOpen}>
                    {matches ? ' See all facilities' : ' See all facilities & amenities'}</Button>
                : showFacilityText == 'property-details' ?
                    listing_details?.facilities?.length > 0 ? (<Button className=" btn-facilities" onClick={handleOpen}>
                        <Typography textTransform={"capitalize"}>View </Typography>
                        <Typography textTransform={"lowercase"}>all facilities </Typography>
                    </Button>) : '' : ''
            }

            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <>
                    <Box sx={style} className='iGor_Modal'>
                        <div className="modal-close">
                            <IconButton onClick={handleClose} className="close-button">
                                <Close />
                            </IconButton>
                        </div>


                        <Box sx={{ marginBottom: '0.625rem' }}>
                            <Typography variant="subtitle1" marginTop={1.5}>
                                {t('Facilities & Amenities')}
                            </Typography>
                        </Box>
                        <Box  className='iGor_ProFacili_scroll'>

                            {listing_details?.facilities?.length > 0 ? listing_details?.facilities?.map((item: any) => (
                                <>
                                    <Typography fontWeight={600} marginBottom={0} key={item.title}>{item.title}</Typography>
                                    <List className="iGor_ProFaciliList">
                                        
                                        {item.list.map((subitem: any, index: number) => (
                                            <ListItem key={subitem}>
                                                <ListItemIcon>
                                                <PropertyFacilitiesIconMapper iconName = {item.icon[index]}/>
                                                    {/* <AdjustIcon /> */}
                                                </ListItemIcon>
                                                <ListItemText primary={subitem} />
                                            </ListItem>

                                        ))}
                                    </List>
                                </>
                            )) : (<List className="iGor_ProFaciliList">
                                <ListItem key={'faclityNot'}>
                                    <ListItemIcon>
                                        <AdjustIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={t('5008')} />
                                </ListItem>
                            </List>)}
                        </Box>
                    </Box>
                </>
            </Modal>
        </div>
    );
}

export default PropertyFacilitiesModal;