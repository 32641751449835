import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../appStore/hooks";
import { fetchHotelDetailsFromApi, fetchHotelsRoomsDataFromApi, setHotelDetails, setHotelIdSelected, setHotelRoomDetails } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import VTUrlParser from '../../utils/utilities/VTUrlParser';
import { dateFormatedForUrls } from '../../utils/utilities/DateConverter';
import { environmentVariables } from '../../utils/config/environmentVariables';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import locationpin from '../../assets/icons/location-pin.svg';


function HotelResultVRImgSection(prop: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedHotelsTypeTab, selectedHotelVrImageAndData, initApi, destinationsData, searchDestinationFromDate,
    searchDestinationToDate, loggedInUserCurrency, offerHotelResults } = useAppSelector(state => state.hotel)
  const hotelsResultData = useAppSelector(state => state.hotel.hotelResults.data)
  let hotelsAndResort: [any] = hotelsResultData.hotelresort ? hotelsResultData.hotelresort : []
  let offerHotels: [any] = offerHotelResults.data ? offerHotelResults.data : []
  let vipHotels: [any] = hotelsResultData.vip ? hotelsResultData.vip : []
  const searchId = initApi.searchId;
  let vrImageSelectedHotel = selectedHotelVrImageAndData?.hotelVrImg ? VTUrlParser(selectedHotelVrImageAndData?.hotelVrImg) : ''
  let vrHotelNameSelected = selectedHotelVrImageAndData?.hotelName ? selectedHotelVrImageAndData?.hotelName : 'Hotel name not available'
  const hotelSoldOut = selectedHotelVrImageAndData?.isSoldOut;
  const selectedHotelId = selectedHotelVrImageAndData?.hotelId ? selectedHotelVrImageAndData.hotelId : '';
  const slugId = selectedHotelVrImageAndData?.slugId ? selectedHotelVrImageAndData.slugId : '';
  let hotelsTypeSelected = selectedHotelsTypeTab;
  const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
  const destinationName = destinationsData?.destinationName?.replaceAll(" ", "-")
  const imagePath = environmentVariables.imagePathApp;
  const enviornment = environmentVariables.enviornment;
  const hotelLocation = selectedHotelVrImageAndData?.hotelLocation;

  // zoom in and zoom out function
  const [zoomLevel, setZoomLevel] = useState(100); // Default zoom level is 100%
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 10);
  };
  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => prevZoom === 100 ? prevZoom : prevZoom - 10);
  };

  // hotel checkout handler on view details
  const hotelCheckoutHandler = (hotelId: number) => {
    const fromDate = dateFormatedForUrls(searchDestinationFromDate)
    const toDate = dateFormatedForUrls(searchDestinationToDate)
    dispatch(setHotelDetails({ data: [], loading: false, error: null }))
    dispatch(setHotelRoomDetails({ data: [], loading: false, error: null }))
    if (hotelId && searchId) {
      dispatch(fetchHotelDetailsFromApi({ searchId: searchId, hotelId: hotelId }))
      dispatch(fetchHotelsRoomsDataFromApi({ searchId: searchId, hotelId: hotelId }))
      dispatch(setHotelIdSelected(hotelId))
      toast('Loading..', { position: "top-center" })
      navigate(`/property-details/${slugId}?hotelId=${hotelId}&destinationName=${destinationName}&destinationId=${destinationsData.destinationCode}&checkIn=${fromDate}&checkout=${toDate}&currency=${loggendInUserCurrency}`)
    }
  }
  AppLogHandler('selectedHotelVrImageAndData', selectedHotelVrImageAndData)
  // hotels vr image section component
  const hotelVrImageComponent = () => {
    return (<><Grid item sm={6}>
      <Box className="iGor_ZoomableImage iGOr_mb-D-none" >
        <Box sx={{ transform: `scale(${zoomLevel / 100})`, height: '550px' }}>
          {/* <img
            src={selectedHotelVrImageAndData?.hotelVrImg ? `${imagePath}${vrImageSelectedHotel}` : noImage}
            alt="Zoomable Image"
            style={{ width: '100%', height: '100%' }}
          /> */}
          <iframe
            src={vrImageSelectedHotel}
            style={{ width: '100%', height: '100%' }}
          />
        </Box>
        <Box className="iGor_boxTop">
          <Typography className='iGor_boxTop_hotelName'>{vrHotelNameSelected}</Typography>
          <Typography className='iGor_boxTop_location'>
            <img src={locationpin} alt="time" />
            {hotelLocation}
          </Typography>
        </Box>
        <Box className="iGor_boxBottom">
          <Box>
            <IconButton onClick={handleZoomOut} className='iGor_zoomBtn'><HorizontalRuleIcon /></IconButton>
            <IconButton onClick={handleZoomIn} className='iGor_zoomBtn'><AddIcon /></IconButton>
          </Box>
          <Button
            className={`${enviornment !== 'development' && hotelSoldOut == true ? 'iGOrBtn__disable' : ''}`}
            variant='contained'
            sx={{ width: '140px' }}
            disabled={enviornment == 'PROD' && hotelSoldOut == true ? true : false}
            onClick={() => hotelCheckoutHandler(selectedHotelId)}
          >{t('View details')}</Button>
        </Box>
      </Box>
    </Grid>

    </>)
  }

  useEffect(() => {
    vrImageSelectedHotel = selectedHotelVrImageAndData?.hotelVrImg?.pathImg ? selectedHotelVrImageAndData?.hotelVrImg?.pathImg : ''
    vrHotelNameSelected = selectedHotelVrImageAndData?.hotelName ? selectedHotelVrImageAndData.hotelName : 'Hotel name not available'
    hotelsTypeSelected = selectedHotelsTypeTab;
  }, [selectedHotelVrImageAndData, selectedHotelsTypeTab])

  return (<>
    {!prop.matches &&
      hotelsTypeSelected == 'ALL' ? (
      hotelsAndResort.length > 0 || vipHotels.length > 0 || offerHotels.length > 0 ?
        (hotelVrImageComponent()) : ('')) :
      hotelsTypeSelected == 'HOTELSRESORT' && hotelsResultData?.hotelresort?.length > 0 ? (
        hotelVrImageComponent()
      ) :
        hotelsTypeSelected == 'OFFERS' && offerHotels.length > 0 ? (
          hotelVrImageComponent()
        ) :
          hotelsTypeSelected == 'VIP' && hotelsResultData?.vip?.length > 0 ? (
            hotelVrImageComponent()
          ) :
            ('')
    }
  </>);
}

export default HotelResultVRImgSection;