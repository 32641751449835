import { useEffect, useState } from 'react';
import { Grid, Box, Autocomplete, Typography, TextField, Button, useMediaQuery, InputLabelProps, Checkbox } from "@mui/material";
import PasswordCustom from '../inputFieldCustom/PasswordCustom';
import { useTranslation } from 'react-i18next';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import MailOutlineIcon from '../../assets/icons/icon_email_black.svg';
import { useAppSelector } from '../../appStore/hooks';
import { DatePickerInPopOver } from '../popUpSection/DatePickerInPopOver';
import { useRegisterUserMutation, useSendOTPPhoneMutation } from '../../services/api/hotelApi';
import {
  getUserEmailExistApi, setCountryNumCodeForApi,
  setRegisterUserCurrencyId, setFirstNameUser, setIsRegisterPopUp,
  setLastNameUser, setRegisterUserEmailAddress, setRegisterUserPassword,
  setUserPhoneNumber, setRegisterUserStatus, setInputValidatorRegisterUser, setEnterPassword, setRegisteredUserDetailsReset, setIsVerificationPopUp, setSentOTPPhoneDataReset, setAlertBoxOnError, setErrorMessageForDialogueBox,
  setOTPSentTimer,
  setAllowMoeNotification,
  setUserActionLoader
} from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { CountryCode } from './CountryCode';
import { toastMessages } from '../../utils/messages/toastMessages';
import { sendOTPForPhone } from '../../utils/constants/types';
import { authenticationTypeForApp } from '../../utils/constants/constant';
import { Link } from 'react-router-dom';
import { environmentVariables } from '../../utils/config/environmentVariables';
import { el } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const RegisterAccount = () => {
  // t object for language translation for keys
  const { getUserEmailExist, registerUserStatus, userRegisterEmailAddress, userRegisterPassword, firstNameUser, userBirthDate, lastNameUser, userPhoneNumber, countryId, registerUserCurrencyData, sentOTPForPhoneData, registerUserCountryCall, countryCurrenciesPaypal } = useAppSelector(state => state.hotel);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [ButtonClicked, setButtonClicked] = useState(false)
  const [firstname, setFirstName] = useState(firstNameUser);
  const [lastname, setLastName] = useState(lastNameUser);
  const [emailAddress, setEmailAddress] = useState(userRegisterEmailAddress);
  const [passwordInputRegister, setPasswordInputRegister] = useState('')
  const [phoneNumber, setPhoneNumber]: any = useState('');
  const [allowMoeNotification, setAllowMoeNotifications]: any = useState(true);
  const [phoneErrMsg, setErrMsg] = useState(t('Please fill with your active phone number'))
  const [passwordMsg, setPasswordMsg] = useState(t('Please fill with your password'))
  const [emailErr, setEmailErrMs] = useState(t('Please fill with your active email address'))
  const [callCodeError, setCallCodeError]: any = useState(t('Please select calling code'))
  const [phoneValidator, setPhoneValidator] = useState(userPhoneNumber !== '' ? true : false);
  const countriesCallingCodeData: any = useAppSelector(state => state.hotel?.countryCallingCodes?.countries);
  // const countriesCurrenciesData: any = useAppSelector(state => state.hotel?.countryCurrencies?.currencies);
  const countriesCurrenciesData: any = countryCurrenciesPaypal?.data?.currencies ? countryCurrenciesPaypal?.data?.currencies : [];
  const gmailAuthUserEmail: any = useAppSelector(state => state.hotel?.socialMediaAuthenticationUserData?.userGmail);
  const gmailUserFirebaseId: any = useAppSelector(state => state.hotel?.socialMediaAuthenticationUserData?.userFirebaseId);
  const { authenticationType, showDate, inputValidatorRegisterUser, setRegisteredUserDetails, userActionLoader }: any = useAppSelector(state => state.hotel);
  const newUserCreatedMEssage = setRegisteredUserDetails?.message;
  const newUserCreatedStatus = setRegisteredUserDetails?.status;
  let userEmailExist = getUserEmailExist?.data?.status;
  let useEmailExistLoading = getUserEmailExist?.loading;
  let userRegisterEmailAddres = userRegisterEmailAddress;
  const countryCallingId = registerUserCountryCall?.countryCallingId;
  const phoneNumberForVerification = `+${countryCallingId}${userPhoneNumber}`
  const fromRegister = true;
  const matches = useMediaQuery('(max-width:1024px)');
  const email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const sentOTPStatus = sentOTPForPhoneData?.statusCode;
  const sentOTPMessage = sentOTPForPhoneData?.message;
  const emailAuthentication = authenticationTypeForApp.emailAuth;
  const enviornment = environmentVariables.enviornment;
  const actionLoader = userActionLoader;

  // date formating logic
  function formatDateToYYYYMMDD(dateString: any) {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  const formatteUserdBirthDate = formatDateToYYYYMMDD(userBirthDate);
  const userBirthdateShow = new Date(userBirthDate).toLocaleDateString('en-us',
    { weekday: "long", year: "numeric", month: "short", day: "numeric" })

  // register user api initialize
  const [registerUser] = useRegisterUserMutation();

  // send OTP to phone api initialize
  const [sendOTP] = useSendOTPPhoneMutation();


  // error Messages Handler for register USer
  const onClickHandler = () => {
    if (firstname && lastname && userRegisterPassword.length >= 6 && phoneValidator === true && showDate === true && emailAddress.match(email) && registerUserCurrencyData.currencyId > 0 && authenticationType == emailAuthentication) {
      dispatch(setUserActionLoader({ action: 'signUp', loading: true }))
      setButtonClicked(false)
      dispatch(setInputValidatorRegisterUser(true))
      if (allowMoeNotification == true) {
        dispatch(setAllowMoeNotification(false))
      }
      else {
        dispatch(setAllowMoeNotification(true))
      }
    }
    else if (firstname && lastname && gmailUserFirebaseId && userRegisterEmailAddres !== '' && phoneValidator === true && showDate === true && registerUserCurrencyData.currencyId > 0 && authenticationType !== emailAuthentication) {
      dispatch(setUserActionLoader({ action: '', loading: false }))
      setButtonClicked(false)
      dispatch(setInputValidatorRegisterUser(true))
      // dispatch(setIsVerificationPopUp(true))
    }
    else {
      setButtonClicked(true)
      dispatch(setInputValidatorRegisterUser(false))
    }
  }

  // name input handler
  const nameInputHandler = (e: any) => {
    const regex = /^(?=.*[a-zA-Z])[a-zA-Z\s]+$/;
    e.preventDefault();
    const { value }: any = e.target;
    if (e.target.name == "first-name" && regex.test(value)) {
      setFirstName(value)
    }
    else if (e.target.name == "last-name" && regex.test(value)) {
      setLastName(value.trim())
    }

    if (e.target.name == "first-name" && value.length > 0 && regex.test(value)) {
      dispatch(setFirstNameUser(value))
    }
    else if (e.target.name == "first-name" && value.length == 0) {
      dispatch(setFirstNameUser(value.trim()))
    }
    else if (e.target.name == "last-name" && value.length > 0 && regex.test(value)) {
      dispatch(setLastNameUser(value))
    }
    else if (e.target.name == "last-name" && value.length == 0) {
      dispatch(setLastNameUser(value.trim()))
    }
  }

  // emailAddress input handler
  const emailHandler = (e: any) => {
    e.preventDefault();
    const { value }: any = e.target;
    setEmailAddress(value?.trim());
    if (emailAddress == '') {
      setEmailErrMs(t('Please fill with your active email address'))
    }
    if (!value?.match(email)) {
      setEmailErrMs(t('Please fill with your active email address'))
    }
    if (value?.match(email)) {
      dispatch(setRegisterUserEmailAddress(value?.trim()));
      setEmailErrMs('')
    }
    dispatch(setRegisterUserEmailAddress(value?.trim()));
  }

  // phone number input handler
  const phoneNoHandler = (e: any) => {
    e.preventDefault();
    const { value }: any = e.target;
    setPhoneValidator(true)
    if (value.length >= 7 && (value.length >= 7 && value.length <= 15)) {
      setPhoneNumber(value)
      setPhoneValidator(true)
      dispatch(setUserPhoneNumber(value))
      setErrMsg('')
    }
    else if (value.length === 0) {
      setErrMsg(t('Please fill with your active phone number'))
      setPhoneNumber(0)
      setPhoneValidator(false)
      dispatch(setUserPhoneNumber(''))
    }
    else if (value.length < 7) {
      setErrMsg(t("Please enter valid phone number"))
      setPhoneNumber(value)
      setPhoneValidator(false)
      dispatch(setUserPhoneNumber(value))
    }
  }

  //allow notifications handler
  const allowMoeNotificationsHandler = (checked: boolean) => {
    setAllowMoeNotifications(checked)
  }

  // to send otp enviornment wise
  const checkEnviornmentForSendOTP = () => {
    if (enviornment !== 'development') {
      //TODO:OTP comment the code when want to disable otp send
      sendOTPToUserPhoneNumber()
    }
    else {
      dispatch(setOTPSentTimer({ initialMinutes: 2, initialSeconds: 30 }))
      dispatch(setIsRegisterPopUp(false))
      dispatch(setIsVerificationPopUp(true))
      dispatch(setRegisterUserStatus(false))
      dispatch(setUserActionLoader({ action: '', loading: false }))
    }
  }

  // currency selection handler
  const currencyHandler = (e: any, data: any) => {
    AppLogHandler("currency value", data)
    if (data?.currencyId != '') {
      dispatch(setRegisterUserCurrencyId({ currencyId: data?.currencyId, currencyCode: data?.currencyCode }))
    }
  }

  // country calling array map function for display country call code list
  let countryCallingCodes: any = [];
  countriesCallingCodeData ? countryCallingCodes = countriesCallingCodeData?.map((country: any) => {
    return (
      {
        countryName: country.name,
        countryCallCode: country.phone_code,
        countryId: country.id,
        label: `${country.name}(${country.phone_code})`
      }
    )
  }) : ([''])

  //country currencies array map function for display currency name & code list
  let countryCurrencies: any = [];
  countriesCurrenciesData ? countryCurrencies = countriesCurrenciesData?.map((currency: any) => {
    return {
      currencyName: currency.name,
      currencyCode: currency.code,
      currencyId: currency.id,
      label: `${currency.name} (${currency.code})`
    }
  }) : ('');

  // useEffect for manage user inputs for register user
  useEffect(() => {
    userEmailExist = getUserEmailExist?.data?.status
    userRegisterEmailAddres = userRegisterEmailAddress;
    if (userRegisterPassword.length < 6) {
      setPasswordMsg(t('Password should be at least 6 characters'))
    }
    if (userRegisterPassword === '') {
      setPasswordMsg(t('Please fill with your password'))
    }
    else if (newUserCreatedStatus == 'BadRequest' && authenticationType == emailAuthentication) {
      dispatch(setErrorMessageForDialogueBox('6'))
      dispatch(setAlertBoxOnError(true))
      dispatch(setRegisteredUserDetailsReset({ status: '', message: '', userDetail: [''] }))
      dispatch(setRegisterUserStatus(false))
    }

    // gmail register handle for not exist user 
    if (gmailAuthUserEmail && gmailAuthUserEmail !== '' && authenticationType !== emailAuthentication) {
      dispatch(setRegisterUserEmailAddress(gmailAuthUserEmail));
    }
    // User Register for Auth type Email
    if (userEmailExist == false && registerUserStatus == true) {
      if (userRegisterPassword && authenticationType == emailAuthentication && inputValidatorRegisterUser == true) {
        checkEnviornmentForSendOTP()
      }
      // User Register for Auth type Gmail
      if (authenticationType !== emailAuthentication && gmailUserFirebaseId && gmailAuthUserEmail && inputValidatorRegisterUser == true) {
        checkEnviornmentForSendOTP()
      }
    }
    if (useEmailExistLoading == false && userEmailExist == true && registerUserStatus == true && inputValidatorRegisterUser == true && userRegisterEmailAddres) {
      dispatch(setErrorMessageForDialogueBox('7'))
      dispatch(setAlertBoxOnError(true))
      dispatch(setUserActionLoader({ action: '', loading: false }))
      dispatch(setRegisterUserStatus(false))
    }
  }, [getUserEmailExist.data?.status, userEmailExist, useEmailExistLoading, userRegisterEmailAddres, registerUserStatus,
    authenticationType, gmailAuthUserEmail, inputValidatorRegisterUser, newUserCreatedStatus])

  // useEffect for passwordinput handler
  useEffect(() => {
    if (passwordInputRegister?.length > 0) {
      dispatch(setRegisterUserPassword(passwordInputRegister))
    }
    if (passwordInputRegister?.length == 0) {
      dispatch(setRegisterUserPassword(passwordInputRegister))
    }
  }, [passwordInputRegister])

  // useEffect for mobile no verification
  useEffect(() => {
    if (sentOTPStatus == 'OK' && sentOTPMessage == 'OTP sent on mobile') {
      dispatch(setIsRegisterPopUp(false))
      dispatch(setIsVerificationPopUp(true))
      dispatch(setRegisterUserStatus(false))
      dispatch(setUserActionLoader({ action: '', loading: false }))
      dispatch(setOTPSentTimer({ initialMinutes: 2, initialSeconds: 30 }))
    }
    else if (sentOTPStatus == 'BadRequest') {
      dispatch(setUserActionLoader({ action: '', loading: false }))
      dispatch(setErrorMessageForDialogueBox(sentOTPMessage))
      dispatch(setAlertBoxOnError(true))
      dispatch(setRegisterUserStatus(false))
      dispatch(setSentOTPPhoneDataReset({ message: '', otpResponse: {}, statusCode: '' }))
    }
  }, [sentOTPStatus, sentOTPMessage])

  // password input handler
  const passwordInputHandlerForRegister = (e: any) => {
    e.preventDefault();
    dispatch(setEnterPassword(false))
    const { value } = e.target
    if (value?.length > 0) {
      setPasswordInputRegister(value.trim())
    }
    else if (value?.length === 0) {
      setPasswordInputRegister(value.trim())
    }
  }

  // send otp api call for mobile
  const sendOTPToUserPhoneNumber = () => {
    const payload: sendOTPForPhone = {
      Identifier: [phoneNumberForVerification],
      IdentifierType: 'Phone'
    }
    sendOTP({ payload })
  }

  // register handler
  const registerUserHandler = () => {
    // Existing user check api call
    userRegisterEmailAddres ? dispatch(getUserEmailExistApi(userRegisterEmailAddres)) : ''
    onClickHandler()
    dispatch(setRegisterUserStatus(true))
  }

  AppLogHandler("Register Account", {
    "Register Payload": {
      'firstname': firstname,
      'lastname': lastname,
      'datePicker': formatteUserdBirthDate,
      'phoneNumber': phoneNumber,
      'getUserEmailExistgetUserEmailExist': getUserEmailExist,
      'userRegisterEmailAddres': userRegisterEmailAddres,
      'authetype': authenticationType,
      'emailAddress': emailAddress,
      "emailVerified": true,
      "phoneVerified": true
    }
  })

  const defaultText = 'Currency based on your location';
  const initialOptionIndex = countryCurrencies?.findIndex((option: any) => option.currencyCode === registerUserCurrencyData?.currencyCode);

  return (
    <form >
      <Grid container className='iGor__RegisterAccount'>
        <Grid item xs={12} marginBottom={1}>

          <Box className='iGOr_mb-D-none' sx={{ marginBottom: '15px' }}>
            <Typography align='center' variant="subtitle1">
              {t('Register your account')}</Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className='iGor_setRelative'>
          <Box className='InputField' marginRight={1} >
            <TextField
              name="first-name"
              className='iGor_inputFieldval iGor__RegField'
              label={t('First Name')}
              type='text'
              onChange={nameInputHandler}
              value={firstNameUser}
              id='first-name'

            />
            {ButtonClicked && firstNameUser == '' && <label className="iGor-ErrorMsg firstNameError">{t('Please input your first name')}</label>}
          </Box>
        </Grid>

        <Grid item xs={6} className='iGor_setRelative'>
          <Box className='InputField '  >
            <TextField
              name="last-name"
              className='iGor_inputFieldval iGor__RegField'
              label={t('Last Name')}
              fullWidth
              onChange={nameInputHandler}
              value={lastNameUser}
              id='last-name'
            />
            {ButtonClicked && lastNameUser == '' && <label className="iGor-ErrorMsg lastNameError">{t('Please input your last name')}</label>}
          </Box>
        </Grid>
        <Box className="iGor-PR-20 iGor-MB-15 iGOr_mb-D-none">
          <Typography variant="body1" >
            {t('Make sure it matches')}</Typography>
        </Box>
        <Grid item xs={12} className='iGor_setRelative'>
          <Box className='InputField'>
            <DatePickerInPopOver />
            {ButtonClicked && showDate === false && <label className="iGor-ErrorMsg">{t('Please fill with your date of birth')}</label>}
          </Box>
        </Grid>
        <Grid item xs={12} className='iGor_setRelative'>
          <Box className='InputField'  >
            <img src={MailOutlineIcon} className='inputIcons' />

            <TextField

              className='iGor_inputFieldval iGor__RegField iGor_regEmail'
              label={t('Email Address')}
              value={authenticationType !== emailAuthentication ? userRegisterEmailAddres : userRegisterEmailAddress}
              onChange={emailHandler}
              fullWidth
              id='Email'
              InputProps={{ readOnly: authenticationType !== emailAuthentication ? true : false }}
            // error={ emailValidator? true:false}
            // helperText={emailValidator? "please input":""}
            />
            {ButtonClicked && userRegisterEmailAddres == '' && authenticationType == emailAuthentication && <label className="iGor-ErrorMsg">{emailErr}</label>}
          </Box>
        </Grid>

        <Grid item xs={5} className='iGor_setRelative iGor_CountryCodeinput'>
          <CountryCode setCallCodeError={setCallCodeError} />
          {ButtonClicked && registerUserCountryCall.countryCallCodeLabel == '' && <label className="iGor-ErrorMsg">{callCodeError}</label>}
        </Grid>

        <Grid item xs={7} className='iGor_setRelative'>
          <Box className='InputField iGor-ML-10' >
            <TextField
              className='iGor_inputFieldval iGor__RegField'
              label={t('Phone Number')}
              type='number'
              onChange={phoneNoHandler}
              value={userPhoneNumber}
              id='Number'
            />
            {ButtonClicked && phoneValidator == false && <label className="iGor-ErrorMsg phoneError">{phoneErrMsg}</label>}
          </Box>
        </Grid>
        <Box className='iGor-MB-15' sx={{ width: '100%', textAlign: 'center' }}>
          {matches ?
            <Typography variant="body1" className='iGor__registerSubTitle' color={'#757575'}>
              We will call or send you a confirmation to your phone number.
              <br /> Charges may apply.</Typography> :
            <Typography variant="body1" className='iGor__registerSubTitle' >
              {t('We will call or send you')}</Typography>}



        </Box>
        <Grid item xs={12} className='iGor_setRelative iGor_passwordInput'>
          {authenticationType !== emailAuthentication ? (<></>) : (
            <PasswordCustom
              placeholder='Password'
              fromRegister={fromRegister}
              passwordInputRegister={passwordInputRegister}
              passwordInputHandlerForRegister={passwordInputHandlerForRegister}
              setPasswordInputRegister={setPasswordInputRegister}
            />)}
          {ButtonClicked && userRegisterPassword.length < 6 && authenticationType == emailAuthentication && <label className="iGor-ErrorMsg">{passwordMsg}</label>}
        </Grid>

        <Grid item xs={12} className='iGor_setRelative iGoR_autoComBox'>
          <Autocomplete
            className='iGoR_autoCom'
            disablePortal
            options={countryCurrencies != null ? countryCurrencies : []}
            value={initialOptionIndex !== -1 ? countryCurrencies[initialOptionIndex] : null}
            renderInput={(params) => (
              <TextField
                className='iGor_inputFieldval'
                {...params}
                placeholder={defaultText}
                InputLabelProps={{
                  ...(params.InputLabelProps as Partial<InputLabelProps>),
                }}
              />
            )}
            onChange={(value, data) => currencyHandler(value, data)}
          />
          {ButtonClicked && registerUserCurrencyData.currencyId === 0 && <label className="iGor-ErrorMsg iGorCurrError">{t('signup currancy')}</label>}
        </Grid>
        <Box className='iGor-Text-center iGor-width-100 iGor-Checkbox'>
          <Checkbox
            onChange={(e) => allowMoeNotification == false ? allowMoeNotificationsHandler(true) : allowMoeNotificationsHandler(false)}
            checked={allowMoeNotification}
          />
          <Typography variant="body1" sx={{ textAlign: 'justify', margin: "0.956rem 0", color: '#757575' }}>
            {t('By continuing')}
            <a href='/terms-conditions' target='_blank' className='iGor__TeramAndConLink'>
              {t('terms & conditions')}
            </a>
            {t('and')}
            <a href='/privacy-policy' target='_blank' className='iGor__TeramAndConLink'>
              {t('privacy policy')}
            </a>
            {t('and')} {t('to subscribe')}
          </Typography>
        </Box>
      </Grid>
      <Button variant="contained"
        className='iGor__JoinNowBtn'
        onClick={registerUserHandler}
        disabled={actionLoader?.loading == true ? true : false}
      >
        {actionLoader?.action == 'signUp' && actionLoader?.loading == true ? <Box className='iGor-action_loader'>
          <FontAwesomeIcon icon={faSpinner} spin />
        </Box> :
          matches ? `Join now` : `Continue`}
      </Button>
    </form>
  );
};