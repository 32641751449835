import { Middleware } from '@reduxjs/toolkit';
import { resetState } from './hotelSlice';
import { navigateTo } from './hotelSlice';
import { AppLogHandler } from '../utils/handlers/appLogsHandler';

const TTL = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

// const TTL = 1 * 60 * 1000; // 1 minutes in milliseconds

const ttlMiddleware: Middleware = storeAPI => next => action => {
    const lastActionTime: any = localStorage.getItem('lastActionTime');
    const currentTime: any = new Date().getTime();

    if (lastActionTime && (currentTime - lastActionTime > TTL)) {
        // Reset the state if the TTL has expired
        if (action.type !== resetState.type) {
            AppLogHandler('TTL expired, resetting state', { lastActionTime, currentTime });
            storeAPI.dispatch(resetState());
            localStorage.removeItem('lastActionTime');
            storeAPI.dispatch(navigateTo('/'));
        }
    }

    // Update the timestamp of the last action
    localStorage.setItem('lastActionTime', currentTime);

    return next(action);
};

export default ttlMiddleware;
