import React from 'react'
import Header from '../../layouts/Header'
import demoImg from '../../assets/images/coverImg.jpg'
import ProfileImg from '../../assets/icons/Icon_demoProfile.svg'
import calendarIcon from '../../assets/icons/calender.svg'
import clockIcon from '../../assets/icons/clockhistory.svg'
import ShareIcon from '@mui/icons-material/Share';
import cardImg from '../../assets/images/ArticalImg.png'
import BorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Grid, Box, Typography, useMediaQuery } from '@mui/material'
import { Container } from '@mui/system'
import { Footer } from '../../layouts/Footer'
import { useLocation, useNavigate, useParams } from 'react-router'
import ReactParser from 'html-react-parser';
import convertDateFormat, { formatDateOnSearchResultPage } from '../../utils/utilities/DateConverter'
import { env } from '../../utils/config/appConfig'
import { AppLogHandler } from '../../utils/handlers/appLogsHandler'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchExploreThingTodo, fetchFaviourateFolderList, fetchRemoveFromFaviourates, setAlertBoxOnError, setErrorMessageForDialogueBox, setExploreThingToDoSelectedReset, setPreviousPathName, setRemoveFromFaviouratesReset } from '../../appStore/hotelSlice'
import { useAppSelector } from '../../appStore/hooks'
import { environmentVariables } from '../../utils/config/environmentVariables'
import PropertyShareModel from '../../components/popUpSection/PropertyShareModel'
import AddToFaviourates from '../../pages/PropertyDetails/AddToFaviourates'
import { addHotelFavioratesType } from '../../utils/constants/constant'
import ArrowBack from '@mui/icons-material/ArrowBackIosNew';

const Highlight = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const [AddtoWhishlishOpen, setAddtoWhishlishOpen] = useState(false);
    const params = useParams();
    const slugReceived = state?.slug ? state?.slug : params?.slug;
    const { exploreThingToDoSelected, loggedInUserCurrency, userRemoveFromFaviourates, previousPathName }: any = useAppSelector(state => state.hotel)
    const [exploreThingToDoSelectedData] = exploreThingToDoSelected?.data ? exploreThingToDoSelected?.data?.map((data: any) => data) : []
    const exploreThingSlug = params.slug ? params.slug : '';
    const exploreDestinationDetails = exploreThingToDoSelectedData?.data ? exploreThingToDoSelectedData?.data : {}
    const dataformated = exploreDestinationDetails?.date ? formatDateOnSearchResultPage(exploreDestinationDetails?.date, 'detail') : '';
    const exploreDetailsBody = exploreDestinationDetails?.body;
    const isWishlistArticle = exploreDestinationDetails?.wish;
    const imagePathUrl = environmentVariables.imagePathApp;
    const articleId = exploreDestinationDetails.id;
    const faviourateType = addHotelFavioratesType.article;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    let previousPath = state?.prevPathid ? state?.prevPathid : '';
    const matches = useMediaQuery('(max-width:1024px)');

    const navigateToHome = () => {
        navigate(`/`)
    }

    // move to previous screen handler 
    const moveToPreviousScreenHanlder = () => {
        if (previousPathName.includes('/favourites')) {
            dispatch(setPreviousPathName(''))
            navigate('/favourites')
        }
        else {
            navigate(`/explore/${previousPath}`)
        }
    }

    // open faviourate section hanlder
    const openAddToFaviouratesSection = (userId: number) => {
        if (userId !== 0) {
            setAddtoWhishlishOpen(true);
            dispatch(fetchFaviourateFolderList(userId))
        }
        else {
            dispatch(setErrorMessageForDialogueBox('15'))
            dispatch(setAlertBoxOnError(true))
        }
    }

    // remove from faviourates handler
    const removeFromWishlist = (userId: number, faviourateId: number) => {
        if (userId !== 0 && faviourateId && faviourateType) {
            dispatch(fetchRemoveFromFaviourates({ userId: userId, faviourateId: faviourateId, type: faviourateType }))
        }
        else {
            dispatch(setErrorMessageForDialogueBox('15'))
            dispatch(setAlertBoxOnError(true))
        }
    }

    const addToWishlistHandlerDataUpdate = () => {
        dispatch(fetchExploreThingTodo({ slugId: exploreThingSlug, userId: loggedInUserId }))
    }

    // api call for selected region from params
    useEffect(() => {
        if (exploreThingSlug == slugReceived && loggedInUserId !== undefined) {
            dispatch(fetchExploreThingTodo({ slugId: exploreThingSlug, userId: loggedInUserId }))
        }
        else if (exploreThingSlug !== slugReceived && slugReceived !== undefined) {
            dispatch(setErrorMessageForDialogueBox('32'))
            dispatch(setAlertBoxOnError(true))
            setTimeout(navigateToHome, 4000)
        }
        else if (userRemoveFromFaviourates?.data?.message?.includes("Article removed successfully")) {
            dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
            addToWishlistHandlerDataUpdate()
        }
    }, [exploreThingSlug, slugReceived, loggedInUserId, userRemoveFromFaviourates?.data?.message])

    AppLogHandler('explore things to do page', exploreThingToDoSelectedData)
    return (
        <>
            {!matches ? <Header name='iGoRoomHeader' currClass='iGor_currColor' /> :
                <Box className="iGor_RegionsTop" sx={{ top: '5%', left: '20px', zIndex: '1' }}>
                    <ArrowBack className='iGor_backBtn' onClick={moveToPreviousScreenHanlder} />
                </Box>
            }


            <Box className="section-article-detail" >
                <Container className="iGor-article_wrapper">
                    <Grid container>
                        <Grid item sm={12} className="iGor__banner-article-details" xs={12}>
                            <Box>
                                <Box className="iGor__images">
                                    <Box className="iGor__img">
                                        <img src={`${imagePathUrl}${exploreDestinationDetails?.bg_image}`} alt="Luqaimat" />
                                    </Box>
                                </Box>
                                <Box className="iGor__description">
                                    <Typography className="iGor__type" fontWeight={600}>{exploreDestinationDetails?.category}</Typography>
                                    <Typography variant='h4' className="iGor__title">{exploreDestinationDetails?.title}</Typography>
                                    <Box className="iGor__info">
                                        <Box className="iGor__read iGor__readBy iGor_setFlex iGor_alignCenter">
                                            <img src={exploreDestinationDetails?.authorPhoto ? exploreDestinationDetails?.authorPhoto : ProfileImg}
                                                onError={(e: any) => {
                                                    // Handle the image load error by setting the source to the default image
                                                    e.target.src = ProfileImg;
                                                }}
                                                alt={ProfileImg} />
                                            <Typography className='iGo__subtitle'>by {exploreDestinationDetails?.authorName ? exploreDestinationDetails.authorName : 'Name not available'}</Typography>
                                        </Box>
                                        <Box className="iGor__read iGor__readTime iGor_setFlex iGor_alignCenter">
                                            <img src={clockIcon} alt="clockIcon" />
                                            <Typography className='iGo__subtitle'>{exploreDestinationDetails?.reading_time}</Typography>
                                        </Box>
                                        <Box className="iGor__date iGor_setFlex iGor_alignCenter">
                                            <img src={calendarIcon} alt="calendarIcon" />
                                            <Typography className='iGo__subtitle'>{dataformated}</Typography>
                                        </Box>
                                    </Box>
                                    <Typography>{exploreDestinationDetails?.subtitle}</Typography>
                                    <Box className="iGor__btn-wrapper">
                                        <PropertyShareModel hotelDetails={exploreDestinationDetails} />
                                        {isWishlistArticle == false ? (
                                            <Box
                                                className="iGor__btn-icon wishlist"
                                                onClick={(e) => openAddToFaviouratesSection(loggedInUserId)}
                                            >
                                                <BorderIcon className="share" />
                                            </Box>) :
                                            (<Box
                                                className="iGor__btn-icon wishlist"
                                                onClick={(e) => removeFromWishlist(loggedInUserId, articleId)}
                                            >
                                                <FavoriteIcon
                                                    className="share"
                                                    sx={{ color: '#FF6B6B' }}
                                                />
                                            </Box>)}

                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container className='iGor-article_body'>
                        <Grid item sm={12} >
                            <Box className="iGor__articleCards">
                                <Typography variant='h4' className='iGor__ArtTitle'>1. {exploreDestinationDetails?.title}</Typography>
                                {/* <Box className='iGor__ArtImgCard'>
                                    <img src={cardImg} alt='img' />
                                </Box> */}
                                {exploreDetailsBody ? (<Box>
                                    {ReactParser(exploreDestinationDetails?.body as any)}
                                </Box>) : ''}
                                {/* <Typography className='iGor__ArtSubTitle'>At over 1,145 square kilometers, with a depth of up to 450 meters, it’s no surprise that this lake is renowned as the largest lake in Southeast Asia. Lake Toba, which occupies a caldera of a supervolcano in the south of Medan City, North Sumatra, is filled and surrounded by countless wonders. In the center lies a huge island known as the Samosir Island. Home to the Batak Toba tribe, the island offers various interesting cultural experiences. Buy local products at Tomok Market, see how ulos; a traditional woven fabric of Batak, is made at Ulos Huta Raja Village, and learn about the history of Batak Toba at Huta Bolon Museum. Hills that stretch around the lake also provide a spectacular panorama worth capturing.</Typography> */}
                            </Box>
                        </Grid>
                    </Grid>
                </Container>



                <Footer />
            </Box>
            <AddToFaviourates
                faviourateType={faviourateType}
                AddtoWhishlishOpen={AddtoWhishlishOpen}
                setAddtoWhishlishOpen={setAddtoWhishlishOpen}
                hotelIdSelected={articleId}
                addToWishlistHandlerDataUpdate={addToWishlistHandlerDataUpdate}
            />
        </>
    )
}

export default Highlight