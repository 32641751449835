import { userProfileUpdate } from "../utils/constants/types";

export const updateUserProfilePayload = (dateOfBirth: string, questionare: string, userRegisterMail: string, firstNameUser: string, lastNameUser: string, phoneNumber: string) => {
    let dateOfBirthFormated = ''
    if (dateOfBirth !== '') {
        dateOfBirthFormated
        const date = new Date(dateOfBirth)
        // format birthdate for api payload
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        dateOfBirthFormated = `${year}-${month}-${day}`;
    }

    // payload body for update user profile
    const payload: userProfileUpdate = {
        userDetails: {
            LastName: lastNameUser,
            birthDate: dateOfBirthFormated,
            firstName: firstNameUser,
            questionare: questionare,
            phone: phoneNumber
        },
        userRegisterMail: userRegisterMail
    }
    return payload
}