import React, { useState } from 'react';
import { Typography, Box, useMediaQuery } from '@mui/material';
import NoHotelsImg from '../../assets/images/noHotelIgo.jpg'
import Icon_moon from '../../assets/icons/Icon_moon.svg';
import { useAppSelector } from '../../appStore/hooks';
import { AdultCount, ChildCount } from '../../utils/handlers/arrayLoop';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { env } from '../../utils/config/appConfig';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import PropertyDAccordion from '../../components/PropertyDAccordion/PropertyDAccordion';
import { formatDateOnSearchResultPageMobile } from '../../utils/utilities/DateConverter';
import { environmentVariables } from '../../utils/config/environmentVariables';
import InfoIcon from '@mui/icons-material/Info';


export const OrderSummaryCard = () => {
    const { t } = useTranslation();
    const { searchDestinationFromDate, searchDestinationToDate, noOfNights, roomArray, hotelRoomPriceApiData, roomTypeSelectedForCheckout, selectedCurrencySymboll } = useAppSelector(state => state.hotel)
    const hotelPriceData: any = hotelRoomPriceApiData.data ? hotelRoomPriceApiData.data : []
    const hotelPriceApiLoading: any = hotelRoomPriceApiData.loading;
    const [roomGroup] = hotelPriceData?.roomGroup ? hotelPriceData?.roomGroup : []
    const currencyForcheckout = roomGroup?.currency;
    const roomBedType = hotelPriceData.bedType ? hotelPriceData.bedType : '';
    const fromDate = formatDateOnSearchResultPageMobile(searchDestinationFromDate);
    const toDate = formatDateOnSearchResultPageMobile(searchDestinationToDate)
    const adultCount = AdultCount(roomArray);
    const childCount = ChildCount(roomArray);
    const imagePath = environmentVariables.imagePathApp;
    const currencySelected = roomTypeSelectedForCheckout.listprice?.currency_symbol ? roomTypeSelectedForCheckout.listprice?.currency_symbol : selectedCurrencySymboll;
    const roomNameSelected = roomTypeSelectedForCheckout?.roomname;
    const roomInclusions = roomTypeSelectedForCheckout?.packageinclusion ? roomTypeSelectedForCheckout?.packageinclusion : []
    const roomImage = roomTypeSelectedForCheckout?.image ? roomTypeSelectedForCheckout?.image : NoHotelsImg;
    const matches = useMediaQuery('(max-width:1024px)');

    AppLogHandler("roomGroup,selectedroomForCheckout", { roomGroup, hotelPriceData, roomInclusions, roomTypeSelectedForCheckout })

    return (
        <>
            {matches &&
                <>
                    <Typography fontSize={'8px'} fontWeight={'400'} align='center' mb={'9px'} mt={2}> Please review your booking details before continuing to payment</Typography>
                    <Box className="iGoi_OrderSumLeftCrad iGor_bgWhite iGOr_BorderRadius15">
                        <Box className="iGor-Text-center iGor__ShowTitle">
                            <Typography variant='body2'>This how long you will stay</Typography>
                        </Box>
                        <Box className='iGor_setFlex iGor_justifyBetween iGor__checkInOut'>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                <Typography> Check-in</Typography>
                                <Typography fontWeight={'600'}> {fromDate}</Typography>
                            </Box>
                            <Box className='iGor_Icon_moon'>
                                <img src={Icon_moon} alt="Icon_moon" />
                                <Typography fontWeight={'600'}>{noOfNights} Nights</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                                <Typography>   Check-out</Typography>
                                <Typography fontWeight={'600'}>{toDate}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </>
            }


            <Box className="iGoi_OrderSumLeftCrad iGor_bgWhite iGOr_BorderRadius15 iGor_OrderSumRoomDetails">
                <Box className='iGoi_OrderSumTopTitle '>
                    <Typography variant='subtitle1' className=' iGOr_mb-D-none'> Order Summary</Typography>
                </Box>
                <Box className='iGor_setFlex iGoi_OrderSumImgHeader'>

                    <Box className='iGoi_OrderSumImgCard'>
                        <img src={`${imagePath}${roomImage}`}
                            onError={(e: any) => {
                                // Handle the image load error by setting the source to the default image
                                e.target.src = NoHotelsImg;
                            }} alt='roomimg'
                            width={'100%'} className='iGoi_OrderSumImg iGOr_BorderRadius15' />
                    </Box>
                    <Box width={'50%'} paddingLeft={2}>
                        <Typography variant='subtitle1' mt={1} className='iGor_RoomName-Clamped'>  {roomNameSelected}</Typography>
                        <Box className="iGor_orderBedType">
                            {roomBedType != '' ? (<><Typography variant='body1' className='iGor_orderBedTyTilte'> Bed type </Typography>
                                <Typography variant='body1' fontWeight={'400'} color={'#C4C4C4'}> {roomBedType} </Typography></>) : ''}
                        </Box>
                        <Typography variant='body1' fontWeight={'600'} marginTop={'0.5rem'}> Number of people</Typography>
                        <Typography variant='body1' fontWeight={'400'} color={'#C4C4C4'}> {adultCount} {`${adultCount > 1 ? 'adults' : 'adult'}`} {childCount > 0 ? `, ${childCount}  ${childCount == 1 ? ' child' : ' childs'} ` : ''}</Typography>
                    </Box>
                </Box>
                <Box className='iGor_setFlex iGor_justifyBetween iGor-Policies'>
                    <PropertyDAccordion />
                </Box>

                {!matches && <>
                    <Box className="iGor-Text-center iGor__ShowTitle">
                        <Typography variant='body2'>This how long you will stay</Typography>
                    </Box>
                    <Box className='iGor_setFlex iGor_justifyBetween iGor__checkInOut'>
                        <Box>
                            <Typography> Check-in</Typography>
                            <Typography fontWeight={'600'}> {fromDate}</Typography>
                        </Box>
                        <Box className='iGor_Icon_moon'>
                            <img src={Icon_moon} alt="Icon_moon" />
                            <Typography fontWeight={'600'}>{noOfNights} Nights</Typography>
                        </Box>
                        <Box>
                            <Typography>   Check-out</Typography>
                            <Typography fontWeight={'600'}>{toDate}</Typography>
                        </Box>
                    </Box>

                    <Box>
                        <Typography variant='subtitle1'>Price details</Typography>
                        <Box className='iGor_setFlex iGor_justifyBetween' marginY={1}>
                            <Typography variant='subtitle1' fontSize={'12px'}> ({noOfNights} Nights) {roomNameSelected}   </Typography>
                            <Typography variant='subtitle1'>{`${hotelPriceApiLoading == true ? 'loading' : roomGroup?.roundOffTotalRate ? currencySelected + " " + numeral(roomGroup?.roundOffTotalRate).format('0,0') : roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price ? roomTypeSelectedForCheckout?.listprice?.currency_symbol + numeral(roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price).format('0,0') : 'not available'}`}</Typography>
                        </Box>
                        {roomInclusions.length > 0 ? (<><Typography variant='body1' fontWeight={'600'}>  Inclusions details</Typography>
                            <ul className='iGor_OrderSumList'>
                                {roomInclusions?.map((inclusions: any) => {
                                    return <li key={inclusions}><Typography variant='body1'>{inclusions}</Typography> </li>
                                })}
                            </ul></>) : ''}
                        <>

                            <Box className='iGor_setFlex iGor_justifyBetween iGor_taxandfees' marginY={1}>
                                <Typography> Tax and Other fees</Typography>
                                <Typography>{`${hotelPriceApiLoading == true ? 'loading' : roomGroup?.totalTaxesAndFees !== undefined ? currencySelected + " " + numeral(roomGroup?.totalTaxesAndFees).format('0,0') : roomTypeSelectedForCheckout?.listprice?.currency_symbol + 0}`}</Typography>
                            </Box></>
                    </Box>
                </>}
            </Box>

            {matches && <>
                <Box className="iGoi_OrderSumLeftCrad iGor_bgWhite iGOr_BorderRadius15">
                    <Box>
                        <Typography variant='subtitle1'>Price details</Typography>
                        <Box className='iGor_setFlex iGor_justifyBetween' marginY={1}>
                            <Typography variant='subtitle1'> ({noOfNights} Nights) {roomGroup?.room?.name}   </Typography>

                            <Typography variant='subtitle1'>{`${hotelPriceApiLoading == true ? 'loading' : roomGroup?.baseRate ? currencySelected + " " + numeral(roomGroup?.baseRate).format('0,0') : roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price ? roomTypeSelectedForCheckout?.listprice?.currency_symbol + numeral(roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price).format('0,0') : 'not available'}`}</Typography>
                        </Box>

                        <Box className='iGor_setFlex iGor_justifyBetween' marginY={1}>
                            <Typography variant='subtitle1'>Tax and Other fees</Typography>
                            <Typography variant='subtitle1'>{`${hotelPriceApiLoading == true ? 'loading' : roomGroup?.roundOffTotalTaxesAndFees !== undefined ? currencySelected + " " + numeral(roomGroup?.roundOffTotalTaxesAndFees).format('0,0') : roomTypeSelectedForCheckout?.listprice?.currency_symbol + 0}`}</Typography>
                        </Box>
                        <Box className='iGor_setFlex iGor_justifyBetween' marginY={1}>
                            <Typography variant='subtitle1'> Total</Typography>
                            <Typography variant='subtitle1'>{`${hotelPriceApiLoading == true ? 'loading' : roomGroup?.roundOffTotalRate ? currencySelected + " " + numeral(roomGroup?.roundOffTotalRate).format('0,0') : roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price ? roomTypeSelectedForCheckout?.listprice?.currency_symbol + numeral(roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price).format('0,0') : 'not available'}`}</Typography>
                        </Box>
                        {roomInclusions.length > 0 ? (<><Typography variant='body1' fontWeight={'600'}>  Inclusions details</Typography>
                            <ul className='iGor_OrderSumList'>
                                {roomInclusions?.map((inclusions: any) => {
                                    return <li key={inclusions}><Typography variant='body1'>{inclusions}</Typography> </li>
                                })}
                            </ul></>) : ''}
                    </Box>
                </Box>

            </>}
        </>
    )
}
