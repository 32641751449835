import { Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { useAppSelector } from "../../appStore/hooks";
import Header from "../../layouts/Header";
import { useTermsConditionsQuery } from "../../services/api/hotelApi";
import ReactParser from 'html-react-parser';
import { Box, Container } from "@mui/system";
import { Link } from "react-router-dom";
import BackArrow from '@mui/icons-material/KeyboardArrowLeft';

function TermsAndConditions() {
    useTermsConditionsQuery('')
    const { TermsAndConditions } = useAppSelector(state => state.hotel)
    const termsAndConditionsUIData = TermsAndConditions
    const matches = useMediaQuery('(max-width:1024px)');
    return (<>
        <Box bgcolor={'#ffffff'} marginBottom={4}>
            <Header name='iGoRoomHeader' currClass='iGor_currColor' />
            <Button className='iGor__ProBackArrow'><Link to={'/'}><BackArrow /></Link></Button>
        </Box>
        <Container>
            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '97%' }}>
                <Typography fontSize={'1.75rem'} fontWeight={600}>Terms & Conditions</Typography>
                {!matches && <Typography fontSize={'1.75rem'} fontWeight={600}>Table of Contents</Typography>}
            </Box>
            <Grid container spacing={2}>
                <Grid item lg={9} sm={12}>
                    <Box className='iGor__pageslink'> {ReactParser(termsAndConditionsUIData as any)}
                    </Box>
                </Grid>
                <Grid item sm={3}>
                    <Box className='iGor__pageslink iGor__TermAndcon iGOr_mb-D-none'>
                        <ul >
                            <li><a href="#generalterms">General Terms</a> </li>
                            <li><a href="#useofthewebsite">Use Of The Website</a></li>
                            <li><a href="#igoroomaccount">Igoroom Account</a></li>
                            <li><a href="#bookingonigoroom">Booking on Igoroom</a></li>
                            <li><a href="#specialerequest">  Special Request </a></li>
                            <li><a href="#payment"> Payment</a></li>
                            <li><a href="#cancellationamendment">Cancellation & Amendment</a></li>
                            <li><a href="#refundpolicy">Refund Policy</a></li>
                            <li><a href="#informationonthiswebsite">Information on This Website</a></li>
                            <li><a href="#reviewscommentsratings"> Reviews, Comments, Ratings and Photos</a></li>
                            <li><a href="#righttoalter">Right to Alter, Vary or Alter Website (Variations)</a></li>
                            <li><a href="#traveldestinations">Travel Destinations</a></li>
                            <li><a href="#complaints">Complaints</a></li>
                            <li><a href="#liability">Liability</a></li>
                            <li><a href="#noliability">No Liability for Travel Products</a></li>
                            <li><a href="#indemnity">Indemnity</a></li>
                            <li><a href="#intellectualproperty">Intellectual Property</a></li>
                            <li><a href="#forcemajeure">Force Majeure</a></li>
                            <li><a href="#contactinformation">Contact Information</a></li>
                            <li><a href="#governinglaw">Governing Law</a></li>

                        </ul>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </>
    );
}
export default TermsAndConditions;