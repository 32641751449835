import React, { useEffect, useState } from 'react';
import { IconButton, Button, Popover, Typography, List, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material";
import InboxUpdateCards from '../InboxCards/InboxUpdateCards';
import InboxMassgaeCards from '../InboxCards/InboxMassgaeCards';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { useAppSelector } from '../../appStore/hooks';
import Header from '../../layouts/Header';
import InboxNewsRoomsCard from '../../components/InboxCards/InboxNewsRoomsCard';


const MobileInbox = () => {
    const [inbox, setInbox] = useState(false);
    const [inboxTabs, setInboxTabs] = useState(0);
    const { getUserDetails, setUserLoggedIn, loggedInUserCurrency, userNotificationsAndMessg } = useAppSelector(state => state.hotel);
    const userNotifications = userNotificationsAndMessg;
    const userMessages = userNotificationsAndMessg;
    return (
        <>
            <Box sx={{ margin: '53px 0 25px 0px' }} >
                <Header name='iGoRoomHeader iGor__SecondMenuMB iGor__inboxMenuMB' currClass='iGor_currColor' />
            </Box>
            <Box className='iGor__inbox'>
                <Box onClick={() => setInbox(!inbox)}><Typography variant='h4' className='iGor__inboxTitle' sx={{ margin: '-30px auto 35px auto' }}>Inbox</Typography></Box>
                <Box className='iGor__InboxTabs'>
                    <Typography className={inboxTabs == 0 ? 'iGor__inboxBtn iGor__inboxActive' : 'iGor__inboxBtn '} onClick={() => setInboxTabs(0)}>
                        Update
                    </Typography>
                    <Typography className={inboxTabs == 1 ? 'iGor__inboxBtn iGor__inboxActive' : 'iGor__inboxBtn'} onClick={() => setInboxTabs(1)}>
                        Messages
                    </Typography>
                    <Typography className={inboxTabs === 2 ? 'iGor__inboxBtn iGor__inboxActive' : 'iGor__inboxBtn'} onClick={() => setInboxTabs(2)}>
                        Newsroom
                    </Typography>
                </Box>
                {inboxTabs == 1 ?
                    <Box className='iGor__tabBox' sx={{ height: '75vh', overflowY: 'auto' }}>
                        <InboxMassgaeCards userMessages={userNotifications} />
                    </Box>
                    : inboxTabs == 0 ?
                        <Box sx={{ height: '75vh', overflowY: 'auto' }}>
                            <InboxUpdateCards userNotifications={userMessages} />
                        </Box> :
                        <Box sx={{ height: '75vh', overflowY: 'auto' }}>
                            <InboxNewsRoomsCard />
                        </Box>
                }
            </Box></>
    )
}

export default MobileInbox