import React, { useEffect } from 'react'
import { Modal, Box, IconButton, Button } from "@mui/material";
import { Close } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { LoginAccount } from '../userDetailsFrom/LoginAccount';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from 'react-redux';
import { setIsLogedInUser } from '../../appStore/hotelSlice';
import { useAppSelector } from '../../appStore/hooks';
import IgoRoomLogo from '../../assets/icons/iGoRoom_icons.svg';
import ClearIcon from '@mui/icons-material/Clear';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '15px',
    padding: '0 1rem',
    background: '#F1F5F9 ',
    overflow: 'hidden'

};
export const OrderSumRegisterModel = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const getUserEmailExist = useAppSelector(state => state.hotel.getUserEmailExist.data?.status);
    const { setUserLoggedIn, getUserDetails } = useAppSelector(state => state.hotel)

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const matches = useMediaQuery('(max-width:1024px)');

    useEffect(() => {
        if (getUserDetails.data?.userDetail?.email && getUserEmailExist == true) {
            dispatch(setIsLogedInUser(true))
        }
    }, [getUserDetails.data?.userDetail, getUserEmailExist])


    return (
        <div>
            <Button variant='contained' className='iGor__OrderJoinNowBtn' onClick={handleOpen}>{matches ? 'Join Now' : 'Log In or Register'}</Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <>
                    <Box sx={style} className='iGor_Modal iGor_orderSumJoinMod'>
                        {matches ?
                            <Box className="iGo_mb-menuTop" >
                                <img src={IgoRoomLogo} alt="IgoRoomLogo" />
                                <ClearIcon className="iGo_m-menuClear" onClick={handleClose} />
                            </Box> :
                            <div className="modal-close">
                                <IconButton onClick={handleClose} className="close-button">
                                    <Close />
                                </IconButton>
                            </div>
                        }
                        <Box>
                            {setUserLoggedIn ?
                                (<></>) : <LoginAccount />}
                        </Box>
                    </Box>
                </>
            </Modal>
        </div>
    );
}
