export const toastMessages = {
    // toast error messages    
    1: "User account doesn't exist. Please create account",
    2: "User Sign In Password Wrong !",
    3: "Email Not Match Please Try With Another One !!!",
    4: "We have blocked all request from this device due to unusual activity. Please try again later ...!",
    5: "There might be problem due to network. Please try after sometime ...!",
    6: "Please try with another email id...!",
    7: "User account already exist.Please try again with other social signin type",
    8: "Email address not found please try with different email",
    9: "Invalid email address, please try again",
    10: "Please select atleast one gateway",
    11: "Please select atleast one holiday maker...!",
    12: "Please log in first or register if you are not registered yet...!",
    13: "There might be problem with booking confirmation please try again..",
    14: "There might be problem with payment confirmation please try again..",
    15: "Kindly log in first to add or remove a hotel from your favorites....!",
    16: "Data Not Found...",
    17: "Payment Authorization Failed: Please try again later or contact our support team for assistance.",
    18: "Kindly log in first to see latest offers...!",
    19: "Hotel details not available for this hotel. Please try with another hotel ...!",
    20: "Booking details not found for this booking...!",
    21: "Please try to select an option with a refundable booking...!",
    22: "Cancellation policies were not found for this booking...!",
    23: "Cannot cancel this booking because canceling it will result in a partial refund...!",
    24: "The date was selected for this hotel is expired please search hotel for other date...!",
    25: "Your session has expired. You will be navigated to the home screen.",
    26: "Hotel id and destination id is madatory to view hotel detals and rooms please try with new search ...!",
    27: "This hotels was sold out please try with differnt one...!",
    28: "Room price not available for this room please try with another room...!",
    29: "Selected marker location is not vailable please try with another one...!",
    30: "This service is not currently available for this room. Please try another service...!",
    31: "Please select a destination to explore...!",
    32: "Please select atleast one thing to do...!",
    33: "Please select atleast one region to explore...!",
    34: "There might be problem at our service please try after some time...!",
    35: "hotel details not available...! please try for another hotel.",
    36: "You are not allowed to book hotels if your booking dates are before 90 days from current date in DEV or UAT mode.",
    37: "The selected currency is not supported by our payment provider, PayPal. Please select a different currency to proceed with your booking.",
    38: "We regret to inform you that we are currently unable to book this offer. Please try selecting another option.",
    39: "You are not a logged in user. Please log in or register to access and book offers.",

    // toast Succes messages
    1001: "You will get a mail on ",
    1002: "The booking was successfully canceled.",
    1003: "Link copied to clipboard...",
    1004: "Booking id copied to clipboard",

    // toast normal messages
    5001: "Hotel & Resort Deals Not Found",
    5002: "Offers Deals Not Found",
    5003: "Vip Deals Not Found",
    5004: "No deals found for you please try after sometime...",
    5005: "No Places Found",
    5006: "No reasons found for cancle booking",
    5007: "No inclusions available",
    5008: "No facilities availabel for this room",
    5009: "Notifications not available.",
    5010: "Messages not available.",
    5011: "No country codes found",
    5012: "For booking more than 5 rooms please contact our support team.",
    5013: "No additional service.",
    5014: "No upgrade available.",
    5015: "Problem with loading google map please refresh the page",
    5016: "No Cancellation Policies Available",
    5017: "Currently, there are no available offers, hotels, resorts, or VIP deals matching your criteria.",
    5018:"This rate is non-refundable. Please select a refundable option to view cancellation policies."
}
