import React, { useState } from 'react';
import { TextField, InputAdornment, IconButton, Box } from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import password_Icon from '../../assets/icons/password_Icon.svg';
import password_fill from '../../assets/icons/password-fill.svg';
import { useTranslation } from 'react-i18next';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';

const PasswordCustom = ({ passwordInputLogin, fromLogin, loginIcon, placeholder, passwordInputHandlerForLogin,
  fromRegister, passwordInputRegister, passwordInputHandlerForRegister }: any) => {
  // t object for language translation for keys
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  //const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  AppLogHandler("props", { passwordInputLogin, fromLogin, fromRegister })

  return (<>
    {fromLogin ? (<Box className='InputField'  >
      <img src={loginIcon ? password_fill : password_Icon} alt="password_Icon" className='inputIcons' />
      <TextField
        placeholder={t(`${placeholder}`)}
        type={showPassword ? 'text' : 'password'}
        onChange={(e) => passwordInputHandlerForLogin(e)}
        value={passwordInputLogin}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleTogglePasswordVisibility} sx={{ color: '#FF6B6B' }}>
                {showPassword ? <Visibility className='iGor_passIcon' /> : <VisibilityOff className='iGor_passIcon' />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>) : (<>
      <Box className='InputField'  >
        <img src={loginIcon ? password_fill : password_Icon} alt="password_Icon" className='inputIcons' />
        <TextField
          placeholder={t(`${placeholder}`)}
          type={showPassword ? 'text' : 'password'}
          onChange={(e) => passwordInputHandlerForRegister(e)}
          value={passwordInputRegister}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} sx={{ color: '#FF6B6B' }}>
                {showPassword ? <Visibility className='iGor_passIcon' /> : <VisibilityOff className='iGor_passIcon' />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </>)}
  </>

  );
};

export default PasswordCustom;