import { bookOfferHotelPayload } from "../utils/constants/types"

const bookOfferHotelPayloadData = (passengers: any, contact: any, rateName: string, propertyId: any, price: any,
    hotelName: string, searchId: string) => {
    const payload: bookOfferHotelPayload = {
        offerBookingDetails: {
            passengers: passengers,
            contact: contact,
            rateName: rateName,
            propertyId: propertyId,
            price: price,
            hotelName: hotelName

        },
        searchId: searchId
    }
    return payload;
}

export default bookOfferHotelPayloadData