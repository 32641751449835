import React, { useState } from 'react';
import { Box, Grid, IconButton, ListItem, ListItemIcon, ListItemText, Modal, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AdjustIcon from '@mui/icons-material/Adjust';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { dateConverterCancellationPolicies } from '../../utils/utilities/DateConverter';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FfFfFf',
    borderRadius: '15px',
    width: '25%',
    padding: '0 1rem',
};

function PropertyRoomCancellationPolicies({ hotelRoomRecoDetails ,roomDetails}: any) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const hotelRoomsDetail = hotelRoomRecoDetails;
    const [roomGroup] = hotelRoomsDetail?.roomGroup ? hotelRoomsDetail?.roomGroup : [];
    const refundability = roomGroup?.refundability;
    const policies = roomGroup?.cancellationPolicies?.length > 0 ? roomGroup?.cancellationPolicies[0] : {}
    const currencySymbol = roomDetails?.listprice?.currency_symbol ? roomDetails?.listprice?.currency_symbol : '';

    const handleClose = () => {
        setOpen(false)
    }
    const handleOpen = () => {
        setOpen(true)
    }

    return (<>
        <Box className='iGor_Cancellation-Policies' onClick={handleOpen}>
            <InfoIcon />
        </Box>
        <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
        >
            <Box sx={style} className='iGor_Modal' >
                <div className="modal-close">
                    <IconButton onClick={handleClose} className="close-button">
                        <Close />
                    </IconButton>
                </div>


                <Box sx={{ marginBottom: '0.625rem',display:'flex', justifyContent:'center' }}>
                    <Typography variant="subtitle1" marginTop={1.5}>
                        {t('Cancellation Policy')}
                    </Typography>
                </Box>
                <Box sx={{ overflowY: 'auto', maxHeight: '400px' }}>
                    {policies?.rules?.length> 0 ?(<Typography style={{textAlign: 'justify'}}>Please ensure that you understand this property policy. In 
                        certain cases, you may be eligible for a refund outside of this policy.
                    </Typography>):''}

                    {refundability == 'Refundable' && policies?.rules?.length > 0 ? policies.rules.map((policy: any) => (
                        <>
                            <ListItem className='iGor-Cancellation_policies_list'>
                                <Grid className='iGor-Cancellation_policies_room'>
                                    {policy.value == 0 ? (<Box className='iGor-room_policies'>
                                        <Typography> From {dateConverterCancellationPolicies(policy.start)} to {dateConverterCancellationPolicies(policy.end)}
                                            <span> 100% refund for this reservation</span>
                                        </Typography>
                                    </Box>) :
                                        (<Box className='iGor-room_policies'>
                                            <Typography> From {dateConverterCancellationPolicies(policy.start)} to {dateConverterCancellationPolicies(policy.end)}
                                                <span> Cancellation fees {currencySymbol}{policy.value?.toFixed(2)}</span>
                                            </Typography>
                                            {/* <Typography fontWeight={600}>Cancellation fees {currencySymbol}{policy.value}
                                        </Typography> */}
                                        </Box>)}
                                </Grid>
                            </ListItem>
                            <Typography fontWeight={600} marginBottom={0}></Typography>
                        </>
                    )) : refundability == 'NonRefundable' ?
                        (<Typography fontSize={12} fontWeight={600} marginBottom={3}>{t('5018')}</Typography>) :
                        (<Typography fontSize={12} fontWeight={600} marginBottom={3}>{t('5016')}</Typography>)}
                </Box>
            </Box>
        </Modal>
    </>);
}

export default PropertyRoomCancellationPolicies;