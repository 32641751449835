import React, { useEffect, useState } from 'react'
import { Box, Grid, Container, Typography, useMediaQuery, Button } from '@mui/material'
import Header from '../../layouts/Header'
import { AccountSideMenu } from '../../components/AccountDetails/AccountSideMenu';
import { useAppSelector } from '../../appStore/hooks';
import HotelresultSkeleton from '../../components/Skeleton/HotelresultSkeleton';
import MakeTripCard from './MakeTripCard';
import MytripCards from '../../components/MyTrip/MytripCards';
import { useNavigate } from 'react-router';
import MyBookedOffersCards from '../../components/MyBookedOffers/MyBookedOffersCards';

const MyTrip = () => {
    const navigate = useNavigate();
    const [activeButton, setActiveButton] = useState('Bookings'); // Default active button
    const { userBookingList, setUserLoggedIn, bookedOffersList } = useAppSelector(state => state.hotel)
    const userBookingsList = userBookingList?.data?.bookingList ? userBookingList?.data?.bookingList : [];
    const userBookingListApiLoading = userBookingList?.loading ? userBookingList?.loading : false;
    const bookedOffersListArray = bookedOffersList?.data?.offerList ? bookedOffersList?.data?.offerList : [];
    const bookedOffersListLoading = bookedOffersList?.loading ? bookedOffersList?.loading : false;
    const matches = useMediaQuery('(max-width:1024px)')

    useEffect(() => {
        if (setUserLoggedIn == false && !matches) {
            navigate('/')
        }
    }, [setUserLoggedIn])

    const handleButtonClick = (filterType: string) => {
        setActiveButton(filterType);
    }

    return (
        <>
            <Box sx={{ background: { xs: "", sm: "#ffffff" } }} ><Header name='iGoRoomHeader iGor__SecondMenuMB' currClass='iGor_currColor' /></Box>
            <Container>
                <Grid container spacing={2} marginY={2}>
                    <AccountSideMenu />
                    <Grid item lg={9} xs={12} >
                        <Box my={1} className='iGOr_Desk-D-none'>
                            <Typography variant='subtitle1' fontSize={'24px'} textAlign={'center'} mb={'33px'}>Upcoming Trip</Typography>
                        </Box>
                        <Box sx={{ background: "#fff", padding: '30px', borderRadius: '15px' }} className='iGOr_mb-D-none'>
                            <Typography variant='subtitle1'>
                                My Trip
                            </Typography>
                            <Typography variant='body1'>
                                See your upcoming or history of your trip.
                            </Typography>
                        </Box>
                        <Grid sm={12} xs={12} style={{ margin: '20px 0' }}>
                            <Button
                                className={'iGor_WhishListTab ' + (activeButton === 'Bookings' ? 'iGor_tabActive' : '')}
                                onClick={() => handleButtonClick('Bookings')}
                            >
                                Bookings
                            </Button>
                            <Button
                                className={'iGor_WhishListTab ' + (activeButton === 'Offers' ? 'iGor_tabActive' : '')}
                                onClick={() => handleButtonClick('Offers')}
                            >
                                Offer Bookings
                            </Button>
                        </Grid>
                        {setUserLoggedIn &&
                            activeButton == 'Bookings' ?
                            userBookingsList.length > 0 ?
                                (<MytripCards
                                    userBookingsList={userBookingsList}
                                />)
                                : userBookingListApiLoading == true ?
                                    (<HotelresultSkeleton />) :
                                    (<MakeTripCard
                                        setUserLoggedIn={setUserLoggedIn}
                                    />)
                            : bookedOffersListArray.length > 0 ?
                                (<MyBookedOffersCards
                                    userBookedOffersList={bookedOffersListArray}
                                />)
                                : bookedOffersListLoading == true ?
                                    (<HotelresultSkeleton />) :
                                    (<MakeTripCard
                                        setUserLoggedIn={setUserLoggedIn}
                                    />)
                        }
                    </Grid>
                </Grid >


            </Container >
        </>
    )
}

export default MyTrip