import React, { useEffect, useState } from 'react';
import { IconButton, Button, Popover, Typography, List, ListItemButton, ListItemIcon, ListItemText, Box } from "@mui/material";
import logingUser from '../../assets/icons/userLoginIcon.svg';
import userOutline from '../../assets/icons/userOutline.svg';
import ticketIcon from '../../assets/icons/ticketIcon.svg';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useTranslation } from 'react-i18next';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useAppSelector } from '../../appStore/hooks';
import { setAuthenticationType, setCountryNumCodeForApi, setRegisterUserCurrencyId, setEmailAuthenticationUserData, setEnterPassword, setExistingUserEmailStatusReset, setGetUserDetailsReset, setSocialMediaAuthenticationUserData, setHotelSelectedVrImgAndData, setInputValidatorRegisterUser, setIsLogedInUser, setIsRegisterPopUp, setIsVerificationPopUp, setLoggedInUserData, setLoginUserEmailAddress, setMainPopupForRegisterUser, setRegisterUserEmailAddress, setRegisterUserPassword, setRegisteredUserDetailsReset, setSignInCallApi, setUserLoginPassword, setUserWishlistDataReset, fetchFaviourateFolderList, fetchUserTripsApi, setUserMytripDetailsDataReset, setUserMytripsDataReset, setFirstNameUser, setLastNameUser, setUserPhoneNumber, setGetHotelsToExploreOffersReset, setResonsToBookingCancle, setCancellationPoliciesReset, setUserNotificationsAndMessgReset, setSearchDestinationFromDate, setSearchDestinationToDate, setUserFaviouratesListReset, setRemoveFromFaviouratesReset, setAddTofavouriteListReset, setAllowMoeNotification, setUserActionLoader, setShowFaviourateList, setFolderSelectedDetails, fetchDataAsyncStaticApi, setMediaNewsArticlesDataReset, setBookedOfferListDataReset, setBookedOfferDetailsReset, getBookedOffersList } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { signOut } from "firebase/auth";
import { auth } from '../../utils/config/firebaseAuth';
import { useNavigate } from 'react-router';
import { accountOptionSectionType, asyncThunkApiEndPoints, countryResidenceAndCulture, eventNameForMoeEvents } from '../../utils/constants/constant';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import InboxUpdateCards from '../InboxCards/InboxUpdateCards';
import InboxMassgaeCards from '../InboxCards/InboxMassgaeCards';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { useGetUserNotificatiobsMutation } from '../../services/api/hotelApi';
import { getNotificationsPayload } from '../../utils/constants/types';
import { calculateDateFourtyFiveDaysLater } from '../../utils/utilities/DateConverter';
import MoengageRegisterUserHandler from '../../utils/utilities/MoengageRegisterUser';
import { EventLogHanlderMoengage } from '../../utils/handlers/EventLogsHandler';
import moengageWebPush from '../../services/moengage/moengageWebPush';
import InboxNewsRoomsCard from '../../components/InboxCards/InboxNewsRoomsCard';



export const UserLoginPopover = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState(false);
    const { getUserDetails, setUserLoggedIn, loggedInUserCurrency, userNotificationsAndMessg, allowMoeNotifications } = useAppSelector(state => state.hotel);
    const firstNameUser = getUserDetails.data?.userDetail?.firstName;
    const lastNameUser = getUserDetails.data?.userDetail?.LastName;
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const pathname = window.location.pathname;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const accountSection = accountOptionSectionType.accountDetails;
    const myFavouriteSection = accountOptionSectionType.myFavourite;
    const myTripSection = accountOptionSectionType.myTrip;
    const accountSectionType = accountOptionSectionType;
    const [inbox, setInbox] = useState(false);
    const [inboxTabs, setInboxTabs] = useState(0);
    const userEmail = getUserDetails.data?.userDetail?.email ? getUserDetails.data?.userDetail.email : '';
    const userMobile = getUserDetails.data?.userDetail?.phone ? getUserDetails.data?.userDetail.phone : '';
    const userBirthdate = getUserDetails.data?.userDetail?.birthDate ? getUserDetails.data?.userDetail.birthDate : '';
    const allowNotifications = allowMoeNotifications;
    const culture = countryResidenceAndCulture.culture;
    const userNotifications = userNotificationsAndMessg;
    const userMessages = userNotificationsAndMessg;
    const todaysDate = new Date()
    const tommorowsDate = new Date((new Date()).setDate((new Date()).getDate() + 1))
    const destinationSearchStartDate = calculateDateFourtyFiveDaysLater(todaysDate);
    const destinationSearchEndDate = calculateDateFourtyFiveDaysLater(tommorowsDate);
    const eventNameLogOutMoe = eventNameForMoeEvents.Logout;
    const endPointGetMediaNewsArticles = asyncThunkApiEndPoints.getMediaNewsArticles;

    AppLogHandler('userEmail', { userNotificationsAndMessg, allowMoeNotifications })

    // get user notigification and messages api initialization
    const [getUserNotification] = useGetUserNotificatiobsMutation();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setInbox(false);
    };

    // api payload for get notifications
    const handlePermissionRequest = async () => {
        setInbox(!inbox);
        const payload: getNotificationsPayload = {
            userDetails: {
                userId: `${loggedInUserId}`,
                date: "",
                culture: culture
            }
        }
        getUserNotification({ payload })
        dispatch(fetchDataAsyncStaticApi(endPointGetMediaNewsArticles))
    }

    const open = Boolean(anchorEl);
    const id = setUserLoggedIn ? 'LoginAccount-popover' : undefined;

    // navigation handler
    const navigationHandler = (sectionType: string) => {
        switch (sectionType) {
            case accountSection:
                navigate('/account-details');
                break;
            case myTripSection:
                dispatch(setBookedOfferListDataReset({ data: [], loading: false, error: null }))
                dispatch(fetchUserTripsApi(loggedInUserId))
                dispatch(getBookedOffersList(loggedInUserId))        
                navigate('/my-trips')
                break;
            case myFavouriteSection:
                dispatch(setShowFaviourateList(false))
                //need to remove this after 2-3 days
                dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
                dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
                dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
                dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
                dispatch(fetchFaviourateFolderList(loggedInUserId))
                navigate('/favourites')
                dispatch(setSearchDestinationFromDate(destinationSearchStartDate?.toISOString()))
                dispatch(setSearchDestinationToDate(destinationSearchEndDate?.toISOString()))
                break;
            case accountSectionType.notificationPermission:
                navigate('/account-details')
                break;
            default: return 'No Section Matches'
        }
    }

    // user logout handler function    
    const userLogoutHandler = () => {

        // event log when logout
        const moeAttributes = {
            "Logout": "Logout"
        }
        EventLogHanlderMoengage(eventNameLogOutMoe, moeAttributes)

        // Sign out call for gmail user looged in
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });

        // navigate to home screen when logout from profile
        if (pathname == '/account-details') {
            navigate('/')
        } else if (pathname == '/latest-offers') {
            dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
            navigate('/')
        }
        else if (pathname == '/my-trips') {
            navigate('/')
        }
        (window as any).Moengage.destroy_session();

        sessionStorage.clear()
        dispatch(setEnterPassword(false))
        dispatch(setIsLogedInUser(false))
        dispatch(setEmailAuthenticationUserData(['']))
        dispatch(setSocialMediaAuthenticationUserData(['']))
        dispatch(setLoginUserEmailAddress(''))
        dispatch(setUserLoginPassword(''))
        dispatch(setAuthenticationType(''))
        dispatch(setRegisteredUserDetailsReset({ status: '', message: '', userDetail: [''] }))
        dispatch(setGetUserDetailsReset([]))
        dispatch(setRegisterUserEmailAddress(''))
        dispatch(setFirstNameUser(''))
        dispatch(setLastNameUser(''))
        dispatch(setUserPhoneNumber(''))
        dispatch(setRegisterUserPassword(''))
        dispatch(setIsLogedInUser(false))
        dispatch(setExistingUserEmailStatusReset({ data: [''], loading: false, error: null, }))
        dispatch(setMainPopupForRegisterUser(false))
        dispatch(setIsVerificationPopUp(false))
        dispatch(setSignInCallApi(false))
        setUserProfile(false)
        dispatch(setInputValidatorRegisterUser(false))
        dispatch(setRegisterUserCurrencyId({ currencyId: 0, currencyCode: '' }))
        dispatch(setCountryNumCodeForApi(101))
        dispatch(setLoggedInUserData({}))
        dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
        dispatch(setUserMytripsDataReset({ data: [], loading: false, error: null }))
        dispatch(setUserMytripDetailsDataReset({ data: [], loading: false, error: null }))
        dispatch(setResonsToBookingCancle({ status: '', response: [] }))
        dispatch(setCancellationPoliciesReset({ data: [], loading: false, error: null }))
        dispatch(setUserNotificationsAndMessgReset([]))
        dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
        dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
        dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
        dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
        dispatch(setAllowMoeNotification(null))
        dispatch(setShowFaviourateList(false))
        dispatch(setFolderSelectedDetails({ folderName: '', folderId: 0 }))
        dispatch(setMediaNewsArticlesDataReset({}))
        dispatch(setBookedOfferListDataReset({ data: [], loading: false, error: null }))
        dispatch(setBookedOfferDetailsReset({ data: [], loading: false, error: null }))
    }

    useEffect(() => {
        const userBirthday = new Date(userBirthdate);
        // const userBirthday = userBirthdateFormated ? userBirthdateFormated?.toISOString() : '';

        if (userEmail) {
            moengageWebPush(userEmail)
        }
        if (setUserLoggedIn == true && firstNameUser != undefined && lastNameUser != undefined) {
            setUserProfile(true)
            dispatch(setUserActionLoader({ action: '', loading: false }))
            // moengage add email and enable web push
            MoengageRegisterUserHandler({ userEmail, firstNameUser, lastNameUser, userBirthday, userMobile, allowNotifications })
            dispatch(setAllowMoeNotification(null))
        }
        else if (getUserDetails.data.length == 0 && firstNameUser == undefined && lastNameUser == undefined) {
            setUserProfile(false)
            dispatch(setUserActionLoader({ action: '', loading: false }))
            dispatch(setIsLogedInUser(false))
        }
    }, [setUserLoggedIn, firstNameUser, getUserDetails.data, lastNameUser])

    return (
        <div>
            {userProfile == true ? (<IconButton aria-describedby={id} onClick={handleClick} value={`${firstNameUser} ${lastNameUser}`} className='iGor_userLoginProBtn'>
                <img src={logingUser} alt="logingUser" className='iGor_userLoginProImg' />
                <Typography sx={{ margin: '0 1rem', fontWeight: '600' }} variant='caption'>{`${firstNameUser} ${lastNameUser}`}</Typography>
            </IconButton>) : ('')}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{ borderRadius: '15px' }}
                className='iGor_UserLoginPop'
            >
                <>
                    {!inbox ?
                        <>
                            <Box sx={{ padding: '0 0.625rem 0.625rem' }}>
                                <List >
                                    <ListItemButton sx={{ paddingLeft: '0' }} onClick={handlePermissionRequest}>
                                        <ListItemIcon className='iGor_UserIcon'>
                                            <EmailOutlinedIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('Inbox')} className='iGor_UserTitle' />
                                    </ListItemButton>
                                    <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(accountSection)}>
                                        <ListItemIcon className='iGor_UserIcon'>
                                            <img src={userOutline} className='iGor_userOutline' />
                                        </ListItemIcon>
                                        <ListItemText primary={t('Account Details')} className='iGor_UserTitle' />
                                    </ListItemButton>
                                    <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(myTripSection)}>
                                        <ListItemIcon className='iGor_UserIcon'>
                                            <img src={ticketIcon} className='ticketIcon' />
                                        </ListItemIcon>
                                        <ListItemText primary={t('My Trip')} className='iGor_UserTitle' />
                                    </ListItemButton>
                                    <ListItemButton sx={{ paddingLeft: '0' }} onClick={() => navigationHandler(myFavouriteSection)}>
                                        <ListItemIcon className='iGor_UserIcon'>
                                            <FavoriteBorderIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={t('My Favourite')} className='iGor_UserTitle' />
                                    </ListItemButton>
                                </List>

                                <Button variant="contained" sx={{ width: '185px' }} onClick={userLogoutHandler}>Logout</Button>
                            </Box>

                        </>
                        :
                        <Box className='iGor__inbox'>
                            <Box onClick={() => setInbox(!inbox)}><Typography variant='h4' className='iGor__inboxTitle'><KeyboardArrowLeft /> Inbox</Typography></Box>
                            <Box className='iGor__InboxTabs'>
                                <Typography className={inboxTabs == 0 ? 'iGor__inboxBtn iGor__inboxActive' : 'iGor__inboxBtn'} onClick={() => setInboxTabs(0)}>
                                    Update
                                </Typography>
                                <Typography className={inboxTabs == 1 ? 'iGor__inboxBtn iGor__inboxActive' : 'iGor__inboxBtn'} onClick={() => setInboxTabs(1)}>
                                    Messages
                                </Typography>
                                <Typography className={inboxTabs == 2 ? 'iGor__inboxBtn iGor__inboxActive' : 'iGor__inboxBtn'} onClick={() => setInboxTabs(2)}>
                                    Newsroom
                                </Typography>
                            </Box>
                            {inboxTabs == 1 ?
                                <Box className='iGor__tabBox' sx={{ height: '75%', overflowY: 'auto' }}>
                                    <InboxMassgaeCards userMessages={userMessages} />
                                </Box>
                                : inboxTabs == 0 ?
                                    <Box sx={{ height: '75%', overflowY: 'auto' }}>
                                        <InboxUpdateCards userNotifications={userNotifications} />
                                    </Box> :
                                    <Box sx={{ height: '75%', overflowY: 'auto' }}>
                                        <InboxNewsRoomsCard />
                                    </Box>
                            }
                        </Box>
                    }
                </>
            </Popover>
        </div>
    );
}