import { AppLogHandler } from "../handlers/appLogsHandler"

function VTUrlParser(vtUrl: any) {
    let vtUrlparsed: any = new URL(vtUrl)
    AppLogHandler('vtUrl', vtUrlparsed)
    if (vtUrlparsed.hostname == 'vt.igoroom.com') {
        vtUrlparsed.href = vtUrlparsed.href + `/?whs&noUi`
    }
    else if (vtUrlparsed.hostname == 'igoroom.com' && vtUrlparsed.href.includes('?ss')) {
        vtUrlparsed.href = vtUrlparsed.href + '&whs'
    }
    else if (vtUrlparsed.hostname == 'igoroom.com') {
        vtUrlparsed.href = vtUrlparsed.href + '?whs'
    }
    return vtUrlparsed.href !== '' ? vtUrlparsed.href : ''
}
export default VTUrlParser