import { Switch, alpha, styled } from "@mui/material";

export const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: 'white',
        '&:hover': {
            backgroundColor: alpha('#EE5253', theme.palette.action.hoverOpacity),
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: 'red',
    },
}));