import React, { useEffect, useState } from 'react'
import { Box, Typography } from "@mui/material";
import { PropertyTypePopOver } from "../../components/popUpSection/PropertyTypePopOver";
import { CustomTabs } from "../../components/inputFieldCustom/CustomTabs";
import PriceRangeFilters from "./PriceRangeFilters";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../appStore/hooks";
import { AppLogHandler } from '../handlers/appLogsHandler';
import { fetchHotesResultsDataFromApi, fetchOfferHotelsResult, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels, setHotelsResultsDataReset, setSearchHotelsResultCall, setWebFiltersEnable } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import applyFiltersPayload from '../../services/applyFiltersApiPayload';
import { useApplyFiltersHotelsResultMutation } from '../../services/api/hotelApi';

function Filters() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [numberOfHits,setnumberOfHits] =useState(2);
    const { getUIFiltersData, loggedInUserCurrency, initApi ,hotelResults} = useAppSelector(state => state.hotel);
    const filtersForUI = getUIFiltersData?.data;
    const searchId = initApi.searchId;
    let defaultPropertyType = filtersForUI?.propertyTypes;
    let defaultRoomTypes = filtersForUI?.roomTypes;
    let defaultPriceRanges = filtersForUI?.priceRange;
    const defaultMinPrice = defaultPriceRanges.min_price;
    const defaultMaxPrice = defaultPriceRanges.max_price;
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 270;
    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const currencySymbol = hotelResults.data?.currencySymbol ? hotelResults.data?.currencySymbol :loggendInUserCurrency;
    AppLogHandler("getUIFiltersData", defaultPriceRanges)

    // apply filters api call assign to variable for dynamic use
    const [applyFilters] = useApplyFiltersHotelsResultMutation();

    // reset properties when property or room index is 0
    const resetPropertiesToHotelsResult = () => {
        dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
        dispatch(fetchHotesResultsDataFromApi({ searchId, numberOfHits }));
        dispatch(fetchOfferHotelsResult(searchId))
        dispatch(setSearchHotelsResultCall(true))
    }

    useEffect(() => {
        // re-assigning data if change in filters for ui
        defaultPropertyType = filtersForUI?.propertyTypes;
        defaultRoomTypes = filtersForUI?.roomTypes;
        defaultPriceRanges = filtersForUI?.priceRange;
    }, [getUIFiltersData])

    const { setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters } = useAppSelector(state => state.hotel)
    const propertyTypeSelectedArray: [any] = setPropertyTypeSelectedFilters ? setPropertyTypeSelectedFilters : [];
    const roomTypeSelectedArray: [any] = setRoomTypeSelectedFilters ? setRoomTypeSelectedFilters : [];
    // const [propertyTypeSelectedArrayState, setPropetyTypeSelectedArray]: any = useState(setPropertyTypeSelectedFilters);
    // const [roomTypeSelectedArraysState, setRoomTypeSeletedArray]: any = useState(setRoomTypeSelectedFilters);

    // property type selection logic
    const propertyTypeSelectionHandler = (value: number, propertyTypeId: any) => {
        const currentPropetyIndex = propertyTypeSelectedArray.indexOf(propertyTypeId)
        const propertyArrayDispatch: any = [...propertyTypeSelectedArray]
        if (currentPropetyIndex === -1) {
            propertyArrayDispatch.push(propertyTypeId);
        } else {
            propertyArrayDispatch.splice(currentPropetyIndex, 1);
        }

        // apply filters for property types
        if (propertyArrayDispatch.length > 0) {
            const payload = applyFiltersPayload(loggedInUserId, propertyArrayDispatch, roomTypeSelectedArray, defaultMinPrice, defaultMaxPrice, searchId)
            if (payload) {
                applyFilters({ payload })
            }
        }
        else if (propertyArrayDispatch.length == 0 && searchId) {
            resetPropertiesToHotelsResult()
        }

        dispatch(setAppliedPropertyTypeFiltersForHotels(propertyArrayDispatch))
        dispatch(setWebFiltersEnable(true))

        AppLogHandler("propertyArrayDispatch", propertyArrayDispatch)
    };

    // room type selection logic
    const roomTypeSelectionHandler = (value: any, roomTypeId: number) => {
        const currentRoomIndex = roomTypeSelectedArray?.indexOf(roomTypeId)
        const roomArrayDispatch: any = [...roomTypeSelectedArray]

        if (currentRoomIndex === -1) {
            roomArrayDispatch.push(roomTypeId);
        } else {
            roomArrayDispatch.splice(currentRoomIndex, 1);
        }

        // apply filters for room types
        if (roomArrayDispatch.length > 0) {
            const payload = applyFiltersPayload(loggedInUserId, propertyTypeSelectedArray, roomArrayDispatch, defaultMinPrice, defaultMaxPrice, searchId)
            if (payload) {
                applyFilters({ payload })
            }
        }
        else if (roomArrayDispatch.length == 0 && searchId) {
            resetPropertiesToHotelsResult()
        }

        dispatch(setAppliedRoomTypeFiltersForHotels(roomArrayDispatch))
        dispatch(setWebFiltersEnable(true))
        AppLogHandler("propertyArrayDispatchssssss", { propertyTypeSelectedArray, roomTypeSelectedArray, roomArrayDispatch })
    }

    AppLogHandler("setPropertyTypeSelectedFilters", { setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters })


    return (<>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }} >
            <PriceRangeFilters currencySymbolPrice={currencySymbol}/>
            <Box sx={{ marginLeft: '30px' }}>
                <Typography variant="subtitle2" className='subtitle2'>{t('Type')}</Typography>
                <CustomTabs />
            </Box>
            <Box sx={{ width: '370px',display:'flex' }}>
                <Box sx={{ marginRight: '30px' }}>
                    <Typography variant="subtitle2" className='subtitle2'>{t('Property type')}</Typography>
                    <PropertyTypePopOver setlist='Propertytype' propertyTypeSelectionHandler={propertyTypeSelectionHandler} />
                </Box>
                <Box>
                    <Typography variant="subtitle2" className='subtitle2' >{t('Room Type')}</Typography>
                    <PropertyTypePopOver setlist='Roomtype' roomTypeSelectionHandler={roomTypeSelectionHandler} />
                </Box>
            </Box>
            {/* <Box>
                <Typography variant="subtitle2" className='subtitle2'>{t('View By')}</Typography>
                <PropertyTypePopOver setlist='Viewtype' />
            </Box> */}
        </Box>
    </>);
}

export default Filters;