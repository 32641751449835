export const environmentVariables = {
    appApiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    imagePathApp: process.env.REACT_APP_IMAGE_PATH,
    accountIdApp: process.env.REACT_APP_ACCOUNT_ID,
    channelIdApp: process.env.REACT_APP_CHANNEL_ID,
    publishableKeyStripe: process.env.REACT_APP_PUBLISHABLE_KEY,
    appSecreatKeyStripe: process.env.REACT_APP_SECREAT_KEY,
    baseUrlPaypalApis: process.env.REACT_APP_BASE_URL_PAYPAL,
    clientIdPaypal: process.env.REACT_APP_CLIENT_ID_PAYPAL,
    clientSecreatPaypal: process.env.REACT_APP_CLIENT_SECREAT_PAYPAL,
    googleMapKey: process.env.REACT_APP_MAP_KEY,
    appServerKey: process.env.REACT_APP_APPLICATION_SERVER_KEY,
    enviornment:process.env.REACT_APP_ENV
}

export const appConfigFirebase = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}