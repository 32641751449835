import React, { useState, useEffect } from 'react'
import { Popover, TextField } from "@mui/material";
import { Calendar } from "react-date-range";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../appStore/hooks';
import { setShowDate, setUserDOBForUpdate } from '../../appStore/hotelSlice';
import Icon_calendarBlack from '../../assets/icons/Icon_calendarBlack.svg';

function DateOfBirthUpdateHandler() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const showDates = useAppSelector((state) => state.hotel.showDate);
    const loggedInUserDetails = useAppSelector(state=>state.hotel.getUserDetails.data);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const loggedInUserBirthDate = loggedInUserDetails?.userDetail?.birthDate ? loggedInUserDetails?.userDetail?.birthDate : Date()
    const defaultDate = new Date(loggedInUserBirthDate);
    
    // Calculate the default date (17 years ago from today)
    const maxDate = new Date()
    maxDate.setFullYear(maxDate.getFullYear() - 17);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChangeHandler = (newDate: any) => {
        setDate(newDate);
        dispatch(setShowDate(true));
    };

    const open = Boolean(anchorEl);
    const id = open ? 'DatePiker-popover' : undefined;
    let [date, setDate]: any = useState(defaultDate);

    useEffect(() => {
        setAnchorEl(null);
        const dateFormated = new Date(date)
        dispatch(setUserDOBForUpdate(dateFormated.toISOString()));
    }, [date]);

    const showDate = date.toLocaleDateString('en-us', {
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
    return (
        <>
            {/* <DateRangeIcon className='inputIcons' /> */}
            <img src={Icon_calendarBlack} alt='Icon_calendar' className='inputIcons' />
            {showDates == false ? (
                <TextField
                    aria-describedby={id}
                    className='iGor_inputFieldval'
                    placeholder={('Date of birth')}
                    onClick={handleClick}
                    inputProps={{ readOnly: true }}
                ></TextField>
            ) : (
                <TextField
                    aria-describedby={id}
                    className='iGor_inputFieldval'
                    value={showDate}
                    onClick={handleClick}
                    inputProps={{ readOnly: true }}
                ></TextField>
            )}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Calendar
                    date={date}
                    maxDate={maxDate}
                    onChange={(setDate: any) => onChangeHandler(setDate)}
                    className='iGor_calendar'
                />
            </Popover>
        </>
    );
}

export default DateOfBirthUpdateHandler;