import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, Modal, Box, IconButton, Button, Popover, Typography, Container, Grid } from "@mui/material";
import { Close } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import useMediaQuery from '@mui/material/useMediaQuery';
import ExploreCard from "./ExploreCard";
import Slider from "react-slick";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


// function  NextArrow(props:any) {
//     const { className, style, onClick } = props;
//     return (
//       <ArrowForwardIcon
//         className={className}
//         style={{ ...style, display: "block", color: "#ffffff" ,border: '1.5px solid',
//         borderRadius: '50px'}}
//         onClick={onClick}
//       />
//     );
//   }
  
//   function  PrevArrow(props:any) {
//     const { className, style, onClick } = props;
//     return (
//       <ArrowBackIcon
//         className={className}
//         style={{ ...style, display: "block",color: "#ffffff",border: '1.5px solid',
//         borderRadius: '50px'}}
//         onClick={onClick}
//       />
//     );
//   }

export default function   ExploreDestinationModel() {
    
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const matches = useMediaQuery('(max-width:1024px)');

    // const settings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 5,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //             breakpoint: 1285,
    //             settings: {
    //               slidesToShow:4,
    //             }
    //           },
    //         {
    //           breakpoint: 1024,
    //           settings: {
    //             slidesToShow:4,
    //           }
    //         },],
    //     nextArrow: <NextArrow />,
    //     prevArrow: <PrevArrow />

    // };
    return (
        <>
            <Box marginLeft={1} onClick={handleOpen} width={'100%'}>
                <Typography variant='body1' fontWeight={600} marginBottom={'-5px'}>Destination</Typography>
                <Typography variant='caption' color={"#C4C4C4"} >Where do you want to go?</Typography>
            </Box>
            <Backdrop
                sx={{ color: '#fff', backdropFilter: "blur(5px)", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                // onClick={handleClose}
            >
                <Container className='iGo_Explore-Model'>
                    <Grid container >
                        <Grid item sm={12} >
                            <Box className='iGor_Modal iGor_setRelative'>
                                <Box className='iGo__Exp-closeSection'>
                                    <Box className="iGo_Explore-close">
                                        <IconButton onClick={handleClose} className="close-button">
                                            {matches  ? <><CancelOutlinedIcon/></>:<> <Close/> Close </>}
                                        </IconButton>
                                    </Box>

                                    <Typography className="IGor_ExploerSub">Discover More</Typography>
                                    <Typography className="IGor_ExploerTitle"> Travel Around the World </Typography>
                                </Box>
                                    <ExploreCard key='Explore Card'/>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Backdrop>
        </>
    );
}