import { AppLogHandler } from "../handlers/appLogsHandler";

function MoengageRegisterUserHandler({ userEmail, firstNameUser, lastNameUser, userBirthday, userMobile, allowNotifications }: any) {
    const userMailToRegister = userEmail;
    const firstName = firstNameUser;
    const lastName = lastNameUser;
    const birthdayUser = userBirthday;
    const mobileNoUser = userMobile;
    AppLogHandler('MoengageAttrubutes ', {
        userEmail,
        firstName,
        lastName,
        birthdayUser,
        mobileNoUser,
        allowNotifications
    })

    try {
        (window as any).Moengage.add_unique_user_id(userMailToRegister).then((e: any) => {
            AppLogHandler('success', e)
            return e
        }).catch((error: any) => {
            AppLogHandler('error', error)
        });
        (window as any).Moengage.add_first_name(firstName).then((e: any) => {
            AppLogHandler('success', e)
            return e
        }).catch((error: any) => {
            AppLogHandler('error', error)
        });
        (window as any).Moengage.add_last_name(lastName).then((e: any) => {
            AppLogHandler('success', e)
            return e
        }).catch((error: any) => {
            AppLogHandler('error', error)
        });
        (window as any).Moengage.add_email(userMailToRegister).then((e: any) => {
            AppLogHandler('success', e)
            return e
        }).catch((error: any) => {
            AppLogHandler('error', error)
        });
        // (window as any).Moengage.add_mobile(mobileNoUser).then((e: any) => {
        //     AppLogHandler('success', e)
        //     return e
        // }).catch((error: any) => {
        //     AppLogHandler('error', error)
        // });
        (window as any).Moengage.add_birthday(birthdayUser).then((e: any) => {
            AppLogHandler('success', e)
            return e
        }).catch((error: any) => {
            AppLogHandler('error', error)
        });
        if (allowNotifications == true) {
            (window as any).Moengage.add_user_attribute("notification_block", allowNotifications).then((e: any) => {
                AppLogHandler('success', e)
                return e
            }).catch((error: any) => {
                AppLogHandler('error', error)
            });
        }
        else if (allowNotifications == false) {
            (window as any).Moengage.add_user_attribute("notification_block", allowNotifications).then((e: any) => {
                AppLogHandler('success', e)
                return e
            }).catch((error: any) => {
                AppLogHandler('error', error)
            });
        }

        (window as any).Moengage.call_web_push();
    }
    catch (error: any) {
        AppLogHandler('moeerror', error)
    }

    if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
            AppLogHandler('Notification permission:', permission);
        });
    }

    if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.pushManager.getSubscription()
                .then((subscription) => {
                    if (subscription) {
                        // User is already subscribed
                        AppLogHandler('User is already subscribed:', subscription);
                    } else {
                        // User is not subscribed, subscribe them
                        registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            applicationServerKey: 'BG9yZ2CWpnylqiUtFonGeWKNeoBnxAO6uMBCYvLw0GSGUmT-H_Z6x-fa7DeqUrHBQNmWTt27PCF3Zu8pXrd20bs',
                        })
                            .then((newSubscription) => {
                                AppLogHandler('Push notification subscription:', newSubscription);
                            })
                            .catch((error) => {
                                AppLogHandler('Error subscribing to push notifications:', error);
                            });
                    }
                })
                .catch((error) => {
                    AppLogHandler('Error checking push subscription:', error);
                });
        });
    }

    (window as any).Moengage?.pushPermission?.request()?.then((permission: any) => {
        if (permission === 'granted') {
            (window as any).Moengage.addEventListener('notificationclick', (event: any) => {
                AppLogHandler('Notification Clicked:', event);
            });
        }
    });
    return () => {
        (window as any).Moengage.removeEventListener('notificationclick');
    };
}
export default MoengageRegisterUserHandler