import adventureImage from '../../assets/images/adventure.jpg';
import beachImage from '../../assets/images//beach.jpg';
import cityImage from '../../assets/images/city.jpg';
import spaImage from '../../assets/images/spa.jpg';
import activitiesImage from '../../assets/images/activities.jpg';
import familyImage from '../../assets/images/family.jpg';
import natureImage from '../../assets/images/nature.jpg';
import authenticImage from '../../assets/images/authentic.jpg';
import modernImage from '../../assets/images/mordern.jpg';
import toursImage from '../../assets/images/tours.jpg';
import romanticImage from '../../assets/images/romantic.jpg';
import culinaryImage from '../../assets/images/culinary.jpg';
import riceFields from '../../assets/images/ricefields.jpg';
import waterVillas from '../../assets/images/watervillas.jpg'
const returnUrl = window.location.origin + '/confirm-payments'
const cancleUrl = window.location.origin + '/order-summary'

export const popularCurrencies: any = [
    { label: 'United states dollar', currencyCode: 'USD', symbol: "$", currencyNumCode: 21 },
    // { label: 'Singaporean Doller', currencyCode: 'SGD', symbol: 'S$', currencyNumCode: 21 },
    { label: 'Euro', currencyCode: 'EUR', symbol: '€', currencyNumCode: 21 },
    { label: 'British pound', currencyCode: 'GBP', symbol: '£', currencyNumCode: 21 },
    // { label: 'Indonesian Rupiah', currencyCode: 'IDR', symbol: 'Rp', currencyNumCode: 21 },
    { label: 'Malaysian ringgit', currencyCode: 'MYR', symbol: 'RM', currencyNumCode: 21 },
]

export const userGateWays = [
    { type: 'Adventure', img: adventureImage },
    { type: 'Beach', img: beachImage },
    { type: 'City', img: cityImage },
    { type: 'Nature', img: natureImage },
    { type: 'Authentic', img: authenticImage },
    { type: 'Modern', img: modernImage },
    { type: 'Ricefields', img: riceFields },
    { type: 'Watervillas ', img: waterVillas }
]

export const userHolidaymakers = [
    { type: 'Activities', img: activitiesImage },
    { type: 'Family', img: familyImage },
    { type: 'Tours', img: toursImage },
    { type: 'Spa', img: spaImage },
    { type: 'Romantic', img: romanticImage },
    { type: 'Culinary', img: culinaryImage }
]
export const authenticationTypeForApp = { emailAuth: 'Email', gmailAuth: 'Gmail', appleAuth: 'Apple', facebook: 'Facebook' }
export const hotelTypes = { hotelsAndResort: 'HotelsAndResorts', VipHotels: 'VipHotels', OfferHotels: 'OfferHotels' }
export const countryResidenceAndCulture = { countryOfResidence: "IN", nationality: "IN", culture: "en-US" }
export const Destination: any = ["Maldives", "Indonesia", "Seychelles", "United Emirates Arab", "Malaysia"]
export const viewByOptionsRoomList = ['Bedroom', 'Living Room', 'Bathroom']
export const emailValidations = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
export const addHotelFavioratesType = {
    property: 'property',
    article: 'article'
};

// hotel api end points for without params 
export const asyncThunkApiEndPoints: any = {
    getPopularHotels: 'api/hotels/getPopularHotel',
    getDestinationsForHotels: 'api/UserAccount/getDestinationData',
    getCountryCallingCodes: 'api/UserAccount/getCountryData',
    getCurrencies: 'api/UserAccount/getCurrencyData',
    getResonsForBookingCancle: 'api/Booking/booking/GetCancellationReason',
    getMediaNewsArticles: 'api/Media/newsarticle'
};

// hotel api end points with params
export const asyncThunkApiEndPointsWithParams: any = {
    exploreDestinations: 'api/Hotels/getExplore',
    exploreHotelsToStay: 'api/hotels/getPopularHotelByLocation',
    getUserWishlit: 'api/Hotels/getWishlistHotel',
    getUserTrips: 'api/Booking/bookinglist',
    getUserTripDetails: 'api/Booking/bookingdetails',
    getHotelsToExploreOffers: 'api/Hotels/getHotelForExplore',
    getCancellationPolicies: 'api/booking',
    getPaypalCurrencies: 'api/UserAccount/getCurrencyData',
    exploreThingsTodo: 'api/hotels/getThingsToDoForExplore',
    exploreRegions: 'api/hotels/getRegionsForExplore',
    getHotelDetailsUsingSlug: 'api/hotels',
    getFaviouratesFloderList: 'api/hotels/getfavouritefolderlist',
    getFaviourateFolderDetails: 'api/hotels/getfolderdetails',
    removeFromFaviourates: 'api/hotels/removefromfavourite',
    donwloadBookingPDF:'api/Booking/downloadbookingconfirmationpdf',
    offerHotelsResults:'api/hotels/offers',
    getBookedOfferList: 'api/Booking/getofferlist',
    getBookedOfferDetails: 'api/Booking/getofferdetails'
};

export const accountOptionSectionType = {
    latestOffers: 'Latest Offers',
    exploreDestinations: 'Explore',
    accountDetails: 'Account Details',
    myTrip: 'My Trip',
    myFavourite: 'My Favourite',
    notificationPermission: 'Notification and Permission',
    termsAndConditions: 'Terms and Condition',
    inbox: 'inbox',
}

export const wishlistApiMessages = {
    addedToWishlist: '',
    RemovedFromWishlist: 'Property removed successfully',
    CredentialNotValid: 'Credentials are not valid'
}

export const paymentGateWaysType = {
    stripe: 'Stripe',
    paypal: 'PayPal'
}

export const payPalHeaders = {
    'Accept': '*/*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Authorization': ''
}

export const itemsOBJPayPalOrder = [
    {
        "name": "hotel rooms",
        "description": "delux room",
        "quantity": "1",
        "unit_amount": {
            "currency_code": "USD",
            "value": "100.00"
        }
    }
]

export const amountOBJPayPalOrder = {
    "currency_code": "USD",
    "value": "100.00",
    "breakdown": {
        "item_total": {
            "currency_code": "USD",
            "value": "100.00"
        }
    }
}

export const applicationContextOBJ = {
    return_url: returnUrl,
    cancel_url: cancleUrl
}

export const orderSummarySectionType = {
    orderSummary: 'OrderSummary',
    payment: 'Payment',
    finish: 'Finish'
}

export const defaultCardPaymentObj = {
    billingAddress: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        country: "",
        postalCode: ""
    }
}

// event name object for events log
export const EventNamesForEventLogs = {
    addToWishlist: 'ig_added_to_favourite',
    completeRegistration: 'ig_completed_registration',
    payNow: 'ig_payment_initiated',
    viewPropertyDetails: 'ig_property_details',
    hotelSearch: 'ig_search',
    loginOfUser: 'ig_login_completed',
    paymentSuccess: 'ig_payment_completed',
    bookingSuccess: 'ig_booking_completed',
    reviewOrder: 'ig_review_order',
    exploreDestination: 'ig_explore_destination',
    exploreRegion: 'ig_explore_region_view',
    explorePlacesToStay: 'ig_explore_place_to_stay_view',
    exploreCulture: 'ig_explore_culture_view',
    exploreUpComingEvents: 'ig_explore_upcoming_event_view',
    favouriteContentView: 'ig_favourite_content_view',
    cancleBooking: 'ig_cancel_booking'
}

export const eventNameForMoeEvents = {
    Login: "Login",
    Logout: "Logout",
    Signup: "Signup",
    ViewScreen: "View Screen",
    HotelView: "HotelView",
    Purchase: "Purchase",
    Search: "Search",
    SearchResult: "Search Result"
}

export const paymentsErrors = {
    currencyNotSupported: 'The requested action could not be performed, semantically incorrect, or failed business validation.'
}

export const bookOfferPassengerObj = {
    "id": 1,
    "title": "Mr",
    "firstName": "",
    "lastName": "",
    "isLeadPax": true,
    "type": "Adult"
}