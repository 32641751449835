import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../appStore/hooks';
import ToastSuccessMessageContainer from '../../utils/utilities/ToastSuccesMessageContainer';
import clipboardCopy from 'clipboard-copy';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    width: '500px',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FFFFFF',
    borderRadius: '10px',
};


function MyBookedOffersModal({ handleClose, open }: any) {
    const { t } = useTranslation();
    const { bookedOfferDetails } = useAppSelector(state => state.hotel);
    const userBookedOffersDetails = bookedOfferDetails.data ? bookedOfferDetails.data : {};

    // handle copy booking id
    const handleCopyToClipboard = (bookingId: string) => {
        clipboardCopy(bookingId);
        const copyMessage = `${t('1004')} ${bookingId} `
        ToastSuccessMessageContainer(copyMessage)
    }

    return (<div>
        {/* <MoreVertIcon className='iGor__ModelBtn' onClick={openbox} /> */}

        <Modal

            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 50,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style} className='iGor_Modal iGor_bookingDetailsModal'>
                    <Box className='iGor_setFlex iGor_justifyBetween iGor_bookingDetHeader' >
                        <IconButton onClick={handleClose} className="close-button">
                            <Close />
                        </IconButton>
                        <Typography variant="subtitle1" >
                            Booking Details
                        </Typography>
                        <IconButton
                        //   disabled={downloadPDFUrl ? false : true}
                        >

                            {/* <DownloadIcon
                            onClick={downloadBookingPDFHandler}
                            /> */}
                        </IconButton>
                    </Box>
                    <Box className="iGor_ContentBody">
                        <Box className="iGor_bookingDetBox">
                            <Box className='iGor_setFlex iGor_justifyBetween iGor_BookingID' >
                                <Box>
                                    <Typography color={'#c4c4c4'}>
                                        Booking ID
                                    </Typography>
                                    <Typography fontWeight={500} justifyContent={'center'}>
                                        {userBookedOffersDetails.bookingId}
                                        {/* <ContentCopyIcon
                                            onClick={(e) => handleCopyToClipboard(userBookedOffersDetails?.bookingId)}
                                            className='iGor_BookingCopyId'
                                        /> */}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Button
                                        className={userBookedOffersDetails.bookingStatus == 'Confirmed' ? "iGor_ConfirmedBtn" : userBookedOffersDetails.bookingStatus == 'Requested' ? "iGor_PendingBtn" : " iGor__CancelledBtn"}
                                        startIcon={userBookedOffersDetails.bookingStatus == 'Confirmed' ?
                                            <CheckIcon /> :
                                            userBookedOffersDetails.bookingStatus == 'Requested' ?
                                                <AccessAlarmsIcon /> : <CancelIcon />}

                                    >{userBookedOffersDetails.bookingStatus}</Button>
                                </Box>
                            </Box>
                            <Box className="iGor_BookingDetails">
                                <Typography color={'#c4c4c4'}>
                                    Booking Details
                                </Typography>
                                <Typography variant='body2'>
                                    {userBookedOffersDetails?.hotelName}
                                </Typography>
                            </Box>
                            <Box className='iGor_setFlex iGor_justifyBetween iGor_BookingRoomInfo' >
                                <Box width={'33.3%'}>
                                    <Typography color={'#c4c4c4'}>
                                        Room Type
                                    </Typography>
                                    <Typography variant='body2'>
                                        {userBookedOffersDetails?.rateName}
                                    </Typography>
                                </Box>
                                {/* <Box width={'33.3%'}>
                                    <Typography color={'#c4c4c4'}>
                                        Bed Type
                                    </Typography>
                                    <Typography variant='body2'>{userBookedOffersDetails?.bedType?.length > 0 ? userBookedOffersDetails?.bedType : 'Not Available'}</Typography>

                                </Box> */}
                                {/* <Box>
                      <Typography color={'#c4c4c4'}>
                        Guests
                      </Typography>
                      <Typography variant='body2'>{`${adultsCount.length} ${adultsCount.length > 1 ? 'Adults' : 'Adult'} ${childCount[0] !== null ? ', ' + childCount.length + ' ' + (childCount.length > 1 ? 'Childs' : 'Child') : ''}`}</Typography>
                    </Box> */}
                            </Box>
                        </Box>
                        <Box className="iGor_bookingDetBox">
                            <Box className='iGor_setFlex iGor_justifyBetween iGor_bookingDetPrice'  >
                                <Box>
                                    <Typography className='iGor_bookingDetTitle'>Price Details</Typography>
                                    <Typography variant='body2'>
                                        {`${userBookedOffersDetails.noOfDays ? userBookedOffersDetails.noOfDays > 1 ? userBookedOffersDetails.noOfDays + ' Nights, ' : userBookedOffersDetails.noOfDays + ' Night, ' : ''}`}
                                        {userBookedOffersDetails?.rateName}
                                    </Typography>
                                </Box>
                                <Box paddingTop={'8px'}>
                                    <Typography variant='body2'>{userBookedOffersDetails.currencySymbol} {userBookedOffersDetails?.price}</Typography>
                                </Box>
                            </Box>
                            {/* {bookedHotelInclusions.length > 0 ? (<><Typography fontWeight={600} className='iGor_bookingDetTitle'>Inclusions details</Typography>
                    <Box className="iGor__MytripList">
                      <ul>
                        {bookedHotelInclusions.map((inclusion: any) => {
                          return (<li>{inclusion}</li>)
                        }
                        )}
                      </ul>
                    </Box></>) : ''} */}

                            {/* <Box className='iGor_setFlex iGor_justifyBetween' mb={'0.65rem'}>
                                <Box>
                                    <Typography variant='body2'>Base Rate</Typography>
                                </Box>
                                <Box>
                                    <Typography variant='body2'>{userBookedOffersDetails.hotelbooking.currency} {baseRate ? baseRate : 0}</Typography>
                                </Box>
                            </Box>
                            <Box className='iGor_setFlex iGor_justifyBetween iGor_bookingfooter'>
                                <Box>
                                    <Typography variant='body2' >Taxes & Fees</Typography>
                                </Box>
                                <Box>
                                    <Typography variant='body2'>{userBookedOffersDetails.hotelbooking.currency} {taxesAndFees ? taxesAndFees : 0}</Typography>
                                </Box>
                            </Box> */}

                        </Box>

                        <Box className='iGor_setFlex iGor_justifyBetween iGOr_bookingDetlsTotal'>
                            <Box>
                                <Typography variant='body2'>Total</Typography>
                                {/* <Typography color={"#FF6B6B"}>This account will earn 2300 Points</Typography> */}
                            </Box>
                            <Box>
                                <Typography variant='body2'>{userBookedOffersDetails.currencySymbol} {userBookedOffersDetails?.price}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    </div>);
}

export default MyBookedOffersModal;