import { environmentVariables } from "../../utils/config/environmentVariables";
import { env } from "../../utils/config/appConfig";
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";
const applicationServerkeyMoengage = environmentVariables.appServerKey;

function moengageWebPush(email: any) {
    try {
        (window as any).Moengage.add_email(email)?.then((e: any) => {
            AppLogHandler('successLogin', e)
            return e
        }).catch((error: any) => {
            AppLogHandler('error', error)
        });

        (window as any).Moengage.call_web_push();
    }
    catch (error: any) {
        AppLogHandler('moeerror', error)
    }

    if ('Notification' in window) {
        Notification.requestPermission().then((permission) => {
            AppLogHandler('Notification permission:', permission);
        });
    }

    if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: applicationServerkeyMoengage,
            })
                .then((subscription) => {
                    AppLogHandler('Push notification subscription:', subscription);
                })
                .catch((error) => {
                    AppLogHandler('Error subscribing to push notifications:', error);
                });
        });
    }

    (window as any).Moengage?.pushPermission?.request()?.then((permission: any) => {
        if (permission === 'granted') {
            (window as any).Moengage.addEventListener('notificationclick', (event: any) => {
                AppLogHandler('Notification Clicked:', event);
            });
        }
    });
    AppLogHandler('permisson handled', '')
    return () => {
        (window as any).Moengage.removeEventListener('notificationclick');
    };
}

export default moengageWebPush; 