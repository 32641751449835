import { Box, Button, Container, Grid, IconButton, Popover, Typography, MenuList, Skeleton, SwipeableDrawer, List, ListItem, ListItemText, useMediaQuery } from "@mui/material";
import { useAppSelector } from "../../appStore/hooks";
import DestinationMenuName from "../../components/DestinationsHotelsResults/DestinationMenuName";
import DestinationNightCount from "../../components/DestinationsHotelsResults/DestinationNightCount";
import DestinationRoomAdditionPopOver from "../../components/DestinationsHotelsResults/DestinationRoomAdditionPopOver";
import { DatePickerComp } from "../../components/inputFieldCustom/DatePicker";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { formatDateOnSearchResultPageMobile } from "../../utils/utilities/DateConverter";
import { formatDateOnSearchResultPage } from "../../utils/utilities/DateConverter";
import { AdultCount } from "../../utils/handlers/arrayLoop";
import { ChildCount } from "../../utils/handlers/arrayLoop";
import GuestsIcon from '../../assets/icons/GuestsIcon.svg';
import locationIcon from '../../assets/icons/location-pin.svg';
import icons_calendar from '../../assets/icons/icons_calendar.svg';
import { searchValuesDispatch } from "../../components/DestinationsHotelsResults/SearchValuesDispatch";
import { setAppliedPriceRangeFiltersForHotels, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels, setHotelSearchRequestReset, setHotelsResultsDataReset, setMobileAppliedPropertyTypeFilterForHotels, setMobileAppliedRoomTypeFilterForHotels, setMobileFiltersEnable, setNoOfHitsResultApi, setSearchDestinationData, setWebFiltersEnable } from "../../appStore/hotelSlice";
import initApiCall from "../../services/initApiCall";
import { EventLogHanlderMoengage } from "../../utils/handlers/EventLogsHandler";
import { useInitApiMutation } from "../../services/api/hotelApi";
import { countryResidenceAndCulture, eventNameForMoeEvents } from '../../utils/constants/constant';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function SearchModifyBox({ searchClickHandler, setDestinationObj, setRoomArrayForGuest,
    setStartDateForDestinationSearch, setEndDateForDestinationSearch, startDateForDestination,
    endDateForDestination, roomArrayForGuest, destinationObj, initApiLoading }: any) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const dispatch: any = useDispatch();
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [RoomanchorEl, setRoomAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [checkInanchorEl, setCheckInAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [show, setShow] = useState(false);
    const [stepFirst, setstepFirst] = useState(false);
    const [stepSecond, setstepSecond] = useState(true);
    const [stepNumber, setStepNumber] = useState(0);
    const [tabType, setTabType] = useState('ALL');
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [destinationShow, setDestinationShow] = useState(false);
    const [serchField, setSerchField] = useState(false)
    const [researchHotelResults, setResearchHotelResults] = useState(false);
    const { webFilterEnable, mobileFilterEnable, loggedInUserCurrency, searchDestinationFromDate, searchDestinationToDate,
        destinationsData, setPropertyTypeSelectedFilters, setRoomTypeSelectedFilters, setMobilePropertyTypeSelectedFilters,
        setMobileRoomTypeSelectedFilters, setPriceRangeSelectedFilters, screenTypeSeleted, searchHotelsResult, NoOfHitsResultApi,
        noOfNights } = useAppSelector(state => state.hotel)
    const searchId = useAppSelector(state => state.hotel.initApi?.searchId);
    const RoomArr = useAppSelector(state => state.hotel.roomArray)
    const filtersForUI = useAppSelector(state => state.hotel.getUIFiltersData?.data);
    const hotelsDataLoading = useAppSelector(state => state.hotel.hotelResults?.loading)
    const destinationSelectedData = useAppSelector(state => state.hotel.destinationsData)
    const startDate = startDateForDestination;
    const endDate = endDateForDestination;
    const [numberOfNights, setNumberOfNights] = useState(noOfNights);
    const formatedFromDate = formatDateOnSearchResultPage(startDateForDestination, 'search')
    const formatedToDate = formatDateOnSearchResultPage(endDateForDestination, 'search')
    const serializedGuestArray: any = searchParams.get('guestArray')
    const decodedGuestArray: any = decodeURIComponent(serializedGuestArray);
    const adultCount = AdultCount(roomArrayForGuest);
    const childCount = ChildCount(roomArrayForGuest);
    const guestArray = JSON.parse(decodedGuestArray);


    const pathname = window.location.pathname;
    // assign the price for updated price and default prices
    let defaultPriceRanges = filtersForUI?.priceRange;

    let setPriceRangesSelected = setPriceRangeSelectedFilters;
    let updatedMinPrice: number = setPriceRangesSelected?.min_price;
    let updatedMaxPrice: number = setPriceRangesSelected?.max_price;
    const eventNameSearchMoe = eventNameForMoeEvents.Search;
    const countryResidenceAndCultureInit = countryResidenceAndCulture;


    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    // for room addition
    const open = Boolean(anchorEl);
    const checkInOpen = Boolean(checkInanchorEl)
    const id = open ? 'simple-popover' : undefined;
    const checkInID = checkInOpen ? 'simple-popover' : undefined;
    const RoomOpen = Boolean(RoomanchorEl)
    const roomId = RoomOpen ? 'simple-popover' : undefined;

    let idParamsFromHome: any = {
        destinationName: searchParams.get('destinationName'),
        destinationId: searchParams.get('destinationId'),
        checkInDate: searchParams.get('checkIn'),
        checkOutDate: searchParams.get('checkout'),
        roomArray: guestArray,
        currency: searchParams.get('currency')
    }

    const handleRoomClick = (event: any) => {
        setRoomAnchorEl(event.currentTarget)
    }
    // For CheckIn Date Drop Down 
    const checkInhandleClick = (event: any) => {
        setCheckInAnchorEl(event.currentTarget);
    }

    // For Destination Drop Down
    const handleDestinationClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleDestinationClose = () => {
        setAnchorEl(null)
    }

    const handleClose = () => {
        setCheckInAnchorEl(null)
    }


    return (<><Box className="iGoR-SrFilterTop iGor_setFlex" >
        <Box className="iGor-width-20 iGoR-Sr_line">
            <Typography variant="subtitle2" className='subtitle2'>
                <img src={locationIcon} alt="locationIcon" />
                {t('Location')}
            </Typography>
            <div onClick={(event) => { handleDestinationClick(event) }} aria-describedby={id}>
                <Typography variant="subtitle1" >
                    {destinationObj.destinationName}
                </Typography>
            </div>
            {/* Destination drop down */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleDestinationClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className="iGor_PopularDesti">
                    <Box >
                        <Typography variant='subtitle1' className='iGor_DestiTitle'  > {t("Popular Destinations")}</Typography>
                        <MenuList
                            autoFocusItem={open}
                            className='iGor_DestiList'
                        >
                            <DestinationMenuName
                                setDestinationObj={setDestinationObj}
                                setAnchorEl={setAnchorEl}
                            />
                        </MenuList>
                    </Box>
                </Box>
            </Popover>
        </Box>
        <Box className="iGor-width-20 iGoR-Sr_line iGor-result_dates" aria-describedby={checkInID} onClick={checkInhandleClick}>
            <Typography variant="subtitle2" className='subtitle2'>
                <img src={icons_calendar} alt="locationIcon" />
                {t('Check in')}
            </Typography>
            <Typography variant="subtitle1">
                {formatedFromDate}
            </Typography>
        </Box>

        <Box className="iGor-width-20 iGoR-Sr_line iGor-result_dates" aria-describedby={checkInID} onClick={checkInhandleClick}>
            <Typography variant="subtitle2" className='subtitle2'>
                <img src={icons_calendar} alt="locationIcon" />
                {t('Check Out')}
            </Typography>
            <Typography variant="subtitle1">
                {formatedToDate}
            </Typography>
        </Box>
        <Popover
            id={checkInID}
            open={checkInOpen}
            anchorEl={checkInanchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', padding: '10px' }}>
                <DestinationNightCount
                    numberOfNights={numberOfNights}
                    setNumberOfNights={setNumberOfNights}
                    endDateForDestination={endDateForDestination}
                    setEndDateForDestinationSearch={setEndDateForDestinationSearch}
                />
                <DatePickerComp
                    numberOfNights={numberOfNights}
                    setNumberOfNights={setNumberOfNights}
                    startDateForDestination={startDateForDestination}
                    endDateForDestination={endDateForDestination}
                    setEndDateForDestinationSearch={setEndDateForDestinationSearch}
                    setStartDateForDestinationSearch={setStartDateForDestinationSearch}
                />
                <Button variant='contained' onClick={() => { setCheckInAnchorEl(null) }}>{t("Continue")}</Button>
            </div>
        </Popover>
        <Box className="iGor-width-20">
            <Typography variant="subtitle2" className='subtitle2'>
                <img src={GuestsIcon} alt="locationIcon" />
                {t('Guests')}
            </Typography>
            <div onClick={(event) => { handleRoomClick(event) }} aria-describedby={roomId}>
                <Typography variant="subtitle1">
                    {roomArrayForGuest?.length} Room , {` ${adultCount > 1 ? adultCount + t(' Adults') : childCount == 0 ? t("I’m alone") : adultCount + t(' Adult')}`} {childCount > 0 ? ", " + childCount + ` ${childCount > 1 ? t('Children') : t('Child')}` : ''}</Typography>
            </div>
            <DestinationRoomAdditionPopOver
                setRoomArrayForGuest={setRoomArrayForGuest}
                roomArrayForGuest={roomArrayForGuest}
                setRoomAnchorEl={setRoomAnchorEl}
                RoomanchorEl={RoomanchorEl}
                roomId={roomId}
                open={RoomOpen}
            />
        </Box>
        <Box className="iGor-width-20 iGoR-SrFilterTopBtn">
            <Box sx={{ display: 'flex' }}>
                {/* <AdvancedFilterMod /> */}
                <Button
                    variant='contained'
                    sx={{ width: '172px' }}
                    onClick={searchClickHandler}
                    disabled={initApiLoading == true || hotelsDataLoading == true ? true : false}
                >
                    {initApiLoading == true || hotelsDataLoading == true ? <Box className='iGor-action_loader'>
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </Box> :
                        t('Search')}
                </Button>
            </Box>
        </Box>
    </Box>

    </>);
}

export default SearchModifyBox;