import React from 'react';
import { Box, Typography } from '@mui/material';
import artimg from '../../assets/images/ArticalImg.png';
import { useAppSelector } from '../../appStore/hooks';
import { environmentVariables } from '../../utils/config/environmentVariables';
import NoHotelsImg from '../../assets/images/No Hotels Img.png'
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setPreviousPathName } from '../../appStore/hotelSlice';

const ArticalCard = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userFaviouratesFolderDetails } = useAppSelector(state => state.hotel)
    const articlesArray = userFaviouratesFolderDetails?.data ?
        userFaviouratesFolderDetails.data.filter((item: any) => item.articles)
            .map((item: any) => item.articles).flat() : [];
    const imagePath = environmentVariables.imagePathApp;
    const pathName = window.location.pathname;

    // navigate to article details screen
    const checkoutArticleHanlder = (articleId: number, countryCode: string, location: string, slug: string) => {
        dispatch(setPreviousPathName(pathName))
        const locationSlug = countryCode + "-" + location && location != undefined ? location?.toLowerCase() : slug;
        navigate(`/explore/${locationSlug}/${slug}`)
    }

    return (
        <>
            {articlesArray.length > 0 ? (<Typography fontSize={'14px'} fontWeight={600} mt={1} ml={1} > Favourite Article</Typography>) : (<></>)}
            <Box className='iGorFav_ArticalCard'>
                {articlesArray.map((article: any) => {
                    return (
                        <Box className="articalCard" onClick={(e) => checkoutArticleHanlder(article.id, article.country, article.location, article.slug)}>
                            <img
                                src={article.backgroundImage ? `${imagePath}${article.backgroundImage}` : NoHotelsImg}
                                onError={(e: any) => {
                                    // Handle the image load error by setting the source to the default image
                                    e.target.src = NoHotelsImg;
                                }}
                                className='artical_img'
                            />
                            <Typography className='artcal_title'>{article.title}</Typography>
                            <Typography className='artcal_subtitle'>{article.readingTime}</Typography>
                        </Box>
                    )
                })}
            </Box>
        </>
    )
}

export default ArticalCard