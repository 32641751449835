import React, { useEffect, useState } from 'react';
import { GoogleMap, MarkerClusterer, Marker, InfoWindow } from '@react-google-maps/api';
import mapIconIgo from '../../assets/icons/igo_map_icon.svg'
import clusterIcons from '../../assets/icons/without_number_map_bg.svg'
import HotelCardMap from './HotelCardMap';
import { env } from '../../utils/config/appConfig';
import { environmentVariables } from '../../utils/config/environmentVariables';

function GoogleMapForOfferHotels({ markersData, defaultCenter, defaultZoom, selectedMarker,
    setSelectedMarker, hotelCardOpen, setHotelCardOpen, setDefaultZoom }: any) {
    const mapkey = environmentVariables.googleMapKey;
    const [markerPositions, setMarkerPositions]: any = useState([]);

    useEffect(() => {
        // Update marker positions whenever markersData changes
        const positions = markersData.map((location: any) => ({
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude)
        }));
        setMarkerPositions(positions);
    }, [markersData]);

    // marker properties
    const markerIcon = {
        url: mapIconIgo,
        grid: 60,
        scaledSize: new window.google.maps.Size(40, 40)
    };

    const clusterIcon = {
        url: clusterIcons,
        height: 40,
        width: 40,
        textColor: 'white',
        anchor: [25, 0],
    };

    const center = {
        lat: selectedMarker?.latitude ? parseFloat(selectedMarker?.latitude) : defaultCenter?.latitude,
        lng: selectedMarker?.longitude ? parseFloat(selectedMarker?.longitude) : defaultCenter?.longitude
    };

    const handleMarkerClick = (marker: any) => {
        setSelectedMarker(marker);
        setDefaultZoom(20)
        setHotelCardOpen(true)
    };

    const closeInfoWindow = () => {
        setHotelCardOpen(false)
        setDefaultZoom(8)
    };

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={center}
                zoom={defaultZoom}
                onClick={() => setHotelCardOpen(false)}
                options={{
                    minZoom: 2,
                    maxZoom: 20,
                }}
            >
                <MarkerClusterer
                    options={{ imagePath: 'path/to/m', gridSize: 60, styles: [clusterIcon] }}
                >
                    {(clusterer) =>
                        markerPositions.map((position: any, index: any) => (
                            <Marker
                                key={index}
                                position={position}
                                clusterer={clusterer}
                                icon={markerIcon}
                                onClick={() => handleMarkerClick(markersData[index])}
                            />
                        ))
                    }
                </MarkerClusterer>
                {selectedMarker && hotelCardOpen && (
                    <InfoWindow
                        position={{
                            lat: parseFloat(selectedMarker.latitude),
                            lng: parseFloat(selectedMarker.longitude),
                        }}
                        onCloseClick={closeInfoWindow}
                        options={{
                            pixelOffset: new window.google.maps.Size(0, -40),
                        }}
                    >
                        <HotelCardMap hotelInfo={selectedMarker} />
                    </InfoWindow>
                )}
            </GoogleMap>
        </div>
    );
}

export default GoogleMapForOfferHotels;
