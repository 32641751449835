import React, { useState } from 'react';
import Icon_demoProfile from '../../assets/icons/Icon_demoProfile.svg';
import { Box } from '@mui/system';
import { Button, Grid, Typography, useMediaQuery } from '@mui/material';
export const PropertyDetailsReview = (props: any) => {
    const { hotelDetail } = props;
    const [showMoreReview, setShowMoreReviews] = useState(false)
    const [activeIndex, setActiveIndex] = useState(null);
    const matches = useMediaQuery('(max-width:1024px)');
    const [showFullReview, setShowFullReview]: any = useState(false)
    const [showFullReviewIndex, setFullReviewIndex]: any = useState(null)
    const visibleReviews = showMoreReview == true ? hotelDetail?.review : hotelDetail?.review.slice(0, 2);
    const showAllReviewsHandler = () => {
        setShowMoreReviews(true)
    }
    const handleBoxClick = (index: any) => {
        setActiveIndex(index === activeIndex ? null : index);
        setFullReviewIndex(index)
    };

    const hideReviewHanlder = () => {
        setShowMoreReviews(false)
        setFullReviewIndex(null)
        setShowFullReview(false)
    }
    return (
        <>

            {matches && <Grid item sm={2} marginY={3} className="iGor_reviewSection"><Typography variant='body2' paddingLeft={'5px'}>Review by Google</Typography></Grid>}
            <Grid item lg={1} sm={12} marginY={3} className="iGor_reviewSection" bgcolor={"#ffffff "}>
                <Box className="iGor_box-review">
                    <Typography variant='subtitle1'>
                        {hotelDetail?.googleReviewRating ? parseFloat(hotelDetail?.googleReviewRating).toFixed(1) : 0}
                    </Typography>
                </Box>
                {/* <a href={hotelDetail?.moreReviewURL} target='_blank'> */}
                    <Box className='d-flex'>
                        <Typography variant='body2' paddingLeft={'5px'}>{hotelDetail?.userTotalReview ? hotelDetail?.userTotalReview : 0} Reviews</Typography>
                        {!matches && <Typography variant='body2' paddingLeft={'5px'}> by Google</Typography>}
                    </Box>
                {/* </a> */}
            </Grid>

            <Grid item lg={11} sm={12} xs={12} className="iGor_reviewCardSection">
                <Box className="iGor_reviewCard">
                    {visibleReviews?.map((item: any, index: any) => {
                        return (<Box className={`iGor_reviewCards ${index === activeIndex ? 'iGor_reviewCards100' : ''}`} key={item.title} onClick={() => handleBoxClick(index)}>
                            <Box className='iGor_reviewProfile' >
                                <img
                                    src={item.profilePhotoUrl ? item.profilePhotoUrl : Icon_demoProfile}
                                    onError={(e: any) => {
                                        // Handle the image load error by setting the source to the default image
                                        e.target.src = Icon_demoProfile;
                                    }}
                                    alt="reviewProfile" />                                <Box className="iGor_reviewName">
                                    <Typography variant='body2'>{item.username}</Typography>
                                    <Typography color={'#C4C4C4'}>{item.reviewPostedDate}</Typography>
                                </Box>
                            </Box>
                            <Box className="iGor_reviewContent">
                                {showFullReview == true && showFullReviewIndex == index ? (<Typography onClick={(e) => { setShowFullReview(false); setFullReviewIndex(null) }}>
                                    {item.message
                                        ? item.message : 'No information available'}
                                </Typography>)
                                    : (<Typography onClick={(e) => setShowFullReview(true)}>
                                        {item.message ? `${item.message.slice(0, 300)}......` : 'No information available'}
                                    </Typography>)}
                            </Box>
                        </Box>)
                    }
                    )}
                </Box>
            </Grid>


            <Grid item sm={12} xs={12} className='iGor-Text-center'>
                {(hotelDetail?.review.length > 2) ?
                    showMoreReview == false ? (<Button
                        variant='contained'
                        className='iGor_ReviewBtn'
                        onClick={(e) => setShowMoreReviews(true)}>See all reviews
                    </Button>)
                        : (<Button
                            variant='contained'
                            className='iGor_ReviewBtn'
                            onClick={hideReviewHanlder}>Hide all reviews
                        </Button>) : ''}
            </Grid>
        </>
    )
}
