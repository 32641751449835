import { calculateDateFourtyFiveDaysLater, dateConvertForExpiredCheckOut, dateFormatedForUrls } from "./DateConverter"

// checkout date expired for checkin and checkout
function DateExpirationHandler(idParamsFromHome: any) {
  const todaysDate: any = new Date()
  let todaysDateFormated = dateFormatedForUrls(todaysDate)
  let tommorrowsDateFormated = dateConvertForExpiredCheckOut(todaysDate)
  let checkInDateFromUrl: any = ''
  let checkOutDateFromUrl: any = ''
  let formatedDatesForparams: any = {
    checkInDateForparams: '',
    checkOutDateForparams: ''
  };

  if (checkInDateFromUrl == undefined || checkInDateFromUrl == '' || checkInDateFromUrl == null) {
    checkInDateFromUrl = todaysDateFormated
    // dispatch(setSearchDestinationFromDate(todaysDate.toISOString()))
  }
  else {
    checkInDateFromUrl = dateFormatedForUrls(idParamsFromHome.checkInDate)
  }

  if (checkOutDateFromUrl == '' || checkOutDateFromUrl == undefined || checkOutDateFromUrl == null) {
    checkOutDateFromUrl == tommorrowsDateFormated
    const date = new Date()
    date.setDate(date.getDate() + 1);
    // dispatch(setSearchDestinationToDate(date.toISOString()))
  }
  else {
    checkOutDateFromUrl = dateFormatedForUrls(idParamsFromHome.checkOutDate)
  }

  if (checkInDateFromUrl < todaysDateFormated) {
    formatedDatesForparams.checkInDateForparams = calculateDateFourtyFiveDaysLater(todaysDateFormated)
  }
  else if (checkInDateFromUrl >= todaysDateFormated) {
    formatedDatesForparams.checkInDateForparams = calculateDateFourtyFiveDaysLater(checkInDateFromUrl)
  }

  if (checkOutDateFromUrl < todaysDateFormated) {
    formatedDatesForparams.checkOutDateForparams = calculateDateFourtyFiveDaysLater(tommorrowsDateFormated)
  }
  else if (checkOutDateFromUrl > todaysDateFormated) {
    formatedDatesForparams.checkOutDateForparams = calculateDateFourtyFiveDaysLater(checkOutDateFromUrl)
  }

  return formatedDatesForparams;
}

export default DateExpirationHandler