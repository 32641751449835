export const envUAT = {
    // Api base Urls
    REACT_APP_API_BASE_URL: 'https://api.uatts.igoroom.com/',

    // Image Paths for images
    ImagePath: 'https://api-test.igoroom.com/api/media?path=',

    // Account Id's & Channel Id's
    AccountIdDemo: 'nexus-demo-account',
    ChannelIdDemo: 'nexus-demo-channel',
    // AccountIdIgo: 'igoroom-test-account',
    // ChannelIdIgo: 'igr-hyperguesttest-channel',
    AccountIdIgo: 'igoroom-live-account',
    ChannelIdIgo: 'igr-live-channel',
    // ChannelIdIgo: 'igr-fastpayhotelslive-channel',

    // stripe Api keys
    PublishableKey: 'pk_test_51O8JjZBM2yBApKNXbAJMgu0ZKf3Lvj3RbvdIExJwz5nHPEsED54hyfLDRLTyQI1SUjENZALtLmfev5RIb2Jpsqwd00aNd3k3FX',
    SecreatKey: 'sk_test_51O8JjZBM2yBApKNXo9xaXmXyzAyIUeBBtdbhWmhjUEtrMVDvUiJG3FN6DCeJvemmPgEMxYXYXwjZxeq3xO47pN1k000RVngU2w',

    //Paypal Client id and client_secret
    baseURLPayPal: 'https://api-m.sandbox.paypal.com',
    clientIdPaypal: 'AcSYXsEHxCLMmujiCaq_aQtafElKH9IRhQnPcSAieJKIEn839ZzN-L8JnRB4CJPIv-bvj9pn0GSuf6lk',
    clientSecretPaypal: 'EAdCUsGYbuS8mbmMkh_BqPcxqKkm3ChF9wWis0tgEGGYyj5ibAxmEfGm8jfCFlt2cu_X-nXJcfRK12dF',

    // map key google map
    mapKeyForGoogleMap:"AIzaSyCv-xlgR_sz2wS_xr3b_3dR-mBv-Qy4K_0",
    // mapKeyForGoogleMap: "AIzaSyBxLowJI44H84AppglorDKI5i4YofEGPG0",

    // application server key moengage
    applicationServerKey: 'BG9yZ2CWpnylqiUtFonGeWKNeoBnxAO6uMBCYvLw0GSGUmT-H_Z6x-fa7DeqUrHBQNmWTt27PCF3Zu8pXrd20bs',

    // environment 
    environment: 'UAT'
}

export const firebaseConfigUAT = {
    apiKey: "AIzaSyDAXirdp8cXKcO1-t8cZyeMQ4UmeYVCKU4",
    authDomain: "igorooms-uat.firebaseapp.com",
    projectId: "igorooms-uat",
    storageBucket: "igorooms-uat.appspot.com",
    messagingSenderId: "429021141641",
    appId: "1:429021141641:web:53bee5eaf7ca67734e8458",
    measurementId: "G-BJCVC46HLM"
};