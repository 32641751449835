import { useState, useEffect } from "react";
import { useAppSelector } from "../../appStore/hooks";
import { setRoomRecommendationIndexSelected, setRoomRecommendationSelected } from "../../appStore/hotelSlice";
import { useDispatch } from "react-redux";
import { Typography, useMediaQuery } from "@mui/material";
import { RadioButtonChecked } from "@mui/icons-material";
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";
import { Box } from "@mui/system";
import { env } from "../../utils/config/appConfig";
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import numeral from "numeral";
import { environmentVariables } from "../../utils/config/environmentVariables";
import PropertyRoomCancellationPolicies from "./PropertyRoomCancellationPolicy";

function PropertyRoomsRecomendations({ roomRecommendation, currencySymboll, setSelectedRoomId, roomId,
    selectedRoomId, setDefaultRecommendation, selectedDivIndex, setSelectedDivIndex, roomDetails }: any) {
    const dispatch = useDispatch()
    const { roomRecommendationSelected, roomRecommendationIndexSelected } = useAppSelector(state => state.hotel)
    const selectedRoomRecommendationId = roomRecommendationSelected?.id
    const environment = environmentVariables.enviornment;
    const pathname = window.location.pathname;
    const matches = useMediaQuery('(max-width:1024px)');

    // room recommendation checkout handler
    const roomRecommendationSelectionHandler = (roomRecommendationSelected: any, index: any) => {
        setDefaultRecommendation(false)
        setSelectedRoomId(roomId)
        if (setSelectedDivIndex) setSelectedDivIndex(index);
        if (pathname == '/property-details') {
            dispatch(setRoomRecommendationIndexSelected(index))
        }
        dispatch(setRoomRecommendationSelected(roomRecommendationSelected))
    }

    AppLogHandler('roomRecommendationSelected', { roomRecommendationSelected, roomRecommendationIndexSelected, selectedDivIndex })
    const RadioButtonComp = (roomRecommendation: any, index: number) => {
        if (index === selectedDivIndex && selectedRoomId == roomId) {
            return (<RadioButtonChecked
                className="iGor_Ractive"
                onClick={(e) => roomRecommendationSelectionHandler(roomRecommendation, index)}
            />)
        }
        else if (selectedRoomRecommendationId !== roomRecommendation.id && selectedRoomId !== roomId && index == 0) {
            return (<RadioButtonChecked
                className="iGor_Ractive"
                onClick={(e) => roomRecommendationSelectionHandler(roomRecommendation, index)}
            />)
        }
        else if (selectedRoomRecommendationId == roomRecommendation.id && selectedRoomId !== roomId && index == 0) {
            return (<RadioButtonChecked
                className="iGor_Ractive"
                onClick={(e) => roomRecommendationSelectionHandler(roomRecommendation, index)}
            />)
        }
        else {
            return (<RadioButtonUnchecked
                className="iGor_RDactive"
                onClick={(e) => roomRecommendationSelectionHandler(roomRecommendation, index)}
            />)
        }
    }

    return (<>
        {roomRecommendation.length > 0 ? roomRecommendation?.map((roomRecommendation: any, index: any) => {

            return (<>
                <Box key={index} className='iGor_RecommSection'>
                    {RadioButtonComp(roomRecommendation, index)}
                    {/* {index === selectedDivIndex && selectedRoomId == roomId ?
                        <RadioButtonChecked
                            className="iGor_Ractive"
                            onClick={(e) => roomRecommendationSelectionHandler(roomRecommendation, index)}
                        />
                        : selectedRoomRecommendationId !== roomRecommendation.id && selectedRoomId !== roomId && index == 0 ?
                            <RadioButtonChecked
                                className="iGor_Ractive"
                                onClick={(e) => roomRecommendationSelectionHandler(roomRecommendation, index)}
                            /> :
                            <RadioButtonUnchecked
                                className="iGor_RDactive"
                                onClick={(e) => roomRecommendationSelectionHandler(roomRecommendation, index)}
                            />} */}
                    <Box width={pathname.includes('/property-details') ? '45%' : '95%'}>
                        <Typography>{roomRecommendation?.roomGroup[0]?.boardBasis.type}</Typography>
                        {roomRecommendation?.roomGroup[0]?.refundability == 'Refundable' ?
                            (<Typography fontSize={'8px'}> ({'Refundable'})</Typography>)
                            : (<Typography fontSize={'8px'}> ({'Non-Refundable'})</Typography>)}
                    </Box>
                    <PropertyRoomCancellationPolicies
                        hotelRoomRecoDetails={roomRecommendation}
                        roomDetails={roomDetails}
                    />
                    {environment == 'development' ? (
                        <Typography
                            style={{
                                width:'20%',
                                fontWeight: 'bold',
                                fontSize: '10px'
                            }}
                        >({roomRecommendation?.roomGroup[0]?.providerName})
                        </Typography>
                    ) : ''}
                    <Box className='iGor_PriceReco'>
                        <Typography variant="body2" >{currencySymboll} {numeral(roomRecommendation?.perNightTotal).format('0,0')}</Typography>
                        <span>/night</span>
                    </Box>
                </Box>
            </>)
        }) : (<Box className='iGor_Recommendations_sold' width={'100%'}>
            <ErrorOutlineSharpIcon style={{ marginRight: '5px' }} />
            SOLD OUT
        </Box>
        )}
    </>);
}

export default PropertyRoomsRecomendations;