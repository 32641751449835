
interface RoomGuestDetails {
    adults: number;
    children: number;
}

interface GuestDetailsForBookingOBJ {
    id: number;
    title: string;
    firstName: string;
    lastName: string;
    isLeadPax: boolean;
    type: 'Adult' | 'Child';
}

function roomsAndGuestCountHandler(roomArrayForGuest: RoomGuestDetails[]) {
    const guestUserDetails: GuestDetailsForBookingOBJ = {
        id: 0,
        title: 'Mr',
        firstName: '',
        lastName: '',
        isLeadPax: true,
        type: 'Adult'
    };

    const guestUserChildDetails: GuestDetailsForBookingOBJ = {
        id: 0,
        title: 'Mr',
        firstName: '',
        lastName: '',
        isLeadPax: true,
        type: 'Child'
    };

    const passengersArray: { adultGuests: GuestDetailsForBookingOBJ[], childGuests: GuestDetailsForBookingOBJ[], room: number, adultCount: number, childCount: number }[] = [];

    roomArrayForGuest.forEach((room, index) => {
        const adultGuests = [...Array(room.adults)].map(() => ({ ...guestUserDetails }));
        const childGuests = [...Array(room.children)].map(() => ({ ...guestUserChildDetails }));

        const roomDetails = {
            adultGuests: adultGuests,
            childGuests: childGuests,
            room: index,
            adultCount: room.adults,
            childCount: room.children
        };

        passengersArray.push(roomDetails);
    });

    return passengersArray;
}

export default roomsAndGuestCountHandler;
