import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery, Skeleton } from '@mui/material';
import cardImg from '../../assets/images/mytripCards.jpg';
import iconMoon from '../../assets/icons/Icon_crescent-moon.svg';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import { environmentVariables } from "../../utils/config/environmentVariables";
import { cacheImages } from '../../utils/utilities/ImageCaching';
import MytripPopover from '../../components/MyTrip/MytripPopover';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import convertDateFormat, { formatDateDayDateYear } from '../../utils/utilities/DateConverter';
import { FacebookShareButton } from 'react-share';
import Icon_maps_pin from '../../assets/icons/Icon_maps-pin.svg';
import Icon_facebook from '../../assets/icons/Icon-white-facebook.svg';
import MytripCancellationModel from '../../components/MyTrip/MytripCancellationModel';
import { useDispatch } from 'react-redux';
import { fetchDownloadBookingDetails, getBookedOfferDetails, setAlertBoxOnError, setBookedOfferDetailsReset, setErrorMessageForDialogueBox } from '../../appStore/hotelSlice';
import { useAppSelector } from '../../appStore/hooks';
import MyBookedOffersModal from './MyOffersModal';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';

function MyBookedOffersCards({ userBookedOffersList }: any) {
    const dispatch = useDispatch();
    const { bookedOfferDetails } = useAppSelector(state => state.hotel)
    const [open, setOpen] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const matches = useMediaQuery('(max-width:1024px)');
    const matchesMobile = useMediaQuery('(max-width:600px)');
    const userBookedOfferDetails = bookedOfferDetails?.data ? bookedOfferDetails?.data : {};
    const userBookedOfferApiStatus = userBookedOfferDetails?.status;
    const imagePath = environmentVariables.imagePathApp;
    const pathToShare = window.location.href;
    const userBookedOffersListArray = userBookedOffersList

    const handleImageLoad = () => {
        setTimeout(() => { setImageLoaded(true) }, 2000)
    };

    const getBookingDetailsHandler = (bookingId: any) => {
        dispatch(setBookedOfferDetailsReset({ data: [], loading: false, error: null }))
        dispatch(getBookedOfferDetails(bookingId))
        // dispatch(fetchDownloadBookingDetails(bookingId)) 
    }
    AppLogHandler('userBookedOfferDetails', userBookedOfferDetails)

    const handleClose = () => {
        dispatch(setBookedOfferDetailsReset({ data: [], loading: false, error: null }))
        setOpen(false)
    };

    useEffect(() => {

        if (userBookedOfferApiStatus == 'Success') {
            setOpen(true);
        }
        else if (userBookedOfferApiStatus == 'Failed') {
            dispatch(setErrorMessageForDialogueBox('20'))
            dispatch(setAlertBoxOnError(true))
            dispatch(setBookedOfferDetailsReset({ data: [], loading: false, error: null }))

        }
    }, [userBookedOfferApiStatus])

    //direct to location
    const moveToLocationGoogleMap = (coOrdinates: any) => {
        const coOrdinatesMap = coOrdinates;
        if (coOrdinatesMap?.latitude && coOrdinatesMap?.longitude) {
            window.open(`https://www.google.com/maps/search/?api=1&query=${coOrdinatesMap?.latitude},${coOrdinatesMap?.longitude}`);
        }
    }

    return (<>{userBookedOffersListArray.map((bookingList: any) => {
        const imageForBookings = bookingList.path_Img ? `${imagePath}${bookingList.path_Img}` : cardImg
        const imageCached = cacheImages(imageForBookings);
        return (<Box className="iGor__MytripCards" key={bookingList.booking_id}>
            <MytripPopover booking={bookingList} />
            <Grid container onClick={!matchesMobile ? () => getBookingDetailsHandler(bookingList.bookingId) : () => { }}>
                <Grid item sm={7} xs={12} >
                    <Grid container  >
                        {matches &&
                            <Grid item xs={12} height={'14px'}>
                                <Box height={'100%'} className="iGor__BtnBox">
                                    <Button className={bookingList.bookingStatus == 'Confirmed' ? 'iGor__Paid' : bookingList.bookingStatus == 'Requested' ? 'iGor__Pending' : 'iGor__Cancelled'}>
                                        {bookingList.bookingStatus == 'Confirmed' ?
                                            (<><CheckIcon /> Paid</>) :
                                            bookingList.bookingStatus == 'Requested' ?
                                                (<> <AccessAlarmsIcon />{bookingList.bookingStatus}</>)
                                                : (<><CancelIcon />Cancelled</>)}
                                    </Button>
                                </Box>
                            </Grid>}
                        <Grid item sm={6} xs={5}>
                            <Box className='iGor__MytripImgBox' onClick={matchesMobile ? () => getBookingDetailsHandler(bookingList.bookingId) : () => { }}>
                                {!imageLoaded && (<Skeleton variant="rectangular" height={132} animation="wave" sx={{ borderRadius: '0.625rem' }} />)}

                                <img src={imageCached.src} className='iGor__MytripImg'
                                    style={{
                                        display: imageLoaded ? 'block' : 'none'
                                    }}
                                    onLoad={handleImageLoad} />
                            </Box>
                        </Grid>
                        <Grid item sm={6} className='iGor__MytripCardContent' xs={7}>
                            <Typography variant='body1' className='iGor__orderId'>  Order ID: {bookingList.bookingId}    </Typography>
                            <Typography variant='subtitle1' className='iGor__Title'>      {bookingList.hotelName}</Typography>
                            <Box className="iGor_setFlex iGor_justifyBetween iGor_alignCenter" sx={{ margin: '1rem 0' }}>
                                <Box>
                                    <Typography variant='body1' className='iGor__check'>   Check-in</Typography>
                                    <Typography variant='body1' className='iGor__Date'> {convertDateFormat(bookingList.tripStartDate)}</Typography>
                                </Box>
                                <Box>
                                    <img style={{ display: 'flex' }} src={iconMoon} />
                                </Box>
                                <Box>
                                    <Typography variant='body1' className='iGor__check'>   Check-out </Typography>
                                    <Typography variant='body1' className='iGor__Date'>  {convertDateFormat(bookingList.tripEndDate)}</Typography>

                                </Box>
                            </Box>
                            <Typography variant='body1' className='iGor__subTilte'>   {bookingList.rateName}</Typography>
                            <Typography variant='body1' className='iGor__subTilte'>   {`${bookingList.numberOfPassengers ? bookingList.numberOfPassengers > 1 ? bookingList.numberOfPassengers + ' Guests' : bookingList.numberOfPassengers + ' Guest' : ''}`} •  {formatDateDayDateYear(bookingList.createdAt) + " "}
                                • {`${bookingList.noOfDays ? bookingList.noOfDays > 1 ? bookingList.noOfDays + ' Nights' : bookingList.noOfDays + ' Night' : ''}`}</Typography>
                            {matches &&
                                <Box className='IGor_mytripBtnWrapper'>
                                    <List className='iGor_mytripPopoList'>
                                        <ListItem disablePadding>
                                            <ListItemButton className='iGor_mytripBtnMap' onClick={(e) => { moveToLocationGoogleMap(bookingList) }}>
                                                <ListItemIcon>
                                                    <img src={Icon_maps_pin} className='iGor__maps_pin' />
                                                </ListItemIcon>
                                                <ListItemText primary="Direct me" />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding className='iGor_mytripBtnFbList'>
                                            <FacebookShareButton url={pathToShare}>
                                                <ListItemButton className='iGor_mytripBtnFb'>
                                                    <ListItemIcon>
                                                        <img src={Icon_facebook} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Share this" />
                                                </ListItemButton>
                                            </FacebookShareButton>
                                        </ListItem>
                                    </List>
                                    {bookingList.bookingStatus === 'Confirmed' ? (<Box>
                                        <MytripCancellationModel
                                            bookingId={bookingList.booking_id}
                                        />
                                    </Box>) : (<></>)}
                                </Box>}
                        </Grid>
                    </Grid>

                </Grid>
                {!matches &&
                    <Grid item sm={5}>
                        <Box height={'100%'} className="iGor__BtnBox">
                            <Button className={bookingList.bookingStatus == 'Confirmed' ? 'iGor__Paid' : bookingList.bookingStatus == 'Requested' ? 'iGor__Pending' : 'iGor__Cancelled'}>
                                {bookingList.bookingStatus == 'Confirmed' ?
                                    (<><CheckIcon /> Paid</>) :
                                    bookingList.bookingStatus == 'Requested' ?
                                        (<> <AccessAlarmsIcon />{bookingList.bookingStatus}</>)
                                        : (<><CancelIcon />Cancelled</>)}
                            </Button>
                        </Box>
                    </Grid>}
            </Grid>
        </Box>)
    })}
        {userBookedOfferApiStatus === 'Success' && (
            <MyBookedOffersModal
                handleClose={handleClose}
                open={open}
            />
        )}
    </>);
}

export default MyBookedOffersCards;