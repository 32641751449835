import { setNoOfNights, setRoomArray, setSearchDestinationFromDate, setSearchDestinationToDate } from "../../appStore/hotelSlice"

export const searchValuesDispatch: any = (numberOfNights: number,roomArrayForGuest: any, startDateForDestination: any,
    endDateForDestination: any,
    
) => {
    return async (dispatch: any) => {
        const dateSelectedFrom = new Date(startDateForDestination)
        const dateSelectedTo = new Date(endDateForDestination)
        dispatch(setNoOfNights(numberOfNights))
        dispatch(setRoomArray(roomArrayForGuest))
        dispatch(setSearchDestinationFromDate(dateSelectedFrom.toISOString()))
        dispatch(setSearchDestinationToDate(dateSelectedTo.toISOString()))
    }
}