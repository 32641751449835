import React, { useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import Icon_InfoPro from '../../assets/icons/Icon_InfoPro.svg';
import icon_room from '../../assets/icons/icon_room.svg';
import Icon_bed from '../../assets/icons/Icon_bed.svg';
import { useLocation, useNavigate } from 'react-router';
import StarIcon from '@mui/icons-material/Star';
import { setRoomIndexSelectedForCheckout, setRoomRecommendationIndexSelected, setRoomTypeSelectedForCheckout } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from 'react-i18next';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { ToastContainer } from 'react-toastify';
import PropertyFacilitiesModal from '../../components/popUpSection/PropertyFacilitiesModal';
import { PropertyRoomSelectionFunction } from '../../components/PropertyDetailsCards/PropertyRoomSelection';
import numeral from 'numeral';


const PropertyDetailsView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [moredetails, setMoredetails] = useState(false);
    const [showFacilities, setShowFacilities] = useState(false)
    const { state } = useLocation();
    const { roomArray, hotelIdSelected, initApi, } = useAppSelector(state => state.hotel);
    const hotelDetailsData = useAppSelector(state => state.hotel.hotelDetails?.data?.hotel)
    const hotelRating = hotelDetailsData?.googleReviewRating ? hotelDetailsData?.googleReviewRating : 0;
    const hotelStarRating = hotelDetailsData?.googleReviewRating ? parseInt(hotelDetailsData?.googleReviewRating) : 0;
    const hotelReviews = hotelDetailsData?.userTotalReview ? hotelDetailsData?.userTotalReview : 0;
    const roomArrayForGuest = roomArray;
    const pathnameFromUrl = window.location.pathname;
    const searchId = initApi.searchId;
    const roomDetails = state.roomSelected;
    const moveToPropertyDetails = state.href;
    const selectedRoomId = state.selectedRoomId
    const roomRecommendationSelected = state.roomRecommendationSelected;
    const defaultRecommendation = state.defaultRecommendation;
    const roomIndexSelected = state.roomIndexSelected;
    const selectedDivIndex = state.selectedDivIndex;

    const roomCheckoutHandler = async (roomRecommendations: any, roomSelectedForCheckout: any) => {
        if (roomSelectedForCheckout.isOffer == false) {

            dispatch(setRoomIndexSelectedForCheckout(roomIndexSelected))
            if (pathnameFromUrl == '/order-summary') {
                dispatch(setRoomRecommendationIndexSelected(selectedDivIndex))
            }
            else if (pathnameFromUrl.includes('/property-details')) {
                dispatch(setRoomRecommendationIndexSelected(selectedDivIndex))
            }
            let res = await dispatch(PropertyRoomSelectionFunction(
                roomRecommendations,
                roomSelectedForCheckout,
                searchId,
                defaultRecommendation,
                roomRecommendationSelected,
                hotelIdSelected,
                roomArrayForGuest,
                roomIndexSelected,
                navigate
            ))
        }
        else if (roomSelectedForCheckout) {
            if (roomSelectedForCheckout) {
                dispatch(setRoomIndexSelectedForCheckout(roomIndexSelected))
                dispatch(setRoomTypeSelectedForCheckout(roomSelectedForCheckout))
                navigate('/order-summary')
            }
        }
    }


    AppLogHandler('PropertyDetailsVrView', {
        'state Received': roomDetails
    })

    return (
        <>
            <Box className="iGor__ProDetailsView">
                <iframe src={roomDetails.vrroom} id='landingPageIframe'></iframe>

                <Box className="iGor__proBackBtnBox">
                    <Button className='iGor__proBackBtn' onClick={() => navigate(`${moveToPropertyDetails}`)}><ArrowBackIcon /> Back</Button>
                </Box>
                <Box className="iGor__ProDetailsViewCard">
                    <Box >
                        <Typography className='iGor__proTitle'>{roomDetails.roomname}</Typography>
                        <Box className="iGor_setFlex iGor__proReviweBox">
                            <Typography className='iGor__proResorts'>Resort</Typography>
                            {Array.from({ length: hotelStarRating || 0 }, (_, index) => (
                                <StarIcon key={index} className='iGor__proRStar' />
                            ))}
                            {Array.from({ length: 5 - hotelStarRating || 0 }, (_, index) => (
                                <StarIcon key={index} className='iGor_starIcon_not_rating' />
                            ))}
                            <Box><Typography className='iGor__proReviwe'> {hotelRating}</Typography></Box>
                            <Typography className='iGor__proReviewTitle'>
                                {`${hotelStarRating <= 2 ? t('Average') : hotelStarRating <= 4 && hotelStarRating >= 2 ? t('Good') : t('Excellent')}`}
                                ({numeral(hotelReviews).format('0,0')} reviews) </Typography>
                        </Box>
                    </Box>
                    <Box className="iGor_setFlex iGor_proPrice_Detls">
                        <Box className='iGor-width-50'>
                            <Box className="iGor_setFlex">
                                <Typography className='iGor__proPriceIcon'>{roomDetails?.isSoldOut == true ? '' : roomDetails.listprice.currency_symbol}</Typography>
                                {roomDetails?.listprice?.roomRecommendation.length > 0 ? roomDetails?.listprice?.roomRecommendation.map((recommendation: any, indexReco: any) => {
                                    if (roomDetails.roomid == selectedRoomId && roomRecommendationSelected.id == recommendation.id && roomRecommendationSelected.groupId == recommendation.groupId) {
                                        return (<Typography className='iGor__proPrice'>{`${roomDetails?.isSoldOut == true ? 'SOLD OUT' : recommendation.roundOffPerNightTotal}`}</Typography>)
                                    }
                                    if (roomDetails.roomid !== selectedRoomId && indexReco == 0) {
                                        return (<Typography className='iGor__proPrice'>{`${roomDetails?.isSoldOut == true ? 'SOLD OUT' : recommendation.roundOffPerNightTotal}`}</Typography>)
                                    }
                                }) : (<Box className='iGor_Recommendations_sold' width={'100%'}>
                                    <ErrorOutlineSharpIcon style={{ marginRight: '5px' }} />
                                    SOLD OUT
                                </Box>)}
                                {/* <Typography className='iGor__prPriceInfo'> /night</Typography> */}
                            </Box>
                            {roomDetails?.isSoldOut == true ? '' : <Typography className='iGor__prPriceInfo'> Taxes and all fees exclude</Typography>
                            }

                        </Box>
                        <Box className="iGor_setFlex iGor-width-50" sx={{ justifyContent: 'flex-end' }}>
                            <img src={Icon_InfoPro} className='iGor_proInfoIcon' onClick={(e) => { setShowFacilities(true) }} />
                            <Button variant='contained' disabled={roomDetails.isSoldOut} className={roomDetails?.isSoldOut == true ? 'iGor_proBookNow_disable' : 'iGor_proBookNow'} onClick={(e) => { roomCheckoutHandler(roomDetails.listprice.roomRecommendation, roomDetails) }}>Book Now</Button>
                        </Box>
                    </Box>
                    {moredetails &&
                        <Box>
                            <Box className='iGor__ProDContentBox'>
                                {roomDetails.packagedescription ? (<Typography className='iGor__ProDetailsContent'>
                                    {roomDetails.packagedescription}
                                </Typography>) : (<Typography>Discription not available</Typography>)}
                            </Box>
                            <Box className="iGor_setFlex iGor__ProDRoom">
                                {roomDetails.roomsize && <Box className=" iGor-width-50 iGor_setFlex">
                                    <img src={icon_room} />
                                    <Typography className='iGor__ProDetailsMain' component={'span'}>Room size:</Typography>
                                    <Typography className='iGor__ProDetailsSub' component={'span'}>{roomDetails.roomsize ? `${roomDetails.roomsize}sqm` : 'N/A'}</Typography>
                                </Box>}
                                {roomDetails.roomcapacity && <Box className=" iGor-width-50 iGor_setFlex">
                                    <img src={Icon_bed} />
                                    <Typography className='iGor__ProDetailsMain' component={'span'}>Capacity: </Typography>
                                    <Typography className='iGor__ProDetailsSub' component={'span'}> {roomDetails.roomcapacity} people</Typography>
                                </Box>}
                            </Box>
                        </Box>}
                    <Box className='iGor__proMoreLessBox' onClick={() => setMoredetails(!moredetails)}>
                        <Typography className='iGor__proMoreLess'> {`${moredetails == false ? 'More' : 'Less'} `} details</Typography>
                        {moredetails == false ? <ExpandMoreIcon className='iGor__proML_Icon' /> : <ExpandLessIcon className='iGor__proML_Icon' />}

                    </Box>
                    <PropertyFacilitiesModal
                        listing_details={roomDetails}
                        showFacilities={showFacilities}
                        showFacilityText={"property-view"}
                        setShowFacilities={setShowFacilities}
                    />
                </Box>

            </Box >
            <ToastContainer />
        </>
    )
}

export default PropertyDetailsView