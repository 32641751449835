import roomsAndGuestCountHandler from '../../utils/utilities/RoomAndGuestCount';
import { fetchHotelsRoomPriceDataFromApi, setGuestDetailsAtCheckIn, setHotelRoomPriceSelectedReset, setRoomRecommendationIndexSelected, setRoomTypeSelectedForCheckout } from '../../appStore/hotelSlice';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';

interface Room {
    id: number;
    // Define other properties of Room
}

interface RoomGroup {
    providerName: string;
    refundability: 'Refundable' | 'NonRefundable';
    // Define other properties of RoomGroup
}

interface RoomRecommendation {
    id: number;
    roomGroup: RoomGroup[];
    // Define other properties of RoomRecommendation
}

interface RoomSelected {
    // Define properties of RoomSelected
}

export const PropertyRoomSelectionFunction: any = (
    roomRecommendations: RoomRecommendation[],
    roomSelectedForCheckout: RoomSelected,
    searchId: string,
    defaultRecommendation: boolean,
    roomRecommendationSelected: any, // Define type for roomRecommendationSelected
    hotelIdSelected: any,
    roomArrayForGuest: any,
    roomIndexSelected: number,
    navigate: any,
) => {
    return async (dispatch: any) => {

        dispatch(setHotelRoomPriceSelectedReset({ data: [], loading: false, error: null }))
        const passengerCountsForDetails: any = roomsAndGuestCountHandler(roomArrayForGuest) // Assuming roomArrayForGuest is defined somewhere

        let selectedRoomRecommForCheckout: RoomRecommendation | undefined
        if (!defaultRecommendation) {
            selectedRoomRecommForCheckout = roomRecommendations.find(room => room.id === roomRecommendationSelected.id)
        } else if (!selectedRoomRecommForCheckout && defaultRecommendation) {
            selectedRoomRecommForCheckout = roomRecommendations[0]
            dispatch(setRoomRecommendationIndexSelected(0))
        }

        if (selectedRoomRecommForCheckout === undefined) {
            selectedRoomRecommForCheckout = roomRecommendations[0]
            dispatch(setRoomRecommendationIndexSelected(0))
        }

        const roomPriceId = selectedRoomRecommForCheckout?.id || ''
        const [roomGroup] = selectedRoomRecommForCheckout?.roomGroup || []

        AppLogHandler('roomSelectedForCheckout', { roomSelectedForCheckout, selectedRoomRecommForCheckout, roomGroup })

        if (searchId !== '' && selectedRoomRecommForCheckout && roomSelectedForCheckout && roomGroup.providerName && roomPriceId) {
            dispatch(setRoomTypeSelectedForCheckout(roomSelectedForCheckout))
            dispatch(fetchHotelsRoomPriceDataFromApi({ searchId, providerName: roomGroup.providerName, priceId: roomPriceId, hotelId: hotelIdSelected })) // Assuming hotelIdSelected is defined somewhere
            navigate('/order-summary')
            dispatch(setGuestDetailsAtCheckIn(passengerCountsForDetails))
        }
        // else if (roomGroup.refundability === 'NonRefundable') {
        // dispatch(setErrorMessageForDialogueBox('17'))
        // dispatch(setAlertBoxOnError(true))
        // }
    }
}


