import React from 'react'
import { Grid, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useAppSelector } from '../appStore/hooks'
import { env } from '../utils/config/appConfig'
import { useTranslation } from 'react-i18next'

export const Footer = () => {
    const { t } = useTranslation()
    const { initApi, loggedInUserCurrency } = useAppSelector(state => state.hotel)
    const corellationId = initApi?.searchTracingKey;
    const channelId = env.ChannelIdIgo;
    const accountId = env.AccountIdIgo;
    const environment =env.environment
    const currencySelected = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const currencySymbol = loggedInUserCurrency?.currencySymbol ? loggedInUserCurrency?.currencySymbol : '$';
    return (
        <>
            <footer className="iGor_ProDet-footer iGOr_mb-D-none  ">
                <Container>
                    <Grid container>
                        <Grid item sm={12} className='iGor_setFlex'>
                            <ul>
                                <li> <Typography variant='caption'>{t('© Copyright')}</Typography></li>
                                <a href='/terms-conditions' target='_blank' className='iGor-footer-links'>
                                    <li>
                                        <Typography variant='caption'>{t('Privacy')}</Typography>
                                    </li>
                                </a>
                                <a href='/terms-conditions' target='_blank' className='iGor-footer-links'>
                                    <li>
                                        <Typography variant='caption'>{t('Terms')}</Typography>
                                    </li>
                                </a>
                            </ul>
                            <ul className='igor_footerleft'>
                                <li> <Typography variant='body2'>English (EN)</Typography></li>
                                <li> <Typography variant='body2'>{currencySymbol}{currencySelected}</Typography></li>
                                <li> <a href='https://support.igoroom.com/support/home' target='_blank' style={{ textDecoration: 'none', color: 'black' }}>
                                    <Typography variant='body2'>Support</Typography>
                                </a></li>
                            </ul>
                        </Grid>
                        {environment == 'development' ? (<Grid>
                            <ul className='igor_Test_credentials'>
                                <li> <Typography variant='body2'>Account Id :</Typography></li>
                                <Typography variant='body1'>{accountId}</Typography>
                                <li> <Typography variant='body2'>Channel Id :</Typography></li>
                                <Typography variant='body1'>{channelId}</Typography>
                                <li> <Typography variant='body2'>Corelletion Id :</Typography></li>
                                <Typography variant='body1'>{corellationId}</Typography>
                            </ul>
                        </Grid>) : ('')}
                    </Grid>
                </Container >
            </footer >
        </>
    )
}
