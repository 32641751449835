import React, { useState, useEffect } from 'react';
import { OrderSummaryHeader } from '../../components/OrderSummary/OrderSummaryHeader';
import { Box, Container, positions } from '@mui/system';
import { Button, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, InputAdornment, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Icon_couponline from '../../assets/icons/Icon_coupon-line.svg';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { OrderSumRegisterModel } from '../../components/popUpSection/OrderSumRegisterModel';
import { Footer } from '../../layouts/Footer';
import { OrderSumInfoMassage } from '../../components/OrderSummary/OrderSumInfoMassage';
import { OrderSumFinishCard } from '../../components/OrderSummary/OrderSumFinishCard';
import { OrderSummaryCard } from '../../components/OrderSummary/OrderSummaryCard';
import { OrderGuestDetails } from '../../components/OrderSummary/OrderGuestDetails';
import { OrderSumPayment } from '../../components/OrderSummary/OrderSumPayment';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { useAppSelector } from '../../appStore/hooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import StripePaymentIntent from '../../components/Payments/StripePaymentIntent'
import { EventNamesForEventLogs, orderSummarySectionType } from '../../utils/constants/constant';
import { useDispatch } from 'react-redux';
import { fetchHotelsRoomPriceDataFromApi, setAlertBoxOnError, setErrorMessageForDialogueBox, setOrderSummarySectionType, setPassengerDetailsForCheckout } from '../../appStore/hotelSlice';
import numeral from 'numeral';
import PaypalCreateOrder from '../../components/Payments/PaypalCreateOrder';
import { PropertyDetailsCards } from '../../components/PropertyDetailsCards/PropertyDetailsCards';
import OrderSummaryAdditionalService from './OrderSummaryAdditionalServices';
import { EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import { isDateWithinThreeMonthsFromNow } from '../../utils/utilities/DateConverter';
import { CheckAllMatchFulfilledForBooking } from './CheckAllMatchFulfil';

export const OrderSummary = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue]: any = useState(null);
  const [activeStep, setActiveStep] = useState(1);
  const [continuePayment, setContinuePayment] = useState(false)
  const [paypalDashBoard, setPaypalDashBoard] = useState(false);
  const [errors, setErrors]: any = useState([]);
  const matches = useMediaQuery('(max-width:1024px)');
  const { hotelRoomPriceApiData, selectedCurrencySymboll, searchDestinationFromDate, roomRecommendationIndexSelected, hotelIdSelected, roomRecommendationSelected, hotelRoomDetails, roomIndexSelectedForCheckout, roomTypeSelectedForCheckout, initApi } = useAppSelector(state => state.hotel)
  const hotelDetailsResponse = useAppSelector(state => state.hotel.hotelDetails?.data?.hotel)
  const maldivesHotel = hotelDetailsResponse?.city_country ? hotelDetailsResponse?.city_country : '';
  const userLoggedIn = useAppSelector(state => state.hotel.setUserLoggedIn)
  const hotelPriceData: any = hotelRoomPriceApiData.data;
  const hotelPriceApiLoading: any = hotelRoomPriceApiData.loading;
  const orderSummarySectionSelected = useAppSelector(state => state.hotel.orderSummerySection)
  const passengerDetails = useAppSelector(state => state.hotel.guestDetailsAtCheckIn)
  const searchId = initApi.searchId;
  const [roomGroup] = hotelPriceData?.roomGroup ? hotelPriceData?.roomGroup : []
  let roomPriceSelected = roomGroup?.roundOffTotalRate;
  let roomPriceConverted = roomPriceSelected;
  const currencyForcheckout = roomTypeSelectedForCheckout.listprice?.currency_symbol ? roomTypeSelectedForCheckout.listprice?.currency_symbol : roomGroup?.currency
  const orderSummarySction = orderSummarySectionType.orderSummary;
  const paymentSection = orderSummarySectionType.payment;
  const finishSection = orderSummarySectionType.finish;
  const eventNameOrderReview = EventNamesForEventLogs.reviewOrder;
  const totalRateForHotel = roomGroup?.roundOffTotalRate;
  const refundability = roomGroup?.refundability ? roomGroup?.refundability : '';
  const isOfferRooms = roomTypeSelectedForCheckout?.isOffer ? roomTypeSelectedForCheckout?.isOffer : false;
  const totalRateCheckout = isOfferRooms == false ? roomGroup?.roundOffTotalRate ? roomGroup?.roundOffTotalRate : 0 : roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price ? roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price : 0;
  const handleBack = (data: any) => {
    setActiveStep(data);
    setContinuePayment(false)
  };

  // validate user details entered
  const validateGuestDetails = () => {
    const newErrors = passengerDetails.map((obj: any) => {
      const guestErrors: any = {
        adultGuests: [],
        childGuests: []
      };

      // Function to validate a single guest
      const validateSingleGuest = (guest: any) => {
        const errors: any = {};
        if (guest.firstName.trim() === '') {
          errors.firstName = 'Please Input First Name';
        }
        if (guest.lastName.trim() === '') {
          errors.lastName = 'Please Input Last Name';
        }
        if (guest.title.trim() === '') {
          errors.title = 'Select Title';
        }
        return errors;
      };

      // Validate adult guests
      obj.adultGuests.forEach((adult: any) => {
        const errors = validateSingleGuest(adult);
        guestErrors.adultGuests.push(errors);
      });

      // Validate child guests
      obj.childGuests.forEach((child: any) => {
        const errors = validateSingleGuest(child);
        guestErrors.childGuests.push(errors);
      });

      return guestErrors;
    });

    // Check if there are any errors
    const hasErrors = newErrors.some((errors: any) => {
      return Object.values(errors.adultGuests).some((error: any) => Object.keys(error).length > 0) ||
        Object.values(errors.childGuests).some((error: any) => Object.keys(error).length > 0);
    });

    setErrors(newErrors);

    return hasErrors;
  };



  // function to dispatch details of passengers for booking
  const passangerDetailsHandler = (passengerArry: any) => {
    const allGuests: any[] = [];
    passengerArry.forEach((room: any) => {
      allGuests.push(...room.adultGuests);
      allGuests.push(...room.childGuests);
    })
    dispatch(setPassengerDetailsForCheckout(allGuests))
  }

  // submit user details and continue to payment handler
  const submitUserDetailsHandler = async () => {
    const inValidUser = validateGuestDetails();
    if (userLoggedIn == true && inValidUser == false && totalRateCheckout) {
      setContinuePayment(true)
      passangerDetailsHandler(passengerDetails)
      setPaypalDashBoard(true)
      dispatch(setOrderSummarySectionType(paymentSection))

      // event log to analytics
      const eventIdsForLogEvents = {
        "ig_content_id": hotelIdSelected,
        "ig_room_id": roomRecommendationSelected.id,
        "ig_price": totalRateForHotel
      }
      EventLogsHandler(eventNameOrderReview, eventIdsForLogEvents)
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    // else if (userLoggedIn == true && validateUser == true && roomGroup?.totalRate && matches) {
    //   dispatch(setOrderSummarySectionType(paymentSection))
    // }

    else if (userLoggedIn == false) {
      const res = await dispatch(CheckAllMatchFulfilledForBooking
        (true, '12'))
    }
    else if (totalRateCheckout == undefined) {
      const res = await dispatch(CheckAllMatchFulfilledForBooking
        (true, '28'))
    }
    else if (inValidUser == true) {
      window.scrollTo(0, 0)
    }
    else if (inValidUser == true) {
      window.scrollTo(0, 0)
    }
  };

  AppLogHandler('Order Summary Page', {
    roomRecommendationIndexSelected: roomRecommendationIndexSelected,
    roomTypeSelectedForCheckout: roomTypeSelectedForCheckout,
    orderSummarySectionSelected: orderSummarySectionSelected
  })
  return (
    <>
      <OrderSummaryHeader
        onBackStep={handleBack}
        nextStep={activeStep}
        setMobileStep={orderSummarySectionSelected === orderSummarySction}
        setContinuePayment={setContinuePayment}
      />

      <Container className='iGor__orderSumSection'>
        {activeStep <= 2 &&
          <Grid container spacing={2} className='iGor__OrdSumContainer'>
            {orderSummarySectionSelected === orderSummarySction &&
              <Grid item lg={7} xs={12}>
                {userLoggedIn == false ? (<Box className="iGor_bgWhite iGOr_BorderRadius15 iGor_OrderRegBtn iGor_setFlex iGor_justifyBetween">
                  <Box >
                    <Typography variant='subtitle1'>   Log In or register to enjoy this member-only benefit
                    </Typography>
                    <Typography variant='body1'>
                      Book faster, easier and cheaper</Typography>
                  </Box>
                  <Box> <OrderSumRegisterModel /></Box>

                </Box>) : <></>}
                {maldivesHotel.includes('Maldives') ? (<OrderSumInfoMassage />) : ''}
                <Typography variant='subtitle1' className='iGor__GuestdetailsTilte'>Guest details</Typography>

                <OrderGuestDetails errors={errors} setErrors={setErrors} validateGuestDetails={validateGuestDetails} continuePayment={continuePayment} setContinuePayment={setContinuePayment} />

                {isOfferRooms == false && <>
                  <Typography variant='subtitle1' className='iGor__GuestdetailsTilte'>Additional services</Typography>
                  <Box className='iGor_bgWhite iGOr_BorderRadius15' sx={{ padding: '16px 20px 16px 23px' }}>
                    <OrderSummaryAdditionalService />
                  </Box>
                  <Typography variant='subtitle1' margin={'30px 0 13px 0'}>Upgrade my room</Typography>
                  <Box className="iGor__UpGradeMyRoom">
                    <PropertyDetailsCards />
                  </Box></>}
              </Grid>
            }

            {orderSummarySectionSelected === paymentSection &&
              (<OrderSumPayment />)
            }

            <Grid item lg={5} xs={12} >
              <Box className='iGor__orderSumSticky'>
                {orderSummarySectionSelected != orderSummarySction && matches ? <> <OrderSummaryCard /></> : <></>}
                {!matches && <OrderSummaryCard />}

                {/* <Box className=" iGor_bgWhite iGOr_BorderRadius15 iGor_OrderCouponcode">

                  <Box className="iGor_setFlex iGor_justifyBetween">+
                    <Typography variant='body2'>  Input promo or coupon code  </Typography>
                    <Button className='iGor_orderPromBtn'> See all promo</Button>
                  </Box>

                  <TextField InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Icon_couponline} />
                      </InputAdornment>
                    ),
                  }}
                    className='iGor_OrderS_PromCode'></TextField>
                </Box> */}
                {orderSummarySectionSelected !== finishSection &&
                  <Box className="iGoi_OrderPayBox">

                    <Box className="iGor_setFlex iGor_justifyBetween iGOr_mb-D-none" style={{ padding: '8px 30px' }}>
                      <Box>
                        <Typography variant='body2'>    Total </Typography>
                        {/* <Typography variant='body1' color={'#FF6B6B'}>This account will earn 2300 points </Typography> */}
                      </Box>
                      <Typography variant='body2'>{`${hotelPriceApiLoading == true ? 'loading' : roomGroup?.roundOffTotalRate ? currencyForcheckout + " " + numeral(roomGroup?.roundOffTotalRate).format('0,0') : roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price ? roomTypeSelectedForCheckout?.listprice?.currency_symbol + numeral(roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price).format('0,0') : 'not available'}`}</Typography>
                    </Box>

                    {orderSummarySectionSelected === orderSummarySction && <Button variant='contained' onClick={submitUserDetailsHandler}>{matches ? 'Review Order' : isOfferRooms == true ? 'Book Now' : 'Continue to Payment'} </Button>}

                  </Box>
                }
              </Box>
            </Grid>
          </Grid>}

        {orderSummarySectionSelected === finishSection &&
          <OrderSumFinishCard />
        }
      </Container >

      {orderSummarySectionSelected === paymentSection && roomPriceConverted ? (
        <StripePaymentIntent
          roomPriceConverted={roomPriceConverted * 100}
          currencyForcheckout={currencyForcheckout}
        />
      ) : ('')}
      <Box className="iGor_OrderSumFooter">
        <Footer />
      </Box>
      <ToastContainer />
    </>
  )
}
