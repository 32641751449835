import React, { useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import mapIconIgo from '../../assets/icons/igo_map_icon.svg'
import { env } from '../config/appConfig';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setErrorMessageForDialogueBox, setAlertBoxOnError } from '../../appStore/hotelSlice';
import ArrowUpMap from '../../assets/icons/ArrowMap.svg'
import { useAppSelector } from '../../appStore/hooks';

const MapContainer = ({ latitude, longitude }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapkey = env.mapKeyForGoogleMap;
  const hotelsDetailsLoading = useAppSelector(state => state.hotel.hotelDetails.loading)
  const mapStyles = {
    height: '70vh',
    width: '100%',
  };

  const imageStyles: any = {
    position: 'absolute',
    top: '55vh',
    left: '80vw',
    cursor: 'pointer'
  }

  let defaultCenter = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude)
  };

  let markerLocation = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude)
  }

  useEffect(() => {
    if (latitude && longitude) {
      markerLocation.lat = parseFloat(latitude),
        markerLocation.lng = parseFloat(longitude)
    }

  }, [latitude, longitude])

  const moveToLocationGoogleMap = (coOrdinates: any) => {
    const coOrdinatesMap = coOrdinates;
    if (coOrdinatesMap.lat && coOrdinatesMap.lng) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${coOrdinatesMap.lat},${coOrdinatesMap.lng}`);
    }
    else {
      dispatch(setErrorMessageForDialogueBox('29'))
      dispatch(setAlertBoxOnError(true))
    }
  }

  return (
    <LoadScript
      googleMapsApiKey={mapkey} // Replace with your API key
    >
      {defaultCenter ? (<GoogleMap
        mapContainerStyle={mapStyles}
        zoom={11}
        center={defaultCenter}
      >
        {hotelsDetailsLoading == false && <img src={ArrowUpMap} style={imageStyles} onClick={(e) => window.scrollTo(0, 0)}></img>}
        <Marker onClick={(e) => moveToLocationGoogleMap(markerLocation)} position={markerLocation} icon={mapIconIgo} />
      </GoogleMap>) : 'Loading'}
    </LoadScript>
  );
};

export default MapContainer;
