import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import roomCard_2 from '../../assets/images/roomCard_2.jpg';
import roomCard_1 from '../../assets/images/roomCard_3.jpg';
import roomCard_3 from '../../assets/images/room.jpg';
import { useAppSelector } from '../../appStore/hooks';
import { useDispatch } from 'react-redux';
import { fetchFaviourateFolderDetails, setFolderSelectedDetails, setShowFaviourateList } from '../../appStore/hotelSlice';
import { environmentVariables } from '../../utils/config/environmentVariables';
import FavTripCardSkelton from './FavTripCardSkelton';
import { dateFormatedFaviourateFolder } from '../../utils/utilities/DateConverter';

function formatDateToDayMonth(date: any) {
    const options: any = { day: '2-digit', month: 'short' };
    return new Date(date).toLocaleDateString('en-US', options);
}

const FavTripCard = ({ folderListData }: any) => {
    const dispatch = useDispatch();
    const matches = useMediaQuery('(max-width:1024px)');
    const imagePath = environmentVariables.imagePathApp;
    const { userFavouritesFolderList } = useAppSelector(state => state.hotel)
    const favouriteFolderLoading = userFavouritesFolderList?.loading ? userFavouritesFolderList?.loading : false;

    const getFolderDetailsHanlder = (userId: number, folderId: number, folderName: string) => {
        dispatch(fetchFaviourateFolderDetails({ userId: userId, folderId: folderId }))
        dispatch(setShowFaviourateList(true))
        dispatch(setFolderSelectedDetails({ folderName: folderName, folderId: folderId }))
    }

    return (
        <>
            {favouriteFolderLoading == true ?
                (<><FavTripCardSkelton />
                    <FavTripCardSkelton />
                    <FavTripCardSkelton />
                </>) :
                folderListData.length > 0 ? folderListData.map((folder: any) => {
                    const favouriteFolderImages = folder.images.filter((image: any) => image.includes('property-image'));

                    return (<Box className="favTripCard"
                        key={folder.folderId}
                        onClick={(e) => getFolderDetailsHanlder(folder.userId, folder.folderId, folder.folderName)}
                    >
                        <Box className="favTrip_imgSection">

                            <img src={favouriteFolderImages[0] != '' ? `${imagePath}${favouriteFolderImages[0]}` : roomCard_2} className='favTrip_leftImg' />

                            {!matches && <Box className="favTrip_ImgSctionRight">
                                {favouriteFolderImages.length > 0 ? (
                                    <>
                                        {favouriteFolderImages[1] && (
                                            <img src={`${imagePath}${favouriteFolderImages[1]}`} className='favTrip_rightImg' />
                                        )}
                                        {favouriteFolderImages[2] && (
                                            <img src={`${imagePath}${favouriteFolderImages[2]}`} className='favTrip_rightImg' />
                                        )}
                                    </>
                                ) : (
                                    <>
                                    </>
                                )}
                            </Box>}
                        </Box>
                        <Box marginLeft={!matches ? '15px' : ''}>
                            <Typography className='favTrip_title'>{folder.folderName}</Typography>
                            {matches ? <Typography className='favTrip_date'>
                                <span> Date Details :</span> {dateFormatedFaviourateFolder(folder.createdAt,'created')} - {dateFormatedFaviourateFolder(folder.updatedAt,'updated')}
                            </Typography> :
                                <Typography className='favTrip_date'>
                                    {dateFormatedFaviourateFolder(folder.createdAt,'created')} - {dateFormatedFaviourateFolder(folder.updatedAt,'created')}
                                </Typography>}
                        </Box>
                    </Box>)
                }) : (<></>)}
        </>
    )
}

export default FavTripCard