import React, { useState } from "react";
import { Link, Modal, Box, IconButton, Button, Popover, Typography, TextField, List, ListItem, ListItemAvatar, ListItemText, ListItemIcon } from "@mui/material";
import { Close } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import shareFill from '../../assets/icons/icon_share-fill.svg';
import MailOutline from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook';
import CopyIcon from '@mui/icons-material/ContentCopy';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import proImg from '../../assets/images/room.jpg';
import { env } from "../../utils/config/appConfig";
import { EmailShareButton, FacebookShareButton, FacebookShareCount, WhatsappShareButton } from "react-share";
import ClipboardCopy from 'clipboard-copy';
import ToastSuccessMessageContainer from "../../utils/utilities/ToastSuccesMessageContainer";
import { environmentVariables } from "../../utils/config/environmentVariables";
import ShareIcon from '@mui/icons-material/Share';
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#FfFfFf',
    borderRadius: '15px',
    padding: '0 1rem',
};

function PropertyShareModel({ hotelDetails }: any) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { t } = useTranslation();
    const imagePath = environmentVariables.imagePathApp;
    const shareUrl = window.location.origin + '/property-details/' + hotelDetails?.slug;
    const pathName = window.location.pathname;

    const handleCopyToClipboard = (url: string) => {
        ClipboardCopy(url);
        ToastSuccessMessageContainer(t('1003'))
    }
    
    AppLogHandler('hotelDetailshotelDetails', window.location)
    return (
        <>
            {pathName?.includes('explore') ?
                (<Box className="iGor__btn-icon " onClick={handleOpen}>
                    <ShareIcon className="share" />
                </Box>) :
                (<Button className="btn-outline" variant="outlined" onClick={handleOpen}>
                    <Box className="img-before">
                        <img src={shareFill} alt="Share" className='iGor_btnIcon' />
                    </Box>
                    <span>Share</span>
                </Button>)

            }
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <>
                    <Box sx={style} className='iGor_Modal iGor_proShareModal'>
                        <IconButton onClick={handleClose} className="close-button">
                            <Close />
                        </IconButton>
                        <Box className="iGor_proShareMain">
                            <Typography variant="h4">Share this to</Typography>
                            <Box className="iGor_imgSection">
{                            hotelDetails?.image?.pathIMG ?
                                <img src={`${imagePath +  hotelDetails?.image?.pathIMG }`} alt="img" className='iGor_proimg' />
                                :<img src={`${imagePath + hotelDetails?.bg_image}`} alt="img" className='iGor_proimg' />}

                                <Typography variant="subtitle2">{hotelDetails?.name ? hotelDetails?.name : hotelDetails?.title }</Typography>
                            </Box>
                            <Box className="iGor_sharebtnBox">
                                <IconButton className="iGor_sharebtn" onClick={() => handleCopyToClipboard(shareUrl)}>
                                    <CopyIcon /> <Typography variant="subtitle1">Copy Link </Typography>
                                </IconButton>
                                <EmailShareButton url={shareUrl}>
                                    <IconButton className="iGor_sharebtn">
                                        <MailOutline /> <Typography variant="subtitle1"> Email</Typography>
                                    </IconButton>
                                </EmailShareButton>
                            </Box>
                            <Box className="iGor_sharebtnBox" mt={'11px'}>
                                <FacebookShareButton url={shareUrl}>
                                    <IconButton className="iGor_sharebtn">
                                        <FacebookIcon className="iGor_shareFb" />
                                        <Typography variant="subtitle1"> Facebook</Typography>
                                    </IconButton>
                                </FacebookShareButton>
                                <WhatsappShareButton url={shareUrl} title="Check out this link!">
                                    <IconButton className="iGor_sharebtn">
                                        <WhatsAppIcon className="iGor_shareWhat" />
                                        <Typography variant="subtitle1"> Whatsapp </Typography>
                                    </IconButton>
                                </WhatsappShareButton>
                            </Box>
                        </Box>
                    </Box>
                </>
            </Modal>
        </>
    );
}

export default PropertyShareModel;