import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuList, Box, Typography, TextField, Grid } from "@mui/material";
import SearchIcon from '../../assets/icons/search_Icon.svg';
import { DatePickerComp } from '../inputFieldCustom/DatePicker';
import ArrowRightAltIcon from '../../assets/icons/Icon_arrow-right.svg';
import { useTranslation } from 'react-i18next';
import aloneIcon from '../../assets/icons/arrow_alone.svg';
import { useAppSelector } from '../../appStore/hooks';
import { useInitApiMutation } from '../../services/api/hotelApi'
//import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { fetchGetFiltersDataFromApi, fetchHotesResultsDataFromApi, setHotelsTypeTabSelection, setLoadHotelResultSkeleton, setOfferHotelsDataReset, setResetRoomArray, setRoomArray, setSearchDestinationData, setSearchDestinationFromDate, setSearchDestinationToDate, setSearchHotelsResultCall } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import initApiCall from '../../services/initApiCall';
import DestinationRoomAddition from './DestinationRoomAddition';
import DestinationAddRoomBtn from './DestinationAddRoomBtn';
import DestinationMenuName from './DestinationMenuName';
import DestinationNightCount from './DestinationNightCount';
import { EventNamesForEventLogs, countryResidenceAndCulture, eventNameForMoeEvents } from '../../utils/constants/constant';
import { PinkSwitch } from '../../utils/constants/SliderColourTheme';
import Icon_searchRed from '../../assets/icons/Icon_searchRed.svg';
import { convetDateForInitApiCall, dateConvertForExpiredCheckOut, dateFormatedForUrls, formatDateOnSearchResultPage, getThreeMonthsLaterDate } from '../../utils/utilities/DateConverter';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { AdultCount, ChildCount } from '../../utils/handlers/arrayLoop';
import { EventLogHanlderMoengage, EventLogsHandler } from '../../utils/handlers/EventLogsHandler';
import { searchValuesDispatch } from './SearchValuesDispatch';
import { environmentVariables } from '../../utils/config/environmentVariables';

export const DestinationAutocomplete = ({ setclass, showOpen, stepNumUpdate, stepNumber, stepSecond, setstepSecond }: any) => {
    // t object for language translation for keys
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [stepFirst, setstepFirst] = useState(false);
    const [searchMSg, setSearchMsg]: any = useState(t("Where do you want to go?"))
    const [destinVal, setDestinVal] = useState('');
    const [checkMsg, setcheckMsg] = useState(false);
    const [switchBtn, setswitchBtn] = useState(false);
    const anchorRef = useRef(null);
    const [showResults, setShowResults] = useState(false);
    const { searchHotelsResult, noOfNights, loggedInUserCurrency, initApi, destinationsData, roomArray, searchDestinationFromDate, searchDestinationToDate } = useAppSelector(state => state.hotel)
    const [destinationObj, setDestinationObj] = useState(destinationsData);
    const [roomArrayForGuest, setRoomArrayForGuest] = useState(roomArray);
    const environment = environmentVariables.enviornment;

    const todaysDate = new Date().toISOString();
    const tommorowsDate = new Date((new Date()).setDate((new Date()).getDate() + 1)).toISOString();
    const threeMonthsLaterTodaysDate = getThreeMonthsLaterDate(todaysDate);
    const threeMonthsLaterTommorowsDate = getThreeMonthsLaterDate(tommorowsDate);
    const [startDateForDestination, setStartDateForDestinationSearch] = useState(environment == 'PROD' ? todaysDate : threeMonthsLaterTodaysDate);
    const [endDateForDestination, setEndDateForDestinationSearch] = useState(environment == 'PROD' ? tommorowsDate : threeMonthsLaterTommorowsDate);
    const [numberOfNights, setNumberOfNights] = useState(noOfNights);
    const searchId = initApi?.searchId;
    const tracingKey = initApi?.searchTracingKey
    const destinationSearchStartDate = useAppSelector(state => state.hotel?.searchDestinationFromDate);
    const destinationSearchEndDate = useAppSelector(state => state.hotel?.searchDestinationToDate);
    const formatedFromDate = formatDateOnSearchResultPage(destinationSearchStartDate, 'search')
    const formatedToDate = formatDateOnSearchResultPage(destinationSearchStartDate, 'search')
    const destinationSelectedData = useAppSelector(state => state.hotel.destinationsData)
    const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
    const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
    const countryResidenceAndCultureInit = countryResidenceAndCulture;
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };
    const matches = useMediaQuery('(max-width:1024px)');
    const [animation, setAnimation] = useState('');
    const adultsCount = AdultCount(roomArrayForGuest);
    const childsCount = ChildCount(roomArrayForGuest);
    const eventNameSearch = EventNamesForEventLogs.hotelSearch;
    const eventNameSearchMoe = eventNameForMoeEvents.Search;


    const handleToggle = () => {

        setstepFirst(false);
        setstepSecond(true);
        setcheckMsg(false)
        setOpen((prevOpen) => !prevOpen);
        { matches && setclass(true) }
        setAnimation('iGor_animation_1')

        // stepNumUpdate(0, 'increment');
    };

    const setTextFieldValue = (e: any) => {
        setSearchMsg("When and how long is your trip?")
        setDestinVal(e)
    }

    const onAloneHandler = (e: any) => {
        setswitchBtn(e.target.checked)
    }
    useEffect(() => {
        // Update the 'open' state when 'showOpen' prop changes

        setOpen(showOpen);
    }, [showOpen]);

    useEffect(() => {
        if (stepNumber === 0) {
            setstepFirst(false);
            setstepSecond(true);
        }

    }, [stepNumber]);

    useEffect(() => {
        if (switchBtn == true) {
            let arr = [{
                adults: 1,
                children: 0,
                childAges: [],
            }]
            setRoomArrayForGuest(arr)
        }
        else {
            let arr = [{
                adults: 2,
                children: 0,
                childAges: [],
            }]
            setRoomArrayForGuest(arr)
        }
    }, [switchBtn])
    const fromDate = new Date(startDateForDestination)
    const toDate = new Date(endDateForDestination)

    let startMonth = fromDate.toLocaleString('default', {
        month: 'long',
    });
    let endMonth = toDate.toLocaleString('default', {
        month: 'long',
    });
    let startDay = fromDate.getDate()
    let endDay = toDate.getDate()

    useEffect(() => {
        if (searchDestinationToDate) {
            // setEndDateForDestinationSearch(new Date(searchDestinationToDate))
        }
        if (open == false) {
            setSearchMsg("Where do you want to go?")
        }
    }, [searchDestinationToDate, open])

    // useEffect(() => {
    //     if (searchId && searchHotelsResult == true) {
    //         dispatch(fetchHotesResultsDataFromApi(searchId));
    //         navigate(`/search-results?destinationName=${destinationSelectedData.destinationName}&destinationId=${locationId}&checkIn=${checkInAndCheckoutDatesParams.checkInDate}&checkout=${checkInAndCheckoutDatesParams.checkOutDate}&guestArray=${serializedGuestArray}&currency=${loggendInUserCurrency}`)
    //     }
    // }, [searchId, searchHotelsResult])

    // hotel check availability handler
    const onCheckHandler = async () => {
        const searchDataDispatched = await dispatch(searchValuesDispatch(
            numberOfNights,
            roomArrayForGuest,
            startDateForDestination,
            endDateForDestination,
        ))
        dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
        dispatch(setSearchDestinationData(destinationObj));
        let locationId = destinationObj?.destinationCode
        const destinationName = destinationObj.destinationName?.replaceAll(" ", "-")
        const checkIndateConvetedParams = startDateForDestination;
        const checkOutDateParams = endDateForDestination;
        const checkInAndCheckoutDatesParams = {
            checkInDate: dateFormatedForUrls(checkIndateConvetedParams),
            checkOutDate: dateFormatedForUrls(checkOutDateParams)
        }
        // event log to analytics
        const eventIdsForLogEvents = {
            "ig_content_id": locationId,
            "ig_date_a": formatedFromDate,
            "ig_date_b": formatedToDate,
            "ig_destination_a": destinationName,
            "ig_num_adults": adultsCount,
            "ig_num_children": childsCount,
        }
        EventLogsHandler(eventNameSearch, eventIdsForLogEvents)

        // event logs for Moengage
        const eventIdsForLogEventsMoe = {
            "Search term": destinationName,
            "Booking date start": new Date(startDateForDestination),
            "Booking date finish": new Date(endDateForDestination),
            "Number of guest": adultsCount + childsCount
        }
        EventLogHanlderMoengage(eventNameSearchMoe, eventIdsForLogEventsMoe)
        // dispatch(setHotelsTypeTabSelection('ALL'))
        // const payload = initApiCall(loggendInUserCurrency, destinationSearchStartDate, destinationSearchEndDate,
        //     roomArray, countryResidenceAndCultureInit.countryOfResidence, countryResidenceAndCultureInit.nationality,
        //     countryResidenceAndCultureInit.culture, locationId, loggedInUserId)
        // iniApiCall({ payload })
        dispatch(setLoadHotelResultSkeleton(true))
        const serializedGuestArray = encodeURIComponent(JSON.stringify(roomArrayForGuest));
        // const searializeDestinationObj = encodeURIComponent(JSON.stringify(destinationSelectedData))
        navigate(`/search-results?destinationName=${destinationName}&destinationId=${locationId}&checkIn=${checkInAndCheckoutDatesParams.checkInDate}&checkout=${checkInAndCheckoutDatesParams.checkOutDate}&currency=${loggendInUserCurrency}`)
        dispatch(setSearchHotelsResultCall(true))
        setShowResults(true)
    }



    AppLogHandler("tracingkey", { "destinationdata": destinationObj, "tracingkey": tracingKey, "searchId": searchId })

    return (
        <>

            {stepNumber === 0 || !matches ?
                <Box ref={anchorRef} onClick={handleToggle} className={"InputField iGor_DestAutoTitBtn " + (stepNumber != 0 && ' iGor__MbSerInput')}>
                    <img src={matches ? Icon_searchRed : SearchIcon} alt='SearchIcon' />
                    <TextField value={destinVal} placeholder={searchMSg} autoComplete='off' onChange={(e) => setTextFieldValue(e.target.value)}>
                    </TextField>
                </Box>
                : <Box ref={anchorRef} className={"InputField iGor_DestAutoTitBtn " + (stepNumber != 0 && ' iGor__MbSerInput')}>
                    <Typography fontWeight={600} fontSize={'12px'} color={'#FF6B6B'} mx={'auto'}>{stepNumber === 1 ? 'How long is your trip?' : 'Guest & Room details'}</Typography>
                </Box>
            }
            {/* <Box className={`InputField iGor_DestAutoTitBtn ${stepFirst! && matches? 'iGor__textFieldMb':''  }`}>
                    <img src={SearchIcon} alt='SearchIcon' />
                    <TextField value={destinVal} placeholder={searchMSg} autoComplete='off' onChange={(e) => setTextFieldValue(e.target.value)}>
                    </TextField>
            </Box> */}
            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal className={!stepFirst ? 'iGor_DestAutoMain' : !stepSecond ? 'iGor_DestAutoMain iGor__DestImAlone' : 'iGor_DestAutoMain'} >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => `${setOpen(() => matches ? true : false)} ${setcheckMsg(false)}`} >
                                <div className={"iGor_animation " + (!stepSecond ? roomArrayForGuest.length > 1 || roomArrayForGuest[0].children >= 1 ? " " : animation : animation)}  >
                                    {!stepFirst &&
                                        <Box sx={{ position: "relative", height: '100%', overflow: 'hidden' }}>
                                            <Typography variant='subtitle1' className='iGor_DestiTitle'  > {t("Popular Destinations")}</Typography>
                                            <Box className='iGor_DestiListSection'>
                                                <MenuList
                                                    autoFocusItem={open}
                                                    className='iGor_DestiList'
                                                >
                                                </MenuList>
                                                <DestinationMenuName
                                                    initialValue={destinVal}
                                                    onTextChange={setTextFieldValue}
                                                    setstepFirst={setstepFirst}
                                                    setcheckMsg={setcheckMsg}
                                                    setSearchMsg={setSearchMsg}
                                                    setAnimation={setAnimation}
                                                    stepSecond={!stepSecond}
                                                    stepNumUpdate={stepNumUpdate}
                                                    setDestinationObj={setDestinationObj}
                                                    setDestinVal={setDestinVal}
                                                />
                                            </Box>
                                        </Box>}
                                    {stepFirst &&
                                        <Box sx={{ textAlign: 'center' }} className='iGor__roomAddSeciton'>
                                            <Box className={stepSecond ? 'iGor_Desti_header ' : 'iGor_Desti_header iGor_Desti_border'}  >
                                                <div className='iGor_item'>
                                                    <Typography variant='subtitle2' className='iGor_sub'>{t("Destination")}</Typography>
                                                    <Typography className='iGor_title' onClick={() => { setAnimation('iGor_animation_1'); setstepFirst(!stepFirst); setSearchMsg(t("Where do you want to go?")); setstepSecond(true); setswitchBtn(false) }} >{destinationObj.destinationName}</Typography>
                                                </div>
                                                <img src={ArrowRightAltIcon} className="iGor_rightIcon" />
                                                <div className='iGor_item iGor_DatesBox'>
                                                    <Typography className='iGor_sub'>{t("Dates")}</Typography>
                                                    <Typography className='iGor_title' onClick={() => { setAnimation('iGor_animation_2'); setstepSecond(true); setSearchMsg(t('When and how long is your trip?')); setswitchBtn(false) }}>{
                                                        stepSecond ? t("Choose date") : `${startDay} ${startMonth.substring(0, 3)} - ${endDay} ${endMonth.substring(0, 3)}`}</Typography>
                                                </div>
                                                {stepSecond ? <></> : <><img src={ArrowRightAltIcon} className="iGor_rightIcon iGor_rightIcon2" /> <div className='iGor_item'>
                                                    <Typography className='iGor_sub'>{t("Room")} </Typography>
                                                    <Typography className='iGor_title'> {`${roomArrayForGuest.length} ${roomArrayForGuest.length == 1 ? 'Room' : 'Rooms'}`}</Typography>
                                                </div></>}
                                            </Box>
                                            {stepSecond || stepNumber === 1 ?
                                                <DestinationNightCount
                                                    numberOfNights={numberOfNights}
                                                    setNumberOfNights={setNumberOfNights}
                                                    endDateForDestination={endDateForDestination}
                                                    setEndDateForDestinationSearch={setEndDateForDestinationSearch}
                                                />
                                                : <></>
                                            }
                                            <Box className='iGor__ImAloneBox'>
                                                {stepSecond || stepNumber === 1 ?
                                                    <DatePickerComp
                                                        setNumberOfNights={setNumberOfNights}
                                                        startDateForDestination={startDateForDestination}
                                                        endDateForDestination={endDateForDestination}
                                                        setEndDateForDestinationSearch={setEndDateForDestinationSearch}
                                                        setStartDateForDestinationSearch={setStartDateForDestinationSearch}
                                                        setSearchMsg={setSearchMsg}
                                                    />
                                                    : <>
                                                        <Grid container >
                                                            <Grid item xs={6} sm={6} sx={{ textAlign: 'left', margin: ' 0.625rem 0' }} >
                                                                <Typography variant='subtitle1' className='iGo__titleImalone'>{t("I’m alone")}  </Typography>
                                                            </Grid>
                                                            <Grid item xs={6} sm={6} sx={{ textAlign: 'right' }}>
                                                                <PinkSwitch {...label} className='iGor__ImAloneBtn' onChange={(e) => onAloneHandler(e)} />
                                                            </Grid>
                                                        </Grid>
                                                        {!switchBtn && <>
                                                            <Box className='iGor_d_roomBtnSectiom'>
                                                                <DestinationRoomAddition
                                                                    setRoomArrayForGuest={setRoomArrayForGuest}
                                                                    roomArrayForGuest={roomArrayForGuest}
                                                                />
                                                            </Box>
                                                            <Grid container spacing={1} className='iGor_mb_BtnAddSection'>
                                                                <Grid item sm={12}>
                                                                    <DestinationAddRoomBtn
                                                                        setRoomArrayForGuest={setRoomArrayForGuest}
                                                                        roomArrayForGuest={roomArrayForGuest}
                                                                    />
                                                                </Grid>
                                                            </Grid> </>}
                                                        {switchBtn &&
                                                            <Box className='iGor__ImConentBox' >
                                                                <img src={aloneIcon} alt='arrow alone' className='aloneIcon' />
                                                                {matches ? <Typography variant='body1' className='iGor__ImAloneContent'>
                                                                    You never really travel alone.<br />
                                                                    The world is full of friends waiting to get to know you!
                                                                </Typography>
                                                                    :
                                                                    <Typography variant='body1' className='iGor__ImAloneContent'>
                                                                        {t("You never really travel alone")}
                                                                    </Typography>}
                                                            </Box>
                                                        }
                                                    </>
                                                }
                                                {stepSecond &&
                                                    <Button variant='contained' className={'iGor_mb_Btn iGor__conBtn ' + (stepNumber == 1 ? " iGor__conBtnwithAddRoomBtn" : '')} onClick={() => { stepNumUpdate(2, 'increment'); setstepSecond(false); setSearchMsg(t("How many guest & room?")); setAnimation('iGor_animation_3') }}>{matches ? t("Submit") : t("Continue")}</Button>}
                                                {!stepSecond && <>
                                                    <Button variant='contained' className='iGor_mb_Btn' onClick={onCheckHandler}>{matches ? t("Submit") : t("Check Availability")}</Button>
                                                </>
                                                }
                                            </Box>
                                        </Box>}
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};