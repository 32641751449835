import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useState } from "react";
import axios from "axios";
import { generateToken } from "./PayPalApis";
import { env } from "../../utils/config/appConfig";
import { EventNamesForEventLogs, amountOBJPayPalOrder, applicationContextOBJ, itemsOBJPayPalOrder, payPalHeaders, paymentsErrors } from "../../utils/constants/constant";
import { Button } from "@mui/material";
import { createOrderBodyPayPAl } from "../../utils/constants/types";
import { useTranslation } from "react-i18next";
import { AppLogHandler } from "../../utils/handlers/appLogsHandler";
import paypalImg from '../../assets/images/paypal.png'
import { Box } from "@mui/system";
import { EventLogsHandler } from "../../utils/handlers/EventLogsHandler";
import { useDispatch } from "react-redux";
import { setErrorMessageForDialogueBox, setAlertBoxOnError } from "../../appStore/hotelSlice";
import PaymentPopUp from "./PaymentPopUp";
import { isDateWithinThreeMonthsFromNow } from "../../utils/utilities/DateConverter";
import { useAppSelector } from "../../appStore/hooks";
import { CheckAllMatchFulfilledForBooking } from "../../pages/Order Summary/CheckAllMatchFulfil";

function PayPalGateWay({ currencyForcheckout, roomPriceSelected, refundability }: any) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const baseURL = env.baseURLPayPal;
    const style: any = { "layout": "vertical" };
    const [createdOrderDetails, setCreatedOrderDetails]: any = useState()
    const { searchDestinationFromDate } = useAppSelector(state => state.hotel)
    let headers: any = payPalHeaders;
    const eventNamePayNow = EventNamesForEventLogs.payNow;
    const currencyPaymentError = paymentsErrors.currencyNotSupported;

    // assigning price value to amount obj
    let amountObj: any = amountOBJPayPalOrder;
    amountObj.value = roomPriceSelected?.toString();
    amountObj.currency_code = currencyForcheckout;
    amountObj.breakdown.item_total.value = roomPriceSelected?.toString();
    amountObj.breakdown.item_total.currency_code = currencyForcheckout;
    amountObj.breakdown.currency_code = currencyForcheckout;

    //assigning price to items obj
    let itemsOBJPayPalOrdersss: any = itemsOBJPayPalOrder
    itemsOBJPayPalOrdersss[0].unit_amount.value = roomPriceSelected?.toString();
    itemsOBJPayPalOrdersss[0].unit_amount.currency_code = currencyForcheckout;

    let createOrderBody: createOrderBodyPayPAl = {
        intent: 'AUTHORIZE',
        purchase_units: [{
            items: itemsOBJPayPalOrder,
            amount: amountObj,
        }],
        application_context: applicationContextOBJ
    }

    // payment handler for paypal
    const continueToPaymentHandler = async () => {
        const isThreeeMonthLaterDate = isDateWithinThreeMonthsFromNow(searchDestinationFromDate)
        if (refundability == "Refundable" && isThreeeMonthLaterDate == true) {
            createOrder()
        }
        else if (refundability == "NonRefundable") {
            const res = await dispatch(CheckAllMatchFulfilledForBooking
                (true, '21'))
        }
        else if (isThreeeMonthLaterDate == false) {
            const res = await dispatch(CheckAllMatchFulfilledForBooking
                (true, '36'))
        }

        // event log to analytics
        EventLogsHandler(eventNamePayNow, { ig_content_id: "id1234" })
    }

    //    function for generate token and create order 
    const createOrder: any = async (data: any) => {
        const accessToken = await generateToken()
        headers.Authorization = `Bearer ${accessToken}`;
        sessionStorage.setItem('acceess-Token', accessToken)

        // create order api call
        try {
            const response = await axios.post(`${baseURL}/v2/checkout/orders`, createOrderBody, {
                headers: headers
            }).then((response) => response.data).
                catch((error: any) => {
                    const errorMessage = error?.response?.data?.message
                    if (errorMessage.includes(currencyPaymentError)) {
                        dispatch(setErrorMessageForDialogueBox('37'))
                        dispatch(setAlertBoxOnError(true))
                    }
                    else {
                        dispatch(setErrorMessageForDialogueBox('17'))
                        dispatch(setAlertBoxOnError(true))
                    }
                })
            setCreatedOrderDetails(response)
            return response;

        } catch (error: any) {
            const errorMessage = error.message
            window.alert(errorMessage)
        }
    }

    // navigating to complete payment paypal ui
    if (createdOrderDetails?.links) {
        const approvalURL = createdOrderDetails?.links?.find((link: any) => link.rel === 'approve').href;
        window.location.href = approvalURL;
    }

    AppLogHandler('PaypalGateWays Page', {
        'createOrderBody': createOrderBody,
    })

    return (<>
        {/* <PaymentPopUp openPopUp= {true}/> */}
        <Button variant="contained" onClick={continueToPaymentHandler} className="iGor__payNowBtn">Pay Now</Button>
        {/* <img src={paypalImg} height={'110px'} width={'350px'} style={{cursor:'pointer'}} onClick={createOrder}></img> */}
        {/* <Button className="Igo_paypal-button" onClick={createOrder}>PayPal</Button> */}
    </>);
}

export default PayPalGateWay;