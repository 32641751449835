import { useDispatch } from "react-redux";
import { setSearchDestinationData } from "../../appStore/hotelSlice";
import { useAppSelector } from "../../appStore/hooks";
import { MenuItem, useMediaQuery } from "@mui/material";
import locationIcon from '../../assets/icons/location-pin.svg';
import iconMappin from '../../assets/icons/icon_map-pin.svg';
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { EventLogHanlderMoengage } from "../../utils/handlers/EventLogsHandler";
import { eventNameForMoeEvents } from "../../utils/constants/constant";


interface DestinationForSearch {
  destinations: any[]; // Update this to match your data structure
}
// Component to display names of destinations fetched from API
const DestinationMenuName = ({ initialValue, onTextChange, setstepFirst, setcheckMsg, setSearchMsg, setAnimation, stepSecond
  , setAnchorEl, stepNumUpdate, setDestinationShow, setDestinationObj, closeGuestPickerHander, setDestinVal }: any) => {
  const dispatch = useDispatch();
  const matches = useMediaQuery('(max-width:1024px)');
  const pathname = window.location.pathname;
  // Use type annotation for destinationsApiData
  const destinationsApiData: DestinationForSearch = useAppSelector(state => state.hotel.destinationForSearch);
  const eventNameViewScreenMoe = eventNameForMoeEvents.ViewScreen;


  // Check if destinationsApiData and destinations are loaded
  if (!destinationsApiData?.destinations) {
    // Handle the loading state here, e.g., display a loading indicator
    return <p>Loading destinations...</p>;
  }


  // Use initialValue if it's defined; otherwise, use an empty string
  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    setInputValue(initialValue); // Use an empty string as a default
  }, [initialValue, destinationsApiData.destinations]);

  const menuHandler = (event: any, data: any) => {
    if(setDestinVal)setDestinVal('')
    if (closeGuestPickerHander) closeGuestPickerHander()
    if (setDestinationObj) setDestinationObj({ destinationName: data.name, destinationCode: data?.countryCode })
    if (setAnimation) setAnimation(stepSecond ? 'iGor_animation_3' : 'iGor_animation_2');
    if (setcheckMsg) setcheckMsg(true);
    if (setAnchorEl) setAnchorEl(null);
    if (stepNumUpdate) stepNumUpdate(0, 'increment');
    if (setSearchMsg) setSearchMsg("When and how long is your trip?")
    if (setDestinationShow) setDestinationShow(false)
    if (setstepFirst && !matches) { setstepFirst(true); }
    else if(matches && pathname !== 'search-results'){setstepFirst(true);}
    else if (matches && pathname == 'search-results' && setstepFirst) {
      setstepFirst(false)
    }

    // event logs for Moengage
    const eventIdsForLogEventsMoe = {
      "Destination Screen": "Destination Screen"
    }
    EventLogHanlderMoengage(eventNameViewScreenMoe, eventIdsForLogEventsMoe)
  }


  // Filter destinations based on inputValue or display all if initialValue is not provided
  const filteredDestinations = inputValue
    ? destinationsApiData.destinations.filter((destination: any) =>
      destination?.name?.toLowerCase().includes(inputValue.toLowerCase())
    )
    : destinationsApiData.destinations;

  return (
    <>
      {filteredDestinations.length>0 ? filteredDestinations.map((destination: any, index: any) => {
        return (
          <MenuItem
            key={index}
            onClick={(event: any) => {
              menuHandler(event, destination);
            }}
            value={destination?.name}
            style={{ fontSize: '14px' }}
          >
            <img src={matches ? iconMappin : locationIcon} alt='locationIcon' />
            {destination?.name}
          </MenuItem>
        );
      }) : (<Box className='Igo_Nomatch_destinations'>No match found for destinations</Box>)}
    </>
  );
};

export default DestinationMenuName;
