import React, { useEffect, useRef } from 'react';
 
const DisableSwipe = () => {
  const startX = useRef(null);
  const startY = useRef(null);
 
  useEffect(() => {
    const handleTouchStart = (event:any) => {
      startX.current = event.touches[0].clientX;
      startY.current = event.touches[0].clientY;
    };
 
    const handleTouchMove = (event:any) => {
      if (startX.current && startY.current && event.touches.length) {
        const moveX = event.touches[0].clientX;
        const moveY = event.touches[0].clientY;
        const deltaX = moveX - startX.current;
        const deltaY = moveY - startY.current;
 
        // If the swipe is mostly vertical, don't prevent default
        if (Math.abs(deltaY) > Math.abs(deltaX)) {
          startX.current = null;
          return;
        }
 
        // Prevent left-to-right swiping
        if (deltaX > 0) {
          event.preventDefault();
        }
      }
    };
 
    const disableLeftToRightSwipe = () => {
      document.addEventListener('touchstart', handleTouchStart, { passive: true });
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
    };
 
    const enableSwipe = () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
 
    disableLeftToRightSwipe();
 
    return () => {
      enableSwipe();
    };
  }, []);
 
  return <div>{/* Your component content */}</div>;
};
 
export default DisableSwipe;
 