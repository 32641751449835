import { combineReducers, configureStore } from '@reduxjs/toolkit'
import hotelReducer from './hotelSlice'
import { api } from '../services/hotelBookingApi'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import ttlMiddleware from './ttlMiddleware'; // Import the TTL middleware

const rootPersistConfig = {
  key: 'root',
  storage,
}

const userPersistConfig = {
  key: 'user',
  storage: storageSession,
}

const rootReducer = combineReducers({
  [api.reducerPath]: persistReducer(userPersistConfig, api.reducer),
  hotel: hotelReducer,
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(
      {
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
      .concat(api.middleware, ttlMiddleware), // Add the TTL middleware here
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
