import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "../pages/home/Home";
import PagesNotFound from '../pages/PageNotFound/PageNotFound';
import { LatestOffers } from "../pages/LatestOffers/LatestOffers";
import { Explore } from "../pages/Explore/Explore";
import { SearchResults } from "../pages/SearchResults/SearchResults";
import { PropertyDetails } from "../pages/PropertyDetails/PropertyDetails";
import { OrderSummary } from "../pages/Order Summary/OrderSummary";
import { AccountDetails } from "../pages/AccountDetails/AccountDetails";
import { OrderSumFinishCard } from "../components/OrderSummary/OrderSumFinishCard";
import ConfirmPayments from "../components/Payments/ConfirmPayment";
import AboutUS from "../pages/home/AboutUS";
import TermsAndConditions from "../pages/home/TermsAndConditions";
import PrivacyPolicies from "../pages/home/PrivacyPolicy";
import ExploreMore from "../pages/Explore/ExploreMore";
import Highlight from "../pages/Explore/Highlight";
import Regions from "../pages/Explore/Regions";
import MyTrip from "../pages/MyTrip/MyTrip";
import MyFavourites from "../pages/Favourites/MyFavourites";
import FacebookDataPolicies from "../pages/home/FacebookDataPolicy";
import PropertyDetailsView from "../components/PropertyDetailsView/PropertyDetailsView";
import MobileInbox from "../components/mobileComponents/MobileInbox";
import AdvancedFilterMod from "../components/popUpSection/AdvancedFilterMod";

function MainRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/latest-offers" element={<LatestOffers />} />
            <Route path="/favourites" element={<MyFavourites />} />
            <Route path="/explore" element={<Explore />} />
            <Route path="/search-results" element={<SearchResults />} />
            <Route path="/property-details/:slugId" element={<PropertyDetails />} />
            <Route path="/order-summary" element={<OrderSummary />} />
            <Route path="/confirm-payments" element={<ConfirmPayments />} />
            <Route path="/booking-confirmed" element={<OrderSumFinishCard />} />
            <Route path="/account-details" element={<AccountDetails />} />
            <Route path="/filters" element={<AdvancedFilterMod/>}/>
            <Route path="/about-us" element={<AboutUS />} />
            <Route path="/privacy-policy" element={<PrivacyPolicies />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
            <Route path="/fbdatadeletion-instruction" element={<FacebookDataPolicies />} />
            <Route path="/explore/:id" element={<ExploreMore />} />
            <Route path="/explore/:id/:slug" element={<Highlight />} />
            <Route path="/explore/:id/regions/:slug" element={<Regions />} />
            <Route path="/my-trips" element={<MyTrip />} />
            <Route path="/property-details-view" element={<PropertyDetailsView />} />
            <Route path="/inbox" element={<MobileInbox />} />
            <Route path="*" element={<PagesNotFound />} />
        </Routes>
    );
}

export default MainRoutes;