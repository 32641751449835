import React, { useEffect, useState } from 'react';
import { Box, Button, Skeleton, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../appStore/hooks';
import Slider from 'react-slick';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { fetchExploreDestinationsApi, fetchExploreHotelsToStay, setExploreApiDataReset, setExploreHotelsToStayReset } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
 

function NextArrow(props: any) {
  const { className, style, onClick } = props;

  return (
    <ArrowForwardIcon
      className={className}
      style={{
        ...style, display: "block", color: "#ffffff", border: '1.5px solid',
        borderRadius: '50px'
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  return (
    <ArrowBackIcon
      className={className}
      style={{
        ...style, display: "block", color: "#ffffff", border: '1.5px solid',
        borderRadius: '50px'
      }}
      onClick={onClick}
    />
  );
}

const ExploreCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const destinations = useAppSelector(state => state.hotel.destinationForSearch)
  const destinationsToExplore = destinations?.destinations ? destinations?.destinations : ['No Destinations Available To Explore']
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    // Reset imageLoaded state on component unmount
    return () => {
      setImageLoaded(false);
    };
  }, []);

  const ExploreMore = (exploreDestination: any) => {
    const destinationName = exploreDestination.name.replaceAll(" ","-")
    const countryCode = exploreDestination.countryCode + "-" + destinationName.toLowerCase();
    dispatch(setExploreApiDataReset({ data: [], loading: false, error: null }))
    dispatch(setExploreHotelsToStayReset({ data: [], loading: false, error: null }));
    navigate(`/explore/${countryCode}`);
  };

  const handleImageLoad = () => {
    // Set imageLoaded to true when the image is loaded
    setTimeout(()=>{setImageLoaded(true)},1000)
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: '0px',
          variableWidth: true,
          adaptiveHeight: true,
        }
      },
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  return (
    <>
      <Slider {...settings} className="iGo__ExploreSilder">
        {destinationsToExplore.map((exploreToDestination: any) => (
          <Box className="iGor_card-explorer" key={exploreToDestination.name}>
            <Box className="iGor_image">
              <Box className="img fit">
            
                {!imageLoaded && (
                   <Skeleton variant="rectangular" width={400} height={330}   sx={{borderRadius: '0.625rem', bgcolor: 'grey.600' }}/>
                )}
             
                <img
                  src={exploreToDestination.exploreBg}
                  alt={exploreToDestination.name}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    display: imageLoaded ? 'block' : 'none'
                  }}
                  onLoad={handleImageLoad}
                />
              </Box>
              <Box className="iGor_text">
                <h3>{exploreToDestination.name}</h3>
              </Box>
            </Box>
            <Box className="iGor_body">
              <Typography className="iGor_title">{exploreToDestination.name}</Typography>
              <Typography className="iGor_description">
                {exploreToDestination.description ?
                  exploreToDestination.description : 'Description not available for this destination'}
              </Typography>
              <Button variant="contained" className='iGor__ExploreBtn' onClick={(e) => ExploreMore(exploreToDestination)}>
                Explore More
              </Button>
            </Box>
          </Box>
        ))}
      </Slider>
    </>
  )
}

export default ExploreCard;
