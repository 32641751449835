import { useDispatch } from "react-redux"
import { useAppSelector } from "../../appStore/hooks"
import { setRoomArray } from "../../appStore/hotelSlice"
import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Box, Typography, ButtonGroup, Grid, Slider, Switch, useMediaQuery } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
// return the button on click of which rooms are added
const DestinationAddRoomBtn = ({ setRoomArrayForGuest, roomArrayForGuest }: any) => {
    const { t } = useTranslation();
    const roomArray: any = useAppSelector(state => state.hotel.roomArray);
    const matches = useMediaQuery('(max-width:1024px)');
    const dispatch = useDispatch()
    const onAddRoomHandler = () => {
        let arr = [...roomArrayForGuest]
        arr.push({
            adults: 2,
            children: 0,
            childAges: [],
        })
        { roomArrayForGuest.length < 5 && setRoomArrayForGuest(arr) }
    }

    return (
        roomArrayForGuest.length == 5 ? (<Typography className={matches ? "iGor_room-Exceed_mb" : "iGor_room-Exceed"}>{t('5012')}</Typography>) : (
            <Button className='iGor_mb_BtnAdd' variant='contained' sx={{ background: '#DCDCDC', margin: '0.625rem 0', color: '#000000' }} onClick={onAddRoomHandler}>
                <AddCircleOutlineIcon sx={{ width: '16px', height: '16px', marginRight: '10px' }} />  {t("Add room")}
            </Button>
        )
    )
}

export default DestinationAddRoomBtn