import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { useAppSelector } from '../../appStore/hooks';
import { useMediaQuery } from '@mui/material';
import { dateConverterCancellationPolicies } from '../../utils/utilities/DateConverter';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingLeft: theme.spacing(3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function PropertyDAccordion(props: any) {
    const { roomTypeSelectedForCheckout, selectedCurrencySymboll } = useAppSelector(state => state.hotel);
    const roomSelected = roomTypeSelectedForCheckout;
    const currencySelected = roomTypeSelectedForCheckout.listprice?.currency_symbol ? roomTypeSelectedForCheckout.listprice?.currency_symbol : selectedCurrencySymboll;
    const [expanded, setExpanded] = React.useState<string | false>('');
    const { hotelRoomPriceApiData } = useAppSelector(state => state.hotel);
    const hotelRoomPrice = hotelRoomPriceApiData.data;
    const [roomGroup] = hotelRoomPrice?.roomGroup ? hotelRoomPrice?.roomGroup : []
    const hotelDetails = useAppSelector(state => state.hotel.hotelDetails?.data?.hotel);
    const pathName = window.location.pathname;
    const cancellationPolicies = roomGroup?.cancellationPolicies?.length > 0 ? roomGroup?.cancellationPolicies[0] : {}
    const childPolicies = hotelDetails?.childPolicy;
    const matches = useMediaQuery('(max-width:1024px)');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <Box className="iGor_PropertyDAccordion">
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={!matches ? <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} /> : <ArrowRightIcon className='ArrowRightIcon' />}>
                    <Box>
                        <Typography fontWeight={600} color="#000000">Travel & Hotel Policy</Typography>
                        <Typography variant='body1' color={'#C4C4C4'}>How hotel policies work for stays</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {hotelDetails?.cancellation_policy && hotelDetails?.cancellation_policy != '' ? hotelDetails?.cancellation_policy : 'Not Available'}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            {!pathName?.includes('order-summary') ? (<Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={!matches ? <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} /> : <ArrowRightIcon className='ArrowRightIcon' />}>
                    <Box>
                        <Typography fontWeight={600} color="#000000">Child Policy</Typography>
                        <Typography variant='body1' color={'#C4C4C4'}>How child policies work for stays</Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    {childPolicies?.maxChildAge ? <Typography>
                        - Children up to {childPolicies.maxChildAge} years old are welcome.
                    </Typography> : ''}
                    {childPolicies?.maxInfantAge ? <Typography>
                        - Infants up to {childPolicies.maxInfantAge} years old stay for free.
                    </Typography> : ''}
                </AccordionDetails>
            </Accordion>) : (<Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={!matches ? <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} /> : <ArrowRightIcon className='ArrowRightIcon' />}>
                    <Box>
                        <Typography fontWeight={600} color="#000000"    >Cancellation Policy</Typography>
                        <Typography variant='body1' color={'#C4C4C4'}>How cancellation work for stays</Typography>
                    </Box>
                </AccordionSummary>
                {cancellationPolicies?.rules ? cancellationPolicies?.rules?.map((policy: any) => {
                    return (<AccordionDetails>
                        <Typography>
                            Cancelling the booking from  <b>"{dateConverterCancellationPolicies(policy.start)}"</b>, to <b>"{dateConverterCancellationPolicies(policy.end)}"</b> , will incur cancellation charges of: <b>{currencySelected}{policy.value?.toFixed(2)}</b>
                        </Typography>
                    </AccordionDetails>)
                }) : (<AccordionDetails>
                    <Typography>Not available</Typography>
                </AccordionDetails>)}
            </Accordion>)}
        </Box>
    );
}
