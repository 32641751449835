import { signOut } from "@firebase/auth";
import { useDispatch } from "react-redux";
import { auth } from "../../utils/config/firebaseAuth";

import { setAuthenticationType, setCountryNumCodeForApi, setRegisterUserCurrencyId, setEmailAuthenticationUserData, setEnterPassword, setExistingUserEmailStatusReset, setGetUserDetailsReset, setSocialMediaAuthenticationUserData, setHotelSelectedVrImgAndData, setInputValidatorRegisterUser, setIsLogedInUser, setIsRegisterPopUp, setIsVerificationPopUp, setLoggedInUserData, setLoginUserEmailAddress, setMainPopupForRegisterUser, setRegisterUserEmailAddress, setRegisterUserPassword, setRegisteredUserDetailsReset, setSignInCallApi, setUserLoginPassword, setUserWishlistDataReset, setUserMytripsDataReset, setUserMytripDetailsDataReset, setGetHotelsToExploreOffersReset, setUserNotificationsAndMessgReset, setUserFaviouratesListReset, setRemoveFromFaviouratesReset, setAddTofavouriteListReset, setAllowMoeNotification, setShowFaviourateList, setFolderSelectedDetails, setMediaNewsArticlesDataReset, setBookedOfferListDataReset, setBookedOfferDetailsReset } from '../../appStore/hotelSlice';
import { useNavigate } from "react-router";
import { Button } from "@mui/material";
import { EventLogHanlderMoengage } from "../../utils/handlers/EventLogsHandler";
import { eventNameForMoeEvents } from "../../utils/constants/constant";

function UserLogoutProfile() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const eventNameLogOutMoe = eventNameForMoeEvents.Logout;
    const moeAttributes = {
        "Logout": "Logout"
    }
    // user logout handler from profile
    const logOutUserHandler = () => {
        // event log when logout
        EventLogHanlderMoengage(eventNameLogOutMoe, moeAttributes)

        // Sign out call for gmail user looged in
        signOut(auth).then(() => {
            // Sign-out successful.
        }).catch((error: any) => {
            // An error happened.
        });
        sessionStorage.clear()
        dispatch(setEnterPassword(false))
        dispatch(setIsLogedInUser(false))
        dispatch(setEmailAuthenticationUserData(['']))
        dispatch(setSocialMediaAuthenticationUserData(['']))
        dispatch(setLoginUserEmailAddress(''))
        dispatch(setUserLoginPassword(''))
        dispatch(setAuthenticationType(''))
        dispatch(setRegisteredUserDetailsReset({ status: '', message: '', userDetail: [''] }))
        dispatch(setGetUserDetailsReset([]))
        dispatch(setRegisterUserEmailAddress(''))
        dispatch(setRegisterUserPassword(''))
        dispatch(setIsLogedInUser(false))
        dispatch(setExistingUserEmailStatusReset({ data: [''], loading: false, error: null, }))
        dispatch(setMainPopupForRegisterUser(false))
        dispatch(setIsVerificationPopUp(false))
        dispatch(setSignInCallApi(false))
        dispatch(setInputValidatorRegisterUser(false))
        dispatch(setRegisterUserCurrencyId({ currencyId: 0, currencyCode: '' }))
        dispatch(setCountryNumCodeForApi(101))
        dispatch(setLoggedInUserData({}))
        dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
        dispatch(setUserMytripsDataReset({ data: [], loading: false, error: null }))
        dispatch(setUserMytripDetailsDataReset({ data: [], loading: false, error: null }))
        dispatch(setGetHotelsToExploreOffersReset({ data: [], loading: false, error: null }))
        dispatch(setUserNotificationsAndMessgReset([]))
        dispatch(setUserFaviouratesListReset({ data: [], loading: false, error: null }))
        dispatch(setRemoveFromFaviouratesReset({ data: [], loading: false, error: null }))
        dispatch(setUserWishlistDataReset({ data: [], loading: false, error: null }))
        dispatch(setAddTofavouriteListReset({ status: false, message: '' }))
        dispatch(setAllowMoeNotification(null))
        dispatch(setShowFaviourateList(false))
        dispatch(setFolderSelectedDetails({ folderName: '', folderId: 0 }))
        dispatch(setMediaNewsArticlesDataReset({}))
        dispatch(setBookedOfferListDataReset({ data: [], loading: false, error: null }))
        dispatch(setBookedOfferDetailsReset({ data: [], loading: false, error: null }))
        navigate('/')
    }
    return (<>
        <Button variant='contained' style={{ fontSize: '12px', fontWeight: 600 }} onClick={logOutUserHandler}>   Logout</Button>
    </>);
}

export default UserLogoutProfile;