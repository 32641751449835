import React, { useEffect, useState } from 'react';
import { Button, Box, Typography, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Icon_360 from '../../assets/icons/Icon_360.svg';
import Iconclockhistory from '../../assets/icons/Icon_clock-history.svg';
import icon_room from '../../assets/icons/icon_room.svg';
import Icon_bed from '../../assets/icons/Icon_bed.svg';
import Icon_shaer from '../../assets/icons/Icon_shaerWithBg.svg';
import icon_set from '../../assets/icons/icon_set.svg';
import PropertyFacilitiesModal from '../popUpSection/PropertyFacilitiesModal';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import NoHotelsImg from '../../assets/images/No Hotels Img.png'
import PropertyRoomsRecomendations from './PropertyRoomsRecommendations';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import { setGuestDetailsAtCheckIn, setPreviousPathName, setRoomIndexSelectedForCheckout, setRoomRecommendationIndexSelected, setRoomRecommendationSelected, setRoomTypeSelectedForCheckout } from '../../appStore/hotelSlice';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from 'react-i18next';
import { PropDertailsCardsSkeleton } from '../Skeleton/PropDertailsCardsSkeleton';
import VTUrlParser from '../../utils/utilities/VTUrlParser';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import PropertyRoomVrSectionViewer from './PropertyRoomVrSection';
import roomsAndGuestCountHandler from '../../utils/utilities/RoomAndGuestCount';
import PropertyFacilitiesIconMapper from './PropertyFacilitiesIconMapper';

function PropertyDetailsOffersCards({ initApiLoading }: any) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [iframeShow, setIframeShow] = useState(false);
    const [defaultRecommendation, setDefaultRecommendation] = useState(true)
    const [selectedRoomId, setSelectedRoomId] = useState(0);
    const [selectedDivIndex, setSelectedDivIndex] = useState(0);
    const [iframeIndexId, setIframeIndexId] = useState(null);
    const [seeAmenitiesIndex, setSeeAmenitiesIndex]: any = useState(null);
    const [cardShow, setAmenitiesCardShow] = useState(false);
    const { roomRecommendationSelected, hotelRoomDetails, getUserDetails, hotelDetails } = useAppSelector(state => state.hotel)
    const hotelDetailsData = hotelDetails?.data?.hotel
    const hotelsDetailsLoading = useAppSelector(state => state.hotel.hotelDetails.loading)
    const roomArrayForGuest = useAppSelector(state => state.hotel.roomArray);
    const roomsReceivedFromApis = hotelRoomDetails?.data?.rooms ? hotelRoomDetails?.data?.rooms : [];
    const roomsLoading = hotelRoomDetails?.loading;
    const pathnameFromUrl = window.location.pathname;
    const pathForPrevious = window.location.pathname + window.location.search;
    const pathname = window.location;
    const hrefDetails = pathname.pathname + pathname.search;
    const loggedInUserDetails = getUserDetails.data.userDetail ? getUserDetails.data.userDetail : '';

    const handleClickAccordion = (index: any) => {
        setSeeAmenitiesIndex(index)
        setAmenitiesCardShow(true)
    };

    // room checkout handler on recommendation selected 
    const offerRoomCheckoutHandler = async (roomRecommendations: any, roomSelectedForCheckout: any, roomIndexSelected: number) => {
        const passengerCountsForDetails: any = roomsAndGuestCountHandler(roomArrayForGuest) // Assuming roomArrayForGuest is defined somewhere
        if (roomSelectedForCheckout) {
            dispatch(setGuestDetailsAtCheckIn(passengerCountsForDetails))
            dispatch(setRoomIndexSelectedForCheckout(roomIndexSelected))
            dispatch(setRoomTypeSelectedForCheckout(roomSelectedForCheckout))
            navigate('/order-summary')
        }
        if (pathForPrevious.includes('/property-details')) {
            dispatch(setPreviousPathName(pathForPrevious))
        }
        if (pathnameFromUrl == '/order-summary') {
            dispatch(setRoomRecommendationIndexSelected(selectedDivIndex))
        }
        else if (pathnameFromUrl.includes('/property-details')) {
            dispatch(setRoomRecommendationIndexSelected(selectedDivIndex))
        }
    }

    const offerRoomsFilterdData = roomsReceivedFromApis.filter((rooms: any) => rooms.isOffer == true)

    return (
        <>
            {offerRoomsFilterdData.length > 0 && <Box paddingY={2} className='iGor_procardAvil'>
                <Typography variant='subtitle1'> Available offers</Typography>
            </Box>}
            {initApiLoading == true || hotelsDetailsLoading == true || roomsLoading == true ? <PropDertailsCardsSkeleton /> : (
                offerRoomsFilterdData.length > 0 ? offerRoomsFilterdData.map((hotelrooms: any, roomIndex: number) => {
                    return (<Box className="iGOr_card-listing">
                        <Box className="top">
                            <a className="image">
                                {!iframeShow && (<PropertyRoomVrSectionViewer
                                    hotelrooms={hotelrooms}
                                    selectedRoomId={selectedRoomId}
                                    defaultRecommendation={defaultRecommendation}
                                    setSelectedRoomId={setSelectedRoomId}
                                    setIframeShow={setIframeShow}
                                    roomIndex={roomIndex}
                                    selectedDivIndex={selectedDivIndex}
                                    setIframeIndexId={setIframeIndexId}
                                />)}
                                {iframeShow ? (roomIndex == iframeIndexId ? (<><Box className="iframe">
                                    <iframe src={VTUrlParser(hotelrooms?.vrroom)} ></iframe>
                                </Box >
                                    <Box className='iGor_iconZoom'>
                                        <Link
                                            to={'/property-details-view'}
                                            state={{
                                                roomSelected: hotelrooms,
                                                href: hrefDetails,
                                                selectedRoomId: selectedRoomId,
                                                roomRecommendationSelected: roomRecommendationSelected,
                                                defaultRecommendation: defaultRecommendation,
                                                roomIndexSelected: roomIndex,
                                                selectedDivIndex: selectedDivIndex
                                            }}
                                        >
                                            <img src={icon_set} alt="icon_set" />
                                        </Link>
                                    </Box>
                                </>
                                )

                                    : (<PropertyRoomVrSectionViewer
                                        hotelrooms={hotelrooms}
                                        selectedRoomId={selectedRoomId}
                                        defaultRecommendation={defaultRecommendation}
                                        setSelectedRoomId={setSelectedRoomId}
                                        setIframeShow={setIframeShow}
                                        roomIndex={roomIndex}
                                        selectedDivIndex={selectedDivIndex}
                                        setIframeIndexId={setIframeIndexId}
                                    />))
                                    : ('')
                                }
                            </a>
                            <Box className="description">
                                <Box className="head">
                                    <Box className="title">
                                        <h4>{hotelrooms.roomname}</h4>
                                    </Box >
                                    <Box className="price">
                                        <p className="price-wrapper"><span className="currency">{hotelrooms?.isSoldOut == false ? hotelrooms?.listprice?.currency_symbol : ''}</span>
                                            {hotelrooms?.listprice?.roomRecommendation.length > 0 ? hotelrooms?.listprice?.roomRecommendation.map((recommendation: any, indexReco: any) => {
                                                if (hotelrooms.roomid == selectedRoomId && roomRecommendationSelected.id == recommendation.id && roomRecommendationSelected.groupId == recommendation.groupId) {
                                                    return (<><span className="price-value">{`${hotelrooms?.isSoldOut == true ? 'SOLD OUT' : numeral(recommendation.roundOffPerNightTotal).format('0,0')}`}</span>
                                                        {/* <span className="room">/night</span> */}
                                                    </>)
                                                }
                                                if (hotelrooms.roomid !== selectedRoomId && indexReco == 0) {
                                                    return (<><span className="price-value">{`${hotelrooms?.isSoldOut == true ? 'SOLD OUT' : numeral(recommendation.roundOffPerNightTotal).format('0,0')}`}</span>
                                                        {/* <span className="room">/night</span> */}
                                                    </>)
                                                }
                                            }) : ""
                                            }
                                        </p>
                                        {hotelrooms?.isSoldOut == true ? '' : <p className="price-info">Taxes and all fees exclude</p>}
                                    </Box >
                                </Box >
                                <Box className="body">
                                    <Box className="text-wrapper">
                                        <Typography>
                                            {hotelrooms?.packagedescription ? hotelrooms?.packagedescription : 'No information available'}
                                        </Typography>
                                    </Box>
                                    <Box className="icon-wrapper">
                                        <Box className="icon-room"> <img src={icon_room} alt="room" /><span className="title">Room size: </span><span className="value">{hotelrooms?.roomsize}</span><span> sqm</span></Box >
                                        {/* <Box className="icon-room"> <img src={Icon_bed} alt="fa-solid_bed" /><span className="title">Capacity: </span><span className="value">{hotelrooms?.roomcapacity}</span><span> people</span></Box > */}
                                    </Box>
                                </Box>
                                <Box className="btn-wrapper">
                                    <Button
                                        variant='contained'
                                        className={hotelrooms?.isSoldOut == true ? `iGor_button_disable_room` : ''}
                                        onClick={() => offerRoomCheckoutHandler(hotelrooms.listprice.roomRecommendation, hotelrooms, roomIndex)}
                                    >
                                        {t('Choose this package')}
                                    </Button>
                                    {cardShow == false || roomIndex !== seeAmenitiesIndex ? (<Box className="accordion-head" onClick={() => handleClickAccordion(roomIndex)}  ><span>See room details and amenities</span>
                                        <ArrowDownIcon className="fa-solid fa-angle-down" />
                                    </Box>) : (<Box className="accordion-head" onClick={() => setSeeAmenitiesIndex(null)}  ><span>Hide room details and amenities</span>
                                        <ArrowDownIcon className="fa-solid fa-angle-down" />
                                    </Box>)}
                                </Box >
                            </Box >
                        </Box >
                        <Box className={"bottom iGor_accordion-body   " + (roomIndex === seeAmenitiesIndex && cardShow ? "iGor_accordion_slide-left   " : "iGor_accordion_slide-right iGor_accordion-hide")}>
                            <Box className="inclusion inclusion-left">
                                <Typography variant='subtitle1'>Inclusion Details</Typography>
                                <ul className=''>
                                    {hotelrooms?.packageinclusion.length > 0 ?
                                        hotelrooms?.packageinclusion.map((item: any) => (<li>{item}</li>))
                                        : t('5007')}
                                </ul>
                            </Box >
                            <Box className="details">
                                <Typography variant='subtitle1' >Size Details</Typography>

                                <List className='iGor_Faci_list'>
                                    <ListItem>
                                        <ListItemText primary="1 Large Double bed" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <img src={icon_room} alt="room" />
                                        </ListItemIcon>
                                        <ListItemText primary={`Room size : ${hotelrooms.roomsize} sqm`} />
                                    </ListItem>
                                    {/* <ListItem>
                                        <ListItemIcon>
                                            <img src={Icon_bed} alt="fa-solid_bed" />
                                        </ListItemIcon>
                                        <ListItemText primary={`Capacity : 2 people`} />
                                    </ListItem> */}
                                </List>
                            </Box >
                            <Box className="facilities">
                                <Typography variant='subtitle1'>Main Facilities</Typography>
                                <Box>
                                    <List className='iGor_Faci_list iGor_Faci_listMain '>
                                        {hotelrooms?.facilities.length > 0 ? hotelrooms?.facilities?.map((item: any) => (
                                            <ListItem>
                                                <PropertyFacilitiesIconMapper iconName={item.icon[0]} />
                                                <ListItemText primary={item.title} />
                                            </ListItem>
                                        )) : t('5008')}
                                    </List>
                                </Box>

                                <PropertyFacilitiesModal listing_details={hotelrooms} showFacilityText={"property-details"} />
                            </Box >
                        </Box >
                    </Box >)
                }) :
                    (<></>)
            )}
        </>
    )
}

export default PropertyDetailsOffersCards;