import { Button, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import { Box } from '@mui/system'
import Icon_fb from '../../assets/icons/Icon_fb.svg'
import IconMailColor from '../../assets/icons/IconMailColor.svg'
import Icon_whatsapp from '../../assets/icons/Icon_whatsapp.svg'
import checkFinish from '../../assets/icons/Icon_CheckFinish.svg'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { fetchUserTripsApi, getBookedOffersList, setBookedOfferListDataReset, setHotelRoomPriceSelectedReset, setOrderSummarySectionType, setRoomRecommendationSelected, setUserMytripsDataReset, setbBookingApiDataReset } from '../../appStore/hotelSlice'
import { useAppSelector } from '../../appStore/hooks'
import { EventNamesForEventLogs, eventNameForMoeEvents, orderSummarySectionType } from '../../utils/constants/constant'
import { Footer } from '../../layouts/Footer'
import { EventLogHanlderMoengage, EventLogsHandler } from '../../utils/handlers/EventLogsHandler'
import { formatDateOnSearchResultPage } from '../../utils/utilities/DateConverter'
import { useEffect, useState } from 'react'

export const OrderSumFinishCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(19);
  const matches = useMediaQuery('(max-width:1024px)');
  const { loggedInUserCurrency, destinationsData, hotelRoomPriceApiData, searchDestinationFromDate } = useAppSelector(state => state.hotel)
  const hotelDetailsResponse = useAppSelector(state => state.hotel.hotelDetails?.data?.hotel)
  const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 0;
  const orderSummarySction = orderSummarySectionType.orderSummary;
  const eventNameBookingSuccess = EventNamesForEventLogs.bookingSuccess;
  const hotelPriceData: any = hotelRoomPriceApiData?.data ? hotelRoomPriceApiData?.data : [];
  const hotelId = hotelPriceData.hotelId;
  const [roomGroup] = hotelPriceData?.roomGroup ? hotelPriceData?.roomGroup : [];
  const hotelPrice = roomGroup?.roundOffTotalRate
  const currency = roomGroup?.currency
  const formatedFromDate = formatDateOnSearchResultPage(searchDestinationFromDate, 'detail')
  const hotelNameForEvent = hotelDetailsResponse?.name;
  const eventNamePurchaseMoe = eventNameForMoeEvents.Purchase;

  const homePageBack = () => {
    dispatch(setHotelRoomPriceSelectedReset({ data: [], loading: false, error: null }))
    dispatch(setRoomRecommendationSelected({}))
    dispatch(setbBookingApiDataReset({}))
    dispatch(setOrderSummarySectionType(orderSummarySction))
    navigate('/')
  }

  const moveToMytrips = () => {
    dispatch(setUserMytripsDataReset({ data: [], loading: false, error: null }))
    dispatch(setOrderSummarySectionType(orderSummarySction))
    dispatch(setBookedOfferListDataReset({ data: [], loading: false, error: null }))
    dispatch(fetchUserTripsApi(loggedInUserId))
    dispatch(getBookedOffersList(loggedInUserId))
    navigate('/my-trips')
  }

  // event log to analytics
  const eventIdsForLogEvents = {
    "ig_content_id": hotelId,
    "ig_content_type": 'Hotel',
    "ig_price": hotelPrice,
    "ig_quantity": '1',
    "ig_currency": currency ? currency : '',
    "ig_date_a": formatedFromDate,
  }
  EventLogsHandler(eventNameBookingSuccess, eventIdsForLogEvents)

  // event logs for Moengage
  const eventIdsForLogEventsMoe = {
    "Hotel Name": hotelNameForEvent,
    "Hotel ID": hotelId,
    "Price": hotelPrice
  }
  EventLogHanlderMoengage(eventNamePurchaseMoe, eventIdsForLogEventsMoe)

  useEffect(() => {
    if (seconds > 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      moveToMytrips();
    }
  }, [seconds, navigate]);

  return (
    <>
      <Box className='iGor_setRelative'>

        <Box className='iGor_finishCard'>

          <Typography variant='body2' marginY={1}>  Secure check out</Typography>
          <img src={checkFinish} alt='checkFinish' />
          <Typography variant='h4' marginY={1}>   You’re confirmed</Typography>
          <Box className='iGor-finishText'>
            <Typography color={"#C4C4C4"}>
              Hooray! You will going to {destinationsData.destinationName} soon.
              Be prepare and enjoy your trip.
              Your e-itinerary has been published
            </Typography>
          </Box>
          <Box margin={'15px 0'}>
            <Typography variant='body1' fontWeight={600} marginY={1}> Share this trip to</Typography>
            <Box className='iGor-finishShareTrip'>
              <img src={Icon_fb} alt='Icon_fb' />
              <img src={Icon_whatsapp} alt='Icon_whatsapp' />
              <img src={IconMailColor} alt='IconMailColor' className='iGor-marginX' />
            </Box>
          </Box>

          <Button variant='contained' style={{ height: '40px', fontSize: '12px' }} onClick={moveToMytrips}>  View e-Itinerary</Button>
          <Typography variant='body2' margin={1} onClick={homePageBack} className='iGor_backToHome'>  Back to home</Typography>
        </Box>
        {!matches && <Box className='iGor-redirectBox'>
          <CircularProgress color="inherit" value={100} />
          <Typography>
            This page will redirect in {seconds} seconds
          </Typography>
        </Box>}
      </Box>
      <Box sx={{ position: 'relative', bottom: 0, width: '100%' }}>
        <Footer />
      </Box>
    </>
  )
}
