import React, { useEffect } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useAppSelector } from '../../appStore/hooks'
import PaymentGateWays from '../Payments/PaymentGateWays'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BedIcon from '@mui/icons-material/Bed';
import { useBookOfferHotelMutation } from '../../services/api/hotelApi'
import bookOfferHotelPayloadData from '../../services/bookOfferHotelPayload'
import { AppLogHandler } from '../../utils/handlers/appLogsHandler'
import { useDispatch } from 'react-redux'
import { setErrorMessageForDialogueBox, setOfferBookedDataReset, setAlertBoxOnError } from '../../appStore/hotelSlice'
import { useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'


export const OrderSumPayment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { guestDetailsAtCheckIn, roomTypeSelectedForCheckout, getUserDetails, bookOfferHotelData, hotelDetails } = useAppSelector(state => state.hotel);
    const passengerDetails = useAppSelector(state => state.hotel.passengerDetailsForCheckoutHotel);
    const isOfferRooms = roomTypeSelectedForCheckout.isOffer ? roomTypeSelectedForCheckout.isOffer : false
    const searchId = useAppSelector(state => state.hotel.initApi?.searchId);
    const loggedInUserDetails = getUserDetails?.data?.userDetail ? getUserDetails?.data?.userDetail : '';
    const bookedOfferData = bookOfferHotelData;
    const guestDetails = guestDetailsAtCheckIn;
    const hotelDetailsData = hotelDetails?.data?.hotel

    const contactDetails = {
        phone: loggedInUserDetails.phone,
        email: loggedInUserDetails.email
    }
    const [bookOfferHotel, bookOfferHotelApiData] = useBookOfferHotelMutation();

    const offerCheckoutHanlder = () => {
        if (passengerDetails && roomTypeSelectedForCheckout.ratetype && roomTypeSelectedForCheckout.propertyid && roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price) {
            const payload = bookOfferHotelPayloadData(passengerDetails, contactDetails, roomTypeSelectedForCheckout.ratetype, roomTypeSelectedForCheckout.propertyid, roomTypeSelectedForCheckout?.listprice?.roundOffMin_Price, hotelDetailsData.name, searchId);
            AppLogHandler('loggedInUserDetails', payload)
            if (payload)
                bookOfferHotel({ payload })
        }
    }

    useEffect(() => {
        if (bookedOfferData?.status == 'Success' && bookedOfferData?.message.includes('OfferBooking request added successfully')) {
            dispatch(setOfferBookedDataReset({ status: '', message: '' }))
            setTimeout((() => { navigate('/booking-confirmed') }), 1500)
        }
        else if (bookedOfferData?.status == 'Failed' && bookedOfferData?.message.includes('OfferBooking failed to add')) {
            dispatch(setOfferBookedDataReset({ status: '', message: '' }))
            dispatch(setErrorMessageForDialogueBox('38'))
            dispatch(setAlertBoxOnError(true))
        }
    }, [bookedOfferData])

    return (
        <>
            <Grid item sm={7} xs={12} className='iGor_paymentSection iGoi_OrderPaypl '>
                {/* <Typography variant='body2' marginBottom={'35px'}> Payment Information</Typography> */}
                {/* <Typography variant='body2' color={'#C4C4C4'}>   Your total bill</Typography>
                <Typography className='iGor_OrderAmount' marginBottom={'35px'}>{currencySelected} {numeral(roomGroup?.totalRate).format('0,0')}</Typography> */}

                {/* <Box className='iGor_setFlex iGor_alignCenter' marginBottom={'40px'}>
                    <Typography variant='body2'> Pay with </Typography> */}
                {/* <Box className='iGor_Credit'>
                        <img src={IconCard} alt='' />   Credit / Debit Card
                    </Box>
                    <img src={Icon_Apay} alt='Icon_Apay' />
                    <img src={Icon_Gpay} className='iGor-ML-10' alt='Icon_Gpay' /> */}
                {/* </Box> */}

                {/* <Typography variant='body2'>   Card Number</Typography>
                <Typography>    Enter the 16-digit card number on the card</Typography>

                <CardNumberInput />

                <Grid container marginTop={'30px'}>
                    <Grid item sm={6}>
                        <Typography variant='body2'>   CVV Number</Typography>
                        <Typography>Enter the 3 or 4 digit number on the back of card </Typography>
                    </Grid>

                    <Grid item sm={6}>
                        <TextField className='iGor_cardNumber iGor_CvvNumber ' fullWidth></TextField>
                    </Grid>

                </Grid>
                <Grid container marginBottom={'40px'} >

                    <Grid item sm={6}>
                        <Typography variant='body2'>    Expiry Date</Typography>
                        <Typography>    Enter the expiration date of the card</Typography>
                    </Grid>

                    <Grid item sm={3} paddingRight={1}>
                        <TextField className='iGor_cardNumber iGor_CardYear '></TextField>
                    </Grid>
                    <Grid item sm={3} paddingLeft={1}>
                        <TextField className='iGor_cardNumber iGor_CardYear'></TextField>
                    </Grid>
                </Grid> */}
                <Box className='iGoi_OrderPaypl'>
                    <Box className="iGor_Guestdetails iGOr_mb-D-none" padding={2}>
                        <Box className='iGor_setFlex iGor_justifyBetween'>
                            <Typography variant='body2' mb={3} ml={2}>Guest details for Checkout</Typography>
                            {/* <ArrowDownIcon /> */}
                        </Box>
                        <Box className='iGor__OrderSymList iGor_bgWhite iGOr_BorderRadius15'>
                            {guestDetails?.map((room: any, roomIndex: number) => {

                                return (
                                    <>
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon style={{ color: 'black' }} />}
                                                aria-controls="panel1-content"
                                                id={"panel1-header" + roomIndex}
                                            >
                                                <BedIcon /> <Typography variant='body2' paddingY={1}> Room {room.room + 1}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box>

                                                    <Box>
                                                        <TableContainer className='iGor__listGuest'>
                                                            <Table size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell width={"5%"}><Typography variant='body2'> Title</Typography></TableCell>
                                                                        <TableCell width={"42%"}><Typography variant='body2'>First Name</Typography></TableCell>
                                                                        <TableCell width={"42%"}><Typography variant='body2'>Last Name</Typography></TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>

                                                                    {room?.adultGuests?.map((adultsInfo: any, adultIndex: number) => {
                                                                        return (
                                                                            <TableRow key={adultIndex} >

                                                                                {/* <TableCell >
                                                              <Avatar sx={{ width: '25px', height: '25px', background: '#fff' }}>
                                                                  <HowToRegIcon sx={{ width: '15px', height: '15px', color: '#000' }} />
                                                              </Avatar>
                                                          </TableCell> */}
                                                                                <TableCell >
                                                                                    <Typography variant='caption'>   {adultsInfo.title}</Typography>
                                                                                </TableCell>
                                                                                <TableCell >
                                                                                    <Typography variant='caption'>   {adultsInfo.firstName}</Typography>
                                                                                </TableCell>
                                                                                <TableCell >
                                                                                    <Typography variant='caption'>     {adultsInfo.lastName}</Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}

                                                                    {room?.childGuests?.map((childInfo: any, childIndex: number) => {
                                                                        return (
                                                                            <TableRow key={childIndex} >
                                                                                <TableCell>
                                                                                    <Typography variant='caption'>  {childInfo.title}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography variant='caption'>  {childInfo.firstName}</Typography>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Typography variant='caption'>  {childInfo.lastName}</Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}

                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Box>
                                                    {/* {room.childGuests.length > 0 ? <Typography variant='body2' marginY={1}> Child </Typography> : <></>} */}

                                                    {/* <Box>
                                                        <List dense={dense} >

                                                            {room.childGuests.map((childInfo: any, childIndex: number) => {
                                                                return (
                                                                    <ListItem sx={{ padding: '0 5px' }}
                                                                    >
                                                                        <ListItemAvatar sx={{ minwidth: '40px' }}>
                                                                            <Avatar sx={{ width: '25px', height: '25px', background: '#fff' }}>
                                                                                <HowToRegIcon sx={{ width: '15px', height: '15px', color: '#000' }} />
                                                                            </Avatar>
                                                                        </ListItemAvatar>
                                                                        <ListItemText>
                                                                            <Typography variant='body2'>  {childInfo.title}</Typography>
                                                                        </ListItemText>
                                                                        <ListItemText>
                                                                            <Typography variant='body2'>  {childInfo.firstName}</Typography>
                                                                        </ListItemText>
                                                                        <ListItemText>
                                                                            <Typography variant='body2'>  {childInfo.lastName}</Typography>
                                                                        </ListItemText>
                                                                    </ListItem>
                                                                )
                                                            })}

                                                        </List>
                                                    </Box> */}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion>
                                    </>)
                            })
                            }
                            {/* <List dense={dense} >
                                    {generate(
                                        <ListItem
                                            secondaryAction={
                                                <IconButton edge="end" aria-label="delete">
                                                    <DeleteIcon sx={{width:'15px' ,height:'15px'}}/>
                                                </IconButton>
                                            }
                                        >
                                            <ListItemAvatar sx={{minwidth:'40px'}}>
                                                <Avatar sx={{width:'25px' ,height:'25px',background:'#fff'}}>
                                                    <HowToRegIcon sx={{width:'15px' ,height:'15px',color:'#000'}}/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary="Single-line item"
                                                secondary={secondary ? 'Secondary text' : null}
                                            />
                                        </ListItem>,
                                    )}
                                </List> */}

                        </Box>
                    </Box>
                    <Box className='iGo__OrderSumPaybox'>
                        {/* <Typography variant='body2' className='iGo__OrderSumPayTitle'> Payment Option</Typography> */}
                        {isOfferRooms == false ?
                            (<PaymentGateWays />)
                            : (<Button
                                variant="contained"
                                onClick={offerCheckoutHanlder}
                                disabled={bookOfferHotelApiData.isLoading}
                                className="iGor__payNowBtn"
                            >
                                {bookOfferHotelApiData.isLoading == true ? (<Box className='iGor-action_loader'>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </Box>) : 'Book Now'}
                            </Button>
                            )}
                    </Box>
                </Box>
                {/* <Button variant='contained' onClick={handleNext}> Pay Now</Button> */}
            </Grid >
        </>
    )
}
