import React, { useEffect, useState } from 'react';
import { Modal, Box, IconButton, Typography, TextField, InputAdornment, List, ListItem, Checkbox, ListItemButton, ListItemText, Slider, Button } from "@mui/material";
import FilterIcon from '../../assets/icons/FilterIcon.svg';
import { Close } from '@mui/icons-material';
import loctionIcon from '../../assets/icons/location-pin.svg'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/KeyboardArrowRight';
import TuneIcon from '../../assets/icons/Icon_FilterMb.svg';
import { useAppSelector } from '../../appStore/hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchHotesResultsDataFromApi, fetchOfferHotelsResult, setAppliedPriceRangeFiltersForHotels, setAppliedPropertyTypeFiltersForHotels, setAppliedRoomTypeFiltersForHotels, setHotelsResultsDataReset, setHotelsTypeTabSelection, setMobileAppliedPropertyTypeFilterForHotels, setMobileAppliedRoomTypeFilterForHotels, setMobileFiltersEnable, setOfferHotelsDataReset, setPreviousPathName, setSearchHotelsResultCall } from '../../appStore/hotelSlice';
import { AppLogHandler } from '../../utils/handlers/appLogsHandler';
import PriceRangeFilters from '../../utils/utilities/PriceRangeFilters';
import { useApplyFiltersHotelsResultMutation } from '../../services/api/hotelApi';
import applyFiltersPayload from '../../services/applyFiltersApiPayload';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router';
import DisableSwipe from '../inputFieldCustom/DisableSwipe';
const style = {
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  // Width: '31.25rem',
  // transform: 'translate(-50%, -50%)',
  bgcolor: '#FFFFFF',
  // borderRadius: '10px',
  p: 3,
};

export default function AdvancedFilterMod({ setIcon }: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [numberOfHits,setnumberOfHits] =useState(2);
  // const [open, setOpen] = React.useState(true);
  // const handleOpen = () => { setOpen(true); setFlightdistance(true); }
  const [slideProperty, setSlideProperty] = useState(false)
  const [slideRoom, setSlideRoom] = useState(false)
  const [flightdistance, setFlightdistance] = useState(false)
  const filtersForUI = useAppSelector(state => state.hotel.getUIFiltersData)
  const { setMobilePropertyTypeSelectedFilters, setMobileRoomTypeSelectedFilters, previousPathName, setPriceRangeSelectedFilters, initApi, loggedInUserCurrency, hotelResults } = useAppSelector(state => state.hotel)
  const [propertyTypeSelectedArray, setPropetyTypeSelectedArray]: any = useState(setMobilePropertyTypeSelectedFilters);
  const [roomTypeSelectedArray, setRoomTypeSeletedArray]: any = useState(setMobileRoomTypeSelectedFilters);
  let searchId = initApi?.searchId ? initApi?.searchId : '';
  const loggedInUserId = loggedInUserCurrency?.userId ? loggedInUserCurrency?.userId : 270;
  const loggendInUserCurrency = loggedInUserCurrency?.currencyName ? loggedInUserCurrency?.currencyName : 'USD';
  const currencySymbol = hotelResults.data?.currencySymbol ? hotelResults.data?.currencySymbol : loggendInUserCurrency;
  const previousPath = previousPathName;

  // applied filters fetching 
  let defaultPropertyType = filtersForUI?.data?.propertyTypes;
  let defaultRoomTypes = filtersForUI?.data?.roomTypes;
  let defaultPriceRanges = filtersForUI?.data?.priceRange;

  const priceRangeSelected = setPriceRangeSelectedFilters;
  const defaultMinPrice = defaultPriceRanges?.min_price;
  const defaultMaxPrice = defaultPriceRanges?.max_price;
  const updatedMinPrice = priceRangeSelected?.min_price;
  const updatedMaxPrice = priceRangeSelected?.max_price;

  // apply filters api call assign to variable for dynamic use
  const [applyFilters] = useApplyFiltersHotelsResultMutation();
  AppLogHandler("priceSelectedpriceSelected", { priceRangeSelected, setMobilePropertyTypeSelectedFilters, setMobileRoomTypeSelectedFilters })


  const navigateToResultPage = () => {
    navigate(previousPath)
    dispatch(setPreviousPathName(previousPathName))
  };

  // apply filters hanlder function
  const applyFiltersHanlder = () => {
    navigate(previousPath)
    dispatch(setPreviousPathName(previousPathName))
    if (propertyTypeSelectedArray.length > 0 || roomTypeSelectedArray.length > 0) {
      const payload = applyFiltersPayload(loggedInUserId, propertyTypeSelectedArray, roomTypeSelectedArray, defaultMinPrice, defaultMaxPrice, searchId)
      if (payload) {
        applyFilters({ payload })
      }
    }
  }

  // reset properties when property or room index is 0
  const resetPropertiesToHotelsResult = () => {
    dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
    dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
    dispatch(fetchHotesResultsDataFromApi({ searchId, numberOfHits }));
    dispatch(fetchOfferHotelsResult(searchId))
  }

  // property type selection logic
  const propertyTypeSelectionHandler = (value: number, propertyTypeId: any) => {
    const currentPropetyIndex = propertyTypeSelectedArray.indexOf(propertyTypeId)
    const propertyArrayDispatch = [...propertyTypeSelectedArray]
    if (currentPropetyIndex === -1) {
      propertyArrayDispatch.push(propertyTypeId);
    } else {
      propertyArrayDispatch.splice(currentPropetyIndex, 1);
    }
    if (currentPropetyIndex == 0) {
      dispatch(setSearchHotelsResultCall(true))
      dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
      dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
    }
    dispatch(setMobileFiltersEnable(true))

    // apply property type filters
    if (propertyArrayDispatch.length > 0) {
      const payload = applyFiltersPayload(loggedInUserId, propertyArrayDispatch, roomTypeSelectedArray, defaultMinPrice, defaultMaxPrice, searchId)
      // if (payload) {
      //   applyFilters({ payload })
      // }
    }
    else if (propertyArrayDispatch.length == 0) {
      resetPropertiesToHotelsResult()
    }

    setPropetyTypeSelectedArray(propertyArrayDispatch)
    AppLogHandler("propertyArrayDispatch", currentPropetyIndex)

  };

  // room type selection logic
  const roomTypeSelectionHandler = (value: any, roomTypeId: number) => {
    const currentRoomIndex = roomTypeSelectedArray?.indexOf(roomTypeId)
    const roomArrayDispatch: any = [...roomTypeSelectedArray]

    if (currentRoomIndex === -1) {
      roomArrayDispatch.push(roomTypeId);
    }
    else {
      roomArrayDispatch.splice(currentRoomIndex, 1);
    }

    if (currentRoomIndex == 0) {
      dispatch(setSearchHotelsResultCall(true))
      dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
      dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
    }

    // apply room type filters
    if (roomArrayDispatch.length > 0) {
      const payload = applyFiltersPayload(loggedInUserId, propertyTypeSelectedArray, roomArrayDispatch, defaultMinPrice, defaultMaxPrice, searchId)
      // if (payload) {
      //   applyFilters({ payload })
      // }
    }
    else if (roomArrayDispatch.length == 0) {
      resetPropertiesToHotelsResult()
    }

    dispatch(setMobileFiltersEnable(true))
    setRoomTypeSeletedArray(roomArrayDispatch)
    AppLogHandler("propertyArrayDispatchssssss", { propertyTypeSelectedArray, roomTypeSelectedArray, roomArrayDispatch })
  }

  // reassigning filters for ui 
  useEffect(() => {
    defaultPropertyType = filtersForUI?.data?.propertyTypes;
    defaultRoomTypes = filtersForUI?.data?.roomTypes;
    defaultPriceRanges = filtersForUI.data?.priceRange;
    searchId = initApi?.searchId ? initApi?.searchId : '';
  }, [filtersForUI, initApi?.searchId])

  // dispatching applied filters
  useEffect(() => {
    AppLogHandler("propertyTypeSelectedArray", { roomTypeSelectedArray, propertyTypeSelectedArray })
    dispatch(setMobileAppliedPropertyTypeFilterForHotels(propertyTypeSelectedArray))
    dispatch(setMobileAppliedRoomTypeFilterForHotels(roomTypeSelectedArray))
  }, [propertyTypeSelectedArray, roomTypeSelectedArray])

  // Reset filters 
  const resetFiltersHandler = () => {
    if (setMobilePropertyTypeSelectedFilters?.length > 0 || setMobileRoomTypeSelectedFilters?.length > 0 || updatedMinPrice != defaultMinPrice || updatedMaxPrice != defaultMaxPrice) {
      dispatch(setMobileAppliedPropertyTypeFilterForHotels([]))
      dispatch(setMobileAppliedRoomTypeFilterForHotels([]))
      dispatch(setMobileFiltersEnable(false))
      dispatch(setAppliedPriceRangeFiltersForHotels({ min_price: 0, max_price: 0 }))
      setPropetyTypeSelectedArray([])
      setRoomTypeSeletedArray([])
      dispatch(setHotelsResultsDataReset({ data: [], loading: false, error: null }))
      dispatch(setOfferHotelsDataReset({ data: [], loading: false, error: null }))
      dispatch(fetchHotesResultsDataFromApi({ searchId, numberOfHits }));
      dispatch(fetchOfferHotelsResult(searchId))
    }
    dispatch(setHotelsTypeTabSelection("ALL"))
  }

  return (
    <div>
      {/* <IconButton onClick={handleOpen} className='iGor_PT-0'>
        {setIcon ? <img src={TuneIcon} className='IGor_FilterIcon' /> : <img src={FilterIcon} alt='FilterIcon' />}
      </IconButton> */}
      <Modal
        keepMounted
        open={true}
        onClose={navigateToResultPage}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className='iGor_Modal'
      >
        <>
          <Box sx={style} className='iGOr_DropDown iGor_Modal' >
            <div className="modal-close iGor__AdvClose">
              <IconButton onClick={navigateToResultPage} className="close-button">
                <ArrowLeftIcon />
              </IconButton>
            </div>


            <Box >
              <Typography align='center' variant="subtitle1" fontSize={'12px'}>
                Advanced Filter </Typography>
            </Box>
            {/* <Box sx={{ textAlign: 'right' }}>
              <IconButton onClick={resetFiltersHandler} className='iGor_filterResetBtn'>
                {t('Reset Filters')}
              </IconButton>
            </Box> */}
            <Box className='iGor__AdvfilterSection'>

              {/* <Box className="iGor_AdvFilterInput iGOr_mb-D-none">
                <TextField
                  fullWidth
                  placeholder='Where do you want to go?'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={loctionIcon} />
                      </InputAdornment>
                    ),
                  }}

                />

              </Box> */}
              {/* {flightdistance ?
                <Box className='iGor_flighthours iGor_pointer' onClick={() => { setFlightdistance(!flightdistance) }}>
                  <img src={flightTakeoff} alt="flightTakeoff" />
                or search by flight hours
                </Box>
                :
                <Box>
                  <Typography variant='body2' className='iGor-Text-center' margin={1}>Or</Typography>
                </Box>
              } */}

              {/* {!flightdistance &&
                <Box className='iGor_FlightDistance'>
                  <Box className="iGor_setFlex iGor_justifyBetween">
                    <Typography className='iGor_FDtitle'>Flight distance from me </Typography>
                    <Typography className='iGor_FDsub'> Distance range:   <span> 2 Hours</span> </Typography>
                  </Box>
                  <Box width={'98%'} height={'20px'}>
                    <Slider className='iGor_silder'
                      getAriaLabel={() => 'Temperature range'}
                    // value={value}
                    // onChange={handleChange}
                    />
                  </Box>
                  <Box className="iGor_setFlex iGor_justifyBetween">
                    <Typography className='iGor_FDtitle'>Min</Typography>
                    <Typography className='iGor_FDtitle'>Max</Typography>
                  </Box>
                </Box>
              } */}
              <Box className='iGor__filterPriceRange'>
                <PriceRangeFilters currencySymbolPrice={currencySymbol} />
              </Box>
              {/* <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }} className="iGor_SortPrice">
                <Typography variant="body2" className='subtitle2'>Sort price by</Typography>
                <AvancedFilterRandomPopOver />
              </Box>
            </Box> */}

              <Box className='iGOr_DropDown'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }} onClick={() => { setSlideProperty(!slideProperty); setFlightdistance(true) }}>
                  <Typography variant="body2" className='subtitle2' fontSize={'12px'}>Property type</Typography>
                  <>{slideProperty ? <ExpandLessIcon /> : <ExpandMoreIcon />}   </>
                </Box>
                {slideProperty &&
                  <Box className={`${slideProperty && slideRoom ? 'iGor__AdvanDrop' : ''}`}>
                    <List dense sx={{ width: '100%', paddingTop: "12px" }} >
                      {
                        defaultPropertyType.length > 0 ?
                          defaultPropertyType?.map((propertyType: any) => {
                            const labelId = `checkbox-list-secondary-label-${propertyType?.category ? propertyType?.category : 'No Options Available'}`;
                            return (
                              <ListItem
                                className='iGor_checkBoxDrop'
                                key={propertyType.category}
                                secondaryAction={
                                  <Checkbox
                                    edge="end"
                                    onChange={(e) => propertyTypeSelectionHandler(propertyType?.category, propertyType?.id)}
                                    checked={setMobilePropertyTypeSelectedFilters?.indexOf(propertyType?.id) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}

                                  />
                                }
                                disablePadding
                              >
                                <ListItemButton>
                                  <ListItemText id={labelId} primary={`${propertyType?.category ? propertyType?.category : 'No Options Available'}`} />
                                </ListItemButton>
                              </ListItem>
                            );
                          }) : (
                            <>
                              <ListItem
                                key={'No Match Available'}
                                // secondaryAction={
                                //   <Checkbox
                                //     edge="end"
                                //     onChange={(e) => propertyTypeSelectionHandler(propertyType?.category, propertyType?.id)}
                                //     checked={setPropertyTypeSelectedFilters?.indexOf(propertyType?.id) !== -1}
                                //     inputProps={{ 'aria-labelledby': labelId }}
                                //   />
                                // }
                                disablePadding
                              >
                                <ListItemButton>
                                  <ListItemText id={"No Options Available"} primary={'No Options Available'} />
                                </ListItemButton>
                              </ListItem>
                            </>)}
                    </List>
                  </Box>
                }

              </Box>

              <Box className='iGOr_DropDown'>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: "center" }} onClick={() => { setSlideRoom(!slideRoom) }}>
                  <Typography variant="body2" className='subtitle2' fontSize={'12px'}>Room type</Typography>
                  <>{slideRoom ? <ExpandLessIcon /> : <ExpandMoreIcon />}   </>
                </Box>
                {slideRoom &&
                  <Box className={`${slideProperty && slideRoom ? 'iGor__AdvanDrop' : ''}`}>
                    <List dense sx={{ width: '100%', paddingTop: "12px" }}>
                      {defaultRoomTypes?.map((roomType: any) => {
                        const labelId = `checkbox-list-secondary-label-${roomType?.category ? roomType?.category : 'No Options Available'}`;
                        return (
                          <ListItem
                            className='iGor_checkBoxDrop'
                            key={roomType.category}
                            secondaryAction={
                              <Checkbox
                                edge="end"
                                onChange={(e) => roomTypeSelectionHandler(roomType?.category, roomType?.id)}
                                checked={setMobileRoomTypeSelectedFilters?.indexOf(roomType?.id) !== -1}
                                inputProps={{ 'aria-labelledby': labelId }}

                              />
                            }
                            disablePadding
                          >
                            <ListItemButton>
                              <ListItemText id={labelId} primary={`${roomType?.category ? roomType?.category : 'No Options Available'}`} />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                }

              </Box>
            </Box>

            {/* <Box className='iGor_setFlex  iGor_justifyBetween'>
              <Typography variant='body2' marginTop={'15px'}>View by</Typography>
              <Box className='iGor_AF-viewBy iGor_setFlex iGor_justifyBetween' width={'85%'}>
                <Typography variant='caption' paddingLeft={1}>Random</Typography>
                <EastIcon className='iGor_EastIcon' />
              </Box>
            </Box> */}

          </Box>
          <Box className="iGor_filterBtnSection">
            <Box>
              <Button onClick={resetFiltersHandler} className='iGor_filterResetBtn' variant='contained'>
                Clear all
              </Button>
            </Box>
            <Box>
              <Button variant='contained' onClick={applyFiltersHanlder}>Done</Button>
            </Box>
          </Box>
          <DisableSwipe/>
        </>
      </Modal>
    </div>
  );
}