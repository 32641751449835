import axios from "axios";
import { env } from '../../utils/config/appConfig';
import { payPalHeaders } from "../../utils/constants/constant";
import { environmentVariables } from "../../utils/config/environmentVariables";

const baseURL = environmentVariables.baseUrlPaypalApis;
export const generateToken = async () => {
    const clientId = environmentVariables.clientIdPaypal;
    const clientSecret = environmentVariables.clientSecreatPaypal;

    const authString = `${clientId}:${clientSecret}`;
    const base64AuthString = btoa(authString);
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${base64AuthString}`,
    };
    const body = 'grant_type=client_credentials'

    const approveOrderApiResponse = await axios.post(`${baseURL}/v1/oauth2/token`, body, { headers }).then(
        (response) =>
            response.data.access_token
    )
        .catch((error) => {
            return error.message
        })
    return approveOrderApiResponse;
}

export const authorizePayment = async (token: string, orderId: string, payerId: string) => {
    const body = JSON.stringify({
        "payerId": `${payerId}`
    })

    let headers: any = payPalHeaders;
    headers.Authorization = `Bearer ${token}`;

    const authorizeOrderApiResponse = await axios.post(`${baseURL}/v2/checkout/orders/${orderId}/authorize`, body, { headers }).then(
        (response) =>
            response.data
    )
        .catch((error) => {
            return error.message
        })
    return authorizeOrderApiResponse;

}