import { Grid, Skeleton } from "@mui/material";
import { Box } from "@mui/system";

function VRandMapSectionSkeleton() {
    return (<>
        <Grid item sm={6}>
            <Box className="iGor_ZoomableImage iGOr_mb-D-none" >
                <Box sx={{ height: '550px' }}>
                    <Skeleton variant="rectangular" width="100%" height='100%'>
                    </Skeleton>
                </Box>
            </Box>
        </Grid>
    </>);
}

export default VRandMapSectionSkeleton;