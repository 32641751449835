import React, { useEffect, useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useAppSelector } from '../../appStore/hooks';
import { setSearchDestinationFromDate, setSearchDestinationToDate, setNoOfNights } from '../../appStore/hotelSlice';
import { useDispatch } from 'react-redux';
import { calculateOneYearLaterDate } from '../../utils/utilities/DateConverter';
export const DatePickerComp = ({ setSearchMsg, setNumberOfNights, startDateForDestination, endDateForDestination,
  setEndDateForDestinationSearch, setStartDateForDestinationSearch }: any) => {
  const dispatch = useDispatch();
  const todaysDateForRange = startDateForDestination
  const endDateForRange = endDateForDestination

  // function for date selection handler
  const handleSelect = (ranges: any) => {
    const fromDate = ranges.selection.startDate;
    const toDate = ranges.selection.endDate;
    const days = ((toDate.getTime() - fromDate.getTime()) / (1000 * 3600 * 24))
    if (days == 0) {
      const newDate = new Date(fromDate)
      newDate.setDate(newDate.getDate() + 1)
      setNumberOfNights(1)
      setStartDateForDestinationSearch(fromDate.toISOString())
      setEndDateForDestinationSearch(newDate.toISOString())
      // dispatch(setNoOfNights(1))
      // dispatch(setSearchDestinationFromDate(fromDate.toISOString()))
      // dispatch(setSearchDestinationToDate(newDate.toISOString()))
      if (setSearchMsg) { setSearchMsg('How many guest & room?') }

    }
    else {
      setNumberOfNights(days)
      setStartDateForDestinationSearch(fromDate.toISOString())
      setEndDateForDestinationSearch(toDate.toISOString())
      // dispatch(setNoOfNights(days))
      // dispatch(setSearchDestinationFromDate(fromDate.toISOString()))
      // dispatch(setSearchDestinationToDate(toDate.toISOString()))
      if (setSearchMsg) { setSearchMsg('How many guest & room?') }
    }
  };

  const todaysDate = new Date();
  const onYearLaterTodaysDate = calculateOneYearLaterDate(todaysDate)


  const selectionRange: any = {
    startDate: new Date(todaysDateForRange),
    endDate: new Date(endDateForRange),
    key: 'selection',
  };
  return (
    <>
      <DateRange
        ranges={[selectionRange]}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        onChange={handleSelect}
        rangeColors={['#FF6B6B']}
        showPreview={true}
        minDate={todaysDate}
        maxDate={onYearLaterTodaysDate}
      />
    </>
  );
};
