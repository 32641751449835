import React, { useEffect } from 'react';
import axios from 'axios'
import qs from 'qs'
import { env } from '../../utils/config/appConfig';
import { useDispatch } from 'react-redux';
import { setCliendSecreatId } from '../../appStore/hotelSlice';
import { useAppSelector } from '../../appStore/hooks';
import { environmentVariables } from '../../utils/config/environmentVariables';

function StripePaymentIntent({ roomPriceConverted, currencyForcheckout }: any) {
    const dispatch = useDispatch();
    const { getUserDetails } = useAppSelector(state => state.hotel)
    let customerName = getUserDetails?.data?.userDetail?.firstName ? getUserDetails.data?.userDetail?.firstName : 'data not found'
    const secreatKey = environmentVariables.appSecreatKeyStripe
    // logic for api call and get user details at the time of the payment
    useEffect(() => {
        customerName = getUserDetails?.data?.userDetail?.firstName ? getUserDetails.data?.userDetail?.firstName : 'data not found'
        if (roomPriceConverted) {
            const body = qs.stringify({
                'amount': roomPriceConverted?.toString(),
                'currency': currencyForcheckout,
                'capture_method': 'manual',
                'description': `${customerName} - Booked Hotel With IgoRooms`
            })

            const headers = {
                'Authorization': `Bearer ${secreatKey}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            const fetchPaymentIntent = async () => {
                try {
                    const response = await axios.post(`https://api.stripe.com/v1/payment_intents`, body, {
                        headers: headers
                    });
                    if (response.data.client_secret) {
                        dispatch(setCliendSecreatId(response.data.client_secret))
                    }
                } catch (error) {
                }
                fetchPaymentIntent()
            }
        }
    }, [getUserDetails, roomPriceConverted])

    return (<>
    </>)
}

export default StripePaymentIntent