import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { OrderSumRegisterModel } from "../../components/popUpSection/OrderSumRegisterModel";
import Fav_FirstWishlist from '../../assets/icons/Fav_FirstWishlist.svg'

function MakeWishlist() {
    const matches = useMediaQuery('(max-width:1024px)');
    return (<>
        <Box className={matches ? '' : 'iGor__loginCard'}>
            <Grid container spacing={2} className='iGor__CardForCenter'>
                <Grid item sm={3} className={matches ? 'iGor__loginCard' : ''}>
                    <img src={Fav_FirstWishlist} alt='MyTrip_firsttrip' />
                </Grid>
                <Grid item sm={9}>
                    <Box  className="iGorfavourites_registermsg"  >
                        <Typography variant='h4' marginBottom={2} className="iGorfavourites_title"> Log In or register to enjoy Make Your First Wishlist </Typography>
                        <OrderSumRegisterModel />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>);
}

export default MakeWishlist;