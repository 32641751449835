import { useMemo } from 'react';
import NoHotelsImg from '../../assets/images/noHotelIgo.jpg'

const ImageComponent = ({ imageUrl, handleImageLoad, className, style }: any) => {
    const MemoizedImages = useMemo(() => {
        return <img
            className={className ? className : ''}
            style={style ? style : {}}
            src={imageUrl}
            onLoad={handleImageLoad ? handleImageLoad : ''}
            onError={(e: any) => {
                e.target.src = NoHotelsImg;
            }}
            alt="No Image"
        />

    }, [imageUrl, handleImageLoad]);

    return (MemoizedImages)
}
export default ImageComponent